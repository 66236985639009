(function () {
    'use strict';

    angular
        .module('elogbooks.common.tasks')
        .controller('CommonTaskDetailsEventFeedController', CommonTaskDetailsEventFeedController);

        CommonTaskDetailsEventFeedController.$inject = ['eventCollectionResponse', 'taskResponse'];

    function CommonTaskDetailsEventFeedController (eventCollectionResponse, taskResponse) {
        var vm = this;
            vm.feed = [];
            vm.eventCollectionResponse = eventCollectionResponse;
            vm.task = taskResponse;

        if (vm.eventCollectionResponse) {
            vm.eventCollectionResponse.events = eventCollectionResponse.events.filter(function(value){
                if (value.type !== 'task_assigned' && value.type !== 'task_unassigned') {
                    return value;
                }
            });
        }
    }
})();
