angular.module('elogbooksServices').service('fetchJobLabourLinesOperativesService', function (apiClient, $q) {
    let operativeCollectionResponse = [];

    this.fetchOperatives = (userResponse, serviceProviderResponse = null, jobResponse = null) => {
        const promise = $q.defer();

        if (operativeCollectionResponse?.[userResponse.id]) {
            promise.resolve(operativeCollectionResponse?.[userResponse.id]);
            return promise.promise;
        }

        const params = { permission: 'operative_permission_receive_jobs' };

        const [requestUrl, requestParams] = (() => {
            let url = userResponse.getLink('operatives');
            let newParams = {...params};

            if (serviceProviderResponse) {
                if (serviceProviderResponse.isShared) {
                    newParams = { serviceProviderId: serviceProviderResponse.id, ...params };
                } else {
                    url = serviceProviderResponse.getLink('joboperatives') || serviceProviderResponse.getLink('operatives');
                }
            } else if (jobResponse?.additionalOperatives.length) {
                newParams = { jobAdditionalOperatives: jobResponse.id, ...params };
            }

            return [url, newParams];
        })();

        if (!requestUrl) {
            promise.reject(false);
            return promise.promise;
        }

        apiClient.get(requestUrl, requestParams).then((response) => {
            if (response) {
                operativeCollectionResponse[userResponse.id] = response;
                promise.resolve(response);
            } else {
                promise.resolve(apiClient.noResourceFound());
            }
        }).catch((response) => {
            console.error('Error during loading operatives', response);
            promise.reject(response);
        });

        return promise.promise;
    };
});
