(function () {
    'use strict';

    angular
        .module('elogbooks.admin.service-providers')
        .controller('ServiceProviderOperativesAddEditController', ServiceProviderOperativesAddEditController);

    ServiceProviderOperativesAddEditController.$inject = [
        '$state',
        'apiClient',
        '$translate',
        'operativePermissions',
        'operativeResponse',
        'requestDataFactory',
        'messenger',
        'operativesCollection'
    ];

    function ServiceProviderOperativesAddEditController (
        $state,
        apiClient,
        $translate,
        operativePermissions,
        operativeResponse,
        requestDataFactory,
        messenger,
        operativesCollection
    ) {
        var vm = this;
        vm.operative = operativeResponse;
        vm.data = requestDataFactory.createRequest(getDefaults());
        vm.userSelect = {
            link: '/users',
            responseKeyPath: 'users',
            required: true,
            linkParameters: { order: 'name' }
        };

        // Permissions
        vm.operative.permissions = [];
        vm.permissions = operativePermissions.getAllTranslated();
        vm.selectedPermissions = [];
        vm.operativePermissions = [];

        // Functions
        vm.create = createAction;
        vm.addPermissions = addPermissions;
        vm.removePermission = removePermission;

        function getDefaults() {
            return {
                _links: {
                    user: null
                },
                permissions: [],
            };
        }

        function addPermissions (permissions, force) {
            angular.forEach(permissions, function (permission, index) {
                var index = vm.operative.permissions.indexOf(permission.label);

                if (index === -1 || force) {

                    var newPermission = {
                        label: permission.label,
                        translation: $translate.instant(permission.label.toUpperCase()),
                        description: $translate.instant(permission.label.toUpperCase() + '_DETAIL')
                    };

                    if (index === -1) {
                        vm.operative.permissions.push(newPermission.label);
                    }

                    vm.operativePermissions.push(newPermission);

                    angular.forEach(vm.permissions, function (permission, index) {
                        if (permission.label === newPermission.label) {
                            vm.permissions.splice(index, 1);
                        }
                    });
                }
            });

            vm.selectedPermissions = [];
        }

        function removePermission (permission) {
            var index = vm.operative.permissions.indexOf(permission);

            if (index > -1) {
                vm.operative.permissions.splice(index, 1);
                vm.operativePermissions.splice(index, 1);

                var oldPermission = {
                    label: permission,
                    name: $translate.instant(permission.toUpperCase()),
                    translation: $translate.instant(permission.toUpperCase()),
                    description: $translate.instant(permission.toUpperCase() + '_DETAIL')
                };

                vm.permissions.push(oldPermission);
            }
        }

        function createAction () {
            if (vm.userSelect.selected) {
                vm.data._links.user = { href : vm.userSelect.selected.href }
            }

            vm.data.permissions = vm.operative.permissions;

            apiClient.create(operativesCollection.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('dashboard.admin.service-providers.list.details.operatives', {}, { reload: true }).then(function () {
                        messenger.success('OPERATIVE_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }

})();
