(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            PROSURE_NOT_ENABLED: "Vantify Supply Chain is currently not enabled for this installation",

            PROSURE_COMPANY_NAME:
            "For Vantify Supply Chain Company: <strong>{{ ::companyName }}</strong>",
            PROSURE_SERVICE_PROVIDER: "Service Provider",
            USER_INTEGRATIONS_PROSURE: "Vantify Supply Chain",
            USER_INTEGRATIONS_PROSURE_EDIT: "Create Links",
            PROSURE_REQUIREMENTS_SUCCESS: "Meets Requirements",
            PROSURE_REQUIREMENTS_BELOW: "Below Requirements",
            PROSURE_REQUIREMENTS_EXPIRING: "Expiring Soon",
            VANTIFY_SUPPLY_CHAIN_REQUIREMENTS_BELOW_WARNING: "Please be aware you have selected a Service Provider who does not meet your Vantify Supply Chain Requirements.",
            PROSURE_REQUIREMENTS_BELOW_CONFIRMATION: "Click here to Acknowledge that the Service Provider you have selected does not meet your Vantify Supply Chain Requirements.",
            PROSURE_SUSPENDED: "Suspended",
            VANTIFY_SUPPLY_CHAIN_SUSPENDED_WARNING: "Please be aware you have selected a Service Provider who is currently Suspended based on your Vantify Supply Chain Requirements.",
            NO_VANTIFY_SUPPLY_CHAIN_SP: 'Not in my Vantify Supply Chain',
            NOT_IN_MY_SUPPLY_CHAIN_WARNING: 'Please be aware you have selected a Service Provider who is currently not in your Vantify Supply Chain.',
            VANTIFY_SUPPLY_CHAIN_SP_REQUIREMENTS_SUCCESS: 'Vantify Supply Chain Meets Requirements',
            VANTIFY_SUPPLY_CHAIN_SP_REQUIREMENTS_EXPIRING: 'Vantify Supply Chain Expiring Soon',
            VANTIFY_SUPPLY_CHAIN_REQUIREMENTS_EXPIRING_WARNING: 'Please be aware you have selected a Service Provider whose Vantify Supply Chain Requirements are Expiring Soon.',
            VANTIFY_SUPPLY_CHAIN_SP_REQUIREMENTS_BELOW: 'Vantify Supply Chain Below Requirements',
            VANTIFY_SUPPLY_CHAIN_SP_SUSPENDED: 'Vantify Supply Chain Suspended',
            VANTIFY_SUPPLY_CHAIN_STATUS: "Vantify Supply Chain Status",

            PROSURE_CANCEL_SETUP: "Are you sure you want to return to Vantify Supply Chain without setting up any links?",

            PROSURE_UNABLE_TO_GET_COMPANY_INFO: "Unable to retreive Company Info from Vantify Supply Chain",
            PROSURE_UNABLE_TO_UPDATE_LINKS: "Unable to update links",

            PROSURE_NO_ASSOCIATED_SPS: "Your user currently has no associated Service Providers.",
            PROSURE_EITHER: "Either",

            PROSURE_TRY_ANOTHER_USER: "and try another user, or return back to",

            PROSURE_COMPANY_INVALID: "Company is Invalid.",

            PROSURE_RETURN: "Return back to",
        });
    }
})();
