(function () {
    'use strict';

    angular
        .module('elogbooks.admin.scd-imports')
        .controller('SCDImportListController', ['importsCollectionResponse', '$state', '$stateParams', '$translate', 'importsFilter', SCDImportListController]);

    function SCDImportListController(importsCollectionResponse, $state, $stateParams, $translate, importsFilter) {
        var vm = this;
        vm.importsCollection = importsCollectionResponse;
        vm.imports = importsCollectionResponse.imports;
        vm.entityName = $translate.instant('SCD');
        vm.importRoute = '.new-scd-import';
        vm.isCurrentState = $state.includes('dashboard.admin.scd-imports');
        vm.currentState = 'dashboard.admin.scd-imports';
        vm.noBorder = false;

        const { status, debug, startDate, endDate, filename } = $stateParams;

        vm.search = searchAction;
        vm.criteria = {
            status: { type: 'options', multiple: true, title: 'STATUS', value: status, options: importsFilter.statusOptions, clearValue: importsFilter.defaultStatuses },
            startDate: { type: 'date', value: startDate, title: 'DATE_STARTED_AT',  maxDependsOn: 'endDate' },
            endDate: { type: 'date', value: endDate, title: 'DATE_ENDED_AT', minDependsOn: 'startDate' },
            filename: { type: 'text', title: 'FILE_NAME', value: filename },
            debug: { type: 'options', title: 'DEBUG', value: debug, options: importsFilter.debugOptions, clearValue: 'all' }
        };

        function searchAction (params) {
            $state.go('.', params, { reload: $state.current });
        }
    }
})();
