(function() {
    'use strict';

    angular
        .module('elogbooks.user.sites.jobs')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesDetailsWorkflow);

    var priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all,entity_type_job'};
    var jobParams = {
        jobsPage: '1',
        jobsLimit: '30',
        jobsOrder: '-id',
        jobId: null,
        jobsType: null,
        statType: {array: true},
        jobSummary: null,
        jobPriority: {array: true},
        assetTypes: {array: true},
        jobSiteRegion: {array: true},
        serviceProviders: {array: true},
        createdAtStart: null,
        createdAtEnd: null,
        createdBy: null,
        jobGroups: {array: true},
        chargeType: null,
        operative: null,
        alarmedAtStart: null,
        alarmedAtEnd: null,
        description: null,
        approver: {array: true},
        jobStatus: {array: true},
        jobsOverdueAttendance: null,
        jobsOverdueCompletion: null,
        jobsStatutory: null,
        jobsServiceProvider: null,
        jobsReminderAtStart: null,
        jobsReminderAtEnd: null,
        jobsAttendanceDueStart: null,
        jobsAttendanceDueEnd: null,
        completionDueStart: null,
        completionDueEnd: null,
        jobSiteGroups: {array: true},
        asset: null,
        subAsset: null,
        serviceProvider: null,
        fromJob: null,
        jobRequisitePendingApproval: null,
        requisite: null,
        fileOrder: '-id',
        jobsDueBy: null,
        completedAtStart: null,
        completedAtEnd: null,
        coreClient: null,
        serviceProviderGroup: null,
        location: null,
        isRemedial: null,
        jobsRemedial: null,
        sourceJob: null,
        jobSpecificSpPriority: null,
        sourceJobAsset: null,
        sourceJobSubasset: null,
        sites: {array: true},
        documentTypes: {array: true},
        jobsHasDocumentType: null,
        prosureStatusAtCompletion: {array: true},
        prosureStatusAtApproval: {array: true},
        poNumber: null
    };

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.jobs', {
                url: '?' + Object.keys(jobParams).join('&'),
                abstract: true,
                params: jobParams,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                }
            });
    }

    function registerRoutesList($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.jobs.list', {
                url: '/jobs',
                parent: 'dashboard.user.sites.list.details.jobs',
                views: {
                    '@dashboard.user.sites.list.details.jobs': {
                        templateUrl: '/modules/common/jobs/jobs-list.html',
                        controller: 'CommonJobsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    config: function(user, userManager) {
                        /**
                         * While unused, user is required as a dependency here to prevent race conditions against userManager.
                         * Do not remove it!
                         */

                        return {
                            resourceName: 'jobResource',
                            isHelpdesk: userManager.hasPermission('site_permission_helpdesk'),
                            ignoredFilters: [
                                'jobsPage',
                                'jobsLimit',
                                'jobsOrder',
                                'fileOrder',
                                'limit',
                                'childLocationActive',
                                'page',
                                'locationActive',
                                'resource',
                                'status'
                            ]
                        };
                    },
                    selectedSiteResponse: function(siteResponse) {
                        return siteResponse; // return the site for use in bulk actions
                    },
                    statutoryTypeCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('statutorytypes')) {
                            return apiClient.get(rootResourceResponse.getLink('statutorytypes')).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    documentTypesCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('documenttypes')) {
                            return apiClient.get(rootResourceResponse.getLink('documenttypes'), null, 'long').then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    priorityCollectionResponse: function(apiClient, rootResourceResponse, siteResponse) {

                        var priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all,entity_type_job', 'sites[]':siteResponse.id};

                        if (rootResourceResponse.getLink('priorities')) {
                            return apiClient.get(rootResourceResponse.getLink('priorities'), priorityLinkParameters).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    jobCollectionResponse: function($stateParams, $rootScope, jobStatuses, userManager, elbSettings) {
                        if (userManager.hasPermission('site_permission_helpdesk')) {
                            $rootScope.jobStatusQuickFilter = 'all';
                        } else {
                            if (Array.isArray($stateParams.jobStatus)) {
                                $rootScope.jobStatusQuickFilter = $stateParams.jobStatus.length === 0 ? 'all' : 'filtered';
                            }

                            if ($stateParams.jobStatus === undefined && $rootScope.jobStatusQuickFilter != 'all') {
                                $stateParams.jobStatus = jobStatuses.getAll('nonfinal');
                            }
                        }

                        if (!elbSettings.getSetting('prosure_enabled').value) {
                            delete $stateParams.prosureStatusAtCompletion;
                            delete $stateParams.prosureStatusAtApproval;
                        }

                        return {
                            jobs: null,
                            pages: 0,
                            count: 0
                        }
                    },
                    chargeTypesCollectionResponse: function(apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('chargetypes')) {
                            return apiClient.get(rootResourceResponse.getLink('chargetypes')).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    canBulkReassign: function(user, userManager) {
                        return userManager.hasPermission('site_permission_bulk_reassign_jobs');
                    },
                    canBulkReassignOperative: function(apiClient, user, userManager) {
                        if (user.getLink('operatives')) {
                            return apiClient.get(user.getLink('operatives'), {permission: 'operative_permission_bulk_reassign_operative_jobs'}).then(function(response) {
                                var canReassign = false;
                                if (response) {
                                    canReassign = response.count;
                                }
                                return canReassign || userManager.hasPermission('site_permission_bulk_reassign_operative_jobs');
                            });
                        }
                    },
                    canBulkCancel: function(apiClient, user, userManager) {
                        if (user.getLink('operatives')) {
                            return apiClient.get(user.getLink('operatives'), {permission: 'operative_permission_bulk_cancel_jobs'}).then(function(response) {
                                var canCancel = false;
                                if (response) {
                                    canCancel = response.count;
                                }
                                return canCancel || userManager.hasPermission('site_permission_bulk_cancel_jobs');
                            });
                        }
                    },
                    serviceProviderCollectionResponse: function($stateParams, apiClient, user, userManager, selectedSiteResponse) {
                        if (!user.getLink('serviceproviders')) {
                            return null;
                        }

                        var params = {};
                        if ($stateParams.serviceProviders) {
                            params.id = $stateParams.serviceProviders[0];
                        }

                        if (selectedSiteResponse) {
                            params.siteId = selectedSiteResponse.id;
                        }

                        if (user.getLink('serviceproviders')) {
                            return apiClient.get(user.getLink('serviceproviders'), params).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"JOBS" | translate }}'
                }
            });
    }

    function registerRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.jobs.list.add', {
                url: '/add?action&spillage',
                abstract: true,
                views: {
                    '@dashboard.user.sites.list.details.jobs': {
                        templateUrl: '/modules/common/jobs/add/job-form.html',
                        controller: 'CommonJobAddController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    action: null,
                    spillage: null
                },
                resolve: {
                    config: function(siteResponse, userManager, config) {
                        return angular.extend(config, {
                            isHelpdesk: userManager.hasPermission('site_permission_helpdesk'),
                            isTenantPlus: userManager.hasPermission('site_permission_tenant_plus')
                                          && !userManager.hasPermission('site_permission_helpdesk')
                                          && !userManager.hasPermission('site_permission_create_jobs'),
                            isTenant: userManager.hasPermission('site_permission_tenant')
                                      && !userManager.hasPermission('site_permission_tenant_plus')
                                      && !userManager.hasPermission('site_permission_helpdesk')
                                      && !userManager.hasPermission('site_permission_create_jobs'),
                            showSiteNotes: config.isHelpdesk,
                            addReporter: config.isHelpdesk,
                            link: null,
                            siteDisabled: true
                        });
                    },
                    siteResourceResponse: function(siteResponse) {
                        return siteResponse;
                    },
                    actionResponse: function(apiClient, $stateParams) {
                        if ($stateParams.action) {
                            return apiClient.get($stateParams.action.decode(), { datagroup: 'details' }).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }

                        return null;
                    },
                    actionFilesCollection: function(apiClient, actionResponse) {
                        if (actionResponse) {
                            return apiClient.get(actionResponse.getLink('files')).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    notesCollectionResponse: function($stateParams, apiClient, siteResourceResponse, config) {
                        if (config.isHelpdesk && siteResourceResponse) {
                            return apiClient.get(siteResourceResponse.getLink('notes')).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    spillageResponse: function(apiClient, $stateParams) {
                        if ($stateParams.spillage) {
                            return apiClient.get($stateParams.spillage.decode(), { datagroup: 'details' }).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }

                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"JOB_ADD" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.add.notes', {
                url: '/notes',
                parent: 'dashboard.user.sites.list.details.jobs.list.add',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.add': {
                        templateUrl: '/modules/common/jobs/add/job-notes.html',
                        controller: 'CommonJobAddNotesController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"NOTES" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.add.site', {
                url: '/site',
                parent: 'dashboard.user.sites.list.details.jobs.list.add',
                views: {
                    '@dashboard.user.sites.list.details.jobs.list.add': {
                        templateUrl: '/modules/common/jobs/add/job-form-site.html',
                        controller: 'CommonJobAddSiteController',
                        controllerAs: '_' // Faked controllerAs so we can inherit from parent
                    }
                },
                resolve: {
                    siteCollectionResponse: function() {
                        return null;
                    },
                    siteRelationshipResponse: function(rootResourceResponse) {
                        return rootResourceResponse.getResource('siterelationships');
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SITE" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.add.reporter', {
                url: '/reporter',
                parent: 'dashboard.user.sites.list.details.jobs.list.add.site',
                views: parseBook({
                    '@dashboard.user.sites.list.details.jobs.list.add': {
                        templateUrl: '/modules/common/jobs/add/job-form-reporter.html',
                        controller: 'CommonJobAddReporterController',
                        controllerAs: '_' // Faked controllerAs so we can inherit from parent
                    }
                }),
                resolve: {
                    userCollectionResponse: function(apiClient, user, siteResourceResponse) {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"JOB_REPORTER" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.add.contact', {
                url: '/contact',
                parent: 'dashboard.user.sites.list.details.jobs.list.add.reporter',
                views: {
                    '@dashboard.user.sites.list.details.jobs.list.add': {
                        templateUrl: '/modules/common/jobs/add/job-form-contact.html',
                        controller: 'CommonJobAddContactController',
                        controllerAs: '_' // Faked controllerAs so we can inherit from parent
                    }
                },
                resolve: {
                    userCollectionResponse: function(siteResourceResponse) {
                        return null; //siteResourceResponse.getResource('memberships');
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"JOB_CONTACT" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.add.details', {
                url: '/details',
                parent: 'dashboard.user.sites.list.details.jobs.list.add.contact',
                views: {
                    '@dashboard.user.sites.list.details.jobs.list.add': {
                        templateUrl: '/modules/common/jobs/add/job-form-details.html',
                        controller: 'CommonJobAddDetailsController',
                        controllerAs: '_' // Faked controllerAs so we can inherit from parent
                    }
                },
                resolve: {
                    jobGroupCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('jobgroups'), { order: name, fetchHierarchy: false }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    partnershipCollectionResponse: function (apiClient, siteResourceResponse, user, elbSettings) {
                        var params = {active: 1, includeCanCreateJobsOtherServiceProviders: 'true'};

                        if (elbSettings.getSetting('prosure_enabled').value) {
                            params = angular.extend({order: 'prosureStatus'}, params);
                        }

                        if (siteResourceResponse.getLink('partnerships')) {
                            return apiClient.get(siteResourceResponse.getLink('partnerships'), params).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        } else if (user.getLink('partnerships')) {
                            params = angular.extend({siteId: siteResourceResponse.id}, params);

                            return apiClient.get(user.getLink('partnerships'), params).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    settingCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('jobsettings')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        })
                    },
                    statutoryTypeCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('statutorytypes')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        })
                    },
                    locationCollectionResponse: function (apiClient, siteResourceResponse) {
                        return apiClient.get(siteResourceResponse.getLink('locations')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        })
                    },
                    chargeTypesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('chargetypes')).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    assetsCollectionResponse: function (apiClient, siteResourceResponse) {
                        if (siteResourceResponse.getLink('assets')) {
                            return apiClient.get(siteResourceResponse.getLink('assets'), {active:1}).then(function (response) {
                                return response;
                            });
                        }
                    },
                    userIsReactiveJobApprover: function(apiClient, siteResourceResponse, settingCollectionResponse) {
                        if (settingCollectionResponse.reactiveSelfApproval) {
                            return apiClient.get(siteResourceResponse.getLink('isreactivejobapprover')).then(function (response) {
                                var data = response.getData();
                                return data.isReactiveJobApprover || false;
                            });
                        } else {
                            return null;
                        }
                    },
                    config: function($state, selectedSiteResponse, userManager) {
                        return {
                            showSiteNotes: userManager.hasPermission('site_permission_helpdesk')
                        };
                    },
                    documentTypesCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('documenttypes')) {
                            return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', limit: 999}).then(function (response) {
                                return response;
                            });
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"JOB_DETAILS" | translate }}'
                }
            });
    }

    function registerRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.jobs.list.details', {
                url: '/{jobResource}',
                parent: 'dashboard.user.sites.list.details.jobs.list',
                abstract: true,
                views: {
                    '@dashboard.user.sites.list.details.jobs': {
                        templateUrl: '/modules/common/jobs/details/job-details-header.html',
                        controller: 'CommonJobDetailsController',
                        controllerAs: 'vm'
                    },
                    'feed@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/jobs/details/event/feed.html',
                        controller: 'CommonJobDetailsEventFeedController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    hasExplicitAccess: null
                },
                resolve: {
                    jobResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.jobResource.decode(), { datagroup: 'details' }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteResponse: function(apiClient, jobResponse) {
                        if (typeof jobResponse.hasExplicitAccess !== 'undefined') {
                            return {}
                        } else {
                            return jobResponse.getResource('site') || apiClient.noResourceFound();
                        }
                    },
                    eventCollectionResponse: function (apiClient, $http, API_URL, $stateParams) {
                        if ($stateParams.jobResource) {
                            return $http({
                                url: API_URL + $stateParams.jobResource.decode() + '/events',
                                method: "GET",
                                params: {datagroup: 'details'}
                            }).then(function (response) {
                                return new apiClient.ResponseData(response.data);
                            }, function (response) {
                                if (response.status === 403) {
                                    return {};
                                }
                            });
                        }
                    },
                    messageCollectionResponse: function (apiClient, $http, API_URL, $stateParams) {
                        if ($stateParams.jobResource) {
                            return $http({
                                url: API_URL + $stateParams.jobResource.decode() + '/messages',
                                method: "GET",
                                params: {datagroup: 'details'}
                            }).then(function (response) {
                                return new apiClient.ResponseData(response.data);
                            }, function (response) {
                                if (response.status === 403) {
                                    return {};
                                }
                            });
                        }
                    },
                    jobRequisitesResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.jobResource.decode() + '/jobrequisites', { datagroup: 'list' }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    jobLinksCollection: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.jobResource.decode() + '/links').then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    canViewJobLinks: function(jobResponse) {
                        return jobResponse.getLink('create-link') ? true : false;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().jobResponse.summary }} '
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.status', {
                url: '/status',
                parent: 'dashboard.user.sites.list.details.jobs.list.details',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/jobs/details/status/status.html'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"JOB_STATUS" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.files', {
                url: '/files?filePage&fileLimit',
                parent: 'dashboard.user.sites.list.details.jobs.list.details',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/files/files-list.html',
                        controller: 'CommonFilesListController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    documentType: null,
                    filePage: '1',
                    fileLimit: '30',
                    fileOrder: 'id'
                },
                resolve: {
                    fileCollectionResponse: function(apiClient, jobResponse, $stateParams) {
                        var params = {
                            order: $stateParams.fileOrder,
                            limit: $stateParams.fileLimit,
                            page: $stateParams.filePage

                        };
                        return apiClient.get(jobResponse.getLink('files'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function(config) {
                        return angular.extend({}, config, {
                            showStatutoryType: true
                        });
                    },
                    integrationDocTypesResponse: function()
                    {
                        return null;
                    },
                    documentTypeCollectionResponse : function (apiClient, rootResourceResponse, jobResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', order: 'name', job: jobResponse.id})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    serviceProviderResponse: function () {
                        return null;
                    },
                    operativeCollectionResponse: function () {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"FILES" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.job-files', {
                url: '/job-files?filePage&fileLimit',
                parent: 'dashboard.user.sites.list.details.jobs.list.details',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/files/files-list.html',
                        controller: 'CommonFilesListController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    filePage: '1',
                    fileLimit: '30',
                    fileOrder: 'id'
                },
                resolve: {
                    fileCollectionResponse: function(apiClient, siteResponse, user, jobResponse, $stateParams) {
                        var params = {
                            site: siteResponse.id,
                            order: $stateParams.fileOrder,
                            limit: $stateParams.fileLimit,
                            page: $stateParams.filePage,
                            isJobFile: true,
                            'applicableTo[]': [jobResponse.type]
                        };

                        return apiClient.get(jobResponse.getLink('site-files'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function(config) {
                        return angular.extend({}, config, {
                            showStatutoryType: true,
                            showDownloadButton: true,
                            hideAddButton: true
                        });
                    },
                    integrationDocTypesResponse: function()
                    {
                        return null;
                    },
                    documentTypeCollectionResponse : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', order: 'name'})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    serviceProviderResponse: function () {
                        return null;
                    },
                    operativeCollectionResponse: function () {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"FILES_SITE_FILES" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.files.details', {
                url: '/details/{fileResource}',
                parent: 'dashboard.user.sites.list.details.jobs.list.details.files',
                views: {
                    'files@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/files/details/files-details.html',
                        controller: 'CommonFilesDetailsController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    fileResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.fileResource.decode()).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function (config) {
                        return angular.extend(config, {
                            showVisibility: true
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().fileResponse.title }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.files.details.edit', {
                url: '/edit',
                parent: 'dashboard.user.sites.list.details.jobs.list.details.files.details',
                views: {
                    'files@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                        controller: 'CommonFilesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    config: function(siteResponse, jobResponse, config) {
                        return angular.extend(config, {
                            entitySite: siteResponse,
                            associatedEntity: jobResponse,
                            fileCreateLink: jobResponse.getLink('files'),
                            showVisibility: true
                        });
                    },
                    tagCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), { limit: 30 }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.files.add', {
                url: '/add',
                parent: 'dashboard.user.sites.list.details.jobs.list.details.files',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                        controller: 'CommonFilesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    fileResponse: function() {
                        return {};
                    },
                    tagCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), { limit: 30 }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function(siteResponse, jobResponse, config) {
                        return angular.extend(config, {
                            entitySite: siteResponse,
                            associatedEntity: jobResponse,
                            fileCreateLink: jobResponse.getLink('files'),
                            showVisibility: true
                        });
                    },
                    integrationDocTypesResponse: function() {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.contacts', {
                url: '/contacts',
                parent: 'dashboard.user.sites.list.details.jobs.list.details',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/contacts/contacts.html',
                        controller: 'CommonContactsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entityResponse: function (jobResponse) {
                        return jobResponse;
                    },
                    contactCollectionResponse: function(apiClient, jobResponse) {
                        return apiClient.get(jobResponse.getLink('contacts')).then(function(response) {
                            return response || (jobResponse.getLink('contacts') && apiClient.noResourceFound());
                        });
                    },
                    config: function(config) {
                        return angular.extend(config, {
                            entityName: 'JOB'
                        });
                    },

                },
                ncyBreadcrumb: {
                    label: '{{ ::"CONTACTS" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.site-notes', {
                url: '/site-notes',
                parent: 'dashboard.user.sites.list.details.jobs.list.details',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/site-notes/site-notes-list.html',
                        controller: 'CommonSiteNotesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    siteNoteCollectionResponse: function(apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('notes'), { shared: 'true' }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SITE_NOTES" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.requisites', {
                url: '/requisites',
                parent: 'dashboard.user.sites.list.details.jobs.list.details',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/jobs/details/requisites/requisites.html',
                        controller: 'CommonJobDetailsRequisitesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    jobRequisiteEventsResponse: function(apiClient, jobResponse) {
                        return apiClient.get(jobResponse.getLink('jobrequisitesanswers')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"REQUISITES" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.requisites.view-form', {
                url: '/view-form/{formVersionInstanceResource}',
                parent: 'dashboard.user.sites.list.details.jobs.list.details.requisites',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/forms/details/details.html',
                        controller: 'CommonFormsDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    formVersionInstanceResponse: function($stateParams, apiClient) {
                        return apiClient
                            .get($stateParams.formVersionInstanceResource.decode(), {datagroup: 'details'})
                            .then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    associatedEntities: function(jobResponse) {
                        return {
                            job: jobResponse
                        };
                    },
                    config: function() {
                        return {
                            mode: 'render-preview',
                            jobRequisite: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().formVersionInstanceResponse._embedded.form.summary }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.requisites.view-form.edit', {
                url: '/edit',
                parent: 'dashboard.user.sites.list.details.jobs.list.details.requisites.view-form',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/forms/details/details.html',
                        controller: 'CommonFormsDetailsController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    config: function() {
                        return {
                            mode: 'render'
                        };
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}',
                },
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.requisites.approve', {
                url: '/{jobRequisiteResource}/approve',
                parent: 'dashboard.user.sites.list.details.jobs.list.details.requisites',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/jobs/details/requisites/workflow.html',
                        controller: 'CommonJobRequisitesWorkflowController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    jobRequisiteAnswerResponse: function(apiClient, $stateParams, base64) {
                        return apiClient.get(base64.decode($stateParams.jobRequisiteResource));
                    }
                },
                ncyBreadcrumb: '{{ ::"JOB_REQUISITE_APPROVE" | translate }}'
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.requisites.reject', {
                url: '/{jobRequisiteResource}/reject',
                parent: 'dashboard.user.sites.list.details.jobs.list.details.requisites',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/jobs/details/requisites/workflow.html',
                        controller: 'CommonJobRequisitesWorkflowController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    jobRequisiteAnswerResponse: function(apiClient, $stateParams, base64) {
                        return apiClient.get(base64.decode($stateParams.jobRequisiteResource));
                    }
                },
                ncyBreadcrumb: '{{ ::"JOB_REQUISITE_REJECT" | translate }}'
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.requisites.escalate', {
                url: '/{jobRequisiteResource}/escalate',
                parent: 'dashboard.user.sites.list.details.jobs.list.details.requisites',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/jobs/details/requisites/workflow.html',
                        controller: 'CommonJobRequisitesWorkflowController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    jobRequisiteAnswerResponse: function(apiClient, $stateParams, base64) {
                        return apiClient.get(base64.decode($stateParams.jobRequisiteResource));
                    }
                },
                ncyBreadcrumb: '{{ ::"JOB_REQUISITE_ESCALATE" | translate }}'
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.service-routine-lines', {
                url: '/service-routine-lines?serviceRoutineLinePage&serviceRoutineLineLimit',
                parent: 'dashboard.user.sites.list.details.jobs.list.details',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/service-routines/lines/service-routine-lines-list.html',
                        controller: 'CommonServiceRoutineLinesController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    serviceRoutineLinePage: '1',
                    serviceRoutineLineLimit: '30'
                },
                resolve: {
                    serviceRoutine: function(apiClient, jobResponse) {
                        if (typeof jobResponse._links.serviceroutine !== 'undefined') {
                            return apiClient.get(jobResponse.getLink('serviceroutine'));
                        }

                        return {};
                    },
                    serviceRoutineLines: function(apiClient, serviceRoutine, $stateParams) {
                        if (typeof serviceRoutine._links.lines !== 'undefined') {

                            return apiClient.get(serviceRoutine.getLink('lines'), angular.extend({}, $stateParams, {
                                page: $stateParams.serviceRoutineLinePage,
                                limit: $stateParams.serviceRoutineLineLimit
                            })).then(function(response) {
                                if (response) {
                                    return response;
                                }
                            });
                        }

                        return {};
                    },
                    event: function(apiClient, jobResponse) {
                        if (typeof jobResponse._links.taskevent !== 'undefined') {
                            return apiClient.get(jobResponse.getLink('taskevent'));
                        }

                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_ROUTINE_LINES" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.links-to', {
                url: '/links-to',
                parent: 'dashboard.user.sites.list.details.jobs.list.details',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/jobs/details/links-to/links-to.html',
                        controller: 'CommonLinksToController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    canCreateJobs: function(jobCollectionResponse) {
                        return jobCollectionResponse.canCreate;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"LINKS_TO" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.remedials', {
                url: '/remedials?remedialsPage&remedialsLimit',
                parent: 'dashboard.user.sites.list.details.jobs.list.details',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/jobs/details/remedials/remedials.html',
                        controller: 'CommonRemedialsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    remedialsPage: '1',
                    remedialsLimit: '10'
                },
                resolve: {
                    jobRemedialsCollection: function (apiClient, jobResponse, $stateParams) {
                        var params = {
                            page: $stateParams.remedialsPage,
                            limit: $stateParams.remedialsLimit
                        };
                        if (jobResponse.getLink('remedials')) {
                            return apiClient.get(jobResponse.getLink('remedials'), params).then(function(response) {
                                return response;
                            });
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"REMEDIALS" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.explicit-access', {
                url: '/explicit-access?explicitPage&explicitLimit',
                parent: 'dashboard.user.sites.list.details.jobs.list.details',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/jobs/details/explicit-access/explicit-access-list.html',
                        controller: 'CommonExplicitAccessController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    explicitPage: '1',
                    explicitLimit: '10'
                },
                resolve: {
                    explicitAccessCollectionResponse: function (apiClient, jobResponse, $stateParams) {
                        var params = {
                            page: $stateParams.explicitPage,
                            limit: $stateParams.explicitLimit
                        };
                        return apiClient.getPage(jobResponse.getLink('explicit-access'), params);
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EXPLICIT_ACCESS" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.explicit-access.add', {
                url: '/add',
                parent: 'dashboard.user.sites.list.details.jobs.list.details.explicit-access',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/jobs/details/explicit-access/explicit-access-add-edit.html',
                        controller: 'CommonExplicitAccessAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    userCollectionResponse: function (apiClient, user, jobResponse, siteResponse) {
                        return apiClient.get(user.getLink('users'), { explicitAccess:true, status:'active', jobId:jobResponse.id });
                    },
                    explicitAccessUserResponse: function() {
                        return false;
                    },
                    config: function() {
                        return {
                            edit: false
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EXPLICIT_ACCESS_ADD" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.explicit-access.edit', {
                url: '/{explicitResource}/edit',
                parent: 'dashboard.user.sites.list.details.jobs.list.details.explicit-access',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/jobs/details/explicit-access/explicit-access-add-edit.html',
                        controller: 'CommonExplicitAccessAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    userCollectionResponse: function (apiClient, user) {
                        return apiClient.get(user.getLink('users'));
                    },
                    explicitAccessUserResponse: function(apiClient, base64, $stateParams) {
                        return apiClient.get(base64.decode($stateParams.explicitResource));
                    },
                    config: function() {
                        return {
                            edit: true
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EXPLICIT_ACCESS_EDIT" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.labour-lines', {
                url: '/labour-lines?labourLinePage&labourLineLimit',
                parent: 'dashboard.user.sites.list.details.jobs.list.details',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/labour-lines/labour-lines-list.html',
                        controller: 'CommonLabourLinesListController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    labourLinePage: '1',
                    labourLineLimit: '30'
                },
                resolve: {
                    jobLabourLinesCollectionResponse: function(apiClient, jobResponse, $stateParams) {
                        return apiClient.get(jobResponse.getLink('job-labours'), angular.extend({}, $stateParams, {
                            page: $stateParams.labourLinePage,
                            limit: $stateParams.labourLineLimit
                        })).then(function(response) {
                            if (response) {
                                return response;
                            }
                        });
                    },
                    labourTypeCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('labourtypes')) {
                            return apiClient.get(rootResourceResponse.getLink('labourtypes')).then( function(response) {
                                return response || null;
                            });
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"LABOUR_LINES" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.labour-lines.add', {
                url: '/add',
                parent: 'dashboard.user.sites.list.details.jobs.list.details.labour-lines',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/labour-lines/add-edit/labour-lines-add-edit.html',
                        controller: 'CommonLabourLinesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    jobCompleteLabourLinesController: () => false,
                    userResponse: function(user) {
                        return user;
                    },
                    jobLabourResponse: function() {
                        return {};
                    },
                    serviceProviderResponse: function(apiClient, jobResponse) {
                        return apiClient.get(jobResponse.getLink('serviceprovider'), {datagroup: 'details'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    operativeCollectionResponse: function(apiClient, serviceProviderResponse, userResponse, jobResponse) {
                        // Fetch operatives for additional operative
                        if (!serviceProviderResponse) {
                            if (userResponse.getLink('operatives')) {
                                return apiClient
                                    .get(userResponse.getLink('operatives'), { permission: 'operative_permission_receive_jobs', jobAdditionalOperatives: jobResponse.id })
                                    .then(function(response) {
                                        return response || apiClient.noResourceFound();
                                    });
                            }
                        }
                        else if (serviceProviderResponse.isShared) {
                            if (userResponse.getLink('operatives')) {
                                return apiClient
                                    .get(userResponse.getLink('operatives'), { serviceProviderId: serviceProviderResponse.id, permission: 'operative_permission_receive_jobs' })
                                    .then(function(response) {
                                        return response || apiClient.noResourceFound();
                                    });
                            }
                        } else if (serviceProviderResponse.getLink('joboperatives')) {
                            return apiClient.get(serviceProviderResponse.getLink('joboperatives')).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        } else if (jobResponse.additionalOperatives.length && userResponse.getLink('operatives')) {
                            return apiClient
                                    .get(userResponse.getLink('operatives'), { permission: 'operative_permission_receive_jobs', jobAdditionalOperatives: jobResponse.id })
                                    .then(function(response) {
                                        return response || apiClient.noResourceFound();
                                    });
                        }

                        return [];
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.labour-lines.edit', {
                url: '/{jobLabourResource}/edit',
                parent: 'dashboard.user.sites.list.details.jobs.list.details.labour-lines',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/labour-lines/add-edit/labour-lines-add-edit.html',
                        controller: 'CommonLabourLinesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    jobCompleteLabourLinesController: () => false,
                    userResponse: function(user) {
                        return user;
                    },
                    jobLabourResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.jobLabourResource.decode()).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    serviceProviderResponse: function(apiClient, jobResponse) {
                        return apiClient.get(jobResponse.getLink('serviceprovider'), {datagroup: 'details'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    operativeCollectionResponse: function(apiClient, serviceProviderResponse, userResponse, jobResponse) {
                        // Fetch operatives for additional operative
                        if (!serviceProviderResponse) {
                            if (userResponse.getLink('operatives')) {
                                return apiClient
                                    .get(userResponse.getLink('operatives'), { permission: 'operative_permission_receive_jobs', jobAdditionalOperatives: jobResponse.id })
                                    .then(function(response) {
                                        return response || apiClient.noResourceFound();
                                    });
                            }
                        }
                        else if (serviceProviderResponse.isShared) {
                            if (userResponse.getLink('operatives')) {
                                return apiClient
                                    .get(userResponse.getLink('operatives'), { serviceProviderId: serviceProviderResponse.id, permission: 'operative_permission_receive_jobs' })
                                    .then(function(response) {
                                        return response || apiClient.noResourceFound();
                                    });
                            }
                        } else if (serviceProviderResponse.getLink('joboperatives')) {
                            return apiClient.get(serviceProviderResponse.getLink('joboperatives')).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        } else if (jobResponse.additionalOperatives.length && userResponse.getLink('operatives')) {
                            return apiClient
                                    .get(userResponse.getLink('operatives'), { permission: 'operative_permission_receive_jobs', jobAdditionalOperatives: jobResponse.id })
                                    .then(function(response) {
                                        return response || apiClient.noResourceFound();
                                    });
                        }

                        return [];
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.forms', {
                url: '/forms?formsPage&formsLimit&formsOrder&formsSummary&formsDescription',
                parent: 'dashboard.user.sites.list.details.jobs.list.details',
                params: {
                    formsPage: '1',
                    formsLimit: '10',
                    formsOrder: 'summary',
                    formsSummary: null,
                    formsDescription: null,
                },
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/forms/list/forms-list.html',
                        controller: 'CommonFormsListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    formVersionInstanceCollectionResponse: function($stateParams, apiClient, jobResponse) {
                        var params = {
                            page: $stateParams.formsPage,
                            limit: $stateParams.formsLimit,
                            order: $stateParams.formsOrder,

                            summary: $stateParams.formsSummary,
                            description: $stateParams.formsDescription,
                        };

                        return apiClient
                            .get(jobResponse.getLink('forms'), params)
                            .then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"FORMS" | translate }}',
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.forms.add', {
                url: '/add',
                parent: 'dashboard.user.sites.list.details.jobs.list.details.forms',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/forms/add/form-form.html',
                        controller: 'CommonFormsAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    formCollectionResponse: function(apiClient, rootResourceResponse, jobResponse) {
                        var params = {
                            type: 'job',
                            active: true,
                            fvStatus: 1,
                            excludeJobId: jobResponse.id,
                        }
                        return apiClient.get(rootResourceResponse.getLink('forms'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}',
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.forms.details', {
                url: '/{formVersionInstanceResource}',
                parent: 'dashboard.user.sites.list.details.jobs.list.details.forms',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/forms/details/details.html',
                        controller: 'CommonFormsDetailsController',
                        controllerAs: 'vm',
                    },
                },
                resolve: {
                    formVersionInstanceResponse: function($stateParams, apiClient) {
                        return apiClient
                            .get($stateParams.formVersionInstanceResource.decode(), {datagroup: 'details'})
                            .then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    assetResponse: function (apiClient, jobResponse) {
                        if (jobResponse.getLink('asset')) {
                            return apiClient.get(jobResponse.getLink('asset'), {active:1}).then(function (response) {
                                return response;
                            });
                        }

                        return {};
                    },
                    taskEventResponse: function (apiClient, jobResponse) {
                        if (jobResponse.getLink('taskevent')) {
                            return apiClient.get(jobResponse.getLink('taskevent'), {}).then(function (response) {
                                return response;
                            });
                        }

                        return null;
                    },
                    taskResponse: function (apiClient, taskEventResponse) {
                        if (taskEventResponse && taskEventResponse.getLink('task')) {
                            return apiClient.get(taskEventResponse.getLink('task'), {}).then(function (response) {
                                return response;
                            });
                        }

                        return {};
                    },
                    associatedEntities: function(jobResponse, assetResponse, taskResponse) {
                        return {
                            job: jobResponse,
                            asset: assetResponse,
                            task: taskResponse
                        };
                    },
                    config: function() {
                        return {
                            mode: 'render-preview'
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().formVersionInstanceResponse._embedded.form.summary }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.forms.details.edit', {
                url: '/edit',
                parent: 'dashboard.user.sites.list.details.jobs.list.details.forms.details',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/forms/details/details.html',
                        controller: 'CommonFormsDetailsController',
                        controllerAs: 'vm',
                    },
                },
                resolve: {
                    config: function($state) {
                        return {
                            mode: 'render',
                        };
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}',
                },
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.sub-assets', {
                url: '/sub-assets?subAssetPage&subAssetLimit&subAssetOrder&subAssetName&subAssetReference',
                parent: 'dashboard.user.sites.list.details.jobs.list.details',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/asset/manage/details/sub-assets/sub-assets-list.html',
                        controller: 'UserAssetsSubAssetsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    subAssetPage: '1',
                    subAssetLimit: '30',
                    subAssetOrder: 'name',
                    subAssetName: null,
                    subAssetReference: null,
                },
                resolve: {
                    assetResponse: function (apiClient, jobResponse) {
                        if (jobResponse.getLink('asset')) {
                            return apiClient.get(jobResponse.getLink('asset')).then(function (response) {
                                return response;
                            });
                        }

                        return {};
                    },
                    subAssetCollectionResponse: function (apiClient, assetResponse, $stateParams) {
                        return apiClient.get(assetResponse.getLink('subassets'), angular.extend({}, $stateParams, {
                            page: $stateParams.subAssetPage,
                            limit: $stateParams.subAssetLimit,
                            order: $stateParams.subAssetOrder,
                            name: $stateParams.subAssetName,
                            reference: $stateParams.subAssetReference
                        })).then(function (response) {
                            if (response) {
                                return response;
                            }
                        });
                    },
                    config: function() {
                        return {
                            entityName: 'JOB'
                        };
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SUB_ASSETS" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.sub-assets.details', {
                url: '/details/{subAssetResource}',
                abstract: true,
                parent: 'dashboard.user.sites.list.details.jobs.list.details.sub-assets',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/asset/manage/details/sub-assets/details/sub-asset-details.html',
                        controller: 'UserAssetsSubAssetDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    assetResponse: function (assetResponse) {
                        return assetResponse;
                    },
                    subAssetResponse: function (apiClient, $state, messenger, $stateParams) {
                        return apiClient.get($stateParams.subAssetResource.decode()).then(function (response) {
                            if (response) {
                                return response;
                            }
                        });
                    },
                    locationResponse: function (apiClient, subAssetResponse) {
                        if (typeof subAssetResponse._links.location != 'undefined') {
                            return apiClient.get(subAssetResponse.getLink('location'));
                        }

                        return {};
                    },
                    sectionResource: function() {
                        return {
                            infoSref: 'dashboard.user.sites.list.details.jobs.list.details.sub-assets.details.info',
                            listSref: 'dashboard.user.sites.list.details.jobs.list.details.sub-assets'
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().subAssetResponse.name }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.sub-assets.details.info', {
                url: '/info',
                parent: 'dashboard.user.sites.list.details.jobs.list.details.sub-assets.details',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/asset/manage/details/sub-assets/info/sub-asset-info.html',
                        controller: 'UserSubAssetsInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.materials', {
                url: '/materials?materialsPage&materialsLimit&materialsDescription&supplier&purchaseOrder&costType',
                parent: 'dashboard.user.sites.list.details.jobs.list.details',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/jobs/details/materials/materials.html',
                        controller: 'CommonJobDetailsMaterialsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    materialsPage: '1',
                    materialsLimit: '30',
                    materialsDescription: null,
                    supplier: null,
                    purchaseOrder:null,
                    costType: null
                },
                resolve: {
                    materialLineCollectionResponse: function(apiClient, jobResponse, $stateParams) {
                        var params = angular.extend({}, $stateParams,
                            {
                                page: $stateParams.materialsPage,
                                limit: $stateParams.materialsLimit,
                                datagroup: 'list',
                                description: $stateParams.materialsDescription,
                                supplier:$stateParams.supplier ? JSON.parse($stateParams.supplier).v : null
                            });

                        return apiClient.get(jobResponse.getLink('material-lines'), params).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    supplierCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('suppliers'), {
                            active: true,
                            order: 'name'
                        }).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"JOB_MATERIALS" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.materials.add', {
                url: '/add',
                parent: 'dashboard.user.sites.list.details.jobs.list.details.materials',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/jobs/details/materials/add/materials-add.html',
                        controller: 'CommonJobMaterialAddEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.purchase-orders', {
                url: '/purchase-orders',
                parent: 'dashboard.user.sites.list.details.jobs.list.details',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/jobs/details/purchase-orders/purchase-orders.html',
                        controller: 'CommonPurchaseOrdersController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    purchaseOrdersPage: '1',
                    purchaseOrdersLimit: '30',
                    jobId: null
                },
                resolve: {
                    purchaseOrdersCollectionResponse: function(jobResponse, apiClient, $stateParams) {
                        var params = {
                            page: $stateParams.purchaseOrdersPage,
                            limit: $stateParams.purchaseOrdersLimit,
                            jobId: jobResponse.id
                        };

                        if (jobResponse.getLink('purchase-orders')) {
                            return apiClient.get(jobResponse.getLink('purchase-orders'), params).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        } else {
                            return apiClient.noResourceFound();
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PURCHASE_ORDERS" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.purchase-orders.add', {
                url: '/add',
                parent: 'dashboard.user.sites.list.details.jobs.list.details.purchase-orders',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/user/finance/purchase-orders/add-edit/add-edit.form.html',
                        controller: 'PurchaseOrderAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    purchaseOrderResponse: function() {
                        return {};
                    },
                    suppliersCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('suppliers'), {
                            active: true,
                            order: 'name'
                        }).then(function(response) {
                            return response || apiClient.noResourceFound();

                        });
                    },
                    config: function(jobResponse) {
                        return {
                            job: jobResponse.getLink('self')
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.job-costing', {
                url: '/job-costing',
                parent: 'dashboard.user.sites.list.details.jobs.list.details',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/jobs/details/job-costing/job-costing.html',
                        controller: 'CommonJobDetailsJobCostingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    jobResponse: function(jobResponse) {
                        return jobResponse;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"JOB_COSTING" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.jobs.list.details.job-four-d', {
                url: '/job-four-d',
                parent: 'dashboard.user.sites.list.details.jobs.list.details',
                views: {
                    'info@dashboard.user.sites.list.details.jobs.list.details': {
                        templateUrl: '/modules/common/jobs/details/job-four-d/job-four-d.html',
                        controller: 'CommonJobDetailsJobFourDController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    jobResponse: function(jobResponse) {
                        return jobResponse;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"JOB_FOUR_D" | translate }}'
                }
            });
    }

    function registerRoutesDetailsWorkflow($stateProvider) {
        elbJobWorkflowRouting().addRoutes($stateProvider, 'dashboard.user.sites.list.details.jobs.list.details.status', false);
    }

})();
