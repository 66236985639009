(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('nl', {
            SUMMARY: 'Overzicht',

            WORKFLOW: 'Workflow',
            APPROVALS_REQUIRED: 'Openstaande goedkeuringen',

            ENTER_DETAILS_MANUALLY: 'Voeg gegevens handmatig toe',
            PHONE_NUMBER: 'Telefoonnummer',
            RELATIONSHIP_TO_THE_PROPERTY: 'Relatie tot gebouw',
            NOTIFY_ON_CREATION: 'Waarschuw bij aanmaken',
            NOTIFY_ON_COMPLETION: 'Waarschuw bij afronding',
            NOTIFY_ON_ACCEPTANCE: 'Waarschuw bij goedkeuring',

            REPORTER_NAME: 'Naam verslaggever',

            EDIT_ALARM: 'Wijzig Alarm',
            SET_ALARM: 'Alarm installeren',
            ALARM_LOCKED: 'Vergrendeld',

            UNLIMITED: 'Unlimited',

            COLOUR: 'Markeren',
            NONE_SET: 'Niets geselecteerd',
            COUNT: 'Count',

            DESCRIPTION: 'Omschrijving',
            ID: 'ID',
            NO: 'Nee',
            NONE_SELECTED: 'Niets geselecteerd',
            PLEASE_SELECT: 'Selecteer een optie',
            NOTES: 'Notitie',
            VALUE: 'Waarde',
            YES: 'Ja',
            NAME: 'Naam',
            REFERENCE: 'Referentie',
            EXTERNAL_REFERENCE: 'Externe referentie',
            STATUS: 'Status',
            ADDRESS: 'Adres',
            PHONE: 'Telefoon',
            TYPE: 'Type',
            LOCATION: 'Locatie',
            EMAIL_ADDRESS: 'E-mail',
            EMAIL_SIGNATURE: 'Email Handtekening',
            INFO: 'Info',
            COMPANY: 'Bedrijf',
            ALL: 'Alles',
            POSITION: 'Positie',
            NEW_CUSTOMER_ACCESS: 'Toegang nieuwe klant',
            FAILED_LOGIN_ATTEMPTS: 'Mislukte toegangspogingen',
            ACCOUNT_LOCKED: 'Account geblokkeerd',
            UNLOCK_USER: 'Blokkade gebruiker opheffen',
            ACCOUNT_UNLOCKED: 'Blokkade gebruiker opgeheven',
            IS_TEMPLATE: 'Is sjabloon',
            EMAIL: 'E-mail',
            ADDRESS_LINE_1: 'Adresregel 1',
            ADDRESS_LINE_2: 'Adresregel 2',
            ADDRESS_LINE_3: 'Adresregel 3',
            ADD_ADDRESS: 'Voeg Adres toe',
            GO: 'Ga',
            REPORTER: 'Verslaggever',
            NOTE: 'Notitie',

            CURRENT_VERSION: 'Huidige Versie',
            NEW_VERSION: 'Nieuwe Versie',

            DASHBOARD: 'Dashboard',
            ADMIN: 'Admin',

            NO_NOTIFICATIONS: 'U heeft geen notificaties',
            BUDDY_SWITCHED_ON: 'Buddy Switched On',
            BUDDYING_FOR_ON: 'Buddying For On',

            ADD_SUCCESS: 'Toegevoegd',
            UPDATE_SUCCESS: 'Aangepast',
            EDIT_SUCCESS: 'Gewijzigd',
            DELETE_SUCCESS: 'Verwijderd',
            REQUEST_ERROR: 'Er is iets mis met het verzoek, probeer het later nog eens.',

            VIEW: 'Bekijken',
            ADD: 'Toevoegen',
            CREATE: 'Maken',
            EDIT: 'Wijzigen',
            UPDATE: 'Aanpassen',
            CANCEL: 'Annuleren',
            DELETE: 'Verwijderen',
            CLOSE: 'Sluiten',
            RESET: 'Resetten',
            CONFIRM: 'Bevestigen',
            PREVIOUS: 'Vorige',
            DECLINED: 'Afgewezen',
            ACTIVATE: 'Geactiveerd',
            DEACTIVATE: 'Uitgeschakeld',

            PARENT: 'Ouder',
            CHILDREN: 'Kinderen',

            IS_ACTIVE: 'Status',
            STATUS_ACTIVE: 'Actief',
            STATUS_INACTIVE: 'Niet actief',

            ARE_YOU_SURE_HEADER: 'Weet je het zeker?',
            ARE_YOU_SURE: 'Weet je het zeker dat deze actie moet worden uitgevoerd?',
            NAVIGATION_CONFIRMATION: 'Weet je zeker dat je de pagina wil verlaten? Alle wijzigingen worden niet opgeslagen.',

            BARCODE: 'Streepjescode',
            FILES: 'Bestanden',

            ALL_SERVICE_PROVIDERS: 'Alle Leveranciers',
            SELECTED_SERVICE_PROVIDERS: 'Geselecteerde Leveranciers',

            ALL_PARTNERSHIPS: 'Alle samenwerkingen',
            SELECTED_PARTNERSHIPS: 'Geselecteerde samenwerkingen',

            TITLE: 'Titel',

            FOOTER_COPYRIGHT: "Copyright &copy; Vantify Limited {{ ::currentYear }} - Reg Company No. 05277497",

            ZERO_OPTIONS_CHECKED_TITLE: 'Geen items geselecteerd',
            ZERO_OPTIONS_CHECKED_MESSAGE: 'Je moet minimaal een selectie hebben gedaan alvorens verder te gaan',


            SERVICEPROVIDER: 'Leverancier',
            STATUTORYTYPE: 'Statutair Type',
            STATUTORY_TYPE: 'Statutair Type',

            START_TYPING_TO_SEARCH: 'Start met typen om te zoeken',
            START_TYPING_TO_FILTER: 'Start met typen om te filteren',

            START_TYPING_TO_SEARCH_SITES: 'Alle objecten- Start met typen om te zoeken',

            ACTION_SUCCESS: 'Actie Succesvol',
            ACTIVE: 'Actief',
            INACTIVE: 'Niet actief',

            LOCAL_SITE_TIME: 'Lokale tijd object',

            WITH_ID: '<strong>ID</strong> {{ ::id }}',
            WITH_SITE: '<strong>Gebouw</strong> {{ ::site }}',
            WITH_NAME: '<strong>Naam</strong> {{ ::name }}',
            WITH_SUMMARY: '<strong>Overzicht</strong> {{ ::summary }}',
            WITH_REFERENCE: '<strong>Referentie</strong> {{ ::reference }}',
            WITH_CHILDREN: '<strong>Kinderen</strong> {{ ::children }}',
            WITH_ACTIVE: '<strong>Actief</strong> {{ ::(active == "true" ? "JA" : "NEE") | translate }}',

            CONTACT: 'Contact',
            CONTACTS: 'Contacten',


            BACK: 'Terug',

            NO_RESULTS_FOUND: 'Geen resultaten',
            PAGINATION_SHOWING: 'Weergave {{::from}} tot {{::to}} van {{::total}}',
            DETAILS: 'Details',

            SUBMIT: 'Indienen',

            VISIBILITY_PUBLIC: 'Publiek',
            VISIBILITY_HELPDESK: 'Helpdesk',

            INFORMATION: 'Informatie',
            SEND_EMAIL: 'Stuur E-mail',

            ATTENDANCE_DUE: 'Aanwezigheid vervallen',
            COMPLETION_DUE: 'Afronding vervallen',
            NEW_COMPLETION_DUE: 'New Completion Due',
            CREATED_AT:     'Gemaakt op',
            APPROVED_AT: 'Approved At',

            ACTION_CANCEL: 'Annuleren',
            ACTION_COMPLETE: 'Afgerond',
            ACTION_RAISE_JOB: 'Maak opdracht',
            ACTION_RAISE_QUOTE: 'Maak offerte',
            ADD_BULK_PATROL_SCHEDULE: 'Maak ronde schemas',
            ADD_CONTRACT_TYPE: 'Voeg contract type toe',
            ADD_DEPARTMENT: 'Voeg afdeling toe',
            ADD_NEW_CONTRACT_TYPE: 'Maak nieuw contract type',
            ADD_NEW_DEPARTMENT: 'Maak nieuwe afdeling',
            ADD_NEW_READING: 'Maak nieuwe opname',
            ADD_NEW_SUBMETER: 'Voeg nieuwe tussenmeter toe',
            ADD_NEW_TRADE_TYPE: 'Voeg nieuw merk toe',
            ADD_TRADE_TYPE: 'Maak merk type',
            ALL_USERS: 'Alle gebruikers',
            ASSIGNEE: 'Gevolmachtigde',
            ATTACH_A_FILE: 'Voeg bijlage toe',
            ATTACHMENT: 'Bijlage',
            ATTACHMENTS: 'Bijlagen',
            ATTENDANCE_DATE: 'Datum Aanwezigheid',
            ATTENDED: 'Aanwezig',
            BY_PLANNED_DATE: 'Op plandatum',
            CANCELLED: 'Geannuleerd',
            COMPLETED: 'Afgerond',
            COMPLETION_BY_DATE: 'Afgerond op datum',
            COMPLETION_DUE_BETWEEN: 'Afgerond tussen',
            CREATED_BY: 'Gemaakt door',
            COMPOSE_CONVERSATION: 'Maak een gesprek aan',
            CONDITION: 'Conditie',
            CONTRACT_APPROVAL: 'Contract Goedkeuring',
            CONTRACT_TERMINATED: 'Contract beeindigd',
            CONTRACT_TYPES: 'Contract Types',
            CONVERSATIONS: 'Gesprekken',
            CREATE_AS_GLOBAL_TEMPLATE: 'Maak als standaard sjabloon',
            CREATED: 'Gemaakt',
            DATE_CREATED: 'Datum gemaakt',
            DATE_READ: 'Datum gelezen',
            DELETE_SURVEY: 'Verwijder opname ',
            DEPARTMENTS: 'Afdeling',
            DONE: 'Klaar',
            EDIT_CONTRACT: 'Wijzig Contract',
            EDIT_CONTRACT_TYPE: 'Wijzig Contract Type',
            EDIT_DEPARTMENT: 'Wijzig afdeling',
            EDIT_TRADE_TYPE: 'Wijzig handels Type',
            EDITABLE: 'Aanpasbaar',
            EXPORT: 'Export',
            EXPORT_IN_PROCESS: 'Export in wachtrij',
            EXPORT_IN_PROCESS_WITH_LIMIT: 'Export in wachtrij, but data will be limited to {{ limit }} items',
            EXPORT_KPI_TREND: 'Export KPI Trend',
            EXPORT_KPI_DATA: 'Export KPI Data',
            GROUP: 'Groep',
            HARD_SERVICE: 'Hard Service',
            HELPDESK_NOTES: 'Helpdesk Notities',
            HIDE: 'Verbergen',
            JOB_HISTORY_NOTE: 'Opdrachtgeschiedenis',
            JOB_MORE_MESSAGES: 'Meer berichten',
            JOB_NEXT_PLANNED_VISIT: 'Volgend gepland bezoek',
            JOB_STATUS_: 'Status',
            LAST_MESSAGE: 'Laatste bericht',
            LAST_SUBMITTED_BETWEEN: 'Laatste bericht tussen',
            LATEST_REACTIVE_JOBS: 'Laatste correctieve opdracht',
            LEGEND_: 'Legenda',
            LOCATION_BASED_SECTION: 'Informatie voor locatie',
            MONTHS: 'Maanden',
            'N/A': 'N/A',
            NEW_JOB: 'Nieuwe opdracht',
            NEXT: 'Volgende',
            NO_ANSWER_OPTIONS: 'Geen antwoord opties',
            NO_NOTE_PROVIDED: 'Geen notitie ingegeven',
            NO_REACTIVE_JOBS: 'Geen correctieve opdrachten',
            NO_SERVICE_PROVIDER_ENTERED: 'Geen leverancier geselecteerd',
            NO_SITE_ASSOCIATES_ASSIGNED: 'Geen gebouw contacten geselecteerd',
            
            NO_DECIMAL_ALLOWED : 'Decimalen niet toegestaan',
            NON_CHARGEABLE: 'Niet verrekenbaar',
            NONE: 'Geen',
            ON_SITE_DATE: 'Datum op locatie',
            OOH_NOTES: 'OOH Notities',
            OOH_PHONE: 'OOH Telefoon',
            OPEN: 'Open',
            ORDER: 'Volgorde',
            OVERDUE_NO: 'Nee',
            OVERDUE_YES: 'Ja',
            OWNER: 'Eigenaar',
            PREV: 'Vorige',
            RAISED_BY: 'Gemaakt door',
            READING: 'Aan het lezen',
            REQUEST: 'Verzoek',
            RISK: 'Risico',
            SATISFACTION_SURVEY: 'Satisfaction Survey',
            SAVE: 'Opslaan',
            SCORE_RANGE: 'Score reeks',
            SEARCH: 'Zoeken',
            SEARCH_SITE: 'Zoek Gebouw',
            SELECT: 'Selecteer',
            
            SELECT_A_USER: 'Selecteer gebruiker',
            SELECT_CONDITION: 'Selecteer conditie',
            SELECT_DEPARTMENT: 'Selecteer afdeling',
            SELECT_RISK: 'Selecteer Risico',
            SELECT_SURVEY: 'Selecteer onderzoek',
            SELECTED_USERS: 'Geslecteerde gebruikers',
            SEND: 'Verzonden',
            SERVICE: 'Service',
            SERVICE_PROVIDED: 'Service geleverd',
            SHOW: 'Weergeven',
            SKIP_THIS_TIME: 'Sla deze tijd over',
            SOFT_SERVICE: 'Soft Service',
            STATUS_DETAILS: 'Status Details',
            SUBMITTED: 'Verzonden',
            SURVEY_RANGE: 'Reeks',
            SURVEY_WEIGHT: 'Weging',
            TARGET: 'Doel',
            TELEPHONE: 'Telefoon',
            TRADE_TYPES: 'Trade Typen',
            UNABLE_TO_CREATE_TENANT: 'Niet mogelijk huurder aan te maken',
            UNABLE_TO_GET_LOCATION: 'Niet mogelijk om locatie te verkrijgen',
            UNABLE_TO_GET_TENANT: 'Niet mogelijk om huurder te selecteren',
            UNABLE_TO_UPDATE_TENANT: 'Niet mogelijk om huurder aan te passen',
            UNSUBSCRIBE: 'Afmelden',
            VIEW_NOTIFICATIONS_UNREAD_NEWER: 'Nieuwer',
            VIEW_NOTIFICATIONS_UNREAD_OLDER: 'Ouder',
            WEBSITE: 'Website',
            WEIGHT: 'Weging',
            WEIGHTED_SCORE: 'Gewogen Score',
            WRITE_A_MESSAGE: 'Schrijf een bericht',
            FILE_ATTACHMENT: 'Bestandsbijlage',
            COPY_ME: 'Stuur een kopie naar mij',
            SUBJECT: 'Onderwerp',
            TO: 'Aan',
            EMAIL_S_SENT: 'E-mail(s) verstuurd',
            SHOW_MORE: 'Laat meer zien',
            SHOW_LESS: 'Laat minder zien',
            EDIT_RECIPIENTS: 'Wijzig ontvangers',
            RECIPIENTS: 'Ontvangers',
            VIEW_ALL: 'Laat alles zien',
            WILL_CHASE_AT: 'Opvolging op',
            EXTENSION_DATE: 'Extensie datum',
            I_AM_THE_APROVER: 'Fiatteur',
            I_AM_APPROVER: 'Fiatteur',
            MESSAGE_CREATED: 'Bericht gemaakt',
            ACCEPT: 'Accepteren',
            REJECT: 'Afwijzen',
            DUE_DATE: 'Vervaldatum',
            FEET: 'Voet',
            METRES: 'Meter',
            IMAGE_FILE: 'Afbeelding',
            NOTIFICATION_NOT_FOUND: 'Notificatie niet gevonden',
            NOTIFICATION_TYPE_NOT_FOUND: 'Type notitie niet gevonden',
            OTHER: 'Overige',
            SKIPPED_NOTIFICATION: 'Notitie overgeslagen',
            REMINDER_AT: 'Herinnering op',
            REQUESTED: 'Opgevraagd',
            INCLUDE_MY_SIGNATURE: 'Voeg handtekening toe',
            LOAD_MORE: 'Meer laden',
            AT_LEAST_ONE_ITEM_REQUIRED: 'Er is minimaal een item nodig.',
            NO_MORE_RESULTS: 'Niet meer resultaten beschikbaar.',
            MAX_NUMBER_SELECTED: 'Selecteer maximum aantal items.',
            EXPIRED: 'Vervallen',
            VALID: 'Geldig',
            REVOKE: 'Intrekken',
            REVOKED: 'Ingetrokken',
            INDEFINITE: 'Onbepaald',
            FOR_REVIEW: 'ter beoordeling',
            PAPERWORK: 'Papierwerk',
            CREATED_AT_BETWEEN: 'Gemaakt op tussen',
            DATE_COMPLETE_AT: 'Afgerond op',
            COPIED_TO_CLIPBOARD: 'Gekopieerd naar Klembord',
            BROWSER_UNSUPPORTED: 'Deze browser(versie) wordt niet ondersteund',
            IMAGES_UPDATED: 'Afbeeldingen bijgewerkt',
            IMAGE_DELETE: 'Afbeelding verwijderd',
            IMAGE_UPLOAD: 'Afbeelding geupload',
            IMAGE_UPDATE: 'Afbeelding aangepast',
            NO_IMAGE_SELECTED: 'Geen afbeelding geselecteerd',
            SET_MAIN_IMAGE: 'Voorkeursafbeelding',
            INCORRECT_FILE_TYPE: 'Geen geldig fileformat',
            INVALID_PERMISSIONS: 'Ongeldige rechten',
            NO_RESOURCE_FOUND: 'Geen bron gevonden',
            FILTERED: 'Gefilterd',
            SELECTED: 'Geselecteerd',
            APPROVER_IS_NOT_SET: 'Goedkeuring nodig maar niemand om goed te keuren ingesteld',
            SELECT_AT_LEAST_ONE_SITE: 'Er is minimaal 1 object nodig',
            SELECTALLSITES: 'Selecteer alle objecten',
            TOTALS: 'Totalen',
            PLANNED_JOBS: 'Planmatige opdrachten',
            REACTIVE_JOBS: 'Correctieve opdrachten',
            REPORT_ON: 'Rapporteren op',
            TABLE_ACTIONS: 'Overzicht Acties',
            EXPAND_ALL: 'Uitklappen',
            COLLAPSE_ALL: 'Inklappen',
            EXPIRED_AT: 'Verlopen op',
            REJECTED_AT: 'Verworpen op',
            DECLINED_AT: 'Afgewezen op',
            ACCEPTED_AT: 'Geaccepteerd op',
            FIELD_WITH_MAXIMUM_LENGTH: '{{ fieldName }} Maximum lengte is {{ limit }} karakters'
        });
    }
})();
