(function () {
    'use strict';

    angular
        .module('elogbooks.admin.document-types-management')
        .controller('DocumentTypesListController', DocumentTypesListController);

    DocumentTypesListController.$inject = [
        '$state',
        '$stateParams',
        'documentTypeCollectionResponse',
        'statutoryTypeCollectionResponse',
        'paramConverterService',
        'lodash',
        '$translate'
    ];

    function DocumentTypesListController(
        $state,
        $stateParams,
        documentTypeCollectionResponse,
        statutoryTypeCollectionResponse,
        paramConverterService,
        lodash,
        $translate
    ) {
        var vm = this;
        vm.clear = clear;
        
        vm.exportParams = angular.extend({}, $stateParams, {});
        Object.keys(vm.exportParams).forEach(key => {
            if (vm.exportParams[key] === 'all') {
              vm.exportParams[key] = null;
            } else if(key === 'criticality' || key === 'documentStatutoryType') {
                vm.exportParams[`${key}[]`] = vm.exportParams[key];
            }
          });
          
        vm.params = {};

        vm.documentTypes = documentTypeCollectionResponse.documentTypes;
        vm.documentTypeCollectionResponse = documentTypeCollectionResponse;

        vm.order = order;
        vm.search = search;
        vm.recurrenceModel = getRecurrence();

        var statuses = [
            {
                title: $translate.instant('STATUS_ACTIVE'),
                value: 'active'
            },
            {
                title: $translate.instant('STATUS_INACTIVE'),
                value: 'inactive'
            },
            {
                title: $translate.instant('NONE_SELECTED'),
                value: null
            }
        ];

        var criticalityOptions = [
            { value: 'high', title: $translate.instant('CRITICALITY_HIGH') },
            { value: 'medium', title: $translate.instant('CRITICALITY_MEDIUM') },
            { value: 'low', title: $translate.instant('CRITICALITY_LOW') },
        ];

        var approvalOptions = [
            { title: $translate.instant('ALL'), value: 'all' },
            { title: $translate.instant('YES'), value: 'yes' },
            { title: $translate.instant('NO'), value: 'no' }
        ];   
        
        function getRecurrence() {
           return [
                {
                    labelKey: 'INTERVAL',
                    modelKey: 'interval',
                    type: 'number',
                    min: 1,
                    max: 999999,
                    required: true,
                },
                {
                    labelKey: 'FREQUENCY',
                    modelKey: 'frequency',
                    type: 'select',
                    placeholderKey: 'NONE_SELECTED',
                    options: [
                        { value: 'weekly', labelKey: 'FREQUENCY_WEEKLY' },
                        { value: 'monthly', labelKey: 'FREQUENCY_MONTHLY' },
                        { value: 'yearly', labelKey: 'FREQUENCY_YEARLY' }
                    ]
                },
            ];
        }

        // Validate and update params with recurrenceModel
        function updateRecurrence() {
            const { selected, frequency, interval } = vm.recurrenceModel;
            if (selected !== null && (frequency == null || interval == null)) {
                return false;
            }

            vm.params.recurrence = selected === null ? null : JSON.stringify({ frequency, interval });
            return true;
        }      
    
        const recurrenceOptions = (() => {
            const { interval = null, frequency = null } = $stateParams.recurrence ? JSON.parse($stateParams.recurrence) : {};
            return {
                interval,
                frequency
            }
        })();

        vm.criteria = {
            name: {
                type: 'text',
                value: $stateParams.name,
                title: 'NAME'
            },
            criticality: {
                type: 'options',
                multiple: true,
                title: 'CRITICALITY',
                value: $stateParams.criticality,
                options: criticalityOptions
            },
            status: {
                type: 'options',
                title: 'IS_ACTIVE',
                value: $stateParams.status,
                options: statuses
            },
            recurrence: {
                type: 'recurringInterval',
                title: 'RECURRENCE',
                value: JSON.parse($stateParams.recurrence),
                options: recurrenceOptions,
                fields: vm.recurrenceModel,
                formattedValue: ({ interval, frequency }) => {
                    return frequency ? interval + ' ' + $translate.instant('FREQUENCY_' + frequency.toUpperCase()) : null;
                }
            },
            documentStatutoryType: {
                type: 'options',
                title: 'STATUTORY_TYPE',
                multiple: true,
                value: paramConverterService.checkStateParams($stateParams.documentStatutoryType),
                options: getStatTypes()
            },
            approvalRequired: {
                type: 'options',
                options: approvalOptions,
                title: 'APPROVAL_REQUIRED',
                value: $stateParams.approvalRequired,
                clearValue: 'all'
            }
        };

        function search(params) {
            var override = {
                page: 1
            };

            if (updateRecurrence()) {
                params = angular.extend({}, params, vm.params);
            }

            $state.go('.', angular.extend({}, params, override), {reload: $state.current});
        };

        function order(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), {reload: $state.current});
        };

        function getStatTypes() {
            return paramConverterService.formatResponse(statutoryTypeCollectionResponse.statutorytypes);
        }   
        
        function clear() {
            var clearValues = {};
            lodash.each($stateParams, function(value, key) {
                if (value) {
                    clearValues[key] = null;
                }
            });

            $state.go('.', angular.extend({}, clearValues), { reload: true });
        }
    }
})();
