(function () {
    'use strict';

    angular
        .module('elogbooks.common.files')
        .controller('CommonFilesListController', CommonFilesListController);

    CommonFilesListController.$inject = [
        '$stateParams',
        '$state',
        'fileCollectionResponse',
        'selectedSiteResponse',
        '$translate',
        'config',
        'lodash',
        'apiClient',
        'messenger',
        'elbDownloadService',
        'rootResourceResponse',
        'user',
        'siteCollectionResponse',
        'documentTypeCollectionResponse',
        'jobResponse',
        'serviceProviderResponse',
        'operativeCollectionResponse',
        'analyticsService',
        '$scope'
    ];

    function CommonFilesListController (
        $stateParams,
        $state,
        fileCollectionResponse,
        selectedSiteResponse,
        $translate,
        config,
        lodash,
        apiClient,
        messenger,
        elbDownloadService,
        rootResourceResponse,
        user,
        siteCollectionResponse,
        documentTypeCollectionResponse,
        jobResponse,
        serviceProviderResponse,
        operativeCollectionResponse,
        analyticsService,
        $scope
    ) {
        var vm = this;
        vm.job = jobResponse;
        vm.fileCollectionResponse = fileCollectionResponse;
        vm.entityCount = fileCollectionResponse.count;
        vm.selectedSiteResponse = selectedSiteResponse;
        vm.config = config;
        vm.config.overrideRoute = vm.config.overrideRoute || false;
        vm.download = elbDownloadService.download;

        var deregisterWatch = $scope.$watch('vm.fileCollectionResponse', function(value) {
            analyticsService.pageLoaded();
            deregisterWatch();
        });

        if (fileCollectionResponse.getLink('notify-expiry')) {
            vm.showNotifyExpiry = true;
        }
        vm.updatingNotifyExpiry = false;
        vm.isAllSelected = false;
        vm.notifyExpiryUpdatedCount = 0;

        vm.notifyExpiryOriginal = lodash.filter(vm.fileCollectionResponse.files, { 'notifyExpiry': true });
        vm.notifyExpiryAdditions = [];
        vm.notifyExpiryRemovals = [];

        vm.toggleAll = toggleAll;
        vm.toggleSelected = toggleSelected;
        vm.notifyExpiry = notifyExpiry;
        vm.moment = moment;
        vm.stateParams = $stateParams;

        vm.search = searchAction;
        vm.order = orderAction;
        vm.getIntegrationUploadStatus = getIntegrationUploadStatus;
        vm.showIntegrateButton = true;
        vm.resources = {
            'rootResource': rootResourceResponse,
            'stateParams': $stateParams,
            'root':rootResourceResponse,
            'user': user
        };
        vm.tabTitle = config.title ? $translate.instant(config.title) : null;

        var yesNoOptions = [
            {
                title: $translate.instant('NONE_SELECTED'),
                value: null
            },
            {
                title: $translate.instant('YES'),
                value: 'true'
            },
            {
                title: $translate.instant('NO'),
                value: 'false'
            }
        ];

        var activeInactiveOptions = [
            {
                title: $translate.instant('Active'),
                value: 'Active'
            },
            {
                title: $translate.instant('Inactive'),
                value: 'Inactive'
            }
        ];

        vm.tagSelect = {
            link : rootResourceResponse.getLink('tags'),
            responseKeyPath: 'tags',
            itemValuePath: 'name',
            itemHrefPath: 'id',
        };

        vm.criteria = {
            fileId: { type: 'number', value: $stateParams.fileId ? parseInt($stateParams.fileId, 10) : null, title: 'ID', min: 1 },
            fileTitle: { type: 'text', value: $stateParams.fileTitle, title: 'NAME'},
            fileExpiresDateStart: { type: 'date', value: $stateParams.fileExpiresDateStart, title: 'EXPIRES_DATE_START', maxDependsOn: 'fileExpiresDateEnd' },
            fileExpiresDateEnd: { type: 'date', value: $stateParams.fileExpiresDateEnd, title: 'EXPIRES_DATE_END', minDependsOn: 'fileExpiresDateStart' },
            fileTags:{ type: 'autocompletemulti', multiple: true, title: 'TAGS', value: $stateParams.fileTags, options: vm.tagSelect, param: 'fileTags', max: 50 }
        };

        if (vm.config.showStatutoryType || vm.config.isEntityAttachments) {
            vm.criteria.fileIsStatutory = { type: 'options', title: 'IS_STATUTORY_FILE', value: $stateParams.fileIsStatutory, options: yesNoOptions };
        }

        if (vm.config.showJobFiles && user.getLinkAttribute('files', 'canSeeJobFiles')) {
            vm.criteria.fileIsJobFile = { type: 'options', title: 'JOB_FILE', value: $stateParams.fileIsJobFile, options: yesNoOptions };
        }

        if (documentTypeCollectionResponse && documentTypeCollectionResponse.count > 0) {

            var selected = $stateParams.documentType ? {
                value: JSON.parse($stateParams.documentType).n,
                href: parseInt(JSON.parse($stateParams.documentType).v)
            } : null;

            vm.documentTypeSelect= {
                link : documentTypeCollectionResponse.getLink('self'),
                linkParameters: { status: 'active', order: 'name', job: vm.job ? vm.job.id : null },
                responseKeyPath: 'documentTypes',
                itemValuePath: 'name',
                itemHrefPath: 'id',
                response: documentTypeCollectionResponse,
                selected: selected || null
            };

            vm.criteria.documentTypes = {
                type: 'autocompletemulti',
                multiple: true,
                title: 'DOCUMENT_TYPE',
                options: vm.documentTypeSelect,
                param: 'documentTypes',
                value: $stateParams.documentTypes,
                max: 50
            };
        }

        // Do not show the Site column on all pages except (sometimes) the global Files page
        if (
                $state.is('dashboard.user.files.manage-files.site-files-list') || $state.is('dashboard.user.files.list') ||
                $state.is('dashboard.user.files.manage-files.all-files-list') || vm.config.isEntityAttachments && vm.config.showSite != false
        ) {
            vm.siteSelect = {
                response : siteCollectionResponse,
                link : siteCollectionResponse ? siteCollectionResponse.getLink('self') : null,
                responseKeyPath: 'sites',
                itemValuePath: 'name',
                itemHrefPath: 'id',
                disabled: $stateParams.selectedSiteResource
            };

            if (selectedSiteResponse) {
                vm.siteSelect.cacheItems = [{value:selectedSiteResponse.name, href:selectedSiteResponse.id, object:selectedSiteResponse}];
            }

            vm.criteria.siteIds = { type: 'autocompletemulti', multiple: true, title: 'SITE', value: $stateParams.siteIds, options: vm.siteSelect, param: 'siteIds', max: 50 };
        } else {
            vm.selectedSiteResponse = {};
        }

        if (config.showSiteStatusFilter) {
            vm.criteria.siteStatus = {
                type: 'options',
                title: 'SITE_STATUS',
                value: $stateParams.siteStatus === null ? 'Active' : $stateParams.siteStatus,
                options: activeInactiveOptions
            };
        }

        if (config.isAudit) {
            vm.criteria = {
                auditId: { type: 'number', value: $stateParams.auditId ? parseInt($stateParams.auditId, 10) : null, title: 'ID_AUDIT', min: 1 },
                fileId: { type: 'number', value: $stateParams.fileId ? parseInt($stateParams.fileId, 10) : null, title: 'FILE_ID', min: 1 },
                auditSummary: { type: 'text', value: $stateParams.auditSummary, title: 'SUMMARY' },
                auditReference: { type: 'text', value: $stateParams.auditReference, title: 'REFERENCE' },

                documentDateRange: {
                    type: 'date-range',
                    title: 'FROM_AND_TO ',
                    value: {
                        startDate: ($stateParams.documentDateRange !== null && typeof $stateParams.documentDateRange === 'string') ? moment(new Date($stateParams.documentDateRange.split(',')[0])) : null,
                        endDate: ($stateParams.documentDateRange !== null && typeof $stateParams.documentDateRange === 'string') ? moment(new Date($stateParams.documentDateRange.split(',')[1])) : null,
                    },
                    clearValue: {
                        startDate: null,
                        endDate: null
                    }
                }
            }

            if (serviceProviderResponse) {
                vm.serviceProviderSelect = {
                    response : serviceProviderResponse,
                    link : serviceProviderResponse ? serviceProviderResponse.getLink('self') : null,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    responseKeyPath: 'serviceproviders'
                };

                vm.criteria.auditServiceProvider = {
                    type: 'jsonselectwidget',
                    title: 'SERVICE_PROVIDER',
                    value: $stateParams.auditServiceProvider ? JSON.parse($stateParams.auditServiceProvider) : null,
                    options: vm.serviceProviderSelect
                };
            }

            if (operativeCollectionResponse && operativeCollectionResponse.count) {
                vm.operativeSelect = {
                    response : operativeCollectionResponse,
                    link : operativeCollectionResponse ? operativeCollectionResponse.getLink('self') : null,
                    itemValuePath: 'id',
                    itemTitlePath: '_links.user.name',
                    responseKeyPath: 'operatives'
                };

                vm.criteria.auditOperative = {
                    type: 'jsonselectwidget',
                    title: 'OPERATIVE',
                    value: $stateParams.auditOperative ? JSON.parse($stateParams.auditOperative) : null,
                    options: vm.operativeSelect
                };
            }
        }

        function searchAction (params) {
            var override = {
                filePage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function getIntegrationUploadStatus(integration) {
            var status = {};

            switch (integration.status) {
                case 0:
                    status = {icon: 'fa fa-clock-o', title: $translate.instant(integration.type.toUpperCase() + '_UPLOAD_PENDING')};
                    break;
                case 1:
                    status = {icon: 'fa fa-check', title: $translate.instant(integration.type.toUpperCase() + '_UPLOADED')};
                    break;
                case 2:
                    status = {icon: 'fa fa-exclamation-circle', title: $translate.instant(integration.type.toUpperCase() + '_UPLOAD_ERROR')};
                    break;
            }

            return status;
        }

        function toggleAll() {
            vm.notifyExpiryAdditions = [];
            vm.notifyExpiryRemovals = [];

            lodash.forEach(vm.fileCollectionResponse.files, function(file) {
                if (file.expiresAt) {
                    if (vm.isAllSelected === true) {
                        if (!lodash.includes(vm.notifyExpiryOriginal, file)) {
                            vm.notifyExpiryAdditions.push(file._links.self);
                        }
                    } else {
                        if (lodash.includes(vm.notifyExpiryOriginal, file)) {
                            vm.notifyExpiryRemovals.push(file._links.self);
                        }
                    }

                    file.notifyExpiry = vm.isAllSelected;
                }
            });

            countNotifyExpiryUpdates();
        }

        function toggleSelected(file) {
            var expireKey;

            if (file.notifyExpiry) {
                if (!lodash.includes(vm.notifyExpiryOriginal, file) && !lodash.includes(vm.notifyExpiryAdditions, file._links.self)) {
                    vm.notifyExpiryAdditions.push(file._links.self);
                }

                expireKey = lodash.findKey(vm.notifyExpiryRemovals, ['id', file.id]);
                vm.notifyExpiryRemovals.splice(expireKey, 1);

            } else {
                if (lodash.includes(vm.notifyExpiryOriginal, file) && !lodash.includes(vm.notifyExpiryRemovals, file._links.self)) {

                    vm.notifyExpiryRemovals.push(file._links.self);
                }

                expireKey = lodash.findKey(vm.notifyExpiryRemovals, ['id', file.id]);
                vm.notifyExpiryAdditions.splice(expireKey, 1);
            }

            countNotifyExpiryUpdates();
        }

        function countNotifyExpiryUpdates() {
            vm.notifyExpiryUpdatedCount =
                vm.notifyExpiryAdditions.length +
                vm.notifyExpiryRemovals.length;
        }

        function notifyExpiry(filter) {
            vm.updatingNotifyExpiry = true;
            var data = {};

            if (filter) {
                data = {
                    filter: JSON.stringify(angular.extend({}, $stateParams, {
                        page: $stateParams.filePage,
                        limit: $stateParams.fileLimit,
                        order: $stateParams.fileOrder,
                        title: $stateParams.fileTitle,
                        uploadedBy: $stateParams.fileUploadedBy,
                        uploadedDateStart: $stateParams.fileUploadedDateStart ? new Date($stateParams.fileUploadedDateStart).toISOString() : null,
                        uploadedDateEnd: $stateParams.fileUploadedDateEnd ? new Date($stateParams.fileUploadedDateEnd).toISOString() : null,
                        expiresDateStart: $stateParams.fileExpiresDateStart ? new Date($stateParams.fileExpiresDateStart).toISOString() : null,
                        expiresDateEnd: $stateParams.fileExpiresDateEnd ? new Date($stateParams.fileExpiresDateEnd).toISOString() : null,
                        tags: $stateParams.fileTags,
                        isStatutory: $stateParams.fileIsStatutory,
                        documentTypes: $stateParams.documentTypes
                    }))
                };
            } else {
                data = {
                    _links: {
                        additions: vm.notifyExpiryAdditions.filter(Boolean),
                        removals: vm.notifyExpiryRemovals.filter(Boolean)
                    }
                };
            }

            apiClient.update(fileCollectionResponse.getLink('notify-expiry'), data).then(function() {
                $state.go('.', {}, { reload: true }).then(function() {
                    messenger.success('FILES_NOTIFY_EXPIRY_UPDATED');
                });
            });
        }

        function orderAction(key) {
            $stateParams.fileOrder = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }

        if (vm.job &&
            (
                $state.current.name === 'dashboard.user.jobs.list.details.files' ||
                $state.current.name === 'dashboard.user.sites.list.details.jobs.list.details.files' ||
                $state.current.name === 'dashboard.user.jobs.list.details.files.details'
            )
        ) {
            remainingRequiredDocTypes();
        }

        function remainingRequiredDocTypes() {
            vm.mandatoryDocTypes = [];
            vm.toRemove = [];
            vm.addedDocTypes = [];

            //Files with document type added to job before complete paperwork step
            lodash.each(vm.fileCollectionResponse.files, function (jobFile) {
                vm.addedDocTypes.push(jobFile.getLink('documentType'));
            });

            //Job's required document types
            lodash.each(vm.job.requiredDocumentTypes.documentTypes, function (jobDocType) {
                vm.mandatoryDocTypes.push({name: jobDocType.name, href: jobDocType._links.self.href});
            });

            vm.mandatoryDocTypes = lodash.sortBy(vm.mandatoryDocTypes.filter(function(jobDocType) {
                return vm.addedDocTypes.indexOf(jobDocType.href) === -1;
            }), 'name');
        }
    }
})();
