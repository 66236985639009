angular.module('elogbooksServices')
    .constant('importsFilterDefaults', { 
        defaultStatuses: ['0', '1', '2', '3', '4', '5']
    })
    .service('importsFilter', function($translate, importsFilterDefaults) {
    this.debugOptions = [
        { title: $translate.instant('ALL'), value: 'all' },
        { title: $translate.instant('YES'), value: '1' },
        { title: $translate.instant('NO'), value: '0' }
    ];

    this.statusOptions = [
        { title: $translate.instant('STATUS_PENDING'), value: '0' },
        { title: $translate.instant('STATUS_IN_PROGRESS'), value: '1' },
        { title: $translate.instant('STATUS_DONE'), value: '2' },
        { title: $translate.instant('STATUS_DONE_WITH_ERRORS'), value: '3' },
        { title: $translate.instant('STATUS_INVALID_FILE'), value: '4' },
        { title: $translate.instant('STATUS_INVALID_TYPE'), value: '5' }
    ]    
    
    this.defaultStatuses = importsFilterDefaults.defaultStatuses;
    
    return this;
});
