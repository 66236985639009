(function(){
    'use strict';

    angular
        .module('elogbooks.user.spillages')
        .controller('UserSpillageListController', UserSpillageListController);

    UserSpillageListController.$inject = ['$stateParams', '$state', 'spillageCollectionResponse', 'user','serviceProviderCollectionResponse', 'operativeCollectionResponse', '$translate'];

    function UserSpillageListController($stateParams, $state, spillageCollectionResponse, user, serviceProviderCollectionResponse, operativeCollectionResponse, $translate) {
        var vm = this;
        vm.search = search;
        vm.spillageCollectionResponse = spillageCollectionResponse;
        vm.spillages = spillageCollectionResponse.spillages;
        vm.stateParams = $stateParams;
        vm.loadedCollections = [serviceProviderCollectionResponse];

        vm.stateParams = $stateParams;

        var options = [
            {
                title: $translate.instant('NONE_SELECTED'),
                value: null
            },
            {
                title: $translate.instant('YES'),
                value: '1'
            },
            {
                title: $translate.instant('NO'),
                value: '0'
            }
        ];

        vm.operativeSelect = {
            response: operativeCollectionResponse,
            link: operativeCollectionResponse ? operativeCollectionResponse.getLink('self') : null,
            linkParameters: { permission: 'operative_permission_receive_spillages' },
            responseKeyPath: 'operatives',
            itemValuePath: '_links.user.id',
            itemTitlePath: '_links.user.name'
        };

        vm.criteria = {
            operative: { type: 'jsonselectwidget', title: 'OPERATIVE', value: JSON.parse($stateParams.operative), options: vm.operativeSelect },
            createdAtStart: { type: 'date', value: $stateParams.createdAtStart, title: 'CREATED_AT_START', maxDependsOn: 'createdAtEnd' },
            createdAtEnd: { type: 'date', value: $stateParams.createdAtEnd, title: 'CREATED_AT_END', minDependsOn: 'createdAtStart' },
            accidentOccurred: { type: 'options', title: 'ACCIDENT_OCCURRED', value: $stateParams.accidentOccurred, options: options },
            assignedToMe: { type: 'options', title: 'ASSIGNED_TO_ME', value: $stateParams.assignedToMe, options: options }
        };

        if (serviceProviderCollectionResponse) {
            vm.serviceProviderSelect = {
                response : serviceProviderCollectionResponse,
                link : serviceProviderCollectionResponse ? serviceProviderCollectionResponse.getLink('self') : null,
                itemValuePath: 'id',
                itemTitlePath: 'name',
                responseKeyPath: 'serviceproviders'
            };

            vm.criteria.serviceProvider = { type: 'jsonselectwidget', title: 'SERVICE_PROVIDER', value: JSON.parse($stateParams.serviceProvider), options: vm.serviceProviderSelect };
        }

        function search(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

    }
})();
