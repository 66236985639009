(function () {
    'use strict';

    angular
        .module('elogbooks.common.waste-management')
        .controller('WasteEntriesController', WasteEntriesController);

    WasteEntriesController.$inject = [
        '$state',
        '$stateParams',
        '$filter',
        'wasteEntryCollectionResponse',
        'serviceProviderCollectionResponse',
        'wasteTypeCollectionResponse',
        'unitCollectionResponse',
        'config',
        'lodash'
    ];

    function WasteEntriesController(
        $state,
        $stateParams,
        $filter,
        wasteEntryCollectionResponse,
        serviceProviderCollectionResponse,
        wasteTypeCollectionResponse,
        unitCollectionResponse,
        config,
        lodash
        ) {
        var vm = this;

        vm.wasteEntries = wasteEntryCollectionResponse.wasteEntries;
        vm.wasteEntryCollectionResponse = wasteEntryCollectionResponse;
        vm.config = config;

        vm.order = order;
        vm.search = search;

        vm.criteria = {
            id: { type: 'number', value: $stateParams.id ? parseInt($stateParams.id, 10) : null, title: 'ID', min: 1 },
            dateStart: { type: 'date', value: $stateParams.dateStart, title: 'DATE_START', maxDependsOn: 'dateEnd' },
            dateEnd: { type: 'date', value: $stateParams.dateEnd, title: 'DATE_END', minDependsOn: 'dateStart' },
        };

        if (serviceProviderCollectionResponse) {
            vm.serviceProviderSelect = {
                response : serviceProviderCollectionResponse,
                link : serviceProviderCollectionResponse ? serviceProviderCollectionResponse.getLink('self') : null,
                itemValuePath: 'id',
                itemTitlePath: 'name',
                responseKeyPath: 'serviceproviders'
            };
            vm.criteria.serviceProvider = {
                type: 'jsonselectwidget',
                title: 'SERVICE_PROVIDER',
                value: JSON.parse($stateParams.serviceProvider),
                options: vm.serviceProviderSelect };
        }

        if (wasteTypeCollectionResponse) {
            vm.wasteTypeSelect = {
                response : wasteTypeCollectionResponse,
                link : wasteTypeCollectionResponse ? wasteTypeCollectionResponse.getLink('self') : null,
                itemValuePath: 'id',
                itemTitlePath: 'nameAndCode',
                responseKeyPath: 'wasteTypes'
            };

            vm.criteria.wasteType = { type: 'jsonselectwidget', title: 'WASTE_TYPE', value: JSON.parse($stateParams.wasteType), options: vm.wasteTypeSelect };
        }

        if (unitCollectionResponse) {
            vm.unitSelect = {
                response : unitCollectionResponse,
                link : unitCollectionResponse ? unitCollectionResponse.getLink('self') : null,
                itemValuePath: 'id',
                itemTitlePath: 'symbol',
                responseKeyPath: 'units'
            };

            vm.criteria.unit = { type: 'jsonselectwidget', title: 'UNIT', value: JSON.parse($stateParams.unit), options: vm.unitSelect };
        }

        function search(params) {
            var override = {
                wasteManagementPage: 1
            };

            $state.go('.', angular.extend({}, params, override), {reload: $state.current});
        }

        function order(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), {reload: $state.current});
        }
    }
})();
