(function () {
    'use strict';

    angular
        .module('elogbooks.user.assets')
        .controller('CommonImportListController', ['importsCollectionResponse', '$state', '$stateParams', '$translate', 'importsFilter', CommonImportListController]);

    function CommonImportListController(importsCollectionResponse, $state, $stateParams, $translate, importsFilter) {
        var vm = this;
        vm.importsCollection = importsCollectionResponse;
        vm.imports = importsCollectionResponse.imports;
        vm.entityName = $translate.instant('IMPORTS');
        vm.importRoute = '.add';
        vm.isCurrentState = $state.current.name.indexOf('dashboard.admin.imports') > -1;
        vm.noBorder = false;

        const { status, type, debug, startDate, endDate, filename } = $stateParams;

        if (type === null) {
            vm.noType = true;
        } else if (!vm.isCurrentState) {
            vm.noBorder = true;
        }

        // Hateoas serializes templates response as an object rather than array if there's only one object in the 'collection'.
        var imports = (typeof importsCollectionResponse._links.templates.length === 'undefined') ? [importsCollectionResponse._links.templates] : importsCollectionResponse._links.templates;

        var typeOptions = [
            {
                title: $translate.instant('ALL'),
                value: 'all'
            }
        ];

        angular.forEach(imports, function(importEntity) {
            typeOptions.push({
                title: $translate.instant(importEntity.name.toUpperCase()),
                value: importEntity.name
            });
        });

        vm.search = searchAction;
        vm.criteria = {
            type: { type: 'options', title: 'TYPE', value: type, options: typeOptions, clearValue: 'all' },
            status: { type: 'options', multiple: true, title: 'STATUS', value: status, options: importsFilter.statusOptions, clearValue: importsFilter.defaultStatuses },
            startDate: { type: 'date', value: startDate, title: 'DATE_STARTED_AT',  maxDependsOn: 'endDate' },
            endDate: { type: 'date', value: endDate, title: 'DATE_ENDED_AT', minDependsOn: 'startDate' },
            filename: { type: 'text', title: 'FILE_NAME', value: filename },
            debug: { type: 'options', title: 'DEBUG', value: debug, options: importsFilter.debugOptions, clearValue: 'all' }
        };

        function searchAction (params) {
            var override = {
                page: 1
            };
            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }
    }
})();
