(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('es', {

            WASTE_MANAGEMENT: 'Gestión de residuos',
            WASTE_TYPE_LIST: 'Tipos de residuo',
            WASTE_ENTRY_LIST: 'Entradas de residuos',
            WASTE_TYPE: 'Tipo de residuo',
            TREATMENT_TYPE: 'Tipo de tratamiento',
            TREATMENT_TYPE_STATUS: 'Estado del tipo de tratamiento',
            DUPLICATED_TREATMENT_TYPE: 'Tipo de tratamiento duplicado',
            TREATMENT_TYPE_LIST: 'Tipos de tratamiento',
            TREATMENT_TARGET_LIST: 'Objetivos de los tipos de tratamiento',
            TREATMENT_TARGET_ADD: 'Añadir objetivo de tipo de tratamiento',
            SITE_TARGET: 'Objetivo del sitio',
            WASTE_TREATMENT_TYPES: 'Tipos de tratamiento de residuos',
            WASTE_TREATMENT_TARGETS: 'Objetivos de tratamiento de residuos',
            WASTE_TREATMENT_TYPES_LIST: 'Tipos de tratamiento de residuos',
            WASTE_TYPE_ADD: 'Añadir tipo de residuo',
            UNIT_ADD: 'Añadir unidad',
            TREATMENT_TYPE_ADD: 'Añadir tipo de tratamiento',
            WASTE_TYPES: 'Tipos de residuo',
            WASTETYPE: 'Tipo de residuo',
            TREATMENT_TYPES: 'Tipos de tratamiento',
            CODE: 'Código',
            WASTE_TYPE_CREATED: 'Tipo de residuo creado',
            WASTE_ENTRY_CREATED: 'Entrada de residuo creada',
            WASTE_TYPE_UPDATED: 'Tipo de residuo actualizado',
            WASTE_ENTRY_UPDATED: 'Entrada de residuo actualizada',
            WASTE_TYPE_DELETED: 'Tipo de residuo eliminado',
            WASTE_ENTRY_DELETED: 'Entrada de residuo eliminada',
            WASTE_ADD: 'Añadir tipo de residuo',
            WASTE_EDIT: 'Editar tipo de residuo',
            UNIT_EDIT: 'Editar unidad',
            TREATMENT_TYPE_EDIT: 'Editar tipo de tratamiento',
            UNITS: 'Unidades',
            TREATMENT_TYPE_CREATED: 'Tipo de tratamiento creado',
            TREATMENT_TYPE_UPDATED: 'Tipo de tratamiento actualizado',
            TREATMENT_TYPE_DELETED: 'Tipo de tratamiento eliminado',
            TARGET_DIRECTION: 'Dirección del objetivo',
            TARGET_DIRECTION_BELOW: 'Por debajo del objetivo es positivo',
            TARGET_DIRECTION_ABOVE: 'Por encima del objetivo es positivo',
            TARGET_PERCENT: 'Objetivo (%)',
            UNIT_UPDATED: 'Tipo de unidad actualizada',
            UNIT_DELETED: 'Tipo de unidad eliminada',
            UNIT_CREATED: 'Tipo de unidad creada',
            WASTE_MANAGEMENT_TARGETS: 'Objetivo de la gestión de residuos',
            UNIT_LIST: 'Unidades',
            WASTEMANAGEMENT: 'Gestión de residuos',
            DATE_START: 'Fecha de inicio',
            DATE_END: 'Fecha de fin',
            ENTRYDATE: 'Fecha de recogida',
            ENTRY_DATE: 'Fecha de recogida',
            WASTE_ENTRY_ADD: 'Añadir entrada de residuos',
            WASTE_ENTRY_EDIT: 'Editar entrada de residuos',
            WASTE_ENTRIES: 'Entradas de residuos',
            ENTRIES: 'Entradas',
            ENTRIES_IMPORT: 'Importar entradas de residuos',
            TAG_WASTEENTRY: 'Entrada de residuos',
            TREATMENT_TYPE_ADDED: 'Tipo de tratamiento añadido',
            LAST_THREE_ENTRIES: 'Últimas 3 entradas de residuos',
            DOWNOLAD_WASTE_TYPE: 'Descargar tipos de residuos»',
            DOWNOLAD_TREATMENT_TYPE: 'Descargar tipos de tratamiento»',
            DOWNOLAD_UNIT: 'Descargar unidades»',
            FILE_VISIBILITY_PUBLIC: 'Público',
            FILE_VISIBILITY_PARTNERSHIPS: 'Colaboración',
            FILE_VISIBILITY_OCCUPIERS: 'Ocupantes',
            MAX: 'Valor máx.',
            MIN: 'Valor mín.',
            REQUIRED: 'Requerido',
            NOTES_MAX_LENGTH: 'La longitud máxima del campo notas es 255',
            NAME_MAX_LENGTH: 'La longitud máxima del campo nombre es 255',
            WASTE_ENTRY_VALUE_ERROR: 'El valor total de los tipos de tratamiento no puede ser mayor que el valor de entrada de los residuos',
            USER: 'Usuario',
            USERS: 'Usuarios',
            USER_EDIT: 'Editar usuario',
            USER_LIST: 'Usuarios',
            USER_ADD: 'Añadir un nuevo usuario',
            ADD_NEW_USER: 'Añadir un usuario',
            NEW_SITE_ACCESS: 'Conceder acceso a nuevos sitios',
            USER_CREATED: 'Usuario creado',
            NEW_ROW: 'Nueva fila',
            USER_UPDATED: 'Usuario actualizado',
            USER_ROLES: 'Roles',
            ALL_ROLES: 'Todos los roles',
            SELECTED_ROLES: 'Roles seleccionados',
            CHANGE_PASSWORD: 'Cambiar contraseña',
            PASSWORD_RESET_REQUEST_SUCCESSFUL: 'Compruebe su correo electrónico para ver las instrucciones de cambio de contraseña.',
            USER_PREFERENCES_VIEW: 'Preferencias del usuario',
            USER_PREFERENCES_EDIT: 'Editar las preferencias del usuario',
            USER_PREFERENCES: 'Preferencias del usuario',
            USER_NOTIFICATION_TYPE: 'Método de notificación',
            NOTIFICATIONMETHOD: 'Método de notificación',
            EMAILCONTENTTYPE: 'Tipo de contenido de correo electrónico',
            EMAIL_CONTENT_TYPE: 'Tipo de contenido de correo electrónico',
            USER_EMAIL_CONTENT_TYPE: 'Tipo de contenido de correo electrónico',
            USER_PREFERENCE_ALL: 'todo',
            USER_PREFERENCE_NOTIFICATION: 'notificación',
            USER_PREFERENCE_EMAIL: 'correo electrónico',
            USER_PREFERENCE_TEXT: 'texto',
            USER_PREFERENCE_HTML: 'html',
            PREFERENCES_UPDATED: 'Las preferencias se han actualizado',
            USER_PREFERENCE_LOCALE: 'Lugar',
            USER_PREFERENCE_NOTIFY_ON_CREATE_JOB: 'Notificar al crear trabajo',
            USER_PREFERENCE_NOTIFY_ON_COMPLETE_PLANNED_JOB: 'Notificar al completar un trabajo planificado',
            USER_PREFERENCE_NOTIFY_ON_COMPLETE_REACTIVE_JOB: 'Notificar al completar un trabajo reactivo',
            USER_PREFERENCE_NOTIFY_ON_COMPLETE_STATUTORY_JOB: 'Notificar al completar un trabajo normativo',
            USER_PREFERENCE_NOTIFY_ON_CREATE_QUOTE: 'Notificar al crear cotización',
            USER_PREFERENCE_NOTIFY_ON_ACCEPT_QUOTE: 'Notificar al aceptar cotización',
            THIS_USER_HAS_NO_SITES: 'El usuario no tiene acceso a sitios',
            GIVE_ACCESS: 'Dar acceso',
            CHANGE_SITE_ACCESS: 'Cambiar acceso al sitio',
            GRANT_ACCESS: 'Conceder acceso',
            REVOKE_ACCESS: 'Revocar acceso',
            TO_ALL_ACTIVE_SITES: 'Para todos los sitios activos',
            SELECT_SPECIFIC_SITES: 'Seleccionar sitios específicos',
            COPY_FROM_USER: 'Copiar desde usuario',
            FROM_ALL_SITES_ON_THE_SYSTEM: 'Desde todos los sitios en el sistema',
            CONFIRM_AND_FINISH: 'Confirmar y finalizar',
            CONTINUE_AND_CHOOSE_SITES: 'Continuar y elegir sitios',
            AVAILABLE_SITES: 'Sitios disponibles',
            GRANT_ACCESS_TO: 'Conceder acceso a sitios',
            MODIFY: 'Modificar',
            SITE_PERMISSIONS: 'Permisos del sitio',
            SELECT_PERMISSIONS: 'Seleccionar permisos',
            ADD_PERMISSIONS: 'Añadir permisos',
            UPDATE_PERMISSIONS: 'Actualizar permisos',
            EXPLICIT_PERMISSIONS: 'Permisos explícitos',
            USER_HAS_NO_SITE_ACCESS: 'El usuario no tiene acceso al sitio',
            USER_PERMISSIONS: 'Permisos del usuario',
            PERMISSIONS: 'Permisos',
            PERMISSION: 'Permiso',
            REMOVE: 'Quitar',
            NO_OPERATIVES_ASSIGNED: 'No hay operativos asignados',
            SITE_PERMISSIONS_UPDATED: 'Permisos de sitio actualizados',
            USER_PERMISSIONS_UPDATED: 'Permisos de usuario actualizados',
            USER_TOKENS: 'Tokens',
            TOKEN: 'Token',
            TOKENS_LIST: 'Lista de tokens',
            EXPIRATION_DATE: 'Fecha de caducidad',
            TOKENS_ADD: 'Crear un token nuevo',
            USER_TOKEN_ADD: 'Crear token',
            TOKENS: 'Tokens',
            TOKEN_ADD: 'Crear un token nuevo',
            TOKEN_CREATED: 'Token creado',
            TOKEN_REVOKED: 'Token revocado',
            STANDARD: 'Estándar',
            MERIDIAN: 'Meridian',
            RISKWISE: 'RiskWise',
            ALCUMUS: 'Alcumus',
            PROPERTYPLUS: 'Property Plus',
            PROPERTYPLUS_LIGTAS: 'Property Plus (Ligtas)',
            QCOMPLIANCE: 'Conformidad Q',
            USED_TO_SET_API_USER_TYPE: 'Utilizado para establecer tipo de usuario API',
            INVALID_USER_TYPE: 'Tipo de usuario no válido. Es posible que esté intentando utilizar un tipo de usuario para un módulo desactivado.',
            USER_TYPE: 'Tipo',
            INVALID_TOKEN: 'Token no válido',
            ROLES_TO_MANY_MEMBERSHIPS: 'Un usuario tiene más de 100 afiliaciones en el sitio, así que sólo debe agregar o eliminar una función por solicitud.',
            SITE_ACCESS_REMOVED: 'Acceso retirado al sitio',
            CONTACT_HAS_NO_EMAIL: 'Los usuarios deben tener al menos una dirección de correo electrónico, añada una dirección de correo electrónico',
            EMAIL_NOT_VALID: 'El correo electrónico no es válido',
            USER_EDITED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> actualizó el usuario.',
            USER_EDITED_SYSTEM_ACTION: 'El sistema actualizó automáticamente el usuario.',
            ASSOCIATE_DETAILS: 'Detalles asociados',
            USER_PERMISSION_MANAGE_ASSET_TYPES: 'Gestionar tipos de activo',
            USER_PERMISSION_MANAGE_ASSOCIATE_TYPES: 'Gestionar tipos de asociado',
            USER_PERMISSION_MANAGE_CERTIFICATE_TYPES: 'Gestionar tipos de certificado',
            USER_PERMISSION_MANAGE_CHARGE_TYPES: 'Gestionar tipos de carga',
            USER_PERMISSION_MANAGE_CONTRACT_TYPES: 'Gestionar tipos de contrato',
            USER_PERMISSION_MANAGE_JOB_REQUISITES: 'Gestionar requisitos de trabajo',
            USER_PERMISSION_MANAGE_JOB_GROUPS: 'Gestionar grupos de trabajo',
            USER_PERMISSION_MANAGE_LETTERS: 'Gestionar cartas',
            USER_PERMISSION_MANAGE_METER_INFO: 'Gestionar la información del medidor',
            USER_PERMISSION_MANAGE_PRIORITIES: 'Gestionar prioridades',
            USER_PERMISSION_MANAGE_REGIONS: 'Gestionar regiones',
            USER_PERMISSION_MANAGE_ROLES: 'Trabajar con roles',
            USER_PERMISSION_MANAGE_SATISFACTION_SURVEYS: 'Gestionar encuestas de satisfacción',
            USER_PERMISSION_MANAGE_SECTORS: 'Gestionar sectores',
            USER_PERMISSION_MANAGE_SERVICE_PROVIDERS: 'Gestionar proveedores de servicios',
            USER_PERMISSION_MANAGE_SERVICE_PROVIDER_GROUPS: 'Gestionar grupos de proveedores de servicios',
            USER_PERMISSION_MANAGE_SERVICE_ROUTINES: 'Gestionar rutinas de servicio',
            USER_PERMISSION_MANAGE_SETTINGS: 'Gestionar ajustes',
            USER_PERMISSION_MANAGE_SITES: 'Gestionar sitios',
            USER_PERMISSION_MANAGE_SITE_GROUPS: 'Gestionar grupos de sitios',
            USER_PERMISSION_MANAGE_SITE_NOTE_TYPES: 'Gestionar tipos de nota del sitio',
            USER_PERMISSION_MANAGE_SITE_RELATIONSHIPS: 'Gestionar relaciones del sitio',
            USER_PERMISSION_MANAGE_SURVEYS: 'Gestionar encuestas',
            USER_PERMISSION_MANAGE_TAGS: 'Gestionar etiquetas',
            USER_PERMISSION_MANAGE_TRADE_TYPES: 'Gestionar tipos de comercio',
            USER_PERMISSION_MANAGE_USERS: 'Gestionar usuarios',
            USER_PERMISSION_MANAGE_DUTY_MANAGEMENT_SCHEDULES: 'Gestionar horarios de gestión de servicios',
            USER_PERMISSION_MANAGE_MASTER_TASKS: 'Gestionar tareas maestro',
            USER_PERMISSION_VIEW_ASSET_TYPES: 'Ver tipos de activo',
            USER_PERMISSION_VIEW_ASSOCIATE_TYPES: 'Ver tipos de asociado',
            USER_PERMISSION_VIEW_CERTIFICATE_TYPES: 'Ver tipos de certificado',
            USER_PERMISSION_VIEW_CHARGE_TYPES: 'Ver tipos de carga',
            USER_PERMISSION_VIEW_CONTRACT_TYPES: 'Ver tipos de contrato',
            USER_PERMISSION_VIEW_JOB_REQUISITES: 'Ver requisitos del trabajo',
            USER_PERMISSION_VIEW_JOB_GROUPS: 'Ver grupos de trabajo',
            USER_PERMISSION_VIEW_LETTERS: 'Ver cartas',
            USER_PERMISSION_VIEW_METER_INFO: 'Ver información del medidor',
            USER_PERMISSION_VIEW_PRIORITIES: 'Ver prioridades',
            USER_PERMISSION_VIEW_REGIONS: 'Ver regiones',
            USER_PERMISSION_VIEW_ROLES: 'Ver roles',
            USER_PERMISSION_VIEW_SATISFACTION_SURVEYS: 'Ver encuestas de satisfacción',
            USER_PERMISSION_VIEW_SECTORS: 'Ver sectores',
            USER_PERMISSION_VIEW_SERVICE_PROVIDERS: 'Ver proveedores de servicios',
            USER_PERMISSION_VIEW_SERVICE_PROVIDER_GROUPS: 'Ver grupos de proveedores de servicios',
            USER_PERMISSION_VIEW_SERVICE_ROUTINES: 'Ver rutinas de servicio',
            USER_PERMISSION_VIEW_SETTINGS: 'Ver ajustes',
            USER_PERMISSION_VIEW_SITES: 'Ver sitios',
            USER_PERMISSION_VIEW_SITE_GROUPS: 'Ver grupos de sitios',
            USER_PERMISSION_VIEW_SITE_NOTE_TYPES: 'Ver tipos de nota de sitio',
            USER_PERMISSION_VIEW_SURVEYS: 'Ver encuestas',
            USER_PERMISSION_VIEW_TAGS: 'Ver etiquetas',
            USER_PERMISSION_VIEW_TRADE_TYPES: 'Ver tipo de comercio',
            USER_PERMISSION_VIEW_USERS: 'Ver usuarios',
            USER_PERMISSION_VIEW_DUTY_MANAGEMENT_SCHEDULES: 'Ver horarios de gestión de servicios',
            USER_PERMISSION_CAN_RECEIVE_SUPPORT_MESSAGES: 'Puede recibir mensajes de soporte',
            USER_PERMISSION_CAN_CREATE_GLOBAL_AUDIT_TEMPLATES: 'Puede crear plantillas de auditoría global',
            USER_PERMISSION_ACCESS_SERVICE_PROVIDER_APP: 'Acceder a aplicación del proveedor de servicios',
            USER_PERMISSION_ACCESS_CLIENT_APP: 'Acceder a aplicación de cliente',
            USER_PERMISSION_MANAGE_SERVICE_TYPES: 'Gestionar tipos de servicio',
            USER_PERMISSION_VIEW_SERVICE_TYPES: 'Ver tipos de servicio',
            USER_PERMISSION_MANAGE_IMPORTS: 'Gestionar importaciones',
            USER_PERMISSION_MANAGE_EXPORTS: 'Gestionar exportaciones',
            USER_PERMISSION_MANAGE_CORE_CLIENTS: 'Gestionar clientes principales',
            USER_PERMISSION_KPI_TREND_EXPORT: 'Exportar tendencia de KPI',
            USER_PERMISSION_MANAGE_ASSET_SCORE_TYPES: 'Gestionar tipos de puntuación de activos',
            USER_PERMISSION_VIEW_ASSET_SCORE_TYPES: 'Ver tipos de puntuación de activos',
            USER_PERMISSION_MANAGE_WASTE_MANAGEMENT: 'Gestionar gestión de residuos',
            USER_PERMISSION_MANAGE_ACTION_TYPES: 'Gestionar tipos de acción',
            USER_PERMISSION_VIEW_ACTION_TYPES: 'Ver tipos de acción',
            USER_PERMISSION_VIEW_FORMS: 'Formularios de vista',
            USER_PERMISSION_MANAGE_FORMS: 'Gestionar formularios',
            USER_PERMISSION_MANAGE_METER_INFO_DETAIL: '',
            USER_PERMISSION_VIEW_METER_INFO_DETAIL: '',
            USER_PERMISSION_CAN_CREATE_GLOBAL_AUDIT_TEMPLATES_DETAIL: '',
            USER_PERMISSION_ACCESS_SERVICE_PROVIDER_APP_DETAIL: '',
            USER_PERMISSION_ACCESS_CLIENT_APP_DETAIL: '',
            USER_PERMISSION_MANAGE_BILLING_TYPES: 'Gestionar tipos de facturación',
            USER_PERMISSION_MANAGE_ACTION_TYPES_DETAIL: 'La capacidad de crear, actualizar y eliminar tipos de acción',
            USER_PERMISSION_MANAGE_ASSET_TYPES_DETAIL: 'La capacidad de crear, actualizar y eliminar tipos de activos',
            USER_PERMISSION_MANAGE_ASSOCIATE_TYPES_DETAIL: 'La capacidad de crear, actualizar y eliminar asociados del sitio',
            USER_PERMISSION_MANAGE_CERTIFICATE_TYPES_DETAIL: 'La capacidad de crear, actualizar y eliminar tipos de certificado',
            USER_PERMISSION_MANAGE_CHARGE_TYPES_DETAIL: 'La capacidad de crear, actualizar y eliminar tipos de carga',
            USER_PERMISSION_MANAGE_CONTRACT_TYPES_DETAIL: 'La capacidad de crear, actualizar y eliminar tipos de contrato',
            USER_PERMISSION_MANAGE_JOB_REQUISITES_DETAIL: 'La capacidad de crear, actualizar y eliminar requisitos de trabajo',
            USER_PERMISSION_MANAGE_JOB_GROUPS_DETAIL: 'La capacidad de crear, actualizar y eliminar grupos de trabajo',
            USER_PERMISSION_MANAGE_LETTERS_DETAIL: 'La capacidad de crear, actualizar y eliminar plantillas de cartas',
            USER_PERMISSION_MANAGE_METERINFO_DETAIL: 'La capacidad de crear, actualizar y eliminar fabricantes de medidores, proveedores de medidores, tipos de suministro de medidores y tipos de medidores',
            USER_PERMISSION_MANAGE_PRIORITIES_DETAIL: 'La capacidad de crear, actualizar y eliminar prioridades del sistema',
            USER_PERMISSION_MANAGE_REGIONS_DETAIL: 'La capacidad de crear, actualizar y eliminar regiones',
            USER_PERMISSION_MANAGE_ROLES_DETAIL: 'La capacidad de crear, actualizar y eliminar roles',
            USER_PERMISSION_MANAGE_SATISFACTION_SURVEYS_DETAIL: 'La capacidad de crear, actualizar y eliminar preguntas de la encuesta de satisfacción',
            USER_PERMISSION_MANAGE_SECTORS_DETAIL: 'La capacidad de crear, actualizar y eliminar sectores',
            USER_PERMISSION_MANAGE_SERVICE_PROVIDERS_DETAIL: 'La capacidad de crear, actualizar y eliminar proveedores de servicio',
            USER_PERMISSION_MANAGE_SERVICE_PROVIDER_GROUPS_DETAIL: 'La capacidad de crear, actualizar y eliminar grupos de proveedores de servicio',
            USER_PERMISSION_MANAGE_SERVICE_ROUTINES_DETAIL: 'La capacidad de crear, actualizar y eliminar rutinas de servicio del sistema',
            USER_PERMISSION_MANAGE_SETTINGS_DETAIL: 'La capacidad de actualizar los ajustes del sistema',
            USER_PERMISSION_MANAGE_SITES_DETAIL: 'La capacidad de crear, actualizar y eliminar sitios',
            USER_PERMISSION_MANAGE_SITE_GROUPS_DETAIL: 'La capacidad de crear, actualizar y eliminar grupos de sitios',
            USER_PERMISSION_MANAGE_SITE_NOTE_TYPES_DETAIL: 'La capacidad de crear, actualizar y eliminar tipos de notas de sitios',
            USER_PERMISSION_MANAGE_SITE_RELATIONSHIPS_DETAIL: 'La capacidad de crear, actualizar y eliminar relaciones de sitios',
            USER_PERMISSION_MANAGE_SURVEYS_DETAIL: 'La capacidad de crear, actualizar y eliminar encuestas de KPI',
            USER_PERMISSION_MANAGE_TAGS_DETAIL: 'La capacidad de crear, actualizar y eliminar etiquetas',
            USER_PERMISSION_MANAGE_TRADE_TYPES_DETAIL: 'La capacidad de crear, actualizar y eliminar tipos de comercio',
            USER_PERMISSION_MANAGE_USERS_DETAIL: 'La capacidad de crear, actualizar y eliminar usuarios',
            USER_PERMISSION_MANAGE_DUTY_MANAGEMENT_SCHEDULES_DETAIL: 'La capacidad de gestionar los horarios de gestión de los servicios para el sistema',
            USER_PERMISSION_MANAGE_MASTER_TASKS_DETAIL: 'La capacidad de crear, actualizar y eliminar tareas maestras',
            USER_PERMISSION_MANAGE_CORE_CLIENTS_DETAIL: 'La capacidad de crear, actualizar y eliminar clientes principales',
            USER_PERMISSION_VIEW_ACTION_TYPES_DETAIL: 'La capacidad de ver tipos de acción',
            USER_PERMISSION_VIEW_ASSET_TYPES_DETAIL: 'La capacidad de ver tipos de activo',
            USER_PERMISSION_VIEW_ASSOCIATE_TYPES_DETAIL: 'La capacidad de ver tipos de asociado',
            USER_PERMISSION_VIEW_CERTIFICATE_TYPES_DETAIL: 'La capacidad de ver tipos de certificado',
            USER_PERMISSION_VIEW_CHARGE_TYPES_DETAIL: 'La capacidad de ver tipos de carga',
            USER_PERMISSION_VIEW_CONTRACT_TYPES_DETAIL: 'La capacidad de ver tipos de contrato',
            USER_PERMISSION_VIEW_JOB_REQUISITES_DETAIL: 'La capacidad de ver requisitos de trabajo',
            USER_PERMISSION_VIEW_JOB_GROUPS_DETAIL: 'La capacidad de ver grupos de trabajo',
            USER_PERMISSION_VIEW_LETTERS_DETAIL: 'La capacidad de ver plantillas de carta',
            USER_PERMISSION_VIEW_METERINFO_DETAIL: 'La capacidad de ver fabricantes de medidores, proveedores de medidores, tipos de suministro de medidores y tipos de medidores',
            USER_PERMISSION_VIEW_PRIORITIES_DETAIL: 'La capacidad de ver prioridades del sistema',
            USER_PERMISSION_VIEW_REGIONS_DETAIL: 'La capacidad de ver regiones',
            USER_PERMISSION_VIEW_ROLES_DETAIL: 'La capacidad de ver roles',
            USER_PERMISSION_VIEW_SATISFACTION_SURVEYS_DETAIL: 'La capacidad de ver preguntas de la encuesta de satisfacción',
            USER_PERMISSION_VIEW_SECTORS_DETAIL: 'La capacidad de ver sectores',
            USER_PERMISSION_VIEW_SERVICE_PROVIDERS_DETAIL: 'La capacidad de ver proveedores de servicio',
            USER_PERMISSION_VIEW_SERVICE_PROVIDER_GROUPS_DETAIL: 'La capacidad de ver grupos de proveedores de servicio',
            USER_PERMISSION_VIEW_SERVICE_ROUTINES_DETAIL: 'La capacidad de ver rutinas de servicio de sistema',
            USER_PERMISSION_VIEW_SETTINGS_DETAIL: 'La capacidad de ver los ajustes del sistema',
            USER_PERMISSION_VIEW_SITES_DETAIL: 'La capacidad de ver sitios',
            USER_PERMISSION_VIEW_SITE_GROUPS_DETAIL: 'La capacidad de ver grupos de sitios',
            USER_PERMISSION_VIEW_SITE_NOTE_TYPES_DETAIL: 'La capacidad de ver tipos de notas de sitios',
            USER_PERMISSION_VIEW_SURVEYS_DETAIL: 'La capacidad de ver encuestas KPI',
            USER_PERMISSION_VIEW_TAGS_DETAIL: 'La capacidad de ver etiquetas',
            USER_PERMISSION_VIEW_TRADE_TYPES_DETAIL: 'La capacidad de ver tipos de comercio',
            USER_PERMISSION_VIEW_USERS_DETAIL: 'La capacidad de ver usuarios',
            USER_PERMISSION_VIEW_DUTY_MANAGEMENT_SCHEDULES_DETAIL: 'La capacidad de ver los horarios de gestión de los servicios para el sistema',
            USER_PERMISSION_CAN_RECEIVE_SUPPORT_MESSAGES_DETAIL: 'La capacidad de ver y responder a mensajes de soporte',
            USER_PERMISSION_MANAGE_SERVICE_TYPES_DETAIL: 'La capacidad de crear, actualizar y eliminar proveedores de servicio',
            USER_PERMISSION_VIEW_SERVICE_TYPES_DETAIL: 'La capacidad de ver tipos de servicio',
            USER_PERMISSION_MANAGE_IMPORTS_DETAIL: 'La capacidad de importar datos',
            USER_PERMISSION_MANAGE_EXPORTS_DETAIL: 'La capacidad de exportar datos',
            USER_PERMISSION_MANAGE_BILLING_TYPES_DETAIL: 'La capacidad de crear tipos de facturación',
            USER_PERMISSION_KPI_TREND_EXPORT_DETAIL: 'La capacidad de exportar tendencias KPI',
            USER_PERMISSION_MANAGE_ASSET_SCORE_TYPES_DETAIL: 'La capacidad de gestionar tipos de puntuación de activos',
            USER_PERMISSION_VIEW_ASSET_SCORE_TYPES_DETAIL: 'La capacidad de ver tipos de puntuación de activos',
            USER_PERMISSION_OPEN_CLOSE_DETAIL: 'La capacidad de crear registros de apertura tardía/cierre temprano',
            USER_PERMISSION_OPEN_CLOSE: 'La capacidad de crear registros de apertura tardía/cierre temprano',
            USER_PERMISSION_MANAGE_SPILLAGE_TYPES: 'Gestionar los tipos de vertido',
            USER_PERMISSION_MANAGE_SPILLAGE_TYPES_DETAIL: 'La capacidad de gestionar los tipos de vertido',
            USER_PERMISSION_VIEW_SPILLAGE_TYPES: 'Ver los tipos de vertido',
            USER_PERMISSION_VIEW_SPILLAGE_TYPES_DETAIL: 'La capacidad de ver los tipos de vertido',
            USER_PERMISSION_MANAGE_MISSED_PATROL_REASONS: 'Gestionar los motivos de patrullas perdidas',
            USER_PERMISSION_MANAGE_MISSED_PATROL_REASONS_DETAIL: 'Gestionar los motivos de patrullas perdidas',
            USER_PERMISSION_VIEW_FORMS_DETAIL: 'La capacidad para ver formularios',
            USER_PERMISSION_MANAGE_FORMS_DETAIL: 'La capacidad para gestionar formularios',
            USER_PERMISSION_MANAGE_WASTE_MANAGEMENT_DETAIL: 'La capacidad para gestionar la gestión de residuos',
            UPDATES_AVAILABLE_HEADER: 'Actualizaciones disponibles',
            UPDATES_AVAILABLE_BODY: 'Hay actualizaciones disponibles para Elogs CAFM y su navegador se volverá a cargar en 5 minutos para aplicarlas. Puede posponer esta actualización y continuar con lo que está haciendo; sin embargo, puede experimentar problemas con Elogs CAFM.',
            UPDATE_NOW: 'Actualizar ahora',
            UPDATE_POSTPONE: 'Posponer actualización',
            THRESHOLD: 'Umbral',
            THRESHOLDS: 'Umbrales',
            THRESHOLD_LIST: 'Umbrales',
            QUOTE_THRESHOLD_CREATED: 'Umbral de cotización creado',
            QUOTE_THRESHOLD_UPDATED: 'Umbral de cotización actualizado',
            QUOTE_THRESHOLD_DELETED: 'Umbral de cotización eliminado',
            THRESHOLD_ADD: 'Añadir un nuevo umbral',
            THRESHOLD_EDIT: 'Editar umbral',
            TENANT: 'Arrendatario',
            TENANTS: 'Arrendatarios',
            TENANT_LIST: 'Arrendatarios',
            TENANT_ADD: 'Añadir un equipo nuevo arrendatario',
            ADD_NEW_TENANT: 'Añadir arrendatario',
            TENANT_EDIT: 'Editar arrendatario',
            TENANT_CREATED: 'Arrendatario creado',
            TENANT_DELETED: 'Arrendatario eliminado',
            EMAIL_SELECTED_TENANTS: '<i class="fa fa-mail-forward"></i> Enviar correo electrónico a arrendatarios ({{ ::selected }} seleccionados)',
            EMAIL_FILTERED: 'Correo electrónico filtrado',
            FILTERED_TENANTS: 'Arrendatarios filtrados',
            EMAIL_TENANTS: 'Correo electrónico a arrendatarios',
            EMAIL_LOGO_SUGGESTED_SIZE: 'Tamaño de carga sugerido: max-width = 393px',
            TENANT_CONTACT_CREATED: 'Arrendatario creado',
            TENANT_CONTACT_UPDATED: 'Arrendatario actualizado',
            TENANT_CONTACT_DELETED: 'Arrendatario eliminado',
            A_TENANT_CANNOT_BE_A_USER: 'Un arrendatario no puede ser un usuario',
            A_TENANT_MUST_HAVE_A_VALID_NAME: 'Un arrendatario debe tener un nombre válido',
            NOT_A_TENANT: 'No es un arrendatario',
            TASK: 'Tarea',
            TASKS: 'Tareas',
            TASKS_MANAGE_TASKS: 'Gestionar tareas',
            TASKS_GENERATE_PLANNER: 'Generar planificador',
            TASK_GENERATE_PLANNER: 'Generar planificador',
            TASK_GENERATE_DATES: 'Generación de rango de datos',
            TASKS_VIEW_PLANNER: 'Ver planificador',
            TASKS_IMPORT: 'Importar tareas',
            VIEW_PLANNER: 'Ver planificador',
            MASTER_TASKS_PLANNER: 'Planificador de tarea maestra',
            TASK_CREATE_PROCESS_CONTINUE: 'Continuar',
            TASK_CREATE_PROCESS_CREATE_ASSET: 'Añadir activo',
            TASK_CREATE_STEP_1: 'Crear tarea',
            SERVICE_TYPE_TIP: 'Los Servicios difíciles requerirán un activo.',
            LIST_TASKS: 'Tareas',
            TASK_PLANNER: 'Planificador',
            TASK_EVENT_ADD: 'Añadir evento de tarea',
            TASK_EVENT_EDIT: 'Editar evento de tarea',
            PLANNER: 'Planificador',
            PPM_PLANNER: 'Planificador PPM',
            TASK_ADD: 'Añadir tarea',
            TASK_EDIT: 'Editar tarea',
            TASK_GENERATE: 'Generar tareas',
            TASK_STATUS: 'Estados de tarea',
            TASKS_NO_RESULTS: 'Sin resultados',
            STATUS_ALL: 'Todo',
            SELECT_FREQUENCY: 'Seleccione la frecuencia',
            SELECT_INTERVAL: 'Seleccionar intervalo',
            SELECT_PREFIX: 'Seleccionar prefijo',
            SELECT_DAY: 'Seleccionar día',
            SELECT_DAY_NUMBER: 'Seleccionar día',
            SELECT_MONTH: 'Seleccionar mes',
            SELECT_ALL_FIELDS_ERROR: 'Seleccione todos los campos',
            AT_LEAST_ONE_FREQUENCY_REQUIRED: 'Se requiere al menos una frecuencia',
            DECLINE_SELECTED: 'Declinar seleccionados',
            DECLINE_FILTERED: 'Declinar todo lo filtrado',
            APPROVE_SELECTED: 'Aprobar seleccionados ({{ count }})',
            APPROVE_FILTERED: 'Aprobar todo lo filtrado',
            REMOVE_SELECTED: 'Eliminar seleccionados',
            REMOVE_FILTERED: 'Eliminar todo lo filtrado',
            GENERATE_SELECTED: 'Generar seleccionados ({{ count }})',
            GENERATE_FILTERED: 'Generar todo lo filtrado',
            SEND_FOR_APPROVAL: 'Enviar para aprobación',
            SEND_FILTERED_FOR_APPROVAL: 'Enviar todo lo filtrado para aprobación ({{ count }})',
            INTERVAL: 'Intervalo',
            RULE: 'Regla',
            RULES: 'Reglas',
            OF: 'de',
            DATE: 'Fecha',
            DAY_OF_THE_WEEK: 'Día de la semana',
            FREQUENCY: 'Frecuencia',
            FREQUENCIES: 'Frecuencias',
            START: 'Fecha de inicio',
            DATE_FROM: 'Fecha de inicio',
            END: 'Fecha final',
            DATE_TO: 'Fecha final',
            TOTAL: 'Total',
            NO_ITEMS_SELECTED: 'No hay elementos seleccionados',
            REASON_FOR_TASK_EVENT_REJECT: 'Escriba una causa de la declinación',
            TASK_GENERATION_INFO: 'solicitud(es) de generación de tareas en cola. Actualice el planificador después de 1 minuto.',
            TASK_APPROVAL_PENDING_INFO: '{{ count }} solicitud de aprobación {{ count > 1 ? "s" : "" }} pendiente.',
            ALLTASKS: 'Seleccionar todas las tareas',
            COPYFROMLASTYEAR: 'Copiar del año pasado',
            TASK_SERVICE_PROVIDER_NOT_DEFINED: 'Proveedor de servicios no definido',
            TASK_APPROVAL_STATUS: 'Estado de aprobación de tareas',
            TASK_STATUS_DRAFT: 'Borrador',
            TASK_STATUS_PENDING_APPROVAL: 'Aprobación pendiente',
            TASK_STATUS_REJECTED: 'Rechazado',
            TASK_STATUS_APPROVED: 'Aprobado',
            TASK_STATUS_JOB_COMPLETED: 'Completado',
            TASK_STATUS_JOB_AWAITING_PAPERWORK: 'Esperando papeleo',
            TASK_STATUS_JOB_IN_PROGRESS: 'En curso',
            TASK_STATUS_JOB_MISSED: 'Perdido',
            TASK_STATUS_JOB_OVERDUE: 'Vencido',
            TASK_STATUS_FUTURE_DRAFT: 'Borrador futuro',
            TASK_STATUS_FUTURE_PENDING_APPROVAL: 'Aprobación pendiente futura',
            TASK_EVENT_STATUTORY_BASED_ON_FREQUENCY: 'Normativa basada en la frecuencia',
            TASK_STATUS_FUTURE_REJECTED: 'Futuro rechazado',
            TASK_STATUS_FUTURE_APPROVED: 'Futuro aprobado',
            TASK_STATUS_IGNORED: 'Ignorado',
            TASKS_AWAITING_APPROVAL: 'Esperando aprobación',
            TASK_IS_MANDATORY: 'Obligatorio',
            SOFT_SERVICE: 'Servicio suave',
            HARD_SERVICE: 'Servicio difícil',
            TASKS_MY_PLANNER: 'Mi planificador',
            SELECT_SITE_TO_CHOSE_SERVICE_PROVIDER: 'Seleccione el sitio para elegir el proveedor de servicios.',
            BULK_ASSIGN_SERVICE_PROVIDERS: 'Asignar proveedores de servicios en masa',
            TASK_SERVICE_ROUTINES_MUST_MATCH: 'Las frecuencias e intervalos no coinciden con la rutina de servicio del activo.',
            TASKS_PLANNER_UPLOAD: 'Importaciones de planificador',
            TASK_IMPORTS: 'Importaciones',
            CURRENT_USER_IS_NOT_A_VALID_APPROVER: 'No es un aprobador de PPM válido.',
            CANNOT_SEND_SO_MANY_TASKS: 'No se pueden enviar tantas tareas para su aprobación. El límite está establecido en 1000. Refine sus filtros',
            CANNOT_GENERATE_SO_MANY_TASKS: 'No puede generar tantas tareas. El límite está establecido en 1000. Refine sus filtros',
            CANNOT_REMOVE_SO_MANY_TASKS: 'No se pueden eliminar tantas tareas. El límite está establecido en 1000. Refine sus filtros',
            CANNOT_APPROVE_SO_MANY_TASKS: 'No se pueden aprobar tantas tareas. El límite está establecido en 1000. Refine sus filtros',
            CANNOT_DECLINE_SO_MANY_TASKS: 'No se pueden declinar tantas tareas. El límite está establecido en 1000. Refine sus filtros',
            ONE_OR_MORE_RULES_MISSING: 'Falta una o más reglas de frecuencia',
            CANCEL_SELECTED: 'Cancelar seleccionado ({{ count }})',
            CANCEL_FILTERED: 'Cancelar todo lo filtrado',
            TASKS_SELECTED: 'Tareas seleccionadas',
            TASKS_POINT_IN_TIME: 'Las tareas en este punto en el tiempo coinciden con sus criterios de búsqueda',
            ALL_FILTERED_TASKS: 'Todas las tareas filtradas',
            CANCEL_TASKS: 'Cancelar tareas',
            TASKS_QUEUED_FOR_CANCELLATION: 'Tareas en cola para cancelación',
            NO_ITEMS: 'No hay elementos que cancelar',
            TASK_START_DATE_WARNING: 'La fecha de inicio no puede ser anterior al 1 de enero {{ lastYear }}',
            TAG: 'Carpeta',
            TAGS: 'Carpetas',
            TAG_LIST: 'Carpetas',
            TAG_CREATED: 'Carpeta creada',
            TAG_UPDATED: 'Carpeta actualizada',
            TAG_DELETED: 'Carpeta eliminada',
            TAG_ADD: 'Añadir una nueva carpeta',
            TAG_ACTION: 'Acción: {{ ::tag.entityId }}',
            TAG_CONTRACT: 'Adquisición de contratos: {{ ::tag.entityId }}',
            TAG_JOB: 'Trabajo: {{ ::tag.entityId }}',
            TAG_JOBREQUISITEANSWER: 'Respuesta de requisito de trabajo',
            TAG_PLANNEDJOB: 'Trabajo planificado: {{ ::tag.entityId }}',
            TAG_QUOTE: 'Cotización: {{ ::tag.entityId }}',
            TAG_QUOTEOPTION: 'Opción de cotización',
            TAG_QUOTEREQUEST: 'Solicitud de cotización',
            TAG_REACTIVEJOB: 'Trabajo: {{ ::tag.entityId }}',
            TAG_SITE: '{{ ::tag.name }}',
            TAG_SPILLAGE: 'Vertido: {{ ::tag.entityId }}',
            AND_MORE: '...y {{ ::number | number:0 }} más',
            TAG_EXCEEDS_CHAR_LIMIT: 'La etiqueta excede el límite de caracteres de 255 caracteres.',
            TAG_CHAR_MIN_LIMIT: 'El nombre de la etiqueta debe tener al menos 1 carácter',
            TAG_MISSING_NAME: 'Falta el nombre de la etiqueta',
            SCHEDULES: 'Horarios',
            SURVEY_SCHEDULES: 'Horarios de encuesta',
            SURVEY_SCHEDULE: 'Horario',
            SURVEYSCHEDULE_ADD: 'Añadir horario',
            SURVEY_REQUEST_ADD: 'Añadir encuesta de KPI',
            ADD_NEW_SURVEY_REQUEST: 'Aceptar una nueva encuesta de KPI',
            ACCEPT_SURVEY: 'Aceptar encuesta de KPI',
            ADD_NEW_SURVEY: 'Añadir una nueva encuesta de KPI',
            ADD_SURVEY: 'Añadir encuesta de KPI',
            ADD_SURVEY_LINE: 'Añadir línea de encuesta de KPI',
            EDIT_SURVEY: 'Editar encuesta de KPI',
            FM_COMMENT: 'Comentario FM',
            FM_SCORE: 'Puntuación FM',
            CALCULATED_SCORE: 'Puntuación',
            LAST_UPDATED: 'Última actualización',
            MAXIMUM_SCORE: 'Puntuación máxima',
            REPORTING_PERIOD_MONTH_YEAR: 'Período de informes',
            RESET_LINES: 'Restablecer líneas',
            SAVE_CHANGES: 'Guardar cambios',
            SELECT_SCORE_RANGE: 'Rango de puntuación',
            SP_COMMENT: 'Comentario PS',
            SP_SCORE: 'Puntuación PS',
            SPSCORE_FMSCORE: 'Puntuación PS/FM',
            SUBMIT_SURVEY: 'Enviar encuesta de KPI',
            SURVEY_ID: 'ID de encuesta de KPI',
            SURVEY_LINES: 'Líneas de encuesta',
            SURVEY_SITES: 'Sitios de encuesta de KPI',
            SURVEY_SITES_ADD: 'Añadir sitio',
            SURVEY_REQUESTS: 'Solicitudes de encuesta de KPI',
            WEIGHT_1_TO_5: 'Peso (1 a 5)',
            REPORTING_MONTH: 'Mes del informe',
            REPORTING_YEAR: 'Año del informe',
            ALLOW_SP_TO_COMPLETE_THIS_SURVEY: 'Permitir que las operativas de los proveedores de servicios completen esta encuesta de KPI',
            SCORE_LESS_THAN: 'Puntuación inferior a',
            NO_ACTIVE_SURVEYS_FOR_SERVICE_PROVIDER: 'Actualmente no hay encuestas de KPI activas para este proveedor de servicios',
            STATUS_IN_PROGRESS: 'En curso',
            STATUS_PENDING: 'Pendiente',
            STATUS_COMPLETED: 'Completado',
            STATUS_DONE: 'Hecho',
            STATUS_DONE_WITH_ERRORS: 'Hecho (con errores)',
            STATUS_INVALID_FILE: 'Archivo no válido',
            STATUS_INVALID_TYPE: 'Índice no válido',
            STATUS_OPEN: 'Abrir (activo o pendiente)',
            STATUS_CLOSED: 'Cerrado',
            LAST_RUN: 'Última ejecución',
            NEXT_RUN: 'Siguiente ejecución',
            NO_SURVEYS_FOR_SERVICE_PROVIDER: 'Actualmente no hay encuestas de KPI activas para este proveedor de servicios',
            SP_EDITABLE: 'PS editable',
            SERVICEPROVIDER: 'Proveedor de Servicios',
            SERVICEPROVIDER_GROUP: 'Grupo de proveedor de servicios',
            SURVEY: 'Encuesta',
            SPEDITABLE: 'PS editable',
            CREATEDAT: 'Creado en',
            UPDATEDAT: 'Actualizar en',
            SURVEYSCHEDULE_EDIT: 'Editar horario de encuesta',
            SURVEY_SCHEDULE_CREATED: 'Horario de encuesta creada',
            SURVEY_SCHEDULE_UPDATED: 'Horario de encuesta actualizado',
            SURVEY_SCHEDULE_DELETED: 'Horario de encuesta eliminado',
            SURVEY_SAVED: 'Encuesta guardada',
            SURVEY_SUBMITTED: 'Encuesta enviada',
            SURVEY_ACCEPTED: 'Encuesta aceptada',
            SITES_ADD: 'Añadir sitio a encuesta de KPI',
            ERROR_NOT_ALLOWED_TO_UPDATE_FM_AND_SP_SCORE: 'No tiene permiso para actualizar la puntuación de FM y PS',
            ERROR_NOT_ALLOWED_TO_UPDATE_FM_SCORE: 'No tiene permiso para actualizar la puntuación de FM',
            ERROR_NOT_ALLOWED_TO_UPDATE_SP_SCORE: 'No tiene permiso para actualizar la puntuación de PS',
            PENDING_KPI_SURVEYS: 'Encuestas de KPI pendientes',
            SURVEY_ACTIVE_STATE_CHANGED: 'Estado de encuesta cambiado',
            QUESTION_ANSWERED_CANNOT_DELETE: 'La pregunta respondida no puede ser eliminada',
            SURVEYS: 'Encuestas de KPI',
            SURVEY_LIST: 'Encuestas de KPI',
            SURVEY_VIEW: 'Información',
            SURVEY_ADD: 'Aceptar una nueva encuesta de KPI',
            SURVEY_EDIT: 'Editar encuesta de KPI',
            CHANGE_SITE_SURVEY_ACCESS: 'Añadir encuesta de KPI a sitios',
            SITE_ADDED: 'Encuesta de KPI añadida',
            SURVEY_CREATED: 'Encuesta de KPI creada',
            SURVEY_UPDATED: 'Encuesta de KPI actualizada',
            SURVEY_DELETED: 'Encuesta de KPI eliminada',
            SURVEY_TO_SUBMIT: 'Encuestas de KPI para enviar',
            SURVEY_TO_APPROVE: 'Encuestas de KPI para aprobar',
            OUTSTANDING_SURVEYS: 'Encuestas de KPI pendientes',
            SURVEY_INACTIVE: 'Encuesta inactiva - No se puede actualizar',
            NO_RULES_ENTERED: 'Ninguna regla introducida',
            AUTOMATED_STATS: 'Estadísticas automatizadas',
            SELECT_STATS: 'Seleccionar estadísticas',
            STAT_TYPE: 'Tipo de estadística',
            UPDATE_STATS: 'Actualizar estadísticas',
            STATISTIC: 'Estadística',
            SURVEY_STATS_UPDATED: 'Estadísticas de encuesta actualizadas',
            SITES_DELETED_FROM_SURVEY: 'Sitios eliminados de la encuesta',
            KPI_SURVEY_SCHEDULES: 'Horarios de encuesta KPI',
            SURVEY_LINE: 'Línea de encuesta',
            SURVEY_LINE_LIST: 'Líneas de encuesta',
            SURVEY_LINE_ADD: 'Añadir una nueva línea de encuesta',
            SURVEY_LINE_EDIT: 'Editar línea de encuesta',
            SURVEY_LINE_CREATED: 'Línea de encuesta creada',
            SURVEY_LINE_UPDATED: 'Línea de encuesta actualizada',
            SURVEY_LINE_DELETED: 'Línea de encuesta eliminada',
            SURVEY_LINES_UPDATED: 'Líneas de encuesta actualizadas',
            VALUE_MUST_BE_A_NUMBER_BETWEEN_1_AND_5: 'El valor debe ser un número entre 1 y 5',
            COMMENT_3000_OR_LESS: 'El comentario debe tener menos de 3000 caracteres',
            STATUS_ACTIVE: 'Activo',
            STATUS_INACTIVE: 'Inactivo',
            STATUS_DRAFT: 'Borrador',
            STATUS_TRAINING: 'Formación',
            SPILLAGES: 'Vertidos',
            SPILLAGE_LIST: 'Vertidos',
            SPILLAGE: 'Vertido',
            SPILLAGE_TYPE: 'Tipo de vertido',
            ACCIDENT_OCCURRED: 'Accidente ocurrido',
            SPILLAGES_ADD: 'Añadir vertido',
            HAS_ACCIDENT_OCCURRED: '¿Accidente ocurrido?',
            I_AM_REPORTER: 'Soy el reportero',
            REPORT_SPILLAGE: 'Informar de un vertido',
            REPORTER_NAME: 'Nombre del reportero',
            SELECT_LOCATION: 'Seleccionar ubicación',
            SPILLAGE_ADD: 'Registrar nuevo vertido',
            SPILLAGE_CREATED: 'Vertido creado',
            SITE_LOCATION: 'Ubicación de sitio',
            I_AM_NOT_NEAR_SPILLAGE: 'No estoy cerca de un vertido',
            ASSIGNED_TO_ME: 'Asignado a mí',
            SITE_REPORTER: 'Reportero de sitio',
            ASSIGN_TO_ME: 'Asignar a mí',
            SPILLAGE_DETAILS_WITH_ID: 'Detalles del vertido n.º {{ ::id }}',
            SPILLAGE_OPEN: 'Abrir',
            SPILLAGE_ACCEPTED: 'Aceptado',
            SPILLAGE_OPEN_PENDING_PHOTO: 'Foto pendiente',
            SPILLAGE_CANCELLED: 'Cancelada',
            SPILLAGE_IN_PROGRESS: 'En curso',
            SPILLAGE_STARTED: 'Comenzado',
            SPILLAGE_COMPLETED: 'Completado',
            SPILLAGE_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> creó el vertido".',
            SPILLAGE_CREATED_SYSTEM_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> creó el vertido".',
            SPILLAGE_MESSAGE_ACTION: '<strong>{{ ::message._links["sender"].title }}</strong> proporcionó una actualización".',
            SPILLAGE_REASSIGNED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> reasignó el vertido".',
            SPILLAGE_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> canceló el vertido".',
            SPILLAGE_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> acepó el vertido".',
            SPILLAGE_STARTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> inició el vertido".',
            SPILLAGE_COMPLETED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> completó el vertido".',
            SPILLAGE_CHASED_ACTION: 'Se ha enviado una notificación de búsqueda n.º {{ ::event.chaseSequence }} a <strong>{{ ::event._links["user"].title }}</strong>".',
            SPILLAGE_CHASED_SYSTEM_ACTION: 'Se ha enviado una notificación de búsqueda n.º {{ ::event.chaseSequence }}.',
            SPILLAGE_ESCALATION_CHASED_ACTION: 'Se ha enviado una notificación de búsqueda de escalada n.º {{ ::event.chaseSequence }} a <strong>{{ ::event._links["user"].title }}</strong>".',
            SPILLAGE_ESCALATION_CHASED_SYSTEM_ACTION: 'Se ha enviado una notificación de búsqueda de escalada n.º {{ ::event.chaseSequence }}.',
            SPILLAGE_WORKFLOW_REASSIGN: 'Reasignar vertido',
            SPILLAGE_WORKFLOW_REASSIGN_SUBMIT: 'Reasignar',
            SPILLAGE_WORKFLOW_REASSIGN_SUCCESS: 'Vertido reasignado',
            SPILLAGE_WORKFLOW_CANCEL: 'Cancelar vertido',
            SPILLAGE_WORKFLOW_CANCEL_SUBMIT: 'Cancelar vertido',
            SPILLAGE_WORKFLOW_CANCEL_SUCCESS: 'Vertido cancelado',
            SPILLAGE_OPEN_CHASE_FREQ_BEFORE: 'Frecuencia de búsqueda ABIERTA - Primera búsqueda (mín.)',
            SPILLAGE_OPEN_CHASE_FREQ_AFTER: 'Frecuencia de búsqueda ABIERTA - Búsquedas adicionales (mín.)',
            SPILLAGE_ACCEPTED_CHASE_FREQ_BEFORE: 'Frecuencia de búsqueda ACEPTADA - Primera búsqueda (mín.)',
            SPILLAGE_ACCEPTED_CHASE_FREQ_AFTER: 'Frecuencia de búsqueda ACEPTADA - Búsquedas adicionales (mín.)',
            SPILLAGE_IN_PROGRESS_CHASE_FREQ_BEFORE: 'Frecuencia de búsqueda EN CURSO - Primera búsqueda (mín.)',
            SPILLAGE_IN_PROGRESS_CHASE_FREQ_AFTER: 'Frecuencia de búsqueda EN CURSO - Búsquedas adicionales (mín.)',
            SPILLAGE_ESCALATION_INTERVAL_REPEAT: 'Intervalo de escalada - Repetición (mín.)',
            SPILLAGE_ESCALATION_INTERVAL: 'Intervalo de escalada (mín.)',
            SPILLAGE_ESCALATION_SITEASSOCIATE_TYPE: 'Tipo de asociado de sitio de escalada',
            SPILLAGE_SETTINGS_VIEW: 'Ajustes de vertido',
            SPILLAGE_JOB_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> creó un trabajo',
            SPILLAGE_QUOTE_REQUEST_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> creó una solicitud de cotización',
            SPILLAGE_ACTION_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> creó la acción',
            FROM_SPILLAGE: 'Desde el vertido',
            ADD_AN_ACTION: 'Añadir una acción',
            ADD_A_JOB: 'Añadir un trabajo',
            ADD_A_QUOTE: 'Añadir una cotización',
            SPILLAGE_TYPES: 'Tipos de vertido',
            ADD_NEW_SPILLAGE_TYPE: 'Añadir nuevo tipo de vertido',
            EDIT_SPILLAGE_TYPE: 'Editar tipo de vertido',
            SPILLAGE_TYPE_LIST: 'Tipos de vertido',
            SPILLAGE_TYPE_ADD: 'Añadir nuevo tipo de vertido',
            SPILLAGE_TYPE_EDIT: 'Editar tipo de vertido',
            SPILLAGE_TYPE_CREATED: 'Tipo de vertido creado',
            SPILLAGE_TYPE_UPDATED: 'Tipo de vertido actualizado',
            SPILLAGE_TYPE_DELETED: 'Tipo de vertido eliminado',
            SPILLAGE_TYPE_ADDED: 'Tipo de vertido añadido',
            SITE_GROUP: 'Grupo de sitio',
            SITE_GROUPS: 'Grupos de sitio',
            SITE_GROUP_LIST: 'Grupos de sitio',
            SITE_GROUP_ADD: 'Añadir un nuevo grupo de sitio',
            SITE_GROUP_EDIT: 'Editar un grupo de sitios',
            EDIT_SITE_GROUP: 'Editar un grupo de sitios',
            SITE_GROUP_SITE_LIST: 'Sitios de un grupo de sitios',
            ADD_NEW_SITE_GROUP: 'Añadir un nuevo grupo de sitio',
            MANAGE_SITE_GROUP_SITES: 'Gestionar sitios',
            SITE_GROUP_SITE_ADDED: 'Sitio añadido a grupo de sitios',
            SITES_DELETED_FROM_SITE_GROUP: 'Sitio eliminado de grupo de sitios',
            SITE_GROUP_CREATED: 'Grupo de sitio creado',
            SITE_GROUP_UPDATED: 'Grupo de sitio actualizado',
            SITE_GROUP_DELETED: 'Grupo de sitio eliminado',
            SITE: 'Sitio',
            SITES: 'Sitios',
            SITES_LIST: 'Lista de sitios',
            ADD_NEW_SITE: 'Añadir un nuevo sitio',
            EDIT_SITE: 'Editar sitio',
            VIEW_SITE: 'Ver sitio',
            ADD_SITE: 'Añadir sitio',
            ADDRESSES: 'Direcciones',
            CITY: 'Ciudad',
            COUNTY_STATE_PROVINCE_REGION: 'Condado/Región',
            POSTAL_CODE_ZIP: 'Código postal',
            COUNTRY: 'País',
            CONTACT_INFO_TYPE: 'Tipo de contacto',
            CONTACT_ADDRESS_ADDED: 'Añadir dirección de contacto',
            CONTACT_ADDRESS_UPDATED: 'Dirección de contacto actualizada',
            CONTACT_ADDRESS_DELETED: 'Dirección de contacto eliminada',
            MANAGE_SITES: 'Gestionar sitios',
            ALL_SITES: 'Todos los sitios',
            SELECTED_SITES: 'Sitios seleccionados',
            CONTACT_INFORMATION: 'Información de contacto',
            CONTACT_INFORMATION_ADDRESS: 'Información de contacto y dirección',
            SITE_CONTACT: 'Contacto de sitio',
            SITE_IMAGE: 'Imagen de sitio',
            SITE_MEMBERSHIP: 'Afiliación a sitio',
            SITE_MEMBERSHIPS: 'Afiliaciones a sitio',
            SITE_MEMBERSHIP_LIST: 'Lista de afiliación a sitio',
            SITE_MEMBERSHIP_ADD: 'Añadir afiliación a sitio',
            SITE_MEMBERSHIP_EDIT: 'Editar afiliación a sitio',
            SITE_MEMBERSHIP_CREATED: 'Afiliación a sitio creada correctamente',
            SITE_MEMBERSHIP_UPDATED: 'Afiliación a sitio actualizada correctamente',
            SITE_MEMBERSHIP_DELETED: 'Afiliación a sitio eliminada',
            IMAGE_DELETED: 'Imagen eliminada',
            REQUIRE_PURCHASE_ORDER: '¿Requerir orden de compra?',
            EXCLUDE_PCD: '¿Excluir de PCD?',
            EXCLUDE_P2P: '¿Excluir de P2P?',
            EXCLUDE_FROM_HELPDESK_MONITOR: '¿Excluir del monitor de servicio de asistencia?',
            SITE_P2P_STARTED_AT_DATE: 'P2P comenzó en la fecha',
            AREA: 'Zona',
            UNIT: 'Unidad',
            SITE_CONTACTS: 'Contactos del sitio',
            ADD_NEW_SITE_CONTACT: 'Añadir contacto de sitio',
            EDIT_CONTACT: 'Editar contacto de sitio',
            SITE_MANAGER: 'Administrador del sitio',
            SITE_MANAGERS: 'Gerentes de sitio',
            SITE_LIST: 'Sitios',
            SITE_ADD: 'Añadir un nuevo sitio',
            SITE_EDIT: 'Editar sitio',
            SITE_CREATED: 'Sitio creado correctamente',
            SITE_UPDATED: 'Sitio actualizado correctamente',
            SITE_DELETED: 'Sitio eliminado',
            A_SITE_CONTACT_MUST_HAVE_CONTACT_INFORMATION: 'Un contacto del sitio debe tener información de contacto',
            A_SITE_CONTACT_MUST_HAVE_A_VALID_NAME: 'Un contacto de sitio debe tener un nombre válido',
            ADD_NEW_SITE_NOTE_TYPE: 'Añadir un nuevo tipo de nota de sitio',
            SITE_NOTE_TYPE_TITLE: 'Título',
            ALLOW_REQUIRE_FM: 'Permita marcar que esto requiere una aprobación FM',
            SITE_NOTE_TYPE: 'Tipo de nota de sitio',
            SITE_NOTE_TYPES: 'Tipos de nota de sitio',
            NOT_APPLICABLE: 'No Aplicable',
            REQUIRE_FM: 'Requiere aprobación FM',
            SITE_REQUIRES_FM_APPROVAL: 'Se requiere aprobación FM',
            NO_DATA: 'Todavía no se han introducido datos para este campo',
            SITE_CHOOSE_A_USER: 'Elija un usuario',
            SITE_ENTER_DETAILS_MANUALLY: 'Introducir datos manualmente',
            SITE_NOTES: 'Notas',
            SITE_ACCESS: 'Acceso a sitio',
            SITE_ONE_ADDRESS: 'El sitio sólo puede tener una dirección',
            SEARCH_SITES_BY_NAME: 'Buscar sitios por nombre',
            SITE_INFORMATION: 'Información sobre el sitio',
            ASSOCIATES_UPDATED: 'Asociados del sitio actualizados',
            ASSOCIATES: 'Asociados',
            EDIT_ADDRESS: 'Editar dirección',
            SITE_CONTACT_ADD: 'Añadir contacto de sitio',
            SITE_CONTACT_EDIT: 'Editar contacto de sitio',
            CONTACT_CONTACT_CREATED: 'Contacto de sitio creado',
            CONTACT_CONTACT_UPDATED: 'Contacto de sitio actualizado',
            CONTACT_CONTACT_DELETED: 'Contacto de sitio eliminado',
            ADD_LOCATION: 'Añadir ubicación',
            EDIT_LOCATION: 'Editar ubicación',
            SITE_NOTE_UPDATED: 'Nota de sitio actualizada',
            SITE_NOTE_CREATED: 'Nota de sitio creada',
            SITE_THRESHOLD_DELETED: 'Umbral de sitio eliminado',
            SITE_THRESHOLD_VALUE_ADDED: 'Umbral de sitio añadido',
            NO_CONTACT_INFORMATION_ASSIGNED: 'Sin información de contacto asignada',
            SITE_STATUS: 'Estado del sitio',
            REFERENCE_PREFIX: 'Prefijo de referencia',
            SITE_USER_ALREADY_HAS_ASSOCIATION_WITH_SITE: 'El usuario ya está asociado con este sitio.',
            SITE_USER_HAS_NO_ASSOCIATION_WITH_SITE: 'El usuario no tiene ninguna asociación con este sitio.',
            SITE_CANNOT_ADD_FOR_SITES_YOU_ARE_NOT_ASSOCIATED_WITH: 'No puede añadir asociaciones para sitios con los que no está asociado.',
            KEYHOLDER: 'Llavero',
            SITE_NOT_ACTIVE: 'Sitio no activo',
            SITE_RELATIONSHIPS: 'Relaciones del sitio',
            SITE_RELATIONSHIP_INFORMATION: 'Información de relación del sitio',
            ADD_NEW_SITE_RELATIONSHIP: 'Añadir una nueva relación de sitio',
            EDIT_SITE_RELATIONSHIP: 'Editar relación de sitio',
            SITE_RELATIONSHIP_LIST: 'Relaciones del sitio',
            SITE_RELATIONSHIP_ADD: 'Añadir una nueva relación de sitio',
            SITE_RELATIONSHIP_EDIT: 'Editar relación de sitio',
            SITE_RELATIONSHIP_CREATED: 'Relación de sitio creada correctamente',
            SITE_RELATIONSHIP_UPDATED: 'Relación de sitio actualizada correctamente',
            SITE_RELATIONSHIP_DELETED: 'Relación de sitio eliminada correctamente',
            SITE_PERMISSION_EDIT_KPIS: 'Editar Kpis',
            SITE_PERMISSION_VIEW_FILES: 'Ver archivos',
            SITE_PERMISSION_EDIT_FILES: 'Editar archivos',
            SITE_PERMISSION_VIEW_NOTES: 'Ver notas',
            SITE_PERMISSION_EDIT_NOTES: 'Editar notas',
            SITE_PERMISSION: 'Permisos del sitio',
            SITE_PERMISSION_CAN_BE_APPROVER: 'Puede ser aprobador',
            SITE_PERMISSION_CAN_BE_ASSOCIATE: 'Puede ser asociado',
            SITE_PERMISSION_CAN_CANCEL_PATROLS: 'Puede cancelar patrullas',
            SITE_PERMISSION_CREATE_JOBS: 'Crear trabajos',
            SITE_PERMISSION_CREATE_ACTION: 'Crear acciones',
            SITE_PERMISSION_CREATE_ASSETS: 'Crear activos',
            SITE_PERMISSION_CREATE_AUDIT_TEMPLATES: 'Crear plantillas de auditorías',
            SITE_PERMISSION_CREATE_AUDITS: 'Crear auditorías',
            SITE_PERMISSION_CREATE_KPIS: 'Crear Kpis',
            SITE_PERMISSION_CREATE_QUOTES: 'Crear cotizaciones',
            SITE_PERMISSION_CREATE_TASKS: 'Crear tareas',
            SITE_PERMISSION_CREATE_ACTIONS: 'Crear acciones',
            SITE_PERMISSION_EDIT: 'Editar sitios',
            SITE_PERMISSION_EDIT_ACTIONS: 'Editar acciones',
            SITE_PERMISSION_EDIT_APPROVERS: 'Editar aprobadores',
            SITE_PERMISSION_EDIT_ASSETS: 'Editar activos',
            SITE_PERMISSION_EDIT_ASSOCIATES: 'Editar asociados',
            SITE_PERMISSION_EDIT_CONTACTS: 'Editar contactos',
            SITE_PERMISSION_EDIT_CONTRACTS: 'Editar contratos',
            SITE_PERMISSION_EDIT_JOBS: 'Editar trabajos',
            SITE_PERMISSION_EDIT_LOCATIONS: 'Editar ubicaciones',
            SITE_PERMISSION_EDIT_METER_READINGS: 'Editar lecturas de medidor',
            SITE_PERMISSION_EDIT_METERINFO: 'Editar información de medidor',
            SITE_PERMISSION_EDIT_METERS: 'Editar medidores',
            SITE_PERMISSION_EDIT_PARTNERSHIPS: 'Editar asociaciones',
            SITE_PERMISSION_EDIT_PATROL_INSTANCES: 'Editar instancias de patrulla',
            SITE_PERMISSION_EDIT_PRIORITIES: 'Editar prioridades',
            SITE_PERMISSION_EDIT_QUOTES: 'Editar cotizaciones',
            SITE_PERMISSION_EDIT_TASKS: 'Editar tareas',
            SITE_PERMISSION_EDIT_TENANTS: 'Editar arrendatarios',
            SITE_PERMISSION_HELPDESK: 'Atención al cliente',
            SITE_PERMISSION_MANAGE_PATROLS: 'Gestionar patrullas',
            SITE_PERMISSION_TENANT: 'Arrendatario',
            SITE_PERMISSION_TENANT_PLUS: 'Arrendatario Plus',
            SITE_PERMISSION_VIEW: 'Ver sitios',
            SITE_PERMISSION_VIEW_AUDITS: 'Ver auditorías',
            SITE_PERMISSION_EDIT_AUDIT_REFERENCE: 'Editar auditorías de referencia',
            SITE_PERMISSION_VIEW_KPIS: 'Ver Kpis',
            SITE_PERMISSION_VIEW_PATROLS: 'Ver patrullas',
            SITE_PERMISSION_VIEW_ACTIONS: 'Ver acciones',
            SITE_PERMISSION_VIEW_APPROVERS: 'Ver aprobadores',
            SITE_PERMISSION_VIEW_ASSETS: 'Ver activos',
            SITE_PERMISSION_VIEW_ASSOCIATES: 'Ver asociados',
            SITE_PERMISSION_VIEW_CONTACTS: 'Ver contactos',
            SITE_PERMISSION_VIEW_CONTRACTS: 'Ver contratos',
            SITE_PERMISSION_VIEW_JOBS: 'Ver trabajos',
            SITE_PERMISSION_VIEW_LOCATIONS: 'Ver ubicaciones',
            SITE_PERMISSION_VIEW_METERINFO: 'Ver información del medidor',
            SITE_PERMISSION_VIEW_METERS: 'Ver medidores',
            SITE_PERMISSION_VIEW_PARTNERSHIPS: 'Ver asociaciones',
            SITE_PERMISSION_VIEW_PATROL_INSTANCES: 'Ver instancias de patrulla',
            SITE_PERMISSION_VIEW_PRIORITIES: 'Ver prioridades',
            SITE_PERMISSION_VIEW_QUOTES: 'Ver cotizaciones',
            SITE_PERMISSION_VIEW_TASKS: 'Ver tareas',
            SITE_PERMISSION_VIEW_TENANTS: 'Ver arrendatarios',
            SITE_PERMISSION_VIEW_SURVEYS: 'Ver encuestas',
            SITE_PERMISSION_EDIT_SURVEYS: 'Editar encuestas',
            SITE_PERMISSION_CREATE_SURVEYS: 'Crear encuestas',
            SITE_PERMISSION_VIEW_SURVEY_SCHEDULES: 'Ver horarios de encuesta',
            SITE_PERMISSION_EDIT_SURVEY_SCHEDULES: 'Editar horarios de encuesta',
            SITE_PERMISSION_CREATE_SURVEY_SCHEDULES: 'Crear horarios de encuesta',
            SITE_PERMISSION_FORCE_COMPLETE_AUDITS: 'Forzar auditorías completas',
            SITE_PERMISSION_ASSET_USER_IMPORTS: 'Importar activos',
            SITE_PERMISSION_TASK_USER_IMPORTS: 'Importar tareas',
            SITE_PERMISSION_ASSET_TASK_USER_IMPORTS: 'Importar planificador',
            SITE_PERMISSION_VIEW_FILES_DETAIL: '',
            SITE_PERMISSION_EDIT_FILES_DETAIL: '',
            SITE_PERMISSION_VIEW_NOTES_DETAIL: '',
            SITE_PERMISSION_EDIT_NOTES_DETAIL: '',
            SITE_PERMISSION_CAN_BE_APPROVER_DETAIL: 'Puede ser aprobador',
            SITE_PERMISSION_CAN_BE_ASSOCIATE_DETAIL: 'Puede ser asociado',
            SITE_PERMISSION_CAN_CANCEL_PATROLS_DETAIL: 'Puede cancelar instancias de patrulla',
            SITE_PERMISSION_CREATE_JOBS_DETAIL: 'Crear trabajos',
            SITE_PERMISSION_CREATE_ACTION_DETAIL: 'Crear acciones',
            SITE_PERMISSION_CREATE_ASSETS_DETAIL: 'Crear activos',
            SITE_PERMISSION_CREATE_AUDIT_TEMPLATES_DETAIL: 'Crear plantillas de auditorías',
            SITE_PERMISSION_CREATE_AUDITS_DETAIL: 'Crear auditorías',
            SITE_PERMISSION_CREATE_KPIS_DETAIL: 'Crear Kpis',
            SITE_PERMISSION_CREATE_QUOTES_DETAIL: 'Crear cotizaciones',
            SITE_PERMISSION_CREATE_TASKS_DETAIL: 'Crear tareas',
            SITE_PERMISSION_CREATE_ACTIONS_DETAIL: 'Crear acciones',
            SITE_PERMISSION_EDIT_DETAIL: 'Editar sitios',
            SITE_PERMISSION_EDIT_ACTIONS_DETAIL: 'Editar acciones',
            SITE_PERMISSION_EDIT_APPROVERS_DETAIL: 'Editar aprobadores',
            SITE_PERMISSION_EDIT_ASSETS_DETAIL: 'Editar activos',
            SITE_PERMISSION_EDIT_ASSOCIATES_DETAIL: 'Editar asociados',
            SITE_PERMISSION_EDIT_CONTACTS_DETAIL: 'Editar contactos',
            SITE_PERMISSION_EDIT_CONTRACTS_DETAIL: 'Editar contratos',
            SITE_PERMISSION_EDIT_JOBS_DETAIL: 'Editar trabajos',
            SITE_PERMISSION_EDIT_LOCATIONS_DETAIL: 'Editar ubicaciones',
            SITE_PERMISSION_EDIT_METER_READINGS_DETAIL: 'Editar lecturas de medidor',
            SITE_PERMISSION_EDIT_METERINFO_DETAIL: 'Editar información de medidor',
            SITE_PERMISSION_EDIT_METERS_DETAIL: 'Editar medidores',
            SITE_PERMISSION_EDIT_PARTNERSHIPS_DETAIL: 'Editar asociaciones',
            SITE_PERMISSION_EDIT_PATROL_INSTANCES_DETAIL: 'Editar instancias de patrulla',
            SITE_PERMISSION_EDIT_PRIORITIES_DETAIL: 'Editar prioridades',
            SITE_PERMISSION_EDIT_QUOTES_DETAIL: 'Editar cotizaciones',
            SITE_PERMISSION_EDIT_TASKS_DETAIL: 'Editar tareas',
            SITE_PERMISSION_CANCEL_TASK_EVENTS: 'Cancelar tareas',
            SITE_PERMISSION_CANCEL_TASK_EVENTS_DETAIL: 'Cancelar tareas y todos los trabajos de esas tareas',
            SITE_PERMISSION_EDIT_TENANTS_DETAIL: 'Editar arrendatarios',
            SITE_PERMISSION_HELPDESK_DETAIL: 'Atención al cliente',
            SITE_PERMISSION_MANAGE_PATROLS_DETAIL: 'Gestionar patrullas',
            SITE_PERMISSION_TENANT_DETAIL: 'Arrendatario',
            SITE_PERMISSION_TENANT_PLUS_DETAIL: 'Arrendatario Plus',
            SITE_PERMISSION_VIEW_DETAIL: 'Ver sitios',
            SITE_PERMISSION_VIEW_AUDITS_DETAIL: 'Ver auditorías',
            SITE_PERMISSION_EDIT_AUDIT_REFERENCE_DETAIL: 'Editar referencia de auditoría cuando la auditoría está activa',
            SITE_PERMISSION_VIEW_KPIS_DETAIL: 'Ver Kpis',
            SITE_PERMISSION_VIEW_PATROLS_DETAIL: 'Ver patrullas',
            SITE_PERMISSION_VIEW_ACTIONS_DETAIL: 'Ver acciones',
            SITE_PERMISSION_VIEW_APPROVERS_DETAIL: 'Ver aprobadores',
            SITE_PERMISSION_VIEW_ASSETS_DETAIL: 'Ver activos',
            SITE_PERMISSION_VIEW_ASSOCIATES_DETAIL: 'Ver asociados',
            SITE_PERMISSION_VIEW_CONTACTS_DETAIL: 'Ver contactos',
            SITE_PERMISSION_VIEW_CONTRACTS_DETAIL: 'Ver contratos',
            SITE_PERMISSION_VIEW_JOBS_DETAIL: 'Ver trabajos',
            SITE_PERMISSION_VIEW_LOCATIONS_DETAIL: 'Ver ubicaciones',
            SITE_PERMISSION_VIEW_METERINFO_DETAIL: 'Ver información del medidor',
            SITE_PERMISSION_VIEW_METERS_DETAIL: 'Ver medidores',
            SITE_PERMISSION_VIEW_PARTNERSHIPS_DETAIL: 'Ver asociaciones',
            SITE_PERMISSION_VIEW_PATROL_INSTANCES_DETAIL: 'Ver instancias de patrulla',
            SITE_PERMISSION_VIEW_PRIORITIES_DETAIL: 'Ver prioridades',
            SITE_PERMISSION_VIEW_QUOTES_DETAIL: 'Ver cotizaciones',
            SITE_PERMISSION_VIEW_TASKS_DETAIL: 'Ver tareas',
            SITE_PERMISSION_VIEW_TENANTS_DETAIL: 'Ver arrendatarios',
            SITE_PERMISSION_VIEW_SURVEYS_DETAIL: 'Ver encuestas',
            SITE_PERMISSION_EDIT_SURVEYS_DETAIL: 'Editar encuestas',
            SITE_PERMISSION_CREATE_SURVEYS_DETAIL: 'Crear encuestas',
            SITE_PERMISSION_VIEW_SURVEY_SCHEDULES_DETAIL: 'Ver horarios de encuesta',
            SITE_PERMISSION_EDIT_SURVEY_SCHEDULES_DETAIL: 'Editar horarios de encuesta',
            SITE_PERMISSION_CREATE_SURVEY_SCHEDULES_DETAIL: 'Crear horarios de encuesta',
            SITE_PERMISSION_EMAIL_TENANTS: 'Programa de correo electrónico del arrendatario',
            SITE_PERMISSION_EMAIL_TENANTS_DETAIL: 'Puede usar el programa de correo electrónico del arrendatario',
            SITE_PERMISSION_FORCE_COMPLETE_AUDITS_DETAIL: 'Forzar auditorías completas sin responder a todas las preguntas',
            SITE_PERMISSION_VIEW_EMAIL_LOGS: 'Ver registros de correo electrónico',
            SITE_PERMISSION_VIEW_EMAIL_LOGS_DETAIL: 'Puede ver correos electrónicos de otros usuarios',
            SITE_PERMISSION_P2P: 'P2P',
            SITE_PERMISSION_P2P_DETAIL: '',
            SITE_PERMISSION_ASSET_USER_IMPORTS_DETAIL: 'Importar activos',
            SITE_PERMISSION_TASK_USER_IMPORTS_DETAIL: 'Importar tareas',
            SITE_PERMISSION_ASSET_TASK_USER_IMPORTS_DETAIL: 'La capacidad de importar tareas y activos',
            SITE_PERMISSION_JOB_OPERATIVE: 'Ver operativas',
            SITE_PERMISSION_JOB_OPERATIVE_DETAIL: 'Capacidad de seleccionar un operativo en el trabajo',
            SITE_PERMISSION_BULK_REASSIGN_JOBS: 'Reasignar trabajos en masa',
            SITE_PERMISSION_BULK_REASSIGN_JOBS_DETAIL: 'Capacidad de reasignar trabajos en masa.',
            SITE_PERMISSION_BULK_REASSIGN_OPERATIVE_JOBS: 'Reasignar operativos de trabajo en masa',
            SITE_PERMISSION_BULK_REASSIGN_OPERATIVE_JOBS_DETAIL: 'Capacidad de reasignar operativos de trabajo en masa.',
            SITE_PERMISSION_BULK_CANCEL_JOBS: 'Cancelar trabajos en masa',
            SITE_PERMISSION_BULK_CANCEL_JOBS_DETAIL: 'Capacidad de cancelar trabajos en masa.',
            USER_STILL_AN_APPROVER: 'No puede eliminar Puede ser aprobador". Este usuario aún es un aprobador de al menos un sitio".',
            AT_LEAST_ONE_USER_STILL_AN_APPROVER: 'Al menos un usuario sigue siendo un aprobador para un sitio.',
            CANNOT_REMOVE_APPROVER_PERMISSION: 'No se puede eliminar el permiso del aprobador. El(Los) usuario(s) con esta función son actualmente aprobadores.',
            SITE_PERMISSION_VIEW_AUDIT_TEMPLATES: 'Ver plantillas de auditorías',
            SITE_PERMISSION_CREATE_PUBLIC_AUDIT_TEMPLATES: 'Crear plantillas de auditorías públicas',
            SITE_PERMISSION_VIEW_AUDIT_SCHEDULES: 'Ver horarios de auditorías',
            SITE_PERMISSION_CREATE_AUDIT_SCHEDULES: 'Crear horarios de auditorías',
            SITE_PERMISSION_VIEW_AUDIT_TEMPLATES_DETAIL: 'Ver plantillas de auditorías',
            SITE_PERMISSION_CREATE_PUBLIC_AUDIT_TEMPLATES_DETAIL: 'Crear plantillas de auditorías públicas',
            SITE_PERMISSION_VIEW_AUDIT_SCHEDULES_DETAIL: 'Ver horarios de auditorías',
            SITE_PERMISSION_CREATE_AUDIT_SCHEDULES_DETAIL: 'Crear horarios de auditorías',
            SITE_PERMISSION_EDIT_METER_REFERENCES: 'Editar referencias de medidor',
            SITE_PERMISSION_EDIT_METER_REFERENCES_DETAIL: 'Editar referencias de medidor',
            SITE_PERMISSION_WRITE_NFC_TAGS: 'Escribir etiquetas NFC',
            SITE_PERMISSION_WRITE_NFC_TAGS_DETAIL: 'Escribir etiquetas NFC',
            SITE_PERMISSION_ACTIVATE_SURVEY: 'Activar encuesta de KPI',
            SITE_PERMISSION_ACTIVATE_SURVEY_DETAIL: 'Activar encuesta de KPI',
            SITE_PERMISSION_NOTIFY_UPDATES: 'Notificar contactos de trabajo/cotización',
            SITE_PERMISSION_NOTIFY_UPDATES_DETAIL: 'Notificar contactos de trabajo/cotización de actualizaciones',
            SITE_PERMISSION_VIEW_LINK_JOBS: 'Ver enlaces para trabajos',
            SITE_PERMISSION_VIEW_LINK_JOBS_DETAIL: 'Ver enlaces para trabajos',
            SITE_PERMISSION_REMOVE_LINK_JOBS: 'Eliminar enlaces para trabajos',
            SITE_PERMISSION_REMOVE_LINK_JOBS_DETAIL: 'Eliminar enlaces para trabajos',
            SITE_PERMISSION_EDIT_SCORE_TYPES_ON_ASSET: 'Editar tipos de puntuación en activo',
            SITE_PERMISSION_EDIT_SCORE_TYPES_ON_ASSET_DETAIL: 'Editar tipos de puntuación al editar activos',
            SITE_PERMISSION_OPEN_CLOSE: 'Crear registros de apertura/cierre de tienda',
            SITE_PERMISSION_OPEN_CLOSE_DETAIL: 'La capacidad de crear registros de apertura/cierre de tienda',
            SITE_PERMISSION_VIEW_OPEN_CLOSE: 'Ver registros de apertura/cierre de tienda',
            SITE_PERMISSION_VIEW_OPEN_CLOSE_DETAIL: 'La capacidad de ver registros de apertura/cierre de tienda',
            SITE_PERMISSION_CREATE_CONTRACTS: 'Crear contratos',
            SITE_PERMISSION_CREATE_CONTRACTS_DETAIL: 'La capacidad para crear contratos',
            SITE_PERMISSION_CREATE_SPILLAGES: 'Informar de vertidos',
            SITE_PERMISSION_CREATE_SPILLAGES_DETAIL: 'La capacidad de informar de vertidos',
            SITE_PERMISSION_EDIT_SPILLAGES: 'Editar vertidos notificados',
            SITE_PERMISSION_EDIT_SPILLAGES_DETAIL: 'La capacidad de editar los vertidos notificados',
            SITE_PERMISSION_VIEW_SPILLAGES: 'Ver vertidos',
            SITE_PERMISSION_VIEW_SPILLAGES_DETAIL: 'La capacidad de ver vertidos',
            SITE_PERMISSION_VIEW_FILTER_BAR: 'Ver barra de filtro',
            SITE_PERMISSION_VIEW_FILTER_BAR_DETAIL: 'La capacidad para ver la barra de filtro',
            SITE_PERMISSION_BULK_NOTIFY_FILES: 'Archivos de notificación en masa',
            SITE_PERMISSION_BULK_NOTIFY_FILES_DETAIL: 'La capacidad de notificar en masa los archivos caducados',
            SITE_PERMISSION_VIEW_JOB_FILES: 'Ver archivos de trabajo',
            SITE_PERMISSION_VIEW_JOB_FILES_DETAIL: 'La capacidad de ver archivos que deben ser visibles en trabajos',
            SITE_PERMISSION_EDIT_JOB_FILES: 'Editar archivos de trabajo',
            SITE_PERMISSION_EDIT_JOB_FILES_DETAIL: 'La capacidad de editar archivos que deben ser visibles en trabajos',
            SITE_PERMISSION_VIEW_WASTE_ENTRIES: 'Ver entradas de residuos',
            SITE_PERMISSION_VIEW_WASTE_ENTRIES_DETAIL: 'La capacidad para ver entradas de residuos',
            SITE_PERMISSION_CREATE_WASTE_ENTRIES: 'Crear entradas de residuos',
            SITE_PERMISSION_CREATE_WASTE_ENTRIES_DETAIL: 'La capacidad para crear entradas de residuos',
            SITE_PERMISSION_EDIT_WASTE_ENTRIES: 'Editar entradas de residuos',
            SITE_PERMISSION_EDIT_WASTE_ENTRIES_DETAIL: 'La capacidad para editar entradas de residuos',
            SITE_PERMISSION_IMPORT_WASTE_ENTRIES: 'Importar entradas de residuos',
            SITE_PERMISSION_IMPORT_WASTE_ENTRIES_DETAIL: 'La capacidad para importar entradas de residuos',
            SITE_PERMISSION_CREATE_AUDIT_ANSWER_OPTIONS: 'Crear opciones de respuesta a la auditoría',
            SITE_PERMISSION_CREATE_AUDIT_ANSWER_OPTIONS_DETAIL: 'La capacidad de crear opciones de respuesta a la auditoría',
            SITE_PERMISSION_EDIT_AUDIT_ANSWER_OPTIONS: 'Editar opciones de respuesta a la auditoría',
            SITE_PERMISSION_EDIT_AUDIT_ANSWER_OPTIONS_DETAIL: 'La capacidad de editar opciones de respuesta a la auditoría',
            SITE_PERMISSION_VIEW_AUDIT_ANSWER_OPTIONS: 'Ver opciones de respuesta a la auditoría',
            SITE_PERMISSION_VIEW_AUDIT_ANSWER_OPTIONS_DETAIL: 'La capacidad de ver opciones de respuesta a la auditoría',
            SITE_NOTE_TYPE_INFORMATION: 'Información de tipo de nota de sitio',
            EDIT_SITE_NOTE_TYPE: 'Editar tipo de nota de sitio',
            ALLOW_NOT_APPLICABLE: 'Permitir N/D',
            ALLOW_HELPDESK: 'Permitir ver a la atención al cliente',
            NOTE_VISIBILITY: 'Visibilidad',
            VISIBILITY: 'Visibilidad',
            VISIBILITY_PRIVATE: 'Privado',
            VISIBILITY_TENANT: 'Arrendatario',
            VISIBILITY_SERVICE_PROVIDER: 'Proveedor de Servicios',
            SITE_NOTE_TYPE_LIST: 'Tipos de nota de sitio',
            SITE_NOTE_TYPE_ADD: 'Añadir un nuevo tipo de nota de sitio',
            SITE_NOTE_TYPE_EDIT: 'Editar tipo de nota de sitio',
            SITE_NOTE_TYPE_CREATED: 'Tipo de nota de sitio creado correctamente',
            SITE_NOTE_TYPE_UPDATED: 'Tipo de nota de sitio actualizado correctamente',
            SITE_NOTE_TYPE_DELETED: 'Tipo de nota de sitio eliminado correctamente',
            SITE_ASSOCIATE_TYPE: 'Tipo de asociado de sitio',
            SITE_ASSOCIATE_TYPES: 'Tipos de asociados de sitio',
            SITE_ASSOCIATE_TYPE_INFORMATION: 'Información de tipo de asociado de sitio',
            ADD_NEW_SITE_ASSOCIATE_TYPE: 'Añadir un nuevo tipo de asociado de sitio',
            EDIT_SITE_ASSOCIATE_TYPE: 'Editar tipo de asociado de sitio',
            SITE_ASSOCIATE_TYPE_LIST: 'Tipos de asociados de sitio',
            SITE_ASSOCIATE_TYPE_ADD: 'Añadir un nuevo tipo de asociado de sitio',
            SITE_ASSOCIATE_TYPE_EDIT: 'Editar tipo de asociado de sitio',
            SITE_ASSOCIATE_TYPE_CREATED: 'Tipo de asociado de sitio creado',
            SITE_ASSOCIATE_TYPE_UPDATED: 'Tipo de asociado de sitio actualizado',
            SITE_ASSOCIATE_TYPE_DELETED: 'Tipo de asociado de sitio eliminado',
            SITE_ASSOCIATES: 'Asociados del sitio',
            SITE_ASSOCIATE_LIST: 'Asociados del sitio',
            NO_ASSOCIATES_ADDED: 'Ningún asociado seleccionado',
            CANNOT_DELETE_TYPE: 'Este tipo no se puede eliminar',
            GENERAL_SETTINGS: 'General',
            SETTINGS_UPDATED: 'Ajustes actualizados',
            EMAIL_SETTINGS: 'Correo electrónico',
            PASSWORD_SETTINGS: 'Contraseña',
            JOB_SETTINGS: 'Trabajo',
            JOB_SETTINGS_VIEW: 'Ajustes del trabajo',
            JOB_CHASE_SETTINGS_VIEW: 'Ajustes de búsqueda de trabajo',
            CHASE_SETTINGS_VIEW: 'Ajustes de la búsqueda',
            QUOTE_SETTINGS: 'Cotización',
            QUOTE_SETTINGS_VIEW: 'Ajustes de cotización',
            CHASE_SETTINGS: 'Búsqueda',
            SURVEY_SETTINGS: 'Encuestas',
            SURVEY_SETTINGS_VIEW: 'Ajustes de encuesta',
            PERFORMANCE_REPORTING_SETTINGS: 'Informe de rendimiento',
            PERFORMANCE_SETTINGS_VIEW: 'Ajustes de informes de rendimiento',
            FILE_SETTINGS: 'Archivos',
            FILE_SETTINGS_VIEW: 'Ajustes de archivos',
            APP_SETTINGS: 'Aplicación',
            APP_SETTINGS_VIEW: 'Ajustes de la aplicación',
            SETTINGS_EDIT: 'Editar ajustes',
            GENERAL_SETTINGS_VIEW: 'Configuración general',
            GENERAL_LOGO: 'Logotipo del sistema',
            GENERAL_OPERATING_HOURS: 'Horas de funcionamiento',
            GENERAL_OPENING_TIME: 'Horario de apertura',
            GENERAL_CLOSING_TIME: 'Horario de cierre',
            GENERAL_TASKS_GENERATE_AHEAD: 'Generar tareas con varios días de anticipación',
            GENERAL_TASKS_WILL_ALWAYS_GENERATE_NEXT_SUNDAY: 'Las tareas se generarán siempre hasta el siguiente domingo',
            GENERAL_AUDITS_GENERATE_AHEAD: 'Generar auditorías con varios días de anticipación',
            GENERAL_ACTIONSITEASSOCIATETYPE: 'Tipo de asociado de cesionario de acción',
            GENERAL_ACTIONS_PAPERWORK_NOTE_REQUIRED: 'Nota requerida al completar el papeleo para acciones de revisión',
            GENERAL_AUDITCOMPLETIONEMAILASSOCIATETYPE: 'Tipo de asociado de correo electrónico de terminación de auditoría',
            ACTION_SITE_ASSOCIATE_TYPE_SETTING_ASSOCIATE_TYPE_INVALID: 'No se puede presentar acción del papeleo, la configuración de tipo de asociado de acción no es válida',
            ACTION_ASSOCIATES_NO_ASSOCIATES_ASSIGNED_FOR_THIS_SITE: 'No se puede aumentar la acción de papeleo, no hay asociados para este sitio para la configuración del tipo de asociado del asignado de la acción',
            EMAIL_SETTINGS_VIEW: 'Ajustes de correo electrónico',
            EMAIL_HTML_SIGNATURE: 'Firma de correo electrónico (HTML)',
            EMAIL_TEXT_SIGNATURE: 'Firma de correo electrónico (Texto)',
            EMAIL_TENANT_LOGO: 'Logotipo del programa de correo electrónico del arrendatario',
            PASSWORD_SETTINGS_VIEW: 'Ajustes de contraseña',
            PASSWORD_DAYS_TILL_CHANGE_NOTIFICATION: 'Número de días entre solicitudes',
            PASSWORD_DISALLOW_PREVIOUS_PASSWORDS: 'No permitir contraseñas anteriores',
            PASSWORD_FORCE_CHANGE: 'Forzar cambio',
            PASSWORD_MIN_CHARACTERS: 'Número mínimo de caracteres',
            PASSWORD_REQUIRE_NUMBER: 'Requerir número',
            PASSWORD_REQUIRE_PUNCTUATION: 'Requerir puntuación',
            PASSWORD_REQUIRE_UPPERCASE: 'Requerir mayúsculas',
            PASSWORD_COMPLEXITY: 'Complejidad de la contraseña',
            PASSWORD_CHANGE_FREQUENCY: 'Frecuencia de cambio de contraseña',
            JOB_GROUPS_REQUIRE: 'Requerir grupo de trabajo',
            JOB_GROUP_CHILD_SELECT_MANDATORY: 'Selección de grupo de trabajo de niños obligatoria',
            JOB_EXTRA_SETTINGS_EDIT: 'Ajustes extra de trabajo',
            JOB_ACCEPTANCE_TERMS_TEXT: 'Términos y condiciones de aceptación del trabajo',
            JOB_ACCEPTANCE_TERMS_FILE: 'Documento de términos y condiciones de aceptación del trabajo',
            JOB_SATISFACTION_SURVEY_FREQUENCY: 'Frecuencia de la encuesta de satisfacción',
            JOB_TENANT_SELECTION_WHEN_LOGGING: 'Permitir que se seleccione un arrendatario cuando se registra un trabajo',
            JOB_APPROVAL_REMINDER: 'Recordatorio de aprobación',
            JOB_REASSIGNMENT_REMINDER: 'Recordatorio de reasignación',
            JOB_ACKNOWLEDGEMENT_REMINDER: 'Recordatorio de reconocimiento',
            JOB_REACTIVE_COMMENCEMENT_REMINDER: 'Recordatorio de comienzo reactivo',
            JOB_REACTIVE_COMPLETION_REMINDER: 'Recordatorio de terminación reactiva',
            JOB_REACTIVE_ALLOW_SELF_APPROVAL: 'Permitir que los aprobadores secundarios en adelante aprueben sus propios trabajos reactivos',
            JOB_PLANNED_COMMENCEMENT_REMINDER: 'Recordatorio de inicio planificado',
            JOB_PLANNED_COMPLETION_REMINDER: 'Recordatorio de finalización planificada',
            JOB_MINUTES_BEFORE_MUST_BE_APPROVED: 'El número de minutos antes del trabajo debe ser aprobado',
            JOB_MINUTES_BEFORE_REASSIGNMENT_REMINDER: 'El número de minutos antes del recordatorio de reasignación',
            JOB_MINUTES_BEFORE_ACKNOWLEDGEMENT_REMINDER: 'El número de minutos antes del recordatorio de confirmación',
            JOB_MINUTES_BEFORE_REACTIVE_COMMENCEMENT_REMINDER: 'El número de minutos antes del recordatorio de inicio reactivo',
            JOB_MINUTES_BEFORE_REACTIVE_COMPLETION_REMINDER: 'El número de minutos antes del recordatorio de finalización reactivo',
            JOB_MINUTES_BEFORE_PLANNED_COMMENCEMENT_REMINDER: 'El número de minutos antes del recordatorio de inicio planificado',
            JOB_MINUTES_BEFORE_PLANNED_COMPLETION_REMINDER: 'El número de minutos antes del recordatorio de finalización planificada',
            JOB_ALLOW_SECONDARY_APPROVERS_TO_REOPEN_JOBS: 'Permitir que los aprobadores secundarios en adelante reabran trabajos reactivos y planificados',
            REQUIREJOBGROUP: 'Requerir grupo de trabajo',
            ALLOWTENANTSELECTION: 'Permitir que se seleccione un arrendatario cuando se registra un trabajo',
            REACTIVESELFAPPROVAL: 'Permitir que los aprobadores secundarios en adelante aprueben sus propios trabajos reactivos',
            REASSIGNMENTREMINDER: 'Recordatorio de reasignación',
            APPROVALREMINDER: 'Recordatorio de aprobación',
            ACKNOWLEDGEMENTREMINDER: 'Recordatorio de reconocimiento',
            REACTIVECOMMENCEMENTREMINDER: 'Recordatorio de comienzo reactivo',
            REACTIVECOMPLETIONREMINDER: 'Recordatorio de terminación reactiva',
            PLANNEDCOMMENCEMENTREMINDER: 'Recordatorio de inicio planificado',
            PLANNEDCOMPLETIONREMINDER: 'Recordatorio de finalización planificada',
            JOBGROUPCHILDSELECTMANDATORY: 'Selección de grupo de trabajo de niños obligatoria',
            ALLOWSECONDARYAPPROVERTOREOPENJOBS: 'Permitir que los aprobadores secundarios en adelante reabran trabajos reactivos y planificados',
            CHASE_SETTINGS_REACTIVE: 'Reactivo',
            CHASE_SETTINGS_REACTIVESTATUTORY: 'Normativa reactiva',
            CHASE_SETTINGS_PLANNED: 'Planificado',
            CHASE_SETTINGS_PLANNEDSTATUTORY: 'Normativa planeada',
            CHASE_SETTINGS_TYPE0_EDIT: 'Editar búsqueda reactiva',
            CHASE_SETTINGS_TYPE1_EDIT: 'Editar búsqueda normativa reactiva',
            CHASE_SETTINGS_TYPE2_EDIT: 'Editar búsqueda planificada',
            CHASE_SETTINGS_TYPE3_EDIT: 'Editar búsqueda normativa planificada',
            CHASE_SETTINGS_TYPE4_EDIT: 'Editar búsqueda de cotización',
            CHASE_SETTINGS_TYPE0_ADD: 'Añadir búsqueda reactiva',
            CHASE_SETTINGS_TYPE1_ADD: 'Añadir búsqueda normativa reactiva',
            CHASE_SETTINGS_TYPE2_ADD: 'Añadir búsqueda planificada',
            CHASE_SETTINGS_TYPE3_ADD: 'Añadir búsqueda normativa planificada',
            CHASE_SETTINGS_TYPE4_ADD: 'Añadir búsqueda de cotización',
            CHASE_SETTINGS_QUOTE: 'Cotización',
            CHASE_SETTINGS_CONTRACTAPPROVAL: 'Aprobación',
            CHASE_SETTINGS_CONTRACTEXPIRED: 'Caducado',
            CHASE_TARGET: 'Objetivo',
            CHASE_START_DATE: 'Fecha de inicio',
            CHASE_EMAIL_BODY: 'Mensaje de búsqueda',
            CHASE_SETTINGS_TYPE_ADD: 'Añadir ajustes de búsqueda',
            CHASE_SETTINGS_TYPE_EDIT: 'Editar ajustes de búsqueda',
            CHASE_SETTING_UPDATED: 'Ajustes de búsqueda actualizados',
            CHASE_SETTING_REMOVED: 'Ajustes de búsqueda eliminados',
            CHASE_SETTINGS_REORDERED: 'Ajustes de búsqueda reordenados',
            CHASE_BODY: 'Cuerpo',
            SITE_ASSOCIATE: 'Asociado del sitio',
            BODY: 'Cuerpo',
            TARGETSITE: 'Sitio objetivo',
            TARGETOPERATIVE: 'Objetivo operativo',
            SEQUENCE: 'Secuencia',
            AFTERDUEDATEPERIOD: 'Después del período de vencimiento',
            AFTERUPDATEPERIOD: 'Después del período de actualización',
            WEEKENDSPUBLICHOLIDAYS: '¿Incluir fines de semana y vacaciones?',
            TARGETAPPROVER: 'Aprobador de objetivos',
            TARGETSUBMITTER: 'Remitente objetivo',
            QUOTE_TIMEOUT: 'Tiempo expirado',
            QUOTE_TIMEOUT_SUB_LABEL: 'Cantidad de días hasta el vencimiento',
            QUOTE_TERMS_TEXT: 'Términos y condiciones de cotización',
            QUOTE_TERMS_FILE: 'Documento de términos y condiciones de cotización',
            TERMS_DOWNLOAD_TITLE: 'Aquí podrá consultar los términos y condiciones actuales',
            QUOTE_EXTRA_SETTINGS_EDIT: 'Extra',
            QUOTE_EXTENSION_APPROVAL: 'Aprobación de extensión de cotización',
            QUOTE_REMINDER_AT: 'Recordatorio de inicio de cotización',
            QUOTE_DISABLE_P2P_ON_ACCEPTANCE: 'Desactivar P2P al aceptar la cotización',
            SURVEY_TIMEOUT: 'Tiempo expirado',
            SURVEY_AUTOMATED_STATS_ENABLED: 'Estadísticas automatizadas habilitadas',
            KPI_TIMEOUT: 'Tiempo expirado',
            KPI_TIMEOUT_SUB_LABEL: 'Número de días después de haber sido enviado por un proveedor de servicios que la encuesta se acepta automáticamente',
            APP_TIMEOUT: 'Tiempo expirado',
            PERFORMANCE_INCLUDE_PATROL_SCORE: 'Incluir puntuaciones de patrulla',
            PERFORMANCE_INCLUDE_SURVEY_SCORE: 'Incluir puntuaciones de encuestas',
            PERFORMANCE_PATROL_SCORE_WEIGHT: 'Ponderación de la puntuación de patrulla',
            PERFORMANCE_QUOTE_SCORE_WEIGHT: 'Ponderación de la puntuación de cotización',
            PERFORMANCE_SURVEY_SCORE_WEIGHT: 'Ponderación de la puntuación de la encuesta',
            PERFORMANCE_SYSTEM_SCORE_WEIGHT: 'Ponderación de la puntuación del sistema',
            FILE_ALLOWED_TYPES: 'Permitir tipos de archivo/tipos Mime',
            FILE_DISALLOWED_TYPES: 'No permitir tipos de archivo/tipos Mime',
            FILE_RESTRICT: 'Cargas de archivos restringidas',
            FILE_TYPE_DISALLOWED: 'No se permite este tipo de archivo',
            FILE_SETTINGS_ALLOW_SUB_LABEL: 'Si este campo está vacío, los permitirá todos excepto los no permitidos',
            FILE_SETTINGS_DISALLOW_SUB_LABEL: 'Si este campo está vacío, no los permitirá todos excepto los permitidos',
            FILE_EXPIRY_NOTIFICATION_TIME: 'Número de días antes del vencimiento del archivo para generar una notificación',
            FILE_EXPIRY_NOTIFICATION_TIME_SUB_LABEL: 'El mínimo es 1 día',
            TASK_FORCE_SERVICE_ROUTINE: 'Forzar rutinas de servicio para tareas difíciles',
            TASK_SETTINGS_VIEW: 'Ajustes de tarea',
            GENERAL_DEFAULT_LOCALE: 'Configuración regional del sistema',
            GENERAL_HELPDESK_NUMBER: 'Número de teléfono del servicio de asistencia',
            GENERAL_HELPDESK_EMAIL: 'Dirección de correo electrónico del servicio de asistencia',
            GENERAL_TENANT_EMAILER_REPLY_EMAIL: 'Correo electrónico de respuesta del programa de correo electrónico del arrendatario',
            GENERAL_GLOBAL_HELPDESK_NUMBER: 'Número de teléfono del servicio de asistencia global',
            GENERAL_TRAINING_LINK: 'Vínculo de formación',
            GENERAL_NON_PRIORITY_COLOUR: 'Color de prioridad de acción «Por fecha planificada»',
            GENERAL_P2P_STARTED_AT_DATE: 'El P2P global comenzó en la fecha',
            DAYS_UNTIL_ALARM_ESCALATION: 'Días hasta la alarma / escalada',
            INCLUDE_WEEKEND_HOLIDAYS: 'Incluidos fines de semana / festivos',
            EXCLUDE_WEEKEND_HOLIDAYS: 'Excepto fines de semana / festivos',
            CHASE_ENTITY_TYPE_SETTINGS_PLANNED_EDIT: 'Editar retrasos en la búsqueda de trabajo planificada',
            CHASE_ENTITY_TYPE_SETTINGS_PLANNED_STATUTORY_EDIT: 'Editar retrasos en la búsqueda de trabajo planificada (normativa)',
            CHASE_ENTITY_TYPE_SETTINGS_REACTIVE_EDIT: 'Editar retrasos de búsqueda de trabajo reactivo',
            CHASE_ENTITY_TYPE_SETTINGS_REACTIVE_STATUTORY_EDIT: 'Editar retrasos en la búsqueda de trabajo reactivo (normativa)',
            CHASE_ENTITY_TYPE_SETTINGS_QUOTE_EDIT: 'Editar retrasos en la búsqueda de cotizaciones',
            CHASE_ENTITY_SETTINGS_UPDATED: 'Ajustes actualizados de retraso de búsqueda',
            AFTERDAYS: 'Número de días de demora',
            INCLUDEWEEKENDSPUBLICHOLIDAYS: '¿Incluir fines de semana/días festivos?',
            SUBMITTER: 'Remitente',
            NO_PERMISSIONS_ADDED: 'Sin permisos añadidos',
            WRONG_URL_FORMAT: 'Formato URL incorrecto',
            SERVICE_TYPES: 'Tipos de servicio',
            SERVICE_TYPES_LIST: 'Tipos de servicio',
            SERVICE_TYPES_ADD: 'Añadir tipo de servicio',
            SERVICE_TYPES_EDIT: 'Editar tipo de servicio',
            SERVICE_TYPES_UPDATED: 'Tipos de servicio actualizados',
            SERVICE_TYPE: 'Tipo de servicio',
            SERVICE_TYPE_LIST: 'Tipos de servicio',
            SERVICE_TYPE_ADD: 'Añadir un nuevo tipo de servicio',
            SERVICE_TYPE_EDIT: 'Editar tipo de servicio',
            SERVICE_TYPE_ADDED: 'Tipo de servicio añadido',
            SERVICE_TYPE_UPDATED: 'Tipo de servicio actualizado',
            SERVICE_TYPE_DELETED: 'Tipo de servicio eliminado',
            SERVICE_ROUTINE: 'Rutina de servicio',
            SERVICE_ROUTINES: 'Rutinas de servicio',
            SERVICEROUTINES: 'Rutinas de servicio',
            ADD_NEW_SERVICE_ROUTINE: 'Añadir una nueva rutina de servicio',
            EDIT_SERVICE_ROUTINE: 'Editar rutina de servicio',
            SERVICE_ROUTINE_LINES: 'Líneas de rutina de servicio',
            ADD_SERVICE_ROUTINE_LINE: 'Añadir línea de rutina de servicio',
            SERVICE_ROUTINE_ACTION: 'Acción',
            SERVICE_ROUTINE_LIST: 'Rutinas de servicio',
            SERVICE_ROUTINE_VIEW: 'Información',
            SERVICE_ROUTINE_ADD: 'Añadir una nueva rutina de servicio',
            SERVICE_ROUTINE_EDIT: 'Editar rutina de servicio',
            SERVICE_ROUTINE_CREATED: 'Rutina de servicio creada',
            SERVICE_ROUTINE_UPDATED: 'Rutina de servicio actualizada',
            SERVICE_ROUTINE_DELETED: 'Rutina de servicio eliminada',
            SERVICE_ROUTINE_LINE_ACTION: 'Acción',
            SERVICE_ROUTINE_LINE_NOTES: 'Notas',
            SERVICE_ROUTINE_LINE_FREQUENCY: 'Frecuencia',
            SERVICE_ROUTINE_LINE: 'Línea de rutina de servicio',
            SERVICE_ROUTINE_LINE_LIST: 'Líneas de rutina de servicio',
            SERVICE_ROUTINE_LINE_ADD: 'Añadir una nueva línea de rutina de servicio',
            SERVICE_ROUTINE_LINE_EDIT: 'Editar línea de rutina de servicio',
            SERVICE_ROUTINE_LINE_CREATED: 'Línea de rutina de servicio creada',
            SERVICE_ROUTINE_LINE_UPDATED: 'Línea de rutina de servicio actualizada',
            SERVICE_ROUTINE_LINES_UPDATED: 'Líneas de rutina de servicio actualizadas',
            SERVICE_ROUTINE_LINE_DELETED: 'Línea de rutina de servicio eliminada',
            REQUIRE_ASSET_WHEN_LOGGING_REACTIVE_JOB: 'Requerir activos para trabajos reactivos',
            SERVICE_PROVIDER: 'Proveedor de Servicios',
            SERVICE_PROVIDERS: 'Proveedores de Servicios',
            ADD_NEW_SERVICE_PROVIDER: 'Añadir un nuevo proveedor de servicios',
            SERVICE_PROVIDER_LIST: 'Proveedores de Servicios',
            SERVICE_PROVIDER_ADD: 'Añadir un nuevo proveedor de servicios',
            SERVICE_PROVIDER_EDIT: 'Editar proveedor de servicios',
            SERVICE_PROVIDER_CREATED: 'Proveedor de servicios creado',
            SERVICE_PROVIDER_UPDATED: 'Proveedor de servicios actualizado',
            SERVICE_PROVIDER_DELETED: 'Proveedor de servicios eliminado',
            SERVICE_PROVIDERS_SELECT: 'Seleccionar proveedores de servicios',
            SERVICE_PROVIDERS_ADD: 'Añadir proveedores de servicios',
            SERVICE_PROVIDERS_UPDATE: 'Actualizar proveedores de servicios',
            SERVICE_PROVIDERS_UPDATED: 'Proveedores de servicios actualizados',
            SERVICE_PROVIDER_PERMISSIONS_UPDATED: 'Permisos del proveedor de servicios actualizado',
            SERVICE_PROVIDERS_IN_ESCALATION: 'Proveedores de servicios en escalada',
            PRIMARY_OPERATIVES: 'Operativas principales',
            PRIMARY_OPERATIVE_TYPE: 'Tipo de operación principal',
            PRIMARY_OPERATIVE_TYPES_EDIT: 'Editar operativas principales',
            PRIMARY_OPERATIVES_UPDATED: 'Operativas principales actualizadas',
            PRIMARY_JOB_OPERATIVE: 'Trabajo',
            PRIMARY_ESCALATION_OPERATIVE: 'Escalada',
            PRIMARY_QUOTE_OPERATIVE: 'Cotización',
            PRIMARY_PATROL_OPERATIVE: 'Patrulla',
            PRIMARY_SURVEY_OPERATIVE: 'Encuesta',
            PRIMARY_AUDIT_OPERATIVE: 'Auditorías',
            PRIMARY_HEALTH_SAFETY_OPERATIVE: 'Salud y Seguridad',
            ACTIVE_AT: 'Activo desde',
            INACTIVE_AT: 'Inactivo desde',
            TRAINED_AT: 'Formado en',
            TRAINED_AT_START: 'Formado al inicio',
            TRAINED_AT_END: 'Formado al final',
            ACTIVE_AT_START: 'Activo al inicio',
            ACTIVE_AT_END: 'Activo al final',
            OPERATIVE_CREATED: 'Operativa creada',
            PERMISSIONS_UPDATED: 'Permisos actualizados',
            OPERATIVE_REMOVED: 'Operativa eliminada',
            SERVICE_PROVIDER_PARTNERSHIP_ADDED: 'Asociación añadida',
            SERVICE_PROVIDER_STATUS: 'Estado del proveedor de servicios',
            CONTRACTOR_APPROVED: 'Aprobado por el contratista',
            CONTRACTORAPPROVED: 'Aprobado por el contratista',
            NOT_APPROVED: 'No aprobado',
            SHARED: 'Compartido',
            REQUIRELABOURLINEONJOBCOMPLETE: 'Requerir la línea de empleo al completar el trabajo',
            REQUIRESIGNATURE: 'Requerir la firma al completar el trabajo',
            MARKUPQUOTES: 'Marcado de cotizaciones',
            MARKUPLABOUR: 'Marcado laboral',
            MARKUPMATERIALS: 'Marcado de materiales',
            SERVICE_PROVIDER_GROUP: 'Grupo de proveedor de servicios',
            SERVICE_PROVIDER_GROUPS: 'Grupos de proveedores de servicios',
            ADD_NEW_SERVICE_PROVIDER_GROUP: 'Añadir un nuevo grupo de proveedores de servicios',
            SERVICE_PROVIDER_GROUP_LIST: 'Grupos de proveedores de servicios',
            SERVICE_PROVIDER_GROUP_ADD: 'Añadir un nuevo grupo de proveedores de servicios',
            SERVICE_PROVIDER_GROUP_EDIT: 'Editar grupo de proveedores de servicios',
            SERVICE_PROVIDER_GROUP_CREATED: 'Grupo de proveedores de servicios creado',
            SERVICE_PROVIDER_GROUP_UPDATED: 'Grupo de proveedores de servicios actualizado',
            SERVICE_PROVIDER_GROUP_DELETED: 'Grupo de proveedores de servicios eliminado',
            REQUIRESASSETSWHENLOGGINGREACTIVEJOB: 'Los trabajos reactivos requieren activos',
            JOBVALUESAUTOAPPROVEDDAYS: 'Número de días antes de que los valores de trabajo sean aprobados automáticamente',
            JOB_VALUES_AUTO_APPROVED_DAYS_WARNING: 'Tenga en cuenta: cambiar el valor puede aprobar automáticamente una acumulación de artículos',
            REQUIRES_ASSETS_WHEN_LOGGING_REACTIVE_JOB: 'Requerir Activo',
            REQUIRES_ASSET: 'Requiere activo',
            NOT_REQUIRES_ASSET: 'No se requieren activos',
            TRAINEDAT: 'Formado en',
            ACTIVEAT: 'Activo en',
            INACTIVEAT: 'Inactivo en',
            SECTOR: 'Sector',
            SECTORS: 'Sectores',
            SECTOR_INFORMATION: 'Información del sector',
            MANAGE_SECTOR_SITES: 'Sitios en este sector',
            ADD_NEW_SECTOR: 'Añadir un nuevo sector',
            EDIT_SECTOR: 'Editar sector',
            SECTOR_LIST: 'Sectores',
            SECTOR_ADD: 'Añadir un nuevo sector',
            SECTOR_EDIT: 'Editar sector',
            SECTOR_CREATED: 'Sector creado',
            SECTOR_UPDATED: 'Sector actualizado',
            SECTOR_DELETED: 'Sector eliminado',
            SECTOR_SITE_LIST: 'Sitios',
            SECTOR_SITE_ADDED: 'Sitios del sector actualizados',
            SITES_DELETED_FROM_SECTOR: 'Sitios del sector eliminados',
            CLEAR_ALL: 'Borrar filtros',
            CLEAR: 'Borrar',
            ADVANCED_FILTERS: 'Filtros avanzados',
            VIEW_COLUMNS: 'Ver columnas',
            SATISFACTIONSURVEYS: 'Encuestas de satisfacción',
            SATISFACTIONSURVEY: 'Encuesta de satisfacción',
            SATISFACTIONSURVEY_JOBID: 'ID de trabajo',
            SATISFACTIONSURVEY_ISCOMPLETE: '¿Está completo?',
            SATISFACTIONSURVEY_REFUSED: '¿Encuesta rechazada?',
            SATISFACTIONSURVEY_EDIT: 'Editar encuesta de satisfacción',
            SATISFACTIONSURVEY_CONTACT_NAME: 'Nombre de contacto',
            SATISFACTIONSURVEY_CONTACT_PHONE: 'Teléfono de contacto',
            SATISFACTIONSURVEY_CONTACT_EMAIL: 'Correo electrónico de contacto',
            SATISFACTIONSURVEY_COMMENTS: 'Comentarios/sugerencias adicionales',
            SATISFACTIONSURVEY_QUESTION: 'Pregunta',
            SATISFACTIONSURVEY_RESPONSE: 'Respuesta',
            SATISFACTIONSURVEY_QUESTIONS: 'Preguntas de la encuesta de satisfacción',
            SATISFACTIONSURVEY_BUTTON_ADD_QUESTION: 'Añadir una pregunta',
            SATISFACTIONSURVEY_ADD_QUESTION: 'Encuesta de satisfacción: añadir una pregunta',
            SATISFACTIONSURVEY_EDIT_QUESTION: 'Encuesta de satisfacción: editar una pregunta',
            SATISFACTIONSURVEY_FILTER_INCOMPLETEREQUIRESATTENTION: 'Incompleto: requiere atención',
            SATISFACTIONSURVEY_FILTER_INCOMPLETENOATTENTION: 'Incompleto: esperando la finalización del trabajo',
            SATISFACTIONSURVEY_FILTER_COMPLETE: 'Completo',
            ROLE: 'Función',
            ROLES: 'Roles',
            ROLE_EDIT: 'Editar función',
            ROLE_LIST: 'Roles',
            ROLE_ADD: 'Añadir una nueva función',
            USER_ROLE: 'Función de Usuario',
            SITE_MEMBERSHIP_ROLE: 'Función de afiliado de sitio',
            OPERATIVE_ROLE: 'Función operativa',
            ROLE_CREATED: 'Función creada',
            ROLE_UPDATED: 'Función actualizada',
            ROLE_DELETED: 'Función eliminada',
            ROLE_PERMISSIONS: 'Permisos de función',
            ROLE_PERMISSIONS_UPDATED: 'Permisos de función actualizada',
            MANAGE_USERS: 'Gestionar usuarios',
            REQUEST_ERROR: 'Ha ocurrido un error con la solicitud. Inténtelo de nuevo más tarde.',
            THIS_ENTITY_HAS_DEPENDENCIES: 'El registro está en uso. No se puede eliminar.',
            REPORTS: 'Informes',
            REPORT_LIST: 'Informes',
            REPORT: 'Informe',
            BETWEEN_DATES: 'Entre fechas',
            SHOW_PERCENTAGES: 'Mostrar porcentajes',
            SP_SUMMARY_REPORT: 'Informe resumido del proveedor de servicios',
            SP_NAME: 'Nombre del PS',
            REACTIVE_ATTENDANCE: 'Asistencia reactiva',
            REACTIVE_MISSED_ATTENDANCE: 'Asistencia reactiva perdida',
            REACTIVE_COMPLETION: 'Finalización reactiva',
            REACTIVE_MISSED_COMPLETION: 'Finalización reactiva perdida',
            PPM_ATTENDANCE: 'Asistencia PPM',
            PPM_MISSED_ATTENDANCE: 'Asistencia perdida de PPM',
            PPM_COMPLETION: 'Finalización PPM',
            PPM_MISSED_COMPLETION: 'Finalización perdida PPM',
            QUOTES: 'Cotizaciones',
            EXTENSION_REQUEST: 'Solicitud de ampliación',
            REOPENED_JOBS: 'Trabajos reabiertos',
            OVERDUE_PLANNED_ATTENDANCE: 'Asistencia planificada vencida',
            OVERDUE_PLANNED_COMPLETION: 'Finalización planificada vencida',
            OVERDUE_PLANNED: 'Planificado vencido',
            COMPLETED_DUE: 'Completado vencido',
            ATTEND_DUE: 'Asistencia vencida',
            COMPLETED: 'Completado',
            ATTENDED: 'Asistió',
            EXTENSION_REQUESTS: 'Solicitudes de ampliación',
            JOB_ESCALATIONS: 'Escaladas de trabajo',
            QUOTE_ESCALATIONS: 'Escaladas de cotización',
            EXTENSION_FROM: 'Ampliación desde',
            EXTENSION_TO: 'Ampliación a',
            EXTENSION_STATUS: 'Estado de extensión',
            QUOTES_COMPLETED: 'Cotizaciones completadas',
            QUOTES_MISSED: 'Cotizaciones perdidas',
            LAST_REOPENED_AT: 'Última reapertura en',
            SERVICE_PROVIDER_SUMMARY: 'Resumen de proveedor de servicios',
            FM_NAME: 'Nombre FM',
            APPROVER_NAME: 'Nombre del aprobador',
            PLANNED_ATTENDANCE: 'Asistencia planificada',
            PLANNED_COMPLETION: 'Finalización planificada',
            PLANNED_WORK: 'Trabajo planeado',
            REACTIVE_WORK: 'Trabajo reactivo',
            KPI_SURVEYS: 'Encuestas de KPI',
            KPI_GROUPS: 'KPI - Grupos',
            LAST_LOGGED_IN: 'Último inicio de sesión',
            SITES_WITH_PPM_REQUIRING_APPROVAL_CONCISE: 'Sitios - aprobaciones de PPM',
            JOBS_APPROVED: 'Trabajos aprobados',
            AVERAGE_TIME_TAKEN: 'Tiempo promedio empleado',
            AVERAGE_TIME_ACROSS_ENTIRE_SYSTEM: 'Tiempo promedio en todo el sistema',
            QUOTES_APPROVED: 'Cotizaciones aprobadas',
            SITES_WITH_QUOTES_REQUIRING_APPROVAL: 'Sitios - Aprobaciones de cotizaciones',
            LIVE_STAT: 'Esta es una estadística en vivo. El filtro intervalo de fechas no se aplica a las siguientes cifras.',
            KPI_SURVEYS_OUTSTANDING: 'Encuestas de KPI pendientes',
            CUSTOMER_GROUP: 'Grupo de clientes',
            QUOTES_REQUIRING_APPROVAL: 'Cotizaciones que requieren aprobación',
            TASKS_REQUIRING_APPROVAL: 'Tareas que requieren aprobación',
            SUBMITTED_DATE: 'Fecha de envío',
            PPM_ON_TRACK: 'PPM en marcha',
            PPM_REQUIRING_ATTENTION: 'PPM que requiere atención',
            REACTIVE_ON_TRACK: 'Reactivo en marcha',
            REACTIVE_REQUIRING_ATTENTION: 'Reactivo que requiere atención',
            ACTIVITY_REPORT: 'Informe de actividad',
            REACTIVE_PAPERWORK_FOR_REVIEW: 'Papeleo reactivo para revisión',
            PLANNED_PAPERWORK_FOR_REVIEW: 'Papeleo planificado para revisión',
            PAPERWORK_FOR_REVIEW: 'Papeleo para revisión',
            REACTIVE_ESCALATED_TO_CLIENT: 'Reactivo escalado a cliente',
            PLANNED_ESCALATED_TO_CLIENT: 'Reactivo planificado a cliente',
            PPM_REQUIRING_APPROVAL: 'PPM que requiere aprobación',
            OUTSTANDING_KPI_SURVEYS: 'Encuestas de KPI pendientes',
            RAISED_DATE: 'Fecha de presentación',
            INVALID_FM: 'FM inválido',
            UNKNOWN: 'Desconocido',
            REPORT_QUEUED: 'Informe en cola',
            AVERAGE_TIME_ACROSS_SYSTEM: 'Tiempo promedio en todo el sistema',
            SITE_QUOTES_REQUIRING_APPROVAL: 'Sitios con cotizaciones que requieren aprobación',
            AGE_OF_APPROVALS: 'Edad de aprobaciones',
            AVERAGE: 'Promedio',
            SUMMARY_REPORT: 'Resumen',
            JOBS_BY_REGION: 'Trabajos por región',
            JOBS_BY_PRIORITY: 'Trabajos por prioridad',
            JOBS_BY_JOB_GROUP: 'Trabajos por grupo de trabajo',
            JOBS_BY_SERVICEPROVIDER: 'Trabajos por proveedor de servicios',
            JOBS_BY_SITE_GROUP: 'Trabajos por grupo de sitio',
            JOBS_BY_SITE: 'Trabajos por sitio',
            QUOTES_BY_SITE: 'Cotizaciones por sitio',
            QUOTES_BY_SP: 'Cotizaciones por PS',
            QUOTES_BY_SERVICEPROVIDER: 'Cotizaciones por proveedor de servicios',
            JOB_GROUP: 'Grupo de trabajo',
            JOBGROUP: 'Grupo de trabajo',
            TOTAL_JOBS: 'Trabajos totales',
            OPEN_JOBS: 'Trabajos abiertos',
            COMPLETED_JOBS: 'Trabajos completados',
            MISSED_JOBS: 'Trabajos perdidos',
            ATTENDED_ON_TIME: 'Atendido a tiempo',
            COMPLETED_ON_TIME: 'Completado a tiempo',
            TOTAL_QUOTES: 'Cotizaciones totales',
            ACCEPTED_QUOTES: 'Cotizaciones aceptadas',
            AVERAGE_TIME_TO_QUOTE: 'Tiempo medio para cotizar',
            DECLINED_QUOTES: 'Cotizaciones denegadas',
            EXPIRED_QUOTES: 'Cotizaciones caducadas',
            REJECTED_QUOTES: 'Cotizaciones rechazadas',
            TOTAL_KPI: 'Encuestas de KPI totales',
            AVERAGE_SCORE: 'Puntuación media',
            ATTENDANCE_DUE_DATE: 'Fecha límite de asistencia',
            COMPLETION_DUE_DATE: 'Fecha límite de finalización',
            DATE_ATTENDED: 'Fecha en la que asistió',
            DATE_COMPLETED: 'Fecha en que se completó',
            REPORTING_PERIOD: 'Período de informe (m/A)',
            SERVICEPROVIDER_SCORE: 'Puntuación PS',
            CLIENT_SCORE: 'Puntuación del cliente',
            TARGET_SCORE: 'Puntuación objetivo',
            PPM_GAP_ANALYSIS: 'Análisis de discrepancias de PPM',
            PRIMARY_PPM_APPROVER: 'Aprobador principal de PPM',
            PRIMARY_FM: 'FM principal',
            OPENCLOSE_OPEN: 'Abierto tarde',
            OPENCLOSE_CLOSE: 'Cierre temprano',
            OPEN_CLOSE: 'Abierto tarde / Cierre temprano',
            EARLY_OPEN: 'Abierto tarde',
            LATE_CLOSE: 'Cierre temprano',
            BOTH: 'Ambos',
            ENGINEER_RISK_REPORT: 'Resumen de riesgos de ingeniería',
            ENGINEERING_RISK_REPORT_TOTALS: 'Totales',
            COMPLIANT: 'Conforme',
            UPCOMING: 'Próximamente',
            MONTHLY_REPORT: 'Mensualmente',
            KPI_MONTHLY_REPORT: 'KPI mensual',
            COMPLIANCE_DUPLICATE: 'Existen registros para el grupo seleccionado, el mes y el año.',
            NO_ACTIVE_SITES: 'No hay sitios activos para el grupo, el mes y el año seleccionados.',
            PORTFOLIO_COMPLIANCE_REPORT: 'Cumplimiento de cartera',
            PORTFOLIO_COMPLIANCE_REPORT_ADD_LINE: 'Añadir línea de cumplimiento de cartera',
            NUMBER_OF_ERRORS: 'N.° errores',
            SP_GROUP: 'Grupo de PS',
            ACTIVE_SITES: 'Sitios activos',
            ERRORS: 'Errores',
            DAYS_LATE: 'Días de retraso',
            QUOTES_COMPLETION: 'Cotización completada',
            MONTHLY_REPORT_DATA: 'Tabla',
            MONTHLY_REPORT_GRAPH: 'Gráfica',
            ENGINEERING_RISK_REPORT_MONTHS_LIMIT_WARNING: 'El intervalo de fechas seleccionado incluye más de 24 meses. La tabla de datos y la exportación se limitarán a 24 meses.',
            MONTHLY_REPORT_MONTHS_LIMIT_WARNING: 'El intervalo de fechas seleccionado incluye más de 12 meses. La tabla de datos y el gráfico se limitarán a 12 meses, pero la exportación incluirá hasta 36 meses de datos.',
            KPI_MONTHLY_REPORT_MONTHS_LIMIT_WARNING: 'El intervalo de fechas seleccionado incluye más de 12 meses. La tabla de datos se limitará a 12 meses, pero la exportación incluirá hasta 36 meses de datos.',
            SELECT_SURVEY: 'Seleccionar encuesta',
            AUDIT_TREND_REPORT: 'Informe de tendencia de auditoría',
            QUESTION_SUMMARY: 'Resumen de la pregunta',
            ANSWER_LIST: 'Lista de respuestas',
            AUDIT_ID: 'ID de auditoría',
            COMMENT_SUPPLIED: 'Comentario proporcionado',
            DATE_ANSWERED: 'Fecha de respuesta',
            AUDIT_REFERENCE: 'Referencia de auditoría',
            AVE: 'Ave',
            SP_USAGE: 'Uso de PS',
            SP_USAGE_REPORT: 'Informe de uso de PS',
            SHOW_RESULTS: 'Mostrar resultados',
            ONLINE: 'En línea',
            MISSED_REACTIVE_SLA: 'SLA reactivo perdido',
            MISSED_PPMS: 'PPM perdidos',
            LATE_QUOTES: 'Cotizaciones tardías',
            ESCALATED_JOBS: 'Trabajos escalados',
            SP_OPERATIVE: 'Operativa de PS',
            DATE_SUBMITTED: 'Fecha de envío',
            DATE_FIRST_ESCALATED: 'Fecha de primera escalada',
            SUBMISSION_DUE: 'Vencimiento de la presentación',
            REPORT_TYPE: 'Tipo de informe',
            RESIDENTIAL_LOCATION: 'Ubicación residencial',
            RESIDENTIAL_LOCATION_DETAILS: 'Ubicación residencial',
            OPERATIVE_PERFORMANCE: 'Rendimiento de operativa',
            OPERATIVE_PERFORMANCE_REPORT: 'Informe de rendimiento operativo',
            OPERATIVE_PERFORMANCE_SP: 'Seleccionar un proveedor de servicios',
            REPORT_PERMISSIONS: 'Permisos de informes',
            REPORT_PERMISSIONS_UPDATED: 'Permisos de informes actualizados',
            USER_PERMISSION_REPORT_SP_SUMMARY: 'Resumen de proveedor de servicios',
            USER_PERMISSION_REPORT_SP_SUMMARY_DETAIL: 'La capacidad de acceder al informe de resumen del proveedor de servicios',
            USER_PERMISSION_REPORT_SITE_ASSOCIATE_ACTIVITY: 'Actividad del asociado del sitio',
            USER_PERMISSION_REPORT_SITE_ASSOCIATE_ACTIVITY_DETAIL: 'La capacidad de acceder al informe de actividad del asociado del sitio',
            USER_PERMISSION_REPORT_APPROVAL_AGE: 'Edad de aprobaciones',
            USER_PERMISSION_REPORT_APPROVAL_AGE_DETAIL: 'La capacidad de acceder al informe de edad de aprobación',
            USER_PERMISSION_REPORT_SUMMARY: 'Resumen',
            USER_PERMISSION_REPORT_SUMMARY_DETAIL: 'La capacidad de acceder al informe de resumen',
            USER_PERMISSION_REPORT_PPM_GAP_ANALYSIS: 'Análisis de discrepancias de PPM',
            USER_PERMISSION_REPORT_PPM_GAP_ANALYSIS_DETAIL: 'La capacidad de acceder al informe de análisis de discrepancias de PPM',
            USER_PERMISSION_REPORT_OPEN_CLOSE: 'Informe de Abierto tarde / Cierre temprano',
            USER_PERMISSION_REPORT_OPEN_CLOSE_DETAIL: 'La capacidad de acceder al informe de Abierto tarde / cierre temprano',
            USER_PERMISSION_REPORT_MONTHLY: 'Mensualmente',
            USER_PERMISSION_REPORT_MONTHLY_DETAIL: 'La capacidad de acceder al informe mensual',
            USER_PERMISSION_REPORT_KPI_MONTHLY: 'KPI mensual',
            USER_PERMISSION_REPORT_KPI_MONTHLY_DETAIL: 'La capacidad de acceder al informe mensual de KPI',
            USER_PERMISSION_REPORT_ENGINEERING_RISK: 'Resumen de riesgos de ingeniería',
            USER_PERMISSION_REPORT_ENGINEERING_RISK_DETAIL: 'La capacidad de acceder al informe de resumen de riesgos de ingeniería',
            USER_PERMISSION_REPORT_PORTFOLIO_COMPLIANCE: 'Informe de cumplimiento de la cartera',
            USER_PERMISSION_REPORT_PORTFOLIO_COMPLIANCE_DETAIL: 'La capacidad de acceder al informe de cumplimiento de la cartera',
            USER_PERMISSION_REPORT_AUDIT_TREND: 'Tendencia de auditoría',
            USER_PERMISSION_REPORT_AUDIT_TREND_DETAIL: 'La capacidad de acceder al informe de tendencias de auditoría',
            USER_PERMISSION_REPORT_SP_USAGE: 'Informe de uso de PS',
            USER_PERMISSION_REPORT_SP_USAGE_DETAIL: 'La capacidad de acceder al informe de uso de PS',
            USER_PERMISSION_REPORT_RESIDENTIAL_LOCATION: 'Informe de ubicación residencial',
            USER_PERMISSION_REPORT_RESIDENTIAL_LOCATION_DETAIL: 'La capacidad de acceder al Informe de ubicación residencial',
            USER_PERMISSION_REPORT_OPERATIVE_PERFORMANCE: 'Informe de rendimiento operativo',
            USER_PERMISSION_REPORT_OPERATIVE_PERFORMANCE_DETAIL: 'La capacidad de acceder al Informe de rendimiento operativo',
            LEGEND_JOB_REACTIVE_OVERDUE_STATUTORY_ATTENDANCE: 'Normativa vencida',
            LEGEND_JOB_REACTIVE_OVERDUE_NON_STATUTORY_ATTENDANCE: 'Vencido No normativo',
            LEGEND_JOB_REACTIVE_NOT_OVERDUE_ATTENDANCE: 'Abrir',
            LEGEND_JOB_REACTIVE_OVERDUE_STATUTORY_COMPLETION: 'Normativa vencida',
            LEGEND_JOB_REACTIVE_OVERDUE_NON_STATUTORY_COMPLETION: 'Vencido No normativo',
            LEGEND_JOB_REACTIVE_NOT_OVERDUE_COMPLETION: 'Abrir',
            LEGEND_JOB_PLANNED_OVERDUE_STATUTORY_COMPLETION: 'Normativa vencida',
            LEGEND_JOB_PLANNED_OVERDUE_NON_STATUTORY_COMPLETION: 'Vencido No normativo',
            LEGEND_JOB_PLANNED_OVERDUE_COMPLETION: 'Vencido',
            LEGEND_JOB_PLANNED_NOT_OVERDUE_COMPLETION: 'Abrir',
            LEGEND_ACTION_OPEN: 'Abrir',
            LEGEND_ACTION_COMPLETED: 'Completado',
            LEGEND_AUDIT_CREATED: 'Abrir',
            LEGEND_AUDIT_STARTED: 'En curso',
            LEGEND_AUDIT_COMPLETED: 'Completado',
            REGION: 'Región',
            REGIONS: 'Regiones',
            REGION_INFORMATION: 'Información de la región',
            MANAGE_REGION_SITES: 'Sitios en esta región',
            ADD_NEW_REGION: 'Añadir una nueva región',
            EDIT_REGION: 'Editar región',
            REGION_LIST: 'Regiones',
            REGION_ADD: 'Añadir una nueva región',
            REGION_EDIT: 'Editar región',
            REGION_CREATED: 'Región creada',
            REGION_UPDATED: 'Región actualizada',
            REGION_DELETED: 'Región eliminada',
            REGION_SITE_ADDED: 'Sitios de región actualizados',
            REGION_SITE_LIST: 'Sitios',
            QUOTE: 'Cotización',
            QUOTE_WITH_ID: 'Cotización {{ id }}',
            QUOTE_LIST: 'Cotizaciones',
            QUOTE_VIEW: 'Cotización',
            QUOTE_SUMMARY: 'Resumen de cotización',
            QUOTE_DESCRIPTION: 'Descripción de cotización',
            QUOTE_PRIORITY: 'Prioridad de cotización',
            QUOTE_ADD: 'Añadir una nueva cotización',
            QUOTE_CREATED: 'Cotización creada',
            QUOTE_UPDATED: 'Cotización actualizada',
            QUOTE_DELETED: 'Cotización eliminada',
            QUOTE_ID: 'Cotización {{ id }}',
            QUOTE_DETAILS: 'Detalles de cotización',
            QUOTE_STATUS: 'Estado',
            QUOTE_CONTACTS: 'Contactos de cotización',
            QUOTE_AUDIT_TRAIL_SENT: 'Pista de auditoría enviada',
            QUOTE_CONTACT: 'Contacto de cotización',
            QUOTE_CONTACT_LIST: 'Contactos de cotización',
            QUOTE_CONTACT_ADD: 'Añadir',
            QUOTE_CONTACT_CREATED: 'Contacto de cotización creado',
            QUOTE_CONTACT_UPDATED: 'Contacto de cotización actualizado',
            QUOTE_CONTACT_DELETED: 'Contacto de cotización eliminado',
            QUOTE_WORKFLOW_ABSTAIN: 'Declinar cotizar',
            QUOTE_WORKFLOW_ACCEPT: 'Aceptar',
            QUOTE_WORKFLOW_CANCEL: 'Cancelar',
            QUOTE_WORKFLOW_HOLD: 'Mantener',
            QUOTE_WORKFLOW_REJECT: 'Rechazar',
            QUOTE_WORKFLOW_REQUEST_MORE_INFORMATION: 'Solicitar más información',
            QUOTE_WORKFLOW_SUBMIT: 'Enviar',
            QUOTE_WORKFLOW_REOPEN: 'Reabrir',
            QUOTE_WORKFLOW_ESCALATE: 'Escalar',
            QUOTE_WORKFLOW_ABSTAIN_SUBMIT: 'Declinar',
            QUOTE_WORKFLOW_ACCEPT_SUBMIT: 'Aceptar cotización',
            QUOTE_WORKFLOW_CANCEL_SUBMIT: 'Cancelar cotización',
            QUOTE_WORKFLOW_HOLD_SUBMIT: 'Mantener',
            QUOTE_WORKFLOW_REJECT_SUBMIT: 'Rechazar cotización',
            QUOTE_WORKFLOW_REQUEST_MORE_INFORMATION_SUBMIT: 'Solicitar más información',
            QUOTE_WORKFLOW_SUBMIT_SUBMIT: 'Enviar cotización',
            QUOTE_WORKFLOW_REOPEN_SUBMIT: 'Reabrir',
            QUOTE_WORKFLOW_ESCALATE_SUBMIT: 'Escalar',
            QUOTE_WORKFLOW_ESCALATE_APPROVAL_SUBMIT: 'Escalar',
            QUOTE_WORKFLOW_SUBMIT_SUCCESS: 'Cotización enviada',
            QUOTE_WORKFLOW_ESCALATE_SUCCESS: 'Cotización escalada',
            QUOTE_WORKFLOW_ALARM: 'Editar alarma',
            QUOTE_WORKFLOW_ALARM_SUCCESS: 'Alarma actualizada',
            QUOTE_WORKFLOW_ALARM_SUBMIT: 'Actualizar',
            QUOTE_WORKFLOW_ALARM_SETNEW: 'Establecer alarma',
            QUOTE_WORKFLOW_ALARM_SETNEW_SUCCESS: 'Ajuste de alarma',
            QUOTE_WORKFLOW_ALARM_SETNEW_SUBMIT: 'Establecer',
            QUOTE_WORKFLOW_POSTPONE_REMINDER_AT: 'Posponer acción',
            QUOTE_WORKFLOW_POSTPONE_REMINDER_AT_SUBMIT: 'Posponer',
            QUOTE_WORKFLOW_POSTPONE_REMINDER_AT_SUCCESS: 'Cotización aplazada',
            QUOTE_WORKFLOW_REJECT_EXTENSION: 'Rechazar ampliación',
            QUOTE_WORKFLOW_REJECT_EXTENSION_SUBMIT: 'Rechazar',
            QUOTE_WORKFLOW_ACCEPT_EXTENSION: 'Aceptar ampliación',
            QUOTE_WORKFLOW_ACCEPT_EXTENSION_SUBMIT: 'Aceptar',
            QUOTE_WORKFLOW_CANCEL_EXTENSION: 'Cancelar ampliación',
            QUOTE_WORKFLOW_CANCEL_EXTENSION_SUBMIT: 'Cancelar ampliación',
            QUOTE_WORKFLOW_REQUEST_EXTENSION: 'Solicitar ampliación',
            QUOTE_WORKFLOW_REQUEST_EXTENSION_SUBMIT: 'Enviar',
            QUOTE_WORKFLOW_ACCEPT_EXTENSION_SUCCESS: 'Ampliación de cotización aceptada',
            QUOTE_WORKFLOW_REJECT_EXTENSION_SUCCESS: 'Ampliación de cotización rechazada',
            QUOTE_WORKFLOW_REQUEST_EXTENSION_SUCCESS: 'Ampliación de cotización solicitada',
            QUOTE_WORKFLOW_CANCEL_EXTENSION_SUCCESS: 'Ampliación de cotización cancelada',
            REQUESTED_EXTENSION: 'Ampliación solicitada',
            QUOTE_WORKFLOW_ESCALATE_APPROVAL_SUCCESS: 'Aprobación de cotización escalada',
            QUOTE_WORKFLOW_REQUEST_MORE_INFORMATION_SUCCESS: 'Solicitud de más información correcta',
            NEW_COMPLETION_DATE_MUST_BE_AFTER_TODAY: 'La nueva fecha de finalización debe ser posterior a hoy',
            NEW_COMPLETION_DATE_MUST_BE_AFTER_CURRENT_COMPLETION_DATE: 'La nueva fecha de finalización debe ser posterior a la fecha de finalización actual',
            QUOTE_APPROVER_NOT_SET: 'No se estableció ningún aprobador de cotizaciones, no se puede enviar la cotización',
            QUOTE_STATUS_CREATED: 'Creada',
            QUOTE_STATUS_REQUESTED: 'Solicitada',
            QUOTE_STATUS_SUBMITTED: 'Aprobación pendiente',
            QUOTE_STATUS_ACCEPTED: 'Aceptado',
            QUOTE_STATUS_REJECTED: 'Rechazado',
            QUOTE_STATUS_CANCELLED: 'Cancelada',
            QUOTE_STATUS_EXPIRED: 'Caducado',
            QUOTE_STATUS_ABSTAINED: 'Rechazó cotizar',
            QUOTE_STATUS_PENDING: 'En espera',
            QUOTE_STATUS_INFORMATION_REQUESTED: 'Más información solicitada',
            QUOTE_STATUS_EXTENSION_REQUESTED: 'Ampliación solicitada',
            QUOTE_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> creó la cotización',
            QUOTE_SUBMITTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> envió la cotización".',
            QUOTE_MORE_INFORMATION_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> solicitó más información".',
            QUOTE_ABSTAINED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> rechazó cotizar.',
            QUOTE_REOPENED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> reabrió la cotización.',
            QUOTE_PUT_ON_HOLD_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> puso la cotización en espera.',
            QUOTE_APPROVAL_RESENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> volvió a enviar una solicitud de aprobación a {{ ::event._links["approver"].title }}".',
            QUOTE_APPROVAL_ESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> escaló la aprobación de cotización.',
            QUOTE_POSTPONE_REMINDER_AT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> cambió la acción requerida por fecha {{ ::event.reminderAt | date:\'short\' }}".',
            QUOTE_APPROVAL_RESENT: 'Solicitud de aprobación de cotización reenviada.',
            QUOTE_WORKFLOW_RESEND_APPROVAL: 'Reenviar solicitud de aprobación',
            QUOTE_ESCALATION_ADDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> escaló la cotización".',
            QUOTE_ESCALATION_ADDED_SYSTEM_ACTION: 'El sistema escaló automáticamente la cotización.',
            QUOTE_ESCALATION_REMOVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> eliminó la cotización de la escalada".',
            QUOTE_ESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> escaló la cotización".',
            QUOTE_ESCALATED_SYSTEM_ACTION: 'El sistema escaló automáticamente la cotización.',
            QUOTE_DEESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> eliminó la cotización de la escalada".',
            QUOTE_DEESCALATED_SYSTEM_ACTION: 'El sistema eliminó automáticamente la cotización de la escalada.',
            QUOTE_ESCALATION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }} </strong> canceló la escalada".',
            QUOTE_WORKFLOW_ESCALATE_APPROVAL: 'Escalar aprobación',
            QUOTE_CHASED_ACTION: 'Se ha enviado una notificación de búsqueda n.º {{ ::event.chaseSequence + 1 }} a <strong>{{ ::event._links["user"].title }}</strong>".',
            QUOTE_ALARM_UPDATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> actualizó la alarma".',
            QUOTE_MESSAGE_ACTION: '<strong>{{ ::message._links["sender"].title }}</strong> proporcionó una actualización".',
            QUOTE_EXTENSION_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> solicitó una ampliación".',
            QUOTE_EXTENSION_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> rechazó la solicitud de ampliación".',
            QUOTE_EXTENSION_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> aceptó la solicitud de ampliación".',
            QUOTE_EXTENSION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> canceló la solicitud de ampliación ".',
            QUOTE_AUDIT_TRAIL_SENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> envió la pista de auditoría".',
            QUOTE_APPROVAL_REQUIRED: 'Aprobaciones de cotización',
            REQUEST_MORE_INFORMATION_SUCCESS: 'Solicitó más información',
            QUOTE_REQUEST_REPORTER: 'Detalles de contacto de sitio',
            PLEASE_SELECT_QUOTE_OPTIONS: 'Seleccione las opciones de cotización',
            QUOTE_ACCEPTED: 'Cotización aceptada',
            CANCEL_SUCCESS: 'Cotización cancelada',
            REOPEN_SUCCESS: 'Cotización reabierta',
            ABSTAIN_SUCCESS: 'Cotización denegada',
            SUBMIT_SUCCESS: 'Cotización enviada',
            REJECT_SUCCESS: 'Cotización rechazada',
            HOLD_SUCCESS: 'Cotización mantenida',
            DATE_DUE: 'Fecha de vencimiento',
            DATE_IN: 'Fecha en',
            NO_SERVICE_PROVIDERS_FOR_SITE: 'Sin proveedores de servicios para el sitio',
            QUANTITY: 'Cantidad',
            PO_NUMBER: 'Número de orden de compra',
            JOBTO: 'A trabajo',
            QUOTE_ACCEPTED_ACTION: 'Cotización aceptada por <strong>{{event._links.user.title}}</strong>',
            QUOTE_CANCELLED_ACTION: 'Cotización cancelada por <strong> {{event._links.user.title}}</strong>',
            QUOTE_EXPIRED_SYSTEM_ACTION: 'Esta cotización ha caducado.',
            QUOTE_HOLD_ACTION: 'Cotización puesta en espera por <strong>{{event._links.user.title}}</strong>',
            QUOTE_REJECTED_ACTION: 'Cotización rechazada por <strong>{{event._links.user.title}}</strong>',
            QUOTE_RFMI_ACTION: 'Solicite más información por <strong>{{event._links.user.title}}</strong>',
            QUOTE_SUBMIT_ACTION: 'Cotización enviada por <strong>{{event._links.user.title}}</strong>',
            QUOTE_ACTIONED_ACTION: 'Se adelantó el plazo para la acción requerida.',
            P2P: 'P2P',
            P2P_CIS: 'CIS',
            P2P_TAX_CODE: 'Código de impuesto',
            P2P_COMMENT: 'Comentario',
            P2P_DESCRIPTION: 'Descripción',
            P2P_TRANSACTION: 'Tipo de transacción',
            P2P_SUPPLIER: 'Proveedor',
            P2P_SCHEDULE_NUMBER: 'Número de horario',
            P2P_NOMINAL_LINES: 'Líneas nominales',
            P2P_EXPENSE_CODE: 'Código de gasto',
            P2P_TENANT_CODE: 'Código de arrendatario',
            P2P_RATIO: 'Relación (% de coste)',
            P2P_PO_SUPPLIER: 'Proveedor P2P',
            ADD_LINE: 'Añadir línea',
            REMOVE_LINE: 'Eliminar una línea',
            P2P_ERROR_QUOTE_PENDING: 'Cotización P2P pendiente',
            P2P_ERROR_DESCRIPTION_TOO_LONG: 'La descripción supera los 40 caracteres',
            P2P_ERROR_JOB_HAS_PO_NUMBER: 'El trabajo ya tiene un número de orden de compra',
            P2P_ERROR_INVALID_SITE_OR_USER: 'Sitio o usuario no válidos',
            P2P_ERROR_RATIOS_INVALID: 'Los intervalos deben ser números enteros y sumar 100 en todas las líneas',
            NO_SEARCH_PROVIDED: 'No se proporcionó búsqueda',
            MISSING_ARGUMENT: 'Falta argumento',
            QUOTE_OPTIONS_TAB_FILES: 'Utilice la pestaña Opciones de cotización para añadir archivos relacionados con una opción de cotización individual',
            LOCATION_REQUIRED_QUOTE: 'Requerir ubicación para cotizaciones reactivas',
            QUOTE_ABSTAINED: 'Rechazó cotizar',
            QUOTE_EXTENSION_CANCELLED: 'Ampliación de cotización solicitada cancelada',
            QUOTE_EXTENSION_REQUESTED: 'Ampliación de cotización solicitada',
            QUOTE_EXTENSION_ACCEPTED: 'Ampliación de cotización aceptada',
            QUOTE_EXTENSION_REJECTED: 'Ampliación de cotización rechazada',
            QUOTE_SUBMITTED: 'Cotización enviada',
            QUOTE_MESSAGE: 'Actualizaciones de cotización añadidas',
            QUOTE_THRESHOLD: 'Umbral de cotización',
            QUOTE_THRESHOLDS: 'Umbrales de cotización',
            QUOTE_THRESHOLD_ADD: 'Añadir un nuevo umbral de cotización',
            QUOTE_THRESHOLD_EDIT: 'Editar umbral de cotización',
            QUOTE_THRESHOLD_LIST: 'Umbrales de cotización',
            FEWER_APPROVERS_THAN_THRESHOLDS: 'Se requieren más aprobadores de cotizaciones para cubrir todos los umbrales de cotizaciones',
            QUOTE_REQUEST_ADD_SITE: 'Sitio',
            QUOTE_REQUEST_ADD_CONTACT_DETAILS: 'Detalles de contacto de sitio',
            QUOTE_REQUEST_ADD_DETAILS: 'Detalles de cotización',
            INCLUDE_NEVER_SUBMITTED: 'Incluir nunca enviado',
            QUOTE_REQUEST: 'Solicitud de cotización',
            QUOTE_REQUESTS: 'Solicitudes de cotización',
            QUOTE_REQUEST_LIST: 'Solicitudes de cotización',
            QUOTE_REQUEST_ADD: 'Añadir una nueva solicitud de cotización',
            QUOTE_REQUEST_CREATED: 'Solicitud de cotización creada',
            QUOTE_REQUEST_UPDATED: 'Solicitud de cotización actualizada',
            QUOTE_REQUEST_DELETED: 'Solicitud de cotización eliminada',
            QUOTE_REQUEST_DETAILS: 'Detalles de solicitud de cotización',
            QUOTE_REQUEST_CONTACTS: 'Contactos para solicitar cotización',
            QUOTE_REQUEST_STATUS_CREATED: 'Creada',
            QUOTE_REQUEST_STATUS_REQUESTED: 'Solicitada',
            QUOTE_REQUEST_STATUS_PART_QUOTED: 'Cotizada parcialmente',
            QUOTE_REQUEST_STATUS_QUOTED: 'Cotizada',
            QUOTE_REQUEST_STATUS_REJECTED: 'Rechazado',
            QUOTE_REQUEST_STATUS_ACCEPTED: 'Aceptado',
            QUOTE_REQUEST_STATUS_CANCELLED: 'Cancelada',
            QUOTE_REQUEST_STATUS_EXPIRED: 'Caducado',
            QUOTE_REQUEST_STATUS_DECLINED: 'Denegada',
            QUOTE_REQUEST_STATUS_EXTENSION_PENDING: 'Ampliación solicitada',
            CREATED_FROM_JOB: 'Creado a partir del trabajo',
            QUOTE_WORKFLOW_REOPEN_SUCCESS: 'Solicitud de reabrir cotización correcta',
            QUOTE_WORKFLOW_CANCEL_SUCCESS: 'Solicitud de cancelar cotización correcta',
            NEW_COMPLETION_DATE_MUST_BE_AFTER: 'La nueva fecha de finalización debe ser posterior a la fecha de finalización actual',
            SUPPORTING_DOCUMENT: 'Archivos de apoyo',
            AT_LEAST_ONE_SERVICE_PROVIDER_DOES_NOT_HAVE_ACTIVE_PARTNERSHIP_WITH_SITE: 'El proveedor de servicios no tiene una asociación activa con el sitio para el que presenta la solicitud de cotización',
            QUOTE_OPTION: 'Opción de cotización',
            QUOTE_OPTIONS: 'Opciones de cotización',
            QUOTE_OPTION_LIST: 'Opciones de cotización',
            QUOTE_OPTION_VIEW: 'Opción de cotización',
            QUOTE_OPTION_ADD: 'Añadir una nueva opción de cotización',
            QUOTE_OPTION_EDIT: 'Editar opción de cotización',
            QUOTE_OPTION_CREATED: 'Opción de cotización creada',
            QUOTE_OPTION_UPDATED: 'Opción de cotización actualizada',
            QUOTE_OPTION_DELETED: 'Opción de cotización eliminada',
            QUOTE_OPTION_STATUS_ACCEPTED: 'Aceptado',
            QUOTE_OPTION_STATUS_REJECTED: 'Rechazado',
            QUOTE_OPTION_STATUS_DRAFT: 'Borrador',
            QUOTE_OPTION_STATUS_SUBMITTED: 'Enviado',
            OPTION_REQUIRES_ESCLATION: 'El valor de esta opción está fuera de su umbral de aprobador, requiere escalada',
            QUOTE_ALREADY_ACCEPTED: 'Esta cotización ya ha sido aceptada',
            QUOTE_OPTION_LABOUR: 'Laboral',
            QUOTE_OPTION_LABOURS: 'Laboral',
            QUOTE_OPTION_LABOUR_LIST: 'Laboral',
            QUOTE_OPTION_LABOUR_ADD: 'Añadir una nueva línea laboral',
            QUOTE_OPTION_LABOUR_EDIT: 'Editar',
            QUOTE_OPTION_LABOUR_CREATED: 'Línea laboral creada',
            QUOTE_OPTION_LABOUR_UPDATED: 'Línea laboral actualizada',
            QUOTE_OPTION_LABOUR_DELETED: 'Línea laboral eliminada',
            QUOTE_OPTION_LABOUR_LABOUR_HOURS: 'Horas laborales',
            QUOTE_OPTION_LABOUR_TRAVEL_HOURS: 'Horas de viaje',
            QUOTE_OPTION_LABOUR_MILEAGE: 'Kilometraje',
            QUOTE_OPTION_LABOUR_VALUE: 'Valor',
            QUOTE_OPTION_MATERIAL: 'Materiales',
            QUOTE_OPTION_MATERIALS: 'Materiales',
            QUOTE_OPTION_MATERIAL_LIST: 'Materiales',
            QUOTE_OPTION_MATERIAL_ADD: 'Añadir un nuevo material',
            QUOTE_OPTION_MATERIAL_EDIT: 'Editar',
            QUOTE_OPTION_MATERIAL_CREATED: 'Material creado',
            QUOTE_OPTION_MATERIAL_UPDATED: 'Material actualizado',
            QUOTE_OPTION_MATERIAL_DELETED: 'Material eliminado',
            QUOTE_OPTION_MATERIAL_QUANTITY: 'Cantidad',
            QUOTE_OPTION_MATERIAL_QUOTED_VALUE: 'Valor cotizado',
            QUOTE_OPTION_MATERIAL_QUOTED_TAX_VALUE: 'Valor fiscal cotizado',
            QUOTE_OPTION_MATERIAL_ACTUAL_VALUE: 'Valor real',
            QUOTE_OPTION_MATERIAL_ACTUAL_TAX_VALUE: 'Valor fiscal real',
            GO_TO_JOB: 'Ir al trabajo',
            GO_TO_QUOTE: 'Ir a la cotización',
            SITE_QUICK_ACTIONS: 'Acciones rápidas en sitio',
            RAISE_JOB: 'Presentar trabajo',
            VIEW_JOBS: 'Ver trabajos',
            RAISE_QUOTE: 'Presentar cotización',
            VIEW_QUOTES: 'Ver cotizaciones',
            NO_JOB_FOUND: 'Ningún trabajo encontrado.',
            NO_QUOTE_FOUND: 'Ninguna cotización encontrada.',
            PUBLIC_HOLIDAYS: 'Días festivos',
            PUBLIC_HOLIDAYS_LIST: 'Días festivos',
            PUBLIC_HOLIDAYS_ADD: 'Añadir día festivo',
            PUBLIC_HOLIDAYS_EDIT: 'Editar día festivo',
            PUBLIC_HOLIDAY: 'Día festivo',
            PUBLIC_HOLIDAY_ADD: 'Añadir día festivo',
            PUBLIC_HOLIDAY_EDIT: 'Editar día festivo',
            PUBLIC_HOLIDAY_ADDED: 'Día festivo añadido',
            PUBLIC_HOLIDAY_UPDATED: 'Día festivo actualizado',
            PUBLIC_HOLIDAY_DELETED: 'Día festivo eliminado',
            PRIORITY: 'Prioridad',
            PRIORITIES: 'Prioridades',
            ADD_PRIORITY: 'Añadir una nueva prioridad',
            PRIORITY_LIST: 'Prioridades',
            PRIORITY_EDIT: 'Editar prioridad',
            PRIORITY_ADD: 'Añadir una nueva prioridad',
            PRIORITY_CREATED: 'Prioridad creada correctamente',
            PRIORITY_UPDATED: 'Prioridad actualizada correctamente',
            PRIORITY_DELETED: 'Prioridad eliminada',
            DEADLINE: 'Fecha límite',
            ATTEND_TIME: 'Asistir dentro',
            ATTEND_DEADLINE: 'Asistir a fecha límite',
            NON_WORKING_DAYS: 'Sin días laborables',
            INCLUDE_NON_WORKING_DAYS: 'Incluir días no laborables',
            COMPLETE_TIME: 'Completar en',
            COMPLETE_DEADLINE: 'Fecha límite de finalización',
            APPROVAL_DELAY: 'Retraso de aprobación',
            ACKNOWLEDGE_DELAY: 'Reconocer retraso',
            ALARM_WHEN_OVERDUE: 'Alarma vencida',
            ALERT_MESSAGE_ENABLED: 'Mensaje de alerta habilitado',
            ALERT_MESSAGE: 'Mensaje de alerta',
            ADD_NEW_PRIORITY: 'Añadir nueva prioridad',
            ATTENDANCE_DUE: 'Asistencia vencida',
            COMPLETION_DUE: 'Finalización vencida',
            PRIORITY_MESSAGE: 'Mensaje con prioridad',
            HELPTEXT_DELAY: 'Los valores de 0 utilizarán ajustes del sistema',
            ENTITY_TYPE: 'Tipo',
            ENTITY_TYPE_ALL: 'Todo',
            ENTITY_TYPE_JOB: 'Trabajos',
            ENTITY_TYPE_QUOTE: 'Cotizaciones',
            ENTITY_TYPE_ACTION: 'Acciones',
            SELECT_PRIORITY: 'Seleccionar prioridad',
            PERFORMANCE_STATISTICS: 'Estadísticas de rendimiento',
            PERFORMANCE_SERVICE_PROVIDER: 'Su rendimiento',
            PERFORMANCE_STATUS: 'Estado',
            PERFORMANCE_SYSTEM: 'Rendimiento medio',
            PERFORMANCE_TYPE_AVERAGE: 'Rendimiento global',
            PERFORMANCE_TYPE_REACTIVE_UPDATES_ACHIEVED: 'Actualizaciones reactivas logradas',
            PERFORMANCE_TYPE_REACTIVE_ATTENDANCE_ACHIEVED: 'Asistencia reactiva lograda',
            PERFORMANCE_TYPE_REACTIVE_COMPLETION_ACHIEVED: 'Terminación reactiva lograda',
            PERFORMANCE_TYPE_PLANNED_UPDATES_ACHIEVED: 'Actualizaciones de PPM logradas',
            PERFORMANCE_TYPE_PLANNED_COMPLETION_ACHIEVED: 'PPM completo',
            PERFORMANCE_TYPE_QUOTE_SLA_ACHIEVED: 'Cotizaciones SLA logradas',
            PERFORMANCE_TYPE_KPI_SURVEYS: 'Encuestas de KPI',
            PERFORMANCE_DASHBOARD_TYPE_AVERAGE: 'General<br />Rendimiento',
            PERFORMANCE_DASHBOARD_TYPE_REACTIVE_UPDATES_ACHIEVED: 'Reactivo<br />Actualizaciones',
            PERFORMANCE_DASHBOARD_TYPE_REACTIVE_ATTENDANCE_ACHIEVED: 'SLA reactivo<br />Asistencia',
            PERFORMANCE_DASHBOARD_TYPE_REACTIVE_COMPLETION_ACHIEVED: 'SLA reactivo<br />Finalización',
            PERFORMANCE_DASHBOARD_TYPE_PLANNED_COMPLETION_ACHIEVED: 'PPM<br />Completo',
            PERFORMANCE_DASHBOARD_TYPE_PLANNED_UPDATES_ACHIEVED: 'PPM<br />Actualizaciones',
            PERFORMANCE_DASHBOARD_TYPE_QUOTE_SLA_ACHIEVED: 'Cotización SLA<br />Finalización',
            PERFORMANCE_DASHBOARD_TYPE_KPI_SURVEYS: 'KPI<br />Encuestas',
            PERFORMANCE_INFORMATION_TITLE: 'Información de rendimiento',
            PERFORMANCE_INFORMATION_DESCRIPTION: 'Estas estadísticas se calculan como un promedio de todos los proveedores de servicios durante los 30 días anteriores.',
            PERFORMANCE_TYPE_AVERAGE_INFORMATION: 'El promedio de todos los demás porcentajes',
            PERFORMANCE_TYPE_REACTIVE_UPDATES_ACHIEVED_INFORMATION: 'Basado en el porcentaje de recordatorios de trabajos reactivos pendientes. Haciendo clic en el icono de estado se mostrarán los trabajos reactivos pendientes que deben ser actualizados.',
            PERFORMANCE_TYPE_REACTIVE_ATTENDANCE_ACHIEVED_INFORMATION: 'El porcentaje de veces que ha asistido a un trabajo reactivo dentro del plazo del SLA.',
            PERFORMANCE_TYPE_REACTIVE_COMPLETION_ACHIEVED_INFORMATION: 'El porcentaje de veces que ha completado un trabajo reactivo dentro del plazo del SLA.',
            PERFORMANCE_TYPE_PLANNED_UPDATES_ACHIEVED_INFORMATION: 'Basado en el porcentaje de recordatorios de trabajo ppm pendientes. Haciendo clic en el icono de estado se mostrarán los trabajos planificados pendientes que deben ser actualizados.',
            PERFORMANCE_TYPE_PLANNED_COMPLETION_ACHIEVED_INFORMATION: 'El porcentaje de veces que ha completado un trabajo PPM dentro del plazo del SLA.',
            PERFORMANCE_TYPE_QUOTE_SLA_ACHIEVED_INFORMATION: 'El porcentaje de veces que ha completado un trabajo de PPM dentro del plazo del SLA.',
            PERFORMANCE_TYPE_KPI_SURVEYS_INFORMATION: 'El porcentaje de veces que ha enviado una cotización dentro del plazo del SLA. Seleccionando el semáforo se mostrarán los KPI con una puntuación FM que sea inferior al 100 %.',
            PATROL: 'Patrulla',
            PATROLS: 'Patrullas',
            MANAGE_PATROLS: 'Gestionar patrullas',
            PATROL_ADD: 'Añadir una nueva patrulla',
            PATROL_EDIT: 'Editar patrulla',
            PATROL_LIST: 'Patrullas',
            PATROL_CREATED: 'Patrulla creada',
            PATROL_UPDATED: 'Patrulla actualizada',
            PATROL_DELETED: 'Patrulla eliminada',
            ORDERED: 'Pedido',
            ORDERED_YES: 'Pedido',
            ORDERED_NO: 'No pedido',
            PATROL_POINT: 'Punto de patrulla',
            PATROL_POINTS: 'Puntos de patrulla',
            PATROL_POINT_TAB: 'Puntos de patrulla',
            PATROL_POINT_LIST: 'Puntos de patrulla',
            PATROLPOINTS_COUNT: 'Puntos',
            PATROLPOINTS_TIME_COUNT: 'Duración',
            PATROL_POINT_ADD: 'Añadir punto',
            PATROL_POINT_EDIT: 'Editar punto',
            TIMEALLOCATED: 'Tiempo de preparación',
            REQUIREMENTTYPE: 'Tipo de requisito',
            REQUIREMENT: 'Requisito',
            PATROL_POINT_REQUIREMENT_NONE: 'Ninguna',
            PATROL_POINT_REQUIREMENT_BARCODE: 'Código de barras',
            PATROL_POINT_REQUIREMENT_NFC: 'NFC',
            PATROL_POINT_REQUIREMENT_PHOTO: 'Foto',
            PATROL_POINT_REQUIREMENT_SIGNATURE: 'Firma',
            PATROL_POINT_REQUIREMENT_FILE: 'Archivo',
            PATROL_POINT_REQUIREMENT_BARCODE_ID: 'Código de barras',
            PATROL_POINT_REQUIREMENT_NFC_ID: 'ID NFC',
            PATROLPOINT_CREATED: 'Punto creado',
            PATROLPOINT_UPDATED: 'Punto actualizado',
            PATROLPOINT_DELETED: 'Punto eliminado',
            PATROL_TEMPLATE: 'Plantilla de patrulla',
            PATROL_TEMPLATE_LIST: 'Plantillas de patrulla',
            PATROL_TEMPLATE_ADD: 'Añadir plantilla',
            PATROL_TEMPLATE_EDIT: 'Editar plantilla',
            PATROL_SCHEDULE: 'Horario de patrulla',
            PATROL_SCHEDULES: 'Horarios de patrulla',
            PATROL_SCHEDULES_TAB: 'Horarios de patrulla',
            PATROL_SCHEDULE_LIST: 'Horarios de patrulla',
            PATROL_SCHEDULE_ADD: 'Añadir horario',
            PATROL_SCHEDULE_EDIT: 'Editar horario',
            DAYOFWEEK: 'Día de la semana',
            DAY_OF_WEEK: 'Día de la semana',
            START_TIME: 'Hora de inicio',
            END_TIME: 'Hora de fin',
            PATROL_SCHEDULE_CREATED: 'Horario creado',
            PATROL_SCHEDULE_UPDATED: 'Horario actualizado',
            PATROL_SCHEDULE_DELETED: 'Horario eliminado',
            PATROL_OCCURRENCE: 'Ocurrencia de patrulla',
            PATROL_OCCURRENCES: 'Ocurrencias de patrulla',
            PATROL_OCCURRENCE_CREATED: 'Ocurrencia creada',
            PATROL_OCCURRENCE_UPDATED: 'Ocurrencia actualizada',
            PATROL_OCCURRENCE_DELETED: 'Ocurrencia eliminada',
            PATROL_OCCURRENCE_ADD: 'Añadir ocurrencia',
            PATROL_OCCURRENCE_EDIT: 'Editar ocurrencia',
            PATROL_OCCURRENCE_ADD_BUTTON: 'Añadir ocurrencia',
            PATROL_OCCURRENCE_ADD_BUTTON_MULTIPLE: 'Añadir múltiples ocurrencias',
            PATROL_OCCURRENCE_LIST: 'Ocurrencias de patrulla',
            PATROL_OCCURRENCE_VIEW: 'Ocurrencia de patrulla',
            OCCURRENCE: 'Ocurrencia',
            PATROL_NAME: 'Nombre de la patrulla',
            PATROL_STATUS_REGISTERED: 'Registrado',
            PATROL_STATUS_SCHEDULED: 'Programado',
            PATROL_STATUS_IN_PROGRESS: 'En curso',
            PATROL_STATUS_OVERDUE: 'Vencido',
            PATROL_STATUS_COMPLETED: 'Completado',
            PATROL_STATUS_CANCELLED: 'Cancelada',
            PATROL_STATUS_MISSED: 'Perdido',
            PATROL_STATUS_AUTO_MISSED: 'Perdido automáticamente',
            PATROL_POINT_WAITING: 'En espera',
            PATROL_POINT_MISSED: 'Perdido',
            PATROL_POINT_DONE: 'Hecho',
            PATROL_TEMPLATE_CHANGE: 'Cambio de plantilla de patrulla',
            PATROL_TEMPLATE_CHANGE_TEXT: '¿Seguro que desea cambiar la plantilla de patrulla?',
            TEMPLATE_UPDATED: 'Plantilla actualizada',
            PATROL_ID: 'ID de patrulla',
            CREATE_FROM_POINT: 'Crear desde punto',
            PATROL_INSTANCES: 'Instancias de patrulla',
            PATROL_STATISTICS: 'Estadísticas de patrulla',
            PATROL_INSTANCE_LIST: 'Lista de instancias de patrulla',
            PATROL_INSTANCE_VIEW: 'Instancia de patrulla',
            INSTANCE: 'Instancia',
            INSTRUCTIONS: 'Instrucciones',
            TIME_ALLOCATED: 'Tiempo de preparación',
            ATTENDED_AT: 'Atendida en',
            COMPLETED_AT: 'Completada en',
            PLANNED_START_DATE: 'Fecha de inicio planificada',
            PLANNED_END_DATE: 'Fecha de fin planificada',
            DATE_STARTED: 'Fecha en que fue iniciada',
            REQUIREMENT_STATUS: 'Estado del requisito',
            REQUIREMENT_CONFIRMATION: 'Confirmación de requisito',
            DOWNLOAD_FILE: 'Descargar archivo',
            POINTS: 'Puntos',
            REASON: 'Razón',
            STARTED_ON_TIME: 'Iniciado en',
            POINTS_COMPLETED: 'Puntos completados',
            NOT_STARTED_EARLY: 'No iniciado pronto',
            POINTS_REQUIREMENTS_MET: 'Requisitos de puntos cumplidos',
            MISSED_OR_INCOMPLETE: 'Falta o incompleta',
            COMPLETED_LATE: 'Completado tarde',
            STARTED_LATE: 'Iniciado tarde',
            STARTED_EARLY: 'Iniciado pronto',
            POINTS_MISSED: 'Puntos perdidos',
            POINTS_REQUIREMENTS_NOT_MET: 'Requisitos de puntos no cumplidos',
            TOTAL_NUMBER_OF_PATROLS: 'Número total de patrullas',
            PATROL_PLANNER: 'Planificador de patrulla',
            PATROL_ACTIVE: 'Patrulla activa',
            PATROL_DRAFT: 'Borrador de patrulla',
            PATROL_COMMENCED: 'Patrulla comenzada',
            PATROL_MISSED: 'Patrulla perdida',
            PATROL_COMPLETED: 'Patrulla completada',
            PATROL_POINT_COMPLETED: 'Punto de patrulla completado',
            PATROL_BULK_SCHEDULE_LOW_FREQUENCY_WARNING: 'La frecuencia está por debajo de la cantidad recomendada',
            PATROL_MISSED_REASON_PHOTO: 'Foto de razón perdida',
            GENERATE_NEW_PASSWORD: 'Generar nueva contraseña',
            RESET_PASSWORD: 'Restablecer contraseña',
            RESET_PASSWORD_INSTRUCTIONS: 'Haga clic para generar una nueva contraseña.',
            MODIFY_PASSWORD: 'Modificar contraseña',
            NEW_PASSWORD: 'Nueva contraseña',
            CONFIRM_PASSWORD: 'Confirmar contraseña',
            NEW_PASSWORD_GENERATED: 'Nueva contraseña generada.',
            LOGIN_AS_USER: 'Inicie sesión como este usuario',
            PASSWORD_IS_TOO_SHORT: 'La contraseña es demasiado corta',
            PASSWORD_SHOULD_CONTAIN_ONE_UPPERCASE: 'La contraseña debe contener al menos una mayúscula',
            PASSWORD_SHOULD_CONTAIN_ONE_NUMBER: 'La contraseña debe contener al menos un número',
            PASSWORD_SHOULD_CONTAIN_ONE_PUNCTUATION_CHAR: 'La contraseña debe contener al menos un carácter de puntuación',
            PASSWORD_SHOULD_BE_DIFFERENT_THAN_LAST_3: 'La contraseña debe ser diferente a las últimas 3 utilizadas',
            RETURN_TO_ORIGINAL_USER_FIRST: 'Primero regrese al usuario original.',
            PARTNERSHIP: 'Colaboración',
            PARTNERSHIPS: 'Asociaciones',
            ADD_NEW_PARTNERSHIP: 'Añadir una nueva asociación',
            PARTNERSHIP_ADD: 'Añadir una nueva asociación',
            PARTNERSHIPS_ADD: 'Añadir asociaciones',
            PARTNERSHIPS_LIST: 'Asociaciones',
            SERVICE_PROVIDER_PARTNERSHIP_ADD: 'Añadir una nueva asociación',
            PARTNERSHIP_LIST: 'Asociaciones',
            PARTNERSHIP_EDIT: 'Editar asociación',
            PARTNERSHIP_CREATED: 'Asociación creada',
            PARTNERSHIP_UPDATED: 'Asociación actualizada',
            PARTNERSHIP_DELETED: 'Asociación eliminada',
            PARTNERSHIP_SURVEYS_ADDED: 'Encuestas añadidas',
            PARTNERSHIP_SURVEYS_DELETED: 'Encuestas no eliminadas',
            PARTNERSHIP_STATUS: 'Estado de asociación',
            SURVEYS_ADDED: 'Encuestas añadidas',
            SURVEYS_DELETED: 'Encuestas no eliminadas',
            PARTNERSHIP_IN_USE: 'Asociación en uso',
            P2PREFERENCE: 'Referencia P2P',
            CANNOT_VALIDATE_ROLE: 'No se puede validar una función requerida',
            P2P_VALUE: 'Valor neto',
            ADD_P2P_VALUE: 'Añadir valor P2P',
            ADD_PO_NUMBER: 'Añadir número de orden de compra',
            JOB_VALUE_UPDATED: 'Valor de trabajo actualizado',
            JOB_PO_UPDATED: 'Orden de compra de trabajo actualizada',
            P2P_NOT_VALID_SITE: 'El sitio asociado a este trabajo no tiene una referencia P2P válida',
            P2P_NOT_VALID_USER: 'Su usuario no tiene una dirección de correo electrónico P2P válida',
            P2P_ELEMENT3_SAVILLS: 'Tipo de efectivo',
            P2P_ELEMENT4_SAVILLS: 'Horario',
            P2P_ELEMENT5_SAVILLS: 'Código GL',
            P2P_ELEMENT6_SAVILLS: 'Arrendar',
            P2P_ELEMENT7_SAVILLS: 'Unidad',
            P2P_ELEMENT3_KF: 'Capacidad de recuperación',
            P2P_ELEMENT4_KF: 'Horario',
            P2P_ELEMENT5_KF: 'Código GL',
            P2P_ELEMENT6_KF: 'Unidad',
            P2P_ELEMENT7_KF: 'Arrendatario',
            P2P_ELEMENT3_FSHC: 'Tipo',
            P2P_ELEMENT4_FSHC: 'Código GL',
            P2P_ELEMENT5_FSHC: 'Categoría de activo',
            P2P_ELEMENT6_FSHC: 'Código de activo',
            OPERATIVE: 'Operativa',
            OPERATIVES: 'Operativas',
            OPERATIVES_LIST: 'Operativas',
            OPERATIVES_ADD: 'Añadir una operativa',
            REMOVE_OPERATIVE: 'Quitar operativa',
            CREATE_NEW_OPERATIVE: 'Crear nueva operativa',
            ADD_SERVICE_PROVIDER: 'Añadir proveedor de servicio',
            MANAGE_OPERATIVES: 'Gestionar operativas',
            OPERATIVE_INFORMATION: 'Información operativa',
            CREATE_OPERATIVE: 'Crear operativa',
            ACTIVITY_LOG: 'Registro de actividad',
            USER_IS_ALREADY_OPERATIVE: 'Este usuario ya es un operativo',
            COPY_OPERATIVE: 'Copia de operativa existente',
            COPY: 'Copiar',
            SERVICE_PROVIDER_REQUIRED: 'Se requiere proveedor de servicios',
            SOURCE_TYPE: 'Tipo de fuente',
            SOURCE_ID: 'ID de fuente',
            ACTIVITY_VIEW: 'Actividad',
            OPERATIVE_PERMISSIONS: 'Permisos operativos',
            ADD_OPERATIVE: 'Añadir operativo',
            OPERATIVE_PERMISSION_VIEW_ASSETS: 'Ver activos',
            OPERATIVE_PERMISSION_CREATE_QUOTES: 'Crear cotizaciones',
            OPERATIVE_PERMISSION_VIEW_QUOTES: 'Ver cotizaciones',
            OPERATIVE_PERMISSION_EDIT_ASSETS: 'Editar activos',
            OPERATIVE_PERMISSION_VIEW_TASKS: 'Ver tareas',
            OPERATIVE_PERMISSION_EDIT_TASKS: 'Editar tareas',
            OPERATIVE_PERMISSION_CREATE_TASKS: 'Crear tareas',
            OPERATIVE_PERMISSION_VIEW_FILES: 'Ver archivos',
            OPERATIVE_PERMISSION_EDIT_FILES: 'Editar archivos',
            OPERATIVE_PERMISSION_VIEW_PATROL_INSTANCES: 'Ver instancias de patrulla',
            OPERATIVE_PERMISSION_EDIT_PATROL_INSTANCES: 'Editar instancias de patrulla',
            OPERATIVE_PERMISSION_RECEIVE_PATROL_INSTANCES: 'Recibir instancias de patrulla',
            OPERATIVE_PERMISSION_REASSIGN_JOB_OPERATIVE: 'Reasignar operativo de trabajo',
            OPERATIVE_PERMISSION_CREATE_REACTIVE_JOBS: 'Crear trabajos reactivos',
            OPERATIVE_PERMISSION_CREATE_REACTIVE_JOBS_FOR_OTHER_SERVICE_PROVIDERS: 'Crear trabajos reactivos para otros proveedores de servicios',
            OPERATIVE_PERMISSION_EDIT_CONTRACTS: 'Editar contratos',
            OPERATIVE_PERMISSION_EDIT_JOBS: 'Editar trabajos',
            OPERATIVE_PERMISSION_REJECT_INSIST_JOBS: 'Rechazar o insistir trabajos de proveedores de servicios compartidos',
            OPERATIVE_PERMISSION_EDIT_METER_READINGS: 'Editar lecturas de medidor',
            OPERATIVE_PERMISSION_EDIT_METERS: 'Editar medidores',
            OPERATIVE_PERMISSION_MANAGE_OPERATIVES: 'Gestionar operativas',
            OPERATIVE_PERMISSION_VIEW_AUDITS: 'Ver auditorías',
            OPERATIVE_PERMISSION_EDIT_AUDIT_REFERENCE: 'Editar auditorías de referencia',
            OPERATIVE_PERMISSION_RECEIVE_AUDITS: 'Recibir auditorías',
            OPERATIVE_PERMISSION_RECEIVE_ALL_EMAILS: 'Recibir todos los correos electrónicos',
            OPERATIVE_PERMISSION_RECEIVE_CONTRACTS: 'Recibir contratos',
            OPERATIVE_PERMISSION_RECEIVE_ESCALATIONS: 'Recibir escaladas',
            OPERATIVE_PERMISSION_RECEIVE_JOBS: 'Recibir trabajos',
            OPERATIVE_PERMISSION_REQUEST_PLANNED_JOBS: 'Solicitar trabajos planificados',
            OPERATIVE_PERMISSION_VIEW_CONTRACTS: 'Ver contratos',
            OPERATIVE_PERMISSION_VIEW_JOBS: 'Ver trabajos',
            OPERATIVE_PERMISSION_VIEW_LINK_JOBS: 'Ver enlaces para trabajos',
            OPERATIVE_PERMISSION_REMOVE_LINK_JOBS: 'Eliminar enlaces para trabajos',
            OPERATIVE_PERMISSION_VIEW_METERS: 'Ver medidores',
            OPERATIVE_PERMISSION_VIEW_SITES: 'Ver sitios',
            OPERATIVE_PERMISSION_VIEW_SURVEYS: 'Ver encuestas',
            OPERATIVE_PERMISSION_EDIT_SURVEYS: 'Editar encuestas',
            OPERATIVE_PERMISSION_CREATE_SURVEYS: 'Crear encuestas',
            OPERATIVE_PERMISSION_VIEW_CONTACTS: 'Ver contactos',
            OPERATIVE_PERMISSION_VIEW_APPROVERS: 'Ver aprobadores de sitios',
            OPERATIVE_PERMISSION_ASSET_USER_IMPORTS: 'Importar activos',
            OPERATIVE_PERMISSION_TASK_USER_IMPORTS: 'Importar tareas',
            OPERATIVE_PERMISSION_ASSET_TASK_USER_IMPORTS: 'Importar planificador',
            OPERATIVE_PERMISSION_FORCE_COMPLETE_AUDITS: 'Forzar auditorías completas',
            OPERATIVE_PERMISSION_FORCE_COMPLETE_AUDITS_DETAIL: 'La capacidad de forzar la realización de una auditoría',
            OPERATIVE_PERMISSION_EDIT_AUDIT_REFERENCE_DETAIL: 'Editar referencia de auditoría cuando la auditoría está activa',
            OPERATIVE_PERMISSION_VIEW_ASSETS_DETAIL: '',
            OPERATIVE_PERMISSION_CREATE_QUOTES_DETAIL: '',
            OPERATIVE_PERMISSION_VIEW_QUOTES_DETAIL: '',
            OPERATIVE_PERMISSION_EDIT_ASSETS_DETAIL: '',
            OPERATIVE_PERMISSION_VIEW_TASKS_DETAIL: '',
            OPERATIVE_PERMISSION_EDIT_TASKS_DETAIL: '',
            OPERATIVE_PERMISSION_CREATE_TASKS_DETAIL: '',
            OPERATIVE_PERMISSION_VIEW_FILES_DETAIL: '',
            OPERATIVE_PERMISSION_EDIT_FILES_DETAIL: '',
            OPERATIVE_PERMISSION_RECEIVE_AUDITS_DETAIL: '',
            OPERATIVE_PERMISSION_VIEW_AUDITS_DETAIL: '',
            OPERATIVE_PERMISSION_VIEW_PATROL_INSTANCES_DETAIL: '',
            OPERATIVE_PERMISSION_EDIT_PATROL_INSTANCES_DETAIL: '',
            OPERATIVE_PERMISSION_RECEIVE_PATROL_INSTANCES_DETAIL: '',
            OPERATIVE_PERMISSION_VIEW_APPROVERS_DETAIL: 'Ver aprobadores de sitios',
            OPERATIVE_PERMISSION_CREATE_REACTIVE_JOBS_DETAIL: '',
            OPERATIVE_PERMISSION_CREATE_REACTIVE_JOBS_FOR_OTHER_SERVICE_PROVIDERS_DETAIL: '',
            OPERATIVE_PERMISSION_EDIT_CONTRACTS_DETAIL: '',
            OPERATIVE_PERMISSION_EDIT_JOBS_DETAIL: '',
            OPERATIVE_PERMISSION_EDIT_METER_READINGS_DETAIL: 'La capacidad de crear, actualizar y borrar lecturas de medidores para medidores asociados con este proveedor de servicios',
            OPERATIVE_PERMISSION_EDIT_METERS_DETAIL: 'La capacidad de crear, actualizar y borrar medidores asociados con este proveedor de servicios',
            OPERATIVE_PERMISSION_MANAGE_OPERATIVES_DETAIL: '',
            OPERATIVE_PERMISSION_RECEIVE_ALL_EMAILS_DETAIL: '',
            OPERATIVE_PERMISSION_RECEIVE_CONTRACTS_DETAIL: '',
            OPERATIVE_PERMISSION_RECEIVE_JOBS_DETAIL: '',
            OPERATIVE_PERMISSION_REQUEST_PLANNED_JOBS_DETAIL: '',
            OPERATIVE_PERMISSION_VIEW_CONTRACTS_DETAIL: '',
            OPERATIVE_PERMISSION_VIEW_JOBS_DETAIL: '',
            OPERATIVE_PERMISSION_VIEW_METERS_DETAIL: 'La capacidad de ver medidores asociados con este proveedor de servicios',
            OPERATIVE_PERMISSION_VIEW_SITES_DETAIL: 'Heredar el acceso al sitio de este proveedor de servicios',
            OPERATIVE_PERMISSION_VIEW_SURVEYS_DETAIL: '',
            OPERATIVE_PERMISSION_EDIT_SURVEYS_DETAIL: '',
            OPERATIVE_PERMISSION_CREATE_SURVEYS_DETAIL: '',
            OPERATIVE_PERMISSION_VIEW_CONTACTS_DETAIL: 'La capacidad de ver los contactos de un sitio',
            OPERATIVE_PERMISSION_P2P: 'P2P',
            OPERATIVE_PERMISSION_P2P_DETAIL: '',
            OPERATIVE_PERMISSION_ASSET_USER_IMPORTS_DETAIL: 'Importar activos',
            OPERATIVE_PERMISSION_TASK_USER_IMPORTS_DETAIL: 'Importar tareas',
            OPERATIVE_PERMISSION_ASSET_TASK_USER_IMPORTS_DETAIL: 'La capacidad de importar tareas y activos',
            OPERATIVE_PERMISSION_REASSIGN_JOB_OPERATIVE_DETAIL: 'Capacidad de reasignar operativos de trabajo',
            OPERATIVE_PERMISSION_JOB_OPERATIVE: 'Ver operativas',
            OPERATIVE_PERMISSION_JOB_OPERATIVE_DETAIL: 'Capacidad de seleccionar un operativo en el trabajo',
            OPERATIVE_PERMISSION_BULK_REASSIGN_OPERATIVE_JOBS: 'Reasignar operativos de trabajo en masa',
            OPERATIVE_PERMISSION_BULK_REASSIGN_OPERATIVE_JOBS_DETAIL: 'Capacidad de reasignar operativos de trabajo en masa.',
            OPERATIVE_PERMISSION_BULK_CANCEL_JOBS: 'Cancelar trabajos en masa',
            OPERATIVE_PERMISSION_BULK_CANCEL_JOBS_DETAIL: 'Capacidad de cancelar trabajos en masa.',
            OPERATIVE_PERMISSION_EDIT_METER_REFERENCES: 'Editar referencias de medidor',
            OPERATIVE_PERMISSION_NOTIFY_UPDATES: 'Notificar contactos de trabajo/cotización',
            OPERATIVE_PERMISSION_CAN_SCORE_ASSETS: 'Puede puntuar activos',
            OPERATIVE_PERMISSION_CAN_SCORE_ASSETS_DETAIL: 'Capacidad de conseguir un activo al completar el trabajo.',
            OPERATIVE_PERMISSION_EDIT_SCORE_TYPES_ON_ASSET: 'Editar tipos de puntuación en activo',
            OPERATIVE_PERMISSION_EDIT_SCORE_TYPES_ON_ASSET_DETAIL: 'Editar tipos de puntuación al crear/editar activos',
            OPERATIVE_PERMISSION_OPEN_CLOSE: 'Crear registro de apertura/cierre de tienda',
            OPERATIVE_PERMISSION_OPEN_CLOSE_DETAIL: 'La capacidad de crear un registro de apertura/cierre de la tienda',
            OPERATIVE_PERMISSION_VIEW_LABOUR_LINES: 'Ver líneas laborales de trabajo',
            OPERATIVE_PERMISSION_EDIT_LABOUR_LINES: 'Editar líneas laborales de trabajo',
            OPERATIVE_PERMISSION_CREATE_LABOUR_LINES: 'Crear líneas laborales de trabajo',
            OPERATIVE_PERMISSION_VIEW_LABOUR_LINES_DETAILS: 'Ver líneas laborales de trabajo',
            OPERATIVE_PERMISSION_EDIT_LABOUR_LINES_DETAILS: 'Editar líneas laborales de trabajo',
            OPERATIVE_PERMISSION_CREATE_LABOUR_LINES_DETAILS: 'Crear líneas laborales de trabajo',
            OPERATIVE_PERMISSION_CREATE_SPILLAGES_FOR_OTHERS: 'Comunicar vertidos para otros',
            OPERATIVE_PERMISSION_CREATE_SPILLAGES_FOR_OTHERS_DETAIL: 'La capacidad de comunicar vertidos y asignar otros proveedores de servicios',
            OPERATIVE_PERMISSION_RECEIVE_SPILLAGES: 'Recibir vertidos',
            OPERATIVE_PERMISSION_RECEIVE_SPILLAGES_DETAIL: 'El operativo puede recibir vertidos',
            OPERATIVE_PERMISSION_CREATE_SPILLAGES: 'Crear vertidos',
            OPERATIVE_PERMISSION_CREATE_SPILLAGES_DETAIL: 'La capacidad de generar vertidos',
            OPERATIVE_PERMISSION_EDIT_SPILLAGES: 'Editar vertidos notificados',
            OPERATIVE_PERMISSION_EDIT_SPILLAGES_DETAIL: 'La capacidad de editar los vertidos notificados',
            OPERATIVE_PERMISSION_VIEW_SPILLAGES: 'Ver vertidos',
            OPERATIVE_PERMISSION_VIEW_SPILLAGES_DETAIL: 'La capacidad de ver vertidos',
            OPERATIVE_PERMISSION_CREATE_SURVEY_SCHEDULES: 'Crear horarios de encuesta',
            OPERATIVE_PERMISSION_CREATE_SURVEY_SCHEDULES_DETAILS: 'La capacidad de crear horarios de encuestas de KPI',
            OPERATIVE_PERMISSION_EDIT_SURVEY_SCHEDULES: 'Editar horarios de encuesta',
            OPERATIVE_PERMISSION_EDIT_SURVEY_SCHEDULES_DETAILS: 'La capacidad de editar horarios de encuestas de KPI',
            OPERATIVE_PERMISSIONS_UPDATED: 'Permisos operativos actualizados',
            OPERATIVE_PERMISSION_REJECT_INSIST_JOBS_DETAIL: 'La capacidad de rechazar o insistir en un trabajo para un proveedor de servicios compartido',
            OPERATIVE_PERMISSION_VIEW_WASTE_ENTRIES: 'Ver entradas de residuos',
            OPERATIVE_PERMISSION_VIEW_WASTE_ENTRIES_DETAIL: 'La capacidad para ver entradas de residuos',
            OPERATIVE_PERMISSION_CREATE_WASTE_ENTRIES: 'Crear entradas de residuos',
            OPERATIVE_PERMISSION_CREATE_WASTE_ENTRIES_DETAIL: 'La capacidad para crear entradas de residuos',
            OPERATIVE_PERMISSION_EDIT_WASTE_ENTRIES: 'Editar entradas de residuos',
            OPERATIVE_PERMISSION_EDIT_WASTE_ENTRIES_DETAIL: 'La capacidad para editar entradas de residuos',
            OPERATIVE_PERMISSION_IMPORT_WASTE_ENTRIES: 'Importar entradas de residuos',
            OPERATIVE_PERMISSION_IMPORT_WASTE_ENTRIES_DETAIL: 'La capacidad para importar entradas de residuos',
            OPERATIVE_PERMISSION_VIEW_LINK_JOBS_DETAIL: 'La capacidad para vincular trabajos',
            OPERATIVE_PERMISSION_REMOVE_LINK_JOBS_DETAIL: 'La capacidad para eliminar trabajos vinculados',
            OPERATIVE_PERMISSION_WAIVE_MANAGEMENT_FEE: 'Eximir de tarifa de gestión',
            OPERATIVE_PERMISSION_WAIVE_MANAGEMENT_FEE_DETAIL: 'Capacidad de renunciar a la tarifa de gestión',
            MISSED_PATROL_REASONS: 'Razones de patrulla perdida',
            MISSED_PATROL_REASONS_LIST: 'Razones de patrulla perdida',
            MISSED_PATROL_REASONS_ADD: 'Añadir nueva razón',
            MISSED_PATROL_REASONS_EDIT: 'Editar razón',
            MISSED_PATROL_REASON_ADDED: 'Razón de patrulla perdida añadida',
            MISSED_PATROL_REASON_UPDATED: 'Razón de patrulla perdida actualizada',
            MISSED_PATROL_REASON_DELETED: 'Razón de patrulla perdida eliminada',
            METER: 'Medidor',
            METERS: 'Medidores',
            METERS_LIST: 'Medidores',
            METERS_ADD: 'Añadir',
            METER_ADD: 'Añadir un nuevo medidor',
            METER_EDIT: 'Editar medidor',
            METER_DELETED: 'Medidor eliminado',
            UTILITY_PROVIDER: 'Proveedor de servicios públicos',
            MANUFACTURER: 'Fabricante',
            SUPPLY_TYPE: 'Tipo de suministro',
            OCCUPANCY: 'Ocupación',
            AUTOMATIC_READINGS: 'Leído automáticamente',
            ACCOUNT_NUMBER: 'Número de cuenta',
            MULTIPLIER: 'Multiplicador',
            DUAL_RATED: 'Doblemente clasificado',
            SERIAL_NUMBER: 'Número de serie',
            ADD_NEW_READING: 'Añadir nueva lectura',
            ADD_NEW_SUBMETER: 'Añadir nuevo medidor secundario',
            DATE_READ: 'Fecha de lectura',
            READING: 'Lectura',
            READINGS: 'Lecturas',
            NIGHT_READINGS: 'Lecturas nocturnas',
            NIGHT_READING: 'Lectura nocturna',
            DAY_READINGS: 'Lecturas diurnas',
            DAY_READING: 'Lectura diurna',
            LAST_READING: 'Última lectura',
            READ_BY: 'Leído por',
            NO_READINGS: 'Sin lecturas',
            NO_SUBMETERS: 'No hay medidores secundarios',
            SUBMETERS: 'Medidores secundarios',
            HISTORY: 'Historial',
            DATE_READING_DUE: 'Lectura vencida',
            GOTOPARENT: 'Ir a la matriz',
            METER_FREQUENCY: 'Frecuencia',
            METER_MAX_VALUE: 'Valor máximo',
            OVER: 'Transferido',
            CONSUMPTION: 'Consumo',
            TUR: 'TUR',
            SUBMETER: 'Medidor secundario',
            HIDE_SUBMETERS: 'Ocultar medidores secundarios',
            SHOW_SUBMETERS: 'Mostrar medidores secundarios',
            METERSUPPLIERS: 'Proveedores de medidor',
            METERMANUFACTURER: 'Fabricante de medidor',
            METERTYPE: 'Tipo de medidor',
            METERTYPESUPPLY: 'Suministro de medidor',
            METERTYPEOCCUPANCY: 'Ocupación de medidor',
            METER_MANUFACTURERS: 'Fabricantes',
            METER_OCCUPANCYTYPES: 'Tipos de ocupación',
            METER_SUPPLIERS: 'Proveedores',
            METER_SUPPLYTYPES: 'Tipos de suministro',
            METER_TYPES: 'Tipos',
            METER_UNITS: 'Unidades',
            EDIT_READING: 'Editar lectura',
            METER_ADDED: 'Medidor añadido',
            METER_READING_ADDED: 'Lectura de medidor añadida',
            METER_READING_UPDATED: 'Lectura de medidor actualizada',
            METER_READING_DELETED: 'Lectura de medidor eliminada',
            REFERENCE_TYPE: 'Tipo de referencia',
            IMAGES: 'Imágenes',
            ERROR_READING_EXCEEDS_MAXVALUE: 'La lectura no debe ser más alta que el valor máximo establecido del medidor',
            ERROR_READING_MUST_BE_GREATER: 'La lectura debe ser mayor o igual a la lectura anterior',
            ERROR_READING_MUST_BE_LOWER: 'La lectura debe ser menor o igual que la siguiente lectura',
            ERROR_INVALID_MAX_VALUE: 'El valor debe ser mayor que cero',
            ERROR_MAX_VALUE_MUST_BE_HIGHER: 'El valor máximo no debe ser inferior a la lectura',
            ERROR_NO_METER_EXISTS_FOR_SITE: 'No existe ningún medidor para el sitio',
            ERROR_ROLLED_OVER_INCORRECT_FORMAT: 'El formato transferido es incorrecto',
            ERROR_FILE_IS_REQUIRED_ON_METER_READING: 'El archivo es necesario para lecturas en este medidor',
            ERROR_READ_AT_DATE_CANNNOT_BE_IN_FUTURE: 'La fecha leída no puede ser futura',
            ERROR_READING_INCORRECT_FORMAT: 'El formato del tipo de lectura es incorrecto',
            ERROR_READING_TYPE_FOR_DUAL_RATED_MUST_BE_EITHER_DAY_OR_NIGHT: 'El tipo de lectura para medidores de doble clasificación debe ser o día o noche',
            ERROR_MAX_VALUE_IS_REQUIRED: 'Valor máximo obligatorio',
            ERROR_READING_EXCEEDS_MAX_VALUE: 'La lectura supera el valor máximo',
            INSUFFICIENT_PERMISSIONS_CANNOT_UPDATE_REFERENCE: 'Permisos insuficientes, no se puede actualizar la referencia.',
            UNABLE_TO_DELETE_METER_UNIT: 'No se puede eliminar la unidad del medidor, ya que está en uso',
            UNABLE_TO_EDIT_METER_UNIT: 'No se puede editar la unidad del medidor, ya que está en uso. Solo se puede cambiar el estado',
            REQUIRE_FILE_ON_METER_READINGS: 'Archivo necesario en lecturas de medidor',
            METER_EXPORT_READINGS: 'Exportar lecturas',
            METER_FORCE_STICK_TO_DATE: 'Forzar ceñirse a la fecha',
            METER_GRACE_TYPE: 'Tipo de gracia de medidor',
            METER_GRACE_PERIOD_WEEK_BEFORE: 'Semana del período de gracia - antes',
            METER_GRACE_PERIOD_WEEK_AFTER: 'Semana del período de gracia - después',
            METER_GRACE_PERIOD_MONTH_BEFORE: 'Mes del período de gracia - antes',
            METER_GRACE_PERIOD_MONTH_AFTER: 'Mes del período de gracia - después',
            METER_GRACE_PERIOD_YEAR_BEFORE: 'Año del período de gracia - antes',
            METER_GRACE_PERIOD_YEAR_AFTER: 'Año del período de gracia - después',
            METER_GRACE_PERIOD_WEEK_BEFORE_SUB_LABEL: '¿Cuántos días antes el usuario puede añadir una lectura del medidor que vence semanalmente?',
            METER_GRACE_PERIOD_WEEK_AFTER_SUB_LABEL: '¿Cuántos días después el usuario puede añadir una lectura del medidor que vence semanalmente?',
            METER_GRACE_PERIOD_MONTH_BEFORE_SUB_LABEL: '¿Cuántos días antes el usuario puede añadir una lectura del medidor que vence mensualmente?',
            METER_GRACE_PERIOD_MONTH_AFTER_SUB_LABEL: '¿Cuántos días después el usuario puede añadir una lectura del medidor que vence mensualmente?',
            METER_GRACE_PERIOD_YEAR_BEFORE_SUB_LABEL: '¿Cuántos días antes el usuario puede añadir una lectura del medidor que vence anualmente?',
            METER_GRACE_PERIOD_YEAR_AFTER_SUB_LABEL: '¿Cuántos días después el usuario puede añadir una lectura del medidor que vence anualmente?',
            METER_GENERATE_WEEK_DAY_SUB_LABEL: 'El día de la semana en que la fecha de lectura del medidor vence para repetirse semanalmente',
            METER_GENERATE_MONTH_DAY_SUB_LABEL: 'El día del mes en que la fecha de lectura del medidor vence para repetirse mensualmente',
            METER_GENERATE_YEAR_MONTH_DAY_SUB_LABEL: 'El mes en que la fecha de lectura del medidor vence para repetirse anualmente',
            METER_GENERATE_YEAR_DAY_SUB_LABEL: 'El día del mes en que la fecha de lectura del medidor vence para repetirse anualmente',
            METER_GENERATE: 'Generar medidor',
            METER_GENERATE_WEEK_DAY: 'Ceñirse a día de la semana',
            METER_GENERATE_MONTH_DAY: 'Ceñirse a día del mes',
            METER_GENERATE_YEAR_DAY: 'Ceñirse a año - día del mes',
            METER_GENERATE_YEAR_MONTH: 'Ceñirse a año - mes',
            METER_SETTINGS_VIEW: 'Ajustes de medidor',
            CANNOT_ENTER_FUTURE_DATE: 'No se puede introducir una fecha futura',
            UTILITY_CONSULTANT: 'Consultor de servicios públicos',
            READING_STATUS: 'Estado de lectura',
            FUTURE_READING: 'Futuro',
            METER_UNIT_INFORMATION: 'Información de unidad del medidor',
            SYMBOL: 'Símbolo de unidad',
            ADD_NEW_UNIT_TYPE: 'Añadir un nuevo tipo de unidad',
            EDIT_METER_UNIT: 'Editar tipo de medidor',
            METER_UNIT: 'Unidad de medidor',
            METER_UNIT_LIST: 'Unidades de medidor',
            METER_UNITS_LIST: 'Unidades de medidor',
            METER_UNIT_ADD: 'Añadir una nueva unidad de medidor',
            METER_UNIT_EDIT: 'Editar unidad de medidor',
            METER_UNIT_CREATED: 'Unidad de medidor creada correctamente',
            METER_UNIT_UPDATED: 'Unidad de medidor actualizada correctamente',
            METER_UNIT_DELETED: 'Unidad de medidor eliminada correctamente',
            METER_TYPE_INFORMATION: 'Información del tipo de medidor',
            ADD_NEW_METER_TYPE: 'Añadir un nuevo tipo de medidor',
            EDIT_METER_TYPE: 'Editar tipo de medidor',
            METER_TYPE_LIST: 'Tipos de medidor',
            METER_TYPES_LIST: 'Tipos de medidor',
            METER_TYPE_ADD: 'Añadir un nuevo tipo de medidor',
            METER_TYPE_EDIT: 'Editar tipo de medidor',
            METER_TYPE_CREATED: 'Tipo de medidor creado correctamente',
            METER_TYPE_UPDATED: 'Tipo de medidor actualizado correctamente',
            METER_TYPE_DELETED: 'Tipo de medidor eliminado correctamente',
            METER_TYPE_METER: 'Medidor',
            METER_TYPE_SUPPLY: 'Tipo de suministro',
            METER_TYPE_OCCUPANCY: 'Ocupación',
            METER_SUPPLY_INFORMATION: 'Información de suministro del medidor',
            ADD_NEW_METER_SUPPLY: 'Añadir un nuevo suministro de medidor',
            EDIT_METER_SUPPLY: 'Editar suministro de medidor',
            METER_SUPPLY_LIST: 'Suministros de medidores',
            METER_SUPPLIES_LIST: 'Suministros de medidores',
            METER_SUPPLY_ADD: 'Añadir un nuevo suministro de medidor',
            METER_SUPPLY_EDIT: 'Editar suministro de medidor',
            METER_SUPPLY_CREATED: 'Suministro de medidor creado correctamente',
            METER_SUPPLY_UPDATED: 'Suministro de medidor actualizado correctamente',
            METER_SUPPLY_DELETED: 'Suministro de medidor eliminado correctamente',
            METER_SUPPLIER_INFORMATION: 'Información del proveedor del medidor',
            ADD_NEW_METER_SUPPLIER: 'Añadir un nuevo proveedor de medidor',
            EDIT_METER_SUPPLIER: 'Editar proveedor de medidor',
            METER_SUPPLIER_LIST: 'Proveedores de medidor',
            METER_SUPPLIER_ADD: 'Añadir un nuevo proveedor de medidor',
            METER_SUPPLIER_EDIT: 'Editar proveedor de medidor',
            METER_SUPPLIER_CREATED: 'Proveedor de medidores creado correctamente',
            METER_SUPPLIER_UPDATED: 'Proveedor de medidores actualizado correctamente',
            METER_SUPPLIER_DELETED: 'Proveedor de medidores eliminado correctamente',
            METER_OCCUPANCY_INFORMATION: 'Información de ocupación del medidor',
            ADD_NEW_METER_OCCUPANCY: 'Añadir una nueva ocupación del medidor',
            EDIT_METER_OCCUPANCY: 'Editar la ocupación del medidor',
            METER_OCCUPANCY_LIST: 'Ocupaciones del medidor',
            METER_OCCUPANCIES_LIST: 'Ocupaciones del medidor',
            METER_OCCUPANCY_ADD: 'Añadir una nueva ocupación del medidor',
            METER_OCCUPANCY_EDIT: 'Editar la ocupación del medidor',
            METER_OCCUPANCY_CREATED: 'Ocupación del medidor creada correctamente',
            METER_OCCUPANCY_UPDATED: 'Ocupación del medidor actualizada correctamente',
            METER_OCCUPANCY_DELETED: 'Ocupación del medidor eliminada correctamente',
            METER_OCCUPANCY_METER: 'medidor',
            METER_OCCUPANCY_SUPPLY: 'suministro',
            METER_OCCUPANCY_OCCUPANCY: 'ocupación',
            METER_MANUFACTURER_INFORMATION: 'Información del fabricante del medidor',
            ADD_NEW_METER_MANUFACTURER: 'Añadir un nuevo fabricante de medidores',
            EDIT_METER_MANUFACTURER: 'Editar fabricante del medidor',
            METER_MANUFACTURER_LIST: 'Fabricantes de medidores',
            METER_MANUFACTURER_ADD: 'Añadir un nuevo fabricante de medidores',
            METER_MANUFACTURER_EDIT: 'Editar fabricante del medidor',
            METER_MANUFACTURER_CREATED: 'Fabricante del medidor creado correctamente',
            METER_MANUFACTURER_UPDATED: 'Fabricante del medidor actualizado correctamente',
            METER_MANUFACTURER_DELETED: 'Fabricante del medidor eliminado correctamente',
            MESSAGE: 'Mensaje',
            MESSAGES: 'Mensajes',
            UPDATE_ADD: 'Añadir actualización',
            MESSAGE_VISIBILITY: 'Visibilidad',
            MESSAGE_VISIBILITY_PUBLIC: 'Público',
            MESSAGE_VISIBILITY_HELPDESK: 'Atención al cliente',
            MESSAGE_DIRECTION: 'Dirección',
            MESSAGE_DIRECTION_INBOUND: 'Comunicaciones entrantes',
            MESSAGE_DIRECTION_OUTBOUND: 'Comunicaciones salientes',
            MESSAGE_WRITE_NEW: 'Escribir un mensaje',
            MESSAGE_SEND: 'Enviar',
            MASTERASSET: 'Resumen de activo',
            MASTER_TASK: 'Tarea maestra',
            MASTER_TASKS: 'Tareas maestras',
            MASTER_TASK_LIST: 'Tareas maestras',
            MASTER_TASK_CREATED: 'Tarea maestra creada',
            MASTER_TASK_UPDATED: 'Tarea maestra actualizada',
            MASTER_TASK_DELETED: 'Tarea maestra eliminada',
            MASTER_TASK_ADD: 'Añadir una nueva tarea maestra',
            MASTER_TASK_EDIT: 'Editar tarea maestra',
            MASTER_TASK_IS_MANDATORY: '¿Es obligatorio?',
            MASTER_TASK_SERVICE_TYPE: 'Tipo de servicio',
            MASTER_TASK_ASSET_NAME: 'Nombre del activo',
            USERNAME: 'Nombre de usuario',
            PASSWORD: 'Contraseña',
            SIGN_IN: 'Registrarse',
            PLEASE_LOGIN: 'Iniciar sesión',
            WELCOME_TO_ELOGBOOKS: 'Bienvenido a Elogs CAFM',
            INVALID_CREDENTIALS: 'Credenciales no válidas',
            FORGOT_YOUR_PASSWORD: '¿Ha olvidado su contraseña?',
            PASSWORD_REQUEST_SUBMITTED: 'Si existe un usuario coincidente en nuestro sistema, recibirá un correo electrónico en breve con instrucciones para restablecer su contraseña.',
            FORGOT_USERNAME_REQUEST_SUBMITTED: 'Si existe un usuario coincidente en nuestro sistema, recibirá un correo electrónico en breve con su nombre de usuario.',
            CHOOSE_YOUR_NEW_PASSWORD: 'Elija su nueva contraseña.',
            PASSWORDS_DO_NOT_MATCH: 'Las contraseñas proporcionadas no coinciden',
            YOUR_PASSWORD_HAS_BEEN_CHANGED: 'Se ha cambiado su contraseña.',
            BACK_TO_LOGIN_PAGE: 'Volver a la página de acceso',
            SET_MY_NEW_PASSWORD: 'Establecer mi nueva contraseña',
            CONFIRM_NEW_PASSWORD: 'Confirmar nueva contraseña',
            USER_AUTH_TOO_MANY_ATTEMPTS: 'Cuenta bloqueada',
            USER_AUTH_BAD_CREDENTIALS: 'Credenciales no válidas',
            USER_AUTH_FAIL: 'Se ha producido un error al autenticar su cuenta. Inténtelo de nuevo.',
            USER_AUTH_RECAPTCHA_REQUIRED: 'Se requiere un código captcha.',
            USER_AUTH_RECAPTCHA_INVALID: 'El captcha no es válido.',
            USER_ACCOUNT_DISABLED: 'Esta cuenta está deshabilitada.',
            LOCATION: 'Ubicación',
            LOCATIONS: 'Ubicaciones',
            LOCATION_ADD: 'Añadir una nueva ubicación',
            LOCATION_EDIT: 'Editar ubicación',
            LOCATION_LIST: 'Ubicaciones',
            LOCATION_ENTER: 'Introduzca un nombre de ubicación…',
            LOCATION_CREATED: 'Ubicación creada',
            LOCATION_UPDATED: 'Ubicación actualizada',
            LOCATION_DELETED: 'Ubicación eliminada',
            LOCATION_NAME: 'Nombre de ubicación',
            PARENT_LOCATION: 'Ubicación matriz',
            NEW_LOCATION: 'Nueva ubicación',
            LOCATION_ADD_PARENT: 'Añadir una ubicación principal',
            LOCATION_ADD_CHILD: 'Añadir una ubicación secundaria',
            LOCATION_NOTES: 'Notas sobre ubicación',
            LOCATION_NOTE_UPDATED: 'Nota de ubicación actualizada',
            LOCATION_NOTE_CREATED: 'Nota de ubicación creada',
            LOCATION_LOCKED: 'No puede actualizar la ubicación en esta etapa',
            'en-gb': 'English',
            fr: 'Francais',
            es: 'Español',
            nl: 'Nederlands',
            'zh-hk': '繁體中文',
            GENERIC_JOB_VALUES: 'Valores de trabajo',
            JOB: 'Trabajo',
            JOBS: 'Trabajos',
            JOB_LIST: 'Trabajos',
            JOB_REACTIVE_APPROVAL_REQUIRED: ' Aprobaciones de trabajos reactivos',
            JOB_VALUE_APPROVALS: 'Aprobaciones de valor de trabajo',
            LOG_A_NEW_JOB: 'Registrar un nuevo trabajo',
            ADD_NEW_JOB: 'Añadir un nuevo trabajo',
            JOB_ADD: 'Añadir un trabajo',
            ALL_JOBS: 'Trabajos',
            CREATED_AT_START: 'Creado al inicio',
            CREATED_AT_END: 'Creado al final',
            ALARMED_AT_START: 'Alarmado al inicio',
            ALARMED_AT_END: 'Alarmado al final',
            REMINDER_AT_START: 'Recordatorio al inicio',
            REMINDER_AT_END: 'Recordatorio al final',
            ATTENDANCE_DUE_AT_START: 'Asistencia vencida al inicio',
            ATTENDANCE_DUE_AT_END: 'Asistencia vencida al final',
            COMPLETION_DUE_AT_START: 'Finalización al inicio',
            COMPLETION_DUE_AT_END: 'Finalización vencida al final',
            LAST_SUBMITTED_AT: 'Enviado última vez el',
            LAST_SUBMITTED_AT_START: 'Enviado última vez al inicio',
            LAST_SUBMITTED_AT_END: 'Enviado última vez al final',
            COMPLETED_AT_START: 'Completado al inicio',
            COMPLETED_AT_END: 'Completado al final',
            JOB_ID: 'Trabajo n.º{{ ::id }}',
            ID_JOB: 'ID de trabajo',
            JOB_DETAILS: 'Detalles de trabajo',
            JOB_DETAILS_WITH_ID: 'Detalles de trabajo n.º {{ ::id }}',
            JOB_STATUS: 'Estado',
            JOB_EVENTS: 'Eventos',
            JOB_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> creó el trabajo.',
            JOB_LINKED_TO_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> añadió un trabajo al vinculo',
            JOB_LINKED_RELATE_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> añadió un vínculo de trabajo relacionado',
            JOB_LINKED_FROM_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> añadió un trabajo desde el vínculo',
            JOB_LINKED_FROM_QUOTE_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> añadió un vínculo de trabajo a cotizar',
            JOB_LINKED_RELATES_QUOTE_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> añadió un vínculo de trabajo relacionado a la cotización',
            JOB_MESSAGE_ACTION: '<strong>{{ ::message._links["sender"].title }}</strong> proporcionó una actualización.',
            JOB_ACTIONED_ACTION: 'Se adelantó el plazo para la acción requerida.',
            JOB_ALARM_UPDATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> actualizó el trabajo.',
            JOB_APPROVAL_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> envió una solicitud de aprobación.',
            JOB_APPROVAL_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> aprobó el trabajo.',
            JOB_APPROVAL_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> rechazó la aprobación del trabajo.',
            JOB_APPROVAL_RESENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> volvió a enviar una solicitud de aprobación a {{ ::event._links["approver"].title }}.',
            JOB_ASSIGNMENT_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> envió una solicitud de asignación a {{ ::event._links["operative"].title }}.',
            JOB_ASSIGNMENT_REASSIGNED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> reasignó el trabajo.',
            JOB_ASSIGNMENT_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> aceptó el trabajo.',
            JOB_ASSIGNMENT_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> rechazó el trabajo.',
            JOB_ASSIGNMENT_RESENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> reenvió una solicitud de asignación a {{ ::event._links["operative"].title }}.',
            JOB_ASSIGNMENT_INSISTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> insiste en que el trabajo está aceptado.',
            JOB_PROPOSAL_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> envió una propuesta.',
            JOB_PROPOSAL_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> aceptó la propuesta.',
            JOB_PROPOSAL_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> rechazó la propuesta.',
            JOB_PROPOSAL_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> canceló la propuesta.',
            JOB_PROPOSAL_ACCEPTED_SYSTEM_ACTION: 'El sistema aceptó automáticamente la solicitud de ampliación.',
            JOB_EXTENSION_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> envió una solicitud de ampliación.',
            JOB_EXTENSION_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> aceptó la solicitud de ampliación.',
            JOB_EXTENSION_ACCEPTED_SYSTEM_ACTION: 'El sistema aceptó automáticamente la solicitud de ampliación.',
            JOB_EXTENSION_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> rechazó la solicitud de ampliación.',
            JOB_EXTENSION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> canceló la solicitud de ampliación .',
            JOB_OPERATION_ACKNOWLEDGED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> reconoció el trabajo.',
            JOB_OPERATION_COMMENCED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> comenzó el trabajo.',
            JOB_OPERATION_COMPLETED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> completó el trabajo.',
            JOB_OPERATION_COMPLETED_PAPERWORK_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> añadió papeleo para el trabajo.',
            JOB_POSTPONE_REMINDER_AT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> cambió la acción requerida por fecha {{ ::event.reminderAt | date:\'short\' }}.',
            JOB_OPERATION_MISSED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> perdió el trabajo.',
            JOB_OPERATION_CHASED_ACTION: 'Se ha enviado una notificación de búsqueda n.º {{ ::event.chaseSequence + 1 }} a <strong>{{ ::event._links["user"].title }}</strong>.',
            JOB_OPERATION_REOPENED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> reabrió el trabajo.',
            JOB_OPERATION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> canceló el trabajo.',
            JOB_APPROVAL_ESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> aumentó la aprobación del trabajo.',
            JOB_AUDIT_TRAIL_SENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> envió la pista de auditoría.',
            JOB_ESCALATION_ADDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> aumentó el trabajo.',
            JOB_ESCALATION_REMOVED_ACTION: '<strong>{{ ::event._links["user"].title }} </strong> canceló la escalada.',
            JOB_RELATION_LINK_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> creó vínculo',
            JOB_RELATION_LINK_REMOVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> eliminó vínculo',
            QUOTE_RELATION_LINK_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> creó vínculo',
            QUOTE_RELATION_LINK_REMOVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> eliminó vínculo',
            JOB_VALUES_ADDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> añadió valores al trabajo.',
            JOB_VALUES_APPROVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> aprobó los valores del trabajo.',
            JOB_VALUES_APPROVED_SYSTEM_ACTION: 'El sistema aprobó automáticamente los valores del trabajo.',
            JOB_VALUES_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> rechazó los valores del trabajo.',
            JOB_FOC_ADDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> marcó el trabajo como gratuito.',
            JOB_ENTER_DETAILS_MANUALLY: 'Introducir datos manualmente',
            JOB_CHOOSE_A_USER: 'Elija un usuario',
            JOB_CHOOSE_A_TENANT: 'Elija un arrendatario',
            JOB_REPORTER_NAME: 'Nombre del reportero',
            JOB_CONTACT_NAME: 'Nombre de contacto',
            JOB_PHONE_NUMBER: 'Número de teléfono',
            JOB_RELATIONSHIP_TO_THE_PROPERTY: 'Relación con la propiedad',
            JOB_NOTIFY_ON_CREATION: 'Notificar sobre la creación',
            JOB_NOTIFY_ON_COMPLETION: 'Notificar al finalizar',
            JOB_NOTIFY_ON_STATUTORY_COMPLETION: 'Notificar al finalizar - Normativa',
            JOB_SKIP_REQUEST_APPROVAL_NOTIFICATION: 'Omitir notificación de aprobación',
            JOB_NEXT_STEP: 'PASO SIGUIENTE',
            JOB_SAME_AS_CALLER: 'Igual que la persona que llama',
            JOB_SITE_CONTACT_MYSELF: 'Soy el contacto del sitio',
            JOB_SITE_CONTACT_AVAILABLE: 'Contacto del sitio disponible',
            JOB_TO_MEET_THE_CONTRACTOR: 'para conocer al contratista',
            JOB_KEYHOLDER_REQUIRED: 'Requiere propietario de llave',
            JOB_SITE_PRIORITY: 'Prioridad del sitio',
            JOB_STEP_DETAIL: 'Detalles',
            JOB_SERVICE_PROVIDER_PRIORITY: 'Prioridad del proveedor de servicios',
            JOB_SYSTEM_PRIORITY: 'Prioridad del sistema',
            JOB_PHONE_NUMBERS: 'Números de teléfono',
            JOB_KEYHOLDER_DETAILS_PASS: 'Detalles del titular de la clave transmitidos:',
            BY_PHONE: 'teléfono',
            BY_MAIL: 'correo',
            SYSTEM_MAIL: 'correo autogenerado',
            JOB_TYPE_REACTIVE: 'Reactivo',
            JOB_TYPE_PLANNED: 'Planificado',
            JOB_REPORTER: 'Reportero',
            JOB_CONTACT: 'Contacto del trabajo',
            JOB_ADD_SITE_DETAILS: 'Seleccione un sitio',
            JOB_ADD_REPORTER_DETAILS: 'Introduzca los detalles del reportero',
            JOB_ADD_CONTACT_DETAILS: 'Introduzca los detalles de contacto del sitio',
            JOB_ADD_DETAILS: 'Introduzca detalles de trabajo',
            JOB_WORKFLOW_COMPLETE: 'Trabajo completado',
            JOB_WORKFLOW_COMPLETE_OPERATION: 'Trabajo completado',
            JOB_WORKFLOW_COMPLETE_OPERATION_SUBMIT: 'Trabajo completado',
            JOB_WORKFLOW_COMPLETE_PAPERWORK: 'Papeleo completo',
            JOB_WORKFLOW_COMPLETE_PAPERWORK_OPERATION: 'Papeleo completo',
            JOB_WORKFLOW_COMPLETE_PAPERWORK_OPERATION_SUBMIT: 'Papeleo completo',
            JOB_STATUS_AWAITING_PAPERWORK: 'Esperando papeleo',
            JOB_STATUS_PAPERWORK_REJECTED: 'Papeleo rechazado',
            COMPLETE_PAPERWORK: 'Papeleo completo',
            JOB_WORKFLOW_ACCEPT_ASSIGNMENT: 'Reconocer',
            JOB_WORKFLOW_REQUEST_PROPOSAL: 'Solicitar nuevo SLA',
            JOB_WORKFLOW_INSIST_ASSIGNMENT: 'Insistir',
            JOB_WORKFLOW_ACCEPT_APPROVAL: 'Aprobar',
            JOB_WORKFLOW_ACCEPT_APPROVAL_SUBMIT: 'Aprobar',
            JOB_WORKFLOW_REOPEN: 'Reabrir',
            JOB_WORKFLOW_ESCALATE_APPROVAL: 'Escalar aprobación',
            JOB_WORKFLOW_ALARM: 'Editar alarma',
            JOB_WORKFLOW_ALARM_SUBMIT: 'Actualizar',
            JOB_WORKFLOW_ALARM_SUCCESS: 'Alarma actualizada',
            JOB_WORKFLOW_ALARM_SETNEW: 'Establecer alarma',
            JOB_WORKFLOW_ALARM_SETNEW_SUBMIT: 'Establecer',
            JOB_WORKFLOW_ALARM_SETNEW_SUCCESS: 'Ajuste de alarma',
            JOB_WORKFLOW_REASSIGN_ASSIGNMENT: 'Reasignar asignación',
            JOB_WORKFLOW_CHANGE_REQUIRED_BY_DATE_OPERATION: 'Posponer acción',
            JOB_WORKFLOW_CHANGE_REQUIRED_BY_DATE_OPERATION_SUBMIT: 'Posponer acción',
            JOB_WORKFLOW_CHANGE_REQUIRED_BY_DATE_OPERATION_SUCCESS: 'Acción pospuesta',
            JOB_WORKFLOW_ACCEPT_PROPOSAL: 'Aceptar nuevo SLA',
            JOB_WORKFLOW_ACCEPT_PROPOSAL_SUBMIT: 'Confirmar',
            PROPOSED: 'Propuesto',
            JOB_WORKFLOW_REJECT_PROPOSAL: 'Rechazar nuevo SLA',
            JOB_WORKFLOW_REJECT_PROPOSAL_SUBMIT: 'Enviar',
            JOB_WORKFLOW_CANCEL_PROPOSAL: 'Cancelar nuevo SLA',
            JOB_WORKFLOW_CANCEL_PROPOSAL_SUBMIT: 'Enviar',
            JOBS_LIST: 'Trabajos',
            JOBS_ADD: 'Añadir un nuevo trabajo',
            JOBS_EDIT: 'Editar trabajo',
            JOB_TYPE: 'Tipo de trabajo',
            JOB_STAT_TYPE: 'Tipo normativo',
            JOB_SUMMARY: 'Resumen de trabajo',
            JOB_PRIORITY: 'Prioridad',
            JOB_STATUS_ACKNOWLEDGED: 'Reconocido',
            JOB_QUOTE_REQUEST_TO: 'A cotizar',
            JOB_QUOTE_REQUEST_WITH: 'Con cotización',
            JOB_QUOTE_REQUEST_FROM: 'Desde Cotización',
            JOB_SENT_TO_QUOTE: 'Enviado a cotización',
            JOB_RELATES_TO_QUOTE: 'Se relaciona con la cotización',
            JOB_STATUS_LEGEND: 'Leyenda de estado de trabajo',
            JOB_STATUS_CREATED: 'Creada',
            JOB_STATUS_APPROVAL_PENDING: 'Aprobación pendiente',
            JOB_STATUS_APPROVAL_REJECTED: 'Aprobación rechazada',
            JOB_STATUS_ASSIGNMENT_PENDING: 'Pendiente de reconocimiento',
            JOB_STATUS_ASSIGNMENT_PROPOSAL: 'Propuesta de SLA pendiente',
            JOB_STATUS_ASSIGNMENT_REJECTED: 'Tarea rechazada',
            JOB_STATUS_OPERATIVE_ASSIGNMENT_REJECTED: 'Asignación de operativa de trabajo rechazada',
            JOB_STATUS_OPERATION_ACKNOWLEDGED: 'Reconocido',
            JOB_STATUS_COMMENCED: 'En curso',
            JOB_STATUS_OPERATION_MISSED: 'Perdido',
            JOB_STATUS_OPERATION_CANCELLED: 'Cancelada',
            JOB_STATUS_COMPLETED: 'Completado',
            JOB_STATUS_OPERATION_AWAITING_PAPERWORK: 'Esperando papeleo',
            JOB_WORKFLOW_COMPLETE_PAPERWORK_OPERATION_SUCCESS: 'Documentación adjunta',
            JOB_WORKFLOW_REASSIGN_OPERATIVE: 'Reasignar operativo de trabajo',
            JOB_STATUS_EXTENSION_PENDING: 'Solicitud de ampliación pendiente',
            JOB_STATUS_EXTENSION_REJECTED: 'Solicitud de ampliación rechazada',
            JOB_STATUS_MISSED: 'Perdido',
            RESEND_APPROVAL_REQUEST: 'Reenviar solicitud de aprobación',
            RESEND_ASSIGNMENT_REQUEST: 'Reenviar solicitud de asignación',
            REASSIGN_OPERATIVE: 'Reasignar operativo',
            SEND_TO_QUOTE: 'Enviar a cotización',
            ESCALATE_TO_CLIENT: 'Escalar al cliente',
            EDIT_JOB: 'Editar trabajo',
            FROM_JOB: 'Desde trabajo',
            LINK_FROM_JOB: 'Desde trabajo',
            LINK_TO_JOB: 'A trabajo',
            LINK_RELATES_TO_JOB: 'Relativo al trabajo',
            FROM_QUOTE: 'Creado desde cotización',
            LINK_FROM_QUOTE: 'Desde Cotización',
            LINK_TO_QUOTE: 'A cotizar',
            LINK_RELATES_TO_QUOTE: 'Se relaciona con la cotización',
            LINK_TYPE: 'Tipo de vínculo',
            JOB_LINK_SEARCH: 'Buscar vínculo de trabajo',
            QUOTE_LINK_SEARCH: 'Búsqueda de vínculo de cotización',
            JOB_LINKS: 'Vínculos de trabajo',
            QUOTE_LINKS: 'Vínculos de cotización',
            COMPLETION_DATE: 'Fecha de finalización',
            NEW_COMPLETION_DATE: 'Nueva fecha de finalización',
            ORIGINAL_COMPLETION_DATE: 'Fecha de finalización original',
            REASSIGN_ASSIGNMENT: 'Reasignar asignación',
            REASSIGN_ASSIGNMENT_SUBMIT: 'Reasignar asignación',
            COMMENCE: 'Comienzo',
            COMPLETE: 'Completo',
            SKIP: 'Omitir',
            ALARM: 'Alarma',
            CANCEL_JOB: '¿Está seguro?',
            CANCEL_JOB_ADD: '¿Seguro que quiere realizar esta acción?',
            APPROVE: 'Aprobar',
            REJECT: 'Rechazar',
            ACKNOWLEDGE: 'Reconocer',
            REASSIGN: 'Reasignar',
            CANCEL: 'Cancelar',
            INSIST: 'Insistir',
            REQUEST_PROPOSAL: 'Solicitar nuevo SLA',
            ACCEPT_PROPOSAL: 'Aceptar nuevo SLA',
            REJECT_PROPOSAL: 'Rechazar nuevo SLA',
            CANCEL_PROPOSAL: 'Cancelar nuevo SLA',
            REQUEST_EXTENSION: 'Solicitar ampliación',
            ACCEPT_EXTENSION: 'Aceptar solicitud de ampliación',
            REJECT_EXTENSION: 'Rechazar solicitud de ampliación',
            CANCEL_EXTENSION: 'Cancelar solicitud de ampliación',
            CHANGE_ACTION_REQUIRED_BY: 'Posponer acción',
            REOPEN: 'Reabrir',
            ESCALATE_APPROVAL: 'Escalar aprobación',
            ACTION_REQUIRED: 'Acción requerida por',
            JOB_REQUISITES: 'Requisitos de trabajo',
            REQUIREMENT_MET: 'Requisito cumplido',
            JOB_REQUISITES_UPDATED: 'Requisitos de trabajo actualizados',
            JOB_CONTACT_ADD: 'Añadir',
            JOB_DIALS_PLANNED_OVERDUE: 'Planificado vencido',
            JOB_DIALS_REACTIVE_OVERDUE: 'Reactivo vencido',
            JOB_DIALS_PLANNED_REQUIRED_ATTENTION: 'Papeleo para revisión del cliente',
            JOB_DIALS_REACTIVE_REQUIRED_ATTENTION: 'Escalado a cliente',
            JOB_APPROVAL_RESENT: 'Aprobación de trabajo reenviada',
            JOB_ASSIGNMENT_RESENT: 'Asignación de trabajo reenviada',
            JOB_AUDIT_TRAIL_SENT: 'Pista de auditoría enviada',
            JOB_WITH_ID: 'Trabajo {{ id }}',
            JOBS_REACTIVE_OVERDUE_COMPLETION: 'Finalización de trabajos reactivos vencidos',
            JOBS_REACTIVE_OVERDUE_ATTENDANCE: 'Asistencia de trabajos reactivos vencidos',
            JOBS_PLANNED_OVERDUE_COMPLETION: 'Finalización de trabajos planificados vencidos',
            JOB_CURRENT_OPERATIVE: 'Operativa actual',
            REASSIGN_TO: 'Reasignar a',
            JOBS_REACTIVE_UPDATES_DUE: 'Actualizaciones de trabajos reactivos vencidos',
            COMPLETE_TO_RAISE_ESCALATION: 'Completar este campo generará una escalada con el FM.',
            CANNOT_COMPLETE_REQUIRES_FILE_WITH_STAT_TYPE_YES: 'Es imposible completar el papeleo, requiere un archivo con el conjunto normativo ajustado en sí.',
            OVERDUE: 'Vencido',
            OVERDUE_ATTENDANCE: 'Asistencia vencida',
            OVERDUE_COMPLETION: 'Finalización vencida',
            ATTEND_AT: 'Asiste a',
            COMPLETE_AT: 'Completar en',
            JOB_SITE_CONTACT: 'Contacto de sitio de trabajo',
            JOB_APPROVER: 'Aprobador de trabajo',
            JOB_WORKFLOW_COMPLETE_OPERATION_SUCCESS: 'Trabajo completado',
            JOB_WORKFLOW_REQUEST_EXTENSION: 'Solicitar ampliación',
            JOB_WORKFLOW_REQUEST_EXTENSION_SUBMIT: 'Solicitud',
            JOB_WORKFLOW_REQUEST_EXTENSION_SUCCESS: 'Ampliación de trabajo solicitada',
            JOB_WORKFLOW_ACCEPT_EXTENSION: 'Aceptar ampliación',
            JOB_WORKFLOW_ACCEPT_EXTENSION_SUBMIT: 'Aceptar',
            JOB_WORKFLOW_ACCEPT_EXTENSION_SUCCESS: 'Ampliación de trabajo aceptada',
            JOB_WORKFLOW_REJECT_EXTENSION: 'Rechazar ampliación',
            JOB_WORKFLOW_REJECT_EXTENSION_SUBMIT: 'Rechazar',
            JOB_WORKFLOW_REJECT_EXTENSION_SUCCESS: 'Ampliación de trabajo rechazada',
            JOB_WORKFLOW_CANCEL_OPERATION: 'Cancelar trabajo',
            JOB_WORKFLOW_CANCEL_OPERATION_SUBMIT: 'Enviar',
            JOB_WORKFLOW_CANCEL_OPERATION_SUCCESS: 'Trabajo cancelado',
            JOB_WORKFLOW_REOPEN_OPERATION: 'Reabrir',
            JOB_WORKFLOW_REOPEN_OPERATION_SUBMIT: 'Reabrir',
            JOB_WORKFLOW_REOPEN_OPERATION_SUCCESS: 'Trabajo reabierto',
            JOB_CREATED: 'Trabajo creado',
            NO_PLANNED_JOBS: 'No hay trabajos planeados en este sitio, seleccione otra prioridad',
            JOB_PO_REQUIRED: 'Se requiere un número de orden de compra porque el sitio tiene esta opción habilitada',
            USER_IS_APPROVER_AND_TENANT: 'El usuario es el aprobador y el arrendatario',
            JOB_WORKFLOW_REJECT_APPROVAL: 'Rechazar aprobación',
            JOB_WORKFLOW_COMMENCE_OPERATION: 'Comienzo',
            JOB_STATUS_CANCELLED: 'Cancelada',
            JOB_WORKFLOW_CANCEL: 'Cancelar',
            JOB_WORKFLOW_CHANGED_REQUIRED_BY_DATE: 'Posponer acción',
            JOB_WORKFLOW_REJECT_ASSIGNMENT: 'Rechazar asignación',
            CANCEL_OPERATION_SUCCESS: 'Trabajo cancelado',
            REASSIGN_ASSIGNMENT_SUCCESS: 'Trabajo reasignado',
            JOB_WORKFLOW_REASSIGN_ASSIGNMENT_SUCCESS: 'Trabajo reasignado',
            JOB_WORKFLOW_REASSIGN_OPERATIVE_SUCCESS: 'Operativa reasignada',
            REOPEN_OPERATION_SUCCESS: 'Trabajo reabierto',
            REJECT_APPROVAL_SUCCESS: 'Aprobación de trabajo rechazada',
            ESCALATE_APPROVAL_SUCCESS: 'Aprobación del trabajo aumentada',
            JOB_WORKFLOW_ACCEPT_APPROVAL_SUCCESS: 'Trabajo aprobado',
            REQUEST_PROPOSAL_SUCCESS: 'Nueva propuesta de SLA solicitada',
            ACCEPT_PROPOSAL_SUCCESS: 'Nuevo SLA aceptado',
            REJECT_PROPOSAL_SUCCESS: 'Nuevo SLA rechazado',
            CANCEL_PROPOSAL_SUCCESS: 'Nuevo SLA cancelado',
            INSIST_ASSIGNMENT_SUCCESS: 'Trabajo insistido',
            ACCEPT_ASSIGNMENT_SUCCESS: 'Trabajo asignado',
            REJECT_ASSIGNMENT_SUCCESS: 'Trabajo rechazado',
            COMMENCE_OPERATION_SUCCESS: 'Trabajo comenzado',
            REQUEST_EXTENSION_SUCCESS: 'Trabajo ampliado',
            REJECT_EXTENSION_SUCCESS: 'Ampliación de trabajo rechazada',
            ACCEPT_EXTENSION_SUCCESS: 'Ampliación de trabajo aceptada',
            CANCEL_EXTENSION_SUCCESS: 'Ampliación de trabajo cancelada',
            JOB_WORKFLOW_REQUEST_PROPOSAL_SUBMIT: 'Solicitud',
            JOB_WORKFLOW_INSIST_ASSIGNMENT_SUBMIT: 'Insistir',
            JOB_WORKFLOW_REJECT_APPROVAL_SUBMIT: 'Rechazar',
            JOB_WORKFLOW_ESCALATE_APPROVAL_SUBMIT: 'Escalar',
            JOB_WORKFLOW_ACCEPT_ASSIGNMENT_SUBMIT: 'Aceptar',
            JOB_WORKFLOW_COMMENCE_OPERATION_SUBMIT: 'Comienzo',
            JOB_WORKFLOW_REJECT_ASSIGNMENT_SUBMIT: 'Rechazar',
            JOB_WORKFLOW_REASSIGN_ASSIGNMENT_SUBMIT: 'Reasignar',
            JOB_WORKFLOW_REASSIGN_OPERATIVE_SUBMIT: 'Reasignar',
            ACTION_REQUIRED_BETWEEN: 'Acción requerida entre',
            ATTENDANCE_DUE_AT_BETWEEN: 'Asistencia debida entre',
            COMPLETION_DUE_AT_BETWEEN: 'Finalización vencida entre',
            PREVIOUS_OPERATIVE: 'Operativa anterior',
            NEW_OPERATIVE: 'Nueva operativa',
            JOB_NUMBER: 'Número de trabajo',
            PLANNED: 'Planificado',
            REACTIVE: 'Reactivo',
            CANCEL_JOBS: 'Cancelar trabajos',
            JOBS_SELECTED: 'Trabajos seleccionados',
            ARE_YOU_SURE_YOU_WOULD_LIKE_TO_CANCEL_THESE_JOBS: '¿Seguro que desea cancelar estos trabajos?',
            EMAIL_CANCELLATIONS_TO_ENGINEERS: 'Cancelaciones de correo electrónico a ingenieros',
            JOBS_QUEUED_FOR_REASSIGNMENT: 'Trabajos en cola para reasignación',
            JOBS_QUEUED_FOR_CANCELLATION: 'Trabajos en cola para cancelación',
            DATE_NOT_SOONER_THAN_1_HOUR: 'No puedes solicitar una cotización antes de 1 hora a partir de ahora',
            ALL_FILTERED_JOBS: 'Todos los empleos filtrados',
            SELECT_SITE: 'Seleccione un sitio',
            LAST_FIVE_JOBS: 'Últimos 5 trabajos',
            STATUTORY: 'Normativa',
            NOT_STATUTORY: 'No normativa',
            PO_NUMBER_INVALID_LENGTH: 'El número de orden de compra no debe tener más de 40 caracteres',
            JOBS_POINT_IN_TIME: 'Los trabajos en este punto en el tiempo coinciden con sus criterios de búsqueda',
            JOB_LINKS_JOB_ID: 'Trabajo n.º',
            JOB_LINKS_QUOTE_ID: 'Cotización n.º',
            JOB_LINK: 'Enlace',
            JOB_LINK_WITH: 'Con',
            JOB_LINK_TO: 'A',
            JOB_LINK_FROM: 'Desde',
            JOB_LINK_0: 'A',
            JOB_LINK_1: 'Desde',
            JOB_LINK_2: 'Con',
            JOB_EXTENSION_REQUEST_DATE_NOT_SOONER_THAN_SLA: 'No puede solicitar una fecha anterior al SLA ya instalado',
            JOB_LABOUR: 'Trabajo laboral',
            JOB_LABOUR_ADD: 'Añadir trabajo laboral',
            JOB_LABOUR_DELETED: 'Añadir trabajo eliminado',
            JOB_LABOUR_EDIT: 'Editar líneas de empleo de trabajo',
            JOB_LABOUR_UPDATED: 'Trabajo laboral actualizado',
            JOB_LABOUR_CREATED: 'Trabajo laboral creado',
            JOB_LABOUR_LIST: 'Trabajos laborales',
            LABOUR_LINES: 'Líneas laborales',
            TIME_WORKED: 'Tiempo Trabajado',
            TIME_TRAVELLED: 'Tiempo viajado',
            COMPLETION_BEFORE_ATTENDANCE: 'La fecha de finalización no debe ser anterior a la fecha de asistencia',
            JOBS_RATIO: 'Ratio de trabajos',
            LOCATION_REQUIRED_JOB: 'Requerir ubicación para trabajos reactivos',
            NO_JOB_VISIBILITY_ERROR: 'No tiene permiso para ver este trabajo',
            AWAITING_PAPERWORK_SINCE: 'En espera de papeleo desde (horas)',
            AWAITING_PAPERWORK: 'Esperando papeleo',
            JOB_PAPERWORK_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> rechazó el papeleo.',
            JOB_VALUE: 'Valor',
            JOB_VALUE_ADD: 'Añadir valores de trabajo',
            JOB_VALUE_APPROVE: 'Aprobar valores de trabajo',
            JOB_VALUE_APPROVE_DESCRIPTION: 'La descripción que se debe utilizar en la factura.',
            JOB_VALUE_REJECT: 'Rechazar valores de trabajo',
            JOB_VALUE_REJECT_DESCRIPTION: 'La razón para rechazar estos valores',
            JOB_VALUE_LABOUR: 'Valor laboral',
            JOB_VALUE_MATERIAL: 'Valor de los materiales',
            JOB_VALUE_LABOUR_WITH_VALUE: 'Valor laboral {{ value | number:2 }}',
            JOB_VALUE_MATERIAL_WITH_VALUE: 'Valor de los materiales {{ value | number:2 }}',
            JOB_TOTAL_VALUE_WITH_VALUE: 'Valor total {{ value | number:2 }}',
            JOB_VALUE_APPROVE_CREATED: 'Valor de trabajo aprobado',
            JOB_VALUE_SEPARATE_INVOICE: 'Factura separada',
            JOB_VALUE_INVOICE_NUMBER: 'Número de factura',
            JOB_VALUE_INVOICE_DATE: 'Fecha de factura',
            JOB_VALUE_PREVIOUS_VALUES: 'Valores anteriores',
            JOB_TOTAL_VALUE: 'Valor total',
            JOB_FOC_ADD: 'Gratuito',
            JOB_FOC: 'Marcar como gratuito',
            JOB_FOC_SUBMIT: 'Enviar',
            JOB_FOC_CREATED: 'Trabajo marcado como gratuito',
            JOB_PRIMARY_OPERATIVE_APPROVAL: 'Aprobación operativa primaria',
            JOB_APPROVER_APPROVAL: 'Aprobación de aprobador de trabajo',
            JOB_PENDING_WITH_ME: 'Pendiente de mi',
            EXPLICIT_ACCESS: 'Acceso explícito',
            EXPLICIT_ACCESS_LIST: 'Acceso explícito',
            EXPLICIT_ACCESS_ADD: 'Conceder acceso',
            EXPLICIT_ACCESS_EDIT: 'Editar',
            ACCESS_GRANTED: 'Acceso concedido',
            ACCESS_REVOKED: 'Acceso revocado',
            DATE_NOT_IN_RANGE: 'La fecha no está en el intervalo permitido',
            ORIGINAL_VALUES: 'Valores originales',
            MARKEDUP_VALUES: 'Valores marcados',
            JOBS_DUE_BY: 'Vence el',
            JOBS_DUE_BY_TYPE: 'Tipo',
            JOBS_DUE_BY_ATTENDANCE: 'Asistencia',
            JOBS_DUE_BY_COMPLETION: 'Finalización',
            JOBS_DUE_BY_EITHER: 'Ya sea',
            JOBS_DUE_BY_FREQUENCY: 'Frecuencia',
            JOBS_DUE_BY_DAYS: 'Día',
            JOBS_DUE_BY_WEEKS: 'Semana',
            JOBS_DUE_BY_MONTHS: 'Mes',
            JOBS_DUE_BY_INTERVAL: 'Intervalo',
            WAIVE_MANAGEMENT_FEE: 'Eximir de tarifa de gestión',
            WAIVE_MANAGEMENT_FEE_SUBMIT: 'Enviar',
            JOB_WAIVE_MANAGEMENT_FEE: 'Tarifa de administración exonerada',
            JOB_WAIVE_MANAGEMENT_FEE_ACTION: '<strong>{{ ::event._links["user"].title }} </strong> eximió la tarifa de administración.',
            UPHOLD_MANAGEMENT_FEE: 'Cuota de gestión de mantenimiento',
            UPHOLD_MANAGEMENT_FEE_SUBMIT: 'Enviar',
            JOB_UPHOLD_MANAGEMENT_FEE: 'Cuota de gestión mantenida',
            JOB_UPHOLD_MANAGEMENT_FEE_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> confirmó la tarifa de gestión.',
            JOB_WORKFLOW_ESCALATE: 'Escalar',
            JOB_WORKFLOW_ESCALATE_OPERATION: 'Escalar',
            JOB_WORKFLOW_ESCALATE_OPERATION_SUBMIT: 'Escalar',
            JOB_WORKFLOW_COMMENCE: 'Comenzar trabajo',
            JOB_WORKFLOW_CANCEL_EXTENSION: 'Cancelar ampliación',
            JOB_WORKFLOW_CANCEL_EXTENSION_SUBMIT: 'Cancelar ampliación',
            JOB_WORKFLOW_CANCEL_EXTENSION_SUCCESS: 'Ampliación de trabajo cancelada',
            JOB_WORKFLOW_CANCEL_ASSIGNMENT: 'Cancelar asignación',
            JOB_WORKFLOW_SKIP: 'Omitir',
            JOB_WORKFLOW_SKIP_OPERATION: 'Omitir',
            JOB_WORKFLOW_SKIP_OPERATION_SUBMIT: 'Enviar',
            JOB_WORKFLOW_EDIT_JOB: 'Editar trabajo',
            JOB_WORKFLOW_EDIT_JOB_SUBMIT: 'Guardar',
            JOB_WORKFLOW_EDIT_JOB_SUCCESS: 'Trabajo editado',
            JOB_WORKFLOW_P2P: 'P2P',
            NEEDS_ONE_STATUTORY_FILE: 'Al menos un archivo debe ser de tipo normativo',
            NO_FUTURE_JOBS: 'No hay trabajos futuros',
            INVALID_OPERATIVE_SELECTED: 'El operativo seleccionado no es válido',
            SELECTED_OPERATIVE_DOESNT_EXIST: 'El operativo seleccionado no existe',
            OPERATIVE_REQUIRED_NO_PRIMARY_SET: 'Operativo requerido, ya que no se establece ningún operativo principal',
            ONE_HOUR: 'No puede solicitar una fecha posterior a 1 hora a partir de ahora',
            SERVICE_PROVIDER_NOT_CONTRACTOR_APPROVED: 'Proveedor de servicios no aprobado por el contratista',
            COMPLETION_DATE_CANNOT_BE_BEFORE_DATE_ATTENDED: 'La fecha de finalización no puede ser anterior a la fecha de asistencia',
            JOB_APPROVAL_REQUESTED: 'Aprobación de trabajo solicitada',
            JOB_ASSIGNMENT_ACCEPTED: 'Asignación de trabajo aceptada',
            JOB_ASSIGNMENT_REJECTED: 'Asignación de trabajo rechazada',
            JOB_EXTENSION_CANCELLED: 'Ampliación de trabajo cancelada',
            JOB_EXTENSION_REQUESTED: 'Ampliación de trabajo solicitada',
            JOB_OPERATION_ACKNOWLEDGED: 'Trabajo reconocido',
            JOB_OPERATION_COMMENCED: 'Trabajo comenzado',
            JOB_OPERATION_COMPLETED: 'Trabajo completado',
            JOB_OPERATION_COMPLETED_PAPERWORK: 'Papeleo de trabajo completado',
            JOB_OPERATION_MISSED: 'Trabajo omitido',
            JOB_OPERATION_REASSIGNED: 'Trabajo reasignado',
            JOB_PROPOSAL_CANCELLED: 'Nuevo SLA de trabajo propuesto cancelado',
            JOB_PROPOSAL_REQUESTED: 'Nuevo SLA de trabajo propuesto',
            JOB_MESSAGE: 'Actualizaciones de trabajo añadidas',
            ADD_NEW_JOB_REQUISITES: 'Añadir un nuevo requisito de trabajo',
            ADD_PARTNERSHIP: 'Añadir asociación',
            ANSWERED_NA: 'respondido «N/A»',
            ANSWERED_YES: 'respondido «Sí»',
            ATTACHED_FILES: 'Archivo(s) adjunto(s)',
            DAILOG_PARTNERSHIP_EDIT: 'Editar asociaciones',
            DELETE_PARTNERSHIP: 'Eliminar asociación',
            DELETE_SELECTED: 'Eliminar seleccionado',
            DISALLOW_NOT_APPLICABLE: 'No permitir N/A',
            EDIT_JOB_REQUISITE: 'Editar requisito de trabajo',
            HASNOTAPPLICABLEOPTION: 'Permitir opción N/A',
            HELPMESSAGE: 'Texto de ayuda',
            JOB_REQUISITE: 'Requisito de trabajo',
            JOB_REQUISITE_ADD: 'Añadir un nuevo requisito de trabajo',
            JOB_REQUISITE_CREATED: 'Requisito de trabajo creado',
            JOB_REQUISITE_DELETED: 'Requisito de trabajo eliminado',
            JOB_REQUISITE_EDIT: 'Editar requisito de trabajo',
            JOB_REQUISITE_LIST: 'Requisitos de trabajo',
            JOB_REQUISITE_PARTNERSHIP_ADDED: 'Asociación de requisito de trabajo añadida',
            JOB_REQUISITE_PARTNERSHIP_EDIT: 'Editar asociaciones de requisito de trabajo',
            JOB_REQUISITE_PARTNERSHIP_LIST: 'Asociaciones',
            JOB_REQUISITE_SERVICE_PROVIDER_LIST: 'Proveedores de Servicios',
            JOB_REQUISITE_SERVICEPROVIDER_ADDED: 'Proveedor de servicios de requisitos de trabajo añadido',
            JOB_REQUISITE_SERVICEPROVIDER_EDIT: 'Editar proveedores de servicios de requisitos de trabajo',
            JOB_REQUISITE_SITE_ADDED: 'Sitio de requisito de trabajo añadido',
            JOB_REQUISITE_SITE_EDIT: 'Editar sitios de requisito de trabajo',
            JOB_REQUISITE_SITE_LIST: 'Sitios',
            JOB_REQUISITE_UPDATED: 'Requisito de trabajo actualizado',
            JOB_REQUISITE_PENDING_APPROVAL: 'Requisitos de trabajo pendientes de aprobación',
            JOB_REQUISITEUPDATED: 'Requisito de trabajo actualizado',
            NO_ATTACHMENT: 'No hay adjuntos',
            PARTNERSHIPS_DELETED_FROM_JOB_REQUISITE: 'Asociaciones de requisito de trabajo eliminadas',
            REQUEST_ATTACHMENT: 'Solicitar adjunto',
            REQUESTATTACHMENT: 'Solicitar adjunto',
            SERVICEPROVIDERS_DELETED_FROM_JOB_REQUISITE: 'Proveedores de servicios de requisitos de trabajo eliminados',
            SITES_DELETED_FROM_JOB_REQUISITE: 'Sitios de requisito de trabajo eliminados',
            SP_APPROVED: 'Aprobado por PS',
            APPROVER_APPROVED: 'Aprobador Aprobado',
            JOBREQUISITE: 'Requisito de trabajo',
            JOB_REQUISITE_ANSWERED: '<strong>{{ jobRequisiteAnswer._links.user.title }}</strong> {{ jobRequisiteAnswer.answer | translate }} a {{ jobRequisiteAnswer._links.jobrequisite.title }}',
            JOB_REQUISITE_YET_TO_ANSWER: '<strong>{{ jobRequisiteAnswer._links.user.title }}</strong> aún no ha respondido {{ jobRequisiteAnswer._links.jobrequisite.title }}',
            JOB_REQUISITE_ANSWER_APPROVE: 'Aprobar respuesta de requisito de trabajo',
            JOB_REQUISITE_ANSWER_APPROVE_SUBMIT: 'Aprobar',
            JOB_REQUISITE_ANSWER_APPROVE_SUCCESS: 'Respuesta de requisito de trabajo aprobada',
            JOB_REQUISITE_ANSWER_REJECT: 'Rechazar respuesta de requisito de trabajo',
            JOB_REQUISITE_ANSWER_REJECT_SUBMIT: 'Rechazar',
            JOB_REQUISITE_ANSWER_REJECT_SUCCESS: 'Respuesta de requisito de trabajo rechazada',
            JOB_REQUISITE_ESCALATE: 'Aumentar requisito de trabajo',
            JOB_REQUISITE_ANSWER_ESCALATE: 'Aumentar respuesta de requisito de trabajo',
            JOB_REQUISITE_ANSWER_ESCALATE_SUBMIT: 'Escalar',
            JOB_REQUISITE_ANSWER_ESCALATE_SUCCESS: 'El requisito de trabajo ha sido aumentado',
            REQUISITES: 'Requisitos',
            OPT_IN: 'Opt-In',
            OPT_OUT: 'Opt-Out',
            APPROVAL_STRATEGY: 'Estrategia de aprobación',
            NO_APPROVAL_REQUIRED: 'No se requiere aprobación',
            APPROVER_APPROVAL_REQUIRED: 'Se requiere aprobación del aprobador',
            PRIMARY_AND_APPROVER_APPROVAL_REQUIRED: 'Se requiere aprobación del autorizador y del operador principal',
            FORM: 'Formulario',
            FORM_SUMMARY: 'Resumen del formulario',
            FORM_STATUS: 'Estado del formulario',
            BACK_TO_JOB_REQUISITES: 'Volver a Requisitos de trabajo',
            VIEW_FORM: 'Ver Formulario',
            HAS_JOB_REQUISITE: 'Tiene requisito de trabajo',
            PRIMARY_OPERATIVE: 'Operativo primario',
            OPERATIVE_TYPE: 'Tipo operativo',
            HEALTH_AND_SAFETY: 'Salud y Seguridad',
            ALLOW_ESCALATION: 'Permitir escalada al aprobador',
            QUOTED_WORKS: 'Trabajos cotizados',
            PLANNED_JOB: 'Trabajo planificado',
            REACTIVE_JOB: 'Trabajo reactivo',
            JOB_GROUPS: 'Grupos de trabajo',
            ADD_NEW_JOB_GROUP: 'Añadir un nuevo grupo de trabajo',
            EDIT_JOB_GROUP: 'Editar grupo de trabajo',
            JOB_GROUP_LIST: 'Grupos de trabajo',
            JOB_GROUP_ADD: 'Añadir un nuevo grupo de trabajo',
            JOB_GROUP_EDIT: 'Editar grupo de trabajo',
            JOB_GROUP_CREATED: 'Grupo de trabajo creado',
            JOB_GROUP_UPDATED: 'Grupo de trabajo actualizado',
            JOB_GROUP_DELETED: 'Grupo de trabajo eliminado',
            JOB_GROUP_ADDED: 'Grupo de trabajo añadido',
            JOB_GROUP_ENTER: 'Introducir un nombre de grupo de trabajo...',
            JOB_ESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> aumentó el trabajo.',
            JOB_ESCALATED_SYSTEM_ACTION: 'El sistema actualizó automáticamente el trabajo.',
            JOB_ESCALATION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }} </strong> canceló la escalada.',
            JOB_DEESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> reducción del trabajo.',
            JOB_DEESCALATED_SYSTEM_ACTION: 'El sistema canceló automáticamente la escalada.',
            JOB_REQUISITE_ANSWER_APPROVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> aprobó una respuesta de requisito de trabajo.',
            JOB_REQUISITE_ANSWER_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> rechazó una respuesta de requisito de trabajo.',
            JOB_REQUISITE_ANSWERED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> respondió un requisito de trabajo.',
            JOB_REQUISITE_ANSWER_ESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> aumentó requisito de trabajo.',
            REPORTER_EMAIL: 'Correo electrónico del reportero',
            REPORTER_PHONE: 'Teléfono del reportero',
            REPORTER_RELATIONSHIP: 'Relación de reportero',
            REPORTER_NOTIFY_ON_CREATE: 'Notificar al reportero al crear',
            REPORTER_NOTIFY_ON_COMPLETE: 'Notificar al reportero al completar',
            CONTACT_NAME: 'Nombre de contacto',
            CONTACT_EMAIL: 'Correo electrónico de contacto',
            CONTACT_PHONE: 'Teléfono de contacto',
            CONTACT_RELATIONSHIP: 'Relación con el contacto',
            CONTACT_NOTIFY_ON_CREATE: 'Notificar al contacto al crear',
            CONTACT_NOTIFY_ON_COMPLETE: 'Notificar al contacto al completar',
            JOB_CONTACTS: 'Contactos de trabajo',
            JOB_CONTACT_LIST: 'Contactos de trabajo',
            JOB_CONTACT_CREATED: 'Contacto de trabajo creado',
            JOB_CONTACT_UPDATED: 'Contacto de trabajo actualizado',
            JOB_CONTACT_DELETED: 'Contacto de trabajo eliminado',
            JOB_EDITED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> editó el trabajo.',
            JOB_OPERATIVE_REASSIGNED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> reasignó operativa.',
            JOB_SLAUPDATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> actualizó el trabajo.',
            JOB_EXPLICIT_ACCESS_GRANTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> otorgó acceso al trabajo a {{ ::event._links["privileged-user"].title }}.',
            JOB_EXPLICIT_ACCESS_REVOKED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> revocó acceso al trabajo a {{ ::event._links["privileged-user"].title }}.',
            JOB_EXPLICIT_ACCESS_EXPIRED_ACTION: 'Acceso al trabajo caducado para {{ ::event._links["privileged-user"].title }}.',
            MERIDIAN_DOC_TYPE: 'Tipo de documento Meridian',
            RISKWISE_DOC_TYPE: 'Tipo de documento RiskWise',
            QCOMPLIANCE_DOC_TYPE: 'Tipo de documento de cumplimiento Q',
            INSPECTIONDATE: 'Fecha de inspección',
            NEXTINSPECTIONDATE: 'Siguiente fecha de inspección',
            UPLOAD_TO_RISKWISE: 'Enviar a RiskWise',
            RISKWISE_UPLOAD_ADD: 'Enviar a RiskWise',
            IMPORT: 'Importar',
            IMPORT_TYPE: 'Tipo',
            IMPORTS_LIST: 'Importaciones',
            IMPORT_DETAILS_LIST: 'Detalles',
            IMPORT_IS_TEST_RUN: '¿Prueba de funcionamiento?',
            IMPORT_STARTED: 'Empezado en',
            IMPORT_ENDED: 'Finalizó en',
            IMPORT_TOTAL_ROWS: 'Total',
            IMPORT_VALID_ROWS: 'Válida',
            IMPORT_INVALID_ROWS: 'No válida',
            IMPORT_TEST_RUN: 'Depurar',
            IMPORTS: 'Importaciones',
            IMPORT_CSV_FILE: 'Importar archivo (csv)',
            IMPORT_FILE: 'Importar archivo',
            EXTENSION_XML: '.xml',
            EXTENSION_CSV: '.csv',
            IMPORT_ERRORS: 'Errores',
            IMPORT_ROW_NUMBER: 'Fila',
            IMPORT_COLUMN_NAME: 'Clave',
            IMPORT_LOGS: 'Registros de importaciones',
            IMPORT_LOG_TYPE: 'Tipo',
            IMPORT_LOG_ALL: 'Todo',
            IMPORT_LOG_ERROR: 'Error',
            IMPORT_LOG_CHANGE: 'Cambiar',
            IMPORT_LOG_NO_CHANGE: 'Sin cambios',
            IMPORT_LOG_WARN: 'Advertir',
            IMPORT_ERROR_MESSAGE: 'Mensaje de error',
            IMPORT_MESSAGE: 'Mensaje',
            IMPORT_GET_TEMPLATE: 'Obtener plantilla',
            IMPORTS_NO_RESULTS: 'Sin resultados',
            USER_TASK: 'Tarea (usuario)',
            USER_ASSET: 'Activo (usuario)',
            USER_ASSET_TASK: 'Activo/Tarea (usuario)',
            USER_ASSET_TASK_WARNING: 'Tenga en cuenta que, dado que tiene el permiso de Administrar importaciones, la importación no se limita al acceso al sitio.',
            CONTRACT_WARNING: 'Tenga en cuenta que solo puede agregar contratos, no actualizarlos.',
            COMMA_SEPARATED: '(separado con coma)',
            SUBASSET: 'Subactivo',
            WASTE_ENTRY: 'Entrada de residuos',
            SPREADSHEET_TITLE: 'Título de la hoja de cálculo',
            VIEW_LEGEND: 'Ver leyenda',
            DATA_REQUIREMENTS: 'Datos necesarios',
            IMPORT_USERS: 'Importar usuarios',
            IMPORT_USERS_ADD: 'Importar usuarios',
            IMPORT_ADD: 'Importación nueva',
            IMPORT_LEGEND: 'Leyenda',
            DOWNLOAD_IMPORT_TEMPLATE: 'Descargar {{ entity }} plantilla de importación',
            IMPORT_ERROR: 'Error de importación',
            IMPORT_QUEUED: 'Importación en cola',
            SITE_TENANT: 'Inquilino del sitio',
            PCD_IMPORTS: 'Importación de PCD',
            PCD_LIST: 'Lista de importaciones de PCD',
            PCD_IMPORT_ADD: 'Nueva importación de PCD',
            PCD_IMPORT_CREATE_SITES: 'Crear sitios',
            PCD_IMPORT_CREATE_SITE_GROUPS: 'Crear grupos de sitios',
            PCD_IMPORT_CREATE_SECTORS: 'Crear sectores',
            PCD_IMPORT_CREATE_REGIONS: 'Crear regiones',
            PCD_IMPORT_CREATE_USERS: 'Crear usuarios',
            PCD_IMPORT_CREATE_CORE_CLIENTS: 'Crear clientes principales',
            PCD_IMPORT_UPDATE_SITE_CONTACTS: 'Actualizar contactos del sitio',
            PCD_IMPORT_UPDATE_SITE_ACCESS: 'Actualizar el acceso al sitio',
            PCD_IMPORT_UPDATE_SITE: 'Sitio de actualización',
            PCD_IMPORT_UPDATE_SITE_GROUP: 'Actualizar grupo de sitio',
            NO_PCD_IMPORT_FILE_ADDED: 'Ningún archivo de importación PCD añadido',
            DOWNOLAD_ASSET_TYPES: 'Descargar tipos de activos',
            DOWNOLAD_SERVICE_ROUTINES: 'Descargar rutinas de servicio',
            DOWNOLAD_STATUTORY_TYPES: 'Descargar tipos normativos',
            CREATE_OPTIONS: 'Crear opciones',
            UPDATE_OPTIONS: 'Actualizar opciones',
            PCD: 'PCD',
            PCD_DISABLED_FOR_CLIENT: 'Las importaciones PCD no están habilitadas',
            SCD_IMPORTS: 'Importaciones de SCD',
            SCD_LIST: 'Lista SCD',
            SCD_IMPORT_ADD: 'Nueva importación de SCD',
            SCD: 'SCD',
            SCD_IMPORT_CREATE_SERVICE_PROVIDER: 'Crear proveedor de servicios',
            SCD_IMPORT_CREATE_SERVICE_PROVIDER_GROUP: 'Crear grupo de proveedores de servicios',
            SCD_IMPORT_CREATE_PARTNERSHIP: 'Crear asociación',
            SCD_IMPORT_UPDATE_SERVICE_PROVIDER: 'Actualizar proveedor de servicios',
            SCD_IMPORT_UPDATE_SERVICE_PROVIDER_GROUP: 'Actualizar el grupo de proveedores de servicios',
            SCD_IMPORT_UPDATE_PARTNERSHIP: 'Actualizar asociación',
            SITE_CONTACT_DOESNT_EXIST: 'El contacto del sitio no existe',
            PARENT_ALREADY_HAS_A_PARENT: 'El medidor primario especificado ya tiene un elemento primario: solo se admite un nivel de anidamiento.',
            PARENT_BELONGS_TO_DIFFERENT_SITE: 'El medidor primario especificado pertenece a un sitio diferente.',
            CANNOT_CHANGE_PARENT: 'No se puede cambiar la matriz del medidor secundario.',
            PARENT_IS_NOT_ACTIVE: 'El medidor principal está inactivo.',
            METER_READING: 'Lectura de medidores',
            TYPE: 'Tipo',
            MIN_VALUE: 'Valor mínimo',
            MAX_VALUE: 'Valor máx.',
            MIN_LENGTH: 'Longitud mínima',
            MAX_LENGTH: 'Longitud máxima',
            CHOICES: 'Opciones',
            BE_APPROVER: 'Necesita ser aprobador',
            NUMBER_LEGEND: 'Leyenda número',
            DRAFT: 'Borrador',
            TRAINING: 'Formación',
            ACTIVE: 'Activo',
            INACTIVE: 'Inactivo',
            YES: 'Sí',
            NO: 'No',
            IMPORTS_ID: 'Id',
            IMPORTS_FULLNAME: 'Nombre completo',
            IMPORTS_NAME: 'Nombre',
            IMPORTS_ADDRESS1: 'Dirección línea 1',
            IMPORTS_ADDRESS2: 'Dirección línea 2',
            IMPORTS_ADDRESS3: 'Dirección línea 3',
            IMPORTS_TOWN: 'Dirección ciudad',
            IMPORTS_REGION: 'Dirección región',
            IMPORTS_POSTCODE: 'Dirección código postal',
            IMPORTS_COUNTRY: 'Dirección país',
            IMPORTS_STATUS: 'Estado actual',
            IMPORTS_TELEPHONE: 'Número de teléfono',
            IMPORTS_WEBSITE: 'Página web',
            IMPORTS_CITY: 'Ciudad',
            IMPORTS_SERVICE_PROVIDERS: 'Nombres de los proveedores de servicios',
            IMPORTS_SERVICE_PROVIDER_IDS: 'Identificadores del proveedor de servicios',
            IMPORTS_SERVICE_PROVIDER_ID: 'Identificador del proveedor de servicios',
            IMPORTS_SERVICE_PROVIDER: 'Proveedor de servicios (Id o nombre)',
            IMPORTS_SERVICE_PROVIDER_NAME: 'Nombre del proveedor de servicios',
            IMPORTS_SERVICE_ROUTINE_IDS: 'Identificadores de rutina de servicio',
            IMPORTS_SERVICE_ROUTINE_ID: 'Identificador de rutina de servicio',
            IMPORTS_SERVICE_TYPES: 'Nombres de tipo de servicio',
            IMPORTS_SERVICE_TYPES_IDS: 'Identificadores de tipo de servicio',
            IMPORTS_STATUTORY_ID: 'Identificador normativo',
            IMPORTS_LOCATION: 'Nombre de ubicación, ID o nuevo nombre de ubicación',
            IMPORTS_ROLES: 'Roles',
            IMPORTS_ROLE_IDS: 'Identificadores de función',
            IMPORTS_DESCRIPTION: 'Descripción',
            IMPORTS_UTILITY_CONSULTANT: 'Consultor de servicios públicos',
            IMPORTS_SITE_IDS: 'Identificadores de sitio',
            IMPORTS_SITE_ID: 'Identificador de sitio',
            IMPORTS_SITE: 'Sitio (Id o Nombre)',
            IMPORTS_JOB_GROUP_ID: 'Id de grupo de trabajo',
            IMPORTS_ACTIVE: 'Activo',
            IMPORTS_REFERENCE: 'Referencia',
            IMPORTS_EXTERNAL_REFERENCE: 'Referencia externa',
            IMPORTS_TRAINED_AT: 'Formado en',
            IMPORTS_ACTIVE_AT: 'Activo en',
            IMPORTS_INACTIVE_AT: 'Inactivo en',
            IMPORTS_GROUP_ID: 'Id de grupo',
            IMPORTS_SERVICE_TYPE_ID: 'Id de tipo de servicio',
            IMPORTS_NEW_CUSTOMER_ACCESS: 'Nuevo acceso a cliente',
            IMPORTS_USERNAME: 'Nombre de usuario',
            IMPORTS_EMAIL: 'Dirección de correo electrónico válida',
            IMPORTS_POSITION: 'Posición en la compañía',
            IMPORTS_NOTES: 'Notas',
            IMPORTS_MAKE: 'Marca',
            IMPORTS_QUANTITY: 'Cantidad',
            IMPORTS_MODEL: 'Modelo',
            IMPORTS_BARCODE: 'Código de barras',
            IMPORTS_END_OF_LIFE: 'Duración del equipo',
            IMPORTS_CHARGEABLE: 'Cargable',
            IMPORTS_BUSINESS_CONTINUITY_RISK: 'Riesgo de continuidad de negocio',
            IMPORTS_REPLACEMENT_COST_LABOUR: 'Coste de reemplazo de la mano de obra',
            IMPORTS_REPLACEMENT_COST_MATERIALS: 'Coste de reemplazo de materiales',
            IMPORTS_ASSET_TYPE_ID: 'Id del tipo de activo',
            IMPORTS_SERIAL_NUMBER: 'Número de serie',
            IMPORTS_ASSET_NFC_ID: 'Identificador de etiqueta NFC',
            IMPORTS_LOCATION_ID: 'Id de ubicación',
            IMPORTS_ASSET_SCORE_TYPE_IDS: 'Identificadores de tipo de puntuación de activos',
            IMPORTS_PRIMARY_JOB_OPERATIVE_ID: 'Id de operativa de trabajo principal',
            IMPORTS_PRIMARY_QUOTE_OPERATIVE_ID: 'Id operativa de la cotización primaria',
            IMPORTS_PRIMARY_OPERATIVE_ID: 'Id operativa primaria',
            IMPORTS_PRIMARY_ESCALATION_OPERATIVE_ID: 'Id operativa de escalada primaria',
            IMPORTS_PRIMARY_HEALTH_SAFETY_OPERATIVE_ID: 'Id operativa de salud y seguridad primaria',
            IMPORTS_OPERATIVE_IDS: 'Identificadores operativos',
            IMPORTS_SERVICE_PROVIDER_CONTRACTOR_APPROVED: 'Aprobado por el contratista',
            IMPORTS_SERVICE_PROVIDER_REQUIRE_SIGNATURE: 'Requiere firma',
            IMPORTS_GROUP_IDS: 'Identificadores de grupos',
            IMPORTS_AREA: 'Zona',
            IMPORTS_AREA_UNITS: 'Unidades de área',
            IMPORTS_SECTOR_ID: 'Id de sector',
            IMPORTS_REGION_ID: 'Id de región',
            IMPORTS_EXCLUDE_SITE_FROM_P2P: 'Excluir sitio de p2p',
            IMPORTS_P2P_STARTED_AT_DATE: 'P2P comenzó en la fecha',
            IMPORTS_JOB_APPROVAL_REQUIRED: 'Aprobación de trabajo requerida',
            IMPORTS_JOB_APPROVERS_ID: 'Id de aprobadores de trabajo',
            IMPORTS_JOB_EXTENSIONS_APPROVAL_REQUIRED: 'Aprobación de ampliación de trabajo requerida',
            IMPORTS_JOB_EXTENSIONS_APPROVERS_ID: 'Id de aprobadores de ampliaciones de trabajo',
            IMPORTS_QUOTE_APPROVERS_ID: 'Id de aprobadores de cotización',
            IMPORTS_PPM_APPROVAL_REQUIRED: 'Aprobación requerida PPM',
            IMPORTS_PPM_APPROVERS_ID: 'Id de aprobadores de PPM',
            IMPORTS_PPM_EXTENSIONS_APPROVAL_REQUIRED: 'Se requiere aprobación de extensiones PPM',
            IMPORTS_PPM_EXTENSIONS_APPROVERS_ID: 'Id de aprobadores de extensiones PPM',
            IMPORTS_CONTACT_APPROVAL_REQUIRED: 'Se requiere aprobación de contacto',
            IMPORTS_CONTACT_APPROVERS_ID: 'Id de aprobadores de contacto',
            IMPORTS_SURVEY_APPROVERS_ID: 'Id. de aprobadores de encuesta',
            IMPORTS_SURVEY_APPROVAL_REQUIRED: 'Se requiere aprobación de encuesta',
            IMPORTS_SURVEY_APPROVAL_APPROVERS_ID: 'Id. de aprobadores de encuesta',
            IMPORTS_SERVICE_PROVIDERS_ID: 'Id de proveedores de servicios',
            IMPORTS_REFERENCE_PREFIX: 'Prefijo de referencia',
            IMPORTS_SITE_ASSOCIATE_ID1: 'Importar asociado de sitio id1',
            IMPORTS_SITE_ASSOCIATE_USERS_ID1: 'Importar usuarios asociados de sitio id1',
            IMPORTS_SITE_ASSOCIATE_ID2: 'Importar asociado de sitio id2',
            IMPORTS_SITE_ASSOCIATE_USERS_ID2: 'Importar usuarios asociados de sitio id2',
            IMPORTS_SITE_ASSOCIATE_ID3: 'Importar asociado de sitio id3',
            IMPORTS_SITE_ASSOCIATE_USERS_ID3: 'Importar usuarios asociados del sitio id3',
            IMPORTS_COMPANY: 'Empresa',
            IMPORTS_SUPPLY_TYPE_ID: 'Id de tipo de suministro',
            IMPORTS_REFERENCE_TYPE: 'Tipo de referencia',
            IMPORTS_ACCOUNT_NUMBER: 'Número de cuenta',
            IMPORTS_MULTIPLIER: 'Multiplicador',
            IMPORTS_MAX_VALUE: 'Valor máx.',
            IMPORTS_FREQUENCY: 'Frecuencia',
            IMPORTS_INTERVAL: 'Intervalo',
            IMPORTS_IS_DUAL_RATED: 'Tiene doble clasificación',
            IMPORTS_IS_AUTOMATICALLY_READ: 'Leído automáticamente',
            IMPORTS_FILE_REQUIRED_ON_READING: 'Archivo requerido en la lectura',
            IMPORTS_TYPE_ID: 'Id de tipo',
            IMPORTS_MANUFACTURE_ID: 'Id de fabricación',
            IMPORTS_UTILITY_PROVIDER_ID: 'Id de proveedor de servicios públicos',
            IMPORTS_OCCUPANCY_ID: 'Id de ocupación',
            IMPORTS_READ_AT: 'Leer en',
            IMPORTS_LAST_READING: 'Última lectura',
            IMPORTS_UNIT: 'Unidad de medidor',
            IMPORTS_TUR: 'TUR',
            IMPORTS_TYPE: 'Tipo',
            IMPORTS_SUMMARY: 'Resumen',
            IMPORTS_OPERATIVE_ID: 'Id operativa',
            IMPORTS_ASSET_ID: 'Id de activos',
            IMPORTS_CONTRACT_TYPE: 'Tipo de contrato',
            IMPORTS_START_AT: 'Empieza en',
            IMPORTS_REVIEW_AT: 'Revisar en',
            IMPORTS_REVIEW_REMINDER: 'Revisar recordatorio',
            IMPORTS_EXPIRY_DATE: 'Fecha de caducidad',
            IMPORTS_EXPIRES_REMINDER: 'Caduca el recordatorio',
            IMPORTS_VALUE: 'Valor',
            IMPORTS_BILLING_INTERVAL: 'Intervalo de facturación',
            IMPORTS_BILLING_FREQUENCY: 'Frecuencia de facturación',
            IMPORTS_LAST_TENDERED: 'Última licitación',
            IMPORTS_NOTICE_PERIOD_INTERVAL: 'Intervalo del período de aviso',
            IMPORTS_NOTICE_PERIOD_FREQUENCY: 'Frecuencia del período de aviso',
            IMPORTS_WASTE_UNIT: 'Unidad (Id o nombre)',
            IMPORTS_WASTE_TYPE: 'Tipo de residuo (Id, nombre)',
            IMPORTS_WASTE_COLLECTION_DATE: 'Fecha y hora de recogida',
            IMPORTS_WASTE_VALUE: 'Valor de los residuos',
            IMPORTS_WASTE_TREATMENT_TYPE: 'Tipo de tratamiento (Id o nombre)',
            IMPORTS_WASTE_TREATMENT_TYPE_VALUE: 'Valor del tipo de tratamiento',
            IMPORTS_METER_ID: 'Id del medidor',
            IMPORTS_READING: 'Lectura de medidores',
            IMPORTS_READING_TYPE: 'Tipo de lectura',
            IMPORTS_ROLLED_OVER: 'Transferido',
            IMPORTS_FREQUENCY_START_DATE: 'Fecha de inicio de la frecuencia (no puede ser antes del 1 de enero del año pasado)',
            HELPDESK: 'Atención al cliente',
            HELPDESK_DASHBOARD: 'Panel de control de servicio de asistencia',
            QUOTES_LIST: 'Cotizaciones',
            ALARM_AT: 'Alarma a',
            ALARM_LOCK: 'Alarma bloqueada',
            ALARMED: 'Alarmado',
            HD_NO_RESULTS: 'Sin resultados',
            HD_QUOTES: 'Cotizaciones',
            HD_STATUTORY: 'Normativa',
            HD_REACTIVE: 'Reactivo',
            HD_PLANNED: 'Planificado',
            HD_ESCALATIONS: 'Escaladas',
            HD_SATISFACTION_SURVEYS: 'Encuestas de satisfacción',
            HD_AWAITING_PAPERWORK: 'Esperando papeleo',
            HD_TOOLTIP_ALARMS: 'Alarmas',
            HD_TOOLTIP_AWAITING_APPROVAL: 'Esperando aprobaciones',
            HD_TOOLTIP_OVERDUE: 'Vencido',
            HD_TOOLTIP_ESCALATIONS: 'Escaladas',
            HD_TOOLTIP_CHASE: 'Búsqueda',
            HD_TOOLTIP_REMINDER: 'Recordatorio',
            HD_TOOLTIP_REACTIVE_CHASE: 'Búsqueda',
            HD_TOOLTIP_REACTIVE_ACKNOWLEDGE: 'Acuse de recibo',
            HD_TOOLTIP_REACTIVE_APPROVAL: 'Aprobación',
            HD_TOOLTIP_REACTIVE_EXTENSION_APPROVAL: 'Aprobación de extensión',
            HD_TOOLTIP_REACTIVE_EXTENSION_REQUEST: 'Solicitud de ampliación',
            HD_TOOLTIP_JOB_REACTIVE_EXTENSION_REQUEST: 'Solicitud de ampliación de trabajo',
            HD_TOOLTIP_QUOTE_REACTIVE_EXTENSION_REQUEST: 'Solicitud de ampliación de cotización',
            HD_TOOLTIP_REACTIVE_PRIORITY_OVERDUE: 'Asistencia a búsqueda',
            HD_TOOLTIP_REACTIVE_ALARMS: 'Recordatorio',
            HD_TOOLTIP_QUOTES_CHASE: 'Búsqueda',
            HD_TOOLTIP_QUOTES_REMINDER: 'Recordatorio',
            HD_TOOLTIP_QUOTES_EXTENSION_APPROVAL: 'Aprobación de extensión',
            HD_TOOLTIP_AWAITING_PAPERWORK: 'Esperando papeleo',
            HD_DAYS_SHORT: '{{days}}d',
            HD_HOURS_SHORT: '{{hours}}h',
            HD_MINUTES_SHORT: '{{minutes}}m',
            HD_SECONDS_SHORT: '{{seconds}}s',
            SUMMARY: 'Resumen',
            WORKFLOW: 'Flujo de trabajo',
            LINKS_TO: 'Vínculo a',
            APPROVALS_REQUIRED: 'Aprobaciones pendientes',
            WORKFLOW_ACTION_NOT_AVAILABLE: 'La acción de flujo de trabajo ya no está disponible o se ha completado',
            ENTER_DETAILS_MANUALLY: 'Introducir datos manualmente',
            PHONE_NUMBER: 'Número de teléfono',
            RELATIONSHIP_TO_THE_PROPERTY: 'Relación con el sitio',
            NOTIFY_ON_CREATION: 'Notificar sobre la creación',
            NOTIFY_ON_COMPLETION: 'Notificar al finalizar',
            NOTIFY_ON_ACCEPTANCE: 'Notificar sobre la aceptación',
            EDIT_ALARM: 'Editar alarma',
            SET_ALARM: 'Establecer alarma',
            ALARM_LOCKED: 'Bloqueado',
            UNLIMITED: 'Ilimitado',
            COLOUR: 'Color',
            NONE_SET: 'Ninguno establecido',
            COUNT: 'Recuento',
            DESCRIPTION: 'Descripción',
            ID: 'ID',
            NONE_SELECTED: 'Ninguna seleccionada',
            PLEASE_SELECT: 'Elija una opción',
            NOTES: 'Notas',
            VALUE: 'Valor',
            NAME: 'Nombre',
            REFERENCE: 'Referencia',
            EXTERNAL_REFERENCE: 'Referencia externa',
            STATUS: 'Estado',
            ADDRESS: 'Dirección',
            PHONE: 'Teléfono',
            EMAIL_ADDRESS: 'Correo electrónico',
            EMAIL_SIGNATURE: 'Firma de correo electrónico',
            INFO: 'Información',
            COMPANY: 'Empresa',
            ALL: 'Todo',
            POSITION: 'Posición',
            NEW_CUSTOMER_ACCESS: 'Nuevo acceso a cliente',
            FAILED_LOGIN_ATTEMPTS: 'Intentos de inicio de sesión fallidos',
            ACCOUNT_LOCKED: 'Cuenta bloqueada',
            UNLOCK_USER: 'Desbloquear usuario',
            ACCOUNT_UNLOCKED: 'Usuario desbloqueado',
            IS_TEMPLATE: 'Es una plantilla',
            EMAIL: 'Correo electrónico',
            ADDRESS_LINE_1: 'Dirección Línea 1',
            ADDRESS_LINE_2: 'Dirección Línea 2',
            ADDRESS_LINE_3: 'Dirección Línea 3',
            ADD_ADDRESS: 'Añadir dirección',
            GO: 'Ir',
            REPORTER: 'Reportero',
            NOTE: 'Nota',
            MAIN: 'Principal',
            EMAILS: 'Correos electrónicos',
            CURRENT_VERSION: 'Versión actual',
            NEW_VERSION: 'Versión nueva',
            DASHBOARD: 'Tablero de control',
            ADMIN: 'Admin',
            NO_NOTIFICATIONS: 'No tiene notificaciones',
            BUDDY_SWITCHED_ON: 'Compañero encendido',
            BUDDYING_FOR_ON: 'Compañero por encendido',
            ADD_SUCCESS: 'Añadido correctamente',
            UPDATE_SUCCESS: 'Actualizado correctamente',
            EDIT_SUCCESS: 'Editado correctamente',
            DELETE_SUCCESS: 'Eliminado correctamente',
            VIEW: 'Ver',
            ADD: 'Añadir',
            CREATE: 'Crear',
            EDIT: 'Editar',
            UPDATE: 'Actualizar',
            UPDATES: 'Actualizaciones',
            DELETE: 'Eliminar',
            CLOSE: 'Cerrar',
            RESET: 'Restablecer',
            CONFIRM: 'Confirmar',
            PREVIOUS: 'Anterior',
            DECLINED: 'Denegada',
            ACTIVATE: 'Activar',
            DEACTIVATE: 'Desactivar',
            PARENT: 'Primario',
            CHILDREN: 'Niños',
            IS_ACTIVE: 'Estado',
            ARE_YOU_SURE_HEADER: '¿Está seguro?',
            ARE_YOU_SURE: '¿Seguro que quiere realizar esta acción?',
            NAVIGATION_CONFIRMATION: '¿Seguro que desea abandonar esta página? Los cambios realizados se perderán.',
            REASON_FOR_STATUS_CHANGE: 'Escriba una razón para el cambio de estado',
            BARCODE: 'Código de barras',
            FILES: 'Archivos',
            FILES_WITH_COUNT: 'Archivos ({{ ::count || 0 | number:0 }})',
            ALL_SERVICE_PROVIDERS: 'Todos los proveedores de servicio',
            SELECTED_SERVICE_PROVIDERS: 'Proveedores de servicios seleccionados',
            ALL_PARTNERSHIPS: 'Todas las asociaciones',
            SELECTED_PARTNERSHIPS: 'Asociaciones seleccionadas',
            TITLE: 'Título',
            FOOTER_COPYRIGHT: 'Copyright © Vantify Limited {{ ::currentYear }} - Reg Company No. 05277497',
            ZERO_OPTIONS_CHECKED_TITLE: 'No hay elementos seleccionados',
            ZERO_OPTIONS_CHECKED_MESSAGE: 'Debe seleccionar al menos un elemento antes de realizar esta acción',
            STATUTORYTYPE: 'Tipo normativo',
            STATUTORY_TYPE: 'Tipo normativo',
            START_TYPING_TO_SEARCH: 'Empiece a escribir para buscar',
            START_TYPING_TO_FILTER: 'Empiece a escribir para filtrar',
            START_TYPING_TO_SEARCH_SITES: 'Todos los sitios - Comience a escribir para buscar',
            START_TYPING_TO_SEARCH_REGIONS: 'Todas las regiones',
            START_TYPING_TO_SEARCH_ASSOCIATE_TYPES: 'Todos los tipos asociados',
            START_TYPING_TO_SEARCH_USERS: 'Todos los usuarios',
            ACTION_SUCCESS: 'Acción correcta',
            LOCAL_SITE_TIME: 'Hora local de sitio',
            WITH_ID: '<strong>ID</strong> {{ ::id }}',
            WITH_SITE: '<strong>Sitio</strong> {{ ::site }}',
            WITH_NAME: '<strong>Nombre</strong> {{ ::name }}',
            WITH_SUMMARY: '<strong>Resumen</strong> {{ ::summary }}',
            WITH_REFERENCE: '<strong>Referencia</strong> {{ ::reference }}',
            WITH_CHILDREN: '<strong>Niños</strong> {{ ::children }}',
            WITH_ACTIVE: '<strong>Activo</strong> {{ ::(active == "true" || active == true ? "YES" : "NO") | translate }}',
            CONTACT: 'Contacto',
            CONTACTS: 'Contactos',
            BACK: 'Atrás',
            NO_RESULTS_FOUND: 'No hay resultados',
            PAGINATION_SHOWING: 'Mostrando {{::from}} a {{::to}} de {{::total}}',
            DETAILS: 'Detalles',
            SUBMIT: 'Enviar',
            VISIBILITY_PUBLIC: 'Público',
            VISIBILITY_HELPDESK: 'Atención al cliente',
            INFORMATION: 'Información',
            SEND_EMAIL: 'Enviar correo electrónico',
            NEW_COMPLETION_DUE: 'Nueva finalización debida',
            CREATED_AT: 'Creado en',
            APPROVED_AT: 'Aprobado en',
            ACTION_CANCEL: 'Cancelar',
            ACTION_COMPLETE: 'Completo',
            ACTION_RAISE_JOB: 'Presentar trabajo',
            ACTION_RAISE_QUOTE: 'Presentar cotización',
            ADD_BULK_PATROL_OCCURRENCES: 'Añadir ocurrencias de patrulla',
            ADD_CONTRACT_TYPE: 'Añadir contacto',
            ADD_DEPARTMENT: 'Añadir departamento',
            ADD_NEW_CONTRACT_TYPE: 'Añadir nuevo tipo de contrato',
            ADD_NEW_DEPARTMENT: 'Añadir nuevo departamento',
            ADD_NEW_TRADE_TYPE: 'Añadir nuevo tipo de comercio',
            ADD_TRADE_TYPE: 'Añadir tipo de comercio',
            ALL_USERS: 'Todos los usuarios',
            ASSIGNEE: 'Beneficiario',
            ATTACH_A_FILE: 'Adjuntar un archivo',
            ATTACHMENT: 'Adjunto',
            ATTACHMENTS: 'Adjuntos',
            ATTENDANCE_DATE: 'Fecha de asistencia',
            BY_PLANNED_DATE: 'Por fecha planificada',
            CANCELLED: 'Cancelada',
            COMPLETION_BY_DATE: 'Finalización en',
            COMPLETION_DUE_BETWEEN: 'Finalización debida entre',
            CREATED_BY: 'Creado por',
            COMPOSE_CONVERSATION: 'Redactar conversación',
            CONDITION: 'Condición',
            CONTRACT_APPROVAL: 'Aprobación del contrato',
            CONTRACT_TERMINATED: 'Contrato terminado',
            CONTRACT_TYPES: 'Tipos de contratos',
            CONVERSATIONS: 'Conversaciones',
            CREATE_AS_GLOBAL_TEMPLATE: 'Crear como plantilla global',
            CREATED: 'Creada',
            DATE_CREATED: 'Fecha de creación',
            DELETE_SURVEY: 'Eliminar encuesta',
            DEPARTMENTS: 'Departamentos',
            DONE: 'Hecho',
            EDIT_CONTRACT: 'Editar contrato',
            EDIT_CONTRACT_TYPE: 'Editar tipo de contrato',
            EDIT_DEPARTMENT: 'Editar departamento',
            EDIT_TRADE_TYPE: 'Editar tipo de comercio',
            EDITABLE: 'Editable',
            EXPORT: 'Exportar',
            EXPORT_IN_PROCESS: 'Exportación en cola',
            EXPORT_IN_PROCESS_WITH_LIMIT: 'Exportar en cola, pero los datos se limitarán a {{ limit }} elementos',
            EXPORT_KPI_TREND: 'Tendencia de KPI de exportación',
            GROUP: 'Grupo',
            HELPDESK_NOTES: 'Notas del servicio de asistencia',
            HIDE: 'Ocultar',
            JOB_HISTORY_NOTE: 'Historial de trabajo',
            JOB_MORE_MESSAGES: 'No hay más mensajes',
            JOB_NEXT_PLANNED_VISIT: 'Próxima visita planificada',
            JOB_STATUS_: 'Estado',
            LAST_MESSAGE: 'Último mensaje',
            LAST_SUBMITTED_BETWEEN: 'Último enviado entre',
            LATEST_REACTIVE_JOBS: 'Últimos trabajos reactivos',
            LEGEND_: 'Leyenda',
            LOCATION_BASED_SECTION: 'Sección basada en la ubicación',
            MONTHS: 'Meses',
            'N/A': 'N/A',
            NEW_JOB: 'Nuevo trabajo',
            NEXT: 'Siguiente',
            NO_ANSWER_OPTIONS: 'No hay opciones de respuesta',
            NO_NOTE_PROVIDED: 'No se proporciona ninguna nota',
            NOTE_REQUIRED: 'Nota requerida',
            NO_REACTIVE_JOBS: 'No hay trabajos reactivos',
            NO_SERVICE_PROVIDER_ENTERED: 'No se ha introducido ningún proveedor de servicios',
            NO_SITE_ASSOCIATES_ASSIGNED: 'No se introducen asociados de sitio',
            NO_DECIMAL_ALLOWED: 'Números decimales no permitidos',
            NON_CHARGEABLE: 'No cargable',
            NONE: 'Ninguna',
            ON_SITE_DATE: 'Fecha en el sitio',
            OOH_NOTES: 'Notas de OOOH',
            OOH_PHONE: 'Teléfono de OOH',
            OPEN: 'Abrir',
            ORDER: 'Pedido',
            OVERDUE_NO: 'No',
            OVERDUE_YES: 'Sí',
            OWNER: 'Propietario',
            PREV: 'Anterior',
            RAISED_BY: 'Presentado por',
            REQUEST: 'Solicitud',
            RISK: 'Riesgo',
            SATISFACTION_SURVEY: 'Encuesta de satisfacción',
            SAVE: 'Guardar',
            SCORE_RANGE: 'Rango de puntuación',
            SEARCH: 'Buscar',
            SEARCH_SITE: 'Buscar en el sitio',
            SELECT: 'Ver más',
            SELECT_A_USER: 'Seleccionar un usuario',
            SELECT_CONDITION: 'Seleccione condición',
            SELECT_DEPARTMENT: 'Seleccionar departamento',
            SELECT_RISK: 'Seleccionar riesgo',
            SELECTED_USERS: 'Usuarios seleccionados',
            SEND: 'Enviar',
            SERVICE: 'Servicio',
            SERVICE_PROVIDED: 'Servicio proporcionado',
            SHOW: 'Mostrar',
            SKIP_THIS_TIME: 'Omitir esta vez',
            STATUS_DETAILS: 'Detalles de estado',
            SUBMITTED: 'Enviado',
            SURVEY_RANGE: 'Rango',
            SURVEY_WEIGHT: 'Peso',
            TARGET: 'Objetivo',
            TELEPHONE: 'Teléfono',
            TRADE_TYPES: 'Tipos de comercio',
            UNABLE_TO_CREATE_TENANT: 'No se puede crear el arrendatario',
            UNABLE_TO_GET_LOCATION: 'No se puede obtener la ubicación',
            UNABLE_TO_GET_TENANT: 'No se puede conseguir el arrendatario',
            UNABLE_TO_UPDATE_TENANT: 'No se puede actualizar el arrendatario',
            UNSUBSCRIBE: 'Dar de baja',
            VIEW_NOTIFICATIONS_UNREAD_NEWER: 'Más nuevo',
            VIEW_NOTIFICATIONS_UNREAD_OLDER: 'Más viejo',
            WEBSITE: 'Página web',
            WEIGHT: 'Peso',
            WEIGHTED_SCORE: 'Puntuación ponderada',
            WRITE_A_MESSAGE: 'Escribir un mensaje',
            FILE_ATTACHMENT: 'Archivo adjunto',
            COPY_ME: 'Enviarme una copia a mi',
            SUBJECT: 'Asunto',
            TO: 'A',
            EMAIL_S_SENT: 'Correo electrónico(s) enviado(s)',
            SHOW_MORE: 'Mostrar más',
            SHOW_LESS: 'Mostrar menos',
            EDIT_RECIPIENTS: 'Editar destinatarios',
            RECIPIENTS: 'Destinatarios',
            VIEW_ALL: 'Ver todo',
            WILL_CHASE_AT: 'Buscará en',
            EXTENSION_DATE: 'Fecha de la ampliación',
            I_AM_THE_APROVER: 'Aprobador',
            I_AM_APPROVER: 'Soy un aprobador',
            MESSAGE_CREATED: 'Mensaje creado',
            ACCEPT: 'Aceptar',
            DUE_DATE: 'Fecha de vencimiento',
            FEET: 'Pie',
            METRES: 'Metros',
            IMAGE_FILE: 'Imagen',
            NOTIFICATION_NOT_FOUND: 'Notificación no encontrada',
            NOTIFICATION_TYPE_NOT_FOUND: 'Tipo de notificación no encontrado',
            OTHER: 'Otro',
            SKIPPED_NOTIFICATION: 'Notificación omitida',
            REMINDER_AT: 'Recordatorio en',
            REQUESTED: 'Solicitada',
            INCLUDE_MY_SIGNATURE: 'Incluir mi firma',
            LOAD_MORE: 'Cargar más',
            AT_LEAST_ONE_ITEM_REQUIRED: 'Se requiere al menos un elemento.',
            NO_MORE_RESULTS: 'No hay más resultados para cargar.',
            MAX_NUMBER_SELECTED: 'Número máximo de elementos seleccionados.',
            EXPIRED: 'Caducado',
            VALID: 'Válida',
            REVOKE: 'Revocar',
            REVOKED: 'Revocada',
            INDEFINITE: 'Indefinida',
            FOR_REVIEW: 'Para revisión',
            PAPERWORK: 'Papeleo',
            CREATED_AT_BETWEEN: 'Creado entre',
            DATE_COMPLETE_AT: 'Completada en',
            COPIED_TO_CLIPBOARD: 'Copiado en el portapapeles',
            BROWSER_UNSUPPORTED: 'Navegador no compatible',
            IMAGES_UPDATED: 'Las imágenes se han actualizado',
            IMAGE_DELETE: 'La imagen se ha eliminado',
            IMAGE_UPLOAD: 'La imagen se ha cargado',
            IMAGE_UPDATE: 'La imagen se ha actualizado',
            NO_IMAGE_SELECTED: 'No se ha seleccionado ninguna imagen',
            SET_MAIN_IMAGE: 'Establecer como imagen principal',
            INCORRECT_FILE_TYPE: 'Tipo de archivo incorrecto',
            INVALID_PERMISSIONS: 'Permisos no válidos',
            NO_RESOURCE_FOUND: 'Ningún recurso encontrado',
            FILTERED: 'Filtrado',
            SELECTED: 'Seleccionado',
            APPROVER_IS_NOT_SET: 'Se requiere aprobación, pero no se ha establecido ningún aprobador',
            SELECT_AT_LEAST_ONE_SITE: 'Se requiere al menos un sitio',
            ITEM_FIELD_REQUIRED: 'Elemento campo requerido',
            SELECTALLSITES: 'Seleccionar todos los sitios',
            TOTALS: 'Totales',
            PLANNED_JOBS: 'Trabajos planificados',
            REACTIVE_JOBS: 'Trabajos reactivos',
            REPORT_ON: 'Comunicado en',
            TABLE_ACTIONS: 'Acciones de tabla',
            EXPAND_ALL: 'Expandir todo',
            COLLAPSE_ALL: 'Contraer todo',
            EXPIRED_AT: 'Caducado en',
            REJECTED_AT: 'Rechazado en',
            NO_RESULTS: 'Sin resultados',
            DECLINED_AT: 'Denegado en',
            ACCEPTED_AT: 'Aceptado en',
            FIELD_WITH_MAXIMUM_LENGTH: '{{ fieldName }} la longitud máxima del campo es {{ limit }} caracteres',
            START_DATE: 'Fecha de inicio',
            END_DATE: 'Fecha final',
            UPLOAD_SITE_IMAGE: 'Subir una imagen del sitio',
            ACCESS_DENIED: 'Acceso denegado',
            CIRCULAR_REFERENCE: 'Referencia circular',
            RELATION_LINK_PLANNED_TO_RESTRICTION: 'El trabajo planificado no puede vincularse a otros trabajos',
            RELATION_LINK_PLANNED_FROM_QUOTE_RESTRICTION: 'El trabajo planificado no puede vincularse desde la cotización',
            DUPLICATED_RELATION_LINK: 'El vínculo de trabajo ya existe',
            NEW_TAG: ' (Nuevo)',
            DELETED: 'Eliminado',
            NOT_FOUND: 'No se encuentra',
            EXPIRES_AT: 'Fecha de caducidad',
            SERVICETYPE: 'Tipo de servicio',
            LOCATION_REQUIRED: 'Ubicación requerida',
            PHOTO_IS_REQUIRED: 'Foto requerida',
            RATIO: 'Intervalo',
            TRAINING_ACADEMY: 'Academia de formación',
            ORDER_MUST_BE_A_NUMBER: 'El pedido debe ser un número',
            DUPLICATED_ORDER: 'Pedido duplicado',
            INCORRECT_FORMAT: 'Formato incorrecto',
            NO_ASSOCIATE_TYPE_FOR_SITE: 'No hay tal tipo de asociado para este sitio',
            MAX_CHARACTER_COUNT: 'Máx. 40 caracteres',
            PIXELS: 'Píxeles',
            N_A: 'N/A',
            EITHER: 'Ya sea',
            MARKUP: 'Cargo de gestión',
            MARKED_UP_VALUE: 'Coste total',
            SEND_BETWEEN_DATES: 'Envío entre fechas',
            EMAIL_DATE: 'Fecha',
            EMAIL_SUBJECT: 'Asunto',
            EMAIL_SENDER: 'Remitente',
            EMAIL_ATTACHMENT: 'Adjunto',
            EMAIL_SENT_START: 'Inicio enviado',
            EMAIL_SENT_END: 'Final enviado',
            EMAIL_RECIPIENT_NAME: 'Nombre del destinatario',
            EMAIL_DATE_TIME: 'Fecha y hora enviada',
            REPLY_TO: 'Dirección para respuestas',
            INVALID_REPLY_TO_ADDRESS: 'Dirección para respuestas no válida',
            BACK_TO_LIST: 'Volver a la lista',
            COMMENT_INVALID_LENGTH: 'El comentario no puede tener más de 255 caracteres',
            MANDATORY: 'Obligatorio',
            PUBLIC: 'Público',
            PRIVATE: 'Privado',
            COMMENT: 'Comentario',
            ATTENDANCE: 'Asistencia',
            COMPLETION: 'Finalización',
            JOBS_DUE_BY_DAY: 'Día(s)',
            JOBS_DUE_BY_WEEK: 'Semana(s)',
            JOBS_DUE_BY_MONTH: 'Mes(es)',
            JOBS_DUE_BY_SELECT: 'Seleccionar tipo',
            JOBS_DUE_BY_FREQUENCY_SELECT: 'Seleccione la frecuencia',
            DOWNLOAD_PDF: 'Descargar como PDF',
            STAT: 'Tipo de estadística',
            FREQUENCY_YEARLY: '{{ ::interval }} Anualmente',
            FREQUENCY_MONTHLY: '{{ ::interval }} Mensualmente',
            FREQUENCY_WEEKLY: '{{ ::interval }} Semanalmente',
            FREQUENCY_WEEKS: '{{ ::interval }} {{ interval == "1" ? "Week" : "Weeks" }}',
            FREQUENCY_DAYS: '{{ ::interval }} {{ interval == "1" ? "Day" : "Days" }}',
            FREQUENCY_HOURS: '{{ ::interval }} {{ interval == "1" ? "Hour" : "Hours" }}',
            FREQUENCY_MINUTES: '{{ ::interval }} {{ interval == "1" ? "Minute" : "Minutes" }}',
            FREQUENCY_SECONDS: '{{ ::interval }} {{ interval == "1" ? "Second" : "Seconds" }}',
            FORMS: 'Formularios',
            FORM_LIST: 'Formularios',
            FORMS_WITH_COUNT: 'Formularios ({{ ::count || 0 | number:0 }})',
            FORM_REMOVE_COMPONENT: 'Quitar',
            FORM_COPY_COMPONENT: 'Copiar',
            FORM_ADD: 'Añadir un formulario',
            FORM_EDIT: 'Editar un formulario',
            FORM_TYPE: 'Tipo',
            FORM_VERSION: 'Versión',
            FORM_TYPE_JOB: 'Formulario de trabajo',
            FORM_TYPE_ASSET: 'Formulario de activo',
            FORM_TYPE_TASK: 'Formulario de tarea',
            FORM_CREATED: 'Formulario creado',
            FORM_UPDATED: 'Formulario actualizado',
            FORM_DELETED: 'Formulario eliminado',
            FORM_ASSOCIATION_CREATED: 'Formulario añadido',
            FORM_ASSOCIATION_DELETED: 'Formulario eliminado',
            FORM_INSTANCE_CREATED: 'Formulario enviado',
            FORM_INSTANCE_UPDATED: 'Formulario guardado',
            FORM_INSTANCE_DELETED: 'Formulario eliminado',
            FORM_STATUS_OPEN: 'Abrir',
            FORM_STATUS_IN_PROGRESS: 'En curso',
            FORM_STATUS_COMPLETED: 'Completado',
            FORM_VERSION_UPDATED: 'Formulario actualizado',
            FORM_VERSION_NUMBER: 'Versión {{ ::version }}',
            FORM_VERSION_CHANGED_SIGNIFICANTLY: 'Este formulario ha cambiado significativamente y se puede crear una nueva versión al guardar',
            FORM_VERSION_PUBLISH: 'Publicar formulario',
            FORM_VERSION_STATUS: 'Estado de la versión del formulario',
            FORM_VERSION_STATUS_ARCHIVED: 'Archivado',
            FORM_VERSION_STATUS_DRAFT: 'Borrador',
            FORM_VERSION_STATUS_PUBLISHED: 'Publicado',
            FORM_SETTINGS: 'Ajustes',
            FORM_BUILDER: 'Generador',
            FORM_RENDER_WEB: 'Vista Web',
            FORM_RENDER_APP: 'Vista de aplicación',
            FORM_SETTING_FONT_SIZE: 'Tamaño de fuente',
            FORM_SETTING_FONT_COLOUR: 'Color de texto',
            FORM_SETTING_LINE_THICKNESS: 'Espesor de línea',
            FORM_SETTING_COLOUR: 'Color',
            FORM_SETTING_WIDTH: 'Anchura',
            FORM_SETTING_QUESTION_ALIGNMENT: 'Alineación del texto de pregunta',
            FORM_SETTING_QUESTION_ALIGNMENT_AUTO: 'Automático',
            FORM_SETTING_QUESTION_ALIGNMENT_LEFT: 'A la izquierda de la respuesta',
            FORM_SETTING_QUESTION_ALIGNMENT_ABOVE: 'Por encima de la respuesta',
            FORM_SETTING_QUESTION_CHOICE_LABEL_ALIGNMENT: 'Alineación de la etiqueta de elección',
            FORM_SETTING_QUESTION_CHOICE_LABEL_ALIGNMENT_LEFT: 'A la izquierda de la casilla de verificación',
            FORM_SETTING_QUESTION_CHOICE_LABEL_ALIGNMENT_RIGHT: 'A la derecha de la casilla de verificación',
            FORM_CONTROL_HEADER: 'Encabezado',
            FORM_CONTROL_FOOTER: 'Pie de página',
            FORM_CONTROL_QUESTION: 'Pregunta',
            FORM_CONTROL_HORIZONTALRULE: 'Divisor',
            FORM_CONTROL_PAGEBREAK: 'Salto de página',
            FORM_CONTROL_TEXT: 'Texto',
            FORM_CONTROL_MACRO: 'Macro',
            FORM_CONTROL_UNDO: 'Deshacer',
            FORM_CONTROL_REDO: 'Rehacer',
            FORM_CONTROL_HEADER_DETAIL: 'Texto de encabezado',
            FORM_CONTROL_FOOTER_DETAIL: 'Texto de pie de página',
            FORM_CONTROL_QUESTION_DETAIL: 'Pregunta',
            FORM_CONTROL_MACRO_DETAIL: 'Elemento macro',
            FORM_CONTROL_QUESTION_TYPE_DETAIL: 'Tipo de pregunta',
            FORM_CONTROL_QUESTION_TYPE_TEXT: 'Texto (línea única)',
            FORM_CONTROL_QUESTION_TYPE_TEXTAREA: 'Texto (líneas múltiples)',
            FORM_CONTROL_QUESTION_TYPE_CHOICE_SELECT: 'Selección desplegable',
            FORM_CONTROL_QUESTION_TYPE_CHOICE_RADIO: 'Selección de opción',
            FORM_CONTROL_QUESTION_TYPE_CHOICE_CHECKBOX: 'Seleccionar casilla de verificación',
            FORM_CONTROL_QUESTION_TYPE_SLIDING_SCALE: 'Escala deslizable',
            FORM_CONTROL_QUESTION_TYPE_DATEPICKER: 'Selector de fechas',
            FORM_CONTROL_QUESTION_TYPE_FILE: 'Carga de fichero',
            FORM_CONTROL_OPTION_CONDITION: 'Condición',
            FORM_CONTROL_OPTION_CONDITION_ELEMENT: 'Pregunta',
            FORM_CONTROL_OPTION_CONDITION_ELEMENT_SELECT: 'Seleccione un elemento',
            FORM_CONTROL_OPTION_CONDITION_ELEMENT_SELECTING: 'Seleccionar un elemento',
            FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE: 'Coincidir',
            FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_DOES_NOT_CONTAIN: 'No contiene',
            FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_CONTAINS: 'Contiene',
            FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_BEGINS: 'Comienza con',
            FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_ENDS: 'Acaba con',
            FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_EQUALS: 'Igual',
            FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_NOT_EQUALS: 'No es igual',
            FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_GREATER_THAN: 'Mayor que',
            FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_LESSER_THAN: 'Menor que',
            FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_BETWEEN: 'entre',
            FORM_CONTROL_OPTION_CONDITION_VALUE: 'Valor',
            FORM_CONTROL_OPTION_ALLOW_NA: 'Permitir no aplicable',
            FORM_CONTROL_OPTION_REQUIRED: 'Requerido',
            FORM_CONTROL_OPTION_PLACEHOLDER: 'Texto del marcador de posición',
            FORM_CONTROL_OPTION_TEXT_ALIGN: 'Alineación del texto',
            FORM_CONTROL_OPTION_ALLOW_TIME: 'Permitir tiempo',
            FORM_CONTROL_OPTION_MIN_DATE_OFFSET: 'Mín. Días desplazados',
            FORM_CONTROL_OPTION_MAX_DATE_OFFSET: 'Máx. Días desplazados',
            FORM_CONTROL_OPTION_UNRESTRICTED: 'No restringido',
            FORM_CONTROL_TEXTAREA_ROWS: 'Número de filas',
            FORM_CONTROL_CHOICE_OPTIONS: 'Opciones',
            FORM_CONTROL_CHOICE_OPTIONS_TABLE_COLUMNS: 'Columnas',
            FORM_CONTROL_CHOICE_OPTIONS_SORT_ALPHABETICALLY: 'Ordenar alfabéticamente',
            FORM_CONTROL_CHOICE_PLEASE_SELECT: 'Elija una opción',
            FORM_CONTROL_VALIDATION_ALLOW_MULTIPLE: 'Permitir múltiple',
            FORM_CONTROL_VALIDATION_MIN_LENGTH: 'Longitud mínima',
            FORM_CONTROL_VALIDATION_MIN_LENGTH_ZERO_DETAIL: 'Cero elimina el requisito',
            FORM_CONTROL_VALIDATION_MAX_LENGTH: 'Longitud máxima',
            FORM_CONTROL_VALIDATION_MAX_LENGTH_ZERO_DETAIL: 'Cero elimina el requisito',
            FORM_CONTROL_VALIDATION_MIN_VALUE: 'Valor mínimo',
            FORM_CONTROL_VALIDATION_MAX_VALUE: 'Valor máximo',
            FORM_CONTROL_VALIDATION_INTERVAL: 'Intervalo',
            FORM_MACRO_JOB_SUMMARY: 'Resumen de trabajo',
            FORM_MACRO_JOB_DESCRIPTION: 'Descripción del trabajo',
            FORM_MACRO_JOB_SITE_NAME: 'Nombre del sitio',
            FORM_MACRO_JOB_SERVICE_PROVIDER_NAME: 'Nombre del proveedor de servicios',
            FORM_MACRO_JOB_OPERATIVE_NAME: 'Nombre operativa',
            FORM_MACRO_JOB_ATTENDANCE_DUE_AT: 'Fecha límite de asistencia',
            FORM_MACRO_ASSET_NAME: 'Nombre del activo',
            FORM_MACRO_ASSET_REFERENCE: 'Referencia',
            FORM_MACRO_ASSET_MAKE: 'Marca',
            FORM_MACRO_ASSET_MODEL: 'Modelo',
            FORM_MACRO_ASSET_BARCODE: 'Código de barras',
            FORM_MACRO_ASSET_SERIAL_NUMBER: 'Número de serie',
            FORM_MACRO_ASSET_LOCATION_TITLE: 'Ubicación de activo',
            FORM_MACRO_TASK_NAME: 'Nombre de tarea',
            FORM_MACRO_TASK_LOCATION_TITLE: 'Ubicación de tarea',
            FORM_MACRO_TASK_ASSET_NAME: 'Nombre del activo',
            FORM_MACRO_TASK_ASSET_REFERENCE: 'Referencia de activo',
            FORM_MACRO_TASK_ASSET_MAKE: 'Marca de activo',
            FORM_MACRO_TASK_ASSET_MODEL: 'Modelo de activo',
            FORM_MACRO_TASK_ASSET_BARCODE: 'Código de barras de activo',
            FORM_MACRO_TASK_ASSET_SERIAL_NUMBER: 'Número de serie de activo',
            FORM_MACRO_TASK_ASSET_LOCATION_TITLE: 'Ubicación de activo',
            FORM_MACRO_VALUE_NOT_FOUND: 'Valor no encontrado',
            FORM_NO_ANSWER: 'No contesta',
            VIOLATION_FORM_ANSWER_CANNOT_BE_NOT_APPLICABLE: '{{ path }} -- No se permite que la respuesta se marque como no aplicable',
            VIOLATION_FORM_ANSWER_REQUIRED: '{{ path }} -- Se requiere respuesta',
            VIOLATION_FORM_ANSWER_MIN_LENGTH: '{{ path }} -- Longitud mínima no alcanzada',
            VIOLATION_FORM_ANSWER_MAX_LENGTH: '{{ path }} -- Longitud máxima excedida',
            VIOLATION_FORM_ANSWER_MIN_VALUE: '{{ path }} -- Valor mínimo no alcanzado',
            VIOLATION_FORM_ANSWER_MAX_VALUE: '{{ path }} -- Valor máximo excedido',
            VIOLATION_FORM_ANSWER_INTERVAL: '{{ path }} -- Intervalo no válido utilizado',
            VIOLATION_FORM_ANSWER_INVALID_CHOICE: '{{ path }} -- Opción no válida utilizada',
            VIOLATION_FORM_ANSWER_MULTIPLE: '{{ path }} -- Serie de respuestas esperadas',
            VIOLATION_FORM_ANSWER_MULTIPLE_NOT_ALLOWED: '{{ path }} -- Varias respuestas no permitidas',
            VIOLATION_FORM_ANSWER_MIN_DATE: '{{ path }} -- Fecha mínima excedida',
            VIOLATION_FORM_ANSWER_MAX_DATE: '{{ path }} -- Fecha máxima excedida',
            VIOLATION_FORM_WRONG_FONT_SIZE: '{{ path }} -- Tamaño de fuente no válido',
            VIOLATION_FORM_WRONG_LINE_THICKNESS: '{{ path }} -- Espesor de línea no válido',
            UNABLE_TO_DELETE_COMPLETED_FORM: 'No se puede eliminar un formulario completado',
            INVALID_FORM_VERSION_STATUS: 'Estado de la versión del formulario no válido',
            VIOLATION_FORM_CONDITION_UPPER_LIMIT_LESS_THAN_LOWER_LIMIT: 'El límite superior no puede ser menor o igual que el límite inferior',
            FORM_ASSOCIATIONS: 'Formulario',
            FORM_ASSOCIATIONS_LIST: 'Formularios',
            FORM_ASSOCIATIONS_ADD: 'Añadir formulario',
            FORM_ASSOCIATIONS_CREATED: 'Formulario añadido',
            FORM_ASSOCIATIONS_DELETED: 'Formulario eliminado',
            FLOOR: 'Suelo',
            FLOORS: 'Suelos',
            ADD_NEW_FLOOR: 'Añadir un nuevo suelo',
            EDIT_FLOOR: 'Editar suelo',
            FLOOR_CREATED: 'Suelo creado correctamente',
            FLOOR_UPDATED: 'Suelo actualizado correctamente',
            FLOOR_DELETED: 'Suelo eliminado',
            NO_FLOOR_RECORDS: 'No hay registros de suelo',
            FILE: 'Archivo',
            FILES_JOB_FILES: 'Archivos de trabajo',
            FILES_SITE_FILES: 'Archivos de sitio',
            FILE_ADD: 'Añadir un nuevo archivo',
            FILE_EDIT: 'Editar archivo',
            FILE_LIST: 'Archivos',
            FILE_CREATED: 'Archivo creado',
            FILE_UPDATED: 'Archivo actualizado',
            FILE_DELETED: 'Archivo eliminado',
            FILE_TITLE: 'Título de archivo:',
            CAPTION: 'Leyenda',
            UPLOAD_AND_EDIT: 'Cargar y editar',
            FILE_NAME: 'Nombre de archivo',
            UPLOAD: 'Cargar',
            DELETE_IMAGE: 'Borrar imagen',
            UPLOADED_BY: 'Cargado por',
            UPLOADED_DATE_START: 'Fecha de inicio cargada',
            UPLOADED_DATE_END: 'Fecha de fin cargada',
            EXPIRES_DATE_START: 'Fecha de inicio de vencimiento',
            EXPIRES_DATE_END: 'Fecha de fin de vencimiento',
            FILES_NOTIFY_EXPIRY: 'Notificar {{ ::expiryTime }} día(s) antes del vencimiento',
            FILES_NOTIFY_EXPIRY_UPDATED: 'Recordatorios de vencimiento actualizados correctamente',
            FILES_NOTIFY_EXPIRY_SELECTED: 'Actualizar recordatorios de vencimiento',
            FILES_NOTIFY_EXPIRY_FILTERED: 'Enviar recordatorio de vencimiento en filtrado',
            FILES_NOTIFY_EXPIRY_TOGGLE_TOOLTIP: 'Establecer recordatorios de vencimiento',
            FILES_SELECTED: 'Archivos seleccionados',
            FILE_DOWNLOAD: 'Descargar',
            FILE_PROCESSING: 'Procesamiento de archivos',
            INVALID_FILE: 'Archivo no válido',
            IS_STATUTORY_FILE: 'Archivo normativo',
            ISSTATUTORY: 'Archivo normativo',
            FILES_REQUIRED: 'Se requiere al menos un archivo',
            FILE_REQUIRED: 'Se requiere un archivo',
            MAXIMUM_SIX_UPLOADS: 'Máxima cantidad de seis cargas',
            MAXIMUM_SIX_UPLOADS_REACHED: 'Máxima cantidad de seis cargas alcanzada',
            FILES_10_MAX: 'Máximo 10 archivos',
            FILES_ADD: 'Añadir archivos',
            INTEGRATION_UPLOAD_STATUS: 'Estado de carga',
            UPLOAD_PENDING: 'Carga pendiente',
            UPLOADED: 'Cargado',
            UPLOAD_ERROR: 'Error de carga',
            INTEGRATIONS: 'Integraciones',
            ERROR_IMAGE_IS_REQUIRED: 'Se requiere una imagen',
            FILE_TYPE_IMAGE_REQUIRED: 'El archivo no es una imagen',
            INVALID_UPLOADER: 'Cargador no es válido',
            IMAGE: 'Imagen',
            FILES_APPLICABLE_TO: 'Aplicable a',
            EXTEND_SESSION_HEADER: 'Ampliar sesión',
            EXTEND_SESSION_BODY: 'Se cerrará su sesión automáticamente en <strong>{{ ::timeRemaining }}</strong>. Para ampliar su sesión 24 horas, haga clic en «Ampliar» a continuación.',
            EXTEND_SESSION: 'Ampliar',
            LOGOUT: 'Cerrar sesión',
            EVENT_FEED: 'Pista de auditoría',
            EVENT: 'Evento',
            EVENTS: 'Eventos',
            UPDATE_NOTIFY: 'Actualizar y notificar',
            NOTIFICATIONS_SENT_TO: 'Notificaciones enviadas a',
            NOTIFY: 'Notificar',
            WRITE_YOUR_UPDATE: 'Escribir su actualización',
            ESCALATION: 'Escalada',
            ESCALATIONS: 'Escaladas',
            ESCALATION_LIST: 'Escaladas',
            ESCALATION_ADD: 'Añadir una nueva escalada',
            ESCALATION_EDIT: 'Editar escalada',
            ESCALATION_CREATED: 'Escalada creada',
            ESCALATION_UPDATED: 'Escalada actualizada',
            ESCALATION_DELETED: 'Escalada eliminada',
            ESCALATE: 'Escalar',
            ESCALATED: 'Escalado',
            ESCALATED_AT: 'Escalado en',
            ESCALATION_DETAILS: 'Detalles {{ id }} de escalada',
            ESCALATION_STATUS: 'Estado',
            DEESCALATED: 'Eliminado de escalada',
            EXPORT_JOBS: 'Exportar trabajos',
            EXPORT_QUOTES: 'Exportar cotizaciones',
            ESCALATION_SUCCESS: 'Escalada realizada correctamente',
            ESCALATE_OPERATION_SUCCESS: 'Escalado',
            ESCALATION_PROGRESS_SUCCESS: 'Escalada progresada',
            ESCALATION_POSTPONE_SUCCESS: 'Escalada pospuesta',
            ESCALATION_CANCEL_SUCCESS: 'Escalada cancelada',
            ESCALATION_WORKFLOW_PROGRESS: 'Progreso',
            ESCALATION_WORKFLOW_PROGRESS_SUBMIT: 'Progreso',
            ESCALATION_WORKFLOW_CANCEL: 'Cancelar',
            ESCALATION_WORKFLOW_CANCEL_SUBMIT: 'Cancelar escalada',
            ESCALATION_WORKFLOW_POSTPONE: 'Posponer',
            ESCALATION_WORKFLOW_POSTPONE_SUBMIT: 'Posponer',
            NO_ESCALATION_OPERATIVE: 'Sin operativa de escalada principal',
            ESCALATION_STAGE: 'Etapa de escalada',
            ESCALATION_STAGES: 'Etapas de escalada',
            ESCALATION_STAGE_LIST: 'Etapas de escalada',
            ESCALATION_STAGE_ADD: 'Añadir una nueva etapa de escalada',
            ESCALATION_STAGE_EDIT: 'Editar etapa de escalada',
            ESCALATION_STAGE_CREATED: 'Etapa de escalada creada',
            ESCALATION_STAGE_UPDATED: 'Etapa de escalada actualizada',
            ESCALATION_STAGE_DELETED: 'Etapa de escalada eliminada',
            STAGE: 'Etapa',
            EMAIL_CONTENT: 'Contenido de correo electrónico',
            PRIMARY_CONTACT: 'Contacto principal',
            ASSOCIATE_TYPES: 'Tipos de asociados',
            ASSOCIATE_TYPES_SELECT: 'Seleccionar tipos de asociados',
            OTHER_CONTACTS: 'Otros contactos',
            OTHER_CONTACTS_SELECT: 'Seleccionar otros contactos',
            REVIEW_AT: 'Revisar en',
            ESCALATION_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }} </strong> presentó la escalada.',
            ESCALATION_JOB_ADDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> escaló un trabajo.',
            ESCALATION_JOB_ADDED_SYSTEM_ACTION: 'El sistema escaló automáticamente un trabajo.',
            ESCALATION_JOB_READDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> reescaló un trabajo.',
            ESCALATION_JOB_READDED_SYSTEM_ACTION: 'El sistema reescaló automáticamente un trabajo.',
            ESCALATION_JOB_REMOVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> eliminó un trabajo.',
            ESCALATION_JOB_REMOVED_SYSTEM_ACTION: 'El sistema eliminó automáticamente un trabajo.',
            ESCALATION_QUOTE_ADDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> escaló una cotización.',
            ESCALATION_QUOTE_ADDED_SYSTEM_ACTION: 'El sistema escaló automáticamente una cotización.',
            ESCALATION_QUOTE_READDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> reescaló una cotización.',
            ESCALATION_QUOTE_READDED_SYSTEM_ACTION: 'El sistema reescaló automáticamente una cotización.',
            ESCALATION_QUOTE_REMOVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> eliminó una cotización.',
            ESCALATION_QUOTE_REMOVED_SYSTEM_ACTION: 'El sistema eliminó automáticamente una cotización.',
            ESCALATION_POSTPONED_ACTION: '<strong>{{ ::event._links["user"].title }} </strong> pospuso la escalada.',
            ESCALATION_PROGRESSED_ACTION: '<strong>{{ ::event._links["user"].title }} </strong> progresó la escalada.',
            ESCALATION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }} </strong> canceló la escalada.',
            ESCALATION_MESSAGE_ACTION: '<strong>{{ ::message._links["sender"].title }}</strong> proporcionó una actualización.',
            DUTYMANAGEMENT: 'Gestión de los servicios',
            DUTY_MANAGEMENT: 'Gestión de los servicios',
            DUTY_MANAGEMENT_SITE_RELATION_REMOVED: 'La relación de sitio ha sido eliminada',
            DUTY_MANAGEMENT_SCHEDULE_REMOVED: 'El horario ha sido eliminado',
            DUTY_MANAGEMENT_BACK: 'Atrás',
            DUTY_MANAGEMENT_EDIT_USER: 'Editar usuario',
            DUTY_MANAGEMENT_SELECT_USER: 'Seleccionar usuario',
            DUTY_MANAGEMENT_SELECT_SITES: 'Seleccionar sitios',
            DUTY_MANAGEMENT_SELECT_DAYS: 'Seleccionar días',
            DUTY_MANAGEMENT_SELECTED_DAYS: 'Seleccionar días',
            DUTY_MANAGEMENT_SELECTED_SITES: 'Sitios seleccionados',
            DUTY_MANAGEMENT_ADD: 'Crear horario de gestión de servicios',
            DUTY_MANAGEMENT_ALREADY_IN_USE_SITES: 'Los sitios ya están en uso',
            DOWNLOAD_AS_CSV: 'Descargar como CSV',
            DOWNLOAD_AS_JSON: 'Descargar como JSON',
            DIFF_LOAD: 'Cargar/Dif.',
            SOURCE: 'Fuente',
            TRANSLATION: 'Traducción',
            DOC: 'Doc',
            DIRECTIVES: 'Directivas',
            FILTERS: 'Filtros',
            EXAMPLES: 'Ejemplos',
            PANELS: 'Paneles',
            PANEL_TITLE: 'Título',
            TABS: 'Tabuladores',
            FILE_UPLOAD: 'Carga de fichero',
            LISTS: 'Listas',
            DATE_FORMAT: 'Formato de fecha',
            BUTTONS: 'Botones',
            OPTIONS: 'Opciones',
            KEY: 'Clave',
            MONTH: 'Mes',
            YEAR: 'Año',
            DAILY: 'Diario',
            WEEKLY: 'Semanalmente',
            MONTHLY: 'Mensualmente',
            YEARLY: 'Anualmente',
            FIRST: 'Primero',
            SECOND: 'Segundo',
            THIRD: 'Tercero',
            FOURTH: 'Cuarto',
            LAST: 'Último',
            DAY: 'Día',
            DATE_STARTEDAT: 'Empieza',
            DATE_EXPIREDAT: 'Caduca',
            DATE_EXPIRESAT: 'Caduca',
            DATE_REVIEWEDAT: 'Revisar en',
            DATE_TENDEREDAT: 'Licitado en',
            DATE_CREATEDAT: 'Creado en',
            DATE_UPLOADDATE: 'Fecha de carga',
            DATE_COMPLETIONAT: 'Terminación vencida en',
            DATE_LAST_READ: 'Leer en',
            DATE_ACTIVEAT: 'Activo en',
            DATE_INACTIVEAT: 'Inactivo en',
            TASK_PLANNER_DAILY: '{{interval}}D',
            TASK_PLANNER_WEEKLY: '{{interval}}S',
            TASK_PLANNER_MONTHLY: '{{interval}}M',
            TASK_PLANNER_ANNUALLY: '{{interval}}A',
            RELATIVE_STARTDATE: 'Fecha de inicio relativo',
            MONDAY: 'Lunes',
            TUESDAY: 'Martes',
            WEDNESDAY: 'Miércoles',
            THURSDAY: 'Jueves',
            FRIDAY: 'Viernes',
            SATURDAY: 'Sábado',
            SUNDAY: 'Domingo',
            BYDAY_MONDAY: 'Lunes',
            BYDAY_TUESDAY: 'Martes',
            BYDAY_WEDNESDAY: 'Miércoles',
            BYDAY_THURSDAY: 'Jueves',
            BYDAY_FRIDAY: 'Viernes',
            BYDAY_SATURDAY: 'Sábado',
            BYDAY_SUNDAY: 'Domingo',
            BYDAY_MONDAY_FIRST: 'Primer lunes del mes',
            BYDAY_TUESDAY_FIRST: 'Primer martes del mes',
            BYDAY_WEDNESDAY_FIRST: 'Primer miércoles del mes',
            BYDAY_THURSDAY_FIRST: 'Primer jueves del mes',
            BYDAY_FRIDAY_FIRST: 'Primer viernes del mes',
            BYDAY_SATURDAY_FIRST: 'Primer sábado del mes',
            BYDAY_SUNDAY_FIRST: 'Primer domingo del mes',
            BYDAY_MONDAY_LAST: 'Último lunes del mes',
            BYDAY_TUESDAY_LAST: 'Último martes del mes',
            BYDAY_WEDNESDAY_LAST: 'Último miércoles del mes',
            BYDAY_THURSDAY_LAST: 'Último jueves del mes',
            BYDAY_FRIDAY_LAST: 'Último viernes del mes',
            BYDAY_SATURDAY_LAST: 'Último sábado del mes',
            BYDAY_SUNDAY_LAST: 'Último domingo del mes',
            SECONDS: 'Segundos',
            MINUTES: 'Minutos',
            HOURS: 'Horas',
            DAYS: 'Días',
            WEEKS: 'Semanas',
            YEARS: 'Años',
            NUMBER_MINUTES: '{{ minutes }} Minuto(s)',
            JANUARY: 'Enero',
            FEBRUARY: 'Febrero',
            MARCH: 'Marzo',
            APRIL: 'Abril',
            MAY: 'Mayo',
            JUNE: 'Junio',
            JULY: 'Julio',
            AUGUST: 'Agosto',
            SEPTEMBER: 'Septiembre',
            OCTOBER: 'Octubre',
            NOVEMBER: 'Noviembre',
            DECEMBER: 'Diciembre',
            JAN: 'Ene',
            FEB: 'Feb',
            MAR: 'Mar',
            APR: 'Abr',
            JUN: 'Jun',
            JUL: 'Jul',
            AUG: 'Ago',
            SEP: 'Sep',
            OCT: 'Oct',
            NOV: 'Nov',
            DEC: 'Dic',
            CAL_JANUARY: 'ENERO',
            CAL_FEBRUARY: 'FEBRERO',
            CAL_MARCH: 'MARZO',
            CAL_APRIL: 'ABRIL',
            CAL_MAY: 'MAYO',
            CAL_JUNE: 'JUNIO',
            CAL_JULY: 'JULIO',
            CAL_AUGUST: 'AGOSTO',
            CAL_SEPTEMBER: 'SEPTIEMBRE',
            CAL_OCTOBER: 'OCTUBRE',
            CAL_NOVEMBER: 'NOVIEMBRE',
            CAL_DECEMBER: 'DICIEMBRE',
            CAL_SUNDAY: 'Domingo',
            CAL_MONDAY: 'Lunes',
            CAL_TUESDAY: 'Martes',
            CAL_WEDNESDAY: 'Miércoles',
            CAL_THURSDAY: 'Jueves',
            CAL_FRIDAY: 'Viernes',
            CAL_SATURDAY: 'Sábado',
            CAL_SUNDAY_SHORT: 'Dom',
            CAL_MONDAY_SHORT: 'Lun',
            CAL_TUESDAY_SHORT: 'Mar',
            CAL_WEDNESDAY_SHORT: 'Mie',
            CAL_THURSDAY_SHORT: 'Jue',
            CAL_FRIDAY_SHORT: 'Vie',
            CAL_SATURDAY_SHORT: 'Sáb',
            EXPIRESAT: 'Caduca en',
            DATE_SHORT: 'dd/mm/aaaa',
            MONTH_YEAR: 'mm/aaaa',
            UPDATES_DUE: 'Las actualizaciones vencen',
            REACTIVE_UPDATES_DUE: 'Trabajos reactivos',
            PLANNED_UPDATES_DUE: 'Trabajos planificados',
            QUOTE_UPDATES_DUE: 'Cotizaciones',
            SITES_WITH_PPM_REQUIRING_APPROVAL: 'Sitios con PPM que requieren aprobación',
            AWAITING_CLIENT_REVIEW: 'Esperando revisión del cliente',
            P2P_JOBS: 'Trabajos P2P',
            P2P_AWAITING_VALUE: 'Esperando valor',
            P2P_AWAITING_PO_NUMBER: 'Esperando número de orden de compra',
            JOB_REQUISITES_REQUIRING_APPROVAL: 'Aprobación de requisitos de trabajo que requieren aprobación',
            JOB_VALUES: 'Valores de trabajo',
            JOB_VALUES_PENDING_APPROVAL: 'Esperando aprobación',
            JOB_VALUE_REQUIRED: 'Valor requerido',
            OPEN_ACTIONS_1: 'Abrir {{ action_0 }} acción',
            OPEN_ACTIONS_2: 'Abrir {{ action_0 }} y {{ action_1 }}acciones',
            OPEN_ACTIONS_3: 'Abrir {{ action_0 }}, {{ action_1 }} y {{ action_2 }} acciones',
            OPEN_ACTIONS_4: 'Abrir {{ action_0 }}, {{ action_1 }}, {{ action_2 }} y {{ action_3 }}acciones',
            OPEN_ACTIONS_5: 'Abrir {{ action_0 }}, {{ action_1 }}, {{ action_2 }}, {{ action_3 }} y {{ action_4 }} acciones',
            WIDGETS: 'Widgets',
            DASHBOARD_STATISTICS_PERFORMANCE_STATISTICS: 'Estadísticas de rendimiento',
            DASHBOARD_STATISTICS_CONTRACT_TIMELINES: 'Líneas de tiempo de contrato',
            DASHBOARD_STATISTICS_CONTRACT_RENEWALS: 'Renovaciones de contrato',
            DASHBOARD_STATISTICS_CONTRACT_APPROVALS: 'Aprobaciones de contrato',
            DASHBOARD_STATISTICS_OPEN_ACTIONS: 'Abrir acciones',
            DASHBOARD_STATISTICS_JOB_REQUISITES_REQUIRING_APPROVAL: 'Aprobación de requisitos de trabajo que requieren aprobación',
            DASHBOARD_STATISTICS_OPEN_INTEGRATION_ACTIONS: 'Abrir acciones de integración',
            DASHBOARD_STATISTICS_P_2_P_JOBS: 'Trabajos P2P',
            DASHBOARD_STATISTICS_SURVEYS: 'Encuestas de KPI pendientes',
            DASHBOARD_STATISTICS_APPROVALS_REQUIRED: 'Aprobaciones pendientes',
            DASHBOARD_STATISTICS_PENDING_ACKNOWLEDGEMENT: 'Pendiente de reconocimiento',
            DASHBOARD_STATISTICS_UPDATES_DUE: 'Las actualizaciones vencen',
            DASHBOARD_STATISTICS_AWAITING_CLIENT_REVIEW: 'Esperando revisión del cliente',
            DASHBOARD_STATISTICS_PLANNED_OVERDUE_COMPLETION: 'Finalización de trabajos planificados vencidos',
            DASHBOARD_STATISTICS_REACTIVE_OVERDUE_COMPLETION: 'Finalización de trabajos reactivos vencidos',
            DASHBOARD_STATISTICS_REACTIVE_OVERDUE_ATTENDANCE: 'Asistencia de trabajos reactivos vencidos',
            DASHBOARD_STATISTICS_PPM_REQUIRING_APPROVAL: 'Trabajos planificados que requieren aprobación',
            DASHBOARD_STATISTICS_ESCALATIONS: 'Escaladas',
            DASHBOARD_STATISTICS_JOB_VALUES: 'Valores de trabajo',
            CURRENCY_NONE: '',
            CURRENCY_GBP: '£',
            CURRENCY_EUR: '€',
            CORE_CLIENT: 'Cliente principal',
            CORE_CLIENTS: 'Clientes principales',
            CORE_CLIENT_INFORMATION: 'Información de cliente principal',
            ADD_NEW_CORE_CLIENT: 'Añadir un nuevo cliente principal',
            EDIT_CORE_CLIENT: 'Editar cliente principal',
            CORE_CLIENT_LIST: 'Clientes principales',
            CORE_CLIENT_ADD: 'Añadir un nuevo cliente principal',
            CORE_CLIENT_EDIT: 'Editar cliente principal',
            CORE_CLIENT_CREATED: 'Cliente principal creado correctamente',
            CORE_CLIENT_UPDATED: 'Cliente principal actualizado correctamente',
            CORE_CLIENT_DELETED: 'Cliente principal eliminado correctamente',
            CONTRACT: 'Contrato',
            CONTRACTS: 'Contratos',
            CONTRACTS_LIST: 'Contratos',
            CONTRACT_AVAILABLE: '¿Contrato disponible?',
            CONTRACT_BOTH: 'Ambos (electrónico y papel)',
            CONTRACT_ELECTRONIC: 'Electrónico',
            CONTRACT_FORMAT: 'Formato de contrato',
            CONTRACT_HARDCOPY: 'Copia impresa',
            CONTRACT_TYPE: 'Tipo de contrato',
            NOTICE_PERIOD: 'Periodo de aviso',
            SCOPE_AVAILABLE: '¿Ámbito disponible?',
            BILLING_FREQUENCY: 'Frecuencia de facturación',
            BILLING_PRACTICE: 'Práctica de facturación',
            BILLING_PRACTICE_IN_ADVANCE: 'Anticipadamente',
            BILLING_PRACTICE_IN_ARREARS: 'En mora',
            CONTRACT_CREATED: 'Contrato creado',
            CONTRACT_SUBMITTED_AND_CREATED: 'Contrato creado y enviado para aprobación',
            CONTRACT_SUBMITTED_AND_UPDATED: 'Contrato actualizado y enviado para aprobación',
            PENDING: 'Pendiente',
            ACCEPTED: 'Aceptado',
            REJECTED: 'Rechazado',
            CONTRACT_APPROVAL_REQUESTED: 'Contrato solicitado',
            CONTRACTS_ADD: 'Añadir contrato',
            CONTRACT_ADD: 'Añadir contrato',
            CONTRACT_EDIT: 'Editar contrato',
            CONTRACT_RENEW: 'Renovar contrato',
            CONTRACT_UPDATED: 'Contrato actualizado',
            CONTRACT_VALUE: 'Valor',
            LAST_TENDERED: 'Última licitación',
            REVIEW_REMINDER: 'Revisar recordatorio',
            EXPIRY_REMINDER: 'Caduca el recordatorio',
            CONTRACT_TYPE_CLIENT: 'Cliente',
            CONTRACT_TYPE_CONSULTANT: 'Asesor',
            CONTRACT_TYPE_FRAMEWORK: 'Marco',
            CONTRACT_TYPE_PSA: 'PSA',
            CONTRACT_TYPE_SUPPLIER: 'Proveedor',
            CONTRACT_TYPE_OTHER: 'Otro',
            CONTRACT_DETAILS_WITH_ID: 'Detalles de contrato n.º{{ ::id }}',
            CONTRACT_DETAILS: 'Detalles de contrato',
            CONTRACT_STATUS: 'Estado',
            CONTRACT_STATUS_DRAFT: 'Borrador',
            CONTRACT_STATUS_APPROVAL_PENDING: 'Aprobación pendiente',
            CONTRACT_STATUS_RENEWAL_APPROVAL_PENDING: 'Renovación de aprobación pendiente',
            CONTRACT_STATUS_RENEWAL_APPROVAL_REJECTED: 'Renovación rechazada',
            CONTRACT_STATUS_CANCELLED: 'Cancelada',
            CONTRACT_STATUS_TERMINATED: 'Finalizada',
            CONTRACT_STATUS_ACTIVE: 'Activo',
            CONTRACT_STATUS_APPROVAL_REJECTED: 'Rechazado',
            CONTRACT_STATUS_DRAFT_IN_RENEWAL: 'Borrador en renovación',
            CONTRACT_STATUS_EXPIRED: 'Caducado',
            TERMINATE: 'Finalizar',
            RENEW: 'Renovar',
            REGRESS: 'Regresar',
            IS_PDF: 'Copia de contrato en PDF',
            IS_CONTRACT: 'Es contrato',
            CONTRACT_WORKFLOW_SUBMIT: 'Enviar',
            CONTRACT_WORKFLOW_SUBMIT_SUBMIT: 'Enviar',
            CONTRACT_SUBMIT: 'Enviar contrato',
            CONTRACT_WORKFLOW_SUBMIT_SUCCESS: 'Contrato enviado',
            CONTRACT_WORKFLOW_CANCEL: 'Cancelar',
            CONTRACT_WORKFLOW_CANCEL_SUBMIT: 'Cancelar',
            CONTRACT_CANCEL: 'Cancelar contrato',
            CONTRACT_WORKFLOW_CANCEL_SUCCESS: 'Contrato cancelado',
            CONTRACT_WORKFLOW_TERMINATE: 'Finalizar',
            CONTRACT_WORKFLOW_TERMINATE_SUBMIT: 'Finalizar',
            CONTRACT_TERMINATE: 'Finalizar contrato',
            CONTRACT_WORKFLOW_TERMINATE_SUCCESS: 'Contrato terminado',
            CONTRACT_WORKFLOW_APPROVE: 'Aprobar',
            CONTRACT_WORKFLOW_APPROVE_SUBMIT: 'Aprobar',
            CONTRACT_APPROVE: 'Aprobar contrato',
            CONTRACT_WORKFLOW_APPROVE_SUCCESS: 'Contrato aprobado',
            CONTRACT_WORKFLOW_REJECT: 'Rechazar',
            CONTRACT_WORKFLOW_REJECT_SUBMIT: 'Rechazar',
            CONTRACT_REJECT: 'Rechazar contrato',
            CONTRACT_WORKFLOW_REJECT_SUCCESS: 'Contrato rechazado',
            CONTRACT_WORKFLOW_REGRESS: 'Regresar',
            CONTRACT_WORKFLOW_REGRESS_SUBMIT: 'Regresar',
            CONTRACT_REGRESS: 'Retroceder contrato',
            CONTRACT_WORKFLOW_REGRESS_SUCCESS: 'Retroceso rechazado',
            CONTRACT_APPROVER_REQUIRED_BUT_NO_APPROVERS: 'Se requiere la aprobación del contrato, pero actualmente no hay ningún aprobador de contrato asignado al sitio',
            TERMINATED_AT: 'Finalizado en',
            BEFOREPERIOD: 'Recordatorio (en días)',
            ASSOCIATETYPES: 'Tipos de asociados de sitio',
            REVIEW: 'REVISIÓN',
            EXPIRY: 'CADUCIDAD',
            REVIEW_IN: 'Revisar en',
            EXPIRY_IN: 'Caduca en',
            CONTRACT_SETTINGS_REVIEW_VIEW: 'Ajustes de revisión',
            CONTRACT_SETTINGS_EXPIRY_VIEW: 'Ajustes de caducidad',
            REVIEW_SETTINGS_TYPE_REVIEW_ADD: 'Añadir nuevo ajuste de revisión',
            REVIEW_SETTINGS_TYPE_EXPIRY_ADD: 'Añadir nuevo ajuste de caducidad',
            REVIEW_SETTINGS_TYPE_REVIEW_EDIT: 'Editar ajuste de revisión',
            REVIEW_SETTINGS_TYPE_EXPIRY_EDIT: 'Editar ajuste de caducidad',
            REVIEW_SETTING_CREATED: 'Revisar ajuste creado',
            EXPIRY_SETTING_CREATED: 'Ajuste de caducidad creado',
            REVIEW_SETTING_REMOVED: 'Ajuste de revisión eliminado',
            EXPIRY_SETTING_REMOVED: 'Ajuste de caducidad eliminado',
            REVIEW_SETTING_UPDATED: 'Ajuste de revisión actualizado',
            EXPIRY_SETTING_UPDATED: 'Ajuste de caducidad actualizado',
            REVIEW_SETTINGS_REORDERED: 'Ajustes de revisión reordenados',
            EXPIRY_SETTINGS_REORDERED: 'Ajustes de caducidad reordenados',
            CONTRACT_CHASE_SETTINGS_TYPE2_ADD: 'Búsqueda de contrato caducado añadida',
            CONTRACT_CHASE_SETTINGS_TYPE3_ADD: 'Búsqueda de aprobación del contrato añadida',
            CONTRACT_CHASE_SETTINGS_TYPE2_EDIT: 'Editar búsqueda de contrato caducado',
            CONTRACT_CHASE_SETTINGS_TYPE3_EDIT: 'Editar búsqueda de aprobación del contrato',
            CONTRACT_APPROVALS: 'Aprobaciones de contrato',
            CONTRACT_RENEWALS: 'Renovaciones de contrato',
            CONTRACT_TIMELINES: 'Líneas de tiempo de contrato',
            AWAITING_APPROVAL: 'Esperando aprobación',
            AWAITING_RENEWAL_APPROVAL: 'Esperando aplicación de renovación',
            RENEWAL_REJECTED: 'Renovación rechazada',
            APPROVAL_REJECTED: 'Aprobación rechazada',
            APPROACHING_REVIEW: 'Planteando revisión',
            APPROACHING_EXPIRY: 'Planteando caducidad',
            OVERDUE_APPROVAL: 'Aprobación vencida',
            CONTRACT_DOCUMENT: 'Documento de contrato',
            CONTRACT_DOCUMENT_NAME: 'Nombre del documento de contrato',
            VERSIONS: 'Versiones',
            COMPARE_VERSIONS_LIST: 'Comparar versiones',
            AUDIT_TRAIL: 'Pista de auditoría',
            VERSION_ID: 'ID de versión',
            COMPARE: 'Comparar',
            CURRENT_CONTRACT_VERSION: 'Versión de contrato actual',
            CONTRACT_VERSION_WITH_ID: 'Versión de contrato n.º{{ ::id }}',
            CONTRACT_FILE_VIEW: 'Archivo de contrato',
            CONTRACT_SUPPORT_FILES_VIEW: 'Archivos de apoyo de contrato',
            CONTRACT_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> creó el contrato',
            CONTRACT_SUBMITTED_ACTION: '<strong>{{ event._links["user"].title }}</strong> envió el contrato',
            CONTRACT_CANCELLED_ACTION: '<strong>{{ event._links["user"].title }}</strong> canceló el contrato',
            CONTRACT_TERMINATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> finalizó el contrato',
            CONTRACT_APPROVAL_REJECTED_ACTION: '<strong>{{ event._links["user"].title }}</strong> rechazó el contrato',
            CONTRACT_REGRESSED_ACTION: '<strong>{{ event._links["user"].title }}</strong> retrocedió el contrato',
            CONTRACT_APPROVAL_ACCEPTED_ACTION: '<strong>{{ event._links["user"].title }}</strong> aprobó el contrato',
            CONTRACT_UPDATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> actualizó el contrato',
            CONTRACT_RENEWED_DRAFT_ACTION: '<strong>{{ event._links["user"].title }}</strong> renovó el contrato',
            CONTRACT_RENEWED_APPROVED_ACTION: '<strong>{{ event._links["user"].title }}</strong> aprobó la renovación del contrato',
            CONTRACT_RENEWED_REJECTED_ACTION: '<strong>{{ event._links["user"].title }}</strong> rechazó la renovación del contrato',
            CONTRACT_RENEWED_SUBMITTED_ACTION: '<strong>{{ event._links["user"].title }}</strong> envió la renovación del contrato',
            CONTRACT_EXPIRED_SYSTEM_ACTION: 'El sistema venció automáticamente el contrato',
            CONTRACT_OPERATION_APPROVAL_CHASED_SYSTEM_ACTION: 'Se ha enviado una notificación de búsqueda n.º {{ ::event.chaseSequence + 1 }}',
            CONTRACT_OPERATION_RENEWAL_APPROVAL_CHASED_SYSTEM_ACTION: 'Se ha enviado una notificación de búsqueda de aprobación de renovación n.º {{ ::event.chaseSequence + 1 }}',
            CONTRACT_OPERATION_EXPIRE_CHASED_SYSTEM_ACTION: 'Se ha enviado una notificación de caducidad de búsqueda n.º {{ ::event.chaseSequence + 1 }}',
            CONTRACT_REVIEW_NOTIFICATION_SYSTEM_ACTION: 'Se ha enviado una notificación de revisión de <strong>{{ event.daysBefore }} días</strong>',
            CONTRACT_EXPIRY_NOTIFICATION_SYSTEM_ACTION: 'Se ha enviado una notificación de caducidad de <strong>{{ event.daysBefore }} días</strong>',
            CHARGE_TYPE: 'Tipo de carga',
            CHARGE_TYPES: 'Tipos de carga',
            CHARGE_TYPE_INFORMATION: 'Información del tipo de carga',
            ADD_NEW_CHARGE_TYPE: 'Añadir un nuevo tipo de carga',
            EDIT_CHARGE_TYPE: 'Editar tipo de carga',
            CHARGE_TYPE_LIST: 'Tipos de carga',
            CHARGE_TYPE_ADD: 'Añadir un nuevo tipo de carga',
            CHARGE_TYPE_EDIT: 'Editar tipo de carga',
            CHARGE_TYPE_CREATED: 'Tipo de carga creado correctamente',
            CHARGE_TYPE_UPDATED: 'Tipo de carga actualizado correctamente',
            CHARGE_TYPE_DELETED: 'Tipo de carga eliminado correctamente',
            CHARGE_TYPE_REQUIRED: 'Tipo de carga requerido',
            CERTIFICATE_TYPES: 'Tipos de certificado',
            CERTIFICATE_TYPE: 'Tipo de certificado',
            CERTIFICATE_TYPE_LIST: 'Tipos de certificado',
            CERTIFICATE_TYPE_ADD: 'Añadir un nuevo tipo de certificado',
            CERTIFICATE_TYPE_EDIT: 'Editar tipo de certificado',
            CERTIFICATE_TYPE_CREATED: 'Tipo de certificado creado',
            CERTIFICATE_TYPE_UPDATED: 'Tipo de certificado actualizado',
            CERTIFICATE_TYPE_DELETED: 'Tipo de certificado eliminado',
            BULK_ACTIONS: 'Acciones en masa',
            BULK_ACTION_DETAILS_LIST: 'Detalles de acción en masa',
            BULK_ACTION_ERROR_MESSAGE: 'Mensaje',
            BULK_ACTION_FAILED: 'Fallido',
            BULK_ACTION_LOG_ALL: 'Todo',
            BULK_ACTION_LOG_CHANGE: 'Correcto',
            BULK_ACTION_LOG_ERROR: 'Error',
            BULK_ACTION_LOG_NO_CHANGE: 'Sin cambios',
            BULK_ACTION_LOG_STATUS: 'Estado',
            BULK_ACTION_LOG_STATUS_DONE: 'Hecho',
            BULK_ACTION_LOG_STATUS_PENDING: 'Pendiente',
            BULK_ACTION_LOG_TYPE: 'Tipo de registro',
            BULK_ACTION_LOGS: 'Registro de acción en masa',
            BULK_ACTION_MAX_FILTERED_ITEMS_EXCEEDED: 'Acción en masa no creada - se ha excedido el máximo de trabajos filtrados',
            BULK_ACTION_MAX_ITEMS: 'Acción en masa no creada - se ha excedido el máximo de trabajos seleccionados',
            BULK_ACTION_MUST_ONLY_BE_FILTERED_OR_SELECTED_JOBS: 'Acción en masa no creada - No se pueden reasignar los trabajos seleccionados y filtrados al mismo tiempo',
            BULK_ACTION_NO_JOBS: 'Acción en masa no creada - Ningún trabajo seleccionado',
            BULK_ACTION_REASSIGN_USER_REQUIRED: 'Reasignación de acción en masa - Se requiere un usuario',
            BULK_ACTION_REASSIGN_SERVICE_PROVIDER_REQUIRED: 'Reasignación de acción en masa - Se requiere un proveedor',
            BULK_ACTION_NO_JOBS_FOUND_THAT_MATCH_FILTERS: 'Acción en masa no creada - No se encontraron trabajos que coincidan con los criterios filtrados',
            BULK_ACTION_SUCCESSFUL: 'Correcto',
            BULK_ACTIONS_LIST: 'Lista de acciones en masa',
            BULKACTIONS: 'Acciones en masa',
            CHANGE_OPERATIVE: 'Cambiar operativa',
            STATUS_PAUSED: 'En curso (pausada)',
            STATUS_INITIALISING: 'Iniciando trabajos',
            BULK_ACTION_NOTE_REQUIRED: 'Campo nota requerido',
            BULK_ACTION_REASSIGN: 'Reasignar',
            BULK_ACTION_CANCEL: 'Cancelar',
            BULK_ACTION_REASSIGN_OPERATIVES: 'Reasignar operativas',
            BULK_ACTION_CANCEL_TASKS: 'Cancelar desde tareas',
            BUILDING: 'Construcción y',
            BUILDINGS: 'Construcciones',
            ADD_NEW_BUILDING: 'Añadir una nueva construcción',
            EDIT_BUILDING: 'Editar construcción',
            BUILDING_CREATED: 'Construcción creada correctamente',
            BUILDING_UPDATED: 'Construcción actualizada correctamente',
            BUILDING_DELETED: 'Construcción eliminada',
            NO_BUILDING_RECORDS: 'No hay registros de construcción',
            BUDDY: 'Compañero',
            BUDDY_ADD: 'Añadir compañero',
            BUDDY_EDIT: 'Editar compañero',
            BUDDY_UPDATED: 'Compañero actualizado',
            BUDDIES: 'Compañeros',
            BUDDIES_NO_RESULTS: 'Sin compañeros',
            BUDDYING_FOR: 'Compañero por',
            BUDDY_SELECT_SITES: 'Sitios seleccionados',
            BUDDY_SITE_RELATION_REMOVED: 'Sitio eliminado',
            BUDDY_REMOVED: 'Compañero eliminado',
            BUDDY_ALREADY_IN_USE_SITES: 'Sitios ya en uso:',
            BUDDY_CONFLICTS: 'Hay conflictos con otros sitios',
            BUDDY_DEACTIVATED: 'Compañero desactivado',
            MY_BUDDY: 'Mi compañero',
            SCHEDULE: 'Horario',
            MY_BUDDIES: 'Mis compañeros',
            NO_BUDDIES_FOUND: 'Ningún compañero establecido',
            BUDDY_BETWEEN: 'Compañero entre',
            ASSIGNED_BY: 'Asignado por',
            BUDDY_ADD_SUCCESS: 'Compañero añadido',
            BUDDY_SITE_ADDED: 'Sitio añadido',
            SITES_EMPTY: 'Los sitios no pueden estar vacíos',
            BILLING_TYPES: 'Tipos de facturación',
            BILLING_TYPE: 'Tipo de facturación',
            BILLING_TYPES_LIST: 'Tipos de facturación',
            BILLING_TYPES_ADD: 'Añadir nuevo tipo de facturación',
            BILLING_TYPES_EDIT: 'Editar tipo de facturación',
            BILLING_TYPE_UPDATED: 'Tipo de facturación actualizado',
            BILLING_TYPE_CREATED: 'Tipo de facturación creado',
            BILLING_TYPE_DELETED: 'Tipo de facturación eliminado',
            BILLING_TYPE_INFORMATION: 'Información del tipo de facturación',
            BILLING_COST: 'Coste de facturación',
            AUDIT: 'Auditorías',
            AUDITS: 'Auditorías',
            AUDIT_LIST: 'Lista de auditorías',
            TEMPLATE_LIST: 'Lista de plantillas de auditorías',
            TEMPLATE_EDIT: 'Editar plantillas de auditorías',
            SCHEDULE_LIST: 'Lista de horarios de auditorías',
            AUDIT_ADD: 'Añadir una nueva auditoría',
            TEMPLATE_ADD: 'Añadir una nueva plantilla de auditoría',
            SCHEDULE_ADD: 'Añadir un nuevo horario de auditoría',
            QUESTIONS: 'Preguntas',
            QUESTION: 'Pregunta',
            AUDIT_SUB_SECTIONS: 'Subsecciones',
            AUDIT_QUESTIONS: 'Preguntas',
            START_AUDIT: 'Iniciar auditoría',
            COMPLETE_AUDIT: 'Completar auditoría',
            EDIT_AUDIT_REFERENCE: 'Editar referencia',
            AUDIT_CONFIRM_START_INSTRUCTIONS: 'Confirmar fecha de inicio',
            NOT_ALL_QUESTIONS_ANSWERED_FORCE_COMPLETE: 'No se han respondido todas las preguntas, confirme por qué está completando la auditoría',
            AUDIT_FORCE_COMPLETE_REASON: 'Razón completa de fuerza de auditoría',
            QUESTIONS_ANSWERED: 'Número de preguntas (respondidas)',
            NUMBER_OF_QUESTIONS: 'Número de preguntas',
            ANSWERED_QUESTIONS: 'Preguntas respondidas',
            NUMBER_OF_SECTIONS: 'Número de secciones',
            NUMBER_OF_SUB_SECTIONS: 'Número de subsecciones',
            DEFAULT_BUTTON_OPTIONS: 'Opciones de botón por defecto',
            NOT_YET_ANSWERED: 'No respondida',
            CONFIRM_ANSWER_CHOICE: 'Confirmar elección de respuesta',
            AUDIT_ANSWER_EXTRA_INFORMATION: 'Información adicional opcional',
            AUDIT_ANSWER_OPTIONS_NO_DEFAULT: 'Sin predeterminados, configuración manual',
            AUDIT_ANSWER_OPTIONS_NO_YES: 'No (0 %), Sí (100 %)',
            AUDIT_ANSWER_OPTIONS_YES_NO: 'Sí (0 %), No (100 %)',
            ANSWER_OPTION_ADDED: 'Opción de respuesta añadida',
            ANSWER_OPTION_UPDATED: 'Opción de respuesta actualizada',
            ANSWER_OPTION_VIEW: 'Detalles de opción de respuesta',
            ANSWER_OPTION_TITLE_MAX_10_CHARACTERS: 'El titulo del botón debe tener 10 caracteres o menos.',
            AUDIT_ASSISTED_COMMENTS: 'Comentarios asistidos',
            SCORE: 'Puntuación',
            AUDIT_CREATED: 'Creada',
            AUDIT_STARTED: 'En curso',
            AUDIT_DRAFT: 'En borrador',
            AUDIT_CANCELLED: 'Cancelada',
            AUDIT_COMPLETED: 'Completado',
            AUDIT_TEMPLATES: 'Plantillas',
            AUDIT_TEMPLATE: 'Plantilla de auditoría',
            AUDIT_TEMPLATE_ACTIVE: 'Plantilla activa',
            AUDIT_TEMPLATE_INACTIVE: 'Plantilla inactiva',
            AUDIT_SCHEDULE_ACTIVE: 'Horario activo',
            AUDIT_SCHEDULE_CREATED: 'Horario de auditoría creado',
            AUDIT_SCHEDULE_INACTIVE: 'Horario inactivo',
            AUDIT_TEMPLATE_CREATED: 'Plantilla de auditoría creada',
            AUDIT_TEMPLATE_SAVE: 'Plantilla Guardar como',
            AUDIT_COPY_TEMPLATE: 'Copiar plantilla',
            AUDIT_TEMPLATE_SUMMARY: 'Resumen de plantilla',
            AUDIT_TEMPLATE_STATUS: 'Estado de plantilla',
            AUDIT_TEMPLATE_VER: 'Versión de plantilla',
            AUDIT_TEMPLATE_CREATE_VERSION: 'Crear versión nueva',
            AUDIT_TEMPLATE_NEW_VERSION_CREATED: 'Nueva versión creada',
            AUDIT_TEMPLATE_ACTIVATE_VERSION: 'Activar versión',
            AUDIT_TEMPLATE_VERSION_ACTIVATED: 'Versión activada',
            AUDIT_TEMPLATE_DEACTIVATE_VERSION: 'Desactivar versión',
            AUDIT_TEMPLATE_VERSION_DEACTIVATED: 'Versión desactivada',
            AUDIT_ANSWER_OPTIONS: 'Opciones de respuesta',
            OPTION_LIST: 'Opciones de respuesta',
            OPTION_ADD: 'Añadir nueva opción de respuesta',
            AUDIT_SCHEDULES: 'Horarios',
            NO_OPERATIVES_FOUND: 'No se encontraron operativas de auditoría primaria',
            QUESTION_TYPE: 'Tipo de pregunta',
            COMMENT_TYPE: 'Sólo comentario',
            BUTTONS_TYPE: 'Opciones de botón',
            OPTIONS_TYPE: 'Opciones desplegables',
            ATTACHMENT_REQUIRED: 'Adjunto requerido',
            WEIGHTING: 'Ponderación',
            CREATE_AS_TEMPLATE: 'Crear como plantilla',
            CREATE_FROM_TEMPLATE: 'Crear desde plantilla',
            INSTRUCTION: 'Instrucción',
            AUDIT_SECTION: 'Sección',
            BLANK_AUDIT_FORM_PDF: 'Generar PDF en blanco',
            BLANK_AUDIT_FORM_PDF_QUEUED: 'PDF en blanco en cola',
            PDF_REPORT: 'Generar informe en PDF',
            PDF: 'Exportación de PDF',
            ACTIVATE_AUDIT: 'Activar',
            CANCEL_AUDIT: 'Cancelar',
            AUDIT_SECTIONS: 'Secciones',
            AUDIT_EDIT: 'Editar auditoría',
            SCHEDULE_EDIT: 'Editar horarios de auditorías',
            AUDIT_UPDATED: 'Auditoría actualizada',
            AUDIT_DELETED: 'Auditoría eliminada',
            AUDIT_CONFIRM_ACTIVATE_INSTRUCTIONS: '¿Está seguro?',
            AUDIT_CONFIRM_CANCEL_INSTRUCTIONS: 'Introduzca el texto de soporte',
            AUDIT_CANCEL_REASON: 'Razón de cancelación',
            ADD_SECTION: 'Añadir sección',
            EDIT_SECTION: 'Editar sección',
            SECTION: 'Sección',
            ADD_SUB_SECTION: 'Añadir subsección',
            ADD_QUESTION: 'Añadir pregunta',
            SECTIONS: 'Secciones',
            AUDIT_SECTION_UPDATED: 'Sección de auditoría actualizada',
            AUDIT_UPDATED_TO_CANCELLED: 'Auditoría cancelada',
            AUDIT_HAS_NO_ASSIGNED_OPERATIVE: 'La auditoría no tiene una operativa asignada',
            AUDIT_HAS_NO_ASSIGNED_SERVICE_PROVIDER: 'La auditoría no tiene un proveedor de servicios asignado',
            AUDIT_HAS_NO_QUESTIONS: 'La auditoría no tiene preguntas',
            AUDIT_SECTION_CREATED: 'Sección de auditoría creada',
            AUDIT_SECTION_DELETED: 'Sección de auditoría eliminada',
            AUDIT_QUESTION_CREATED: 'Pregunta de auditoría creada',
            AUDIT_QUESTION_UPDATED: 'Pregunta de auditoría actualizada',
            AUDIT_QUESTION_DELETED: 'Pregunta de auditoría eliminada',
            AUDIT_UPDATED_TO_ACTIVATED: 'Auditoría activada',
            ADD_AUDIT_QUESTION: 'Añadir pregunta',
            EDIT_AUDIT_QUESTION: 'Editar pregunta',
            EDIT_AUDIT_TEMPLATE: 'Editar opción de respuesta',
            ADD_AUDIT_TEMPLATE: 'Añadir opción de respuesta',
            AUDIT_QUESTION_COMMENT: 'Comentario',
            AUDIT_QUESTION_OPTIONS: 'Opciones',
            AUDIT_QUESTION_BUTTONS: 'Botones',
            ANSWER: 'Respuesta',
            ANSWER_OPTIONS: 'Opciones de respuesta',
            POINTS_VALUE: 'Puntos',
            AUDIT_SECTION_ORDER_UPDATED: 'Orden de sección de auditoría actualizada',
            AUDIT_QUESTION_ORDER_UPDATED: 'Orden de pregunta de auditoría actualizada',
            AUDIT_IMAGE: 'Imagen de auditoría',
            CREATE_AS_AUDIT_SCHEDULE: 'Crear nuevo horario',
            SCHEDULE_START_AT: 'Inicio de horario en',
            FORCE_COMPLETE: 'Forzar completa',
            AUDIT_COMPLETE_DATE: 'Auditoría completada en',
            RESET_SECTIONS: 'Restablecer orden',
            RESET_QUESTIONS: 'Restablecer orden',
            RESET_ANSWER_OPTIONS_ORDER: 'Orden de opciones de restablecimiento',
            ANSWER_OPTION_EDIT: 'Editar opción de respuesta',
            OUT_OF: '/',
            DATE_STARTED_AT: 'Empezado en',
            ANSWER_COMMENT: 'Comentario',
            REVERT: 'Revertir',
            QUESTION_ANSWERED: 'Respuesta enviada',
            QUESTION_REVERTED: 'Respuesta revertida',
            ANSWER_OPTION: 'Opción de respuesta',
            SELECTED_ANSWER: 'Respuesta seleccionada',
            ANSWER_SCORE: 'Puntuación de respuesta',
            ANSWERED: 'Respondida',
            SHOW_ANSWER: 'Mostrar',
            HIDE_ANSWER: 'Ocultar',
            AUDIT_INSTRUCTION: 'Instrucción de auditoría',
            AUDIT_UPDATED_TO_COMPLETED: 'Auditoría completada',
            FORCE_COMPLETED: 'Nota de completada',
            ADD_ANSWER_OPTION: 'Añadir opción de respuesta',
            AUDIT_ANSWER_OPTION_CREATED: 'Opción de respuesta creada',
            ANSWER_OPTION_DELETED: 'Opción de respuesta eliminada',
            ANSWER_OTPION_UPDATED: 'Opción de respuesta actualizada',
            AUDIT_ANSWER_OPTIONS_ORDER_UPDATED: 'Opciones de orden de respuesta de auditoría actualizada',
            AUDIT_UPDATED_TO_STARTED: 'Auditoría iniciada',
            AUDIT_AT_LEAST_ONE_SECTION_HAS_NO_QUESTIONS: 'No se puede activar la auditoría, por lo menos una sección no tiene preguntas',
            AUDIT_AT_LEAST_ONE_QUESTION_HAS_NO_ANSWER_OPTIONS: 'Por lo menos una sección no tiene preguntas',
            NOT_ALL_QUESTIONS_ANSWERED: 'No todas las preguntas de la auditoría respondidas',
            NO_PERMISSION_TO_FORCE_COMPLETE: 'No tiene permiso para forzar una auditoría completa',
            NO_PERMISSION_TO_EDIT_AUDIT_REFERENCE: 'No tiene permiso para editar la referencia de la auditoría',
            EDIT_SUB_SECTION: 'Editar subsección',
            ANSWER_BELOW_100_REQUIRES_PRIORITY: 'Prioridad requerida',
            AUDIT_QUESTION_RAISE_ACTION_SELECT_PRIORITY: 'Ha seleccionado una respuesta inferior al 100 % para que se presente una acción; seleccione una prioridad para esta acción',
            DATE_CANCELLED: 'Fecha cancelada',
            CANCEL_REASON: 'Razón cancelada',
            SERVICE_PROVIDER_NO_MATCH: 'No hay ninguna coincidencia entre el proveedor de servicios y el sitio que ha elegido',
            SITES_REQUIRED: 'Se requiere el sitio/sitios',
            CANNOT_CHANGE_FROM_PUBLIC: 'No se puede cambiar de público a privado',
            DUPLICATE_ANSWER_OPTION: 'Los duplicados no están permitidos',
            AUDITS_DASHBOARD: 'Panel de control de auditorías',
            AUDITS_RAISED_IN_LAST_60_DAYS: 'Auditorías presentadas en los últimos 60 días',
            AUDITS_RECENTLY_COMPLETED: 'Auditorías completadas recientemente',
            AUDITS_NEXT: 'Próximas auditorías',
            AUDITS_NEXT_SCHEDULED: 'Próxima auditorías programadas',
            AUDITS_GENERATED_FOR_DATE: 'Generada por fecha',
            AUDITS_GENERATED_FOR_DATE_START: 'Generada por fecha de inicio',
            AUDITS_GENERATED_FOR_DATE_END: 'Generada por fecha de fin',
            AUDITS_SCHEDULES: 'Horarios de auditorías',
            AUDITS_SCHEDULE: 'Horario',
            FORCE_RAISE: 'Presentar fuerza',
            FORCE_RAISE_CHOICE: 'Por favor, plantear un trabajo o una acción',
            REQUIRE_ACTION: 'Requerir acción',
            REQUIRE_EITHER: 'Requerir alguno',
            RAISE_ACTION: 'Presentar acción',
            ACTION_RAISED: 'Acción presentada',
            REQUIRE_JOB: 'Requerir trabajo',
            ACTION_HAS_QUESTION: 'Ya hay una acción para esta cuestión',
            NO_PERMISSION_TO_RAISE_JOB: 'No tiene permiso para plantear un trabajo, la pregunta no puede ser respondida',
            AUDIT_OPEN_ACTION: 'Abrir acciones de auditoría',
            ANSWER_UPDATED: 'Respuesta actualizada',
            NEXT_OCCURENCE: 'Siguiente ocurrencia',
            NEXT_OCCURENCE_START: 'Siguiente inicio de ocurrencia',
            NEXT_OCCURENCE_END: 'Siguiente fin de ocurrencia',
            AUDIT_TEMPLATE_VERSION: 'Versión',
            DISABLED_SUMMARY_FIELD: 'No se puede editar el resumen debido a que la plantilla está activada',
            COPY_FROM_ANSWER_OPTIONS: 'Copiar de las opciones de respuesta',
            SCHEDULE_NAME: 'Nombre de horario',
            TEMPLATE_LINK: 'Vínculo a plantilla',
            SCHEDULE_INVALID_INVALID_LENGTH: 'Nombre de programa demasiado largo. Debería tener 100 caracteres o menos.',
            ASSET: 'Activo',
            ASSETS: 'Activos',
            ASSET_LIST: 'Activos',
            ASSET_ADD: 'Añadir un nuevo activo',
            ASSET_ENTER: 'Introducir un activo',
            SUB_ASSET_ENTER: 'Introducir un subactivo',
            ASSET_EDIT: 'Editar activo...',
            ASSET_CREATED: 'Activo creado',
            ASSET_UPDATED: 'Activo actualizado',
            ASSET_DELETED: 'Activo eliminado',
            ASSET_IMPORTS: 'Importaciones de activo',
            MANAGE_ASSETS: 'Gestionar activos',
            SUB_ASSET: 'Subactivo',
            SUB_ASSETS: 'Subactivos',
            SUB_ASSET_LIST: 'Subactivos',
            SUB_ASSET_ADD: 'Añadir un nuevo subactivo',
            SUB_ASSET_CREATED: 'Subactivo creado',
            SUB_ASSET_UPDATED: 'Subactivo actualizado',
            SUB_ASSET_DELETED: 'Subactivo eliminado',
            SUB_ASSET_EDIT: 'Editar subactivo',
            SUB_ASSETS_WITH_COUNT: 'Subactivos ({{ ::count || 0 | number:0 }})',
            BACK_TO_SUB_ASSETS: 'Volver a subactivos',
            MAKE: 'Marca',
            MODEL: 'Modelo',
            SERIAL: 'De serie',
            BUSINESS_CONTINUITY_RISK: 'Continuidad comercial',
            REPLACEMENT_COST_LABOUR: 'Coste de reemplazo laboral',
            REPLACEMENT_COST_MATERIALS: 'Coste de reemplazo de materiales',
            END_OF_LIFE: 'Duración del equipo',
            CHARGEABLE: 'Cargable',
            ASSET_TYPE: 'Tipo de activo',
            NUMBER: 'Número',
            IS_STATUTORY: '¿Es de tipo normativo?',
            ASSET_HISTORICAL_SCORES: 'Puntuaciones históricas',
            ASSET_HISTORICAL_SCORES_LIST: 'Puntuaciones históricas',
            COMPLETED_BY: 'Completada por',
            FREQUENCY_CANT_BE_DAILY: 'La rutina de servicio seleccionada contiene una frecuencia diaria. Cambie o elimine',
            ASSET_TYPES: 'Tipos de activo',
            ADD_NEW_ASSET_TYPE: 'Añadir un nuevo tipo de activo',
            EDIT_ASSET_TYPE: 'Editar tipo de activo',
            ASSET_TYPE_LIST: 'Tipos de activo',
            ASSET_TYPE_ADD: 'Añadir un nuevo tipo de activo',
            ASSET_TYPE_EDIT: 'Editar tipo de activo',
            ASSET_TYPE_CREATED: 'Tipo de activo creado',
            ASSET_TYPE_UPDATED: 'Tipo de activo actualizado',
            ASSET_TYPE_DELETED: 'Tipo de activo eliminado',
            ASSET_TYPE_ADDED: 'Tipo de activo añadido',
            ASSET_SCORE_TYPE: 'Tipo de puntuación de activo',
            ASSET_SCORE_TYPES: 'Tipos de puntuación de activo',
            ASSET_SCORE_TYPE_ADD: 'Añadir un nuevo tipo de puntuación de activo',
            ASSET_SCORE_TYPE_LIST: 'Tipo de puntuación de activo',
            ASSET_SCORE_TYPE_VIEW: 'Información',
            ASSET_SCORE_TYPE_EDIT: 'Editar tipo de puntuación de activo',
            ASSET_SCORE_TYPE_CREATED: 'Tipo de puntuación de activo creado',
            ASSET_SCORE_TYPE_UPDATED: 'Tipo de puntuación de activo actualizado',
            ASSET_SCORE_TYPE_DELETED: 'Tipo de puntuación de activo eliminado',
            ASSET_SCORE_TYPE_ADDED: 'Tipo de puntuación de activo añadido',
            RANGE_WEIGHT: 'Rango peso',
            RANGE_LOWER_BETTER: 'Inferior es mejor',
            RANGE_HIGHER_BETTER: 'Superior es mejor',
            COLOUR_FIELD_MUST_BE_IN_HEX_FORMAT: 'El campo color debe estar en formato hexadecimal',
            ASSET_SCORE_TYPE_OPTION: 'Opciones de tipo de puntuación de activo',
            ASSET_SCORE_TYPE_OPTION_LIST: 'Opciones de tipo de puntuación de activo',
            ASSET_SCORE_TYPE_OPTION_EDIT: 'Editar opción de tipo de puntuación de activo',
            ASSET_SCORE_TYPE_OPTION_ADD: 'Añadir una nueva opción de tipo de puntuación de activo',
            ASSET_SCORE_TYPE_OPTION_CREATED: 'Opción de tipo de puntuación de activo creada',
            ASSET_SCORE_TYPE_OPTION_UPDATED: 'Opción de tipo de puntuación de activo actualizada',
            ASSET_SCORE_TYPE_OPTION_DELETED: 'Opción de tipo de puntuación de activo eliminada',
            ADD_ASSET_SCORES: 'Añadir puntuaciones de activo',
            UNABLE_TO_DELETE_SCORE_TYPE: 'No se puede eliminar el tipo de puntuación ya que está en uso',
            UNABLE_TO_EDIT_SCORE_TYPE: 'No se puede editar el tipo de puntuación ya que está en uso',
            UNABLE_TO_DELETE_SCORE_TYPE_OPTION: 'No se puede eliminar la opción de tipo de puntuación ya que está en uso',
            UNABLE_TO_EDIT_SCORE_TYPE_OPTION: 'No se puede editar la opción de tipo de puntuación ya que está en uso',
            APPROVER: 'Aprobador',
            APPROVERS: 'Aprobadores',
            APPROVED_BY: 'Aprobado por',
            APPROVERS_UPDATED: 'Aprobadores actualizados',
            APPROVAL_REQUIRED: 'Aprobación requerida',
            APPROVER_REQUIRED: 'Se debe añadir un aprobador a esta lista, ya que se requiere la aprobación',
            APPROVER_NOT_ADDED: 'No se ha añadido ningún aprobador',
            APPROVER_ADDED_TO_ALL_APPROVER_TYPES: 'Aprobador añadido a todos los tipos de aprobador para este sitio',
            APPROVER_REMOVED_FROM_ALL_APPROVER_TYPES: 'Aprobador eliminado de todos los tipos de aprobador para este sitio',
            USER_ALREADY_APPROVER: 'El usuario ya está asignado como un aprobador de este tipo para este sitio',
            ADD_REMOVE_USER_ALL_APPROVER_TYPES: 'Añadir o eliminar un usuario de todos los tipos de aprobador',
            REACTIVE_JOB_APPROVER: 'Aprobador reactivo',
            REACTIVE_JOB_APPROVERS: 'Trabajo reactivo',
            REACTIVE_JOB_EXTENSIONS_APPROVERS: 'Ampliaciones de trabajo reactivo',
            PLANNED_JOB_APPROVER: 'Aprobador de planificador',
            PLANNED_JOB_APPROVERS: 'PPM',
            PLANNED_JOB_EXTENSIONS_APPROVERS: 'Ampliaciones PPM',
            JOB_EXTENSION_APPROVER: 'Aprobador de ampliación de trabajo',
            JOB_EXTENSION_APPROVERS: 'Ampliación de trabajo',
            QUOTE_APPROVER: 'Aprobador de cotización',
            QUOTE_APPROVERS: 'Cotización',
            CONTRACT_APPROVER: 'Aprobador de contrato',
            CONTRACT_APPROVERS: 'Contrato',
            SURVEY_APPROVER: 'Aprobador de encuesta',
            SURVEY_APPROVERS: 'Encuesta',
            EXTENSION_APPROVER: 'Aprobador de ampliación',
            APPROVER_ADDED_BUT_NOT_REQUIRED: 'Se ha añadido un aprobador, pero no se ha marcado la opción Aprobación requerida.',
            SITE_APPROVER_LIST: 'Aprobadores de sitios',
            ACTION: 'Acción',
            ACTIONS: 'Acciones',
            ACTION_LIST: 'Acciones',
            ACTION_ADD: 'Añadir una nueva acción',
            ACTION_EDIT: 'Editar acción',
            CANNOT_CREATE_ACTION_SITE_ASSOCIATE_NOT_CORRECTLY_DEFINED: 'No se puede crear acción, asociados del sitio definidos incorrectamente',
            ACTION_CREATED: 'Acción creada',
            ACTION_UPDATED: 'Acción actualizada',
            ACTION_DELETED: 'Acción eliminada',
            ACTIONS_OPEN: 'Abrir acciones',
            ACTION_STATUS_OPEN: 'Abrir',
            ACTION_STATUS_COMPLETED: 'Completado',
            ACTION_STATUS_CANCELLED: 'Cancelada',
            ACTION_STATUS_PAPERWORK_REJECTED: 'Papeleo rechazado',
            ACTION_ADD_SITE: 'Sitio',
            ACTION_ADD_CONTACT_DETAILS: 'Detalles de contacto',
            ACTION_ADD_DETAILS: 'Detalles de acción',
            ACTION_ID: 'Acción {{ id }}',
            ACTION_DETAILS: 'Detalles de acción',
            ACTION_STATUS: 'Estado',
            ACTION_CHANGE_ASSIGNEE_TO_ME: 'Convertirse en beneficiario',
            DATE_DUE_START: 'Fecha de inicio de vencimiento',
            DATE_DUE_END: 'Fecha de fin de vencimiento',
            PAPERWORK_FOR_CLIENT_REVIEW: 'Papeleo para revisión del cliente',
            ESCALATED_TO_CLIENT: 'Escalado a cliente',
            EXTERNAL_SYSTEM: 'Sistema exterior',
            FROM_AUDIT: 'De auditoría',
            FROM_AUDIT_QUESTION: 'De pregunta de auditoría',
            ACTION_SUMMARY: 'Resumen de acción',
            JOB_COMPLETION_DATE: 'Fecha de finalización de trabajo',
            ACTION_ASSIGNEE_TYPE: 'Tipo de acción de beneficiario',
            ACTION_WORKFLOW_REJECT_JOB_PAPERWORK: 'Rechazar papeleo',
            ACTION_WORKFLOW_REJECT_JOB_PAPERWORK_SUBMIT: 'Rechazar papeleo',
            ACTION_WORKFLOW_CANCEL: 'Cancelar',
            ACTION_WORKFLOW_COMPLETE: 'Completo',
            ACTION_WORKFLOW_REOPEN: 'Reabrir',
            ACTION_WORKFLOW_REASSIGN: 'Reasignar',
            ACTION_WORKFLOW_REJECT_SUBMIT: 'Rechazar',
            ACTION_WORKFLOW_CANCEL_SUBMIT: 'Enviar',
            ACTION_WORKFLOW_COMPLETE_SUBMIT: 'Completo',
            ACTION_WORKFLOW_REOPEN_SUBMIT: 'Reabrir',
            ACTION_WORKFLOW_REASSIGN_SUBMIT: 'Reasignar',
            ACTION_WORKFLOW_CANCEL_SUCCESS: 'Acción cancelada',
            ACTION_WORKFLOW_COMPLETE_SUCCESS: 'Acción completada',
            ACTION_WORKFLOW_REOPEN_SUCCESS: 'Acción reabierta',
            ACTION_WORKFLOW_REASSIGN_SUCCESS: 'Acción reasignada',
            ACTION_WORKFLOW_REJECT_JOB_PAPERWORK_SUCCESS: 'Papeleo rechazado',
            ACTION_TYPE: 'Tipo de acción',
            ACTION_TYPES: 'Tipos de acción',
            ACTION_TYPE_LIST: 'Tipos de acción',
            ACTION_TYPE_ADD: 'Añadir un nuevo tipo de acción',
            ACTION_TYPE_EDIT: 'Editar tipo de acción',
            ACTION_TYPE_CREATED: 'Tipo de acción creada',
            ACTION_TYPE_UPDATED: 'Tipo de acción actualizada',
            ACTION_TYPE_DELETED: 'Tipo de acción eliminada',
            CANNOT_USE_ACTION_TYPE_RESERVED_NAME: 'No se puede utilizar nombre reservado del tipo de acción',
            ACTION_FILE_SENT_ACTION: '<strong>{{ event._links["user"].title }}</strong> envió un archivo a Riskwise',
            ACTION_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> creó la acción',
            ACTION_COMPLETED_ACTION: '<strong>{{ event._links["user"].title }}</strong> completó la acción',
            ACTION_CANCELLED_ACTION: '<strong>{{ event._links["user"].title }}</strong> canceló la acción',
            ACTION_REOPENED_ACTION: '<strong>{{ event._links["user"].title }}</strong> reabrió la acción',
            ACTION_REASSIGNED_ACTION: '<strong>{{ event._links["user"].title }}</strong> reasignó la acción a <strong>{{ event._links["assignee"].title }}</strong>',
            ACTION_JOB_PAPERWORK_REJECTED_ACTION: '<strong>{{ event._links["user"].title }}</strong> rechazó el papeleo del trabajo',
            ACTION_MESSAGE_ACTION: '<strong>{{ message._links["sender"].title }}</strong> proporcionó una actualización',
            ACTION_FILE_ADDED: '<strong>{{ file._links["author"].title }}</strong> añadió un archivo',
            ACTION_JOB_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> creó un trabajo',
            ACTION_QUOTE_REQUEST_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> creó una solicitud de cotización',
            ACTION_CANCELLED_SYSTEM_ACTION: 'El sistema canceló automáticamente la acción.',
            LOG_OUT: 'Cerrar sesión',
            YOUR_DASHBOARD: 'Su panel de control',
            LOGGED_IN_AS: 'Sesión iniciada como <strong>{{::name}}</strong>',
            RETURN_TO_USER: 'Volver al usuario <strong>{{::name}}</strong>',
            LOGIN: 'Inicio de sesión',
            RESET_PASSWORD: '¿Ha olvidado su contraseña?',
            FORGOTTEN_USERNAME: '¿Ha olvidado su nombre de usuario?',
            PASSWORD_CHANGED: 'Contraseña cambiada',
            RESET_PASSWORD_EXPLANATION: 'Introduzca su nombre de usuario a continuación y le enviaremos un enlace por correo electrónico para restablecer su contraseña.',
            FORGOTTEN_USERNAME_EXPLANATION: 'Introduzca su correo electrónico a continuación y le enviaremos su nombre de usuario.',
            FORGOTTEN_USERNAME_ERROR: 'Ha introducido una dirección de correo electrónico que no es válida.',
            SETTINGS: 'Ajustes',
            SETTINGS_DESCRIPTION: 'Ajustes del sistema para personalizar su instalación de Elogs CAFM.'
    });
    }

})();
