(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('zh-hk', {
            SUMMARY: '總結',

            WORKFLOW: '工作流程',
            APPROVALS_REQUIRED: '未審批',

            SELECT_A_USER: '選擇用戶',
            ENTER_DETAILS_MANUALLY: '輸入詳細資料',
            PHONE_NUMBER: '電話號碼',
            RELATIONSHIP_TO_THE_PROPERTY: '與地點的關係',
            NOTIFY_ON_CREATION: '通知建立',
            NOTIFY_ON_COMPLETION: '通知完成',
            NOTIFY_ON_ACCEPTANCE: '通知驗收',

            REPORTER_NAME: '回報者',

            EDIT_ALARM: '修改警報',
            SET_ALARM: '設定警報',
            ALARM_LOCKED: '鎖定',

            UNLIMITED: '不限',

            COLOUR: '顏色',
            NONE_SET: '不設定'
        });
    }
})();


app.config(function ($translateProvider) {
    $translateProvider.translations('zh-hk', {
        DESCRIPTION: '描述',
        ID: 'ID',
        NO: '號碼',
        NONE_SELECTED: '未選擇',
        PLEASE_SELECT: '請選擇選項',
        NOTES: '備忘錄',
        VALUE: '價值',
        YES: '是',
        NAME: '名稱',
        REFERENCE: '參考',
        EXTERNAL_REFERENCE: '外部參考',
        STATUS: '狀態',
        ADDRESS: '地址',
        PHONE: '電話',
        TYPE: '類別',
        LOCATION: '地點',
        EMAIL_ADDRESS: '電子郵件',
        INFO: '資訊',
        COMPANY: '公司',
        ALL: '所有',
        POSITION: '位置',
        NEW_CUSTOMER_ACCESS: '新客戶存取',
        FAILED_LOGIN_ATTEMPTS: '登入失敗',
        ACCOUNT_LOCKED: '用戶已鎖定',
        UNLOCK_USER: '解鎖用戶',
        ACCOUNT_UNLOCKED: '用戶已解鎖',
        IS_TEMPLATE: '是樣板',
        EMAIL: '電子郵件',
        ADDRESS_LINE_1: '地址列一',
        ADDRESS_LINE_2: '地址列二',
        ADDRESS_LINE_3: '地址列三',
        ADD_ADDRESS: '新增地址',
        GO: '繼續',
        REPORTER: '回報者',
        NOTE: '備忘錄',

        UNLIMITED: '不限',

        CURRENT_VERSION: '現時版本',
        NEW_VERSION: '新版本',

        DASHBOARD: '儀表板',
        ADMIN: '管理員',

        NO_NOTIFICATIONS: '你沒有通知',

        ADD_SUCCESS: '已成功加入',
        UPDATE_SUCCESS: '已成功更新',
        EDIT_SUCCESS: '已成功修改',
        DELETE_SUCCESS: '已成功刪除',
        REQUEST_ERROR: '發生錯誤,請稍後再試.',

        VIEW: '檢視',
        ADD: '增加',
        CREATE: '建立',
        EDIT: '修改',
        UPDATE: '更新',
        CANCEL: '取消',
        DELETE: '刪除',
        CLOSE: '關閉',
        RESET: '重設',
        CONFIRM: '確定',
        PREVIOUS: '上一個',
        DECLINED: '拒絕',
        DEACTIVATE: '停用',

        PARENT: '母',
        CHILDREN: '子',

        IS_ACTIVE: '狀態',
        STATUS_ACTIVE: '有效',
        STATUS_INACTIVE: '無效',

        ARE_YOU_SURE_HEADER: '你是否肯定?',
        ARE_YOU_SURE: '你是否肯定執行此操作?',
        NAVIGATION_CONFIRMATION: '你是否肯定要離開此頁面? 你所做任何的更改都將會失去.',

        BARCODE: '條碼',
        FILES: '檔案',

        ALL_SERVICE_PROVIDERS: '所有服務提供商',
        SELECTED_SERVICE_PROVIDERS: '選擇服務提供商',

        ALL_PARTNERSHIPS: '所有合作夥伴',
        SELECTED_PARTNERSHIPS: '選擇合作夥伴',

        TITLE: '標題',

        FOOTER_COPYRIGHT: "Copyright &copy; Vantify Limited {{ ::currentYear }} - Reg Company No. 05277497",

        ZERO_OPTIONS_CHECKED_TITLE: '未選擇項目',
        ZERO_OPTIONS_CHECKED_MESSAGE: '您必須選擇至少一個項目才能執行此操作',


        SERVICEPROVIDER: '服務提供商',
        STATUTORYTYPE: '法定類別',
        STATUTORY_TYPE: '法定類別',

        START_TYPING_TO_SEARCH: '開始輸入搜索',
        START_TYPING_TO_FILTER: '開始輸入篩選',

        ACTION_SUCCESS: '操作成功',
        ACTIVE: '有效',
        INACTIVE: '無效',

        LOCAL_SITE_TIME: '地點當地時間',

        WITH_ID: '<strong>ID</strong> {{ ::id }}',
        WITH_SITE: '<strong>地點</strong> {{ ::site }}',
        WITH_NAME: '<strong>名稱</strong> {{ ::name }}',
        WITH_SUMMARY: '<strong>總結</strong> {{ ::summary }}',
        WITH_REFERENCE: '<strong>參考</strong> {{ ::reference }}',
        WITH_CHILDREN: '<strong>子</strong> {{ ::children }}',
        WITH_ACTIVE: '<strong>有效</strong> {{ ::(active == "true" || active == true ? "YES" : "NO") | translate }}',

        CONTACT: '聯絡',
        CONTACTS: '聯絡',


        BACK: '返回',

        NO_RESULTS_FOUND: '未找到结果',
        PAGINATION_SHOWING: '顯示結果由 {{::from}} 至 {{::to}} 總結果 {{::total}}',
        DETAILS: '詳細資料',

        SUBMIT: '遞交',

        VISIBILITY_PUBLIC: '公用',
        VISIBILITY_HELPDESK: '服務台',

        INFORMATION: '資訊',
        SEND_EMAIL: '發送電子郵件',

        ATTENDANCE_DUE: '出勤到期',
        COMPLETION_DUE: '完成到期',
        CREATED_AT:     '建立於',

        ACTION_CANCEL: '取消',
        ACTION_COMPLETE: '完成',
        ACTION_RAISE_JOB: '上報工作',
        ACTION_RAISE_QUOTE: '上報價單',
        ADD_CONTRACT_TYPE: '添加聯絡類別',
        ADD_DEPARTMENT: '添加部門',
        ADD_NEW_CONTRACT_TYPE: '新增合約類別',
        ADD_NEW_DEPARTMENT: '新增部門',
        ADD_NEW_READING: '新增讀數',
        ADD_NEW_SUBMETER: '新增子計',
        ADD_NEW_TRADE_TYPE: '新增貿易類別',
        ADD_TRADE_TYPE: '添加貿易類別',
        ALL_USERS: '所有用戶',
        ASSIGNEE: '分配者',
        ATTACH_A_FILE: '附上檔案',
        ATTACHMENT: '附加檔案',
        ATTACHMENTS: '附加檔案',
        ATTENDANCE_DATE: '出席日期',
        ATTENDED: '出席',
        BY_PLANNED_DATE: '按計劃日期',
        CANCELLED: '取消',
        COMPLETED: '完成',
        COMPLETION_BY_DATE: '完成者',
        COMPLETION_DUE_BETWEEN: '完成到期',
        COMPOSE_CONVERSATION: '組合對話',
        CONDITION: '狀況',
        CONTRACT_APPROVAL: '合約審批',
        CONTRACT_TERMINATED: '合約中止',
        CONTRACT_TYPES: '合約類別',
        CONVERSATIONS: '對話',
        CREATE_AS_GLOBAL_TEMPLATE: '建立為全球樣板',
        CREATED: '已建立',
        DATE_CREATED: '日期已建立',
        DATE_READ: '閱讀日期',
        DELETE_SURVEY: '刪除調查',
        DEPARTMENTS: '部門',
        DONE: '完成',
        EDIT_CONTRACT: '修改合約',
        EDIT_CONTRACT_TYPE: '修改合約類別',
        EDIT_DEPARTMENT: '修改部門',
        EDIT_TRADE_TYPE: '修改貿易類別',
        EDITABLE: '可修改',
        EXPORT: '匯出',
        EXPORT_IN_PROCESS: '列隊匯出',
        GROUP: '組別',
        HARD_SERVICE: '硬件服務',
        HELPDESK_NOTES: '服務台備忘錄',
        HIDE: '隱藏',
        JOB_HISTORY_NOTE: '工作歷史備忘錄',
        JOB_MORE_MESSAGES: '更多訊息',
        JOB_NEXT_PLANNED_VISIT: '下次計劃查看',
        JOB_STATUS_: '狀態',
        LAST_MESSAGE: '最後訊息',
        LAST_SUBMITTED_BETWEEN: '最後遞交日期',
        LATEST_REACTIVE_JOBS: '最新再有效的工作',
        LEGEND_: '圖例',
        LOCATION_BASED_SECTION: '基於位置的部分',
        MONTHS: '月',
        'N/A': '不適用',
        NEW_JOB: '新工作',
        NEXT: '下一個',
        NO_ANSWER_OPTIONS: '沒有答案選項',
        NO_NOTE_PROVIDED: '沒有備忘錄',
        NO_REACTIVE_JOBS: '沒有再有效的工作',
        NO_SERVICE_PROVIDER_ENTERED: '沒有服務提供商',
        NO_SITE_ASSOCIATES_ASSIGNED: '沒有地點合作夥伴',
        NON_CHARGEABLE: '不收費',
        NONE: '沒有',
        ON_SITE_DATE: '到場日期',
        OOH_NOTES: 'OOOH 備忘錄',
        OOH_PHONE: 'OOH 電話',
        OPEN: '開啟',
        ORDER: '訂購',
        OVERDUE_NO: '否',
        OVERDUE_YES: '是',
        OWNER: '擁有者',
        PREV: '上一個',
        RAISED_BY: '回報者',
        READING: '檢閱',
        REQUEST: '請求',
        RISK: '風險',
        SATISFACTION_SURVEY: '滿意度調查',
        SAVE: '儲存',
        SCORE_RANGE: '分數範圍',
        SEARCH: '搜尋',
        SEARCH_SITE: '搜尋地點',
        SELECT: '選擇',
        SELECT_A_USER: '選擇用戶',
        SELECT_CONDITION: '選擇狀況',
        SELECT_DEPARTMENT: '選擇部門',
        SELECT_RISK: '選擇風險',
        SELECT_SURVEY: '選擇調查',
        SELECTED_USERS: '已選用戶',
        SEND: '發出',
        SERVICE: '服務',
        SERVICE_PROVIDED: '提供的服務',
        SHOW: '顯示',
        SKIP_THIS_TIME: '今次跳過',
        SOFT_SERVICE: '軟件服務',
        STATUS_DETAILS: '詳細狀態',
        SUBMITTED: '已遞交',
        SURVEY_RANGE: '範圍',
        SURVEY_WEIGHT: '重量',
        TARGET: '目標',
        TELEPHONE: '電話',
        TRADE_TYPES: '貿易類別',
        UNABLE_TO_CREATE_TENANT: '無法建立租戶',
        UNABLE_TO_GET_LOCATION: '無法獲取位置',
        UNABLE_TO_GET_TENANT: '無法獲取租戶',
        UNABLE_TO_UPDATE_TENANT: '無法更新租戶',
        UNSUBSCRIBE: '退訂',
        VIEW_NOTIFICATIONS_UNREAD_NEWER: '較新',
        VIEW_NOTIFICATIONS_UNREAD_OLDER: '較舊',
        WEBSITE: '網站',
        WEIGHT: '重量',
        WEIGHTED_SCORE: '比重分數',
        WRITE_A_MESSAGE: '寫訊息',
        FILE_ATTACHMENT: '檔案附件',
        COPY_ME: '發副本給自己',
        SUBJECT: '標題',
        TO: '給',
        EMAIL_S_SENT: '電子郵件已發送',
        SHOW_MORE: '顯示更多',
        SHOW_LESS: '顯示縮小',
        EDIT_RECIPIENTS: '修改收件人',
        RECIPIENTS: '收件人',
        VIEW_ALL: '顯示全部',
        WILL_CHASE_AT: '會跟進於',
        EXTENSION_DATE: '延期日期',
        I_AM_THE_APROVER: '審批者',
        I_AM_APPROVER: '審批者',
        MESSAGE_CREATED: '訊息已建立',
        ACCEPT: '接受',
        REJECT: '拒絕',
        DUE_DATE: '截止日期',
        FEET: '呎',

        IMAGE_FILE: '圖片',
    });
});
