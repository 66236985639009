angular.module('elogbooksServices').service('userManager', function ($rootScope, $interval, $translate, $window, $http, API_URL, $q, base64, lodash, apiClient, $state, messenger, modulesService) {

    'use strict';

    var self = this;
        this.skippedChangePassword = null;
        this.user = null;
        this.preferences = null;
        this.canAuthorise = false;
        this.originalUser = JSON.parse($window.localStorage.getItem('originalUser'));
    
    this.sanitizeCredentials = function(userCredentials) {
        let sanitizedCredentials = { ...userCredentials };

        if (sanitizedCredentials.username) {
            sanitizedCredentials.username = sanitizedCredentials.username.replace(/\s+/g, '');
        }

        return sanitizedCredentials;
    }

    this.login = function (userCredentials) {
        return $http.post(API_URL + '/auth', this.sanitizeCredentials(userCredentials)).then(function (response) {
            $window.localStorage.setItem('token', response.data.token);
            $window.localStorage.setItem('tokenExpiration', response.data.expiration*1000);

            return $http.get(API_URL + '/').then(function (response) {
                self.skippedChangePassword = false;
                $window.localStorage.setItem('userResource', response.data._links.user.href);
                $window.localStorage.setItem('tokenLink', response.data._links.token.href);

                return true;
            }, function () {
                // unable to call "/" resource
                return false;
            });
        }, function (failResponse) {
            return lodash.has(failResponse, 'data.message') ? failResponse.data.message : false;
        });
    };

    this.getUserResourceAndTokenLink = function () {
        return $http.get(API_URL + '/').then(function (response) {
            self.skippedChangePassword = false;
            $window.localStorage.setItem('userResource', response.data._links.user.href);
            $window.localStorage.setItem('tokenLink', response.data._links.token.href);

            return true;
        }, function () {
            return false;
        });
    };

    this.clearCache = function () {
        var toRemove = [];
        var i;

        // Removing while iteration will change the length of storage
        for (i = 0; i < $window.localStorage.length; i++){
            if ($window.localStorage.key(i).substring(0, 6) == 'cache_') {
                toRemove.push($window.localStorage.key(i));
            }
        }

        for (i = 0; i < toRemove.length; i++){
            $window.localStorage.removeItem(toRemove[i]);
        }

        // Unset the array
        toRemove = [];
    };

    this.clearExpiredCache = function () {
        var toRemove = [];
        var i;

        // Removing while iteration will change the length of storage
        for (i = 0; i < $window.localStorage.length; i++){
            var key = $window.localStorage.key(i);
            if (key.substring(0, 6) == 'cache_') {
                var cacheData = JSON.parse($window.localStorage.getItem(key));

                if (new Date(cacheData.expireAt) < new Date()) {
                    toRemove.push(key);
                }
            }
        }

        for (i = 0; i < toRemove.length; i++){
            $window.localStorage.removeItem(toRemove[i]);
        }

        // Unset the array
        toRemove = [];
    };

    this.loginAsUser = function (targetUser, config) {
        $interval.cancel($rootScope.refreshToken);

        try {
            window.stop();
        } catch (e) {
            document.execCommand('Stop');
        }

        self.clearCache();
        self.skippedChangePassword = true;

        return $http.post(API_URL + targetUser._links.token.href, config).then(function (response) {
            self.originalUser = {
                'token': $window.localStorage.getItem('token'),
                'name': self.user.name,
                'tokenLink': $window.localStorage.getItem('tokenLink')
            };

            $window.localStorage.setItem('originalUser', JSON.stringify(self.originalUser));
            $window.localStorage.setItem('token', response.data.token);

            return $http.get(API_URL + '/').then(function (response) {
                    $window.localStorage.setItem('userResource', response.data._links.user.href);
                    $window.localStorage.setItem('tokenLink', response.data._links.token.href);
                    self.user = self.getUser();

                    if ((self.hasPermission("site_permission_helpdesk", targetUser)) && (!self.hasPermission("user_permission_manage_users"))) {
                        self.getPreferences(true).then(function () {
                            $state.go('dashboard.helpdesk.dashboard.quotes.chase', {}, { reload: true });
                        })
                    } else {
                        $state.go('dashboard.user.dashboard', { reloadUserPreferences: true }, { reload: true });
                    }

                    return true;
                }, function() {
                    return false;
                });
        }, function() {
            return false;
        });
    };

    this.revertToOriginalUser = function() {
        var itemsToRemove = [
            'userIdFilter',
            'userIdName',
            'userIdFilterName',
            'userFilterName',
            'siteAssociateTypeFilterName',
            'siteAssociateTypeFilter',
            'regionFilter',
            'regionFilterName',
            'sp-for-feedback'
        ];

        itemsToRemove.forEach(function (item) {localStorage.removeItem(item);});
        delete $rootScope.feedbackRequired;
        self.originalUser = JSON.parse($window.localStorage.getItem('originalUser'));
        $interval.cancel($rootScope.refreshToken);
        $window.localStorage.setItem('token', self.originalUser.token);
        $window.localStorage.setItem('tokenLink', self.originalUser.tokenLink);

        try {
            window.stop();
        } catch (e) {
            document.execCommand('Stop');
        }

        self.clearCache();

        return $http.get(API_URL + '/')
            .then(function (response) {
                $window.localStorage.removeItem('originalUser');
                self.originalUser = null;
                $window.localStorage.setItem('userResource', response.data._links.user.href);
                self.user = self.getUser();

                $state.go('dashboard.user.dashboard', { reloadUserPreferences: true }, { reload: true });

                return true;
            }, function() {
                return false;
            });
    };

    this.getUser = function (params) {
        if (!$window.localStorage.getItem('userResource')) {
            return $q.reject().promise;
        }

        return apiClient.get($window.localStorage.getItem('userResource'), params, false)
            .then(function (response) {
                self.user = response;
                $window.localStorage.setItem('userPreferencesUrl', response.getLink('preferences'));
                return response;
            }, function () {
                // unable to call the user resource
                return false;
            });
    };

    this.getPreferences = function (reload) {
        return new Promise((resolve, reject) => {
            const preferencesUrl = $window.localStorage.getItem('userPreferencesUrl');
            if (!preferencesUrl) {
                return resolve(null); // No preferences URL stored
            }
    
            if (!reload) {
                const storedPreferences = $window.localStorage.getItem('userPreferences');
                if (storedPreferences && storedPreferences !== 'undefined') {
                    try {
                        self.preferences = angular.fromJson(storedPreferences);
                        return resolve(self.preferences); // Resolve with the stored preferences
                    } catch (error) {
                        return reject(new Error('Failed to parse stored preferences'));
                    }
                }
            }
    
            apiClient.get(preferencesUrl, null, true)
                .then(response => {
                    self.preferences = response.userPreferences;
                    $window.localStorage.setItem('userPreferences', angular.toJson(self.preferences));
                    resolve(response);
                })
                .catch(error => {
                    console.error('Unable to call the user resource', error);
                    reject(error); // Reject the promise in case of an error
                });
        });
    };

    this.getPreference = function(name) {
        return lodash.find(self.preferences, { name : name });
    };

    this.setPreferenceOption = function (name, value) {
        const index = lodash.findIndex(self.preferences, { name });
        if (index >= 0) {
            self.preferences[index].value = value;
        } else {
            self.preferences.push({
                name: name,
                value: value,
            });
        }
        $window.localStorage.setItem('userPreferences', angular.toJson(self.preferences));
    };

    this.getPreferenceValue = function(name) {
        var pref = self.getPreference(name);

        return pref && typeof pref.value !== 'undefined' ? pref.value : null;
    };

    this.getPreferenceOptions = function() {
        return {
            emailContentOptions : [
                { title: $translate.instant('USER_PREFERENCE_TEXT'), value: 'text' },
                { title: $translate.instant('USER_PREFERENCE_HTML'), value: 'html' }
            ],
            notificationMethodOptions : [
                { title: $translate.instant('USER_PREFERENCE_ALL'), value: 'all' },
                { title: $translate.instant('USER_PREFERENCE_NOTIFICATION'), value: 'notification' },
                { title: $translate.instant('USER_PREFERENCE_EMAIL'), value: 'email' }
            ]
        };
    };

    this.getUrl = function () {
        return $window.localStorage.getItem('userResource');
    };

    this.logout = function () {
        delete $rootScope.feedbackRequired;
        $window.localStorage.removeItem('token');
        $window.localStorage.removeItem('tokenLink');
        $window.localStorage.removeItem('tokenExpiration');
        $window.localStorage.removeItem('userResource');
        $window.localStorage.removeItem('userPreferences');
        $window.localStorage.removeItem('userPreferencesUrl');
        $window.localStorage.removeItem('selectedSiteResource');
        $window.localStorage.removeItem('originalUser');
        $window.localStorage.removeItem('settings');
        $window.localStorage.removeItem('settingsUrl');
        $window.localStorage.removeItem('siteAssociateTypeFilter');
        $window.localStorage.removeItem('regionFilter');
        $window.localStorage.removeItem('userFilter');
        $window.localStorage.removeItem('userSelected');
        $window.localStorage.removeItem('siteFilter');
        $window.localStorage.removeItem('siteFilterName');
        $window.localStorage.removeItem('regionFilterName');
        $window.localStorage.removeItem('rootResource');
        $window.localStorage.removeItem('sp-for-feedback');

        self.clearCache();

        self.originalUser = null;
    };

    this.getToken = function () {
        return $window.localStorage.getItem('token');
    };

    this.setUser = function (user) {
        self.user = user;

        self.allowAuthorisation();
    };

    this.allowAuthorisation = function () {
        if (! self.canAuthorise) {
            $rootScope.$on('$stateChangeStart', function (event, state, params) {
                var hasAccess = false;

                if (state.data && state.data.module) {
                    if (! modulesService.getEnabled(state.data.module)) {
                        event.preventDefault();
                        return self.authoriseModule(state.data.module);
                    }
                }

                if (state.data && state.data.authorisations) {
                    angular.forEach(state.data.authorisations, function (authorisation) {
                        if (self.hasPermission(authorisation)) {
                            hasAccess = true;
                        }
                    });

                    if (!hasAccess) {
                        event.preventDefault();
                        return self.authorise(state.data.authorisations);
                    }
                }
            });

            if ($state.current.data && $state.current.data.module) {
                self.authoriseModule($state.current.data.module);
            }

            if ($state.current.data && $state.current.data.authorisations) {
                self.authorise($state.current.data.authorisations);
            }
        }

        self.canAuthorise = true;
    };

    this.authoriseModule = function (module) {
        if (! modulesService.getEnabled(module)) {
            return $state.go('dashboard.user.dashboard', {}, { reload: true }).then(function () {
                messenger.error('NO_MODULE_ACCESS');
            });
        }

        return;
    };

    this.authorise = function (permissions) {
        var hasAccess = false;

        angular.forEach(permissions, function (permission) {
            if (self.hasPermission(permission)) {
                hasAccess = true;
            }
        });

        if (!hasAccess) {
            return $state.go('dashboard.user.dashboard', {}, {reload: true}).then(function () {
                messenger.error('NO_ACCESS');
            });
        }

        return;
    };

    this.hasPermission = function (permissionArg, user) {
        if (angular.isArray(permissionArg)) {
            for (var i in permissionArg) {
                if (self.hasPermission(permissionArg[i])) {
                    return true;
                }
            }

            return false;
        }

        var permission = String(permissionArg).toLowerCase();

        if (! user) {
            user = self.user;
            if (! user) {
                return false;
            }
        }

        if (user.permissions && user.permissions.indexOf(permission) !== -1) {
            return true;
        } else if (user.permissionDependencies && user.permissionDependencies.indexOf(permission) !== -1) {
            return true;
        } else if (user.permissionsInherited && user.permissionsInherited.indexOf(permission) !== -1) {
            return true;
        } else if (user.permissionsInheritedDependencies && user.permissionsInheritedDependencies.indexOf(permission) !== -1) {
            return true;
        } else if (user.defaultSiteMembershipPermissions && user.defaultSiteMembershipPermissions.indexOf(permission) !== -1) {
            return true;
        } else if (user.defaultOperativePermissions && user.defaultOperativePermissions.indexOf(permission) !== -1) {
            return true;
        }

        return false;
    };

    /**
     * Returns true ifa form has been flagged as dirty
     *
     * @returns {boolean}
     */
    this.checkIsDirty = function () {
        var forms = document.getElementsByTagName('FORM');
        var dirtyForm = false;

        //Check all forms on page to see if any of them have been submitted or are dirty.
        angular.forEach(forms, function(value) {
            var form = angular.element(value);

            //Check for any dirty forms on page
            if(!$rootScope.skipDirtyCheck &&
                typeof form.attr('data-ignore-dirty') === 'undefined' &&
                form.hasClass('ng-dirty')
            ) {
                dirtyForm = true;
            }
        });

        //skipDirtyCheck overrides forceDirtyConfirm. The reason for this is you might want a force confirm to work on
        //everything but a specific function/button/workflow e.g. MultiPageForm Next/Prev
        if (!$rootScope.skipDirtyCheck && $rootScope.forceDirtyConfirm) {
            dirtyForm = true;
        }

        return dirtyForm;
    };

    /**
     * Returns true if a form has been submitted
     *
     * @returns {boolean}
     */
    this.checkIsFormSubmitted = function () {
        var forms = document.getElementsByTagName('FORM');
        var submittedForm = false;

        //Check all forms on page to see if any of them have been submitted or are dirty.
        angular.forEach(forms, function(value) {
            var form = angular.element(value);

            //Check for any submitted forms on page
            if(form.hasClass('ng-submitted')) {
                submittedForm = true;
            }
        });

        return submittedForm;
    };

    this.getUserInitials = function(user) {
        if (!user) {
            return '';
        }

        // Split the name into words
        const words = user.name.split(' ');

        if (words.length >= 2) {
            return (words.slice(0, 2).map(word => word.charAt(0))).join('');
        }

        return words[0].slice(0, 2).toUpperCase();
    };
});
