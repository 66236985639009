/**
 * Service Provider Controller
 */
(function () {
    'use strict';

    angular
        .module('elogbooks.user.service-providers')
        .controller('UserServiceProvidersListController', UserServiceProvidersListController);

    UserServiceProvidersListController.$inject = [
        '$state',
        'serviceProviderCollection',
        '$stateParams',
        '$translate',
        'rootResourceResponse',
        'user',
        'elbSettings',
        '$filter',
        'serviceProviderGroupsResponse',
        'paramConverterService',
        'serviceProviderService',
        'analyticsService',
        '$scope'
    ];

    function UserServiceProvidersListController(
        $state,
        serviceProviderCollection,
        $stateParams,
        $translate,
        rootResourceResponse,
        user,
        elbSettings,
        $filter,
        serviceProviderGroupsResponse,
        paramConverterService,
        serviceProviderService,
        analyticsService,
        $scope
    ) {
        var vm = this;
        vm.serviceProviders = serviceProviderCollection.serviceproviders;
        vm.serviceProviderCollectionResponse = serviceProviderCollection;
        vm.config = {};

        vm.search = search;
        vm.order = order;
        vm.saveParams = saveParams;
        vm.listAll = $stateParams.listAll || false;

        if (vm.listAll) {
            vm.config.allServiceProviderTab = true;
        }

        vm.prosureEnabled = !!elbSettings.getSetting('prosure_enabled').value;
        vm.spFeedbackEnabled = elbSettings.getSetting('sp_feedback_enabled').value;

        var deregisterWatch = $scope.$watch('vm.listAll', function(value) {
            analyticsService.pageLoaded();
            deregisterWatch();
        });

        var statusOptions = [
            {title: $filter('translate')('NONE_SELECTED'), value: null},
            {title: $filter('translate')('STATUS_ACTIVE'), value: 'active'},
            {title: $filter('translate')('STATUS_INACTIVE'), value: 'inactive'},
            {title: $filter('translate')('STATUS_TRAINING'), value: 'training'}
        ];

        var contractorApprovedOptions = [
            {title: $translate.instant('NONE_SELECTED'), value: null},
            {title: $translate.instant('YES'), value: '1'},
            {title: $translate.instant('NO'), value: '0'}
        ];

        vm.criteria = {
            name: {type: 'text', title: 'NAME', value: $stateParams.name},
            reference: {type: 'text', title: 'REFERENCE', value: $stateParams.reference},
            serviceProvidersTelephone: {type:'text', title: 'TELEPHONE', value: $stateParams.serviceProvidersTelephone},
            serviceProvidersEmail: {type:'text', title: 'EMAIL', value: $stateParams.serviceProvidersEmail},
            serviceProvidersAddress: {type:'text', title: 'ADDRESS', value: $stateParams.serviceProvidersAddress}
        };

        if (!vm.listAll) {
            vm.criteria.status = {
                type: 'options',
                title: 'STATUS',
                value: $stateParams.status,
                options: statusOptions
            };
        }

        if (vm.listAll && serviceProviderGroupsResponse && serviceProviderGroupsResponse.groups.length !== 0) {
            vm.serviceProviderGroupsSelect = {
                link: serviceProviderGroupsResponse.getLink('self'),
                responseKeyPath: 'groups',
                itemValuePath: 'name',
                itemHrefPath: 'id',
                onRemove: vm.saveParams,
                onSelect: vm.saveParams,
                mapValue: 'serviceProviderGroups',
                autocompletemulti: true,
                advanced: true
            };

            vm.criteria.serviceProviderGroups = {
                type: 'autocompletemulti',
                multiple: true,
                title: 'SERVICE_PROVIDER_GROUPS',
                value: $stateParams.serviceProviderGroups,
                options: vm.serviceProviderGroupsSelect,
                param: 'serviceProviderGroups',
                max: 50
            };
        }

        if (vm.prosureEnabled) {
            vm.criteria.prosure360Status = {
                type: 'options',
                title: 'VANTIFY_SUPPLY_CHAIN_STATUS',
                multiple: true,
                value:  paramConverterService.checkStateParams($stateParams.prosure360Status),
                options: serviceProviderService.prosure360StatusOptions
            };
        }

        vm.loadedCollections = [serviceProviderCollection];

        vm.resources = {
            'rootResource': rootResourceResponse,
            'stateParams': $stateParams,
            'user': user
        };

        var params = {};

        function saveParams(selectModel) {
            params = paramConverterService.saveParams(selectModel, params);
        }

        function search(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), {reload: $state.current});
        }

        function order(key) {
            $stateParams.order = key;

            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override), {reload: $state.current});
        }
    }
})();
