(function () {
    'use strict';

    angular.module('elogbooks.common.serviceproviders').controller('ServiceProviderFeedbackController', ServiceProviderFeedbackController);

    function ServiceProviderFeedbackController(config, modalData, rootResource, apiClient, messenger, $uibModalInstance, $uibModalStack, $rootScope) {
        var vm = this;
        vm.submit = submit;
        vm.data = {};
        vm.submitDisabled = false;
        vm.config = config;
        vm.serviceProviderName = modalData.serviceProviders[0].name;
        vm.plannedJobCount = modalData.serviceProviders[0].plannedJobCount;
        vm.reactiveJobCount = modalData.serviceProviders[0].reactiveJobCount;
        vm.interval = modalData.serviceProviders[0].interval;
        $uibModalStack.dismissAll();

        function submit() {
            vm.submitDisabled = true;
            var data = {feedbackScore: vm.data.rating, serviceProvider: modalData.serviceProviders[0].id};

            if (vm.data.comment) {
                if (vm.data.comment.length > 255) {
                    messenger.error('COMMENT_INVALID_LENGTH');
                    vm.submitDisabled = false;

                    return;
                }

                data.comment = vm.data.comment;
            }

            apiClient.create(rootResource.getLink('post-sp-feedback'), data).then(function (response) {
                if (response) {
                    messenger.success('THANK_YOU_FOR_FEEDBACK');
                }

                $rootScope.feedbackRequired = false;
                localStorage.removeItem('sp-for-feedback');
                vm.submitDisabled = false;
                $uibModalInstance.dismiss();
                setTimeout(function () {
                    $rootScope.$broadcast('updateTooltip', {});
                }, 500);
            });
        }
    }
})();
