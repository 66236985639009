app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        LOCATION: 'Location',
        LOCATIONS: 'Locations',

        LOCATION_ADD: 'Add a new Location',
        LOCATION_EDIT: 'Edit Location',
        LOCATION_LIST: 'Locations',
        LOCATION_ENTER: 'Enter a location name...',

        LOCATION_CREATED: 'Location created',
        LOCATION_UPDATED: 'Location updated',
        LOCATION_DELETED: 'Location deleted',

        LOCATION_NAME: 'Location name',

        PARENT_LOCATION: 'Parent Location',
        NEW_LOCATION: 'New Location',
        LOCATION_ADD_PARENT: 'Add a Parent Location',
        LOCATION_ADD_CHILD: 'Add a Child Location',

        LOCATION_NOTES: 'Location Notes',
        LOCATION_NOTE_UPDATED: 'Location Note Updated',
        LOCATION_NOTE_CREATED: 'Location Note Created',

        LOCATION_LOCKED: 'You cannot update the location at this stage',
        CHILD_LOCATIONS_COUNT: 'Child Locations'
    });
});
