(function() {
    'use strict';

    angular
        .module('elogbooks.common.files')
        .controller('CommonFilesDetailsController', CommonFilesDetailsController);

    CommonFilesDetailsController.$inject = [
        'fileResponse',
        'elbDownloadService',
        'elbSettings',
        'config',
        '$uibModal',
        'userManager',
        '$translate'
    ];

    function CommonFilesDetailsController(
        fileResponse,
        elbDownloadService,
        elbSettings,
        config,
        $uibModal,
        userManager,
        $translate
    ) {
        var vm = this;
        vm.fileResponse = fileResponse;
        vm.download = downloadAction;
        vm.config = config;
        vm.integrations = getIntegrations();
        vm.fileExpiryNotificationTime =  elbSettings.getSetting('file_expiry_notification_time').value;
        vm.newMeridianWorkflow = elbSettings.getSetting('general_meridian_workflow').value;
        vm.editFiles = userManager.hasPermission('site_permission_edit_files');
        vm.siteIsMeridianEnabled = fileResponse.sendToMeridianAvailable;

        if (!vm.config.hideEdit && fileResponse.type === 'Export') {
            vm.config.hideEdit = true;
        }

        function downloadAction() {
            return elbDownloadService.download(vm.fileResponse);
        }

        vm.sendToMeridianDisabled = false;
        function getIntegrations() {
            var integrations = vm.fileResponse.integrations;

            if (integrations.length > 0) {
                var usedIntegrations = [];

                integrations.forEach(function(integration) {
                    if (integration.type === 'meridian') {
                        vm.sendToMeridianDisabled = true;
                    }

                    usedIntegrations.push($translate.instant(integration.type.toUpperCase()));
                });

                return usedIntegrations.join(', ');
            } else {
                return null;
            }
        }

        getIntegrations();

        vm.sendToMeridian = vm.newMeridianWorkflow && vm.editFiles && vm.siteIsMeridianEnabled && function() {
            return !vm.sendToMeridianDisabled && $uibModal.open({
                templateUrl: '/modules/common/files/modal/send-to-meridian/send-to-meridian-form.html',
                size: 'md',
                controller: 'SendToMeridianForm',
                controllerAs: 'vm',
                resolve: {
                    fileResponse: () => fileResponse,
                    meridianDocTypes: (apiClient) => {
                        return apiClient.get(fileResponse.getLink('integration-doc-types')).then(function(integrationDocTypesResponse) {
                            let integrationResponse = null;
                            angular.forEach(integrationDocTypesResponse.data, (integration) => {
                                //Only set meridian
                                if(integration.integration === "meridian") {
                                    integrationResponse = integration.docTypes;
                                }
                            });
                            return integrationResponse || apiClient.noResourceFound();
                        });
                    }
                }
            });
        }
    }
})();
