(function() {
    'use strict';

    angular
        .module('elogbooks.common.assets')
        .controller('UserAssetsSubAssetsController', UserAssetsSubAssetsController);

    UserAssetsSubAssetsController.$inject = ['$state', '$stateParams', 'subAssetCollectionResponse', 'assetResponse', 'config'];

    function UserAssetsSubAssetsController($state, $stateParams, subAssetCollectionResponse, assetResponse, config) {
        var vm = this;
            vm.subAssets = subAssetCollectionResponse.subassets;
            vm.subAssetCollectionResponse = subAssetCollectionResponse;
            vm.hideAdd = $state.current.name.indexOf('jobs.list.details.sub-assets') > 0;
            vm.pages = subAssetCollectionResponse.pages;

        vm.criteria = {
            subAssetName: { type: 'text', title: 'NAME', value: $stateParams.subAssetName },
            subAssetReference: {type: 'text', title: 'REFERENCE', value: $stateParams.subAssetReference}
        };

        vm.config = config;
        vm.type = config.entityName ? 'view' : 'list';
        vm.title = config.entityName === 'JOB' ? assetResponse.name : null;

        vm.search = searchAction;
        vm.order = orderAction;

        function searchAction(params) {
            var override = {
                subAssetPage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function orderAction(key) {
            $stateParams.subAssetOrder = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }
    }
})();
