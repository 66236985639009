angular.module('elogbooksServices').service('jobTypeService', function($translate) {

    var jobTypes = {
        planned: "Planned",
        reactive: "Reactive",
        comprehensive: "Comprehensive",
        additional: "Additional",
        management: "Management",
        patrol: "Patrol"
    };

    this.getAll = function () {
        return jobTypes;
    }

    this.getFilterOptions = function () {
        return [
            {
                title: $translate.instant('NONE_SELECTED'),
                value: null
            },
            {
                title: $translate.instant('JOB_TYPE_REACTIVE'),
                value: 'reactive'
            },
            {
                title: $translate.instant('JOB_TYPE_PLANNED'),
                value: 'planned'
            }
        ];
    }

    return this;
});
