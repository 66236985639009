(function () {
    'use strict';

    angular
        .module('elogbooks.common.jobs.workflow')
        .directive('jobCompleteLabourLinesList', function () {
            return {
                restrict: 'E',
                controller: JobCompleteLabourLinesListController,
                controllerAs: 'vm',
                scope: {
                    jobLabourLinesCollectionResponse: '<',
                    userResponse: '<',
                    jobResponse: '<',
                    serviceProviderResponse: '<',
                    form: '<',
                    labourLinesFormModel: '=',
                },
                bindToController: true,
                template: '<input type="hidden" name="labourLines" data-ng-model="labourLinesFormModel" />' +
                    '<div data-ng-include="\'/modules/common/labour-lines/labour-lines-list.html\'"></div>',
            };
        });

    angular
        .module('elogbooks.common.jobs.workflow')
        .controller('CommonLabourLinesListController', JobCompleteLabourLinesListController);

    JobCompleteLabourLinesListController.$inject = ['modulesService', 'apiClient', '$uibModal', 'confirmationModal', 'messenger', '$scope', 'fetchJobLabourLinesOperativesService'];

    function JobCompleteLabourLinesListController(modulesService, apiClient, $uibModal, confirmationModal, messenger, $scope, fetchJobLabourLinesOperativesService) {
        const vm = this;
        vm.financeEnabled = modulesService.isEnabled('finance');
        vm.isJobComplete = true;
        vm.addEditModal = null;

        let fetchOperativesPromise = null;

        const deregisterWatch = $scope.$watchGroup(['vm.serviceProviderResponse', 'vm.jobLabourLinesCollectionResponse', 'vm.form'], function (newValues) {
            const [serviceProviderResponse, jobLabourLinesCollectionResponse, form] = newValues;

            if (serviceProviderResponse && jobLabourLinesCollectionResponse && form) {
                addLabourLinesFormValidator(serviceProviderResponse, jobLabourLinesCollectionResponse, form);
                fetchOperativesPromise = fetchJobLabourLinesOperativesService.fetchOperatives(vm.userResponse, vm.serviceProviderResponse, vm.jobResponse);
                deregisterWatch();
            }
        });

        function addLabourLinesFormValidator(serviceProviderResponse, jobLabourLinesCollectionResponse, form) {
            serviceProviderResponse.requireLabourLineOnJobComplete && $scope.$watch('vm.jobLabourLinesCollectionResponse', function (data) {
                form.labourLines.$invalid = serviceProviderResponse.requireLabourLineOnJobComplete && data.count === 0;
                form.labourLines.$valid = !form.labourLines.$invalid;

                form.$setValidity('required-labour-lines', form.labourLines.$valid);
            });
        }

        vm.removeLine = (jobLabourResponse) => {
            confirmationModal.open().result.then(function (response) {
                if (response.result) {
                    jobLabourResponse.isRemoving = true;

                    apiClient.delete(jobLabourResponse.getLink('delete')).then(function (isSuccess) {
                        if (isSuccess) {
                            messenger.success('JOB_LABOUR_DELETED');

                            vm.jobLabourLinesCollectionResponse.jobLabourLines = vm.jobLabourLinesCollectionResponse.jobLabourLines.filter(
                                item => item.id !== jobLabourResponse.id
                            );

                            vm.jobLabourLinesCollectionResponse.count = vm.jobLabourLinesCollectionResponse.jobLabourLines.length;
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
            });
        }
        vm.openAddEditModal = (jobLabourResponse = {}) => {
            vm.addEditModal = $uibModal.open({
                templateUrl: '/modules/common/labour-lines/add-edit/labour-lines-add-edit.html',
                controller: 'CommonLabourLinesAddEditController',
                controllerAs: 'vm',
                resolve: {
                    userResponse: vm.userResponse,
                    serviceProviderResponse: vm.serviceProviderResponse,
                    jobLabourResponse: jobLabourResponse,
                    jobLabourLinesCollectionResponse: vm.jobLabourLinesCollectionResponse,
                    labourTypeCollectionResponse: null,
                    jobCompleteLabourLinesController: vm,
                    operativeCollectionResponse: fetchOperativesPromise
                }
            });

            vm.addEditModal.result.then(function (result) {
                if (result?.action) {
                    switch (result.action) {
                        case 'create':
                            vm.jobLabourLinesCollectionResponse.jobLabourLines.push(result.entity);
                            break;
                        case 'edit':
                            const index = vm.jobLabourLinesCollectionResponse.jobLabourLines.findIndex(
                                item => item.id === result.entity.id
                            );

                            if (index >= 0) {
                                vm.jobLabourLinesCollectionResponse.jobLabourLines[index] = result.entity;
                            }

                            break;
                        case 'delete':
                            vm.jobLabourLinesCollectionResponse.jobLabourLines = vm.jobLabourLinesCollectionResponse.jobLabourLines.filter(
                                item => item.id !== result.entity.id
                            );

                            break;
                    }

                    vm.jobLabourLinesCollectionResponse.count = vm.jobLabourLinesCollectionResponse.jobLabourLines.length;
                }
            });
        }
    }
})();
