angular.module('elogbooksDirectives').directive('dateTimeRangeSearchCriteria', function (moment, $rootScope) {
    return {
        restrict: 'AE',
        scope: {
            options: '='
        },
        templateUrl: '/modules/directives/search-criteria/date-time-range-search-criteria.html',
        controller: function($scope, $stateParams, $rootScope) {
            $scope.inputOptionValue = null;
            $scope.disableDatePickers = false;
            $scope.inputValueStartDate = null;
            $scope.inputValueEndDate = null;

            const enableTime = $scope.$parent.criterion.hasOwnProperty('enableTime') ? $scope.$parent.criterion.enableTime : true;

            if (!$scope.hasOwnProperty('options')) {
                $scope.options = null;
            } else {
                const criterion = $scope.$parent.criterion;
                if (criterion.hasOwnProperty('optionParam') && criterion.hasOwnProperty('defaultOption')) {
                    if ($stateParams[criterion.optionParam] === null) {
                        $scope.inputOptionValue = $scope.currentOption = parseInt(criterion.defaultOption, 10);
                    } else {
                        $scope.inputOptionValue = $scope.currentOption = parseInt($stateParams[criterion.optionParam], 10);
                    }
                }

                if ($scope.inputOptionValue) {
                    $scope.disableDatePickers = true;
                }
            }

            // data passed from criterion options (vm.criteria) - default values
            var data = angular.extend({
                // date default values - Date object
                startDate: null,
                endDate: null,

                startDateLabel: 'DATE_FROM_LABEL',
                endDateLabel: 'DATE_TO_LABEL',

                datePickerOptions: {
                    showWeeks: false
                },
                timePickerOptions: {
                    showMeridian: false
                },

                startDateRequired: true,
                endDateRequired: true,

                // min/max date - Date object
                minDate: null,
                maxDate: null,

                placeholder: enableTime ? 'DD/MM/YYYY HH:MM' : 'DD/MM/YYYY',

                // it uses format readable by datetime-picker component
                dateFormatInputs: enableTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy',

                // it uses momentjs format to display date
                dateFormatFiltersBar: enableTime ? 'DD/MM/yyyy HH:mm' : 'DD/MM/yyyy',

                enableTime: enableTime,
                startDefaultTime: enableTime ? '12:00:00' : '00:00:00',
                endDefaultTime: enableTime ? '12:00:00' : '23:59:59',

            }, $scope.$parent.criterion);

            if (data.type !== 'date-time-range') {
                throw new Error('Directive can be used only inside searchCriteria directive');
            }

            $scope.config = data;
            $scope.config.startDatePickerOptions = Object.create(data.datePickerOptions);
            $scope.config.endDatePickerOptions = Object.create(data.datePickerOptions);

            if (data.minDate) {
                $scope.config.startDatePickerOptions.minDate = data.minDate;
                $scope.config.endDatePickerOptions.minDate = data.minDate;
            }

            if (data.maxDate) {
                $scope.config.startDatePickerOptions.maxDate = data.maxDate;
                $scope.config.endDatePickerOptions.maxDate = data.maxDate;
            }

            // contains value witch would be stored in the searching params
            var storedValue = '';

            // contains string with dates displayed for the user
            var displayValue = '';

            // Updates values if filter is already set
            if (data.value && data.value.length && /.*,.+/i.test(data.value)) {
                var dates = data.value.split(',');

                if (dates && dates.length === 2) {
                    if (dates[0]) {
                        $scope.inputValueStartDate = new Date(dates[0]);
                    }

                    if (dates[1]) {
                        $scope.inputValueEndDate = new Date(dates[1]);
                    }

                    updateCriteriaValue();
                    // display value in the filters bar button
                    $scope.$parent.$parent.searchCriterionCtrl.displayValue = displayValue;
                }
            }

            // X button
            $scope.clear = function(type) {
                if (type == 'inputValueStartDate') {
                    $scope.inputValueStartDate = null;
                }

                if (type == 'inputValueEndDate') {
                    $scope.inputValueEndDate = null;
                }
            }

            $scope.onOptionChange = value => {
                if (value) {
                    setDateRangeByOption(value);
                    $scope.disableDatePickers = true;
                } else {
                    $scope.inputValueStartDate = $scope.inputValueEndDate = null;
                    $scope.disableDatePickers = false;
                }

                $scope.currentOption = value;
                $scope.$parent.criterion.currentOption = value;
            }

            $scope.wrapperClickHandling = $event => {
                if ($event.target.id === 'dateFrom') {
                    $scope.dateFromIsOpen2 = false;
                } else if ($event.target.id === 'dateTo') {
                    $scope.dateFromIsOpen = false;
                } else {
                    $scope.dateFromIsOpen2 = false;
                }
            }

            function updateCriteriaValue() {

                var value = '',
                    display = '';

                if ($scope.inputValueStartDate) {
                    value = moment($scope.inputValueStartDate).second(0).milliseconds(0).toISOString(true);
                    display = moment($scope.inputValueStartDate).format(data.dateFormatFiltersBar);

                    $scope.config.endDatePickerOptions.minDate = $scope.inputValueStartDate;
                } else {
                    $scope.config.endDatePickerOptions.minDate = data.minDate;
                }

                if ($scope.inputValueStartDate && $scope.inputValueEndDate) {
                    display += ' - ';
                }

                if ($scope.inputValueEndDate) {
                    value += ',' + moment($scope.inputValueEndDate).second(59).milliseconds(999).toISOString(true);
                    display += moment($scope.inputValueEndDate).format(data.dateFormatFiltersBar);

                    $scope.config.startDatePickerOptions.maxDate = $scope.inputValueEndDate;
                } else if (data.maxDate === null) {
                    delete $scope.config.startDatePickerOptions.maxDate;
                } else {
                    $scope.config.startDatePickerOptions.maxDate = data.maxDate;
                }

                storedValue = value;
                displayValue = display;

                $scope.$parent.$parent.searchCriterionCtrl.canUpdate = true;

                // validate required values
                if ((!$scope.inputValueStartDate && data.startDateRequired)
                    || (!$scope.inputValueEndDate && data.endDateRequired)
                ) {
                    storedValue = null;
                    displayValue = null;

                    $scope.$parent.$parent.searchCriterionCtrl.canUpdate = false;
                }
            }

            $scope.$watch('inputValueStartDate', updateCriteriaValue);
            $scope.$watch('inputValueEndDate', updateCriteriaValue);

            // re-write criteria popup buttons actions
            $scope.$parent.criterion.saveValue = $scope.$parent.criterion.updateValue = function() {
                // updates filter value
                $scope.$parent.$parent.searchCriterionCtrl.value = storedValue;
                $scope.$parent.criterion.value = storedValue;
                $scope.$parent.criterion.currentOption = $scope.currentOption;

                $scope.$parent.$parent.$parent.criterion = $scope.$parent.criterion;

                // display value in the filters bar button
                $scope.$parent.$parent.searchCriterionCtrl.displayValue = displayValue;
            }

            $scope.$parent.criterion.onValueClear = function() {
                $rootScope.skipDirtyCheck = true;
                $rootScope.forceDirtyConfirm = false;
                if ($scope.$parent.criterion.hasOwnProperty('defaultValue')) {
                    setDateRangeByOption($scope.$parent.criterion.defaultValue);
                } else {
                    $scope.inputValueStartDate = null;
                    $scope.inputValueEndDate = null;
                    $scope.$parent.$parent.searchCriterionCtrl.displayValue = null;
                    storedValue = null;
                    displayValue = null;
                }
            };

            function setDateRangeByOption(value) {
                const startDate = new Date();
                startDate.setMonth(startDate.getMonth() - value);
                startDate.setHours(0, 0, 0);

                const endDate = new Date();
                endDate.setHours(23, 59, 59);

                $scope.inputValueStartDate = startDate;
                $scope.inputValueEndDate = endDate;

                return {
                    startDate: startDate,
                    endDate: endDate
                }
            }
        }
    };
});
