(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('de', {

            WASTE_MANAGEMENT: 'Abfallmanagement',
            WASTE_TYPE_LIST: 'Abfallarten',
            WASTE_ENTRY_LIST: 'Abfalleinträge',
            WASTE_TYPE: 'Abfallart',
            TREATMENT_TYPE: 'Behandlungsart',
            TREATMENT_TYPE_STATUS: 'Status Behandlungsart',
            DUPLICATED_TREATMENT_TYPE: 'Doppelte Behandlungsart',
            TREATMENT_TYPE_LIST: 'Behandlungsarten',
            TREATMENT_TARGET_LIST: 'Ziele Behandlungsarten',
            TREATMENT_TARGET_ADD: 'Ziel Behandlungsart hinzufügen',
            SITE_TARGET: 'Gebäudeziel',
            WASTE_TREATMENT_TYPES: 'Abfallbehandlungsarten',
            WASTE_TREATMENT_TARGETS: 'Abfallbehandlungsziele',
            WASTE_TREATMENT_TYPES_LIST: 'Abfallbehandlungsarten',
            WASTE_TYPE_ADD: 'Abfallart hinzufügen',
            UNIT_ADD: 'Einheit hinzufügen',
            TREATMENT_TYPE_ADD: 'Behandlungsart hinzufügen',
            WASTE_TYPES: 'Abfallarten',
            WASTETYPE: 'Abfallart',
            TREATMENT_TYPES: 'Behandlungsarten',
            CODE: 'Code',
            WASTE_TYPE_CREATED: 'Abfallart erstellt',
            WASTE_ENTRY_CREATED: 'Abfalleintrag erstellt',
            WASTE_TYPE_UPDATED: 'Abfallart aktualisiert',
            WASTE_ENTRY_UPDATED: 'Abfalleintrag aktualisiert',
            WASTE_TYPE_DELETED: 'Abfallart gelöscht',
            WASTE_ENTRY_DELETED: 'Abfalleintrag gelöscht',
            WASTE_ADD: 'Abfallart hinzufügen',
            WASTE_EDIT: 'Abfallart bearbeiten',
            UNIT_EDIT: 'Einheit bearbeiten',
            TREATMENT_TYPE_EDIT: 'Behandlungsart bearbeiten',
            UNITS: 'Einheiten',
            TREATMENT_TYPE_CREATED: 'Behandlungsart erstellt',
            TREATMENT_TYPE_UPDATED: 'Behandlungsart aktualisiert',
            TREATMENT_TYPE_DELETED: 'Behandlungsart gelöscht',
            TARGET_DIRECTION: 'Zielrichtung',
            TARGET_DIRECTION_BELOW: 'Unteres Ziel ist positiv',
            TARGET_DIRECTION_ABOVE: 'Oberes Ziel ist positiv',
            TARGET_PERCENT: 'Ziel (%)',
            UNIT_UPDATED: 'Gerätetyp aktualisiert',
            UNIT_DELETED: 'Gerätetyp gelöscht',
            UNIT_CREATED: 'Gerätetyp erstellt',
            WASTE_MANAGEMENT_TARGETS: 'Abfallmanagementziel',
            UNIT_LIST: 'Einheiten',
            WASTEMANAGEMENT: 'Abfallmanagement',
            DATE_START: 'Datum Start',
            DATE_END: 'Datum Ende',
            ENTRYDATE: 'Abholtermin',
            ENTRY_DATE: 'Abholtermin',
            WASTE_ENTRY_ADD: 'Abfalleintrag hinzufügen',
            WASTE_ENTRY_EDIT: 'Abfalleintrag bearbeiten',
            WASTE_ENTRIES: 'Abfalleinträge',
            ENTRIES: 'Einträge',
            ENTRIES_IMPORT: 'Abfalleinträge importieren',
            TAG_WASTEENTRY: 'Abfalleintrag',
            TREATMENT_TYPE_ADDED: 'Behandlungsart hinzugefügt',
            LAST_THREE_ENTRIES: 'Letzte 3 Abfalleinträge',
            DOWNOLAD_WASTE_TYPE: 'System {{ ::"WASTE_TYPES" | translate }}" herunterladen',
            DOWNOLAD_TREATMENT_TYPE: 'System {{ ::"TREATMENT_TYPES" | translate }}" herunterladen',
            DOWNOLAD_UNIT: 'System {{ ::"UNITS" | translate }}" herunterladen',
            FILE_VISIBILITY_PUBLIC: 'Öffentlich',
            FILE_VISIBILITY_PARTNERSHIPS: 'Partnerschaft',
            FILE_VISIBILITY_OCCUPIERS: 'Nutzer',
            MAX: 'Max. {{value}}',
            MIN: 'Min. {{value}}',
            REQUIRED: 'Erforderlich',
            NOTES_MAX_LENGTH: 'Die maximale Länge für das Hinweisfeld beträgt 255',
            NAME_MAX_LENGTH: 'Die maximale Länge für das Namensfeld beträgt 255',
            WASTE_ENTRY_VALUE_ERROR: 'Der Gesamtwert der Behandlungsarten kann nicht größer sein als der Wert des Abfalleintrags',
            USER: 'Benutzer',
            USERS: 'Benutzer',
            USER_EDIT: 'Benutzer bearbeiten',
            USER_LIST: 'Benutzer',
            USER_ADD: 'Einen neuen Benutzer hinzufügen',
            ADD_NEW_USER: 'Einen Benutzer hinzufügen',
            NEW_SITE_ACCESS: 'Zugang auf neue Gebäude gewähren',
            USER_CREATED: 'Benutzer erstellt',
            NEW_ROW: 'Neue Zeile',
            USER_UPDATED: 'Benutzer aktualisiert',
            USER_ROLES: 'Rollen',
            ALL_ROLES: 'Alle Rollen',
            SELECTED_ROLES: 'Ausgewählte Rollen',
            CHANGE_PASSWORD: 'Kennwort ändern',
            PASSWORD_RESET_REQUEST_SUCCESSFUL: 'Bitte überprüfen Sie Ihre E-Mail auf Anweisungen zur Kennwortänderung.',
            USER_PREFERENCES_VIEW: 'Benutzereinstellungen',
            USER_PREFERENCES_EDIT: 'Benutzereinstellungen bearbeiten',
            USER_PREFERENCES: 'Benutzereinstellungen',
            USER_NOTIFICATION_TYPE: 'Benachrichtigungsmethode',
            NOTIFICATIONMETHOD: 'Benachrichtigungsmethode',
            EMAILCONTENTTYPE: 'Art des E-Mail-Inhalts',
            EMAIL_CONTENT_TYPE: 'Art des E-Mail-Inhalts',
            USER_EMAIL_CONTENT_TYPE: 'Art des E-Mail-Inhalts',
            USER_PREFERENCE_ALL: 'Alle',
            USER_PREFERENCE_NOTIFICATION: 'Benachrichtigung',
            USER_PREFERENCE_EMAIL: 'E-Mail',
            USER_PREFERENCE_TEXT: 'Text',
            USER_PREFERENCE_HTML: 'html',
            PREFERENCES_UPDATED: 'Präferenzen wurden aktualisiert',
            USER_PREFERENCE_LOCALE: 'Sprache',
            USER_PREFERENCE_NOTIFY_ON_CREATE_JOB: 'Benachrichtigen bei Auftrag erstellen',
            USER_PREFERENCE_NOTIFY_ON_COMPLETE_PLANNED_JOB: 'Benachrichtigen bei Geplanter Auftrag vollständig',
            USER_PREFERENCE_NOTIFY_ON_COMPLETE_REACTIVE_JOB: 'Benachrichtigen bei Reaktiver Auftrag vollständig',
            USER_PREFERENCE_NOTIFY_ON_COMPLETE_STATUTORY_JOB: 'Benachrichtigen bei Gesetzlicher Auftrag vollständig',
            USER_PREFERENCE_NOTIFY_ON_CREATE_QUOTE: 'Benachrichtigen bei Angebot erstellen',
            USER_PREFERENCE_NOTIFY_ON_ACCEPT_QUOTE: 'Benachrichtigen bei Angebot akzeptieren',
            THIS_USER_HAS_NO_SITES: 'Dieser Benutzer hat keinen Zugang zu Gebäuden',
            GIVE_ACCESS: 'Zugang gewähren',
            CHANGE_SITE_ACCESS: 'Zugang zum Gebäude ändern',
            GRANT_ACCESS: 'Zugang gewähren',
            REVOKE_ACCESS: 'Zugang widerrufen',
            TO_ALL_ACTIVE_SITES: 'Zu allen aktiven Gebäuden',
            SELECT_SPECIFIC_SITES: 'Bestimmte Gebäude auswählen',
            COPY_FROM_USER: 'Von Benutzer kopieren',
            FROM_ALL_SITES_ON_THE_SYSTEM: 'Von allen Gebäuden im System',
            CONFIRM_AND_FINISH: 'Bestätigen und beenden',
            CONTINUE_AND_CHOOSE_SITES: 'Fortfahren und Gebäude auswählen',
            AVAILABLE_SITES: 'Verfügbare Gebäude',
            GRANT_ACCESS_TO: 'Zugang zu Gebäuden gewähren',
            MODIFY: 'Ändern',
            SITE_PERMISSIONS: 'Gebäudeberechtigungen',
            SELECT_PERMISSIONS: 'Berechtigungen auswählen',
            ADD_PERMISSIONS: 'Berechtigungen hinzufügen',
            UPDATE_PERMISSIONS: 'Berechtigungen aktualisieren',
            EXPLICIT_PERMISSIONS: 'Explizite Berechtigungen',
            USER_HAS_NO_SITE_ACCESS: 'Benutzer hat keinen Zugang zum Gebäude',
            USER_PERMISSIONS: 'Benutzerberechtigungen',
            PERMISSIONS: 'Berechtigungen',
            PERMISSION: 'Berechtigung',
            REMOVE: 'Entfernen',
            NO_OPERATIVES_ASSIGNED: 'Keine Arbeiter zugewiesen',
            SITE_PERMISSIONS_UPDATED: 'Gebäudeberechtigungen aktualisiert',
            USER_PERMISSIONS_UPDATED: 'Benutzerberechtigungen aktualisiert',
            USER_TOKENS: 'Tokens',
            TOKEN: 'Token',
            TOKENS_LIST: 'Token-Liste',
            EXPIRATION_DATE: 'Ablaufdatum',
            TOKENS_ADD: 'Ein neues Token erstellen',
            USER_TOKEN_ADD: 'Token erstellen',
            TOKENS: 'Tokens',
            TOKEN_ADD: 'Ein neues Token erstellen',
            TOKEN_CREATED: 'Token erstellt',
            TOKEN_REVOKED: 'Token widerrufen',
            STANDARD: 'Standard',
            MERIDIAN: 'Meridian',
            RISKWISE: 'RiskWise',
            ALCUMUS: 'Alcumus',
            PROPERTYPLUS: 'Property Plus',
            PROPERTYPLUS_LIGTAS: 'Property Plus (Ligtas)',
            QCOMPLIANCE: 'Q-Compliance',
            USED_TO_SET_API_USER_TYPE: 'Wird zum Einstellen des API-Benutzertyps verwendet',
            INVALID_USER_TYPE: 'Ungültiger Benutzertyp. Möglicherweise versuchen Sie, einen Benutzertyp für ein deaktiviertes Modul zu verwenden.',
            USER_TYPE: 'Typ',
            INVALID_TOKEN: 'Ungültiges Token',
            ROLES_TO_MANY_MEMBERSHIPS: 'Ein Benutzer hat mehr als 100 Gebäude-Mitgliedschaften, daher bitte nur 1 Rolle pro Anfrage hinzufügen oder entfernen.',
            SITE_ACCESS_REMOVED: 'Zugang zum Gebäude entfernt',
            CONTACT_HAS_NO_EMAIL: 'Benutzer müssen mindestens 1 E-Mail-Adresse haben, bitte fügen Sie eine E-Mail-Adresse hinzu',
            EMAIL_NOT_VALID: 'Die E-Mail-Adresse ist ungültig',
            USER_EDITED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat den Benutzer aktualisiert.',
            USER_EDITED_SYSTEM_ACTION: 'Das System hat den Benutzer automatisch aktualisiert.',
            ASSOCIATE_DETAILS: 'Details zum Partner',
            USER_PERMISSION_MANAGE_ASSET_TYPES: 'Asset-Typen verwalten',
            USER_PERMISSION_MANAGE_ASSOCIATE_TYPES: 'Partnertypen verwalten',
            USER_PERMISSION_MANAGE_CERTIFICATE_TYPES: 'Zertifikatstypen verwalten',
            USER_PERMISSION_MANAGE_CHARGE_TYPES: 'Ladevorgangstypen verwalten',
            USER_PERMISSION_MANAGE_CONTRACT_TYPES: 'Vertragsarten verwalten',
            USER_PERMISSION_MANAGE_JOB_REQUISITES: 'Auftragsanforderungen verwalten',
            USER_PERMISSION_MANAGE_JOB_GROUPS: 'Auftragsgruppen verwalten',
            USER_PERMISSION_MANAGE_LETTERS: 'Briefe verwalten',
            USER_PERMISSION_MANAGE_METER_INFO: 'Zähler-Info verwalten',
            USER_PERMISSION_MANAGE_PRIORITIES: 'Prioritäten verwalten',
            USER_PERMISSION_MANAGE_REGIONS: 'Regionen verwalten',
            USER_PERMISSION_MANAGE_ROLES: 'Rollen verwalten',
            USER_PERMISSION_MANAGE_SATISFACTION_SURVEYS: 'Zufriedenheitsumfragen verwalten',
            USER_PERMISSION_MANAGE_SECTORS: 'Sektoren verwalten',
            USER_PERMISSION_MANAGE_SERVICE_PROVIDERS: 'Dienstleister verwalten',
            USER_PERMISSION_MANAGE_SERVICE_PROVIDER_GROUPS: 'Dienstleistergruppen verwalten',
            USER_PERMISSION_MANAGE_SERVICE_ROUTINES: 'Service-Routinen verwalten',
            USER_PERMISSION_MANAGE_SETTINGS: 'Einstellungen verwalten',
            USER_PERMISSION_MANAGE_SITES: 'Gebäude verwalten',
            USER_PERMISSION_MANAGE_SITE_GROUPS: 'Gebäudegruppen verwalten',
            USER_PERMISSION_MANAGE_SITE_NOTE_TYPES: 'Typen von Gebäudehinweisen verwalten',
            USER_PERMISSION_MANAGE_SITE_RELATIONSHIPS: 'Gebäude-Beziehungen verwalten',
            USER_PERMISSION_MANAGE_SURVEYS: 'Umfragen verwalten',
            USER_PERMISSION_MANAGE_TAGS: 'Tags verwalten',
            USER_PERMISSION_MANAGE_TRADE_TYPES: 'Handelsarten verwalten',
            USER_PERMISSION_MANAGE_USERS: 'Benutzer verwalten',
            USER_PERMISSION_MANAGE_DUTY_MANAGEMENT_SCHEDULES: 'Zeitplan Aufgabenmanagement verwalten',
            USER_PERMISSION_MANAGE_MASTER_TASKS: 'Master-Aufgaben verwalten',
            USER_PERMISSION_VIEW_ASSET_TYPES: 'Asset-Typen anzeigen',
            USER_PERMISSION_VIEW_ASSOCIATE_TYPES: 'Partnertypen anzeigen',
            USER_PERMISSION_VIEW_CERTIFICATE_TYPES: 'Zertifikatstypen anzeigen',
            USER_PERMISSION_VIEW_CHARGE_TYPES: 'Ladevorgangstypen anzeigen',
            USER_PERMISSION_VIEW_CONTRACT_TYPES: 'Vertragsarten anzeigen',
            USER_PERMISSION_VIEW_JOB_REQUISITES: 'Auftragsanforderungen anzeigen',
            USER_PERMISSION_VIEW_JOB_GROUPS: 'Auftragsgruppen anzeigen',
            USER_PERMISSION_VIEW_LETTERS: 'Buchstaben anzeigen',
            USER_PERMISSION_VIEW_METER_INFO: 'Zähler-Info anzeigen',
            USER_PERMISSION_VIEW_PRIORITIES: 'Prioritäten anzeigen',
            USER_PERMISSION_VIEW_REGIONS: 'Regionen anzeigen',
            USER_PERMISSION_VIEW_ROLES: 'Rollen anzeigen',
            USER_PERMISSION_VIEW_SATISFACTION_SURVEYS: 'Zufriedenheitsumfragen anzeigen',
            USER_PERMISSION_VIEW_SECTORS: 'Sektoren anzeigen',
            USER_PERMISSION_VIEW_SERVICE_PROVIDERS: 'Dienstleister anzeigen',
            USER_PERMISSION_VIEW_SERVICE_PROVIDER_GROUPS: 'Dienstleistergruppen anzeigen',
            USER_PERMISSION_VIEW_SERVICE_ROUTINES: 'Service-Routinen anzeigen',
            USER_PERMISSION_VIEW_SETTINGS: 'Einstellungen anzeigen',
            USER_PERMISSION_VIEW_SITES: 'Gebäude anzeigen',
            USER_PERMISSION_VIEW_SITE_GROUPS: 'Gebäudegruppen anzeigen',
            USER_PERMISSION_VIEW_SITE_NOTE_TYPES: 'Typen von Gebäudehinweisen anzeigen',
            USER_PERMISSION_VIEW_SURVEYS: 'Umfragen anzeigen',
            USER_PERMISSION_VIEW_TAGS: 'Tags anzeigen',
            USER_PERMISSION_VIEW_TRADE_TYPES: 'Handelsarten anzeigen',
            USER_PERMISSION_VIEW_USERS: 'Benutzer anzeigen',
            USER_PERMISSION_VIEW_DUTY_MANAGEMENT_SCHEDULES: 'Zeitplan Aufgabenmanagement anzeigen',
            USER_PERMISSION_CAN_RECEIVE_SUPPORT_MESSAGES: 'Kann Support-Mitteilungen empfangen',
            USER_PERMISSION_CAN_CREATE_GLOBAL_AUDIT_TEMPLATES: 'Kann globale Audit-Vorlagen erstellen',
            USER_PERMISSION_ACCESS_SERVICE_PROVIDER_APP: 'Auf Dienstleister-App zugreifen',
            USER_PERMISSION_ACCESS_CLIENT_APP: 'Auf Client-App zugreifen',
            USER_PERMISSION_MANAGE_SERVICE_TYPES: 'Diensttypen verwalten',
            USER_PERMISSION_VIEW_SERVICE_TYPES: 'Diensttypen anzeigen',
            USER_PERMISSION_MANAGE_IMPORTS: 'Importe verwalten',
            USER_PERMISSION_MANAGE_EXPORTS: 'Exporte verwalten',
            USER_PERMISSION_MANAGE_CORE_CLIENTS: 'Kernkunden verwalten',
            USER_PERMISSION_KPI_TREND_EXPORT: 'Export KPI-Trend',
            USER_PERMISSION_MANAGE_ASSET_SCORE_TYPES: 'Typen Asset-Ergebnis verwalten',
            USER_PERMISSION_VIEW_ASSET_SCORE_TYPES: 'Typen Asset-Ergebnis anzeigen',
            USER_PERMISSION_MANAGE_WASTE_MANAGEMENT: 'Abfallmanagement verwalten',
            USER_PERMISSION_MANAGE_ACTION_TYPES: 'Maßnahmentypen verwalten',
            USER_PERMISSION_VIEW_ACTION_TYPES: 'Maßnahmentypen anzeigen',
            USER_PERMISSION_VIEW_FORMS: 'Formulare anzeigen',
            USER_PERMISSION_MANAGE_FORMS: 'Formulare verwalten',
            USER_PERMISSION_MANAGE_METER_INFO_DETAIL: '',
            USER_PERMISSION_VIEW_METER_INFO_DETAIL: '',
            USER_PERMISSION_CAN_CREATE_GLOBAL_AUDIT_TEMPLATES_DETAIL: '',
            USER_PERMISSION_ACCESS_SERVICE_PROVIDER_APP_DETAIL: '',
            USER_PERMISSION_ACCESS_CLIENT_APP_DETAIL: '',
            USER_PERMISSION_MANAGE_BILLING_TYPES: 'Abrechnungsarten verwalten',
            USER_PERMISSION_MANAGE_ACTION_TYPES_DETAIL: 'Die Befähigung, Maßnahmentypen zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_ASSET_TYPES_DETAIL: 'Die Befähigung, Asset-Typen zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_ASSOCIATE_TYPES_DETAIL: 'Die Befähigung, Gebäudepartner-Typen zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_CERTIFICATE_TYPES_DETAIL: 'Die Befähigung, Zertifikatstypen zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_CHARGE_TYPES_DETAIL: 'Die Befähigung, Gebührenarten zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_CONTRACT_TYPES_DETAIL: 'Die Befähigung, Vertragsarten zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_JOB_REQUISITES_DETAIL: 'Die Befähigung, Auftragsanforderungen zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_JOB_GROUPS_DETAIL: 'Die Befähigung, Auftragsgruppen zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_LETTERS_DETAIL: 'Die Befähigung, Briefvorlagen zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_METERINFO_DETAIL: 'Die Befähigung, Zählerhersteller, Zählerlieferanten, Zählerversorgungstypen und Zählertypen zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_PRIORITIES_DETAIL: 'Die Befähigung, Systemprioritäten zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_REGIONS_DETAIL: 'Die Befähigung, Regionen zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_ROLES_DETAIL: 'Die Befähigung, Rollen zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_SATISFACTION_SURVEYS_DETAIL: 'Die Befähigung, Fragen für Zufriedenheitsumfragen zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_SECTORS_DETAIL: 'Die Befähigung, Sektoren zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_SERVICE_PROVIDERS_DETAIL: 'Die Befähigung, Dienstleister zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_SERVICE_PROVIDER_GROUPS_DETAIL: 'Die Befähigung, Dienstleistergruppen zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_SERVICE_ROUTINES_DETAIL: 'Die Befähigung, System-Service-Routinen zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_SETTINGS_DETAIL: 'Die Befähigung, Systemeinstellungen zu aktualisieren',
            USER_PERMISSION_MANAGE_SITES_DETAIL: 'Die Befähigung, Gebäude zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_SITE_GROUPS_DETAIL: 'Die Befähigung, Gebäudegruppen zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_SITE_NOTE_TYPES_DETAIL: 'Die Befähigung, Typen von Gebäudehinweisen zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_SITE_RELATIONSHIPS_DETAIL: 'Die Befähigung, Gebäude-Beziehungen zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_SURVEYS_DETAIL: 'Die Befähigung, KPI-Umfragen zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_TAGS_DETAIL: 'Die Befähigung, Tags zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_TRADE_TYPES_DETAIL: 'Die Befähigung, Handelsarten zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_USERS_DETAIL: 'Die Befähigung, Benutzer zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_DUTY_MANAGEMENT_SCHEDULES_DETAIL: 'Die Befähigung, die Aufgabenmanagement-Zeitpläne für das System zu verwalten',
            USER_PERMISSION_MANAGE_MASTER_TASKS_DETAIL: 'Die Befähigung, Master-Aufgaben zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_MANAGE_CORE_CLIENTS_DETAIL: 'Die Befähigung, Kernkunden zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_VIEW_ACTION_TYPES_DETAIL: 'Die Befähigung, Maßnahmentypen anzuzeigen',
            USER_PERMISSION_VIEW_ASSET_TYPES_DETAIL: 'Die Befähigung, Asset-Typen anzuzeigen',
            USER_PERMISSION_VIEW_ASSOCIATE_TYPES_DETAIL: 'Die Befähigung, Typen von Gebäudepartnern anzuzeigen',
            USER_PERMISSION_VIEW_CERTIFICATE_TYPES_DETAIL: 'Die Befähigung, Zertifikatstypen anzuzeigen',
            USER_PERMISSION_VIEW_CHARGE_TYPES_DETAIL: 'Die Befähigung, Ladevorgangstypen anzuzeigen',
            USER_PERMISSION_VIEW_CONTRACT_TYPES_DETAIL: 'Die Befähigung, Vertragsarten anzuzeigen',
            USER_PERMISSION_VIEW_JOB_REQUISITES_DETAIL: 'Die Befähigung, Auftragsanforderungen anzuzeigen',
            USER_PERMISSION_VIEW_JOB_GROUPS_DETAIL: 'Die Befähigung, Auftragsgruppen anzuzeigen',
            USER_PERMISSION_VIEW_LETTERS_DETAIL: 'Die Befähigung, Briefvorlagen anzuzeigen',
            USER_PERMISSION_VIEW_METERINFO_DETAIL: 'Die Befähigung, Zählerhersteller, Zählerlieferanten, Zählerversorgungstypen und Zählertypen anzuzeigen',
            USER_PERMISSION_VIEW_PRIORITIES_DETAIL: 'Die Befähigung, Systemprioritäten anzuzeigen',
            USER_PERMISSION_VIEW_REGIONS_DETAIL: 'Die Befähigung, Regionen anzuzeigen',
            USER_PERMISSION_VIEW_ROLES_DETAIL: 'Die Befähigung, Rollen anzuzeigen',
            USER_PERMISSION_VIEW_SATISFACTION_SURVEYS_DETAIL: 'Die Befähigung, Fragen für Zufriedenheitsumfragen anzuzeigen',
            USER_PERMISSION_VIEW_SECTORS_DETAIL: 'Die Befähigung, Sektoren anzuzeigen',
            USER_PERMISSION_VIEW_SERVICE_PROVIDERS_DETAIL: 'Die Befähigung, Dienstleister anzuzeigen',
            USER_PERMISSION_VIEW_SERVICE_PROVIDER_GROUPS_DETAIL: 'Die Befähigung, Dienstleistergruppen anzuzeigen',
            USER_PERMISSION_VIEW_SERVICE_ROUTINES_DETAIL: 'Die Befähigung, System-Service-Routinen anzuzeigen',
            USER_PERMISSION_VIEW_SETTINGS_DETAIL: 'Die Befähigung, Systemeinstellungen anzuzeigen',
            USER_PERMISSION_VIEW_SITES_DETAIL: 'Die Befähigung, Gebäude anzuzeigen',
            USER_PERMISSION_VIEW_SITE_GROUPS_DETAIL: 'Die Befähigung, Gebäudegruppen anzuzeigen',
            USER_PERMISSION_VIEW_SITE_NOTE_TYPES_DETAIL: 'Die Befähigung, Typen von Gebäudehinweisen anzuzeigen',
            USER_PERMISSION_VIEW_SURVEYS_DETAIL: 'Die Befähigung, KPI-Umfragen anzuzeigen',
            USER_PERMISSION_VIEW_TAGS_DETAIL: 'Die Befähigung, Tags anzuzeigen',
            USER_PERMISSION_VIEW_TRADE_TYPES_DETAIL: 'Die Befähigung, Handelsarten anzuzeigen',
            USER_PERMISSION_VIEW_USERS_DETAIL: 'Die Befähigung, Benutzer anzuzeigen',
            USER_PERMISSION_VIEW_DUTY_MANAGEMENT_SCHEDULES_DETAIL: 'Die Befähigung, die Aufgabenmanagement-Zeitpläne für das System anzuzeigen',
            USER_PERMISSION_CAN_RECEIVE_SUPPORT_MESSAGES_DETAIL: 'Die Befähigung, Support-Mitteilungen anzuzeigen und darauf zu reagieren',
            USER_PERMISSION_MANAGE_SERVICE_TYPES_DETAIL: 'Die Befähigung, Diensttypen zu erstellen, zu aktualisieren und zu löschen',
            USER_PERMISSION_VIEW_SERVICE_TYPES_DETAIL: 'Die Befähigung, Diensttypen anzuzeigen',
            USER_PERMISSION_MANAGE_IMPORTS_DETAIL: 'Die Befähigung, Daten zu importieren',
            USER_PERMISSION_MANAGE_EXPORTS_DETAIL: 'Die Befähigung, Daten zu exportieren',
            USER_PERMISSION_MANAGE_BILLING_TYPES_DETAIL: 'Die Befähigung, Abrechnungsarten zu erstellen',
            USER_PERMISSION_KPI_TREND_EXPORT_DETAIL: 'Die Befähigung, KPI-Trends zu exportieren',
            USER_PERMISSION_MANAGE_ASSET_SCORE_TYPES_DETAIL: 'Die Befähigung, Asset-Ergebnistypen zu verwalten',
            USER_PERMISSION_VIEW_ASSET_SCORE_TYPES_DETAIL: 'Die Befähigung, Asset-Ergebnistypen anzuzeigen',
            USER_PERMISSION_OPEN_CLOSE_DETAIL: 'Die Befähigung, Aufzeichnungen über spät/früh schließen zu erstellen',
            USER_PERMISSION_OPEN_CLOSE: 'Die Befähigung, Aufzeichnungen über spät/früh schließen zu erstellen',
            USER_PERMISSION_MANAGE_SPILLAGE_TYPES: 'Verschüttungsarten verwalten',
            USER_PERMISSION_MANAGE_SPILLAGE_TYPES_DETAIL: 'Die Befähigung, Verschüttungsarten zu verwalten',
            USER_PERMISSION_VIEW_SPILLAGE_TYPES: 'Verschüttungsarten anzeigen',
            USER_PERMISSION_VIEW_SPILLAGE_TYPES_DETAIL: 'Die Befähigung, Verschüttungsarten anzuzeigen',
            USER_PERMISSION_MANAGE_MISSED_PATROL_REASONS: 'Gründe für versäumte Patrouillen verwalten',
            USER_PERMISSION_MANAGE_MISSED_PATROL_REASONS_DETAIL: 'Gründe für versäumte Patrouillen verwalten',
            USER_PERMISSION_VIEW_FORMS_DETAIL: 'Die Befähigung, Formulare anzuzeigen',
            USER_PERMISSION_MANAGE_FORMS_DETAIL: 'Die Befähigung, Formulare zu verwalten',
            USER_PERMISSION_MANAGE_WASTE_MANAGEMENT_DETAIL: 'Die Befähigung, Abfallmanagement zu verwalten',
            UPDATES_AVAILABLE_HEADER: 'Updates verfügbar',
            UPDATES_AVAILABLE_BODY: 'Es sind Updates für Elogs CAFM verfügbar und Ihr Browser wird in 5 Minuten neu geladen, um sie anzuwenden. Sie können dieses Update aufschieben und mit dem, was Sie tun, fortfahren; es kann jedoch zu Problemen mit Elogs CAFM kommen.',
            UPDATE_NOW: 'Jetzt aktualisieren',
            UPDATE_POSTPONE: 'Aktualisierung aufschieben',
            THRESHOLD: 'Schwellenwert',
            THRESHOLDS: 'Schwellenwerte',
            THRESHOLD_LIST: 'Schwellenwerte',
            QUOTE_THRESHOLD_CREATED: 'Angebotsschwellenwert erstellt',
            QUOTE_THRESHOLD_UPDATED: 'Angebotsschwellenwert aktualisiert',
            QUOTE_THRESHOLD_DELETED: 'Angebotsschwellenwert gelöscht',
            THRESHOLD_ADD: 'Einen neuen Schwellenwert hinzufügen',
            THRESHOLD_EDIT: 'Schwellenwert bearbeiten',
            TENANT: 'Mieter',
            TENANTS: 'Mieter',
            TENANT_LIST: 'Mieter',
            TENANT_ADD: 'Einen neuen Mieter hinzufügen',
            ADD_NEW_TENANT: 'Mieter hinzufügen',
            TENANT_EDIT: 'Mieter bearbeiten',
            TENANT_CREATED: 'Mieter erstellt',
            TENANT_DELETED: 'Mieter gelöscht',
            EMAIL_SELECTED_TENANTS: '<i class="fa fa-mail-forward"></i> E-Mail Mieter ({{ ::selected }} ausgewählt)',
            EMAIL_FILTERED: 'E-Mail gefiltert',
            FILTERED_TENANTS: 'Gefilterte Mieter',
            EMAIL_TENANTS: 'E-Mail der Mieter',
            EMAIL_LOGO_SUGGESTED_SIZE: 'Empfohlene Upload-Größe: maximale Breite = 393px',
            TENANT_CONTACT_CREATED: 'Mieter erstellt',
            TENANT_CONTACT_UPDATED: 'Mieter aktualisiert',
            TENANT_CONTACT_DELETED: 'Mieter gelöscht',
            A_TENANT_CANNOT_BE_A_USER: 'Ein Mieter kann kein Benutzer sein',
            A_TENANT_MUST_HAVE_A_VALID_NAME: 'Ein Mieter muss einen gültigen Namen haben',
            NOT_A_TENANT: 'Kein Mieter',
            TASK: 'Aufgabe',
            TASKS: 'Aufgaben',
            TASKS_MANAGE_TASKS: 'Aufgaben verwalten',
            TASKS_GENERATE_PLANNER: 'Planer erzeugen',
            TASK_GENERATE_PLANNER: 'Planer erzeugen',
            TASK_GENERATE_DATES: 'Erzeugung Datumsbereich',
            TASKS_VIEW_PLANNER: 'Planer anzeigen',
            TASKS_IMPORT: 'Aufgaben importieren',
            VIEW_PLANNER: 'Planer anzeigen',
            MASTER_TASKS_PLANNER: 'Master-Aufgabenplaner',
            TASK_CREATE_PROCESS_CONTINUE: 'Weiter',
            TASK_CREATE_PROCESS_CREATE_ASSET: 'Asset hinzufügen',
            TASK_CREATE_STEP_1: 'Aufgabe erstellen',
            SERVICE_TYPE_TIP: 'Hard Services werden Asset erfordern.',
            LIST_TASKS: 'Aufgaben',
            TASK_PLANNER: 'Planer',
            TASK_EVENT_ADD: 'Aufgabenereignis hinzufügen',
            TASK_EVENT_EDIT: 'Aufgabenereignis bearbeiten',
            PLANNER: 'Planer',
            PPM_PLANNER: 'PPM-Planer',
            TASK_ADD: 'Aufgabe hinzufügen',
            TASK_EDIT: 'Aufgabe bearbeiten',
            TASK_GENERATE: 'Aufgaben erzeugen',
            TASK_STATUS: 'Taskstatus',
            TASKS_NO_RESULTS: 'Keine Ergebnisse',
            STATUS_ALL: 'Alle',
            SELECT_FREQUENCY: 'Frequenz auswählen',
            SELECT_INTERVAL: 'Intervall auswählen',
            SELECT_PREFIX: 'Präfix auswählen',
            SELECT_DAY: 'Tag auswählen',
            SELECT_DAY_NUMBER: 'Tag auswählen',
            SELECT_MONTH: 'Monat auswählen',
            SELECT_ALL_FIELDS_ERROR: 'Bitte wählen Sie alle Felder aus',
            AT_LEAST_ONE_FREQUENCY_REQUIRED: 'Mindestens eine Frequenz ist erforderlich',
            DECLINE_SELECTED: 'Ausgewählte ablehnen',
            DECLINE_FILTERED: 'Alle Gefilterten ablehnen',
            APPROVE_SELECTED: 'Ausgewählte genehmigen ({{ count }})',
            APPROVE_FILTERED: 'Alle Gefilterten genehmigen',
            REMOVE_SELECTED: 'Ausgewählte entfernen',
            REMOVE_FILTERED: 'Alle Gefilterten entfernen',
            GENERATE_SELECTED: 'Ausgewählte erzeugen ({{ count }})',
            GENERATE_FILTERED: 'Alle Gefilterten erzeugen',
            SEND_FOR_APPROVAL: 'Zur Genehmigung senden',
            SEND_FILTERED_FOR_APPROVAL: 'Alle Gefilterten zur Genehmigung senden',
            INTERVAL: 'Intervall',
            RULE: 'Regel',
            RULES: 'Regeln',
            OF: 'von',
            DATE: 'Datum',
            DAY_OF_THE_WEEK: 'Wochentag',
            FREQUENCY: 'Frequenz',
            FREQUENCIES: 'Frequenzen',
            START: 'Anfangsdatum',
            DATE_FROM: 'Anfangsdatum',
            END: 'Enddatum',
            DATE_TO: 'Enddatum',
            TOTAL: 'Gesamt',
            NO_ITEMS_SELECTED: 'Keine Artikel ausgewählt',
            REASON_FOR_TASK_EVENT_REJECT: 'Bitte schreiben Sie einen Grund für die Ablehnung',
            TASK_GENERATION_INFO: 'Anforderung(en) zu Aufgabenerzeugung in der Warteschlange. Bitte aktualisieren Sie den Planer nach 1 Minute.',
            TASK_APPROVAL_PENDING_INFO: '{{ count }} Genehmigungsanfrage{{ count > 1 ? "s" : "" }} ausstehend.',
            ALLTASKS: 'Alle Aufgaben auswählen',
            COPYFROMLASTYEAR: 'Aus dem letzten Jahr kopieren',
            TASK_SERVICE_PROVIDER_NOT_DEFINED: 'Dienstanbieter nicht definiert',
            TASK_APPROVAL_STATUS: 'Genehmigungsstatus der Aufgabe',
            TASK_STATUS_DRAFT: 'Entwurf',
            TASK_STATUS_PENDING_APPROVAL: 'Ausstehend zur Genehmigung',
            TASK_STATUS_REJECTED: 'Abgelehnt',
            TASK_STATUS_APPROVED: 'Genehmigt',
            TASK_STATUS_JOB_COMPLETED: 'Abgeschlossen',
            TASK_STATUS_JOB_AWAITING_PAPERWORK: 'Warten auf Unterlagen',
            TASK_STATUS_JOB_IN_PROGRESS: 'In Bearbeitung',
            TASK_STATUS_JOB_MISSED: 'Versäumt',
            TASK_STATUS_JOB_OVERDUE: 'Überfällig',
            TASK_STATUS_FUTURE_DRAFT: 'Zukünftiger Entwurf',
            TASK_STATUS_FUTURE_PENDING_APPROVAL: 'Zukünftig zur Genehmigung ausstehend',
            TASK_EVENT_STATUTORY_BASED_ON_FREQUENCY: 'Gesetzlich basierend auf der Frequenz',
            TASK_STATUS_FUTURE_REJECTED: 'Zukünftig abgelehnt',
            TASK_STATUS_FUTURE_APPROVED: 'Zukünftig genehmigt',
            TASK_STATUS_IGNORED: 'Ignoriert',
            TASKS_AWAITING_APPROVAL: 'Warten auf die Genehmigung',
            TASK_IS_MANDATORY: 'Obligatorisch',
            SOFT_SERVICE: 'Soft Service',
            HARD_SERVICE: 'Hard Service',
            TASKS_MY_PLANNER: 'Mein Planer',
            SELECT_SITE_TO_CHOSE_SERVICE_PROVIDER: 'Bitte wählen Sie das Gebäude für die Auswahl des Dienstleisters.',
            BULK_ASSIGN_SERVICE_PROVIDERS: 'Massenzuweisung von Dienstanbietern',
            TASK_SERVICE_ROUTINES_MUST_MATCH: 'Die Frequenzen und Intervalle entsprechen nicht der Service-Routine des Assets.',
            TASKS_PLANNER_UPLOAD: 'Planer-Importe',
            TASK_IMPORTS: 'Importe',
            CURRENT_USER_IS_NOT_A_VALID_APPROVER: 'Sie sind kein gültiger PPM-Genehmigender.',
            CANNOT_SEND_SO_MANY_TASKS: 'Es können nicht so viele Aufgaben zur Genehmigung eingereicht werden. Das Limit ist auf 1000 festgelegt. Bitte verfeinern Sie Ihre Filter',
            CANNOT_GENERATE_SO_MANY_TASKS: 'Es können nicht so viele Aufgaben generiert werden. Das Limit ist auf 1000 festgelegt. Bitte verfeinern Sie Ihre Filter',
            CANNOT_REMOVE_SO_MANY_TASKS: 'Es können nicht so viele Aufgaben entfernt werden. Das Limit ist auf 1000 festgelegt. Bitte verfeinern Sie Ihre Filter',
            CANNOT_APPROVE_SO_MANY_TASKS: 'Es können nicht so viele Aufgaben genehmigt werden. Das Limit ist auf 1000 festgelegt. Bitte verfeinern Sie Ihre Filter',
            CANNOT_DECLINE_SO_MANY_TASKS: 'Es können nicht so viele Aufgaben abgelehnt werden. Das Limit ist auf 1000 festgelegt. Bitte verfeinern Sie Ihre Filter',
            ONE_OR_MORE_RULES_MISSING: 'Eine oder mehrere Frequenzregeln fehlen',
            CANCEL_SELECTED: 'Ausgewählte abbrechen ({{ count }})',
            CANCEL_FILTERED: 'Alle Gefilterten abbrechen',
            TASKS_SELECTED: 'Aufgaben ausgewählt',
            TASKS_POINT_IN_TIME: 'Die Aufgaben zu diesem Zeitpunkt entsprechen Ihren Suchkriterien',
            ALL_FILTERED_TASKS: 'Alle gefilterten Aufgaben',
            CANCEL_TASKS: 'Aufgaben abbrechen',
            TASKS_QUEUED_FOR_CANCELLATION: 'Zum Abbruch anstehende Aufgaben',
            NO_ITEMS: 'Keine zu stornierenden Artikel',
            TASK_START_DATE_WARNING: 'Das Startdatum kann nicht vor dem 1. Januar liegen {{ lastYear }}',
            TAG: 'Ordner',
            TAGS: 'Ordner',
            TAG_LIST: 'Ordner',
            TAG_CREATED: 'Ordner erstellt',
            TAG_UPDATED: 'Ordner aktualisiert',
            TAG_DELETED: 'Ordner gelöscht',
            TAG_ADD: 'Einen neuen Ordner hinzufügen',
            TAG_ACTION: 'Maßnahme: {{ ::tag.entityId }}',
            TAG_CONTRACT: 'Vertrag: {{ ::tag.entityId }}',
            TAG_JOB: 'Auftrag: {{ ::tag.entityId }}',
            TAG_JOBREQUISITEANSWER: 'Antwort Auftragsanforderung',
            TAG_PLANNEDJOB: 'Geplanter Auftrag: {{ ::tag.entityId }}',
            TAG_QUOTE: 'Angebot: {{ ::tag.entityId }}',
            TAG_QUOTEOPTION: 'Angebotsoption',
            TAG_QUOTEREQUEST: 'Angebotsanforderung',
            TAG_REACTIVEJOB: 'Auftrag: {{ ::tag.entityId }}',
            TAG_SITE: '{{ ::tag.name }}',
            TAG_SPILLAGE: 'Verschüttung: {{ ::tag.entityId }}',
            AND_MORE: '...und {{ ::number | number:0 }} mehr',
            TAG_EXCEEDS_CHAR_LIMIT: 'Der Tag überschreitet die Zeichengrenze von 255 Zeichen.',
            TAG_CHAR_MIN_LIMIT: 'Der Tag-Name sollte mindestens 1 Zeichen haben',
            TAG_MISSING_NAME: 'Tag-Name nicht vorhanden',
            SCHEDULES: 'Zeitpläne',
            SURVEY_SCHEDULES: 'Umfragezeitpläne',
            SURVEY_SCHEDULE: 'Zeitplan',
            SURVEYSCHEDULE_ADD: 'Zeitplan hinzufügen',
            SURVEY_REQUEST_ADD: 'KPI-Umfrage hinzufügen',
            ADD_NEW_SURVEY_REQUEST: 'Eine neue KPI-Umfrage hinzufügen',
            ACCEPT_SURVEY: 'KPI-Umfrage akzeptieren',
            ADD_NEW_SURVEY: 'Neue KPI-Umfrage hinzufügen',
            ADD_SURVEY: 'KPI-Umfrage hinzufügen',
            ADD_SURVEY_LINE: 'KPI-Umfragezeile hinzufügen',
            EDIT_SURVEY: 'KPI-Umfrage bearbeiten',
            FM_COMMENT: 'FM-Kommentar',
            FM_SCORE: 'FM-Ergebnis',
            CALCULATED_SCORE: 'Ergebnis',
            LAST_UPDATED: 'Letzte Aktualisierung',
            MAXIMUM_SCORE: 'Maximales Ergebnis',
            REPORTING_PERIOD_MONTH_YEAR: 'Berichtszeitraum',
            RESET_LINES: 'Linien zurücksetzen',
            SAVE_CHANGES: 'Änderungen speichern',
            SELECT_SCORE_RANGE: 'Ergebnisbereich',
            SP_COMMENT: 'SP-Kommentar',
            SP_SCORE: 'SP-Ergebnis',
            SPSCORE_FMSCORE: 'SP/FM-Ergebnis',
            SUBMIT_SURVEY: 'KPI-Umfrage einreichen',
            SURVEY_ID: 'KPI-Umfrage-ID',
            SURVEY_LINES: 'Umfragezeilen',
            SURVEY_SITES: 'KPI-Umfrage-Gebäude',
            SURVEY_SITES_ADD: 'Gebäude hinzufügen',
            SURVEY_REQUESTS: 'Anforderungen KPI-Umfrage',
            WEIGHT_1_TO_5: 'Gewicht (1 bis 5)',
            REPORTING_MONTH: 'Berichtsmonat',
            REPORTING_YEAR: 'Berichtsjahr',
            ALLOW_SP_TO_COMPLETE_THIS_SURVEY: 'Erlauben Sie Arbeitern des Dienstleisters, diese KPI-Umfrage auszufüllen',
            SCORE_LESS_THAN: 'Ergebnis unter',
            NO_ACTIVE_SURVEYS_FOR_SERVICE_PROVIDER: 'Es gibt derzeit keine aktiven KPI-Umfragen für diesen Dienstleister',
            STATUS_IN_PROGRESS: 'In Bearbeitung',
            STATUS_PENDING: 'Ausstehend',
            STATUS_COMPLETED: 'Abgeschlossen',
            STATUS_DONE: 'Erledigt',
            STATUS_DONE_WITH_ERRORS: 'Erledigt (mit Fehlern)',
            STATUS_INVALID_FILE: 'Ungültige Datei',
            STATUS_INVALID_TYPE: 'Typ ungültig',
            STATUS_OPEN: 'Offen (Aktiv oder Ausstehend)',
            STATUS_CLOSED: 'Geschlossen',
            LAST_RUN: 'Letzter Lauf',
            NEXT_RUN: 'Nächster Lauf',
            NO_SURVEYS_FOR_SERVICE_PROVIDER: 'Es gibt derzeit keine aktiven KPI-Umfragen für diesen Dienstleister',
            SP_EDITABLE: 'SP Editierbar',
            SERVICEPROVIDER: 'Dienstleister',
            SERVICEPROVIDER_GROUP: 'Dienstleistergruppe',
            SURVEY: 'Umfrage',
            SPEDITABLE: 'SP Editierbar',
            CREATEDAT: 'Erstellt in',
            UPDATEDAT: 'Aktualisierung in',
            SURVEYSCHEDULE_EDIT: 'Umfragezeitplan bearbeiten',
            SURVEY_SCHEDULE_CREATED: 'Umfragezeitplan erstellt',
            SURVEY_SCHEDULE_UPDATED: 'Umfragezeitplan aktualisiert',
            SURVEY_SCHEDULE_DELETED: 'Umfragezeitplan gelöscht',
            SURVEY_SAVED: 'Umfrage gespeichert',
            SURVEY_SUBMITTED: 'Umfrage eingereicht',
            SURVEY_ACCEPTED: 'Umfrage angenommen',
            SITES_ADD: 'Gebäude zu KPI-Umfrage hinzufügen',
            ERROR_NOT_ALLOWED_TO_UPDATE_FM_AND_SP_SCORE: 'Sie sind nicht berechtigt, FM- und SP-Ergebnisse zu aktualisieren',
            ERROR_NOT_ALLOWED_TO_UPDATE_FM_SCORE: 'Sie sind nicht berechtigt, das FM-Ergebnis zu aktualisieren',
            ERROR_NOT_ALLOWED_TO_UPDATE_SP_SCORE: 'Sie sind nicht berechtigt, das SP-Ergebnis zu aktualisieren',
            PENDING_KPI_SURVEYS: 'Ausstehende KPI-Umfragen',
            SURVEY_ACTIVE_STATE_CHANGED: 'Umfragestatus geändert',
            QUESTION_ANSWERED_CANNOT_DELETE: 'Beantwortete Frage kann nicht gelöscht werden',
            SURVEYS: 'KPI-Umfragen',
            SURVEY_LIST: 'KPI-Umfragen',
            SURVEY_VIEW: 'Info',
            SURVEY_ADD: 'Eine neue KPI-Umfrage hinzufügen',
            SURVEY_EDIT: 'KPI-Umfrage bearbeiten',
            CHANGE_SITE_SURVEY_ACCESS: 'KPI-Umfrage zu Gebäude hinzufügen',
            SITE_ADDED: 'KPI-Umfrage hinzugefügt',
            SURVEY_CREATED: 'KPI-Umfrage erstellt',
            SURVEY_UPDATED: 'KPI-Umfrage aktualisiert',
            SURVEY_DELETED: 'KPI-Umfrage gelöscht',
            SURVEY_TO_SUBMIT: 'KPI-Umfragen zum Einreichen',
            SURVEY_TO_APPROVE: 'KPI-Umfragen zur Genehmigung',
            OUTSTANDING_SURVEYS: 'Herausragende KPI-Umfragen',
            SURVEY_INACTIVE: 'Umfrage inaktiv - Aktualisierung nicht möglich',
            NO_RULES_ENTERED: 'Keine Regeln eingegeben',
            AUTOMATED_STATS: 'Automatisierte Statistiken',
            SELECT_STATS: 'Statistiken auswählen',
            STAT_TYPE: 'Statistik-Typ',
            UPDATE_STATS: 'Statistiken aktualisieren',
            STATISTIC: 'Statistik',
            SURVEY_STATS_UPDATED: 'Umfrage-Statistiken aktualisiert',
            SITES_DELETED_FROM_SURVEY: 'Aus der Umfrage gelöschte Gebäude',
            KPI_SURVEY_SCHEDULES: 'KPI-Umfragezeitpläne',
            SURVEY_LINE: 'Umfragezeile',
            SURVEY_LINE_LIST: 'Umfragezeilen',
            SURVEY_LINE_ADD: 'Eine neue Umfragezeile hinzufügen',
            SURVEY_LINE_EDIT: 'Umfragezeile bearbeiten',
            SURVEY_LINE_CREATED: 'Umfragezeile erstellt',
            SURVEY_LINE_UPDATED: 'Umfragezeile aktualisiert',
            SURVEY_LINE_DELETED: 'Umfragezeile gelöscht',
            SURVEY_LINES_UPDATED: 'Umfragezeilen aktualisiert',
            VALUE_MUST_BE_A_NUMBER_BETWEEN_1_AND_5: 'Wert muss eine Zahl zwischen 1 und 5 sein',
            COMMENT_3000_OR_LESS: 'Kommentar muss weniger als 3000 Zeichen betragen',
            STATUS_ACTIVE: 'Aktiv',
            STATUS_INACTIVE: 'Inaktiv',
            STATUS_DRAFT: 'Entwurf',
            STATUS_TRAINING: 'Schulung',
            SPILLAGES: 'Verschüttungen',
            SPILLAGE_LIST: 'Verschüttungen',
            SPILLAGE: 'Verschüttung',
            SPILLAGE_TYPE: 'Verschüttungsart',
            ACCIDENT_OCCURRED: 'Unfall aufgetreten',
            SPILLAGES_ADD: 'Verschüttung hinzufügen',
            HAS_ACCIDENT_OCCURRED: 'Unfall aufgetreten?',
            I_AM_REPORTER: 'Ich bin der Berichterstatter',
            REPORT_SPILLAGE: 'Verschüttung berichten',
            REPORTER_NAME: 'Name des Berichterstatters',
            SELECT_LOCATION: 'Standort auswählen',
            SPILLAGE_ADD: 'Eine neue Verschüttung protokollieren',
            SPILLAGE_CREATED: 'Verschüttung erstellt',
            SITE_LOCATION: 'Gebäudestandort',
            I_AM_NOT_NEAR_SPILLAGE: 'Ich bin nicht in der Nähe der Verschüttung',
            ASSIGNED_TO_ME: 'Mir zugewiesen',
            SITE_REPORTER: 'Gebäude-Berichterstatter',
            ASSIGN_TO_ME: 'Mir zuweisen',
            SPILLAGE_DETAILS_WITH_ID: 'Verschüttung #{{ ::id }} Details',
            SPILLAGE_OPEN: 'Offen',
            SPILLAGE_ACCEPTED: 'Akzeptiert',
            SPILLAGE_OPEN_PENDING_PHOTO: 'Foto ausstehend',
            SPILLAGE_CANCELLED: 'Abgebrochen',
            SPILLAGE_IN_PROGRESS: 'In Bearbeitung',
            SPILLAGE_STARTED: 'Begonnen',
            SPILLAGE_COMPLETED: 'Abgeschlossen',
            SPILLAGE_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat die Verschüttung erstellt.',
            SPILLAGE_CREATED_SYSTEM_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat die Verschüttung erstellt.',
            SPILLAGE_MESSAGE_ACTION: '<strong>{{ ::message._links["sender"].title }}</strong> hat ein Update zur Verfügung gestellt.',
            SPILLAGE_REASSIGNED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat die Verschüttung neu zugewiesen.',
            SPILLAGE_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat die Verschüttung storniert.',
            SPILLAGE_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat die Verschüttung akzeptiert.',
            SPILLAGE_STARTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat die Verschüttung begonnen.',
            SPILLAGE_COMPLETED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat die Verschüttung abgeschlossen.',
            SPILLAGE_CHASED_ACTION: 'Eine Verfolgungsbenachrichtigung #{{ ::event.chaseSequence }} wurde gesendet an <strong>{{ ::event._links["user"].title }}</strong>.',
            SPILLAGE_CHASED_SYSTEM_ACTION: 'Eine Verfolgungsbenachrichtigung #{{ ::event.chaseSequence }} wurde gesendet.',
            SPILLAGE_ESCALATION_CHASED_ACTION: 'Eine Benachrichtigung zur Eskalationsverfolgung #{{ ::event.chaseSequence }} wurde gesendet an <strong>{{ ::event._links["user"].title }}</strong>.',
            SPILLAGE_ESCALATION_CHASED_SYSTEM_ACTION: 'Eine Benachrichtigung zur Eskalationsverfolgung #{{ ::event.chaseSequence }} wurde gesendet.',
            SPILLAGE_WORKFLOW_REASSIGN: 'Verschüttung neu zuweisen',
            SPILLAGE_WORKFLOW_REASSIGN_SUBMIT: 'Neu zuweisen',
            SPILLAGE_WORKFLOW_REASSIGN_SUCCESS: 'Verschüttung neu zugewiesen',
            SPILLAGE_WORKFLOW_CANCEL: 'Verschüttung abbrechen',
            SPILLAGE_WORKFLOW_CANCEL_SUBMIT: 'Verschüttung abbrechen',
            SPILLAGE_WORKFLOW_CANCEL_SUCCESS: 'Verschüttung abgebrochen',
            SPILLAGE_OPEN_CHASE_FREQ_BEFORE: 'Verfolgungsfrequenz OFFEN - Erste Verfolgung (Min.)',
            SPILLAGE_OPEN_CHASE_FREQ_AFTER: 'Verfolgungsfrequenz OFFEN - Zusätzliche Verfolgungen (Min.)',
            SPILLAGE_ACCEPTED_CHASE_FREQ_BEFORE: 'Verfolgungsfrequenz AKZEPTIERT - Erste Verfolgung (Min.)',
            SPILLAGE_ACCEPTED_CHASE_FREQ_AFTER: 'Verfolgungsfrequenz AKZEPTIERT - Zusätzliche Verfolgungen (Min.)',
            SPILLAGE_IN_PROGRESS_CHASE_FREQ_BEFORE: 'Verfolgungsfrequenz IN BEARBEITUNG - Erste Verfolgung (Min.)',
            SPILLAGE_IN_PROGRESS_CHASE_FREQ_AFTER: 'Verfolgungsfrequenz IN BEARBEITUNG - Zusätzliche Verfolgungen (Min.)',
            SPILLAGE_ESCALATION_INTERVAL_REPEAT: 'Eskalationsintervall - Wiederholung (Min.)',
            SPILLAGE_ESCALATION_INTERVAL: 'Eskalationsintervall (Min.)',
            SPILLAGE_ESCALATION_SITEASSOCIATE_TYPE: 'Eskalation Typen Gebäudepartner',
            SPILLAGE_SETTINGS_VIEW: 'Einstellungen Verschüttung',
            FROM_SPILLAGE: 'Von Verschüttung',
            ADD_AN_ACTION: 'Eine Maßnahme hinzufügen',
            ADD_A_JOB: 'Einen Auftrag hinzufügen',
            ADD_A_QUOTE: 'Ein Angebot hinzufügen',
            SPILLAGE_TYPES: 'Verschüttungsarten',
            ADD_NEW_SPILLAGE_TYPE: 'Eine neue Verschüttungsart hinzufügen',
            EDIT_SPILLAGE_TYPE: 'Verschüttungsart bearbeiten',
            SPILLAGE_TYPE_LIST: 'Verschüttungsarten',
            SPILLAGE_TYPE_ADD: 'Eine neue Verschüttungsart hinzufügen',
            SPILLAGE_TYPE_EDIT: 'Verschüttungsart bearbeiten',
            SPILLAGE_TYPE_CREATED: 'Verschüttungsart erstellt',
            SPILLAGE_TYPE_UPDATED: 'Verschüttungsart aktualisiert',
            SPILLAGE_TYPE_DELETED: 'Verschüttungsart gelöscht',
            SPILLAGE_TYPE_ADDED: 'Verschüttungsart hinzugefügt',
            SPILLAGE_JOB_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> hat einen Auftrag erstellt',
            SPILLAGE_QUOTE_REQUEST_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> hat eine Angebotsanforderung erstellt',
            SPILLAGE_ACTION_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> hat die Maßnahme erstellt',
            SITE_GROUP: 'Gebäudegruppe',
            SITE_GROUPS: 'Gebäudegruppen',
            SITE_GROUP_LIST: 'Gebäudegruppen',
            SITE_GROUP_ADD: 'Eine neue Gebäudegruppe hinzufügen',
            SITE_GROUP_EDIT: 'Gebäudegruppe bearbeiten',
            EDIT_SITE_GROUP: 'Gebäudegruppe bearbeiten',
            SITE_GROUP_SITE_LIST: 'Gebäude Gebäudegruppe',
            ADD_NEW_SITE_GROUP: 'Eine neue Gebäudegruppe hinzufügen',
            MANAGE_SITE_GROUP_SITES: 'Gebäude verwalten',
            SITE_GROUP_SITE_ADDED: 'Gebäude zu Gebäudegruppe hinzugefügt',
            SITES_DELETED_FROM_SITE_GROUP: 'Gebäude von Gebäudegruppe gelöscht',
            SITE_GROUP_CREATED: 'Gebäudegruppe erstellt',
            SITE_GROUP_UPDATED: 'Gebäudegruppe aktualisiert',
            SITE_GROUP_DELETED: 'Gebäudegruppe gelöscht',
            SITE: 'Gebäude',
            SITES: 'Gebäude',
            SITES_LIST: 'Gebäudeliste',
            ADD_NEW_SITE: 'Einen neuen Gebäude hinzufügen',
            EDIT_SITE: 'Gebäude bearbeiten',
            VIEW_SITE: 'Standort anzeigen',
            ADD_SITE: 'Gebäude hinzufügen',
            ADDRESSES: 'Adressen',
            CITY: 'Ort',
            COUNTY_STATE_PROVINCE_REGION: 'Landkreis/Region',
            POSTAL_CODE_ZIP: 'Postleitzahl',
            COUNTRY: 'Land',
            CONTACT_INFO_TYPE: 'Kontakttyp',
            CONTACT_ADDRESS_ADDED: 'Kontaktadresse hinzugefügt',
            CONTACT_ADDRESS_UPDATED: 'Kontaktadresse aktualisiert',
            CONTACT_ADDRESS_DELETED: 'Kontaktadresse gelöscht',
            MANAGE_SITES: 'Gebäude verwalten',
            ALL_SITES: 'Alle Gebäude',
            SELECTED_SITES: 'Ausgewählte Gebäude',
            CONTACT_INFORMATION: 'Kontaktinformationen',
            CONTACT_INFORMATION_ADDRESS: 'Kontaktinformationen und Anschrift',
            SITE_CONTACT: 'Gebäudekontakt',
            SITE_IMAGE: 'Gebäudebild',
            SITE_MEMBERSHIP: 'Gebäude-Mitgliedschaft',
            SITE_MEMBERSHIPS: 'Gebäude-Mitgliedschaften',
            SITE_MEMBERSHIP_LIST: 'Liste Gebäude-Mitgliedschaft',
            SITE_MEMBERSHIP_ADD: 'Gebäude-Mitgliedschaft hinzufügen',
            SITE_MEMBERSHIP_EDIT: 'Gebäude-Mitgliedschaft bearbeiten',
            SITE_MEMBERSHIP_CREATED: 'Gebäude-Mitgliedschaft erfolgreich erstellt',
            SITE_MEMBERSHIP_UPDATED: 'Gebäude-Mitgliedschaft erfolgreich aktualisiert',
            SITE_MEMBERSHIP_DELETED: 'Gebäude-Mitgliedschaft gelöscht',
            IMAGE_DELETED: 'Bild gelöscht',
            REQUIRE_PURCHASE_ORDER: 'Bestellung erforderlich?',
            EXCLUDE_PCD: 'Von PCD ausschließen?',
            EXCLUDE_P2P: 'Von P2P ausschließen?',
            EXCLUDE_FROM_HELPDESK_MONITOR: 'Vom Helpdesk-Monitor ausschließen?',
            SITE_P2P_STARTED_AT_DATE: 'P2P Gestartet am',
            AREA: 'Fläche',
            UNIT: 'Einheit',
            SITE_CONTACTS: 'Gebäudekontakte',
            ADD_NEW_SITE_CONTACT: 'Gebäudekontakt hinzufügen',
            EDIT_CONTACT: 'Gebäudekontakt bearbeiten',
            SITE_MANAGER: 'Baustellenleiter',
            SITE_MANAGERS: 'Baustellenleiter',
            SITE_LIST: 'Gebäude',
            SITE_ADD: 'Einen neuen Gebäude hinzufügen',
            SITE_EDIT: 'Gebäude bearbeiten',
            SITE_CREATED: 'Gebäude wurde erfolgreich erstellt',
            SITE_UPDATED: 'Gebäude wurde erfolgreich aktualisiert',
            SITE_DELETED: 'Gebäude gelöscht',
            A_SITE_CONTACT_MUST_HAVE_CONTACT_INFORMATION: 'Ein Gebäudekontakt muss Kontaktinformationen haben',
            A_SITE_CONTACT_MUST_HAVE_A_VALID_NAME: 'Ein Gebäudekontakt muss einen gültigen Namen haben',
            ADD_NEW_SITE_NOTE_TYPE: 'Einen neuen Typ von Gebäudehinweisen hinzufügen',
            SITE_NOTE_TYPE_TITLE: 'Titel',
            ALLOW_REQUIRE_FM: 'Erlauben Sie, dass dies als FM-Genehmigung erforderlich gekennzeichnet wird',
            SITE_NOTE_TYPE: 'Typ von Gebäudehinweisen',
            SITE_NOTE_TYPES: 'Typen von Gebäudehinweisen',
            NOT_APPLICABLE: 'Nicht zutreffend',
            REQUIRE_FM: 'FM-Genehmigung erforderlich',
            SITE_REQUIRES_FM_APPROVAL: 'FM-Genehmigung ist erforderlich',
            NO_DATA: 'Für dieses Feld sind noch keine Daten eingegeben worden',
            SITE_CHOOSE_A_USER: 'Einen Benutzer auswählen',
            SITE_ENTER_DETAILS_MANUALLY: 'Details manuell eingeben',
            SITE_NOTES: 'Hinweise',
            SITE_ACCESS: 'Zugang zum Gebäude',
            SITE_ONE_ADDRESS: 'Das Gebäude kann nur eine Adresse haben',
            SEARCH_SITES_BY_NAME: 'Gebäude nach Namen suchen',
            SITE_INFORMATION: 'Informationen zum Gebäude',
            ASSOCIATES_UPDATED: 'Gebäudepartner aktualisiert',
            ASSOCIATES: 'Partner',
            EDIT_ADDRESS: 'Adresse bearbeiten',
            SITE_CONTACT_ADD: 'Gebäudekontakt hinzufügen',
            SITE_CONTACT_EDIT: 'Gebäudekontakt bearbeiten',
            CONTACT_CONTACT_CREATED: 'Gebäudekontakt erstellt',
            CONTACT_CONTACT_UPDATED: 'Gebäudekontakt aktualisiert',
            CONTACT_CONTACT_DELETED: 'Gebäudekontakt gelöscht',
            ADD_LOCATION: 'Standort hinzufügen',
            EDIT_LOCATION: 'Standort bearbeiten',
            SITE_NOTE_UPDATED: 'Gebäudehinweis aktualisiert',
            SITE_NOTE_CREATED: 'Gebäudehinweis erstellt',
            SITE_THRESHOLD_DELETED: 'Gebäudeschwellenwert gelöscht',
            SITE_THRESHOLD_VALUE_ADDED: 'Gebäudeschwellenwert hinzugefügt',
            NO_CONTACT_INFORMATION_ASSIGNED: 'Keine Kontaktinformationen zugewiesen',
            SITE_STATUS: 'Gebäudestatus',
            REFERENCE_PREFIX: 'Referenzpräfix',
            SITE_USER_ALREADY_HAS_ASSOCIATION_WITH_SITE: 'Der Benutzer ist bereits mit diesem Gebäude verknüpft.',
            SITE_USER_HAS_NO_ASSOCIATION_WITH_SITE: 'Der Benutzer ist nicht mit diesem Gebäude verknüpft.',
            SITE_CANNOT_ADD_FOR_SITES_YOU_ARE_NOT_ASSOCIATED_WITH: 'Sie können keine Verknüpfungen für Gebäude hinzufügen, mit denen Sie nicht verknüpft sind.',
            KEYHOLDER: 'Keyholder',
            SITE_NOT_ACTIVE: 'Gebäude nicht aktiv',
            SITE_RELATIONSHIPS: 'Gebäude-Beziehungen',
            SITE_RELATIONSHIP_INFORMATION: 'Information Gebäude-Beziehung',
            ADD_NEW_SITE_RELATIONSHIP: 'Eine neue Gebäude-Beziehung hinzufügen',
            EDIT_SITE_RELATIONSHIP: 'Gebäude-Beziehung bearbeiten',
            SITE_RELATIONSHIP_LIST: 'Gebäude-Beziehungen',
            SITE_RELATIONSHIP_ADD: 'Eine neue Gebäude-Beziehung hinzufügen',
            SITE_RELATIONSHIP_EDIT: 'Gebäude-Beziehung bearbeiten',
            SITE_RELATIONSHIP_CREATED: 'Gebäude-Beziehung erfolgreich erstellt',
            SITE_RELATIONSHIP_UPDATED: 'Gebäude-Beziehung erfolgreich aktualisiert',
            SITE_RELATIONSHIP_DELETED: 'Gebäude-Beziehung erfolgreich gelöscht',
            SITE_PERMISSION_EDIT_KPIS: 'KPIs bearbeiten',
            SITE_PERMISSION_VIEW_FILES: 'Dateien anzeigen',
            SITE_PERMISSION_EDIT_FILES: 'Dateien bearbeiten',
            SITE_PERMISSION_VIEW_NOTES: 'Hinweise anzeigen',
            SITE_PERMISSION_EDIT_NOTES: 'Hinweise bearbeiten',
            SITE_PERMISSION: 'Gebäudeberechtigungen',
            SITE_PERMISSION_CAN_BE_APPROVER: 'Kann Genehmigender sein',
            SITE_PERMISSION_CAN_BE_ASSOCIATE: 'Kann Partner sein',
            SITE_PERMISSION_CAN_CANCEL_PATROLS: 'Kann Patrouillen abbrechen',
            SITE_PERMISSION_CREATE_JOBS: 'Aufträge erstellen',
            SITE_PERMISSION_CREATE_ACTION: 'Maßnahmen erstellen',
            SITE_PERMISSION_CREATE_ASSETS: 'Assets erstellen',
            SITE_PERMISSION_CREATE_AUDIT_TEMPLATES: 'Audit-Vorlagen erstellen',
            SITE_PERMISSION_CREATE_AUDITS: 'Audits erstellen',
            SITE_PERMISSION_CREATE_KPIS: 'KPIs erstellen',
            SITE_PERMISSION_CREATE_QUOTES: 'Angebote erstellen',
            SITE_PERMISSION_CREATE_TASKS: 'Aufgaben erstellen',
            SITE_PERMISSION_CREATE_ACTIONS: 'Maßnahmen erstellen',
            SITE_PERMISSION_EDIT: 'Gebäude bearbeiten',
            SITE_PERMISSION_EDIT_ACTIONS: 'Maßnahmen bearbeiten',
            SITE_PERMISSION_EDIT_APPROVERS: 'Genehmiger bearbeiten',
            SITE_PERMISSION_EDIT_ASSETS: 'Assets bearbeiten',
            SITE_PERMISSION_EDIT_ASSOCIATES: 'Partner bearbeiten',
            SITE_PERMISSION_EDIT_CONTACTS: 'Kontakte bearbeiten',
            SITE_PERMISSION_EDIT_CONTRACTS: 'Verträge bearbeiten',
            SITE_PERMISSION_EDIT_JOBS: 'Aufträge bearbeiten',
            SITE_PERMISSION_EDIT_LOCATIONS: 'Standorte bearbeiten',
            SITE_PERMISSION_EDIT_METER_READINGS: 'Zählerablesungen bearbeiten',
            SITE_PERMISSION_EDIT_METERINFO: 'Zähler-Info bearbeiten',
            SITE_PERMISSION_EDIT_METERS: 'Zähler bearbeiten',
            SITE_PERMISSION_EDIT_PARTNERSHIPS: 'Partnerschaften bearbeiten',
            SITE_PERMISSION_EDIT_PATROL_INSTANCES: 'Patrouillen-Instanzen bearbeiten',
            SITE_PERMISSION_EDIT_PRIORITIES: 'Prioritäten bearbeiten',
            SITE_PERMISSION_EDIT_QUOTES: 'Angebote bearbeiten',
            SITE_PERMISSION_EDIT_TASKS: 'Aufgaben bearbeiten',
            SITE_PERMISSION_EDIT_TENANTS: 'Mieter bearbeiten',
            SITE_PERMISSION_HELPDESK: 'Helpdesk',
            SITE_PERMISSION_MANAGE_PATROLS: 'Patrouillen verwalten',
            SITE_PERMISSION_TENANT: 'Mieter',
            SITE_PERMISSION_TENANT_PLUS: 'Mieter Plus',
            SITE_PERMISSION_VIEW: 'Gebäude anzeigen',
            SITE_PERMISSION_VIEW_AUDITS: 'Audits anzeigen',
            SITE_PERMISSION_EDIT_AUDIT_REFERENCE: 'Audit-Referenz bearbeiten',
            SITE_PERMISSION_VIEW_KPIS: 'KPIs anzeigen',
            SITE_PERMISSION_VIEW_PATROLS: 'Patrouillen anzeigen',
            SITE_PERMISSION_VIEW_ACTIONS: 'Maßnahmen anzeigen',
            SITE_PERMISSION_VIEW_APPROVERS: 'Genehmiger anzeigen',
            SITE_PERMISSION_VIEW_ASSETS: 'Assets anzeigen',
            SITE_PERMISSION_VIEW_ASSOCIATES: 'Partner anzeigen',
            SITE_PERMISSION_VIEW_CONTACTS: 'Kontakte anzeigen',
            SITE_PERMISSION_VIEW_CONTRACTS: 'Verträge anzeigen',
            SITE_PERMISSION_VIEW_JOBS: 'Aufträge einsehen',
            SITE_PERMISSION_VIEW_LOCATIONS: 'Standorte anzeigen',
            SITE_PERMISSION_VIEW_METERINFO: 'Zähler-Info anzeigen',
            SITE_PERMISSION_VIEW_METERS: 'Zähler anzeigen',
            SITE_PERMISSION_VIEW_PARTNERSHIPS: 'Partnerschaften anzeigen',
            SITE_PERMISSION_VIEW_PATROL_INSTANCES: 'Patrouillen-Instanzen anzeigen',
            SITE_PERMISSION_VIEW_PRIORITIES: 'Prioritäten anzeigen',
            SITE_PERMISSION_VIEW_QUOTES: 'Angebote anzeigen',
            SITE_PERMISSION_VIEW_TASKS: 'Aufgaben anzeigen',
            SITE_PERMISSION_VIEW_TENANTS: 'Mieter anzeigen',
            SITE_PERMISSION_VIEW_SURVEYS: 'Umfragen anzeigen',
            SITE_PERMISSION_EDIT_SURVEYS: 'Umfragen bearbeiten',
            SITE_PERMISSION_CREATE_SURVEYS: 'Umfragen erstellen',
            SITE_PERMISSION_VIEW_SURVEY_SCHEDULES: 'Umfragezeitpläne anzeigen',
            SITE_PERMISSION_EDIT_SURVEY_SCHEDULES: 'Umfragezeitpläne bearbeiten',
            SITE_PERMISSION_CREATE_SURVEY_SCHEDULES: 'Umfragezeitpläne erstellen',
            SITE_PERMISSION_FORCE_COMPLETE_AUDITS: 'Vollständige Audits erzwingen',
            SITE_PERMISSION_ASSET_USER_IMPORTS: 'Assets importieren',
            SITE_PERMISSION_TASK_USER_IMPORTS: 'Aufgaben importieren',
            SITE_PERMISSION_ASSET_TASK_USER_IMPORTS: 'Planer-Import',
            SITE_PERMISSION_VIEW_FILES_DETAIL: '',
            SITE_PERMISSION_EDIT_FILES_DETAIL: '',
            SITE_PERMISSION_VIEW_NOTES_DETAIL: '',
            SITE_PERMISSION_EDIT_NOTES_DETAIL: '',
            SITE_PERMISSION_CAN_BE_APPROVER_DETAIL: 'Kann Genehmigender sein',
            SITE_PERMISSION_CAN_BE_ASSOCIATE_DETAIL: 'Kann Partner sein',
            SITE_PERMISSION_CAN_CANCEL_PATROLS_DETAIL: 'Kann Patrouillen-Instanzen abbrechen',
            SITE_PERMISSION_CREATE_JOBS_DETAIL: 'Aufträge erstellen',
            SITE_PERMISSION_CREATE_ACTION_DETAIL: 'Maßnahmen erstellen',
            SITE_PERMISSION_CREATE_ASSETS_DETAIL: 'Assets erstellen',
            SITE_PERMISSION_CREATE_AUDIT_TEMPLATES_DETAIL: 'Audit-Vorlagen erstellen',
            SITE_PERMISSION_CREATE_AUDITS_DETAIL: 'Audits erstellen',
            SITE_PERMISSION_CREATE_KPIS_DETAIL: 'KPIs erstellen',
            SITE_PERMISSION_CREATE_QUOTES_DETAIL: 'Angebote erstellen',
            SITE_PERMISSION_CREATE_TASKS_DETAIL: 'Aufgaben erstellen',
            SITE_PERMISSION_CREATE_ACTIONS_DETAIL: 'Maßnahmen erstellen',
            SITE_PERMISSION_EDIT_DETAIL: 'Gebäude bearbeiten',
            SITE_PERMISSION_EDIT_ACTIONS_DETAIL: 'Maßnahmen bearbeiten',
            SITE_PERMISSION_EDIT_APPROVERS_DETAIL: 'Genehmiger bearbeiten',
            SITE_PERMISSION_EDIT_ASSETS_DETAIL: 'Assets bearbeiten',
            SITE_PERMISSION_EDIT_ASSOCIATES_DETAIL: 'Partner bearbeiten',
            SITE_PERMISSION_EDIT_CONTACTS_DETAIL: 'Kontakte bearbeiten',
            SITE_PERMISSION_EDIT_CONTRACTS_DETAIL: 'Verträge bearbeiten',
            SITE_PERMISSION_EDIT_JOBS_DETAIL: 'Aufträge bearbeiten',
            SITE_PERMISSION_EDIT_LOCATIONS_DETAIL: 'Standorte bearbeiten',
            SITE_PERMISSION_EDIT_METER_READINGS_DETAIL: 'Zählerablesungen bearbeiten',
            SITE_PERMISSION_EDIT_METERINFO_DETAIL: 'Zähler-Info bearbeiten',
            SITE_PERMISSION_EDIT_METERS_DETAIL: 'Zähler bearbeiten',
            SITE_PERMISSION_EDIT_PARTNERSHIPS_DETAIL: 'Partnerschaften bearbeiten',
            SITE_PERMISSION_EDIT_PATROL_INSTANCES_DETAIL: 'Patrouillen-Instanzen bearbeiten',
            SITE_PERMISSION_EDIT_PRIORITIES_DETAIL: 'Prioritäten bearbeiten',
            SITE_PERMISSION_EDIT_QUOTES_DETAIL: 'Angebote bearbeiten',
            SITE_PERMISSION_EDIT_TASKS_DETAIL: 'Aufgaben bearbeiten',
            SITE_PERMISSION_CANCEL_TASK_EVENTS: 'Aufgaben abbrechen',
            SITE_PERMISSION_CANCEL_TASK_EVENTS_DETAIL: 'Aufgaben und alle Aufträge dieser Aufgaben abbrechen',
            SITE_PERMISSION_EDIT_TENANTS_DETAIL: 'Mieter bearbeiten',
            SITE_PERMISSION_HELPDESK_DETAIL: 'Helpdesk',
            SITE_PERMISSION_MANAGE_PATROLS_DETAIL: 'Patrouillen verwalten',
            SITE_PERMISSION_TENANT_DETAIL: 'Mieter',
            SITE_PERMISSION_TENANT_PLUS_DETAIL: 'Mieter Plus',
            SITE_PERMISSION_VIEW_DETAIL: 'Gebäude anzeigen',
            SITE_PERMISSION_VIEW_AUDITS_DETAIL: 'Audits anzeigen',
            SITE_PERMISSION_EDIT_AUDIT_REFERENCE_DETAIL: 'Audit-Referenz bearbeiten, wenn Audit aktiv ist',
            SITE_PERMISSION_VIEW_KPIS_DETAIL: 'KPIs anzeigen',
            SITE_PERMISSION_VIEW_PATROLS_DETAIL: 'Patrouillen anzeigen',
            SITE_PERMISSION_VIEW_ACTIONS_DETAIL: 'Maßnahmen anzeigen',
            SITE_PERMISSION_VIEW_APPROVERS_DETAIL: 'Genehmiger anzeigen',
            SITE_PERMISSION_VIEW_ASSETS_DETAIL: 'Assets anzeigen',
            SITE_PERMISSION_VIEW_ASSOCIATES_DETAIL: 'Partner anzeigen',
            SITE_PERMISSION_VIEW_CONTACTS_DETAIL: 'Kontakte anzeigen',
            SITE_PERMISSION_VIEW_CONTRACTS_DETAIL: 'Verträge anzeigen',
            SITE_PERMISSION_VIEW_JOBS_DETAIL: 'Aufträge einsehen',
            SITE_PERMISSION_VIEW_LOCATIONS_DETAIL: 'Standorte anzeigen',
            SITE_PERMISSION_VIEW_METERINFO_DETAIL: 'Zähler-Info anzeigen',
            SITE_PERMISSION_VIEW_METERS_DETAIL: 'Zähler anzeigen',
            SITE_PERMISSION_VIEW_PARTNERSHIPS_DETAIL: 'Partnerschaften anzeigen',
            SITE_PERMISSION_VIEW_PATROL_INSTANCES_DETAIL: 'Patrouillen-Instanzen anzeigen',
            SITE_PERMISSION_VIEW_PRIORITIES_DETAIL: 'Prioritäten anzeigen',
            SITE_PERMISSION_VIEW_QUOTES_DETAIL: 'Angebote anzeigen',
            SITE_PERMISSION_VIEW_TASKS_DETAIL: 'Aufgaben anzeigen',
            SITE_PERMISSION_VIEW_TENANTS_DETAIL: 'Mieter anzeigen',
            SITE_PERMISSION_VIEW_SURVEYS_DETAIL: 'Umfragen anzeigen',
            SITE_PERMISSION_EDIT_SURVEYS_DETAIL: 'Umfragen bearbeiten',
            SITE_PERMISSION_CREATE_SURVEYS_DETAIL: 'Umfragen erstellen',
            SITE_PERMISSION_VIEW_SURVEY_SCHEDULES_DETAIL: 'Umfragezeitpläne anzeigen',
            SITE_PERMISSION_EDIT_SURVEY_SCHEDULES_DETAIL: 'Umfragezeitpläne bearbeiten',
            SITE_PERMISSION_CREATE_SURVEY_SCHEDULES_DETAIL: 'Umfragezeitpläne erstellen',
            SITE_PERMISSION_EMAIL_TENANTS: 'Mieter-Emailer',
            SITE_PERMISSION_EMAIL_TENANTS_DETAIL: 'Kann Mieter-Emailer verwenden',
            SITE_PERMISSION_FORCE_COMPLETE_AUDITS_DETAIL: 'Vollständige Audits erzwingen, ohne alle Fragen zu beantworten',
            SITE_PERMISSION_VIEW_EMAIL_LOGS: 'E-Mail-Protokolle anzeigen',
            SITE_PERMISSION_VIEW_EMAIL_LOGS_DETAIL: 'Kann die E-Mails anderer Benutzer einsehen',
            SITE_PERMISSION_P2P: 'P2P',
            SITE_PERMISSION_P2P_DETAIL: '',
            SITE_PERMISSION_ASSET_USER_IMPORTS_DETAIL: 'Assets importieren',
            SITE_PERMISSION_TASK_USER_IMPORTS_DETAIL: 'Aufgaben importieren',
            SITE_PERMISSION_ASSET_TASK_USER_IMPORTS_DETAIL: 'Die Befähigung, Aufgaben und Assets zu importieren',
            SITE_PERMISSION_JOB_OPERATIVE: 'Arbeiter anzeigen',
            SITE_PERMISSION_JOB_OPERATIVE_DETAIL: 'Befähigung, einen Arbeiter am Arbeitsplatz auszuwählen',
            SITE_PERMISSION_BULK_REASSIGN_JOBS: 'Massenneuzuweisung von Aufträgen',
            SITE_PERMISSION_BULK_REASSIGN_JOBS_DETAIL: 'Befähigung, Massenneuzuweisung von Aufträgen vorzunehmen.',
            SITE_PERMISSION_BULK_REASSIGN_OPERATIVE_JOBS: 'Auftragsarbeiter massenhaft neu zuweisen',
            SITE_PERMISSION_BULK_REASSIGN_OPERATIVE_JOBS_DETAIL: 'Befähigung zur Massenneuzuweisung von Auftragsarbeitern.',
            SITE_PERMISSION_BULK_CANCEL_JOBS: 'Aufträge massenweise stornieren',
            SITE_PERMISSION_BULK_CANCEL_JOBS_DETAIL: 'Die Befähigung, Aufträge massenweise zu stornieren.',
            USER_STILL_AN_APPROVER: 'Kann Genehmigender sein kann nicht entfernt werden.“ Dieser Benutzer ist immer noch ein Genehmigender für mindestens einen Gebäude.“',
            AT_LEAST_ONE_USER_STILL_AN_APPROVER: 'Mindestens ein Benutzer ist immer noch ein Genehmigender für einen Gebäude.',
            CANNOT_REMOVE_APPROVER_PERMISSION: 'Berechtigung kann nicht entfernt werden. Benutzer mit dieser Rolle sind derzeit Genehmigende.',
            SITE_PERMISSION_VIEW_AUDIT_TEMPLATES: 'Audit-Vorlagen anzeigen',
            SITE_PERMISSION_CREATE_PUBLIC_AUDIT_TEMPLATES: 'Öffentliche Audit-Vorlagen erstellen',
            SITE_PERMISSION_VIEW_AUDIT_SCHEDULES: 'Audit-Zeitpläne anzeigen',
            SITE_PERMISSION_CREATE_AUDIT_SCHEDULES: 'Audit-Zeitpläne erstellen',
            SITE_PERMISSION_VIEW_AUDIT_TEMPLATES_DETAIL: 'Audit-Vorlagen anzeigen',
            SITE_PERMISSION_CREATE_PUBLIC_AUDIT_TEMPLATES_DETAIL: 'Öffentliche Audit-Vorlagen erstellen',
            SITE_PERMISSION_VIEW_AUDIT_SCHEDULES_DETAIL: 'Audit-Zeitpläne anzeigen',
            SITE_PERMISSION_CREATE_AUDIT_SCHEDULES_DETAIL: 'Audit-Zeitpläne erstellen',
            SITE_PERMISSION_EDIT_METER_REFERENCES: 'Zählerreferenzen bearbeiten',
            SITE_PERMISSION_EDIT_METER_REFERENCES_DETAIL: 'Zählerreferenzen bearbeiten',
            SITE_PERMISSION_WRITE_NFC_TAGS: 'NFC-Tags schreiben',
            SITE_PERMISSION_WRITE_NFC_TAGS_DETAIL: 'NFC-Tags schreiben',
            SITE_PERMISSION_ACTIVATE_SURVEY: 'KPI-Umfrage aktivieren',
            SITE_PERMISSION_ACTIVATE_SURVEY_DETAIL: 'KPI-Umfrage aktivieren',
            SITE_PERMISSION_NOTIFY_UPDATES: 'Auftrags-/Angebotskontakte benachrichtigen',
            SITE_PERMISSION_NOTIFY_UPDATES_DETAIL: 'Auftrags-/Angebotskontakte über Updates benachrichtigen',
            SITE_PERMISSION_VIEW_LINK_JOBS: 'Links für Aufträge anzeigen',
            SITE_PERMISSION_VIEW_LINK_JOBS_DETAIL: 'Links für Aufträge anzeigen',
            SITE_PERMISSION_REMOVE_LINK_JOBS: 'Links für Aufträge löschen',
            SITE_PERMISSION_REMOVE_LINK_JOBS_DETAIL: 'Links für Aufträge löschen',
            SITE_PERMISSION_EDIT_SCORE_TYPES_ON_ASSET: 'Ergebnistypen im Asset bearbeiten',
            SITE_PERMISSION_EDIT_SCORE_TYPES_ON_ASSET_DETAIL: 'Ergebnistypen beim Bearbeiten von Assets bearbeiten',
            SITE_PERMISSION_OPEN_CLOSE: 'Aufzeichnungen über Geschäfte öffnen/schließen erstellen',
            SITE_PERMISSION_OPEN_CLOSE_DETAIL: 'Die Befähigung, Aufzeichnungen über Geschäfte öffnen/schließen erstellen',
            SITE_PERMISSION_VIEW_OPEN_CLOSE: 'Aufzeichnungen über Geschäfte öffnen/schließen anzeigen',
            SITE_PERMISSION_VIEW_OPEN_CLOSE_DETAIL: 'Die Befähigung, Aufzeichnungen über Geschäfte öffnen/schließen anzuzeigen',
            SITE_PERMISSION_CREATE_CONTRACTS: 'Verträge erstellen',
            SITE_PERMISSION_CREATE_CONTRACTS_DETAIL: 'Die Befähigung, Verträge zu erstellen',
            SITE_PERMISSION_CREATE_SPILLAGES: 'Verschüttungen berichten',
            SITE_PERMISSION_CREATE_SPILLAGES_DETAIL: 'Die Befähigung, Verschüttungen zu berichten',
            SITE_PERMISSION_EDIT_SPILLAGES: 'Berichtete Verschüttungen bearbeiten',
            SITE_PERMISSION_EDIT_SPILLAGES_DETAIL: 'Die Befähigung, berichtete Verschüttungen zu bearbeiten',
            SITE_PERMISSION_VIEW_SPILLAGES: 'Verschüttungen anzeigen',
            SITE_PERMISSION_VIEW_SPILLAGES_DETAIL: 'Die Befähigung, Verschüttungen anzuzeigen',
            SITE_PERMISSION_VIEW_FILTER_BAR: 'Filterleiste anzeigen',
            SITE_PERMISSION_VIEW_FILTER_BAR_DETAIL: 'Die Befähigung, Filterleiste anzuzeigen',
            SITE_PERMISSION_BULK_NOTIFY_FILES: 'Massenbenachrichtigungen Dateien',
            SITE_PERMISSION_BULK_NOTIFY_FILES_DETAIL: 'Die Befähigung zur Massenbenachrichtigung über abgelaufene Dateien',
            SITE_PERMISSION_VIEW_JOB_FILES: 'Auftragsdateien anzeigen',
            SITE_PERMISSION_VIEW_JOB_FILES_DETAIL: 'Die Befähigung, Dateien anzuzeigen, die unter Aufträge sichtbar sein sollten',
            SITE_PERMISSION_EDIT_JOB_FILES: 'Auftragsdateien bearbeiten',
            SITE_PERMISSION_EDIT_JOB_FILES_DETAIL: 'Die Befähigung, Dateien zu bearbeiten, die unter Aufträge sichtbar sein sollten',
            SITE_PERMISSION_VIEW_WASTE_ENTRIES: 'Abfalleinträge anzeigen',
            SITE_PERMISSION_VIEW_WASTE_ENTRIES_DETAIL: 'Die Befähigung, Abfalleinträge anzuzeigen',
            SITE_PERMISSION_CREATE_WASTE_ENTRIES: 'Abfalleinträge erstellen',
            SITE_PERMISSION_CREATE_WASTE_ENTRIES_DETAIL: 'Die Befähigung, Abfalleinträge zu erstellen',
            SITE_PERMISSION_EDIT_WASTE_ENTRIES: 'Abfalleinträge bearbeiten',
            SITE_PERMISSION_EDIT_WASTE_ENTRIES_DETAIL: 'Die Befähigung, Abfalleinträge zu bearbeiten',
            SITE_PERMISSION_IMPORT_WASTE_ENTRIES: 'Abfalleinträge importieren',
            SITE_PERMISSION_IMPORT_WASTE_ENTRIES_DETAIL: 'Die Befähigung, Abfalleinträge zu importieren',
            SITE_PERMISSION_CREATE_AUDIT_ANSWER_OPTIONS: 'Audit-Antwortoptionen erstellen',
            SITE_PERMISSION_CREATE_AUDIT_ANSWER_OPTIONS_DETAIL: 'Die Befähigung, Audit-Antwortoptionen zu erstellen',
            SITE_PERMISSION_EDIT_AUDIT_ANSWER_OPTIONS: 'Audit-Antwortoptionen bearbeiten',
            SITE_PERMISSION_EDIT_AUDIT_ANSWER_OPTIONS_DETAIL: 'Die Befähigung, Audit-Antwortoptionen zu bearbeiten',
            SITE_PERMISSION_VIEW_AUDIT_ANSWER_OPTIONS: 'Audit-Antwortoptionen anzeigen',
            SITE_PERMISSION_VIEW_AUDIT_ANSWER_OPTIONS_DETAIL: 'Die Befähigung, Audit-Antwortoptionen anzuzeigen',
            SITE_NOTE_TYPE_INFORMATION: 'Information zum Typ von Standworthinweisen',
            EDIT_SITE_NOTE_TYPE: 'Typ von Gebäudehinweisen bearbeiten',
            ALLOW_NOT_APPLICABLE: 'N/A zulassen',
            ALLOW_HELPDESK: 'Anzeige für Helpdesk zulassen',
            NOTE_VISIBILITY: 'Sichtbarkeit',
            VISIBILITY: 'Sichtbarkeit',
            VISIBILITY_PRIVATE: 'Privat',
            VISIBILITY_TENANT: 'Mieter',
            VISIBILITY_SERVICE_PROVIDER: 'Dienstleister',
            SITE_NOTE_TYPE_LIST: 'Typen von Gebäudehinweisen',
            SITE_NOTE_TYPE_ADD: 'Einen neuen Typ von Gebäudehinweisen hinzufügen',
            SITE_NOTE_TYPE_EDIT: 'Typ von Gebäudehinweisen bearbeiten',
            SITE_NOTE_TYPE_CREATED: 'Typ von Gebäudehinweisen wurde erfolgreich erstellt',
            SITE_NOTE_TYPE_UPDATED: 'Typ von Gebäudehinweisen wurde erfolgreich aktualisiert',
            SITE_NOTE_TYPE_DELETED: 'Typ von Gebäudehinweisen wurde erfolgreich gelöscht',
            SITE_ASSOCIATE_TYPE: 'Typ Gebäudepartner',
            SITE_ASSOCIATE_TYPES: 'Typen Gebäudepartner',
            SITE_ASSOCIATE_TYPE_INFORMATION: 'Information Typ Gebäudepartner',
            ADD_NEW_SITE_ASSOCIATE_TYPE: 'Einen neuen Typen Gebäudepartner hinzufügen',
            EDIT_SITE_ASSOCIATE_TYPE: 'Typ Gebäudepartner bearbeiten',
            SITE_ASSOCIATE_TYPE_LIST: 'Typen Gebäudepartner',
            SITE_ASSOCIATE_TYPE_ADD: 'Einen neuen Typen Gebäudepartner hinzufügen',
            SITE_ASSOCIATE_TYPE_EDIT: 'Typ Gebäudepartner bearbeiten',
            SITE_ASSOCIATE_TYPE_CREATED: 'Typ Gebäudepartner erstellt',
            SITE_ASSOCIATE_TYPE_UPDATED: 'Typ Gebäudepartner aktualisiert',
            SITE_ASSOCIATE_TYPE_DELETED: 'Typ Gebäudepartner gelöscht',
            SITE_ASSOCIATES: 'Gebäudepartner',
            SITE_ASSOCIATE_LIST: 'Gebäudepartner',
            NO_ASSOCIATES_ADDED: 'Keine Partner ausgewählt',
            CANNOT_DELETE_TYPE: 'Dieser Typ kann nicht gelöscht werden',
            GENERAL_SETTINGS: 'Allgemein',
            SETTINGS_UPDATED: 'Einstellungen aktualisiert',
            EMAIL_SETTINGS: 'E-Mail',
            PASSWORD_SETTINGS: 'Kennwort',
            JOB_SETTINGS: 'Auftrag',
            JOB_SETTINGS_VIEW: 'Auftragseinstellungen',
            JOB_CHASE_SETTINGS_VIEW: 'Auftrag Verfolgungseinstellungen',
            CHASE_SETTINGS_VIEW: 'Verfolgungseinstellungen',
            QUOTE_SETTINGS: 'Angebot',
            QUOTE_SETTINGS_VIEW: 'Angebotseinstellungen',
            CHASE_SETTINGS: 'Verfolgen',
            SURVEY_SETTINGS: 'Umfragen',
            SURVEY_SETTINGS_VIEW: 'Umfrageeinstellungen',
            PERFORMANCE_REPORTING_SETTINGS: 'Leistungsberichterstattung',
            PERFORMANCE_SETTINGS_VIEW: 'Einstellungen Leistungsberichterstattung',
            FILE_SETTINGS: 'Dateien',
            FILE_SETTINGS_VIEW: 'Dateieinstellungen',
            APP_SETTINGS: 'App',
            APP_SETTINGS_VIEW: 'App-Einstellungen',
            SETTINGS_EDIT: 'Bearbeitung der Einstellungen',
            GENERAL_SETTINGS_VIEW: 'Allgemeine Einstellungen',
            GENERAL_LOGO: 'System-Logo',
            GENERAL_OPERATING_HOURS: 'Betriebsstunden',
            GENERAL_OPENING_TIME: 'Öffnungszeit',
            GENERAL_CLOSING_TIME: 'Schließzeit',
            GENERAL_TASKS_GENERATE_AHEAD: 'Aufgaben einige Tage im Voraus erzeugen',
            GENERAL_TASKS_WILL_ALWAYS_GENERATE_NEXT_SUNDAY: 'Aufgaben werden immer bis zum nächsten Sonntag erzeugt',
            GENERAL_AUDITS_GENERATE_AHEAD: 'Audits einige Tage im Voraus erzeugen',
            GENERAL_ACTIONSITEASSOCIATETYPE: 'Maßnahme Partnertyp Abtretungsempfänger',
            GENERAL_ACTIONS_PAPERWORK_NOTE_REQUIRED: 'Hinweis erforderlich beim Ausfüllen der Unterlagen für Überprüfungsmaßnahmen',
            GENERAL_AUDITCOMPLETIONEMAILASSOCIATETYPE: 'Audit-Beendigung E-Mail-Partnertyp',
            ACTION_SITE_ASSOCIATE_TYPE_SETTING_ASSOCIATE_TYPE_INVALID: 'Unterlagenmaßnahme kann nicht ausgelöst werden, Einstellung für Maßnahme Partnertyp ungültig',
            ACTION_ASSOCIATES_NO_ASSOCIATES_ASSIGNED_FOR_THIS_SITE: 'Unterlagenmaßnahme nicht möglich, keine Partner für diesen Gebäude für die Einstellung Maßnahme Partnertyp Abtretungsempfänger',
            EMAIL_SETTINGS_VIEW: 'E-Mail-Einstellungen',
            EMAIL_HTML_SIGNATURE: 'E-Mail-Signatur (Html)',
            EMAIL_TEXT_SIGNATURE: 'E-Mail-Signatur (Text)',
            EMAIL_TENANT_LOGO: 'Logo Mieter-Emailer',
            PASSWORD_SETTINGS_VIEW: 'Kennwort-Einstellungen',
            PASSWORD_DAYS_TILL_CHANGE_NOTIFICATION: 'Anzahl der Tage zwischen den Aufforderungen',
            PASSWORD_DISALLOW_PREVIOUS_PASSWORDS: 'Vorherige Passwörter sperren',
            PASSWORD_FORCE_CHANGE: 'Änderung forcieren',
            PASSWORD_MIN_CHARACTERS: 'Mindestanzahl von Zeichen',
            PASSWORD_REQUIRE_NUMBER: 'Zahl erforderlich',
            PASSWORD_REQUIRE_PUNCTUATION: 'Zeichensetzung erforderlich',
            PASSWORD_REQUIRE_UPPERCASE: 'Großbuchstaben erforderlich',
            PASSWORD_COMPLEXITY: 'Kennwort-Komplexität',
            PASSWORD_CHANGE_FREQUENCY: 'Frequenz Kennwortänderung',
            JOB_GROUPS_REQUIRE: 'Auftragsgruppe erforderlich',
            JOB_GROUP_CHILD_SELECT_MANDATORY: 'Auftragsgruppe Untergeordnete Auswahl obligatorisch',
            JOB_EXTRA_SETTINGS_EDIT: 'Auftrag Zusätzliche Einstellungen',
            JOB_ACCEPTANCE_TERMS_TEXT: 'Auftragsannahme Nutzungsbedingungen',
            JOB_ACCEPTANCE_TERMS_FILE: 'Dokument Auftragsannahme Nutzungsbedingungen',
            JOB_SATISFACTION_SURVEY_FREQUENCY: 'Frequenz Zufriedenheitsumfrage',
            JOB_TENANT_SELECTION_WHEN_LOGGING: 'Erlauben Sie die Auswahl eines Mieters bei der Protokollierung eines Auftrags',
            JOB_APPROVAL_REMINDER: 'Genehmigungserinnerung',
            JOB_REASSIGNMENT_REMINDER: 'Erinnerung für Neuzuweisung',
            JOB_ACKNOWLEDGEMENT_REMINDER: 'Bestätigungserinnerung',
            JOB_REACTIVE_COMMENCEMENT_REMINDER: 'Beginnerinnerung reaktivieren',
            JOB_REACTIVE_COMPLETION_REMINDER: 'Beendigungserinnerung reaktivieren',
            JOB_REACTIVE_ALLOW_SELF_APPROVAL: 'Erlauben Sie sekundären Weiterleitungsgenehmigenden, ihre eigenen reaktiven Aufträge zu genehmigen',
            JOB_PLANNED_COMMENCEMENT_REMINDER: 'Geplante Beginnerinnerung',
            JOB_PLANNED_COMPLETION_REMINDER: 'Geplante Beendigungserinnerung',
            JOB_MINUTES_BEFORE_MUST_BE_APPROVED: 'Anzahl an Minuten, bevor ein Auftrag genehmigt werden muss',
            JOB_MINUTES_BEFORE_REASSIGNMENT_REMINDER: 'Anzahl der Minuten vor der Erinnerung für Neuzuweisung',
            JOB_MINUTES_BEFORE_ACKNOWLEDGEMENT_REMINDER: 'Anzahl der Minuten vor der Bestätigungserinnerung',
            JOB_MINUTES_BEFORE_REACTIVE_COMMENCEMENT_REMINDER: 'Anzahl der Minuten vor Reaktivieren der Beginnerinnerung',
            JOB_MINUTES_BEFORE_REACTIVE_COMPLETION_REMINDER: 'Anzahl der Minuten vor Reaktivieren der Beendigungserinnerung',
            JOB_MINUTES_BEFORE_PLANNED_COMMENCEMENT_REMINDER: 'Anzahl der Minuten vor Planung der Beginnerinnerung',
            JOB_MINUTES_BEFORE_PLANNED_COMPLETION_REMINDER: 'Anzahl der Minuten vor Planung der Beendigungserinnerung',
            JOB_ALLOW_SECONDARY_APPROVERS_TO_REOPEN_JOBS: 'Erlauben Sie sekundären Weiterleitungsgenehmigenden, reaktive und geplante Aufträge zu genehmigen',
            REQUIREJOBGROUP: 'Auftragsgruppe erforderlich',
            ALLOWTENANTSELECTION: 'Erlauben Sie die Auswahl eines Mieters bei der Protokollierung eines Auftrags',
            REACTIVESELFAPPROVAL: 'Erlauben Sie sekundären Weiterleitungsgenehmigenden, ihre eigenen reaktiven Aufträge zu genehmigen',
            REASSIGNMENTREMINDER: 'Erinnerung für Neuzuweisung',
            APPROVALREMINDER: 'Genehmigungserinnerung',
            ACKNOWLEDGEMENTREMINDER: 'Bestätigungserinnerung',
            REACTIVECOMMENCEMENTREMINDER: 'Beginnerinnerung reaktivieren',
            REACTIVECOMPLETIONREMINDER: 'Beendigungserinnerung reaktivieren',
            PLANNEDCOMMENCEMENTREMINDER: 'Geplante Beginnerinnerung',
            PLANNEDCOMPLETIONREMINDER: 'Geplante Beendigungserinnerung',
            JOBGROUPCHILDSELECTMANDATORY: 'Auftragsgruppe Untergeordnete Auswahl obligatorisch',
            ALLOWSECONDARYAPPROVERTOREOPENJOBS: 'Erlauben Sie sekundären Weiterleitungsgenehmigenden, reaktive und geplante Aufträge zu genehmigen',
            CHASE_SETTINGS_REACTIVE: 'Reaktivieren',
            CHASE_SETTINGS_REACTIVESTATUTORY: 'Gesetzlich reaktivieren',
            CHASE_SETTINGS_PLANNED: 'Geplant',
            CHASE_SETTINGS_PLANNEDSTATUTORY: 'Gesetzlich geplant',
            CHASE_SETTINGS_TYPE0_EDIT: 'Verfolgungsbearbeitung reaktivieren',
            CHASE_SETTINGS_TYPE1_EDIT: 'Gesetzliche Verfolgungsbearbeitung reaktivieren',
            CHASE_SETTINGS_TYPE2_EDIT: 'Geplante Verfolgungsbearbeitung',
            CHASE_SETTINGS_TYPE3_EDIT: 'Geplante gesetzliche Verfolgungsbearbeitung',
            CHASE_SETTINGS_TYPE4_EDIT: 'Angebot Verfolgungsbearbeitung',
            CHASE_SETTINGS_TYPE0_ADD: 'Verfolgungshinzufügung reaktivieren',
            CHASE_SETTINGS_TYPE1_ADD: 'Gesetzliche Verfolgungshinzufügung reaktivieren',
            CHASE_SETTINGS_TYPE2_ADD: 'Hinzufügen Geplante Verfolgung',
            CHASE_SETTINGS_TYPE3_ADD: 'Hinzufügen Geplante gesetzliche Verfolgung',
            CHASE_SETTINGS_TYPE4_ADD: 'Hinzufügen Angebot Verfolgung',
            CHASE_SETTINGS_QUOTE: 'Angebot',
            CHASE_SETTINGS_CONTRACTAPPROVAL: 'Genehmigung',
            CHASE_SETTINGS_CONTRACTEXPIRED: 'Abgelaufen',
            CHASE_TARGET: 'Ziel',
            CHASE_START_DATE: 'Anfangsdatum',
            CHASE_EMAIL_BODY: 'Benachrichtigung verfolgen',
            CHASE_SETTINGS_TYPE_ADD: 'Verfolgungseinstellung hinzufügen',
            CHASE_SETTINGS_TYPE_EDIT: 'Verfolgungseinstellung bearbeiten',
            CHASE_SETTING_UPDATED: 'Verfolgungseinstellung aktualisiert',
            CHASE_SETTING_REMOVED: 'Verfolgungseinstellung entfernt',
            CHASE_SETTINGS_REORDERED: 'Verfolgungseinstellungen neu geordnet',
            CHASE_BODY: 'Gehäuse',
            SITE_ASSOCIATE: 'Gebäudepartner',
            BODY: 'Gehäuse',
            TARGETSITE: 'Zielgebäude',
            TARGETOPERATIVE: 'Ziel Arbeiter',
            SEQUENCE: 'Abfolge',
            AFTERDUEDATEPERIOD: 'Nach Fälligkeitsfrist',
            AFTERUPDATEPERIOD: 'Nach Aktualisierungszeitraum',
            WEEKENDSPUBLICHOLIDAYS: 'Wochenenden und Feiertage einbeziehen?',
            TARGETAPPROVER: 'Ziel-Genehmigender',
            TARGETSUBMITTER: 'Ziel-Einreicher',
            QUOTE_TIMEOUT: 'Zeitüberschreitung',
            QUOTE_TIMEOUT_SUB_LABEL: 'Anzahl der Tage bis zum Ablauf',
            QUOTE_TERMS_TEXT: 'Angebot Geschäftsbedingungen',
            QUOTE_TERMS_FILE: 'Dokument Angebot Geschäftsbedingungen',
            TERMS_DOWNLOAD_TITLE: 'Hier klicken, um die aktuellen Geschäftsbedingungen anzuzeigen',
            QUOTE_EXTRA_SETTINGS_EDIT: 'Extra',
            QUOTE_EXTENSION_APPROVAL: 'Angebot Erweiterungsgenehmigung',
            QUOTE_REMINDER_AT: 'Angebot Beginnerinnerung',
            QUOTE_DISABLE_P2P_ON_ACCEPTANCE: 'P2P bei Angebotsannahme deaktivieren',
            SURVEY_TIMEOUT: 'Zeitüberschreitung',
            SURVEY_AUTOMATED_STATS_ENABLED: 'Automatisierte Statistiken aktiviert',
            KPI_TIMEOUT: 'Zeitüberschreitung',
            KPI_TIMEOUT_SUB_LABEL: 'Anzahl der Tage, nachdem die Umfrage von einem Dienstanbieter eingereicht wurde und nach denen sie automatisch akzeptiert wird',
            APP_TIMEOUT: 'Zeitüberschreitung',
            PERFORMANCE_INCLUDE_PATROL_SCORE: 'Patrouillen-Ergebnisse einschließen',
            PERFORMANCE_INCLUDE_SURVEY_SCORE: 'Umfrageergebnisse einschließen',
            PERFORMANCE_PATROL_SCORE_WEIGHT: 'Gewichtung Patrouillen-Ergebnis',
            PERFORMANCE_QUOTE_SCORE_WEIGHT: 'Gewichtung Angebots-Ergebnis',
            PERFORMANCE_SURVEY_SCORE_WEIGHT: 'Gewichtung Umfrage-Ergebnis',
            PERFORMANCE_SYSTEM_SCORE_WEIGHT: 'Gewichtung System-Ergebnis',
            FILE_ALLOWED_TYPES: 'Dateitypen/Mime-Typen zulassen',
            FILE_DISALLOWED_TYPES: 'Dateitypen/Mime-Typen nicht zulassen',
            FILE_RESTRICT: 'Eingeschränkte Datei-Uploads',
            FILE_TYPE_DISALLOWED: 'Dieser Dateityp ist nicht zulässig',
            FILE_SETTINGS_ALLOW_SUB_LABEL: 'Wenn dieses Feld leer ist, werden alle außer Nicht Erlaubt zugelassen',
            FILE_SETTINGS_DISALLOW_SUB_LABEL: 'Wenn dieses Feld leer ist, werden alle außer Erlaubt nicht zugelassen',
            FILE_EXPIRY_NOTIFICATION_TIME: 'Anzahl der Tage vor Ablauf der Gültigkeitsdauer der Datei zur Erzeugung einer Benachrichtigung',
            FILE_EXPIRY_NOTIFICATION_TIME_SUB_LABEL: 'Minimum ist 1 Tag',
            TASK_FORCE_SERVICE_ROUTINE: 'Service-Routinen für Hard Tasks forcieren',
            TASK_SETTINGS_VIEW: 'Aufgabeneinstellungen',
            GENERAL_DEFAULT_LOCALE: 'Systemsprache',
            GENERAL_HELPDESK_NUMBER: 'Telefonnummer Helpdesk',
            GENERAL_HELPDESK_EMAIL: 'E-Mail-Adresse Helpdesk',
            GENERAL_TENANT_EMAILER_REPLY_EMAIL: 'Mieter-Emailer Antwort E-Mail-Adresse',
            GENERAL_GLOBAL_HELPDESK_NUMBER: 'Telefonnummer globales Helpdesk',
            GENERAL_TRAINING_LINK: 'Schulungs-Link',
            GENERAL_NON_PRIORITY_COLOUR: 'Prioritäre Farbe Maßnahme ‚Nach geplantem Datum‘',
            GENERAL_P2P_STARTED_AT_DATE: 'Globales P2P Gestartet am',
            DAYS_UNTIL_ALARM_ESCALATION: 'Tage bis Alarm/Eskalation',
            INCLUDE_WEEKEND_HOLIDAYS: 'Einschließlich Wochenenden/Feiertage',
            EXCLUDE_WEEKEND_HOLIDAYS: 'Ausschließlich Wochenenden/Feiertage',
            CHASE_ENTITY_TYPE_SETTINGS_PLANNED_EDIT: 'Geplanter Auftrag Verfolgungsverzögerungen bearbeiten',
            CHASE_ENTITY_TYPE_SETTINGS_PLANNED_STATUTORY_EDIT: 'Geplanter Auftrag (gesetzlich) Verfolgungsverzögerungen bearbeiten',
            CHASE_ENTITY_TYPE_SETTINGS_REACTIVE_EDIT: 'Reaktiver Auftrag Verfolgungsverzögerungen bearbeiten',
            CHASE_ENTITY_TYPE_SETTINGS_REACTIVE_STATUTORY_EDIT: 'Reaktiver Auftrag (gesetzlich) Verfolgungsverzögerungen bearbeiten',
            CHASE_ENTITY_TYPE_SETTINGS_QUOTE_EDIT: 'Angebot Verfolgungsverzögerungen bearbeiten',
            CHASE_ENTITY_SETTINGS_UPDATED: 'Einstellungen Verfolgungsverzögerung aktualisiert',
            AFTERDAYS: 'Anzahl der Tage bis zur Verspätung',
            INCLUDEWEEKENDSPUBLICHOLIDAYS: 'Wochenenden/Feiertage einbeziehen?',
            SUBMITTER: 'Einreicher',
            NO_PERMISSIONS_ADDED: 'Keine Berechtigungen hinzugefügt',
            WRONG_URL_FORMAT: 'Falsches URL-Format',
            SERVICE_TYPES: 'Diensttypen',
            SERVICE_TYPES_LIST: 'Diensttypen',
            SERVICE_TYPES_ADD: 'Diensttyp hinzufügen',
            SERVICE_TYPES_EDIT: 'Diensttyp bearbeiten',
            SERVICE_TYPES_UPDATED: 'Diensttypen aktualisiert',
            SERVICE_TYPE: 'Diensttyp',
            SERVICE_TYPE_LIST: 'Diensttypen',
            SERVICE_TYPE_ADD: 'Einen neuen Diensttypen hinzufügen',
            SERVICE_TYPE_EDIT: 'Diensttyp bearbeiten',
            SERVICE_TYPE_ADDED: 'Diensttyp hinzugefügt',
            SERVICE_TYPE_UPDATED: 'Diensttyp aktualisiert',
            SERVICE_TYPE_DELETED: 'Diensttyp gelöscht',
            SERVICE_ROUTINE: 'Service-Routine',
            SERVICE_ROUTINES: 'Service-Routinen',
            SERVICEROUTINES: 'Service-Routinen',
            ADD_NEW_SERVICE_ROUTINE: 'Eine neue Service-Routine hinzufügen',
            EDIT_SERVICE_ROUTINE: 'Service-Routine bearbeiten',
            SERVICE_ROUTINE_LINES: 'Service-Routine-Zeilen',
            ADD_SERVICE_ROUTINE_LINE: 'Service-Routine-Zeile hinzufügen',
            SERVICE_ROUTINE_ACTION: 'Maßnahme',
            SERVICE_ROUTINE_LIST: 'Service-Routinen',
            SERVICE_ROUTINE_VIEW: 'Info',
            SERVICE_ROUTINE_ADD: 'Eine neue Service-Routine hinzufügen',
            SERVICE_ROUTINE_EDIT: 'Service-Routine bearbeiten',
            SERVICE_ROUTINE_CREATED: 'Service-Routine erstellt',
            SERVICE_ROUTINE_UPDATED: 'Service-Routine aktualisiert',
            SERVICE_ROUTINE_DELETED: 'Service-Routine gelöscht',
            SERVICE_ROUTINE_LINE_ACTION: 'Maßnahme',
            SERVICE_ROUTINE_LINE_NOTES: 'Hinweise',
            SERVICE_ROUTINE_LINE_FREQUENCY: 'Frequenz',
            SERVICE_ROUTINE_LINE: 'Service-Routine-Zeile',
            SERVICE_ROUTINE_LINE_LIST: 'Service-Routine-Zeilen',
            SERVICE_ROUTINE_LINE_ADD: 'Eine neue Service-Routine-Zeile hinzufügen',
            SERVICE_ROUTINE_LINE_EDIT: 'Service-Routine-Zeile bearbeiten',
            SERVICE_ROUTINE_LINE_CREATED: 'Service-Routine-Zeile erstellt',
            SERVICE_ROUTINE_LINE_UPDATED: 'Service-Routine-Zeile aktualisiert',
            SERVICE_ROUTINE_LINES_UPDATED: 'Service-Routine-Zeilen aktualisiert',
            SERVICE_ROUTINE_LINE_DELETED: 'Service-Routine-Zeile gelöscht',
            REQUIRE_ASSET_WHEN_LOGGING_REACTIVE_JOB: 'Asset für reaktive Aufträge erforderlich',
            SERVICE_PROVIDER: 'Dienstleister',
            SERVICE_PROVIDERS: 'Dienstleister',
            ADD_NEW_SERVICE_PROVIDER: 'Einen neuen Dienstleister hinzufügen',
            SERVICE_PROVIDER_LIST: 'Dienstleister',
            SERVICE_PROVIDER_ADD: 'Einen neuen Dienstleister hinzufügen',
            SERVICE_PROVIDER_EDIT: 'Dienstleister bearbeiten',
            SERVICE_PROVIDER_CREATED: 'Dienstleister erstellt',
            SERVICE_PROVIDER_UPDATED: 'Dienstleister aktualisiert',
            SERVICE_PROVIDER_DELETED: 'Dienstleister gelöscht',
            SERVICE_PROVIDERS_SELECT: 'Dienstleister auswählen',
            SERVICE_PROVIDERS_ADD: 'Dienstleister hinzufügen',
            SERVICE_PROVIDERS_UPDATE: 'Dienstleister aktualisieren',
            SERVICE_PROVIDERS_UPDATED: 'Dienstleister aktualisiert',
            SERVICE_PROVIDER_PERMISSIONS_UPDATED: 'Dienstleister-Berechtigungen aktualisiert',
            SERVICE_PROVIDERS_IN_ESCALATION: 'Dienstleister in Eskalation',
            PRIMARY_OPERATIVES: 'Primäre Arbeiter',
            PRIMARY_OPERATIVE_TYPE: 'Typ primärer Arbeiter',
            PRIMARY_OPERATIVE_TYPES_EDIT: 'Primäre Arbeiter bearbeiten',
            PRIMARY_OPERATIVES_UPDATED: 'Primäre Arbeiter aktualisiert',
            PRIMARY_JOB_OPERATIVE: 'Auftrag',
            PRIMARY_ESCALATION_OPERATIVE: 'Eskalation',
            PRIMARY_QUOTE_OPERATIVE: 'Angebot',
            PRIMARY_PATROL_OPERATIVE: 'Patrouille',
            PRIMARY_SURVEY_OPERATIVE: 'Umfrage',
            PRIMARY_AUDIT_OPERATIVE: 'Audit',
            PRIMARY_HEALTH_SAFETY_OPERATIVE: 'Gesundheit und Sicherheit',
            ACTIVE_AT: 'Aktiv seit',
            INACTIVE_AT: 'Inaktiv seit',
            TRAINED_AT: 'Geschult am',
            TRAINED_AT_START: 'Geschult am Start',
            TRAINED_AT_END: 'Geschult am Ende',
            ACTIVE_AT_START: 'Aktiv am Start',
            ACTIVE_AT_END: 'Aktiv am Ende',
            OPERATIVE_CREATED: 'Arbeiter erstellt',
            PERMISSIONS_UPDATED: 'Berechtigungen aktualisiert',
            OPERATIVE_REMOVED: 'Arbeiter entfernt',
            SERVICE_PROVIDER_PARTNERSHIP_ADDED: 'Partnerschaft hinzugefügt',
            SERVICE_PROVIDER_STATUS: 'Status Dienstleister',
            CONTRACTOR_APPROVED: 'Auftragnehmer genehmigt',
            CONTRACTORAPPROVED: 'Auftragnehmer genehmigt',
            NOT_APPROVED: 'Nicht genehmigt',
            SHARED: 'Geteilt',
            REQUIRELABOURLINEONJOBCOMPLETE: 'Arbeitszeile bei Abschluss des Auftrags erforderlich',
            REQUIRESIGNATURE: 'Unterschrift bei Abschluss des Auftrags erforderlich',
            MARKUPQUOTES: 'Aufschlag Angebote',
            MARKUPLABOUR: 'Aufschlag Arbeit',
            MARKUPMATERIALS: 'Aufschlag Materialien',
            SERVICE_PROVIDER_GROUP: 'Dienstleistergruppe',
            SERVICE_PROVIDER_GROUPS: 'Dienstleistergruppen',
            ADD_NEW_SERVICE_PROVIDER_GROUP: 'Eine neue Dienstleistergruppe hinzufügen',
            SERVICE_PROVIDER_GROUP_LIST: 'Dienstleistergruppen',
            SERVICE_PROVIDER_GROUP_ADD: 'Eine neue Dienstleistergruppe hinzufügen',
            SERVICE_PROVIDER_GROUP_EDIT: 'Dienstleistergruppe bearbeiten',
            SERVICE_PROVIDER_GROUP_CREATED: 'Dienstleistergruppe erstellt',
            SERVICE_PROVIDER_GROUP_UPDATED: 'Dienstleistergruppe aktualisiert',
            SERVICE_PROVIDER_GROUP_DELETED: 'Dienstleistergruppe gelöscht',
            REQUIRESASSETSWHENLOGGINGREACTIVEJOB: 'Reaktive Aufträge erfordern Assets',
            JOBVALUESAUTOAPPROVEDDAYS: 'Anzahl der Tage, bevor Auftragswerte automatisch genehmigt werden',
            JOB_VALUES_AUTO_APPROVED_DAYS_WARNING: 'Bitte beachten: Eine Änderung des Wertes kann automatisch einen Rückstand von Artikeln genehmigen',
            REQUIRES_ASSETS_WHEN_LOGGING_REACTIVE_JOB: 'Erfordert Asset',
            REQUIRES_ASSET: 'Asset erforderlich',
            NOT_REQUIRES_ASSET: 'Kein Asset erforderlich',
            TRAINEDAT: 'Geschult in',
            ACTIVEAT: 'Aktiv am',
            INACTIVEAT: 'Inaktiv am',
            SECTOR: 'Sektor',
            SECTORS: 'Sektoren',
            SECTOR_INFORMATION: 'Sektor-Information',
            MANAGE_SECTOR_SITES: 'Gebäude in diesem Sektor',
            ADD_NEW_SECTOR: 'Einen neuen Sektor hinzufügen',
            EDIT_SECTOR: 'Sektor bearbeiten',
            SECTOR_LIST: 'Sektoren',
            SECTOR_ADD: 'Einen neuen Sektor hinzufügen',
            SECTOR_EDIT: 'Sektor bearbeiten',
            SECTOR_CREATED: 'Sektor erstellt',
            SECTOR_UPDATED: 'Sektor aktualisiert',
            SECTOR_DELETED: 'Sektor gelöscht',
            SECTOR_SITE_LIST: 'Gebäude',
            SECTOR_SITE_ADDED: 'Sektorgebäude aktualisiert',
            SITES_DELETED_FROM_SECTOR: 'Sektorgebäude gelöscht',
            CLEAR_ALL: 'Filter entfernen',
            CLEAR: 'Entfernen',
            ADVANCED_FILTERS: 'Erweiterte Filter',
            VIEW_COLUMNS: 'Spalten anzeigen',
            SATISFACTIONSURVEYS: 'Zufriedenheitsumfragen',
            SATISFACTIONSURVEY: 'Zufriedenheitsumfrage',
            SATISFACTIONSURVEY_JOBID: 'Auftrags-ID',
            SATISFACTIONSURVEY_ISCOMPLETE: 'Abgeschlossen?',
            SATISFACTIONSURVEY_REFUSED: 'Umfrage abgelehnt?',
            SATISFACTIONSURVEY_EDIT: 'Zufriedenheitsumfrage bearbeiten',
            SATISFACTIONSURVEY_CONTACT_NAME: 'Name der Kontaktperson',
            SATISFACTIONSURVEY_CONTACT_PHONE: 'Telefonnummer der Kontaktperson',
            SATISFACTIONSURVEY_CONTACT_EMAIL: 'E-Mail der Kontaktperson',
            SATISFACTIONSURVEY_COMMENTS: 'Zusätzliche Kommentare / Vorschläge',
            SATISFACTIONSURVEY_QUESTION: 'Frage',
            SATISFACTIONSURVEY_RESPONSE: 'Antwort',
            SATISFACTIONSURVEY_QUESTIONS: 'Fragen Zufriedenheitsumfrage',
            SATISFACTIONSURVEY_BUTTON_ADD_QUESTION: 'Eine Frage hinzufügen',
            SATISFACTIONSURVEY_ADD_QUESTION: 'Zufriedenheitsumfrage - Eine Frage hinzufügen',
            SATISFACTIONSURVEY_EDIT_QUESTION: 'Zufriedenheitsumfrage - Eine Frage bearbeiten',
            SATISFACTIONSURVEY_FILTER_INCOMPLETEREQUIRESATTENTION: 'Unvollständig - Erfordert Eingriff',
            SATISFACTIONSURVEY_FILTER_INCOMPLETENOATTENTION: 'Unvollständig - Warten auf Beendigung des Auftrags',
            SATISFACTIONSURVEY_FILTER_COMPLETE: 'Abschließen',
            ROLE: 'Rolle',
            ROLES: 'Rollen',
            ROLE_EDIT: 'Rolle bearbeiten',
            ROLE_LIST: 'Rollen',
            ROLE_ADD: 'Eine neue Rolle hinzufügen',
            USER_ROLE: 'Benutzerrolle',
            SITE_MEMBERSHIP_ROLE: 'Rolle Gebäude-Mitgliedschaft',
            OPERATIVE_ROLE: 'Arbeiterrolle',
            ROLE_CREATED: 'Rolle erstellt',
            ROLE_UPDATED: 'Rolle aktualisiert',
            ROLE_DELETED: 'Rolle gelöscht',
            ROLE_PERMISSIONS: 'Rollenberechtigungen',
            ROLE_PERMISSIONS_UPDATED: 'Rollenberechtigungen aktualisiert',
            MANAGE_USERS: 'Benutzer verwalten',
            REQUEST_ERROR: 'Es gab einen Fehler bei der Anforderung. Bitte versuchen Sie es später noch einmal.',
            THIS_ENTITY_HAS_DEPENDENCIES: 'Eintrag ist in Gebrauch. Kann nicht gelöscht werden.',
            REPORTS: 'Berichte',
            REPORT_LIST: 'Berichte',
            REPORT: 'Bericht',
            BETWEEN_DATES: 'Zwischen Daten',
            SHOW_PERCENTAGES: 'Prozentsätze anzeigen',
            SP_SUMMARY_REPORT: 'Zusammenfassungsbericht Dienstleister',
            SP_NAME: 'SP Name',
            REACTIVE_ATTENDANCE: 'Teilnahme reaktivieren',
            REACTIVE_MISSED_ATTENDANCE: 'Versäumte Teilnahme reaktivieren',
            REACTIVE_COMPLETION: 'Beendigung reaktivieren',
            REACTIVE_MISSED_COMPLETION: 'Versäumte Beendigung reaktivieren',
            PPM_ATTENDANCE: 'PPM-Teilnahme',
            PPM_MISSED_ATTENDANCE: 'PPM-Versäumte Teilnahme',
            PPM_COMPLETION: 'PPM-Beendigung',
            PPM_MISSED_COMPLETION: 'PPM-Versäumte Beendigung',
            QUOTES: 'Angebote',
            EXTENSION_REQUEST: 'Erweiterungsantrag',
            REOPENED_JOBS: 'Erneut geöffnete Aufträge',
            OVERDUE_PLANNED_ATTENDANCE: 'Geplante Teilnahme überfällig',
            OVERDUE_PLANNED_COMPLETION: 'Geplante Beendigung überfällig',
            OVERDUE_PLANNED: 'Geplant überfällig',
            COMPLETED_DUE: 'Abgeschlossen fällig',
            ATTEND_DUE: 'Betreuen fällig',
            COMPLETED: 'Abgeschlossen',
            ATTENDED: 'Betreut',
            EXTENSION_REQUESTS: 'Erweiterungsanträge',
            JOB_ESCALATIONS: 'Auftragseskalationen',
            QUOTE_ESCALATIONS: 'Angebotseskalationen',
            EXTENSION_FROM: 'Erweiterungen von',
            EXTENSION_TO: 'Erweiterungen bis',
            EXTENSION_STATUS: 'Erweiterungsstatus',
            QUOTES_COMPLETED: 'Angebote abgeschlossen',
            QUOTES_MISSED: 'Versäumte Angebote',
            LAST_REOPENED_AT: 'Zuletzt erneut geöffnet am',
            SERVICE_PROVIDER_SUMMARY: 'Zusammenfassung Dienstleister',
            FM_NAME: 'FM Name',
            APPROVER_NAME: 'Name Genehmigender',
            PLANNED_ATTENDANCE: 'Geplante Teilnahme',
            PLANNED_COMPLETION: 'Geplante Beendigung',
            PLANNED_WORK: 'Geplante Arbeit',
            REACTIVE_WORK: 'Arbeit reaktivieren',
            KPI_SURVEYS: 'KPI-Umfragen',
            KPI_GROUPS: 'KPI - Gruppen',
            LAST_LOGGED_IN: 'Zuletzt angemeldet',
            SITES_WITH_PPM_REQUIRING_APPROVAL_CONCISE: 'Gebäude - PPM Genehmigungen',
            JOBS_APPROVED: 'Aufträge genehmigt',
            AVERAGE_TIME_TAKEN: 'Durchschnittlich benötigte Zeit',
            AVERAGE_TIME_ACROSS_ENTIRE_SYSTEM: 'Durchschnittliche Zeit über das gesamte System',
            QUOTES_APPROVED: 'Genehmigte Aufträge',
            SITES_WITH_QUOTES_REQUIRING_APPROVAL: 'Gebäude - Genehmigungen Angebote',
            LIVE_STAT: 'Dies ist eine Live-Statistik. Der Filter Datumsbereich gilt nicht für die folgenden Abbildungen.',
            KPI_SURVEYS_OUTSTANDING: 'KPI-Umfragen ausstehend',
            CUSTOMER_GROUP: 'Kundengruppe',
            QUOTES_REQUIRING_APPROVAL: 'Genehmigung erfordernde Angebote',
            TASKS_REQUIRING_APPROVAL: 'Genehmigung erfordernde Aufgaben',
            SUBMITTED_DATE: 'Eingereichtes Datum',
            PPM_ON_TRACK: 'PPM wird verfolgt',
            PPM_REQUIRING_ATTENTION: 'PPM erfordert Aufmerksamkeit',
            REACTIVE_ON_TRACK: 'Verfolgung reaktivieren',
            REACTIVE_REQUIRING_ATTENTION: 'Erfordert Aufmerksamkeit reaktivieren',
            ACTIVITY_REPORT: 'Aktivitätsbericht',
            REACTIVE_PAPERWORK_FOR_REVIEW: 'Unterlagen zur Überprüfung reaktivieren',
            PLANNED_PAPERWORK_FOR_REVIEW: 'Geplante Unterlagen zur Überprüfung',
            PAPERWORK_FOR_REVIEW: 'Unterlagen zur Überprüfung',
            REACTIVE_ESCALATED_TO_CLIENT: 'An den Kunden eskaliert reaktivieren',
            PLANNED_ESCALATED_TO_CLIENT: 'An den Kunden eskaliert geplant',
            PPM_REQUIRING_APPROVAL: 'PPM erfordert Genehmigung',
            OUTSTANDING_KPI_SURVEYS: 'Herausragende KPI-Umfragen',
            RAISED_DATE: 'Datum der Erhebung',
            INVALID_FM: 'FM ungültig',
            UNKNOWN: 'Unbekannt',
            REPORT_QUEUED: 'Bericht in Warteschlange',
            AVERAGE_TIME_ACROSS_SYSTEM: 'Durchschnittliche Zeit über das gesamte System',
            SITE_QUOTES_REQUIRING_APPROVAL: 'Gebäude mit Genehmigung erfordernden Angeboten',
            AGE_OF_APPROVALS: 'Alter der Genehmigungen',
            AVERAGE: 'Durchschnitt',
            SUMMARY_REPORT: 'Zusammenfassung',
            JOBS_BY_REGION: 'Aufträge nach Region',
            JOBS_BY_PRIORITY: 'Aufträge nach Priorität',
            JOBS_BY_JOB_GROUP: 'Aufträge nach Auftragsgruppe',
            JOBS_BY_SERVICEPROVIDER: 'Aufträge nach Dienstleister',
            JOBS_BY_SITE_GROUP: 'Aufträge nach Gebäudegruppe',
            JOBS_BY_SITE: 'Aufträge nach Gebäude',
            QUOTES_BY_SITE: 'Angebote nach Gebäude',
            QUOTES_BY_SP: 'Angebote nach SP',
            QUOTES_BY_SERVICEPROVIDER: 'Angebote nach Dienstleister',
            JOB_GROUP: 'Auftragsgruppe',
            JOBGROUP: 'Auftragsgruppe',
            TOTAL_JOBS: 'Aufträge gesamt',
            OPEN_JOBS: 'Aufträge öffnen',
            COMPLETED_JOBS: 'Abgeschlossene Aufträge',
            MISSED_JOBS: 'Versäumte Aufträge',
            ATTENDED_ON_TIME: 'Fristgerecht betreut',
            COMPLETED_ON_TIME: 'Fristgerecht abgeschlossen',
            TOTAL_QUOTES: 'Angebote gesamt',
            ACCEPTED_QUOTES: 'Angenommene Angebote',
            AVERAGE_TIME_TO_QUOTE: 'Durchschnittliche Zeit bis zum Angebot',
            DECLINED_QUOTES: 'Abgelehnte Angebote',
            EXPIRED_QUOTES: 'Abgelaufene Angebote',
            REJECTED_QUOTES: 'Abgelehnte Angebote',
            TOTAL_KPI: 'KPI-Umfragen Gesamt',
            AVERAGE_SCORE: 'Durchschnittliches Ergebnis',
            ATTENDANCE_DUE_DATE: 'Fälligkeitsdatum Teilnahme',
            COMPLETION_DUE_DATE: 'Fälligkeitsdatum Beendigung',
            DATE_ATTENDED: 'Betreut am',
            DATE_COMPLETED: 'Datum des Abschlusses',
            REPORTING_PERIOD: 'Berichtszeitraum (M/J)',
            SERVICEPROVIDER_SCORE: 'SP-Ergebnis',
            CLIENT_SCORE: 'Kundenergebnis',
            TARGET_SCORE: 'Zielergebnis',
            PPM_GAP_ANALYSIS: 'PPM GAP-Analyse',
            PRIMARY_PPM_APPROVER: 'Primärer PPM-Genehmigender',
            PRIMARY_FM: 'Primärer FM',
            OPENCLOSE_OPEN: 'Spätes Öffnen',
            OPENCLOSE_CLOSE: 'Frühes Schließen',
            OPEN_CLOSE: 'Spätes Öffnen / Frühes Schließen',
            EARLY_OPEN: 'Spätes Öffnen',
            LATE_CLOSE: 'Frühes Schließen',
            BOTH: 'Beide',
            ENGINEER_RISK_REPORT: 'Zusammenfassung technischer Risiken',
            ENGINEERING_RISK_REPORT_TOTALS: 'Gesamtbeträge',
            COMPLIANT: 'Konform',
            UPCOMING: 'Anstehend',
            MONTHLY_REPORT: 'Monatlich',
            KPI_MONTHLY_REPORT: 'KPI monatlich',
            COMPLIANCE_DUPLICATE: 'Eintrag existiert für ausgewählte Gruppe, Monat und Jahr.',
            NO_ACTIVE_SITES: 'Für die ausgewählte Gruppe, den Monat und das Jahr gibt es keine aktiven Gebäude.',
            PORTFOLIO_COMPLIANCE_REPORT: 'Portfolio-Konformität',
            PORTFOLIO_COMPLIANCE_REPORT_ADD_LINE: 'Portfolio-Konformität Zeile hinzufügen',
            NUMBER_OF_ERRORS: 'Anz. Fehler',
            SP_GROUP: 'SP Gruppe',
            ACTIVE_SITES: 'Aktive Orte',
            ERRORS: 'Fehler',
            DAYS_LATE: 'Tage Verspätung',
            QUOTES_COMPLETION: 'Angebot abgeschlossen',
            MONTHLY_REPORT_DATA: 'Tabelle',
            MONTHLY_REPORT_GRAPH: 'Grafik',
            ENGINEERING_RISK_REPORT_MONTHS_LIMIT_WARNING: 'Der ausgewählte Datumsbereich erstreckt sich über 24 Monate. Die Datentabelle und der Export sind auf 24 Monate begrenzt.',
            MONTHLY_REPORT_MONTHS_LIMIT_WARNING: 'Der ausgewählte Datumsbereich erstreckt sich über 12 Monate. Die Datentabelle und die Grafik werden auf 12 Monate beschränkt sein, aber der Export wird bis zu 36 Monate von Daten umfassen.',
            KPI_MONTHLY_REPORT_MONTHS_LIMIT_WARNING: 'Der ausgewählte Datumsbereich erstreckt sich über 12 Monate. Die Datentabelle wird auf 12 Monate beschränkt sein, aber der Export wird bis zu 36 Monate von Daten umfassen.',
            SELECT_SURVEY: 'Umfrage auswählen',
            AUDIT_TREND_REPORT: 'Audit-Trendbericht',
            QUESTION_SUMMARY: 'Fragen-Zusammenfassung',
            ANSWER_LIST: 'Antwortliste',
            AUDIT_ID: 'Audit-ID',
            COMMENT_SUPPLIED: 'Kommentar geliefert',
            DATE_ANSWERED: 'Antwortdatum',
            AUDIT_REFERENCE: 'Audit-Referenz',
            AVE: 'Ave',
            SP_USAGE: 'SP Gebrauch',
            SP_USAGE_REPORT: 'SP Gebrauchsbericht',
            SHOW_RESULTS: 'Ergebnisse anzeigen',
            ONLINE: 'Online',
            MISSED_REACTIVE_SLA: 'SLA reaktivieren versäumt',
            MISSED_PPMS: 'Versäumte PPMs',
            LATE_QUOTES: 'Späte Angebote',
            ESCALATED_JOBS: 'Eskalierte Aufträge',
            SP_OPERATIVE: 'SP Arbeiter',
            DATE_SUBMITTED: 'Eingereichtes Datum',
            DATE_FIRST_ESCALATED: 'Datum der ersten Eskalation',
            SUBMISSION_DUE: 'Abgabe fällig',
            REPORT_TYPE: 'Berichtsart',
            RESIDENTIAL_LOCATION: 'Wohnstandort',
            RESIDENTIAL_LOCATION_DETAILS: 'Wohnstandort',
            OPERATIVE_PERFORMANCE: 'Arbeiterleistung',
            OPERATIVE_PERFORMANCE_REPORT: 'Leistungsbericht des Arbeiters',
            OPERATIVE_PERFORMANCE_SP: 'Einen Dienstleister auswählen',
            REPORT_PERMISSIONS: 'Berechtigungen berichten',
            REPORT_PERMISSIONS_UPDATED: 'Berechtigungen berichten aktualisiert',
            USER_PERMISSION_REPORT_SP_SUMMARY: 'Zusammenfassung Dienstleister',
            USER_PERMISSION_REPORT_SP_SUMMARY_DETAIL: 'Die Befähigung, auf den Zusammenfassungsbericht des Dienstanbieters zuzugreifen',
            USER_PERMISSION_REPORT_SITE_ASSOCIATE_ACTIVITY: 'Aktivität Gebäudepartner',
            USER_PERMISSION_REPORT_SITE_ASSOCIATE_ACTIVITY_DETAIL: 'Die Befähigung, auf den Aktivitätsbericht der Gebäudepartner zuzugreifen',
            USER_PERMISSION_REPORT_APPROVAL_AGE: 'Alter der Genehmigungen',
            USER_PERMISSION_REPORT_APPROVAL_AGE_DETAIL: 'Die Befähigung, auf den Bericht über das Alter der Genehmigung zuzugreifen',
            USER_PERMISSION_REPORT_SUMMARY: 'Zusammenfassung',
            USER_PERMISSION_REPORT_SUMMARY_DETAIL: 'Die Befähigung, auf den Zusammenfassungsbericht zuzugreifen',
            USER_PERMISSION_REPORT_PPM_GAP_ANALYSIS: 'PPM GAP-Analyse',
            USER_PERMISSION_REPORT_PPM_GAP_ANALYSIS_DETAIL: 'Die Befähigung, auf den PPM GAP-Analysebericht zuzugreifen',
            USER_PERMISSION_REPORT_OPEN_CLOSE: 'Bericht Spätes Öffnen / Frühes Schließen',
            USER_PERMISSION_REPORT_OPEN_CLOSE_DETAIL: 'Die Befähigung, auf den Bericht Spätes Öffnen / Frühes Schließen zuzugreifen',
            USER_PERMISSION_REPORT_MONTHLY: 'Monatlich',
            USER_PERMISSION_REPORT_MONTHLY_DETAIL: 'Die Befähigung, auf den Monatsbericht zuzugreifen',
            USER_PERMISSION_REPORT_KPI_MONTHLY: 'KPI monatlich',
            USER_PERMISSION_REPORT_KPI_MONTHLY_DETAIL: 'Die Befähigung, auf den KPI-Monatsbericht zuzugreifen',
            USER_PERMISSION_REPORT_ENGINEERING_RISK: 'Zusammenfassung technischer Risiken',
            USER_PERMISSION_REPORT_ENGINEERING_RISK_DETAIL: 'Die Befähigung, auf den Zusammenfassungsbericht zu technischen Risiken zuzugreifen',
            USER_PERMISSION_REPORT_PORTFOLIO_COMPLIANCE: 'Bericht Portfolio-Konformität',
            USER_PERMISSION_REPORT_PORTFOLIO_COMPLIANCE_DETAIL: 'Die Befähigung, auf den Bericht Portfolio-Konformität zuzugreifen',
            USER_PERMISSION_REPORT_AUDIT_TREND: 'Audit-Trend',
            USER_PERMISSION_REPORT_AUDIT_TREND_DETAIL: 'Die Befähigung, auf den Audit-Trendbericht zuzugreifen',
            USER_PERMISSION_REPORT_SP_USAGE: 'SP Gebrauchsbericht',
            USER_PERMISSION_REPORT_SP_USAGE_DETAIL: 'Die Befähigung, auf den SP Gebrauchsbericht zuzugreifen',
            USER_PERMISSION_REPORT_RESIDENTIAL_LOCATION: 'Wohnstandortbericht',
            USER_PERMISSION_REPORT_RESIDENTIAL_LOCATION_DETAIL: 'Die Befähigung, auf den Wohnstandortbericht zuzugreifen',
            USER_PERMISSION_REPORT_OPERATIVE_PERFORMANCE: 'Leistungsbericht des Arbeiters',
            USER_PERMISSION_REPORT_OPERATIVE_PERFORMANCE_DETAIL: 'Die Befähigung, auf den Leistungsbericht des Arbeiters zuzugreifen',
            LEGEND_JOB_REACTIVE_OVERDUE_STATUTORY_ATTENDANCE: 'Gesetzlich überfällig',
            LEGEND_JOB_REACTIVE_OVERDUE_NON_STATUTORY_ATTENDANCE: 'Gesetzlich nicht überfällig',
            LEGEND_JOB_REACTIVE_NOT_OVERDUE_ATTENDANCE: 'Offen',
            LEGEND_JOB_REACTIVE_OVERDUE_STATUTORY_COMPLETION: 'Gesetzlich überfällig',
            LEGEND_JOB_REACTIVE_OVERDUE_NON_STATUTORY_COMPLETION: 'Gesetzlich nicht überfällig',
            LEGEND_JOB_REACTIVE_NOT_OVERDUE_COMPLETION: 'Offen',
            LEGEND_JOB_PLANNED_OVERDUE_STATUTORY_COMPLETION: 'Gesetzlich überfällig',
            LEGEND_JOB_PLANNED_OVERDUE_NON_STATUTORY_COMPLETION: 'Gesetzlich nicht überfällig',
            LEGEND_JOB_PLANNED_OVERDUE_COMPLETION: 'Überfällig',
            LEGEND_JOB_PLANNED_NOT_OVERDUE_COMPLETION: 'Offen',
            LEGEND_ACTION_OPEN: 'Offen',
            LEGEND_ACTION_COMPLETED: 'Abgeschlossen',
            LEGEND_AUDIT_CREATED: 'Offen',
            LEGEND_AUDIT_STARTED: 'In Bearbeitung',
            LEGEND_AUDIT_COMPLETED: 'Abgeschlossen',
            REGION: 'Region',
            REGIONS: 'Regionen',
            REGION_INFORMATION: 'Regionsinformationen',
            MANAGE_REGION_SITES: 'Gebäude in dieser Region',
            ADD_NEW_REGION: 'Eine neue Region hinzufügen',
            EDIT_REGION: 'Region bearbeiten',
            REGION_LIST: 'Regionen',
            REGION_ADD: 'Eine neue Region hinzufügen',
            REGION_EDIT: 'Region bearbeiten',
            REGION_CREATED: 'Region erstellt',
            REGION_UPDATED: 'Region aktualisiert',
            REGION_DELETED: 'Region gelöscht',
            REGION_SITE_ADDED: 'Gebäude der Region aktualisiert',
            REGION_SITE_LIST: 'Gebäude',
            QUOTE: 'Angebot',
            QUOTE_WITH_ID: 'Angebot {{ id }}',
            QUOTE_LIST: 'Angebote',
            QUOTE_VIEW: 'Angebot',
            QUOTE_SUMMARY: 'Zusammenfassung Angebot',
            QUOTE_DESCRIPTION: 'Beschreibung Angebot',
            QUOTE_PRIORITY: 'Priorität Angebot',
            QUOTE_ADD: 'Ein neues Angebot hinzufügen',
            QUOTE_CREATED: 'Angebot erstellt',
            QUOTE_UPDATED: 'Angebot aktualisiert',
            QUOTE_DELETED: 'Angebot gelöscht',
            QUOTE_ID: 'Angebot {{ id }}',
            QUOTE_DETAILS: 'Angebotsdetails',
            QUOTE_STATUS: 'Status',
            QUOTE_CONTACTS: 'Angebotskontakte',
            QUOTE_AUDIT_TRAIL_SENT: 'Auditprotokoll gesendet',
            QUOTE_CONTACT: 'Angebotskontakt',
            QUOTE_CONTACT_LIST: 'Angebotskontakte',
            QUOTE_CONTACT_ADD: 'Hinzufügen',
            QUOTE_CONTACT_CREATED: 'Angebotskontakt erstellt',
            QUOTE_CONTACT_UPDATED: 'Angebotskontakt aktualisiert',
            QUOTE_CONTACT_DELETED: 'Angebotskontakt gelöscht',
            QUOTE_WORKFLOW_ABSTAIN: 'Angebot ablehnen',
            QUOTE_WORKFLOW_ACCEPT: 'Akzeptieren',
            QUOTE_WORKFLOW_CANCEL: 'Abbrechen',
            QUOTE_WORKFLOW_HOLD: 'Halten',
            QUOTE_WORKFLOW_REJECT: 'Ablehnen',
            QUOTE_WORKFLOW_REQUEST_MORE_INFORMATION: 'Weitere Informationen anfordern',
            QUOTE_WORKFLOW_SUBMIT: 'Einreichen',
            QUOTE_WORKFLOW_REOPEN: 'Erneut eröffnen',
            QUOTE_WORKFLOW_ESCALATE: 'Eskalieren',
            QUOTE_WORKFLOW_ABSTAIN_SUBMIT: 'Ablehnen',
            QUOTE_WORKFLOW_ACCEPT_SUBMIT: 'Angebot akzeptieren',
            QUOTE_WORKFLOW_CANCEL_SUBMIT: 'Angebot abbrechen',
            QUOTE_WORKFLOW_HOLD_SUBMIT: 'Halten',
            QUOTE_WORKFLOW_REJECT_SUBMIT: 'Angebot ablehnen',
            QUOTE_WORKFLOW_REQUEST_MORE_INFORMATION_SUBMIT: 'Weitere Informationen anfordern',
            QUOTE_WORKFLOW_SUBMIT_SUBMIT: 'Angebot einreichen',
            QUOTE_WORKFLOW_REOPEN_SUBMIT: 'Erneut eröffnen',
            QUOTE_WORKFLOW_ESCALATE_SUBMIT: 'Eskalieren',
            QUOTE_WORKFLOW_ESCALATE_APPROVAL_SUBMIT: 'Eskalieren',
            QUOTE_WORKFLOW_SUBMIT_SUCCESS: 'Angebot eingereicht',
            QUOTE_WORKFLOW_ESCALATE_SUCCESS: 'Angebot eskaliert',
            QUOTE_WORKFLOW_ALARM: 'Alarm bearbeiten',
            QUOTE_WORKFLOW_ALARM_SUCCESS: 'Alarm aktualisiert',
            QUOTE_WORKFLOW_ALARM_SUBMIT: 'Aktualisieren',
            QUOTE_WORKFLOW_ALARM_SETNEW: 'Alarmeinstellung',
            QUOTE_WORKFLOW_ALARM_SETNEW_SUCCESS: 'Alarm eingestellt',
            QUOTE_WORKFLOW_ALARM_SETNEW_SUBMIT: 'Einstellen',
            QUOTE_WORKFLOW_POSTPONE_REMINDER_AT: 'Maßnahme aufschieben',
            QUOTE_WORKFLOW_POSTPONE_REMINDER_AT_SUBMIT: 'Aufschieben',
            QUOTE_WORKFLOW_POSTPONE_REMINDER_AT_SUCCESS: 'Angebot aufgeschoben',
            QUOTE_WORKFLOW_REJECT_EXTENSION: 'Erweiterung ablehnen',
            QUOTE_WORKFLOW_REJECT_EXTENSION_SUBMIT: 'Ablehnen',
            QUOTE_WORKFLOW_ACCEPT_EXTENSION: 'Erweiterung akzeptieren',
            QUOTE_WORKFLOW_ACCEPT_EXTENSION_SUBMIT: 'Akzeptieren',
            QUOTE_WORKFLOW_CANCEL_EXTENSION: 'Erweiterung abbrechen',
            QUOTE_WORKFLOW_CANCEL_EXTENSION_SUBMIT: 'Erweiterung abbrechen',
            QUOTE_WORKFLOW_REQUEST_EXTENSION: 'Erweiterung anfordern',
            QUOTE_WORKFLOW_REQUEST_EXTENSION_SUBMIT: 'Einreichen',
            QUOTE_WORKFLOW_ACCEPT_EXTENSION_SUCCESS: 'Angebotserweiterung angenommen',
            QUOTE_WORKFLOW_REJECT_EXTENSION_SUCCESS: 'Angebotserweiterung abgelehnt',
            QUOTE_WORKFLOW_REQUEST_EXTENSION_SUCCESS: 'Angebotserweiterung angefordert',
            QUOTE_WORKFLOW_CANCEL_EXTENSION_SUCCESS: 'Angebotserweiterung abgebrochen',
            REQUESTED_EXTENSION: 'Angeforderte Erweiterung',
            QUOTE_WORKFLOW_ESCALATE_APPROVAL_SUCCESS: 'Angebotsgenehmigung eskaliert',
            QUOTE_WORKFLOW_REQUEST_MORE_INFORMATION_SUCCESS: 'Weitere Informationen zum Erfolg anfordern',
            NEW_COMPLETION_DATE_MUST_BE_AFTER_TODAY: 'Neues Abschlussdatum muss nach heute liegen',
            NEW_COMPLETION_DATE_MUST_BE_AFTER_CURRENT_COMPLETION_DATE: 'Neues Abschlussdatum muss nach dem aktuellen Abschlussdatum liegen',
            QUOTE_APPROVER_NOT_SET: 'Kein Angebotsgenehmigender festgelegt, das Angebot kann nicht eingereicht werden',
            QUOTE_STATUS_CREATED: 'Erstellt',
            QUOTE_STATUS_REQUESTED: 'Angefordert',
            QUOTE_STATUS_SUBMITTED: 'Ausstehend zur Genehmigung',
            QUOTE_STATUS_ACCEPTED: 'Akzeptiert',
            QUOTE_STATUS_REJECTED: 'Abgelehnt',
            QUOTE_STATUS_CANCELLED: 'Abgebrochen',
            QUOTE_STATUS_EXPIRED: 'Abgelaufen',
            QUOTE_STATUS_ABSTAINED: 'Abgelehnt, ein Angebot zu machen',
            QUOTE_STATUS_PENDING: 'Im Haltestatus',
            QUOTE_STATUS_INFORMATION_REQUESTED: 'Mehr Informationen angefordert',
            QUOTE_STATUS_EXTENSION_REQUESTED: 'Erweiterung angefordert',
            QUOTE_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat das Angebot erstellt',
            QUOTE_SUBMITTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat das Angebot eingereicht.',
            QUOTE_MORE_INFORMATION_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat mehr Informationen angefordert.',
            QUOTE_ABSTAINED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat abgelehnt, ein Angebot zu machen.',
            QUOTE_REOPENED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat das Angebot erneut geöffnet',
            QUOTE_PUT_ON_HOLD_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> das Angebot in den Haltestatus setzen.',
            QUOTE_APPROVAL_RESENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat eine Genehmigungsanfrage erneut gesendet an {{ ::event._links["approver"].title }}.',
            QUOTE_APPROVAL_ESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat die Angebotsgenehmigung eskaliert.',
            QUOTE_POSTPONE_REMINDER_AT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat die Maßnahme erforderlich bis geändert in {{ ::event.reminderAt | date:\'short\' }}.',
            QUOTE_APPROVAL_RESENT: 'Genehmigungsanforderung für Angebot erneut gesendet.',
            QUOTE_WORKFLOW_RESEND_APPROVAL: 'Genehmigungsanfrage erneut senden',
            QUOTE_ESCALATION_ADDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat das Angebot eskaliert.',
            QUOTE_ESCALATION_ADDED_SYSTEM_ACTION: 'Das System hat das Angebot automatisch eskaliert.',
            QUOTE_ESCALATION_REMOVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat das Angebot aus der Eskalation entfernt.',
            QUOTE_ESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat das Angebot eskaliert.',
            QUOTE_ESCALATED_SYSTEM_ACTION: 'Das System hat das Angebot automatisch eskaliert.',
            QUOTE_DEESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat das Angebot aus der Eskalation entfernt.',
            QUOTE_DEESCALATED_SYSTEM_ACTION: 'Das System hat das Angebot automatisch aus der Eskalation entfernt.',
            QUOTE_ESCALATION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat die Eskalation abgebrochen.',
            QUOTE_WORKFLOW_ESCALATE_APPROVAL: 'Genehmigung eskalieren',
            QUOTE_CHASED_ACTION: 'Eine Verfolgungsbenachrichtigung #{{ ::event.chaseSequence + 1 }} wurde gesendet an <strong>{{ ::event._links["user"].title }}</strong>.',
            QUOTE_ALARM_UPDATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat den Alarm aktualisiert.',
            QUOTE_MESSAGE_ACTION: '<strong>{{ ::message._links["sender"].title }}</strong> hat ein Update zur Verfügung gestellt.',
            QUOTE_EXTENSION_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat eine Erweiterung angefordert.',
            QUOTE_EXTENSION_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat den Erweiterungsantrag abgelehnt.',
            QUOTE_EXTENSION_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat den Erweiterungsantrag akzeptiert.',
            QUOTE_EXTENSION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat den Erweiterungsantrag abgebrochen.',
            QUOTE_AUDIT_TRAIL_SENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat das Auditprotokoll gesendet.',
            QUOTE_APPROVAL_REQUIRED: 'Angebotsgenehmigungen',
            REQUEST_MORE_INFORMATION_SUCCESS: 'Weitere Informationen angefordert',
            QUOTE_REQUEST_REPORTER: 'Gebäude-Kontaktdaten',
            PLEASE_SELECT_QUOTE_OPTIONS: 'Bitte wählen Sie Angebotsoptionen aus',
            QUOTE_ACCEPTED: 'Angebot angenommen',
            CANCEL_SUCCESS: 'Angebot aufgehoben',
            REOPEN_SUCCESS: 'Angebot erneut geöffnet',
            ABSTAIN_SUCCESS: 'Angebot abgelehnt',
            SUBMIT_SUCCESS: 'Angebot eingereicht',
            REJECT_SUCCESS: 'Angebot abgelehnt',
            HOLD_SUCCESS: 'Angebot gehalten',
            DATE_DUE: 'Datum fällig',
            DATE_IN: 'Datum in',
            NO_SERVICE_PROVIDERS_FOR_SITE: 'Kein Dienstleister für Gebäude',
            QUANTITY: 'Menge',
            PO_NUMBER: 'Auftragsnummer',
            JOBTO: 'Nach Auftrag',
            QUOTE_ACCEPTED_ACTION: 'Angebot angenommen von <strong>{{event._links.user.title}}</strong>',
            QUOTE_CANCELLED_ACTION: 'Angebot aufgehoben von <strong>{{event._links.user.title}}</strong>',
            QUOTE_EXPIRED_SYSTEM_ACTION: 'Dieses Angebot ist abgelaufen.',
            QUOTE_HOLD_ACTION: 'Angebot auf Haltestatus gesetzt von <strong>{{event._links.user.title}}</strong>',
            QUOTE_REJECTED_ACTION: 'Angebot abgelehnt von <strong>{{event._links.user.title}}</strong>',
            QUOTE_RFMI_ACTION: 'Anforderung von mehr Informationen von <strong>{{event._links.user.title}}</strong>',
            QUOTE_SUBMIT_ACTION: 'Angebot eingereicht von <strong>{{event._links.user.title}}</strong>',
            QUOTE_ACTIONED_ACTION: 'Die Frist für die erforderliche Maßnahme wurde vorverlegt.',
            P2P: 'P2P',
            P2P_CIS: 'CIS',
            P2P_TAX_CODE: 'Steuercode',
            P2P_COMMENT: 'Kommentar',
            P2P_DESCRIPTION: 'Beschreibung',
            P2P_TRANSACTION: 'Transaktionstyp',
            P2P_SUPPLIER: 'Lieferant',
            P2P_SCHEDULE_NUMBER: 'Nummer des Zeitplans',
            P2P_NOMINAL_LINES: 'Nennlinien',
            P2P_EXPENSE_CODE: 'Ausgaben-Code',
            P2P_TENANT_CODE: 'Mieter-Code',
            P2P_RATIO: 'Verhältnis (% der Kosten)',
            P2P_PO_SUPPLIER: 'P2P-Lieferant',
            ADD_LINE: 'Zeile hinzufügen',
            REMOVE_LINE: 'Zeile entfernen',
            P2P_ERROR_QUOTE_PENDING: 'P2P Angebot ausstehend',
            P2P_ERROR_DESCRIPTION_TOO_LONG: 'Beschreibung überschreitet 40 Zeichen',
            P2P_ERROR_JOB_HAS_PO_NUMBER: 'Der Auftrag hat bereits eine Auftragsnummer',
            P2P_ERROR_INVALID_SITE_OR_USER: 'Ungültiger Gebäude oder Benutzer',
            P2P_ERROR_RATIOS_INVALID: 'Die Verhältnisse müssen ganzzahlig sein und sich über alle Zeilen zu 100 addieren',
            NO_SEARCH_PROVIDED: 'Keine Suche verfügbar',
            MISSING_ARGUMENT: 'Argument fehlt',
            QUOTE_OPTIONS_TAB_FILES: 'Bitte verwenden Sie die Registerkarte Angebotsoptionen, um Dateien hinzuzufügen, die sich auf eine einzelne Angebotsoption beziehen',
            LOCATION_REQUIRED_QUOTE: 'Standort erforderlich für Angebote reaktivieren',
            QUOTE_ABSTAINED: 'Abgelehnt, ein Angebot zu machen',
            QUOTE_EXTENSION_CANCELLED: 'Abbruch Angebotserweiterung angefordert',
            QUOTE_EXTENSION_REQUESTED: 'Angebotserweiterung angefordert',
            QUOTE_EXTENSION_ACCEPTED: 'Angebotserweiterung angenommen',
            QUOTE_EXTENSION_REJECTED: 'Angebotserweiterung abgelehnt',
            QUOTE_SUBMITTED: 'Angebot eingereicht',
            QUOTE_MESSAGE: 'Angebots-Updates hinzugefügt',
            QUOTE_THRESHOLD: 'Angebotsschwellenwert',
            QUOTE_THRESHOLDS: 'Angebotsschwellenwerte',
            QUOTE_THRESHOLD_ADD: 'Eine neue Angebotsschwellenwert hinzufügen',
            QUOTE_THRESHOLD_EDIT: 'Angebotsschwellenwert bearbeiten',
            QUOTE_THRESHOLD_LIST: 'Angebotsschwellenwerte',
            FEWER_APPROVERS_THAN_THRESHOLDS: 'Um alle Angebotsschwellenwerte abzudecken, sind mehr Angebotsgenehmigende erforderlich',
            QUOTE_REQUEST_ADD_SITE: 'Gebäude',
            QUOTE_REQUEST_ADD_CONTACT_DETAILS: 'Gebäude-Kontaktdaten',
            QUOTE_REQUEST_ADD_DETAILS: 'Angebotsdetails',
            INCLUDE_NEVER_SUBMITTED: 'Nie eingereicht einschließen',
            QUOTE_REQUEST: 'Angebotsanforderung',
            QUOTE_REQUESTS: 'Angebotsanforderungen',
            QUOTE_REQUEST_LIST: 'Angebotsanforderungen',
            QUOTE_REQUEST_ADD: 'Eine neue Angebotsanforderung hinzufügen',
            QUOTE_REQUEST_CREATED: 'Angebotsanforderung erstellt',
            QUOTE_REQUEST_UPDATED: 'Angebotsanforderung aktualisiert',
            QUOTE_REQUEST_DELETED: 'Änderungsanforderung gelöscht',
            QUOTE_REQUEST_DETAILS: 'Details Änderungsanforderung',
            QUOTE_REQUEST_CONTACTS: 'Kontakte Angebotsanforderung',
            QUOTE_REQUEST_STATUS_CREATED: 'Erstellt',
            QUOTE_REQUEST_STATUS_REQUESTED: 'Angefordert',
            QUOTE_REQUEST_STATUS_PART_QUOTED: 'Teil angeboten',
            QUOTE_REQUEST_STATUS_QUOTED: 'Angeboten',
            QUOTE_REQUEST_STATUS_REJECTED: 'Abgelehnt',
            QUOTE_REQUEST_STATUS_ACCEPTED: 'Akzeptiert',
            QUOTE_REQUEST_STATUS_CANCELLED: 'Abgebrochen',
            QUOTE_REQUEST_STATUS_EXPIRED: 'Abgelaufen',
            QUOTE_REQUEST_STATUS_DECLINED: 'Abgelehnt',
            QUOTE_REQUEST_STATUS_EXTENSION_PENDING: 'Erweiterung angefordert',
            CREATED_FROM_JOB: 'Von Auftrag erstellt',
            QUOTE_WORKFLOW_REOPEN_SUCCESS: 'Angebotsanforderung erneut eröffnen erfolgreich',
            QUOTE_WORKFLOW_CANCEL_SUCCESS: 'Angebotsanforderung abbrechen erfolgreich',
            NEW_COMPLETION_DATE_MUST_BE_AFTER: 'Neues Abschlussdatum muss nach dem aktuellen Abschlussdatum liegen',
            SUPPORTING_DOCUMENT: 'Unterstützende Dateien',
            AT_LEAST_ONE_SERVICE_PROVIDER_DOES_NOT_HAVE_ACTIVE_PARTNERSHIP_WITH_SITE: 'Der Dienstanbieter hat keine aktive Partnerschaft mit dem Gebäude, für den Sie die Angebotsanfrage stellen',
            QUOTE_OPTION: 'Angebotsoption',
            QUOTE_OPTIONS: 'Angebotsoptionen',
            QUOTE_OPTION_LIST: 'Angebotsoptionen',
            QUOTE_OPTION_VIEW: 'Angebotsoption',
            QUOTE_OPTION_ADD: 'Eine neue Angebotsoption hinzufügen',
            QUOTE_OPTION_EDIT: 'Angebotsoption bearbeiten',
            QUOTE_OPTION_CREATED: 'Angebotsoption erstellt',
            QUOTE_OPTION_UPDATED: 'Angebotsoption aktualisiert',
            QUOTE_OPTION_DELETED: 'Angebotsoption gelöscht',
            QUOTE_OPTION_STATUS_ACCEPTED: 'Akzeptiert',
            QUOTE_OPTION_STATUS_REJECTED: 'Abgelehnt',
            QUOTE_OPTION_STATUS_DRAFT: 'Entwurf',
            QUOTE_OPTION_STATUS_SUBMITTED: 'Eingereicht',
            OPTION_REQUIRES_ESCLATION: 'Der Wert dieser Option liegt außerhalb Ihres Genehmigungsschwellenwerts und erfordert eine Eskalation',
            QUOTE_ALREADY_ACCEPTED: 'Dieses Angebot wurde bereits angenommen',
            QUOTE_OPTION_LABOUR: 'Arbeit',
            QUOTE_OPTION_LABOURS: 'Arbeit',
            QUOTE_OPTION_LABOUR_LIST: 'Arbeit',
            QUOTE_OPTION_LABOUR_ADD: 'Eine neue Arbeitszeile hinzufügen',
            QUOTE_OPTION_LABOUR_EDIT: 'Bearbeiten',
            QUOTE_OPTION_LABOUR_CREATED: 'Arbeitszeile erstellt',
            QUOTE_OPTION_LABOUR_UPDATED: 'Arbeitszeile aktualisiert',
            QUOTE_OPTION_LABOUR_DELETED: 'Arbeitszeile gelöscht',
            QUOTE_OPTION_LABOUR_LABOUR_HOURS: 'Arbeitsstunden',
            QUOTE_OPTION_LABOUR_TRAVEL_HOURS: 'Reisezeit',
            QUOTE_OPTION_LABOUR_MILEAGE: 'Bisherige Fahrleistung laut Tacho',
            QUOTE_OPTION_LABOUR_VALUE: 'Wert',
            QUOTE_OPTION_MATERIAL: 'Materialien',
            QUOTE_OPTION_MATERIALS: 'Materialien',
            QUOTE_OPTION_MATERIAL_LIST: 'Materialien',
            QUOTE_OPTION_MATERIAL_ADD: 'Einen neuen Material hinzufügen',
            QUOTE_OPTION_MATERIAL_EDIT: 'Bearbeiten',
            QUOTE_OPTION_MATERIAL_CREATED: 'Material erstellt',
            QUOTE_OPTION_MATERIAL_UPDATED: 'Material aktualisiert',
            QUOTE_OPTION_MATERIAL_DELETED: 'Material gelöscht',
            QUOTE_OPTION_MATERIAL_QUANTITY: 'Menge',
            QUOTE_OPTION_MATERIAL_QUOTED_VALUE: 'Angebotener Wert',
            QUOTE_OPTION_MATERIAL_QUOTED_TAX_VALUE: 'Angebotener Steuerwert',
            QUOTE_OPTION_MATERIAL_ACTUAL_VALUE: 'Istwert',
            QUOTE_OPTION_MATERIAL_ACTUAL_TAX_VALUE: 'Tatsächlicher Steuerwert',
            GO_TO_JOB: 'Gehe zu Auftrag',
            GO_TO_QUOTE: 'Gehe zu Angebot',
            SITE_QUICK_ACTIONS: 'Gebäude Schnellaktionen',
            RAISE_JOB: 'Auftrag erstellen',
            VIEW_JOBS: 'Aufträge einsehen',
            RAISE_QUOTE: 'Angebot schaffen',
            VIEW_QUOTES: 'Angebote anzeigen',
            NO_JOB_FOUND: 'Kein Auftrag gefunden.',
            NO_QUOTE_FOUND: 'Kein Angebot gefunden.',
            PUBLIC_HOLIDAYS: 'Feiertage',
            PUBLIC_HOLIDAYS_LIST: 'Feiertage',
            PUBLIC_HOLIDAYS_ADD: 'Feiertag hinzufügen',
            PUBLIC_HOLIDAYS_EDIT: 'Feiertag bearbeiten',
            PUBLIC_HOLIDAY: 'Feiertag',
            PUBLIC_HOLIDAY_ADD: 'Feiertag hinzufügen',
            PUBLIC_HOLIDAY_EDIT: 'Feiertag bearbeiten',
            PUBLIC_HOLIDAY_ADDED: 'Feiertag hinzugefügt',
            PUBLIC_HOLIDAY_UPDATED: 'Feiertag aktualisiert',
            PUBLIC_HOLIDAY_DELETED: 'Feiertag gelöscht',
            PRIORITY: 'Priorität',
            PRIORITIES: 'Prioritäten',
            ADD_PRIORITY: 'Eine neue Priorität hinzufügen',
            PRIORITY_LIST: 'Prioritäten',
            PRIORITY_EDIT: 'Priorität bearbeiten',
            PRIORITY_ADD: 'Eine neue Priorität hinzufügen',
            PRIORITY_CREATED: 'Priorität wurde erfolgreich erstellt',
            PRIORITY_UPDATED: 'Priorität wurde erfolgreich aktualisiert',
            PRIORITY_DELETED: 'Priorität gelöscht',
            DEADLINE: 'Frist',
            ATTEND_TIME: 'Betreut binnen',
            ATTEND_DEADLINE: 'Betreuungsfrist',
            NON_WORKING_DAYS: 'Keine Werktage',
            INCLUDE_NON_WORKING_DAYS: 'Keine Werktage einschließen',
            COMPLETE_TIME: 'Abschließen in',
            COMPLETE_DEADLINE: 'Frist vollständig',
            APPROVAL_DELAY: 'Verzögerung bei der Genehmigung',
            ACKNOWLEDGE_DELAY: 'Verzögerung bestätigen',
            ALARM_WHEN_OVERDUE: 'Alarm bei Überfälligkeit',
            ALERT_MESSAGE_ENABLED: 'Warnmeldung aktiviert',
            ALERT_MESSAGE: 'Warnmeldung',
            ADD_NEW_PRIORITY: 'Neue Priorität hinzufügen',
            ATTENDANCE_DUE: 'Teilnahme fällig',
            COMPLETION_DUE: 'Beendigung fällig',
            PRIORITY_MESSAGE: 'Prioritäre Benachrichtigung',
            HELPTEXT_DELAY: 'Werte von 0 verwenden Systemeinstellungen',
            ENTITY_TYPE: 'Typ',
            ENTITY_TYPE_ALL: 'Alle',
            ENTITY_TYPE_JOB: 'Aufträge',
            ENTITY_TYPE_QUOTE: 'Angebote',
            ENTITY_TYPE_ACTION: 'Maßnahmen',
            SELECT_PRIORITY: 'Priorität auswählen',
            PERFORMANCE_STATISTICS: 'Leistungsstatistik',
            PERFORMANCE_SERVICE_PROVIDER: 'Ihre Leistung',
            PERFORMANCE_STATUS: 'Status',
            PERFORMANCE_SYSTEM: 'Durchschnittliche Leistung',
            PERFORMANCE_TYPE_AVERAGE: 'Gesamtleistung',
            PERFORMANCE_TYPE_REACTIVE_UPDATES_ACHIEVED: 'Updates reaktivieren Erreicht',
            PERFORMANCE_TYPE_REACTIVE_ATTENDANCE_ACHIEVED: 'Teilnahme reaktivieren Erreicht',
            PERFORMANCE_TYPE_REACTIVE_COMPLETION_ACHIEVED: 'Beendigung reaktivieren Erreicht',
            PERFORMANCE_TYPE_PLANNED_UPDATES_ACHIEVED: 'PPM-Updates Erreicht',
            PERFORMANCE_TYPE_PLANNED_COMPLETION_ACHIEVED: 'PPM abgeschlossen',
            PERFORMANCE_TYPE_QUOTE_SLA_ACHIEVED: 'Angebot SLAs Erreicht',
            PERFORMANCE_TYPE_KPI_SURVEYS: 'KPI-Umfragen',
            PERFORMANCE_DASHBOARD_TYPE_AVERAGE: 'Insgesamt<br />Leistung',
            PERFORMANCE_DASHBOARD_TYPE_REACTIVE_UPDATES_ACHIEVED: 'Reaktivieren<br />Updates',
            PERFORMANCE_DASHBOARD_TYPE_REACTIVE_ATTENDANCE_ACHIEVED: 'SLA reaktivieren<br />Teilnahme',
            PERFORMANCE_DASHBOARD_TYPE_REACTIVE_COMPLETION_ACHIEVED: 'SLA reaktivieren<br />Beendigung',
            PERFORMANCE_DASHBOARD_TYPE_PLANNED_COMPLETION_ACHIEVED: 'PPM<br />Abschließen',
            PERFORMANCE_DASHBOARD_TYPE_PLANNED_UPDATES_ACHIEVED: 'PPM<br />Updates',
            PERFORMANCE_DASHBOARD_TYPE_QUOTE_SLA_ACHIEVED: 'Angebot SLA<br />Beendigung',
            PERFORMANCE_DASHBOARD_TYPE_KPI_SURVEYS: 'KPI<br />Umfragen',
            PERFORMANCE_INFORMATION_TITLE: 'Leistungsinformationen',
            PERFORMANCE_INFORMATION_DESCRIPTION: 'Diese Statistiken werden als Durchschnitt über alle Dienstleistungsanbieter für die letzten 30 Tage berechnet.',
            PERFORMANCE_TYPE_AVERAGE_INFORMATION: 'Der Durchschnitt aller anderen Prozentsätze',
            PERFORMANCE_TYPE_REACTIVE_UPDATES_ACHIEVED_INFORMATION: 'Basierend auf dem Prozentsatz der ausstehenden reaktiven Auftragserinnerungen. Wenn Sie auf das Statussymbol klicken, werden Ihnen ausstehende reaktive Aufträge angezeigt, für die eine Aktualisierung fällig ist.',
            PERFORMANCE_TYPE_REACTIVE_ATTENDANCE_ACHIEVED_INFORMATION: 'Der Prozentsatz der Male, die Sie an einem reaktiven Auftrag innerhalb der SLA-Frist teilgenommen haben.',
            PERFORMANCE_TYPE_REACTIVE_COMPLETION_ACHIEVED_INFORMATION: 'Der Prozentsatz der Male, die Sie einen reaktiven Auftrag innerhalb der SLA-Frist abgeschlossen haben.',
            PERFORMANCE_TYPE_PLANNED_UPDATES_ACHIEVED_INFORMATION: 'Basierend auf dem Prozentsatz der ausstehenden ppm-Auftragserinnerungen. Wenn Sie auf das Statussymbol klicken, werden Ihnen ausstehende geplante Aufträge angezeigt, für die eine Aktualisierung fällig ist.',
            PERFORMANCE_TYPE_PLANNED_COMPLETION_ACHIEVED_INFORMATION: 'Der Prozentsatz der Male, die Sie einen PPM-Auftrag innerhalb der SLA-Frist abgeschlossen haben.',
            PERFORMANCE_TYPE_QUOTE_SLA_ACHIEVED_INFORMATION: 'Der Prozentsatz der Male, die Sie einen Angebotsauftrag innerhalb der SLA-Frist eingereicht haben.',
            PERFORMANCE_TYPE_KPI_SURVEYS_INFORMATION: 'Basierend auf dem durchschnittlichen FM-KPI-Ergebnis des vorangegangenen Kalendermonats. Wenn Sie die Ampel wählen, werden diejenigen KPIs angezeigt, deren FM-Ergebnis weniger als 100% beträgt.',
            PATROL: 'Patrouille',
            PATROLS: 'Patrouillen',
            MANAGE_PATROLS: 'Patrouillen verwalten',
            PATROL_ADD: 'Eine neue Patrouille hinzufügen',
            PATROL_EDIT: 'Patrouille bearbeiten',
            PATROL_LIST: 'Patrouillen',
            PATROL_CREATED: 'Patrouille erstellt',
            PATROL_UPDATED: 'Patrouille aktualisiert',
            PATROL_DELETED: 'Patrouille gelöscht',
            ORDERED: 'Bestellt',
            ORDERED_YES: 'Bestellt',
            ORDERED_NO: 'Nicht bestellt',
            PATROL_POINT: 'Patrouillenpunkt',
            PATROL_POINTS: 'Patrouillenpunkte',
            PATROL_POINT_TAB: 'Patrouillenpunkte',
            PATROL_POINT_LIST: 'Patrouillenpunkte',
            PATROLPOINTS_COUNT: 'Punkte',
            PATROLPOINTS_TIME_COUNT: 'Dauer',
            PATROL_POINT_ADD: 'Punkt hinzufügen',
            PATROL_POINT_EDIT: 'Punkt bearbeiten',
            TIMEALLOCATED: 'Zeit zugeordnet',
            REQUIREMENTTYPE: 'Anforderungstyp',
            REQUIREMENT: 'Anforderungen',
            PATROL_POINT_REQUIREMENT_NONE: 'Keine',
            PATROL_POINT_REQUIREMENT_BARCODE: 'Barcode',
            PATROL_POINT_REQUIREMENT_NFC: 'NFC',
            PATROL_POINT_REQUIREMENT_PHOTO: 'Foto',
            PATROL_POINT_REQUIREMENT_SIGNATURE: 'Unterschrift',
            PATROL_POINT_REQUIREMENT_FILE: 'Datei',
            PATROL_POINT_REQUIREMENT_BARCODE_ID: 'Barcode',
            PATROL_POINT_REQUIREMENT_NFC_ID: 'NFC ID',
            PATROLPOINT_CREATED: 'Punkt erstellt',
            PATROLPOINT_UPDATED: 'Punkt aktualisiert',
            PATROLPOINT_DELETED: 'Punkt gelöscht',
            PATROL_TEMPLATE: 'Patrouillenvorlage',
            PATROL_TEMPLATE_LIST: 'Patrouillenvorlagen',
            PATROL_TEMPLATE_ADD: 'Vorlage hinzufügen',
            PATROL_TEMPLATE_EDIT: 'Vorlage bearbeiten',
            PATROL_SCHEDULE: 'Patrouillenzeitplan',
            PATROL_SCHEDULES: 'Patrouillenzeitpläne',
            PATROL_SCHEDULES_TAB: 'Patrouillenzeitpläne',
            PATROL_SCHEDULE_LIST: 'Patrouillenzeitpläne',
            PATROL_SCHEDULE_ADD: 'Zeitplan hinzufügen',
            PATROL_SCHEDULE_EDIT: 'Zeitplan bearbeiten',
            DAYOFWEEK: 'Wochentag',
            DAY_OF_WEEK: 'Wochentag',
            START_TIME: 'Startzeit',
            END_TIME: 'Endzeit',
            PATROL_SCHEDULE_CREATED: 'Zeitplan erstellt',
            PATROL_SCHEDULE_UPDATED: 'Zeitplan aktualisiert',
            PATROL_SCHEDULE_DELETED: 'Zeitplan gelöscht',
            PATROL_OCCURRENCE: 'Patrouillen-Vorkommen',
            PATROL_OCCURRENCES: 'Patrouillen-Vorkommen',
            PATROL_OCCURRENCE_CREATED: 'Vorkommen erstellt',
            PATROL_OCCURRENCE_UPDATED: 'Vorkommen aktualisiert',
            PATROL_OCCURRENCE_DELETED: 'Vorkommen gelöscht',
            PATROL_OCCURRENCE_ADD: 'Vorkommen hinzufügen',
            PATROL_OCCURRENCE_EDIT: 'Vorkommen bearbeiten',
            PATROL_OCCURRENCE_ADD_BUTTON: 'Vorkommen hinzufügen',
            PATROL_OCCURRENCE_ADD_BUTTON_MULTIPLE: 'Mehrere Vorkommen hinzufügen',
            PATROL_OCCURRENCE_LIST: 'Patrouillen-Vorkommen',
            PATROL_OCCURRENCE_VIEW: 'Patrouillen-Vorkommen',
            OCCURRENCE: 'Vorkommen',
            PATROL_NAME: 'Patrouillenname',
            PATROL_STATUS_REGISTERED: 'Registriert',
            PATROL_STATUS_SCHEDULED: 'Geplant',
            PATROL_STATUS_IN_PROGRESS: 'In Bearbeitung',
            PATROL_STATUS_OVERDUE: 'Überfällig',
            PATROL_STATUS_COMPLETED: 'Abgeschlossen',
            PATROL_STATUS_CANCELLED: 'Abgebrochen',
            PATROL_STATUS_MISSED: 'Versäumt',
            PATROL_STATUS_AUTO_MISSED: 'Auto Versäumt',
            PATROL_POINT_WAITING: 'Warte',
            PATROL_POINT_MISSED: 'Versäumt',
            PATROL_POINT_DONE: 'Erledigt',
            PATROL_TEMPLATE_CHANGE: 'Änderung Patrouillenvorlage',
            PATROL_TEMPLATE_CHANGE_TEXT: 'Sind Sie sicher, dass Sie die Patrouillenvorlage ändern möchten?',
            TEMPLATE_UPDATED: 'Vorlage aktualisiert',
            PATROL_ID: 'Patrouillen-ID',
            CREATE_FROM_POINT: 'Von Punkt erstellen',
            PATROL_INSTANCES: 'Patrouillen-Instanzen',
            PATROL_STATISTICS: 'Patrouillen-Statistiken',
            PATROL_INSTANCE_LIST: 'Liste Patrouillen-Instanz',
            PATROL_INSTANCE_VIEW: 'Patrouillen-Instanz',
            INSTANCE: 'Instanz',
            INSTRUCTIONS: 'Anweisungen',
            TIME_ALLOCATED: 'Zeit zugeordnet',
            ATTENDED_AT: 'Betreut in',
            COMPLETED_AT: 'Abgeschlossen in',
            PLANNED_START_DATE: 'Geplantes Anfangsdatum',
            PLANNED_END_DATE: 'Geplantes Enddatum',
            DATE_STARTED: 'Startdatum',
            REQUIREMENT_STATUS: 'Anforderungsstatus',
            REQUIREMENT_CONFIRMATION: 'Anforderungsbestätigung',
            DOWNLOAD_FILE: 'Datei herunterladen',
            POINTS: 'Punkte',
            REASON: 'Grund',
            STARTED_ON_TIME: 'Fristgerecht gestartet',
            POINTS_COMPLETED: 'Punkte abgeschlossen',
            NOT_STARTED_EARLY: 'Nicht früh gestartet',
            POINTS_REQUIREMENTS_MET: 'Punkte Anforderungen erfüllt',
            MISSED_OR_INCOMPLETE: 'Versäumt oder unvollständig',
            COMPLETED_LATE: 'Spät abgeschlossen',
            STARTED_LATE: 'Spät gestartet',
            STARTED_EARLY: 'Früh gestartet',
            POINTS_MISSED: 'Punkte versäumt',
            POINTS_REQUIREMENTS_NOT_MET: 'Punkte Anforderungen nicht erfüllt',
            TOTAL_NUMBER_OF_PATROLS: 'Gesamtzahl der Patrouillen',
            PATROL_PLANNER: 'Patrouillen-Planer',
            PATROL_ACTIVE: 'Patrouille aktiv',
            PATROL_DRAFT: 'Patrouillen-Entwurf',
            PATROL_COMMENCED: 'Patrouille begonnen',
            PATROL_MISSED: 'Patrouille versäumt',
            PATROL_COMPLETED: 'Patrouille abgeschlossen',
            PATROL_POINT_COMPLETED: 'Patrouillenpunkt abgeschlossen',
            PATROL_BULK_SCHEDULE_LOW_FREQUENCY_WARNING: 'Die Frequenz liegt unter der empfohlenen Menge',
            PATROL_MISSED_REASON_PHOTO: 'Foto Versäumte Ursache',
            GENERATE_NEW_PASSWORD: 'Neues Kennwort generieren',
            RESET_PASSWORD: 'Kennwort zurücksetzen',
            RESET_PASSWORD_INSTRUCTIONS: 'Klicken, um ein neues Kennwort zu generieren.',
            MODIFY_PASSWORD: 'Kennwort ändern',
            NEW_PASSWORD: 'Neues Kennwort',
            CONFIRM_PASSWORD: 'Kennwort bestätigen',
            NEW_PASSWORD_GENERATED: 'Neues Kennwort generiert.',
            LOGIN_AS_USER: 'Anmeldung als dieser Benutzer',
            PASSWORD_IS_TOO_SHORT: 'Kennwort ist zu kurz',
            PASSWORD_SHOULD_CONTAIN_ONE_UPPERCASE: 'Das Kennwort sollte mindestens einen Großbuchstaben enthalten',
            PASSWORD_SHOULD_CONTAIN_ONE_NUMBER: 'Das Kennwort sollte mindestens eine Zahl enthalten',
            PASSWORD_SHOULD_CONTAIN_ONE_PUNCTUATION_CHAR: 'Das Kennwort sollte mindestens ein Satzzeichen enthalten',
            PASSWORD_SHOULD_BE_DIFFERENT_THAN_LAST_3: 'Das Kennwort sollte sich von den 3 zuletzt verwendeten unterscheiden',
            RETURN_TO_ORIGINAL_USER_FIRST: 'Kehren Sie zuerst zum ursprünglichen Benutzer zurück.',
            PARTNERSHIP: 'Partnerschaft',
            PARTNERSHIPS: 'Partnerschaften',
            ADD_NEW_PARTNERSHIP: 'Eine neue Partnerschaft hinzufügen',
            PARTNERSHIP_ADD: 'Eine neue Partnerschaft hinzufügen',
            PARTNERSHIPS_ADD: 'Partnerschaften hinzufügen',
            PARTNERSHIPS_LIST: 'Partnerschaften',
            SERVICE_PROVIDER_PARTNERSHIP_ADD: 'Eine neue Partnerschaft hinzufügen',
            PARTNERSHIP_LIST: 'Partnerschaften',
            PARTNERSHIP_EDIT: 'Partnerschaft bearbeiten',
            PARTNERSHIP_CREATED: 'Partnerschaft erstellt',
            PARTNERSHIP_UPDATED: 'Partnerschaft aktualisiert',
            PARTNERSHIP_DELETED: 'Partnerschaft gelöscht',
            PARTNERSHIP_SURVEYS_ADDED: 'Umfragen hinzugefügt',
            PARTNERSHIP_SURVEYS_DELETED: 'Umfragen gelöscht',
            PARTNERSHIP_STATUS: 'Partnerschaftsstatus',
            SURVEYS_ADDED: 'Umfragen hinzugefügt',
            SURVEYS_DELETED: 'Umfragen gelöscht',
            PARTNERSHIP_IN_USE: 'Partnerschaft ist in Gebrauch',
            P2PREFERENCE: 'P2P-Referenz',
            CANNOT_VALIDATE_ROLE: 'Kann eine erforderliche Rolle nicht validieren',
            P2P_VALUE: 'Nettowert',
            ADD_P2P_VALUE: 'P2P-Wert hinzufügen',
            ADD_PO_NUMBER: 'Auftragsnummer hinzufügen',
            JOB_VALUE_UPDATED: 'Auftragswert aktualisiert',
            JOB_PO_UPDATED: 'Auftrag aktualisiert',
            P2P_NOT_VALID_SITE: 'Der mit diesem Auftrag verbundener Gebäude verfügt nicht über eine gültige P2P-Referenz',
            P2P_NOT_VALID_USER: 'Ihr Benutzer hat keine gültige P2P-E-Mail-Adresse',
            P2P_ELEMENT3_SAVILLS: 'Barzahlungsart',
            P2P_ELEMENT4_SAVILLS: 'Zeitplan',
            P2P_ELEMENT5_SAVILLS: 'GL-Code',
            P2P_ELEMENT6_SAVILLS: 'Mieten',
            P2P_ELEMENT7_SAVILLS: 'Einheit',
            P2P_ELEMENT3_KF: 'Werthaltigkeit',
            P2P_ELEMENT4_KF: 'Zeitplan',
            P2P_ELEMENT5_KF: 'GL-Code',
            P2P_ELEMENT6_KF: 'Einheit',
            P2P_ELEMENT7_KF: 'Mieter',
            P2P_ELEMENT3_FSHC: 'Typ',
            P2P_ELEMENT4_FSHC: 'GL-Code',
            P2P_ELEMENT5_FSHC: 'Asset-Kategorien',
            P2P_ELEMENT6_FSHC: 'Asset-Code',
            OPERATIVE: 'Arbeiter',
            OPERATIVES: 'Arbeiter',
            OPERATIVES_LIST: 'Arbeiter',
            OPERATIVES_ADD: 'Einen Arbeiter hinzufügen',
            REMOVE_OPERATIVE: 'Arbeiter entfernen',
            CREATE_NEW_OPERATIVE: 'Neuen Arbeiter erstellen',
            ADD_SERVICE_PROVIDER: 'Dienstleister hinzufügen',
            MANAGE_OPERATIVES: 'Arbeiter verwalten',
            OPERATIVE_INFORMATION: 'Information über Arbeiter',
            CREATE_OPERATIVE: 'Arbeiter erstellen',
            ACTIVITY_LOG: 'Aktivitätenprotokoll',
            USER_IS_ALREADY_OPERATIVE: 'Dieser Benutzer ist bereits ein Arbeiter',
            COPY_OPERATIVE: 'Von bestehendem Arbeiter kopieren',
            COPY: 'Kopieren',
            SERVICE_PROVIDER_REQUIRED: 'Dienstleister ist erforderlich',
            SOURCE_TYPE: 'Quell-Typ',
            SOURCE_ID: 'Quell-ID',
            ACTIVITY_VIEW: 'Aktivität',
            OPERATIVE_PERMISSIONS: 'Berechtigungen Arbeiter',
            ADD_OPERATIVE: 'Arbeiter hinzufügen',
            OPERATIVE_PERMISSION_VIEW_ASSETS: 'Assets anzeigen',
            OPERATIVE_PERMISSION_CREATE_QUOTES: 'Angebote erstellen',
            OPERATIVE_PERMISSION_VIEW_QUOTES: 'Angebote anzeigen',
            OPERATIVE_PERMISSION_EDIT_ASSETS: 'Assets bearbeiten',
            OPERATIVE_PERMISSION_VIEW_TASKS: 'Aufgaben anzeigen',
            OPERATIVE_PERMISSION_EDIT_TASKS: 'Aufgaben bearbeiten',
            OPERATIVE_PERMISSION_CREATE_TASKS: 'Aufgaben erstellen',
            OPERATIVE_PERMISSION_VIEW_FILES: 'Dateien anzeigen',
            OPERATIVE_PERMISSION_EDIT_FILES: 'Dateien bearbeiten',
            OPERATIVE_PERMISSION_VIEW_PATROL_INSTANCES: 'Patrouillen-Instanzen anzeigen',
            OPERATIVE_PERMISSION_EDIT_PATROL_INSTANCES: 'Patrouillen-Instanzen bearbeiten',
            OPERATIVE_PERMISSION_RECEIVE_PATROL_INSTANCES: 'Patrouillen-Instanzen empfangen',
            OPERATIVE_PERMISSION_REASSIGN_JOB_OPERATIVE: 'Auftragsarbeiter neu zuweisen',
            OPERATIVE_PERMISSION_CREATE_REACTIVE_JOBS: 'Reaktive Aufträge erstellen',
            OPERATIVE_PERMISSION_CREATE_REACTIVE_JOBS_FOR_OTHER_SERVICE_PROVIDERS: 'Reaktive Aufträge für andere Dienstleister erstellen',
            OPERATIVE_PERMISSION_EDIT_CONTRACTS: 'Verträge bearbeiten',
            OPERATIVE_PERMISSION_EDIT_JOBS: 'Aufträge bearbeiten',
            OPERATIVE_PERMISSION_REJECT_INSIST_JOBS: 'Aufträge von gemeinsamen Dienstleister ablehnen oder darauf bestehen',
            OPERATIVE_PERMISSION_EDIT_METER_READINGS: 'Zählerablesungen bearbeiten',
            OPERATIVE_PERMISSION_EDIT_METERS: 'Zähler bearbeiten',
            OPERATIVE_PERMISSION_MANAGE_OPERATIVES: 'Arbeiter verwalten',
            OPERATIVE_PERMISSION_VIEW_AUDITS: 'Audits anzeigen',
            OPERATIVE_PERMISSION_EDIT_AUDIT_REFERENCE: 'Audit-Referenz bearbeiten',
            OPERATIVE_PERMISSION_RECEIVE_AUDITS: 'Audits empfangen',
            OPERATIVE_PERMISSION_RECEIVE_ALL_EMAILS: 'Alle E-Mails empfangen',
            OPERATIVE_PERMISSION_RECEIVE_CONTRACTS: 'Verträge empfangen',
            OPERATIVE_PERMISSION_RECEIVE_ESCALATIONS: 'Eskalationen empfangen',
            OPERATIVE_PERMISSION_RECEIVE_JOBS: 'Aufträge empfangen',
            OPERATIVE_PERMISSION_REQUEST_PLANNED_JOBS: 'Geplante Aufträge anfordern',
            OPERATIVE_PERMISSION_VIEW_CONTRACTS: 'Verträge anzeigen',
            OPERATIVE_PERMISSION_VIEW_JOBS: 'Aufträge einsehen',
            OPERATIVE_PERMISSION_VIEW_LINK_JOBS: 'Link für Aufträge anzeigen',
            OPERATIVE_PERMISSION_REMOVE_LINK_JOBS: 'Link für Aufträge löschen',
            OPERATIVE_PERMISSION_VIEW_METERS: 'Zähler anzeigen',
            OPERATIVE_PERMISSION_VIEW_SITES: 'Gebäude anzeigen',
            OPERATIVE_PERMISSION_VIEW_SURVEYS: 'Umfragen anzeigen',
            OPERATIVE_PERMISSION_EDIT_SURVEYS: 'Umfragen bearbeiten',
            OPERATIVE_PERMISSION_CREATE_SURVEYS: 'Umfragen erstellen',
            OPERATIVE_PERMISSION_VIEW_CONTACTS: 'Kontakte anzeigen',
            OPERATIVE_PERMISSION_VIEW_APPROVERS: 'Gebäudegenehmigende anzeigen',
            OPERATIVE_PERMISSION_ASSET_USER_IMPORTS: 'Assets importieren',
            OPERATIVE_PERMISSION_TASK_USER_IMPORTS: 'Aufgaben importieren',
            OPERATIVE_PERMISSION_ASSET_TASK_USER_IMPORTS: 'Planer-Import',
            OPERATIVE_PERMISSION_FORCE_COMPLETE_AUDITS: 'Vollständige Audits erzwingen',
            OPERATIVE_PERMISSION_FORCE_COMPLETE_AUDITS_DETAIL: 'Die Befähigung, den Abschluss eines Audits zu forcieren',
            OPERATIVE_PERMISSION_EDIT_AUDIT_REFERENCE_DETAIL: 'Audit-Referenz bearbeiten, wenn Audit aktiv ist',
            OPERATIVE_PERMISSION_VIEW_ASSETS_DETAIL: '',
            OPERATIVE_PERMISSION_CREATE_QUOTES_DETAIL: '',
            OPERATIVE_PERMISSION_VIEW_QUOTES_DETAIL: '',
            OPERATIVE_PERMISSION_EDIT_ASSETS_DETAIL: '',
            OPERATIVE_PERMISSION_VIEW_TASKS_DETAIL: '',
            OPERATIVE_PERMISSION_EDIT_TASKS_DETAIL: '',
            OPERATIVE_PERMISSION_CREATE_TASKS_DETAIL: '',
            OPERATIVE_PERMISSION_VIEW_FILES_DETAIL: '',
            OPERATIVE_PERMISSION_EDIT_FILES_DETAIL: '',
            OPERATIVE_PERMISSION_RECEIVE_AUDITS_DETAIL: '',
            OPERATIVE_PERMISSION_VIEW_AUDITS_DETAIL: '',
            OPERATIVE_PERMISSION_VIEW_PATROL_INSTANCES_DETAIL: '',
            OPERATIVE_PERMISSION_EDIT_PATROL_INSTANCES_DETAIL: '',
            OPERATIVE_PERMISSION_RECEIVE_PATROL_INSTANCES_DETAIL: '',
            OPERATIVE_PERMISSION_VIEW_APPROVERS_DETAIL: 'Gebäudegenehmigende anzeigen',
            OPERATIVE_PERMISSION_CREATE_REACTIVE_JOBS_DETAIL: '',
            OPERATIVE_PERMISSION_CREATE_REACTIVE_JOBS_FOR_OTHER_SERVICE_PROVIDERS_DETAIL: '',
            OPERATIVE_PERMISSION_EDIT_CONTRACTS_DETAIL: '',
            OPERATIVE_PERMISSION_EDIT_JOBS_DETAIL: '',
            OPERATIVE_PERMISSION_EDIT_METER_READINGS_DETAIL: 'Die Befähigung, Zählerablesungen für die mit diesem Dienstanbieter verbundenen Zähler zu erstellen, zu aktualisieren und zu löschen',
            OPERATIVE_PERMISSION_EDIT_METERS_DETAIL: 'Die Befähigung, mit diesem Dienstanbieter verbundene Zähler zu erstellen, zu aktualisieren und zu löschen',
            OPERATIVE_PERMISSION_MANAGE_OPERATIVES_DETAIL: '',
            OPERATIVE_PERMISSION_RECEIVE_ALL_EMAILS_DETAIL: '',
            OPERATIVE_PERMISSION_RECEIVE_CONTRACTS_DETAIL: '',
            OPERATIVE_PERMISSION_RECEIVE_JOBS_DETAIL: '',
            OPERATIVE_PERMISSION_REQUEST_PLANNED_JOBS_DETAIL: '',
            OPERATIVE_PERMISSION_VIEW_CONTRACTS_DETAIL: '',
            OPERATIVE_PERMISSION_VIEW_JOBS_DETAIL: '',
            OPERATIVE_PERMISSION_VIEW_METERS_DETAIL: 'Die Befähigung, mit diesem Dienstanbieter verbundene Zähler anzuzeigen',
            OPERATIVE_PERMISSION_VIEW_SITES_DETAIL: 'Zugang zum Gebäude von diesem Dienstanbieter übernehmen',
            OPERATIVE_PERMISSION_VIEW_SURVEYS_DETAIL: '',
            OPERATIVE_PERMISSION_EDIT_SURVEYS_DETAIL: '',
            OPERATIVE_PERMISSION_CREATE_SURVEYS_DETAIL: '',
            OPERATIVE_PERMISSION_VIEW_CONTACTS_DETAIL: 'Die Befähigung, ... von einem Gebäudekontakt einzusehen',
            OPERATIVE_PERMISSION_P2P: 'P2P',
            OPERATIVE_PERMISSION_P2P_DETAIL: '',
            OPERATIVE_PERMISSION_ASSET_USER_IMPORTS_DETAIL: 'Assets importieren',
            OPERATIVE_PERMISSION_TASK_USER_IMPORTS_DETAIL: 'Aufgaben importieren',
            OPERATIVE_PERMISSION_ASSET_TASK_USER_IMPORTS_DETAIL: 'Die Befähigung, Aufgaben und Assets zu importieren',
            OPERATIVE_PERMISSION_REASSIGN_JOB_OPERATIVE_DETAIL: 'Die Befähigung, einen Auftragsarbeiter neu zuzuweisen',
            OPERATIVE_PERMISSION_JOB_OPERATIVE: 'Arbeiter anzeigen',
            OPERATIVE_PERMISSION_JOB_OPERATIVE_DETAIL: 'Befähigung, einen Arbeiter am Arbeitsplatz auszuwählen',
            OPERATIVE_PERMISSION_BULK_REASSIGN_OPERATIVE_JOBS: 'Auftragsarbeiter massenhaft neu zuweisen',
            OPERATIVE_PERMISSION_BULK_REASSIGN_OPERATIVE_JOBS_DETAIL: 'Befähigung zur Massenneuzuweisung von Auftragsarbeitern.',
            OPERATIVE_PERMISSION_BULK_CANCEL_JOBS: 'Aufträge massenweise stornieren',
            OPERATIVE_PERMISSION_BULK_CANCEL_JOBS_DETAIL: 'Die Befähigung, Aufträge massenweise zu stornieren.',
            OPERATIVE_PERMISSION_EDIT_METER_REFERENCES: 'Zählerreferenzen bearbeiten',
            OPERATIVE_PERMISSION_NOTIFY_UPDATES: 'Auftrags-/Angebotskontakte benachrichtigen',
            OPERATIVE_PERMISSION_CAN_SCORE_ASSETS: 'Kann Assets erzielen',
            OPERATIVE_PERMISSION_CAN_SCORE_ASSETS_DETAIL: 'Die Befähigung, bei Abschluss des Auftrags ein Asset zu erzielen.',
            OPERATIVE_PERMISSION_EDIT_SCORE_TYPES_ON_ASSET: 'Ergebnistypen im Asset bearbeiten',
            OPERATIVE_PERMISSION_EDIT_SCORE_TYPES_ON_ASSET_DETAIL: 'Ergebnistypen beim Bearbeiten/Erstellen von Assets bearbeiten',
            OPERATIVE_PERMISSION_OPEN_CLOSE: 'Aufzeichnungen über Geschäfte öffnen/schließen erstellen',
            OPERATIVE_PERMISSION_OPEN_CLOSE_DETAIL: 'Die Befähigung, Aufzeichnungen über Geschäfte öffnen/schließen erstellen',
            OPERATIVE_PERMISSION_VIEW_LABOUR_LINES: 'Arbeitszeilen Auftrag anzeigen',
            OPERATIVE_PERMISSION_EDIT_LABOUR_LINES: 'Arbeitszeilen Auftrag bearbeiten',
            OPERATIVE_PERMISSION_CREATE_LABOUR_LINES: 'Arbeitszeilen Auftrag erstellen',
            OPERATIVE_PERMISSION_VIEW_LABOUR_LINES_DETAILS: 'Arbeitszeilen Auftrag anzeigen',
            OPERATIVE_PERMISSION_EDIT_LABOUR_LINES_DETAILS: 'Arbeitszeilen Auftrag bearbeiten',
            OPERATIVE_PERMISSION_CREATE_LABOUR_LINES_DETAILS: 'Arbeitszeilen Auftrag erstellen',
            OPERATIVE_PERMISSION_CREATE_SPILLAGES_FOR_OTHERS: 'Verschüttungen für andere berichten',
            OPERATIVE_PERMISSION_CREATE_SPILLAGES_FOR_OTHERS_DETAIL: 'Die Befähigung, Verschüttungen zu berichten und andere Dienstleister zu beauftragen',
            OPERATIVE_PERMISSION_RECEIVE_SPILLAGES: 'Verschüttungen empfangen',
            OPERATIVE_PERMISSION_RECEIVE_SPILLAGES_DETAIL: 'Arbeiter kann Verschüttungen empfangen',
            OPERATIVE_PERMISSION_CREATE_SPILLAGES: 'Verschüttungen erstellen',
            OPERATIVE_PERMISSION_CREATE_SPILLAGES_DETAIL: 'Die Befähigung, Verschüttungen zu erstellen',
            OPERATIVE_PERMISSION_EDIT_SPILLAGES: 'Berichtete Verschüttungen bearbeiten',
            OPERATIVE_PERMISSION_EDIT_SPILLAGES_DETAIL: 'Die Befähigung, berichtete Verschüttungen zu bearbeiten',
            OPERATIVE_PERMISSION_VIEW_SPILLAGES: 'Verschüttungen anzeigen',
            OPERATIVE_PERMISSION_VIEW_SPILLAGES_DETAIL: 'Die Befähigung, Verschüttungen anzuzeigen',
            OPERATIVE_PERMISSION_CREATE_SURVEY_SCHEDULES: 'Umfragezeitpläne erstellen',
            OPERATIVE_PERMISSION_CREATE_SURVEY_SCHEDULES_DETAILS: 'Die Befähigung, KPI-Umfragezeitpläne zu erstellen',
            OPERATIVE_PERMISSION_EDIT_SURVEY_SCHEDULES: 'Umfragezeitpläne bearbeiten',
            OPERATIVE_PERMISSION_EDIT_SURVEY_SCHEDULES_DETAILS: 'Die Befähigung, KPI-Umfragezeitpläne zu bearbeiten',
            OPERATIVE_PERMISSIONS_UPDATED: 'Arbeiter-Berechtigungen aktualisiert',
            OPERATIVE_PERMISSION_REJECT_INSIST_JOBS_DETAIL: 'Die Befähigung, einen Auftrag für einen gemeinsamen Dienstleister abzulehnen oder darauf zu bestehen',
            OPERATIVE_PERMISSION_VIEW_WASTE_ENTRIES: 'Abfalleinträge anzeigen',
            OPERATIVE_PERMISSION_VIEW_WASTE_ENTRIES_DETAIL: 'Die Befähigung, Abfalleinträge anzuzeigen',
            OPERATIVE_PERMISSION_CREATE_WASTE_ENTRIES: 'Abfalleinträge erstellen',
            OPERATIVE_PERMISSION_CREATE_WASTE_ENTRIES_DETAIL: 'Die Befähigung, Abfalleinträge zu erstellen',
            OPERATIVE_PERMISSION_EDIT_WASTE_ENTRIES: 'Abfalleinträge bearbeiten',
            OPERATIVE_PERMISSION_EDIT_WASTE_ENTRIES_DETAIL: 'Die Befähigung, Abfalleinträge zu bearbeiten',
            OPERATIVE_PERMISSION_IMPORT_WASTE_ENTRIES: 'Abfalleinträge importieren',
            OPERATIVE_PERMISSION_IMPORT_WASTE_ENTRIES_DETAIL: 'Die Befähigung, Abfalleinträge zu importieren',
            OPERATIVE_PERMISSION_VIEW_LINK_JOBS_DETAIL: 'Die Befähigung, Aufträge zu verlinken',
            OPERATIVE_PERMISSION_REMOVE_LINK_JOBS_DETAIL: 'Die Befähigung, verlinkte Aufträge zu entfernen',
            OPERATIVE_PERMISSION_WAIVE_MANAGEMENT_FEE: 'Auf Verwaltungsgebühr verzichten',
            OPERATIVE_PERMISSION_WAIVE_MANAGEMENT_FEE_DETAIL: 'Befähigung, auf die Verwaltungsgebühr zu verzichten',
            MISSED_PATROL_REASONS: 'Gründe für versäumte Patrouillen',
            MISSED_PATROL_REASONS_LIST: 'Gründe für versäumte Patrouillen',
            MISSED_PATROL_REASONS_ADD: 'Neuen Grund hinzufügen',
            MISSED_PATROL_REASONS_EDIT: 'Grund bearbeiten',
            MISSED_PATROL_REASON_ADDED: 'Gründe für versäumte Patrouillen hinzugefügt',
            MISSED_PATROL_REASON_UPDATED: 'Gründe für versäumte Patrouillen aktualisiert',
            MISSED_PATROL_REASON_DELETED: 'Gründe für versäumte Patrouillen gelöscht',
            METER: 'Zähler',
            METERS: 'Zähler',
            METERS_LIST: 'Zähler',
            METERS_ADD: 'Hinzufügen',
            METER_ADD: 'Einen neuen Zähler hinzufügen',
            METER_EDIT: 'Meter bearbeiten',
            METER_DELETED: 'Meter gelöscht',
            UTILITY_PROVIDER: 'Versorgungsanbieter',
            MANUFACTURER: 'Hersteller',
            SUPPLY_TYPE: 'Versorgungstyp',
            OCCUPANCY: 'Belegung',
            AUTOMATIC_READINGS: 'Automatisch gelesen',
            ACCOUNT_NUMBER: 'Kontonummer',
            MULTIPLIER: 'Multiplikator',
            DUAL_RATED: 'Zweifach bemessen',
            SERIAL_NUMBER: 'Seriennummer',
            ADD_NEW_READING: 'Neue Ablesung hinzufügen',
            ADD_NEW_SUBMETER: 'Neuen Nebenzähler hinzufügen',
            DATE_READ: 'Datum der Ablesung',
            READING: 'Ablesewert',
            READINGS: 'Ablesewerte',
            NIGHT_READINGS: 'Ablesewerte Nacht',
            NIGHT_READING: 'Ablesewert Nacht',
            DAY_READINGS: 'Ablesewerte Tag',
            DAY_READING: 'Ablesewert Tag',
            LAST_READING: 'Letzter Ablesewert',
            READ_BY: 'Abgelesen von',
            NO_READINGS: 'Keine Ablesewerte',
            NO_SUBMETERS: 'Keine Nebenzähler',
            SUBMETERS: 'Nebenzähler',
            HISTORY: 'Historie',
            DATE_READING_DUE: 'Ablesewert fällig',
            GOTOPARENT: 'Gehe zu Übergeordnet',
            METER_FREQUENCY: 'Frequenz',
            METER_MAX_VALUE: 'Höchstwert',
            OVER: 'Verlängert',
            CONSUMPTION: 'Verbrauch',
            TUR: 'TUR',
            SUBMETER: 'Nebenzähler',
            HIDE_SUBMETERS: 'Nebenzähler ausblenden',
            SHOW_SUBMETERS: 'Nebenzähler anzeigen',
            METERSUPPLIERS: 'Zählerlieferanten',
            METERMANUFACTURER: 'Zählerhersteller',
            METERTYPE: 'Zählertyp',
            METERTYPESUPPLY: 'Zählerversorgung',
            METERTYPEOCCUPANCY: 'Zählerbelegung',
            METER_MANUFACTURERS: 'HERSTELLER',
            METER_OCCUPANCYTYPES: 'Belegungstypen',
            METER_SUPPLIERS: 'Lieferanten',
            METER_SUPPLYTYPES: 'Versorgungstypen',
            METER_TYPES: 'Typen',
            METER_UNITS: 'Einheiten',
            EDIT_READING: 'Ablesewert bearbeiten',
            METER_ADDED: 'Zähler hinzugefügt',
            METER_READING_ADDED: 'Zählerablesung hinzugefügt',
            METER_READING_UPDATED: 'Zählerablesung aktualisiert',
            METER_READING_DELETED: 'Zählerablesung gelöscht',
            REFERENCE_TYPE: 'Referenztyp',
            IMAGES: 'Bilder',
            ERROR_READING_EXCEEDS_MAXVALUE: 'Der Ablesewert darf nicht höher sein als der eingestellte Höchstwert des Zählers',
            ERROR_READING_MUST_BE_GREATER: 'Der Ablesewert muss größer oder gleich dem vorherigen Ablesewert sein',
            ERROR_READING_MUST_BE_LOWER: 'Der Ablesewert muss kleiner oder gleich dem nächsten Ablesewert sein',
            ERROR_INVALID_MAX_VALUE: 'Der Höchstwert muss größer als Null sein',
            ERROR_MAX_VALUE_MUST_BE_HIGHER: 'Der Höchstwert sollte nicht niedriger als der Ablesewert sein',
            ERROR_NO_METER_EXISTS_FOR_SITE: 'Für das Gebäude existiert kein Zähler',
            ERROR_ROLLED_OVER_INCORRECT_FORMAT: 'Das Verlängerungsformat ist falsch',
            ERROR_FILE_IS_REQUIRED_ON_METER_READING: 'Die Datei ist für die Ablesung dieses Zählers erforderlich',
            ERROR_READ_AT_DATE_CANNNOT_BE_IN_FUTURE: 'Gelesen am kann nicht in der Zukunft liegen',
            ERROR_READING_INCORRECT_FORMAT: 'Das Ablesetyp-Format ist falsch',
            ERROR_READING_TYPE_FOR_DUAL_RATED_MUST_BE_EITHER_DAY_OR_NIGHT: 'Der Ablesetyp für Zähler mit zwei Nennleistungen muss entweder Tag oder Nacht sein',
            ERROR_MAX_VALUE_IS_REQUIRED: 'Höchstwert ist erforderlich',
            ERROR_READING_EXCEEDS_MAX_VALUE: 'Der Ablesewert überschreitet den Höchstwert',
            INSUFFICIENT_PERMISSIONS_CANNOT_UPDATE_REFERENCE: 'Unzureichende Berechtigungen, Referenz kann nicht aktualisiert werden.',
            UNABLE_TO_DELETE_METER_UNIT: 'Es ist nicht möglich, die Zählereinheit während des Gebrauchs zu löschen',
            UNABLE_TO_EDIT_METER_UNIT: 'Es ist nicht möglich, die Zählereinheit während des Gebrauchs zu bearbeiten. Nur der Status kann geändert werden',
            REQUIRE_FILE_ON_METER_READINGS: 'Datei über Zählerablesewerte anfordern',
            METER_EXPORT_READINGS: 'Ablesewerte exportieren',
            METER_FORCE_STICK_TO_DATE: 'Einhaltung des Datums forcieren',
            METER_GRACE_TYPE: 'Fristtyp Zähler',
            METER_GRACE_PERIOD_WEEK_BEFORE: 'Nachfristwoche - vorher',
            METER_GRACE_PERIOD_WEEK_AFTER: 'Nachfristwoche - nachher',
            METER_GRACE_PERIOD_MONTH_BEFORE: 'Nachfristmonat - vorher',
            METER_GRACE_PERIOD_MONTH_AFTER: 'Nachfristmonat - nachher',
            METER_GRACE_PERIOD_YEAR_BEFORE: 'Nachfristjahr - vorher',
            METER_GRACE_PERIOD_YEAR_AFTER: 'Nachfristjahr - nachher',
            METER_GRACE_PERIOD_WEEK_BEFORE_SUB_LABEL: 'Wie viele Tage früher kann der Benutzer einen Zählerablesewert hinzufügen, der wöchentlich fällig ist',
            METER_GRACE_PERIOD_WEEK_AFTER_SUB_LABEL: 'Wie viele Tage später kann der Benutzer einen Zählerablesewert hinzufügen, der wöchentlich fällig ist',
            METER_GRACE_PERIOD_MONTH_BEFORE_SUB_LABEL: 'Wie viele Tage früher kann der Benutzer einen Zählerablesewert hinzufügen, der monatlich fällig ist',
            METER_GRACE_PERIOD_MONTH_AFTER_SUB_LABEL: 'Wie viele Tage später kann der Benutzer einen Zählerablesewert hinzufügen, der monatlich fällig ist',
            METER_GRACE_PERIOD_YEAR_BEFORE_SUB_LABEL: 'Wie viele Tage früher kann der Benutzer einen Zählerablesewert hinzufügen, der jährlich fällig ist',
            METER_GRACE_PERIOD_YEAR_AFTER_SUB_LABEL: 'Wie viele Tage später kann der Benutzer einen Zählerablesewert hinzufügen, der jährlich fällig ist',
            METER_GENERATE_WEEK_DAY_SUB_LABEL: 'Der Wochentag, an dem der Zählerablesewert zur wöchentlichen Wiederholung fällig ist',
            METER_GENERATE_MONTH_DAY_SUB_LABEL: 'Der Tag des Monats, an dem der Zählerablesewert zur monatlichen Wiederholung fällig ist',
            METER_GENERATE_YEAR_MONTH_DAY_SUB_LABEL: 'Der Monat, an dem der Zählerablesewert zur jährlichen Wiederholung fällig ist',
            METER_GENERATE_YEAR_DAY_SUB_LABEL: 'Der Tag des Monats, an dem der Zählerablesewert zur jährlichen Wiederholung fällig ist',
            METER_GENERATE: 'Zähler erzeugen',
            METER_GENERATE_WEEK_DAY: 'Halten Sie sich an den Wochentag',
            METER_GENERATE_MONTH_DAY: 'Halten Sie sich an den Tag des Monats',
            METER_GENERATE_YEAR_DAY: 'Halten Sie sich an Jahr - Monat Tag',
            METER_GENERATE_YEAR_MONTH: 'Halten Sie sich an Jahr - Monat',
            METER_SETTINGS_VIEW: 'Zählereinstellungen',
            CANNOT_ENTER_FUTURE_DATE: 'Es kann kein zukünftiges Datum eingeben werden',
            UTILITY_CONSULTANT: 'Berater für Versorgungsunternehmen',
            READING_STATUS: 'Status Ablesewert',
            FUTURE_READING: 'Zukunft',
            METER_UNIT_INFORMATION: 'Informationen zur Zählereinheit',
            SYMBOL: 'Symbol Einheit',
            ADD_NEW_UNIT_TYPE: 'Einen neuen Einheitstyp hinzufügen',
            EDIT_METER_UNIT: 'Zählertyp bearbeiten',
            METER_UNIT: 'Zählereinheit',
            METER_UNIT_LIST: 'Zählereinheiten',
            METER_UNITS_LIST: 'Zählereinheiten',
            METER_UNIT_ADD: 'Eine neue Zählereinheit hinzufügen',
            METER_UNIT_EDIT: 'Zählereinheit bearbeiten',
            METER_UNIT_CREATED: 'Zählereinheit wurde erfolgreich erstellt',
            METER_UNIT_UPDATED: 'Zählereinheit wurde erfolgreich aktualisiert',
            METER_UNIT_DELETED: 'Zählereinheit erfolgreich gelöscht',
            METER_TYPE_INFORMATION: 'Information zum Zählertyp',
            ADD_NEW_METER_TYPE: 'Einen neuen Zählertyp hinzufügen',
            EDIT_METER_TYPE: 'Zählertyp bearbeiten',
            METER_TYPE_LIST: 'Zählertypen',
            METER_TYPES_LIST: 'Zählertypen',
            METER_TYPE_ADD: 'Einen neuen Zählertyp hinzufügen',
            METER_TYPE_EDIT: 'Zählertyp bearbeiten',
            METER_TYPE_CREATED: 'Zählertyp wurde erfolgreich erstellt',
            METER_TYPE_UPDATED: 'Zählertyp wurde erfolgreich aktualisiert',
            METER_TYPE_DELETED: 'Zählertyp wurde erfolgreich gelöscht',
            METER_TYPE_METER: 'Zähler',
            METER_TYPE_SUPPLY: 'Versorgungstyp',
            METER_TYPE_OCCUPANCY: 'Belegung',
            METER_SUPPLY_INFORMATION: 'Informationen zur Zählerversorgung',
            ADD_NEW_METER_SUPPLY: 'Eine neue Zählerversorgung hinzufügen',
            EDIT_METER_SUPPLY: 'Zählerversorgung bearbeiten',
            METER_SUPPLY_LIST: 'Zählerversorgungen',
            METER_SUPPLIES_LIST: 'Zählerversorgungen',
            METER_SUPPLY_ADD: 'Eine neue Zählerversorgung hinzufügen',
            METER_SUPPLY_EDIT: 'Zählerversorgung bearbeiten',
            METER_SUPPLY_CREATED: 'Zählerversorgung wurde erfolgreich erstellt',
            METER_SUPPLY_UPDATED: 'Zählerversorgung wurde erfolgreich aktualisiert',
            METER_SUPPLY_DELETED: 'Zählerversorgung wurde erfolgreich gelöscht',
            METER_SUPPLIER_INFORMATION: 'Informationen zu Zählerlieferant',
            ADD_NEW_METER_SUPPLIER: 'Einen neuen Zählerlieferanten hinzufügen',
            EDIT_METER_SUPPLIER: 'Zählerlieferant bearbeiten',
            METER_SUPPLIER_LIST: 'Zählerlieferanten',
            METER_SUPPLIER_ADD: 'Einen neuen Zählerlieferanten hinzufügen',
            METER_SUPPLIER_EDIT: 'Zählerlieferant bearbeiten',
            METER_SUPPLIER_CREATED: 'Zählerlieferant wurde erfolgreich erstellt',
            METER_SUPPLIER_UPDATED: 'Zählerlieferant wurde erfolgreich aktualisiert',
            METER_SUPPLIER_DELETED: 'Zählerlieferant wurde erfolgreich gelöscht',
            METER_OCCUPANCY_INFORMATION: 'Informationen zu Zählerbelegung',
            ADD_NEW_METER_OCCUPANCY: 'Eine neue Zählerbelegung hinzufügen',
            EDIT_METER_OCCUPANCY: 'Zählerbelegung bearbeiten',
            METER_OCCUPANCY_LIST: 'Zählerbelegungen',
            METER_OCCUPANCIES_LIST: 'Zählerbelegungen',
            METER_OCCUPANCY_ADD: 'Eine neue Zählerbelegung hinzufügen',
            METER_OCCUPANCY_EDIT: 'Zählerbelegung bearbeiten',
            METER_OCCUPANCY_CREATED: 'Zählerbelegung wurde erfolgreich erstellt',
            METER_OCCUPANCY_UPDATED: 'Zählerbelegung wurde erfolgreich aktualisiert',
            METER_OCCUPANCY_DELETED: 'Zählerbelegung wurde erfolgreich gelöscht',
            METER_OCCUPANCY_METER: 'Zähler',
            METER_OCCUPANCY_SUPPLY: 'Versorgung',
            METER_OCCUPANCY_OCCUPANCY: 'Belegung',
            METER_MANUFACTURER_INFORMATION: 'Informationen zu Zählerhersteller',
            ADD_NEW_METER_MANUFACTURER: 'Einen neuen Zählerhersteller hinzufügen',
            EDIT_METER_MANUFACTURER: 'Zählerhersteller bearbeiten',
            METER_MANUFACTURER_LIST: 'Zählerhersteller',
            METER_MANUFACTURER_ADD: 'Einen neuen Zählerhersteller hinzufügen',
            METER_MANUFACTURER_EDIT: 'Zählerhersteller bearbeiten',
            METER_MANUFACTURER_CREATED: 'Zählerhersteller erfolgreich erstellt',
            METER_MANUFACTURER_UPDATED: 'Zählerhersteller erfolgreich aktualisiert',
            METER_MANUFACTURER_DELETED: 'Zählerhersteller erfolgreich gelöscht',
            MESSAGE: 'Benachrichtigung',
            MESSAGES: 'Benachrichtigungen',
            UPDATE_ADD: 'Update hinzufügen',
            MESSAGE_VISIBILITY: 'Sichtbarkeit',
            MESSAGE_VISIBILITY_PUBLIC: 'Öffentlich',
            MESSAGE_VISIBILITY_HELPDESK: 'Helpdesk',
            MESSAGE_DIRECTION: 'Richtung',
            MESSAGE_DIRECTION_INBOUND: 'Eingehend',
            MESSAGE_DIRECTION_OUTBOUND: 'Ausgehend',
            MESSAGE_WRITE_NEW: 'Eine Benachrichtigung schreiben',
            MESSAGE_SEND: 'Senden',
            MASTERASSET: 'Vermögensübersicht',
            MASTER_TASK: 'Master-Aufgabe',
            MASTER_TASKS: 'Master-Aufgaben',
            MASTER_TASK_LIST: 'Master-Aufgaben',
            MASTER_TASK_CREATED: 'Master-Aufgaben erstellt',
            MASTER_TASK_UPDATED: 'Master-Aufgaben aktualisiert',
            MASTER_TASK_DELETED: 'Master-Aufgaben gelöscht',
            MASTER_TASK_ADD: 'Eine neue Master-Aufgabe hinzufügen',
            MASTER_TASK_EDIT: 'Master-Aufgabe bearbeiten',
            MASTER_TASK_IS_MANDATORY: 'Obligatorisch?',
            MASTER_TASK_SERVICE_TYPE: 'Diensttyp',
            MASTER_TASK_ASSET_NAME: 'Asset-Name',
            USERNAME: 'Benutzername',
            PASSWORD: 'Kennwort',
            SIGN_IN: 'Anmelden',
            PLEASE_LOGIN: 'Bitte anmelden',
            WELCOME_TO_ELOGBOOKS: 'Willkommen zu Elogs CAFM',
            INVALID_CREDENTIALS: 'Ungültige Zugangsdaten',
            FORGOT_YOUR_PASSWORD: 'Haben Sie Ihr Kennwort vergessen?',
            PASSWORD_REQUEST_SUBMITTED: 'Wenn ein passender Benutzer in unserem System existiert, erhalten Sie in Kürze eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Kennworts.',
            FORGOT_USERNAME_REQUEST_SUBMITTED: 'Wenn ein passender Benutzer in unserem System existiert, erhalten Sie in Kürze eine E-Mail mit Ihrem Benutzernamen.',
            CHOOSE_YOUR_NEW_PASSWORD: 'Wählen Sie Ihr neues Kennwort.',
            PASSWORDS_DO_NOT_MATCH: 'Die angegebenen Passwörter stimmen nicht überein',
            YOUR_PASSWORD_HAS_BEEN_CHANGED: 'Ihr Kennwort wurde geändert.',
            BACK_TO_LOGIN_PAGE: 'Gehen Sie zurück zur Anmeldeseite',
            SET_MY_NEW_PASSWORD: 'Mein neues Kennwort festlegen',
            CONFIRM_NEW_PASSWORD: 'Neues Kennwort bestätigen',
            USER_AUTH_TOO_MANY_ATTEMPTS: 'Konto gesperrt',
            USER_AUTH_BAD_CREDENTIALS: 'Ungültige Zugangsdaten',
            USER_AUTH_FAIL: 'Es gab ein Problem bei der Authentifizierung Ihres Kontos. Bitte versuchen Sie es erneut.',
            USER_AUTH_RECAPTCHA_REQUIRED: 'Das Captcha ist erforderlich.',
            USER_AUTH_RECAPTCHA_INVALID: 'Das Captcha ist ungültig.',
            USER_ACCOUNT_DISABLED: 'Dieses Konto ist deaktiviert.',
            LOCATION: 'Ort',
            LOCATIONS: 'Gebäude',
            LOCATION_ADD: 'Einen neuen Standort hinzufügen',
            LOCATION_EDIT: 'Standort bearbeiten',
            LOCATION_LIST: 'Gebäude',
            LOCATION_ENTER: 'Einen Standortnamen eingeben...',
            LOCATION_CREATED: 'Standort erstellt',
            LOCATION_UPDATED: 'Standort aktualisiert',
            LOCATION_DELETED: 'Standort gelöscht',
            LOCATION_NAME: 'Standortname',
            PARENT_LOCATION: 'Standort der Muttergesellschaft',
            NEW_LOCATION: 'Neuer Standort',
            LOCATION_ADD_PARENT: 'Einen übergeordneten Standort hinzufügen',
            LOCATION_ADD_CHILD: 'Einen untergeordneten Standort hinzufügen',
            LOCATION_NOTES: 'Standorthinweise',
            LOCATION_NOTE_UPDATED: 'Standorthinweis aktualisiert',
            LOCATION_NOTE_CREATED: 'Standorthinweis erstellt',
            LOCATION_LOCKED: 'Sie können den Standort zu diesem Zeitpunkt nicht aktualisieren',
            'en-gb': 'Englisch',
            fr: 'Französisch',
            es: 'Spanisch',
            nl: 'Niederländisch',
            'zh-hk': '繁體中文',
            GENERIC_JOB_VALUES: 'Auftragswerte',
            JOB: 'Auftrag',
            JOBS: 'Aufträge',
            JOB_LIST: 'Aufträge',
            JOB_REACTIVE_APPROVAL_REQUIRED: ' Auftragsgenehmigungen reaktivieren',
            JOB_VALUE_APPROVALS: 'Genehmigungen Auftragswert',
            LOG_A_NEW_JOB: 'Einen neuen Auftrag protokollieren',
            ADD_NEW_JOB: 'Einen neuen Auftrag hinzufügen',
            JOB_ADD: 'Einen Auftrag hinzufügen',
            ALL_JOBS: 'Aufträge',
            CREATED_AT_START: 'Erstellt in Start',
            CREATED_AT_END: 'Erstellt in Ende',
            ALARMED_AT_START: 'Alarmiert in Start',
            ALARMED_AT_END: 'Alarmiert in Ende',
            REMINDER_AT_START: 'Erinnerung in Start',
            REMINDER_AT_END: 'Erinnerung in Ende',
            ATTENDANCE_DUE_AT_START: 'Teilnahme fällig in Start',
            ATTENDANCE_DUE_AT_END: 'Teilnahme fällig in Ende',
            COMPLETION_DUE_AT_START: 'Beendigung fällig in Start',
            COMPLETION_DUE_AT_END: 'Beendigung fällig in Ende',
            LAST_SUBMITTED_AT: 'Zuletzt eingereicht in',
            LAST_SUBMITTED_AT_START: 'Zuletzt eingereicht in Start',
            LAST_SUBMITTED_AT_END: 'Zuletzt eingereicht in Ende',
            COMPLETED_AT_START: 'Abgeschlossen in Start',
            COMPLETED_AT_END: 'Abgeschlossen in Ende',
            JOB_ID: 'Auftrag #{{ ::id }}',
            ID_JOB: 'Auftrags-ID',
            JOB_DETAILS: 'Auftragsdetails',
            JOB_DETAILS_WITH_ID: 'Auftrag #{{ ::id }} Details',
            JOB_STATUS: 'Status',
            JOB_EVENTS: 'Ereignisse',
            JOB_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat den Auftrag erstellt',
            JOB_LINKED_TO_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat dem Link einen Auftrag hinzugefügt',
            JOB_LINKED_RELATE_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat einen verwandten Auftrags-Link hinzugefügt',
            JOB_LINKED_FROM_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat einen Auftrag von Link hinzugefügt',
            JOB_LINKED_FROM_QUOTE_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat einen Link von Auftrag zu Angebot hinzugefügt',
            JOB_LINKED_RELATES_QUOTE_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat einen Link von einem Auftrag im Zusammenhang mit einem Angebot hinzugefügt',
            JOB_MESSAGE_ACTION: '<strong>{{ ::message._links["sender"].title }}</strong> hat ein Update zur Verfügung gestellt.',
            JOB_ACTIONED_ACTION: 'Die Frist für die erforderliche Maßnahme wurde vorverlegt.',
            JOB_ALARM_UPDATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat den Auftrag aktualisiert.',
            JOB_APPROVAL_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat eine Genehmigungsanfrage gesendet.',
            JOB_APPROVAL_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat den Auftrag genehmigt',
            JOB_APPROVAL_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat die Auftragsgenehmigung abgelehnt.',
            JOB_APPROVAL_RESENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat eine Genehmigungsanfrage erneut gesendet an {{ ::event._links["approver"].title }}.',
            JOB_ASSIGNMENT_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat eine Zuordnungsanforderung gesendet an {{ ::event._links["operative"].title }}.',
            JOB_ASSIGNMENT_REASSIGNED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat den Auftrag neu zugewiesen.',
            JOB_ASSIGNMENT_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat den Auftrag angenommen',
            JOB_ASSIGNMENT_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat den Auftrag abgelehnt',
            JOB_ASSIGNMENT_RESENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat eine Zuordnungsanforderung erneut gesendet an {{ ::event._links["operative"].title }}.',
            JOB_ASSIGNMENT_INSISTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> besteht darauf, dass der Auftrag angenommen ist.',
            JOB_PROPOSAL_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat ein Angebot gesendet.',
            JOB_PROPOSAL_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat das Angebot angenommen.',
            JOB_PROPOSAL_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat das Angebot abgelehnt.',
            JOB_PROPOSAL_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat das Angebot storniert.',
            JOB_PROPOSAL_ACCEPTED_SYSTEM_ACTION: 'Das System hat den Erweiterungsantrag automatisch angenommen.',
            JOB_EXTENSION_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat einen Erweiterungsantrag gesendet.',
            JOB_EXTENSION_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat den Erweiterungsantrag akzeptiert.',
            JOB_EXTENSION_ACCEPTED_SYSTEM_ACTION: 'Das System hat den Erweiterungsantrag automatisch angenommen.',
            JOB_EXTENSION_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat den Erweiterungsantrag abgelehnt.',
            JOB_EXTENSION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat den Erweiterungsantrag abgebrochen.',
            JOB_OPERATION_ACKNOWLEDGED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat den Auftrag bestätigt.',
            JOB_OPERATION_COMMENCED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat den Auftrag begonnen.',
            JOB_OPERATION_COMPLETED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat den Auftrag abgeschlossen.',
            JOB_OPERATION_COMPLETED_PAPERWORK_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat dem Auftrag Unterlagen hinzugefügt.',
            JOB_POSTPONE_REMINDER_AT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat die Maßnahme erforderlich bis geändert in {{ ::event.reminderAt | date:\'short\' }}.',
            JOB_OPERATION_MISSED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat den Auftrag versäumt.',
            JOB_OPERATION_CHASED_ACTION: 'Eine Verfolgungsbenachrichtigung #{{ ::event.chaseSequence + 1 }} wurde gesendet an <strong>{{ ::event._links["user"].title }}</strong>.',
            JOB_OPERATION_REOPENED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat den Auftrag erneut geöffnet.',
            JOB_OPERATION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat den Auftrag storniert.',
            JOB_APPROVAL_ESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat die Auftragsgenehmigung eskaliert.',
            JOB_AUDIT_TRAIL_SENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat das Auditprotokoll gesendet.',
            JOB_ESCALATION_ADDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat den Auftrag eskaliert.',
            JOB_ESCALATION_REMOVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat die Eskalation abgebrochen.',
            JOB_RELATION_LINK_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat einen Link erstellt',
            JOB_RELATION_LINK_REMOVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat einen Link entfernt',
            QUOTE_RELATION_LINK_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat einen Link erstellt',
            QUOTE_RELATION_LINK_REMOVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat einen Link entfernt',
            JOB_VALUES_ADDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat dem Auftrag Werte hinzugefügt.',
            JOB_VALUES_APPROVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat die Auftragswerte genehmigt.',
            JOB_VALUES_APPROVED_SYSTEM_ACTION: 'Das System hat die Auftragswerte automatisch genehmigt.',
            JOB_VALUES_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat die Auftragswerte abgelehnt.',
            JOB_FOC_ADDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat den Auftrag als kostenfrei markiert.',
            JOB_ENTER_DETAILS_MANUALLY: 'Details manuell eingeben',
            JOB_CHOOSE_A_USER: 'Einen Benutzer auswählen',
            JOB_CHOOSE_A_TENANT: 'Einen Mieter auswählen',
            JOB_REPORTER_NAME: 'Name des Berichterstatters',
            JOB_CONTACT_NAME: 'Name der Kontaktperson',
            JOB_PHONE_NUMBER: 'Telefonnummer',
            JOB_RELATIONSHIP_TO_THE_PROPERTY: 'Beziehung zum Eigentum',
            JOB_NOTIFY_ON_CREATION: 'Benachrichtigen bei Erstellung',
            JOB_NOTIFY_ON_COMPLETION: 'Benachrichtigen bei Beendigung',
            JOB_NOTIFY_ON_STATUTORY_COMPLETION: 'Benachrichtigen bei Beendigung - Gesetzlich',
            JOB_SKIP_REQUEST_APPROVAL_NOTIFICATION: 'Genehmigungsbenachrichtigung überspringen',
            JOB_NEXT_STEP: 'NÄCHSTER SCHRITT',
            JOB_SAME_AS_CALLER: 'Wie Anrufer',
            JOB_SITE_CONTACT_MYSELF: 'Ich bin der Gebäudekontakt',
            JOB_SITE_CONTACT_AVAILABLE: 'Gebäudekontakt verfügbar',
            JOB_TO_MEET_THE_CONTRACTOR: 'um den Auftragnehmer zu treffen',
            JOB_KEYHOLDER_REQUIRED: 'Keyholder erforderlich',
            JOB_SITE_PRIORITY: 'Gebäudepriorität',
            JOB_STEP_DETAIL: 'Details',
            JOB_SERVICE_PROVIDER_PRIORITY: 'Priorität Dienstleister',
            JOB_SYSTEM_PRIORITY: 'Systempriorität',
            JOB_PHONE_NUMBERS: 'Telefonnummern',
            JOB_KEYHOLDER_DETAILS_PASS: 'Keyholder-Details weitergeleitet von:',
            BY_PHONE: 'Telefon',
            BY_MAIL: 'E-Mail',
            SYSTEM_MAIL: 'automatisch generierte Post',
            JOB_TYPE_REACTIVE: 'Reaktivieren',
            JOB_TYPE_PLANNED: 'Geplant',
            JOB_REPORTER: 'Berichterstatter',
            JOB_CONTACT: 'Auftragskontakt',
            JOB_ADD_SITE_DETAILS: 'Einen Gebäude auswählen',
            JOB_ADD_REPORTER_DETAILS: 'Details Berichterstatter eingeben',
            JOB_ADD_CONTACT_DETAILS: 'Gebäude-Kontaktdaten eingeben',
            JOB_ADD_DETAILS: 'Auftragsdetails eingeben',
            JOB_WORKFLOW_COMPLETE: 'Auftrag abschließen',
            JOB_WORKFLOW_COMPLETE_OPERATION: 'Auftrag abschließen',
            JOB_WORKFLOW_COMPLETE_OPERATION_SUBMIT: 'Auftrag abschließen',
            JOB_WORKFLOW_COMPLETE_PAPERWORK: 'Unterlagen vollständig',
            JOB_WORKFLOW_COMPLETE_PAPERWORK_OPERATION: 'Unterlagen vollständig',
            JOB_WORKFLOW_COMPLETE_PAPERWORK_OPERATION_SUBMIT: 'Unterlagen vollständig',
            JOB_STATUS_AWAITING_PAPERWORK: 'Warten auf Unterlagen',
            JOB_STATUS_PAPERWORK_REJECTED: 'Unterlagen abgelehnt',
            COMPLETE_PAPERWORK: 'Unterlagen vollständig',
            JOB_WORKFLOW_ACCEPT_ASSIGNMENT: 'Bestätigen',
            JOB_WORKFLOW_REQUEST_PROPOSAL: 'Neues SLA anfordern',
            JOB_WORKFLOW_INSIST_ASSIGNMENT: 'Insistieren',
            JOB_WORKFLOW_ACCEPT_APPROVAL: 'Genehmigen',
            JOB_WORKFLOW_ACCEPT_APPROVAL_SUBMIT: 'Genehmigen',
            JOB_WORKFLOW_REOPEN: 'Erneut eröffnen',
            JOB_WORKFLOW_ESCALATE_APPROVAL: 'Genehmigung eskalieren',
            JOB_WORKFLOW_ALARM: 'Alarm bearbeiten',
            JOB_WORKFLOW_ALARM_SUBMIT: 'Aktualisieren',
            JOB_WORKFLOW_ALARM_SUCCESS: 'Alarm aktualisiert',
            JOB_WORKFLOW_ALARM_SETNEW: 'Alarmeinstellung',
            JOB_WORKFLOW_ALARM_SETNEW_SUBMIT: 'Einstellen',
            JOB_WORKFLOW_ALARM_SETNEW_SUCCESS: 'Alarm eingestellt',
            JOB_WORKFLOW_REASSIGN_ASSIGNMENT: 'Zuordnung neu zuweisen',
            JOB_WORKFLOW_CHANGE_REQUIRED_BY_DATE_OPERATION: 'Maßnahme aufschieben',
            JOB_WORKFLOW_CHANGE_REQUIRED_BY_DATE_OPERATION_SUBMIT: 'Maßnahme aufschieben',
            JOB_WORKFLOW_CHANGE_REQUIRED_BY_DATE_OPERATION_SUCCESS: 'Maßnahme aufgeschoben',
            JOB_WORKFLOW_ACCEPT_PROPOSAL: 'Neues SLA akzeptieren',
            JOB_WORKFLOW_ACCEPT_PROPOSAL_SUBMIT: 'Bestätigen',
            PROPOSED: 'Vorgeschlagen',
            JOB_WORKFLOW_REJECT_PROPOSAL: 'Neues SLA ablehnen',
            JOB_WORKFLOW_REJECT_PROPOSAL_SUBMIT: 'Einreichen',
            JOB_WORKFLOW_CANCEL_PROPOSAL: 'Neues SLA stornieren',
            JOB_WORKFLOW_CANCEL_PROPOSAL_SUBMIT: 'Einreichen',
            JOBS_LIST: 'Aufträge',
            JOBS_ADD: 'Einen neuen Auftrag hinzufügen',
            JOBS_EDIT: 'Auftrag bearbeiten',
            JOB_TYPE: 'Auftragsart',
            JOB_STAT_TYPE: 'Gesetzestyp',
            JOB_SUMMARY: 'Auftragszusammenfassung',
            JOB_PRIORITY: 'Priorität',
            JOB_STATUS_ACKNOWLEDGED: 'Bestätigt',
            JOB_QUOTE_REQUEST_TO: 'Zu Angebot',
            JOB_QUOTE_REQUEST_WITH: 'Mit Angebot',
            JOB_QUOTE_REQUEST_FROM: 'Von Angebot',
            JOB_SENT_TO_QUOTE: 'Gesendet zu Angebot',
            JOB_RELATES_TO_QUOTE: 'Bezieht sich auf Angebot',
            JOB_STATUS_LEGEND: 'Legende Auftragsstatus',
            JOB_STATUS_CREATED: 'Erstellt',
            JOB_STATUS_APPROVAL_PENDING: 'Ausstehend zur Genehmigung',
            JOB_STATUS_APPROVAL_REJECTED: 'Genehmigung abgelehnt',
            JOB_STATUS_ASSIGNMENT_PENDING: 'Bestätigung ausstehend',
            JOB_STATUS_ASSIGNMENT_PROPOSAL: 'SLA-Angebot ausstehend',
            JOB_STATUS_ASSIGNMENT_REJECTED: 'Zuordnung abgelehnt',
            JOB_STATUS_OPERATIVE_ASSIGNMENT_REJECTED: 'Zuordnung Arbeiterauftrag abgelehnt',
            JOB_STATUS_OPERATION_ACKNOWLEDGED: 'Bestätigt',
            JOB_STATUS_COMMENCED: 'In Bearbeitung',
            JOB_STATUS_OPERATION_MISSED: 'Versäumt',
            JOB_STATUS_OPERATION_CANCELLED: 'Abgebrochen',
            JOB_STATUS_COMPLETED: 'Abgeschlossen',
            JOB_STATUS_OPERATION_AWAITING_PAPERWORK: 'Warten auf Unterlagen',
            JOB_WORKFLOW_COMPLETE_PAPERWORK_OPERATION_SUCCESS: 'Unterlagen angefügt',
            JOB_WORKFLOW_REASSIGN_OPERATIVE: 'Auftragsarbeiter neu zuweisen',
            JOB_STATUS_EXTENSION_PENDING: 'Erweiterungsantrag ausstehend',
            JOB_STATUS_EXTENSION_REJECTED: 'Erweiterungsantrag abgelehnt',
            JOB_STATUS_MISSED: 'Versäumt',
            RESEND_APPROVAL_REQUEST: 'Genehmigungsanfrage erneut senden',
            RESEND_ASSIGNMENT_REQUEST: 'Zuordnungsanforderung erneut senden',
            REASSIGN_OPERATIVE: 'Arbeiter neu zuweisen',
            SEND_TO_QUOTE: 'Zu Angebot senden',
            ESCALATE_TO_CLIENT: 'Zu Kunde eskalieren',
            EDIT_JOB: 'Auftrag bearbeiten',
            FROM_JOB: 'Von Auftrag',
            LINK_FROM_JOB: 'Von Auftrag',
            LINK_TO_JOB: 'Nach Auftrag',
            LINK_RELATES_TO_JOB: 'Bezieht sich auf Auftrag',
            FROM_QUOTE: 'Von Angebot erstellt',
            LINK_FROM_QUOTE: 'Von Angebot',
            LINK_TO_QUOTE: 'Zu Angebot',
            LINK_RELATES_TO_QUOTE: 'Bezieht sich auf Angebot',
            LINK_TYPE: 'Link-Typ',
            JOB_LINK_SEARCH: 'Suche Auftrags-Link',
            QUOTE_LINK_SEARCH: 'Suche Angebots-Link',
            JOB_LINKS: 'Auftrags-Links',
            QUOTE_LINKS: 'Angebots-Links',
            COMPLETION_DATE: 'Abschlussdatum',
            NEW_COMPLETION_DATE: 'Neues Abschlussdatum',
            ORIGINAL_COMPLETION_DATE: 'Ursprüngliches Abschlussdatum',
            REASSIGN_ASSIGNMENT: 'Zuordnung neu zuweisen',
            REASSIGN_ASSIGNMENT_SUBMIT: 'Zuordnung neu zuweisen',
            COMMENCE: 'Beginnen',
            COMPLETE: 'Abschließen',
            SKIP: 'Überspringen',
            ALARM: 'Alarm',
            CANCEL_JOB: 'Sind Sie sicher?',
            CANCEL_JOB_ADD: 'Möchten Sie wirklich diesen Vorgang durchführen?',
            APPROVE: 'Genehmigen',
            REJECT: 'Ablehnen',
            ACKNOWLEDGE: 'Bestätigen',
            REASSIGN: 'Neu zuweisen',
            CANCEL: 'Abbrechen',
            INSIST: 'Insistieren',
            REQUEST_PROPOSAL: 'Neues SLA anfordern',
            ACCEPT_PROPOSAL: 'Neues SLA akzeptieren',
            REJECT_PROPOSAL: 'Neues SLA ablehnen',
            CANCEL_PROPOSAL: 'Neues SLA stornieren',
            REQUEST_EXTENSION: 'Erweiterung anfordern',
            ACCEPT_EXTENSION: 'Erweiterungsanforderung akzeptieren',
            REJECT_EXTENSION: 'Erweiterungsanforderung ablehnen',
            CANCEL_EXTENSION: 'Erweiterungsanforderung abbrechen',
            CHANGE_ACTION_REQUIRED_BY: 'Maßnahme aufschieben',
            REOPEN: 'Erneut eröffnen',
            ESCALATE_APPROVAL: 'Genehmigung eskalieren',
            ACTION_REQUIRED: 'Maßnahme erforderlich von',
            JOB_REQUISITES: 'Auftragsanforderungen',
            REQUIREMENT_MET: 'Anforderungen erfüllt',
            JOB_REQUISITES_UPDATED: 'Auftragsanforderungen aktualisiert',
            JOB_CONTACT_ADD: 'Hinzufügen',
            JOB_DIALS_PLANNED_OVERDUE: 'Geplant überfällig',
            JOB_DIALS_REACTIVE_OVERDUE: 'Reaktivieren überfällig',
            JOB_DIALS_PLANNED_REQUIRED_ATTENTION: 'Unterlagen zur Überprüfung durch den Kunden',
            JOB_DIALS_REACTIVE_REQUIRED_ATTENTION: 'An den Kunden eskaliert',
            JOB_APPROVAL_RESENT: 'Auftragsgenehmigung erneut gesendet',
            JOB_ASSIGNMENT_RESENT: 'Auftragszuordnung erneut gesendet',
            JOB_AUDIT_TRAIL_SENT: 'Auditprotokoll gesendet',
            JOB_WITH_ID: 'Auftrag {{ id }}',
            JOBS_REACTIVE_OVERDUE_COMPLETION: 'Reaktive Aufträge Beendigung überfällig',
            JOBS_REACTIVE_OVERDUE_ATTENDANCE: 'Reaktive Aufträge Teilnahme überfällig',
            JOBS_PLANNED_OVERDUE_COMPLETION: 'Geplante Aufträge Beendigung überfällig',
            JOB_CURRENT_OPERATIVE: 'Aktueller Arbeiter',
            REASSIGN_TO: 'Neu zuweisen an',
            JOBS_REACTIVE_UPDATES_DUE: 'Updates Reaktive Aufträge überfällig',
            COMPLETE_TO_RAISE_ESCALATION: 'Das Ausfüllen dieses Feldes wird eine Eskalation mit dem FM auslösen.',
            CANNOT_COMPLETE_REQUIRES_FILE_WITH_STAT_TYPE_YES: 'Unterlagen können nicht ausgefüllt werden, erfordert eine Datei, deren gesetzlicher Status auf Ja gesetzt ist.',
            OVERDUE: 'Überfällig',
            OVERDUE_ATTENDANCE: 'Teilnahme überfällig',
            OVERDUE_COMPLETION: 'Beendigung überfällig',
            ATTEND_AT: 'Betreuen in',
            COMPLETE_AT: 'Abschließen in',
            JOB_SITE_CONTACT: 'Auftrag Gebäudekontakt',
            JOB_APPROVER: 'Auftrag Genehmigender',
            JOB_WORKFLOW_COMPLETE_OPERATION_SUCCESS: 'Auftrag abgeschlossen',
            JOB_WORKFLOW_REQUEST_EXTENSION: 'Erweiterung anfordern',
            JOB_WORKFLOW_REQUEST_EXTENSION_SUBMIT: 'Anfordern',
            JOB_WORKFLOW_REQUEST_EXTENSION_SUCCESS: 'Auftragserweiterung angefordert',
            JOB_WORKFLOW_ACCEPT_EXTENSION: 'Erweiterung akzeptieren',
            JOB_WORKFLOW_ACCEPT_EXTENSION_SUBMIT: 'Akzeptieren',
            JOB_WORKFLOW_ACCEPT_EXTENSION_SUCCESS: 'Auftragserweiterung angenommen',
            JOB_WORKFLOW_REJECT_EXTENSION: 'Erweiterung ablehnen',
            JOB_WORKFLOW_REJECT_EXTENSION_SUBMIT: 'Ablehnen',
            JOB_WORKFLOW_REJECT_EXTENSION_SUCCESS: 'Auftragserweiterung abgelehnt',
            JOB_WORKFLOW_CANCEL_OPERATION: 'Auftrag abbrechen',
            JOB_WORKFLOW_CANCEL_OPERATION_SUBMIT: 'Einreichen',
            JOB_WORKFLOW_CANCEL_OPERATION_SUCCESS: 'Auftrag aufgehoben',
            JOB_WORKFLOW_REOPEN_OPERATION: 'Erneut eröffnen',
            JOB_WORKFLOW_REOPEN_OPERATION_SUBMIT: 'Erneut eröffnen',
            JOB_WORKFLOW_REOPEN_OPERATION_SUCCESS: 'Auftrag erneut geöffnet',
            JOB_CREATED: 'Auftrag erstellt',
            NO_PLANNED_JOBS: 'Es sind keine Aufträge auf dieser Website geplant, bitte wählen Sie eine andere Priorität',
            JOB_PO_REQUIRED: 'Eine Auftragsnummer ist erforderlich, da die Website diese Option aktiviert hat',
            USER_IS_APPROVER_AND_TENANT: 'Benutzer ist der Genehmigender und Mieter',
            JOB_WORKFLOW_REJECT_APPROVAL: 'Genehmigung ablehnen',
            JOB_WORKFLOW_COMMENCE_OPERATION: 'Beginnen',
            JOB_STATUS_CANCELLED: 'Abgebrochen',
            JOB_WORKFLOW_CANCEL: 'Abbrechen',
            JOB_WORKFLOW_CHANGED_REQUIRED_BY_DATE: 'Maßnahme aufschieben',
            JOB_WORKFLOW_REJECT_ASSIGNMENT: 'Zuordnung ablehnen',
            CANCEL_OPERATION_SUCCESS: 'Auftrag aufgehoben',
            REASSIGN_ASSIGNMENT_SUCCESS: 'Auftrag neu zugewiesen',
            JOB_WORKFLOW_REASSIGN_ASSIGNMENT_SUCCESS: 'Auftrag neu zugewiesen',
            JOB_WORKFLOW_REASSIGN_OPERATIVE_SUCCESS: 'Arbeiter neu zugewiesen',
            REOPEN_OPERATION_SUCCESS: 'Auftrag erneut geöffnet',
            REJECT_APPROVAL_SUCCESS: 'Auftragsgenehmigung abgelehnt',
            ESCALATE_APPROVAL_SUCCESS: 'Auftragsgenehmigung eskaliert',
            JOB_WORKFLOW_ACCEPT_APPROVAL_SUCCESS: 'Auftrag genehmigt',
            REQUEST_PROPOSAL_SUCCESS: 'Neues SLA-Angebot angefordert',
            ACCEPT_PROPOSAL_SUCCESS: 'Neues SLA angenommen',
            REJECT_PROPOSAL_SUCCESS: 'Neues SLA abgelehnt',
            CANCEL_PROPOSAL_SUCCESS: 'Neues SLA storniert',
            INSIST_ASSIGNMENT_SUCCESS: 'Auf Auftrag bestanden',
            ACCEPT_ASSIGNMENT_SUCCESS: 'Auftrag zugewiesen',
            REJECT_ASSIGNMENT_SUCCESS: 'Auftrag abgelehnt',
            COMMENCE_OPERATION_SUCCESS: 'Auftrag begonnen',
            REQUEST_EXTENSION_SUCCESS: 'Auftrag erweitert',
            REJECT_EXTENSION_SUCCESS: 'Auftragserweiterung abgelehnt',
            ACCEPT_EXTENSION_SUCCESS: 'Auftragserweiterung angenommen',
            CANCEL_EXTENSION_SUCCESS: 'Auftragserweiterung storniert',
            JOB_WORKFLOW_REQUEST_PROPOSAL_SUBMIT: 'Anfordern',
            JOB_WORKFLOW_INSIST_ASSIGNMENT_SUBMIT: 'Insistieren',
            JOB_WORKFLOW_REJECT_APPROVAL_SUBMIT: 'Ablehnen',
            JOB_WORKFLOW_ESCALATE_APPROVAL_SUBMIT: 'Eskalieren',
            JOB_WORKFLOW_ACCEPT_ASSIGNMENT_SUBMIT: 'Akzeptieren',
            JOB_WORKFLOW_COMMENCE_OPERATION_SUBMIT: 'Beginnen',
            JOB_WORKFLOW_REJECT_ASSIGNMENT_SUBMIT: 'Ablehnen',
            JOB_WORKFLOW_REASSIGN_ASSIGNMENT_SUBMIT: 'Neu zuweisen',
            JOB_WORKFLOW_REASSIGN_OPERATIVE_SUBMIT: 'Neu zuweisen',
            ACTION_REQUIRED_BETWEEN: 'Maßnahme erforderlich zwischen',
            ATTENDANCE_DUE_AT_BETWEEN: 'Teilnahme fällig in Zwischen',
            COMPLETION_DUE_AT_BETWEEN: 'Beendigung fällig in Zwischen',
            PREVIOUS_OPERATIVE: 'Vorheriger Arbeiter',
            NEW_OPERATIVE: 'Neuer Arbeiter',
            JOB_NUMBER: 'Auftragsnummer',
            PLANNED: 'Geplant',
            REACTIVE: 'Reaktivieren',
            CANCEL_JOBS: 'Aufträge abbrechen',
            JOBS_SELECTED: 'Ausgewählte Aufträge',
            ARE_YOU_SURE_YOU_WOULD_LIKE_TO_CANCEL_THESE_JOBS: 'Sind Sie sicher, dass Sie diese Aufträge abbrechen möchten?',
            EMAIL_CANCELLATIONS_TO_ENGINEERS: 'E-Mail-Absagen an Ingenieure',
            JOBS_QUEUED_FOR_REASSIGNMENT: 'Zur Neuzuweisung anstehende Aufträge',
            JOBS_QUEUED_FOR_CANCELLATION: 'Zum Abbruch anstehende Aufträge',
            DATE_NOT_SOONER_THAN_1_HOUR: 'Sie können keinen Termin früher als 1 Stunde ab jetzt beantragen',
            ALL_FILTERED_JOBS: 'Alle gefilterten Aufträge',
            SELECT_SITE: 'Einen Gebäude auswählen',
            LAST_FIVE_JOBS: 'Letzte 5 Aufträge',
            STATUTORY: 'Gesetzlich',
            NOT_STATUTORY: 'Nicht gesetzlich',
            PO_NUMBER_INVALID_LENGTH: 'Auftragsnummer sollte nicht mehr als 40 Zeichen lang sein',
            JOBS_POINT_IN_TIME: 'Die Aufträge zu diesem Zeitpunkt entsprechen Ihren Suchkriterien',
            JOB_LINKS_JOB_ID: 'Auftrag #',
            JOB_LINKS_QUOTE_ID: 'Angebot #',
            JOB_LINK: 'Link',
            JOB_LINK_WITH: 'Mit',
            JOB_LINK_TO: 'An',
            JOB_LINK_FROM: 'Von',
            JOB_LINK_0: 'An',
            JOB_LINK_1: 'Von',
            JOB_LINK_2: 'Mit',
            JOB_EXTENSION_REQUEST_DATE_NOT_SOONER_THAN_SLA: 'Sie können kein Datum vor dem bereits vereinbarten SLA anfordern',
            JOB_LABOUR: 'Auftrag Arbeit',
            JOB_LABOUR_ADD: 'Auftrag Arbeit hinzufügen',
            JOB_LABOUR_DELETED: 'Auftrag gelöscht hinzufügen',
            JOB_LABOUR_EDIT: 'Auftrag Arbeit bearbeiten',
            JOB_LABOUR_UPDATED: 'Auftrag Arbeit aktualisiert',
            JOB_LABOUR_CREATED: 'Auftrag Arbeit erstellt',
            JOB_LABOUR_LIST: 'Auftrag Arbeiten',
            LABOUR_LINES: 'Arbeitszeilen',
            TIME_WORKED: 'Zeit Gearbeitet',
            TIME_TRAVELLED: 'Gereiste Zeit',
            COMPLETION_BEFORE_ATTENDANCE: 'Das Abschlussdatum darf nicht vor dem Teilnahmedatum liegen',
            JOBS_RATIO: 'Verhältnis Aufträge',
            LOCATION_REQUIRED_JOB: 'Standort erforderlich für Aufträge reaktivieren',
            NO_JOB_VISIBILITY_ERROR: 'Sie haben keine Berechtigung zum Anzeigen dieses Auftrags',
            AWAITING_PAPERWORK_SINCE: 'Warten auf Unterlagen seit (Stunden)',
            AWAITING_PAPERWORK: 'Warten auf Unterlagen',
            JOB_PAPERWORK_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat die Unterlagen abgelehnt.',
            JOB_VALUE: 'Wert',
            JOB_VALUE_ADD: 'Auftragswerte hinzufügen',
            JOB_VALUE_APPROVE: 'Auftragswerte genehmigen',
            JOB_VALUE_APPROVE_DESCRIPTION: 'Die Beschreibung, die auf der Rechnung verwendet werden soll',
            JOB_VALUE_REJECT: 'Auftragswerte ablehnen',
            JOB_VALUE_REJECT_DESCRIPTION: 'Der Grund für die Ablehnung dieser Werte',
            JOB_VALUE_LABOUR: 'Arbeitswert',
            JOB_VALUE_MATERIAL: 'Wert der Materialien',
            JOB_VALUE_LABOUR_WITH_VALUE: 'Arbeitswert {{ value | number:2 }}',
            JOB_VALUE_MATERIAL_WITH_VALUE: 'Wert der Materialien {{ value | number:2 }}',
            JOB_TOTAL_VALUE_WITH_VALUE: 'Gesamtwert {{ value | number:2 }}',
            JOB_VALUE_APPROVE_CREATED: 'Auftragswert genehmigt',
            JOB_VALUE_SEPARATE_INVOICE: 'Separate Rechnung',
            JOB_VALUE_INVOICE_NUMBER: 'Rechnungsnummer',
            JOB_VALUE_INVOICE_DATE: 'Rechnungsdatum',
            JOB_VALUE_PREVIOUS_VALUES: 'Vorherige Werte',
            JOB_TOTAL_VALUE: 'Gesamtwert',
            JOB_FOC_ADD: 'FoC (kostenfrei)',
            JOB_FOC: 'Als kostenfrei markieren',
            JOB_FOC_SUBMIT: 'Einreichen',
            JOB_FOC_CREATED: 'Auftrag als kostenfrei markiert',
            JOB_PRIMARY_OPERATIVE_APPROVAL: 'Genehmigung primärer Arbeiter',
            JOB_APPROVER_APPROVAL: 'Genehmigung Auftrag Genehmigender',
            JOB_PENDING_WITH_ME: 'Ausstehend bei mir',
            EXPLICIT_ACCESS: 'Ausdrücklicher Zugang',
            EXPLICIT_ACCESS_LIST: 'Ausdrücklicher Zugang',
            EXPLICIT_ACCESS_ADD: 'Zugang gewähren',
            EXPLICIT_ACCESS_EDIT: 'Bearbeiten',
            ACCESS_GRANTED: 'Zugang gewährt',
            ACCESS_REVOKED: 'Zugang widerrufen',
            DATE_NOT_IN_RANGE: 'Datum nicht im zulässigen Bereich',
            ORIGINAL_VALUES: 'Ursprüngliche Werte',
            MARKEDUP_VALUES: 'Ausgezeichnete Werte',
            JOBS_DUE_BY: 'Fällig am',
            JOBS_DUE_BY_TYPE: 'Typ',
            JOBS_DUE_BY_ATTENDANCE: 'Teilnahme',
            JOBS_DUE_BY_COMPLETION: 'Beendigung',
            JOBS_DUE_BY_EITHER: 'Entweder',
            JOBS_DUE_BY_FREQUENCY: 'Frequenz',
            JOBS_DUE_BY_DAYS: 'Tag',
            JOBS_DUE_BY_WEEKS: 'Woche',
            JOBS_DUE_BY_MONTHS: 'Monat',
            JOBS_DUE_BY_INTERVAL: 'Intervall',
            WAIVE_MANAGEMENT_FEE: 'Auf Verwaltungsgebühr verzichten',
            WAIVE_MANAGEMENT_FEE_SUBMIT: 'Einreichen',
            JOB_WAIVE_MANAGEMENT_FEE: 'Auf Verwaltungsgebühr verzichtet',
            JOB_WAIVE_MANAGEMENT_FEE_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat auf die Verwaltungsgebühr verzichtet.',
            UPHOLD_MANAGEMENT_FEE: 'Verwaltungsgebühr aufrechterhalten',
            UPHOLD_MANAGEMENT_FEE_SUBMIT: 'Einreichen',
            JOB_UPHOLD_MANAGEMENT_FEE: 'Verwaltungsgebühr aufrechterhalten',
            JOB_UPHOLD_MANAGEMENT_FEE_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat die Verwaltungsgebühr aufrechterhalten.',
            JOB_WORKFLOW_ESCALATE: 'Eskalieren',
            JOB_WORKFLOW_ESCALATE_OPERATION: 'Eskalieren',
            JOB_WORKFLOW_ESCALATE_OPERATION_SUBMIT: 'Eskalieren',
            JOB_WORKFLOW_COMMENCE: 'Auftrag beginnen',
            JOB_WORKFLOW_CANCEL_EXTENSION: 'Erweiterung abbrechen',
            JOB_WORKFLOW_CANCEL_EXTENSION_SUBMIT: 'Erweiterung abbrechen',
            JOB_WORKFLOW_CANCEL_EXTENSION_SUCCESS: 'Auftragserweiterung storniert',
            JOB_WORKFLOW_CANCEL_ASSIGNMENT: 'Zuordnung abbrechen',
            JOB_WORKFLOW_SKIP: 'Überspringen',
            JOB_WORKFLOW_SKIP_OPERATION: 'Überspringen',
            JOB_WORKFLOW_SKIP_OPERATION_SUBMIT: 'Einreichen',
            JOB_WORKFLOW_EDIT_JOB: 'Auftrag bearbeiten',
            JOB_WORKFLOW_EDIT_JOB_SUBMIT: 'Speichern',
            JOB_WORKFLOW_EDIT_JOB_SUCCESS: 'Auftrag bearbeitet',
            JOB_WORKFLOW_P2P: 'P2P',
            NEEDS_ONE_STATUTORY_FILE: 'Mindestens eine Datei sollte Gesetzestyp sein',
            NO_FUTURE_JOBS: 'Keine zukünftigen Aufträge',
            INVALID_OPERATIVE_SELECTED: 'Ausgewählter Arbeiter ist ungültig',
            SELECTED_OPERATIVE_DOESNT_EXIST: 'Ausgewählter Arbeiter existiert nicht',
            OPERATIVE_REQUIRED_NO_PRIMARY_SET: 'Arbeiter erforderlich, da kein primärer Arbeiter festgelegt ist',
            ONE_HOUR: 'Sie können keinen Termin später als 1 Stunde ab jetzt beantragen',
            SERVICE_PROVIDER_NOT_CONTRACTOR_APPROVED: 'Dienstleister nicht als Auftragnehmer zugelassen',
            COMPLETION_DATE_CANNOT_BE_BEFORE_DATE_ATTENDED: 'Das Abschlussdatum kann nicht vor dem Datum der Teilnahme liegen',
            JOB_APPROVAL_REQUESTED: 'Auftragsgenehmigung angefordert',
            JOB_ASSIGNMENT_ACCEPTED: 'Auftragszuordnung akzeptiert',
            JOB_ASSIGNMENT_REJECTED: 'Auftragszuordnung abgelehnt',
            JOB_EXTENSION_CANCELLED: 'Auftragserweiterung storniert',
            JOB_EXTENSION_REQUESTED: 'Auftragserweiterung angefordert',
            JOB_OPERATION_ACKNOWLEDGED: 'Auftrag bestätigt',
            JOB_OPERATION_COMMENCED: 'Auftrag begonnen',
            JOB_OPERATION_COMPLETED: 'Auftrag abgeschlossen',
            JOB_OPERATION_COMPLETED_PAPERWORK: 'Auftragsunterlagen abgeschlossen',
            JOB_OPERATION_MISSED: 'Auftrag übersprungen',
            JOB_OPERATION_REASSIGNED: 'Auftrag neu zugewiesen',
            JOB_PROPOSAL_CANCELLED: 'Neues Angebot Auftrags-SLA storniert',
            JOB_PROPOSAL_REQUESTED: 'Neue Auftrags-SLA vorgeschlagen',
            JOB_MESSAGE: 'Auftrags-Updates hinzugefügt',
            ADD_NEW_JOB_REQUISITES: 'Eine neue Auftragsanforderung hinzufügen',
            ADD_PARTNERSHIP: 'Partnerschaft hinzufügen',
            ANSWERED_NA: 'hat \'n.z.\' geantwortet',
            ANSWERED_YES: 'hat \'Ja\' geantwortet',
            ATTACHED_FILES: 'Angehängte Datei(en)',
            DAILOG_PARTNERSHIP_EDIT: 'Partnerschaften bearbeiten',
            DELETE_PARTNERSHIP: 'Partnerschaft löschen',
            DELETE_SELECTED: 'Auswahl löschen',
            DISALLOW_NOT_APPLICABLE: 'N/A nicht zulassen',
            EDIT_JOB_REQUISITE: 'Auftragsanforderung bearbeiten',
            HASNOTAPPLICABLEOPTION: 'Option N/A zulassen',
            HELPMESSAGE: 'Hilfetext',
            JOB_REQUISITE: 'Auftragsanforderung',
            JOB_REQUISITE_ADD: 'Eine neue Auftragsanforderung hinzufügen',
            JOB_REQUISITE_CREATED: 'Auftragsanforderung erstellt',
            JOB_REQUISITE_DELETED: 'Auftragsanforderung gelöscht',
            JOB_REQUISITE_EDIT: 'Auftragsanforderung bearbeiten',
            JOB_REQUISITE_LIST: 'Auftragsanforderungen',
            JOB_REQUISITE_PARTNERSHIP_ADDED: 'Auftragsanforderung Partnerschaft hinzugefügt',
            JOB_REQUISITE_PARTNERSHIP_EDIT: 'Auftragsanforderung Partnerschaft bearbeiten',
            JOB_REQUISITE_PARTNERSHIP_LIST: 'Partnerschaften',
            JOB_REQUISITE_SERVICE_PROVIDER_LIST: 'Dienstleister',
            JOB_REQUISITE_SERVICEPROVIDER_ADDED: 'Auftragsanforderung Dienstleister hinzugefügt',
            JOB_REQUISITE_SERVICEPROVIDER_EDIT: 'Auftragsanforderung Dienstleister bearbeiten',
            JOB_REQUISITE_SITE_ADDED: 'Auftragsanforderung Gebäude hinzugefügt',
            JOB_REQUISITE_SITE_EDIT: 'Auftragsanforderung Gebäude bearbeiten',
            JOB_REQUISITE_SITE_LIST: 'Gebäude',
            JOB_REQUISITE_UPDATED: 'Auftragsanforderungen aktualisiert',
            JOB_REQUISITE_PENDING_APPROVAL: 'Auftragsanforderungen zur Genehmigung ausstehend',
            JOB_REQUISITEUPDATED: 'Auftragsanforderungen aktualisiert',
            NO_ATTACHMENT: 'Kein Anhang',
            PARTNERSHIPS_DELETED_FROM_JOB_REQUISITE: 'Auftragsanforderung Partnerschaften gelöscht',
            REQUEST_ATTACHMENT: 'Anhang anfordern',
            REQUESTATTACHMENT: 'Anhang anfordern',
            SERVICEPROVIDERS_DELETED_FROM_JOB_REQUISITE: 'Auftragsanforderung Dienstleister gelöscht',
            SITES_DELETED_FROM_JOB_REQUISITE: 'Auftragsanforderung Gebäude gelöscht',
            SP_APPROVED: 'SP genehmigt',
            APPROVER_APPROVED: 'Genehmigender genehmigt',
            JOBREQUISITE: 'Auftragsanforderung',
            JOB_REQUISITE_ANSWERED: '<strong>{{ jobRequisiteAnswer._links.user.title }}</strong> {{ jobRequisiteAnswer.answer | translate }} an {{ jobRequisiteAnswer._links.jobrequisite.title }}',
            JOB_REQUISITE_YET_TO_ANSWER: '<strong>{{ jobRequisiteAnswer._links.user.title }}</strong> muss {{ jobRequisiteAnswer._links.jobrequisite.title }} noch antworten',
            JOB_REQUISITE_ANSWER_APPROVE: 'Antwort Auftragsanforderung genehmigen',
            JOB_REQUISITE_ANSWER_APPROVE_SUBMIT: 'Genehmigen',
            JOB_REQUISITE_ANSWER_APPROVE_SUCCESS: 'Antwort Auftragsanforderung genehmigt',
            JOB_REQUISITE_ANSWER_REJECT: 'Antwort Auftragsanforderung ablehnen',
            JOB_REQUISITE_ANSWER_REJECT_SUBMIT: 'Ablehnen',
            JOB_REQUISITE_ANSWER_REJECT_SUCCESS: 'Antwort Auftragsanforderung abgelehnt',
            JOB_REQUISITE_ESCALATE: 'Auftragsanforderung eskalieren',
            JOB_REQUISITE_ANSWER_ESCALATE: 'Antwort Auftragsanforderung eskalieren',
            JOB_REQUISITE_ANSWER_ESCALATE_SUBMIT: 'Eskalieren',
            JOB_REQUISITE_ANSWER_ESCALATE_SUCCESS: 'Auftragsanforderung wurde eskaliert',
            REQUISITES: 'Anforderungen',
            OPT_IN: 'Opt-In',
            OPT_OUT: 'Opt-Out',
            APPROVAL_STRATEGY: 'Genehmigungsstrategie',
            NO_APPROVAL_REQUIRED: 'Keine Genehmigung erforderlich',
            APPROVER_APPROVAL_REQUIRED: 'Genehmigung des Genehmigenden erforderlich',
            PRIMARY_AND_APPROVER_APPROVAL_REQUIRED: 'Genehmigung des primären Arbeiters und Genehmigenden erforderlich',
            FORM: 'Formular',
            FORM_SUMMARY: 'Formularzusammenfassung',
            FORM_STATUS: 'Formularstatus',
            BACK_TO_JOB_REQUISITES: 'Zurück zu Auftragsanforderungen',
            VIEW_FORM: 'Formular anzeigen',
            HAS_JOB_REQUISITE: 'Hat Auftragsanforderung',
            PRIMARY_OPERATIVE: 'Primärer Arbeiter',
            OPERATIVE_TYPE: 'Arbeitertyp',
            HEALTH_AND_SAFETY: 'Gesundheit und Sicherheit',
            ALLOW_ESCALATION: 'Eskalation zu Genehmigendem zulassen',
            QUOTED_WORKS: 'Angebotene Arbeiten',
            PLANNED_JOB: 'Geplanter Auftrag',
            REACTIVE_JOB: 'Reaktiver Auftrag',
            JOB_GROUPS: 'Auftragsgruppen',
            ADD_NEW_JOB_GROUP: 'Eine neue Auftragsgruppe hinzufügen',
            EDIT_JOB_GROUP: 'Auftragsgruppe bearbeiten',
            JOB_GROUP_LIST: 'Auftragsgruppen',
            JOB_GROUP_ADD: 'Eine neue Auftragsgruppe hinzufügen',
            JOB_GROUP_EDIT: 'Auftragsgruppe bearbeiten',
            JOB_GROUP_CREATED: 'Auftragsgruppe erstellt',
            JOB_GROUP_UPDATED: 'Auftragsgruppe aktualisiert',
            JOB_GROUP_DELETED: 'Auftragsgruppe gelöscht',
            JOB_GROUP_ADDED: 'Auftragsgruppe hinzugefügt',
            JOB_GROUP_ENTER: 'Einen Auftragsgruppennamen eingeben...',
            JOB_ESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat den Auftrag eskaliert.',
            JOB_ESCALATED_SYSTEM_ACTION: 'Das System hat den Auftrag automatisch eskaliert.',
            JOB_ESCALATION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat die Eskalation abgebrochen.',
            JOB_DEESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat den Auftrag deeskaliert.',
            JOB_DEESCALATED_SYSTEM_ACTION: 'Das System hat die Eskalation automatisch abgebrochen.',
            JOB_REQUISITE_ANSWER_APPROVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat eine Antwort Auftragsanforderung genehmigt.',
            JOB_REQUISITE_ANSWER_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat eine Antwort Auftragsanforderung abgelehnt.',
            JOB_REQUISITE_ANSWERED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat eine Auftragsanforderung beantwortet.',
            JOB_REQUISITE_ANSWER_ESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat eine Auftragsanforderung eskaliert.',
            REPORTER_EMAIL: 'E-Mail Berichterstatter',
            REPORTER_PHONE: 'Telefon Berichterstatter',
            REPORTER_RELATIONSHIP: 'Beziehung Berichterstatter',
            REPORTER_NOTIFY_ON_CREATE: 'Berichterstatter bei Erstellung benachrichtigen',
            REPORTER_NOTIFY_ON_COMPLETE: 'Berichterstatter bei Beendigung benachrichtigen',
            CONTACT_NAME: 'Name der Kontaktperson',
            CONTACT_EMAIL: 'E-Mail der Kontaktperson',
            CONTACT_PHONE: 'Telefonnummer der Kontaktperson',
            CONTACT_RELATIONSHIP: 'Beziehung der Kontaktperson',
            CONTACT_NOTIFY_ON_CREATE: 'Kontakt bei Erstellung benachrichtigen',
            CONTACT_NOTIFY_ON_COMPLETE: 'Kontakt bei Beendigung benachrichtigen',
            JOB_CONTACTS: 'Auftragskontakte',
            JOB_CONTACT_LIST: 'Auftragskontakte',
            JOB_CONTACT_CREATED: 'Auftragskontakt erstellt',
            JOB_CONTACT_UPDATED: 'Auftragskontakt aktualisiert',
            JOB_CONTACT_DELETED: 'Auftragskontakt gelöscht',
            JOB_EDITED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat den Auftrag bearbeitet.',
            JOB_OPERATIVE_REASSIGNED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat Arbeiter neu zugewiesen.',
            JOB_SLAUPDATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat den Auftrag aktualisiert.',
            JOB_EXPLICIT_ACCESS_GRANTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat Zugang zu Auftrag an {{ ::event._links["privileged-user"].title }} gewährt.',
            JOB_EXPLICIT_ACCESS_REVOKED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat Zugang zu Auftrag {{ ::event._links["privileged-user"].title }} widerrufen.',
            JOB_EXPLICIT_ACCESS_EXPIRED_ACTION: 'Zugang auf den Auftrag abgelaufen für {{ ::event._links["privileged-user"].title }}.',
            MERIDIAN_DOC_TYPE: 'Meridian-Dokumenttyp',
            RISKWISE_DOC_TYPE: 'RiskWise-Dokumenttyp',
            QCOMPLIANCE_DOC_TYPE: 'Q Art des Konformitätsdokuments',
            INSPECTIONDATE: 'Inspektionsdatum',
            NEXTINSPECTIONDATE: 'Nächstes Inspektionsdatum',
            UPLOAD_TO_RISKWISE: 'Senden an RiskWise',
            RISKWISE_UPLOAD_ADD: 'Senden an RiskWise',
            IMPORT: 'Import',
            IMPORT_TYPE: 'Typ',
            IMPORTS_LIST: 'Importe',
            IMPORT_DETAILS_LIST: 'Details',
            IMPORT_IS_TEST_RUN: 'Test ausgeführt?',
            IMPORT_STARTED: 'Gestartet bei',
            IMPORT_ENDED: 'Beendet in',
            IMPORT_TOTAL_ROWS: 'Gesamt',
            IMPORT_VALID_ROWS: 'Gültig',
            IMPORT_INVALID_ROWS: 'Ungültig',
            IMPORT_TEST_RUN: 'Debug',
            IMPORTS: 'Importe',
            IMPORT_CSV_FILE: 'Datei (csv) importieren',
            IMPORT_FILE: 'Datei importieren',
            EXTENSION_XML: '.xml',
            EXTENSION_CSV: '.csv',
            IMPORT_ERRORS: 'Fehler',
            IMPORT_ROW_NUMBER: 'Zeile',
            IMPORT_COLUMN_NAME: 'Key',
            IMPORT_LOGS: 'Protokolle importieren',
            IMPORT_LOG_TYPE: 'Typ',
            IMPORT_LOG_ALL: 'Alle',
            IMPORT_LOG_ERROR: 'Fehler',
            IMPORT_LOG_CHANGE: 'Änderung',
            IMPORT_LOG_NO_CHANGE: 'Keine Änderung',
            IMPORT_LOG_WARN: 'Warnen',
            IMPORT_ERROR_MESSAGE: 'Fehlermeldung',
            IMPORT_MESSAGE: 'Benachrichtigung',
            IMPORT_GET_TEMPLATE: 'Vorlage bekommen',
            IMPORTS_NO_RESULTS: 'Keine Ergebnisse',
            USER_TASK: 'Aufgabe (Benutzer)',
            USER_ASSET: 'Vermögenswert (Benutzer)',
            USER_ASSET_TASK: 'Vermögenswert/Aufgabe (Benutzer)',
            USER_ASSET_TASK_WARNING: 'Bitte beachten Sie, dass der Import nicht auf den Zugang zu Ihrem Gebäude beschränkt ist, da Sie über die Berechtigung Importe verwalten verfügen.',
            CONTRACT_WARNING: 'Bitte beachten Sie, dass Sie nur Verträge hinzufügen, nicht aktualisieren können.',
            COMMA_SEPARATED: '(durch Komma getrennt)',
            SUBASSET: 'Sub-Asset',
            WASTE_ENTRY: 'Abfalleintrag',
            SPREADSHEET_TITLE: 'Titel Tabellenkalkulation',
            VIEW_LEGEND: 'Legende anzeigen',
            DATA_REQUIREMENTS: 'Erforderliche Daten',
            IMPORT_USERS: 'Benutzer importieren',
            IMPORT_USERS_ADD: 'Benutzer importieren',
            IMPORT_ADD: 'Neuer Import',
            IMPORT_LEGEND: 'Legende',
            DOWNLOAD_IMPORT_TEMPLATE: 'Import-Vorlage {{ entity }} herunterladen',
            IMPORT_ERROR: 'Importfehler',
            IMPORT_QUEUED: 'In Warteschlange importieren',
            SITE_TENANT: 'Gebäudemieter',
            PCD_IMPORTS: 'PCD-Import',
            PCD_LIST: 'PCD-Importliste',
            PCD_IMPORT_ADD: 'Neuer PCD-Import',
            PCD_IMPORT_CREATE_SITES: 'Gebäude erstellen',
            PCD_IMPORT_CREATE_SITE_GROUPS: 'Gebäudegruppen erstellen',
            PCD_IMPORT_CREATE_SECTORS: 'Sektoren erstellen',
            PCD_IMPORT_CREATE_REGIONS: 'Regionen erstellen',
            PCD_IMPORT_CREATE_USERS: 'Benutzer erstellen',
            PCD_IMPORT_CREATE_CORE_CLIENTS: 'Kernkunden erstellen',
            PCD_IMPORT_UPDATE_SITE_CONTACTS: 'Gebäudekontakte aktualisieren',
            PCD_IMPORT_UPDATE_SITE_ACCESS: 'Zugang zum Gebäude aktualisieren',
            PCD_IMPORT_UPDATE_SITE: 'Gebäude aktualisieren',
            PCD_IMPORT_UPDATE_SITE_GROUP: 'Gebäudegruppe aktualisieren',
            NO_PCD_IMPORT_FILE_ADDED: 'Keine PCD-Importdatei hinzugefügt',
            DOWNOLAD_ASSET_TYPES: 'Asset-Typen herunterladen',
            DOWNOLAD_SERVICE_ROUTINES: 'Service-Routinen herunterladen',
            DOWNOLAD_STATUTORY_TYPES: 'Gesetzestypen herunterladen',
            CREATE_OPTIONS: 'Optionen erstellen',
            UPDATE_OPTIONS: 'Optionen aktualisieren',
            PCD: 'PCD',
            PCD_DISABLED_FOR_CLIENT: 'PCD-Importe sind nicht aktiviert',
            SCD_IMPORTS: 'SCD-Importe',
            SCD_LIST: 'SCD-Liste',
            SCD_IMPORT_ADD: 'Neue SCD-Importe',
            SCD: 'SCD',
            SCD_IMPORT_CREATE_SERVICE_PROVIDER: 'Dienstleister erstellen',
            SCD_IMPORT_CREATE_SERVICE_PROVIDER_GROUP: 'Dienstleistergruppe erstellen',
            SCD_IMPORT_CREATE_PARTNERSHIP: 'Partnerschaft erstellen',
            SCD_IMPORT_UPDATE_SERVICE_PROVIDER: 'Dienstleister aktualisieren',
            SCD_IMPORT_UPDATE_SERVICE_PROVIDER_GROUP: 'Dienstleistergruppe aktualisieren',
            SCD_IMPORT_UPDATE_PARTNERSHIP: 'Partnerschaft aktualisieren',
            SITE_CONTACT_DOESNT_EXIST: 'Gebäudekontakt existiert nicht',
            PARENT_ALREADY_HAS_A_PARENT: 'Der angegebene übergeordnete Zähler hat bereits einen übergeordneten Zähler - es wird nur eine Verschachtelungsebene unterstützt.',
            PARENT_BELONGS_TO_DIFFERENT_SITE: 'Der angegebene übergeordnete Zähler gehört zu einem anderen Gebäude.',
            CANNOT_CHANGE_PARENT: 'Übergeordneter Nebenzähler kann nicht geändert werden.',
            PARENT_IS_NOT_ACTIVE: 'Der übergeordnete Zähler ist inaktiv.',
            METER_READING: 'Zählerablesewert',
            TYPE: 'Typ',
            MIN_VALUE: 'Mindestwert',
            MAX_VALUE: 'Höchstwert',
            MIN_LENGTH: 'Minimale Länge',
            MAX_LENGTH: 'Maximale Länge',
            CHOICES: 'Auswahlmöglichkeiten',
            BE_APPROVER: 'Muss Genehmigender sein',
            NUMBER_LEGEND: 'Nummer Legende',
            DRAFT: 'Entwurf',
            TRAINING: 'Schulung',
            ACTIVE: 'Aktiv',
            INACTIVE: 'Inaktiv',
            YES: 'Ja',
            NO: 'Nein',
            IMPORTS_ID: 'ID',
            IMPORTS_FULLNAME: 'Vollständiger Name',
            IMPORTS_NAME: 'Name',
            IMPORTS_ADDRESS1: 'Adresszeile 1',
            IMPORTS_ADDRESS2: 'Adresszeile 2',
            IMPORTS_ADDRESS3: 'Adresszeile 3',
            IMPORTS_TOWN: 'Ort der Adresse',
            IMPORTS_REGION: 'Adresse Region',
            IMPORTS_POSTCODE: 'Postleitzahl der Adresse',
            IMPORTS_COUNTRY: 'Land der Adresse',
            IMPORTS_STATUS: 'Aktueller Zustand',
            IMPORTS_TELEPHONE: 'Telefonnummer',
            IMPORTS_WEBSITE: 'Website',
            IMPORTS_CITY: 'Ort',
            IMPORTS_SERVICE_PROVIDERS: 'Namen Dienstleister',
            IMPORTS_SERVICE_PROVIDER_IDS: 'Dienstleister-IDs',
            IMPORTS_SERVICE_PROVIDER_ID: 'Dienstleister-ID',
            IMPORTS_SERVICE_PROVIDER: 'Dienstleister (ID oder Name)',
            IMPORTS_SERVICE_PROVIDER_NAME: 'Name Dienstleister',
            IMPORTS_SERVICE_ROUTINE_IDS: 'IDs Service-Routine',
            IMPORTS_SERVICE_ROUTINE_ID: 'Service-Routine-ID',
            IMPORTS_SERVICE_TYPES: 'Diensttyp-Namen',
            IMPORTS_SERVICE_TYPES_IDS: 'Diensttyp-IDs',
            IMPORTS_STATUTORY_ID: 'Gesetzlich-ID',
            IMPORTS_LOCATION: 'Standortname, ID oder Neuer Standortname',
            IMPORTS_ROLES: 'Rollen',
            IMPORTS_ROLE_IDS: 'Rollen-IDs',
            IMPORTS_DESCRIPTION: 'Beschreibung',
            IMPORTS_UTILITY_CONSULTANT: 'Berater für Versorgungsunternehmen',
            IMPORTS_SITE_IDS: 'Gebäude-IDs',
            IMPORTS_SITE_ID: 'Gebäude-ID',
            IMPORTS_SITE: 'Gebäude (ID oder Name)',
            IMPORTS_JOB_GROUP_ID: 'Auftragsgruppen-ID',
            IMPORTS_ACTIVE: 'Aktiv',
            IMPORTS_REFERENCE: 'Referenz',
            IMPORTS_EXTERNAL_REFERENCE: 'Externe Referenz',
            IMPORTS_TRAINED_AT: 'Geschult in',
            IMPORTS_ACTIVE_AT: 'Aktiv am',
            IMPORTS_INACTIVE_AT: 'Inaktiv am',
            IMPORTS_GROUP_ID: 'Gruppen-ID',
            IMPORTS_SERVICE_TYPE_ID: 'Diensttyp-ID',
            IMPORTS_NEW_CUSTOMER_ACCESS: 'Zugang für Neukunden',
            IMPORTS_USERNAME: 'Benutzername',
            IMPORTS_EMAIL: 'Gültige E-Mail-Adresse',
            IMPORTS_POSITION: 'Position innerhalb der Firma',
            IMPORTS_NOTES: 'Hinweise',
            IMPORTS_MAKE: 'Fabrikat',
            IMPORTS_QUANTITY: 'Menge',
            IMPORTS_MODEL: 'Modell',
            IMPORTS_BARCODE: 'Barcode',
            IMPORTS_END_OF_LIFE: 'Ende der Lebensdauer',
            IMPORTS_CHARGEABLE: 'Aufladbar',
            IMPORTS_BUSINESS_CONTINUITY_RISK: 'Geschäftskontinuitätsrisiko',
            IMPORTS_REPLACEMENT_COST_LABOUR: 'Wiederbeschaffungskosten Arbeit',
            IMPORTS_REPLACEMENT_COST_MATERIALS: 'Wiederbeschaffungskosten Material',
            IMPORTS_ASSET_TYPE_ID: 'Asset-Typ-ID',
            IMPORTS_SERIAL_NUMBER: 'Seriennummer',
            IMPORTS_ASSET_NFC_ID: 'NFC Tag-ID',
            IMPORTS_LOCATION_ID: 'Abteilung-ID',
            IMPORTS_ASSET_SCORE_TYPE_IDS: 'Typ-ID Asset-Ergebnis',
            IMPORTS_PRIMARY_JOB_OPERATIVE_ID: 'ID Primärer Auftragsarbeiter',
            IMPORTS_PRIMARY_QUOTE_OPERATIVE_ID: 'Arbeiter-ID Primäres Angebot',
            IMPORTS_PRIMARY_OPERATIVE_ID: 'ID Primärer Arbeiter',
            IMPORTS_PRIMARY_ESCALATION_OPERATIVE_ID: 'ID Arbeiter primäre Eskalation',
            IMPORTS_PRIMARY_HEALTH_SAFETY_OPERATIVE_ID: 'ID Primärer Gesundheits- &amp; Sicherheitsarbeiter',
            IMPORTS_OPERATIVE_IDS: 'Arbeiter-IDs',
            IMPORTS_SERVICE_PROVIDER_CONTRACTOR_APPROVED: 'Auftragnehmer genehmigt',
            IMPORTS_SERVICE_PROVIDER_REQUIRE_SIGNATURE: 'Unterschrift erforderlich',
            IMPORTS_GROUP_IDS: 'Gruppen-IDs',
            IMPORTS_AREA: 'Fläche',
            IMPORTS_AREA_UNITS: 'Bereichseinheiten',
            IMPORTS_SECTOR_ID: 'Sektor-ID',
            IMPORTS_REGION_ID: 'Regions-ID',
            IMPORTS_EXCLUDE_SITE_FROM_P2P: 'Gebäude von p2p ausschließen',
            IMPORTS_P2P_STARTED_AT_DATE: 'P2P Gestartet am',
            IMPORTS_JOB_APPROVAL_REQUIRED: 'Auftragsgenehmigung erforderlich',
            IMPORTS_JOB_APPROVERS_ID: 'ID Auftrags-Genehmigender',
            IMPORTS_JOB_EXTENSIONS_APPROVAL_REQUIRED: 'Genehmigung Auftragserweiterung erforderlich',
            IMPORTS_JOB_EXTENSIONS_APPROVERS_ID: 'ID Genehmigender Auftragserweiterung',
            IMPORTS_QUOTE_APPROVERS_ID: 'ID Genehmigende Angebot',
            IMPORTS_PPM_APPROVAL_REQUIRED: 'PPM-Genehmigung erforderlich',
            IMPORTS_PPM_APPROVERS_ID: 'ID PPM-Genehmigender',
            IMPORTS_PPM_EXTENSIONS_APPROVAL_REQUIRED: 'Genehmigung PPM-Erweiterungen erforderlich',
            IMPORTS_PPM_EXTENSIONS_APPROVERS_ID: 'ID Genehmigender PPM-Erweiterungen',
            IMPORTS_CONTACT_APPROVAL_REQUIRED: 'Kontakt-Genehmigung erforderlich',
            IMPORTS_CONTACT_APPROVERS_ID: 'ID Kontakt-Genehmigender',
            IMPORTS_SURVEY_APPROVERS_ID: 'ID Genehmigender Umfrage',
            IMPORTS_SURVEY_APPROVAL_REQUIRED: 'Umfrage-Genehmigung erforderlich',
            IMPORTS_SURVEY_APPROVAL_APPROVERS_ID: 'ID Genehmigender Umfrage',
            IMPORTS_SERVICE_PROVIDERS_ID: 'ID Dienstleister',
            IMPORTS_REFERENCE_PREFIX: 'Referenzpräfix',
            IMPORTS_SITE_ASSOCIATE_ID1: 'ID1 Gebäudepartner importieren',
            IMPORTS_SITE_ASSOCIATE_USERS_ID1: 'ID1 Benutzer Gebäudepartner importieren',
            IMPORTS_SITE_ASSOCIATE_ID2: 'ID2 Gebäudepartner importieren',
            IMPORTS_SITE_ASSOCIATE_USERS_ID2: 'ID2 Benutzer Gebäudepartner importieren',
            IMPORTS_SITE_ASSOCIATE_ID3: 'ID3 Gebäudepartner importieren',
            IMPORTS_SITE_ASSOCIATE_USERS_ID3: 'ID3 Benutzer Gebäudepartner importieren',
            IMPORTS_COMPANY: 'Unternehmen',
            IMPORTS_SUPPLY_TYPE_ID: 'Versorgungstyp-ID',
            IMPORTS_REFERENCE_TYPE: 'Referenztyp',
            IMPORTS_ACCOUNT_NUMBER: 'Kontonummer',
            IMPORTS_MULTIPLIER: 'Multiplikator',
            IMPORTS_MAX_VALUE: 'Höchstwert',
            IMPORTS_FREQUENCY: 'Frequenz',
            IMPORTS_INTERVAL: 'Intervall',
            IMPORTS_IS_DUAL_RATED: 'Ist zweifach bemessen',
            IMPORTS_IS_AUTOMATICALLY_READ: 'Automatisch gelesen',
            IMPORTS_FILE_REQUIRED_ON_READING: 'Datei beim Ablesen erforderlich',
            IMPORTS_TYPE_ID: 'Typ-ID',
            IMPORTS_MANUFACTURE_ID: 'Herstellungs-ID',
            IMPORTS_UTILITY_PROVIDER_ID: 'Versorgungsanbieter-ID',
            IMPORTS_OCCUPANCY_ID: 'Belegungs-ID',
            IMPORTS_READ_AT: 'Gelesen in',
            IMPORTS_LAST_READING: 'Letzter Ablesewert',
            IMPORTS_UNIT: 'Zählereinheit',
            IMPORTS_TUR: 'TUR',
            IMPORTS_TYPE: 'Typ',
            IMPORTS_SUMMARY: 'Zusammenfassung',
            IMPORTS_OPERATIVE_ID: 'Arbeiter-ID',
            IMPORTS_ASSET_ID: 'Asset-ID',
            IMPORTS_CONTRACT_TYPE: 'Vertragsart',
            IMPORTS_START_AT: 'Starten in',
            IMPORTS_REVIEW_AT: 'Überprüfen um',
            IMPORTS_REVIEW_REMINDER: 'Erinnerung überprüfen',
            IMPORTS_EXPIRY_DATE: 'Ablaufdatum',
            IMPORTS_EXPIRES_REMINDER: 'Verfällt Erinnerung',
            IMPORTS_VALUE: 'Wert',
            IMPORTS_BILLING_INTERVAL: 'Abrechnungsintervall',
            IMPORTS_BILLING_FREQUENCY: 'Abrechnungsfrequenz',
            IMPORTS_LAST_TENDERED: 'Zuletzt angeboten',
            IMPORTS_NOTICE_PERIOD_INTERVAL: 'Intervall Kündigungsfrist',
            IMPORTS_NOTICE_PERIOD_FREQUENCY: 'Frequenz Kündigungsfrist',
            IMPORTS_WASTE_UNIT: 'Einheit (ID oder Name)',
            IMPORTS_WASTE_TYPE: 'Abfallart (ID, Name)',
            IMPORTS_WASTE_COLLECTION_DATE: 'Abholtermin und Zeit',
            IMPORTS_WASTE_VALUE: 'Abfallwert',
            IMPORTS_WASTE_TREATMENT_TYPE: 'Behandlungsart (ID oder Name)',
            IMPORTS_WASTE_TREATMENT_TYPE_VALUE: 'Wert Behandlungsart',
            IMPORTS_METER_ID: 'Zähler-ID',
            IMPORTS_READING: 'Zählerablesewert',
            IMPORTS_READING_TYPE: 'Typ Ablesewert',
            IMPORTS_ROLLED_OVER: 'Verlängert',
            IMPORTS_FREQUENCY_START_DATE: 'Frequenz Startdatum (kann nicht vor dem 1. Januar des letzten Jahres liegen)',
            HELPDESK: 'Helpdesk',
            HELPDESK_DASHBOARD: 'Helpdesk Dashboard (Bedienfeld)',
            QUOTES_LIST: 'Angebote',
            ALARM_AT: 'Alarm in',
            ALARM_LOCK: 'Alarm gesperrt',
            ALARMED: 'Alarmiert',
            HD_NO_RESULTS: 'Keine Ergebnisse',
            HD_QUOTES: 'Angebote',
            HD_STATUTORY: 'Gesetzlich',
            HD_REACTIVE: 'Reaktivieren',
            HD_PLANNED: 'Geplant',
            HD_ESCALATIONS: 'Eskalationen',
            HD_SATISFACTION_SURVEYS: 'Zufriedenheitsumfragen',
            HD_AWAITING_PAPERWORK: 'Warten auf Unterlagen',
            HD_TOOLTIP_ALARMS: 'Alarme',
            HD_TOOLTIP_AWAITING_APPROVAL: 'Warten auf die Genehmigung',
            HD_TOOLTIP_OVERDUE: 'Überfällig',
            HD_TOOLTIP_ESCALATIONS: 'Eskalationen',
            HD_TOOLTIP_CHASE: 'Verfolgen',
            HD_TOOLTIP_REMINDER: 'Erinnerung',
            HD_TOOLTIP_REACTIVE_CHASE: 'Verfolgen',
            HD_TOOLTIP_REACTIVE_ACKNOWLEDGE: 'Bestätigung',
            HD_TOOLTIP_REACTIVE_APPROVAL: 'Genehmigung',
            HD_TOOLTIP_REACTIVE_EXTENSION_APPROVAL: 'Erweiterungsgenehmigung',
            HD_TOOLTIP_REACTIVE_EXTENSION_REQUEST: 'Erweiterungsantrag',
            HD_TOOLTIP_JOB_REACTIVE_EXTENSION_REQUEST: 'Anforderung Auftragserweiterung',
            HD_TOOLTIP_QUOTE_REACTIVE_EXTENSION_REQUEST: 'Anforderung Angebotserweiterung',
            HD_TOOLTIP_REACTIVE_PRIORITY_OVERDUE: 'Teilnahme an der Verfolgung',
            HD_TOOLTIP_REACTIVE_ALARMS: 'Erinnerung',
            HD_TOOLTIP_QUOTES_CHASE: 'Verfolgen',
            HD_TOOLTIP_QUOTES_REMINDER: 'Erinnerung',
            HD_TOOLTIP_QUOTES_EXTENSION_APPROVAL: 'Erweiterungsgenehmigung',
            HD_TOOLTIP_AWAITING_PAPERWORK: 'Warten auf Unterlagen',
            HD_DAYS_SHORT: '{{days}}d',
            HD_HOURS_SHORT: '{{hours}}h',
            HD_MINUTES_SHORT: '{{minutes}}m',
            HD_SECONDS_SHORT: '{{seconds}}s',
            SUMMARY: 'Zusammenfassung',
            WORKFLOW: 'Arbeitsablauf',
            LINKS_TO: 'Links zu',
            APPROVALS_REQUIRED: 'Ausstehende Genehmigungen',
            WORKFLOW_ACTION_NOT_AVAILABLE: 'Workflow-Maßnahme nicht mehr verfügbar oder jetzt abgeschlossen',
            ENTER_DETAILS_MANUALLY: 'Details manuell eingeben',
            PHONE_NUMBER: 'Telefonnummer',
            RELATIONSHIP_TO_THE_PROPERTY: 'Beziehung zum Gebäude',
            NOTIFY_ON_CREATION: 'Benachrichtigen bei Erstellung',
            NOTIFY_ON_COMPLETION: 'Benachrichtigen bei Beendigung',
            NOTIFY_ON_ACCEPTANCE: 'Benachrichtigen bei Abnahme',
            EDIT_ALARM: 'Alarm bearbeiten',
            SET_ALARM: 'Alarmeinstellung',
            ALARM_LOCKED: 'Gesperrt',
            UNLIMITED: 'Unbegrenzt',
            COLOUR: 'Farbe',
            NONE_SET: 'Keine eingestellt',
            COUNT: 'Zählung',
            DESCRIPTION: 'Beschreibung',
            ID: 'ID',
            NONE_SELECTED: 'Keine gewählt',
            PLEASE_SELECT: 'Bitte wählen Sie eine Option aus',
            NOTES: 'Hinweise',
            VALUE: 'Wert',
            NAME: 'Name',
            REFERENCE: 'Referenz',
            EXTERNAL_REFERENCE: 'Externe Referenz',
            STATUS: 'Status',
            ADDRESS: 'Adresse',
            PHONE: 'Telefon',
            EMAIL_ADDRESS: 'E-Mail',
            EMAIL_SIGNATURE: 'E-Mail-Signatur',
            INFO: 'Info',
            COMPANY: 'Unternehmen',
            ALL: 'Alle',
            POSITION: 'Position',
            NEW_CUSTOMER_ACCESS: 'Zugang für Neukunden',
            FAILED_LOGIN_ATTEMPTS: 'Fehlgeschlagene Anmeldeversuche',
            ACCOUNT_LOCKED: 'Konto gesperrt',
            UNLOCK_USER: 'Benutzer entsperren',
            ACCOUNT_UNLOCKED: 'Benutzer entsperrt',
            IS_TEMPLATE: 'Ist Vorlage',
            EMAIL: 'E-Mail',
            ADDRESS_LINE_1: 'Adresszeile 1',
            ADDRESS_LINE_2: 'Adresszeile 2',
            ADDRESS_LINE_3: 'Adresszeile 3',
            ADD_ADDRESS: 'Adresse hinzufügen',
            GO: 'Gehe zu',
            REPORTER: 'Berichterstatter',
            NOTE: 'Hinweis',
            MAIN: 'Haupt-',
            EMAILS: 'E-Mails',
            CURRENT_VERSION: 'Aktuelle Version',
            NEW_VERSION: 'Neue Version',
            DASHBOARD: 'Dashboard (Bedienfeld)',
            ADMIN: 'Admin',
            NO_NOTIFICATIONS: 'Sie haben keine Benachrichtigungen',
            BUDDY_SWITCHED_ON: 'Freund eingeschaltet',
            BUDDYING_FOR_ON: 'Freunde für eingeschaltet',
            ADD_SUCCESS: 'Erfolgreich hinzugefügt',
            UPDATE_SUCCESS: 'Erfolgreich aktualisiert',
            EDIT_SUCCESS: 'Erfolgreich bearbeitet',
            DELETE_SUCCESS: 'Erfolgreich gelöscht',
            VIEW: 'Anzeigen',
            ADD: 'Hinzufügen',
            CREATE: 'Erstellen',
            EDIT: 'Bearbeiten',
            UPDATE: 'Aktualisieren',
            UPDATES: 'Updates',
            DELETE: 'Löschen',
            CLOSE: 'Schließen',
            RESET: 'Zurücksetzen',
            CONFIRM: 'Bestätigen',
            PREVIOUS: 'Vorherige',
            DECLINED: 'Abgelehnt',
            ACTIVATE: 'Aktivieren',
            DEACTIVATE: 'Deaktivieren',
            PARENT: 'Übergeordnet',
            CHILDREN: 'Untergeordnet',
            IS_ACTIVE: 'Status',
            ARE_YOU_SURE_HEADER: 'Sind Sie sicher?',
            ARE_YOU_SURE: 'Möchten Sie wirklich diesen Vorgang durchführen?',
            NAVIGATION_CONFIRMATION: 'Möchten Sie diese Seite wirklich verlassen? Alle Änderungen, die Sie vorgenommen haben, gehen verloren.',
            REASON_FOR_STATUS_CHANGE: 'Bitte schreiben Sie einen Grund für die Statusänderung',
            BARCODE: 'Barcode',
            FILES: 'Dateien',
            FILES_WITH_COUNT: 'Dateien ({{ ::count || 0 | number:0 }})',
            ALL_SERVICE_PROVIDERS: 'Alle Dienstleister',
            SELECTED_SERVICE_PROVIDERS: 'Ausgewählte Dienstleister',
            ALL_PARTNERSHIPS: 'Alle Partnerschaften',
            SELECTED_PARTNERSHIPS: 'Ausgewählte Partnerschaften',
            TITLE: 'Titel',
            FOOTER_COPYRIGHT: 'Copyright © Vantify Limited {{ ::currentYear }} - Reg Company No. 05277497',
            ZERO_OPTIONS_CHECKED_TITLE: 'Keine Artikel ausgewählt',
            ZERO_OPTIONS_CHECKED_MESSAGE: 'Sie müssen mindestens einen Artikel auswählen, bevor Sie diese Maßnahme ausführen',
            STATUTORYTYPE: 'Gesetzestyp',
            STATUTORY_TYPE: 'Gesetzestyp',
            START_TYPING_TO_SEARCH: 'Beginnen Sie mit der Eingabe für die Suche',
            START_TYPING_TO_FILTER: 'Beginnen Sie mit der Eingabe zum Filtern',
            START_TYPING_TO_SEARCH_SITES: 'Alle Gebäude - Beginnen Sie mit der Eingabe für die Suche',
            START_TYPING_TO_SEARCH_REGIONS: 'Alle Regionen',
            START_TYPING_TO_SEARCH_ASSOCIATE_TYPES: 'Alle Partnertypen',
            START_TYPING_TO_SEARCH_USERS: 'Alle Benutzer',
            ACTION_SUCCESS: 'Maßnahme erfolgreich',
            LOCAL_SITE_TIME: 'Ortszeit Gebäude',
            WITH_ID: '<strong>ID</strong> {{ ::id }}',
            WITH_SITE: '<strong>Gebäude</strong> {{ ::site }}',
            WITH_NAME: '<strong>Name</strong> {{ ::name }}',
            WITH_SUMMARY: '<strong>Zusammenfassung</strong> {{ ::summary }}',
            WITH_REFERENCE: '<strong>Referenz</strong> {{ ::reference }}',
            WITH_CHILDREN: '<strong>Untergeordnet</strong> {{ ::children }}',
            WITH_ACTIVE: '<strong>Aktiv</strong> {{ ::(active == "true" || active == true ? "YES" : "NO") | translate }}',
            CONTACT: 'Kontakt',
            CONTACTS: 'Kontakte',
            BACK: 'Zurück',
            NO_RESULTS_FOUND: 'Keine Ergebnisse gefunden',
            PAGINATION_SHOWING: 'Anzeige {{::from}} an {{::to}} von {{::total}}',
            DETAILS: 'Details',
            SUBMIT: 'Einreichen',
            VISIBILITY_PUBLIC: 'Öffentlich',
            VISIBILITY_HELPDESK: 'Helpdesk',
            INFORMATION: 'Informationen',
            SEND_EMAIL: 'E-Mail senden',
            NEW_COMPLETION_DUE: 'Neues Enddatum',
            CREATED_AT: 'Erstellt in',
            APPROVED_AT: 'Genehmigt in',
            ACTION_CANCEL: 'Abbrechen',
            ACTION_COMPLETE: 'Abschließen',
            ACTION_RAISE_JOB: 'Auftrag erstellen',
            ACTION_RAISE_QUOTE: 'Angebot schaffen',
            ADD_BULK_PATROL_OCCURRENCES: 'Patrouillen-Vorkommen hinzufügen',
            ADD_CONTRACT_TYPE: 'Kontakttyp hinzufügen',
            ADD_DEPARTMENT: 'Abteilung hinzufügen',
            ADD_NEW_CONTRACT_TYPE: 'Neue Vertragsart hinzufügen',
            ADD_NEW_DEPARTMENT: 'Neue Abteilung hinzufügen',
            ADD_NEW_TRADE_TYPE: 'Neue Handelsart hinzufügen',
            ADD_TRADE_TYPE: 'Handelsart hinzufügen',
            ALL_USERS: 'Alle Benutzer',
            ASSIGNEE: 'Abtretungsempfänger',
            ATTACH_A_FILE: 'Eine Datei anhängen',
            ATTACHMENT: 'Anhang',
            ATTACHMENTS: 'Anhänge',
            ATTENDANCE_DATE: 'Teilnahmedatum',
            BY_PLANNED_DATE: 'Nach geplantem Datum',
            CANCELLED: 'Abgebrochen',
            COMPLETION_BY_DATE: 'Beendigung von',
            COMPLETION_DUE_BETWEEN: 'Enddatum zwischen',
            CREATED_BY: 'Erstellt von',
            COMPOSE_CONVERSATION: 'Konversation verfassen',
            CONDITION: 'Bedingung',
            CONTRACT_APPROVAL: 'Vertragsgenehmigung',
            CONTRACT_TERMINATED: 'Vertrag beendet',
            CONTRACT_TYPES: 'Vertragsarten',
            CONVERSATIONS: 'Unterhaltungen',
            CREATE_AS_GLOBAL_TEMPLATE: 'Als globale Vorlage erstellen',
            CREATED: 'Erstellt',
            DATE_CREATED: 'Datum erstellt',
            DELETE_SURVEY: 'Umfrage löschen',
            DEPARTMENTS: 'Abteilungen',
            DONE: 'Erledigt',
            EDIT_CONTRACT: 'Vertrag bearbeiten',
            EDIT_CONTRACT_TYPE: 'Vertragsart bearbeiten',
            EDIT_DEPARTMENT: 'Abteilung bearbeiten',
            EDIT_TRADE_TYPE: 'Handelsart bearbeiten',
            EDITABLE: 'Editierbar',
            EXPORT: 'Exportieren',
            EXPORT_IN_PROCESS: 'In Warteschlange exportieren',
            EXPORT_IN_PROCESS_WITH_LIMIT: 'In Warteschlange exportieren, aber die Daten werden auf {{ limit }} Artikel beschränkt sein',
            EXPORT_KPI_TREND: 'KPI-Trend exportieren',
            GROUP: 'Gruppe',
            HELPDESK_NOTES: 'Hinweis Helpdesk',
            HIDE: 'Ausblenden',
            JOB_HISTORY_NOTE: 'Hinweis Auftragsverlauf',
            JOB_MORE_MESSAGES: 'Mehr Benachrichtigungen',
            JOB_NEXT_PLANNED_VISIT: 'Nächster geplanter Besuch',
            JOB_STATUS_: 'Status',
            LAST_MESSAGE: 'Letzte Benachrichtigung',
            LAST_SUBMITTED_BETWEEN: 'Zuletzt eingereicht zwischen',
            LATEST_REACTIVE_JOBS: 'Letzte reaktive Aufträge',
            LEGEND_: 'Legende',
            LOCATION_BASED_SECTION: 'Standortbasierter Abschnitt',
            MONTHS: 'Monate',
            'N/A': 'Nicht verfügbar',
            NEW_JOB: 'Neuer Auftrag',
            NEXT: 'Weiter',
            NO_ANSWER_OPTIONS: 'Keine Antwortoptionen',
            NO_NOTE_PROVIDED: 'Kein Hinweis bereitgestellt',
            NOTE_REQUIRED: 'Hinweis erforderlich',
            NO_REACTIVE_JOBS: 'Keine reaktiven Aufträge',
            NO_SERVICE_PROVIDER_ENTERED: 'Kein Dienstleister eingegeben',
            NO_SITE_ASSOCIATES_ASSIGNED: 'Kein Gebäudepartner eingegeben',
            NO_DECIMAL_ALLOWED: 'Dezimalzahlen nicht erlaubt',
            NON_CHARGEABLE: 'Nicht aufladbar',
            NONE: 'Keine',
            ON_SITE_DATE: 'Vor Ort Datum',
            OOH_NOTES: 'OOOH Hinweise',
            OOH_PHONE: 'OOH Telefon',
            OPEN: 'Offen',
            ORDER: 'Bestellung',
            OVERDUE_NO: 'Nein',
            OVERDUE_YES: 'Ja',
            OWNER: 'Eigentümer',
            PREV: 'Vorherige',
            RAISED_BY: 'Erhoben von',
            REQUEST: 'Anfordern',
            RISK: 'Risiko',
            SATISFACTION_SURVEY: 'Zufriedenheitsumfrage',
            SAVE: 'Speichern',
            SCORE_RANGE: 'Ergebnisbereich',
            SEARCH: 'Suche',
            SEARCH_SITE: 'Gebäude suchen',
            SELECT: 'Auswählen',
            SELECT_A_USER: 'Einen Benutzer auswählen',
            SELECT_CONDITION: 'Bedingung auswählen',
            SELECT_DEPARTMENT: 'Abteilung auswählen',
            SELECT_RISK: 'Risiko auswählen',
            SELECTED_USERS: 'Ausgewählte Benutzer',
            SEND: 'Senden',
            SERVICE: 'Service',
            SERVICE_PROVIDED: 'Service bereitgestellt',
            SHOW: 'Anzeigen',
            SKIP_THIS_TIME: 'Dieses Mal überspringen',
            STATUS_DETAILS: 'Statusdetails',
            SUBMITTED: 'Eingereicht',
            SURVEY_RANGE: 'Bereich',
            SURVEY_WEIGHT: 'Gewicht',
            TARGET: 'Ziel',
            TELEPHONE: 'Telefon',
            TRADE_TYPES: 'Handelsarten',
            UNABLE_TO_CREATE_TENANT: 'Mieter kann nicht erstellt werden',
            UNABLE_TO_GET_LOCATION: 'Standort konnte nicht ermittelt werden',
            UNABLE_TO_GET_TENANT: 'Mieter konnte nicht ermittelt werden',
            UNABLE_TO_UPDATE_TENANT: 'Mieter kann nicht aktualisiert werden',
            UNSUBSCRIBE: 'Abbestellen',
            VIEW_NOTIFICATIONS_UNREAD_NEWER: 'Neuer',
            VIEW_NOTIFICATIONS_UNREAD_OLDER: 'Älter',
            WEBSITE: 'Website',
            WEIGHT: 'Gewicht',
            WEIGHTED_SCORE: 'Gewichtetes Ergebnis',
            WRITE_A_MESSAGE: 'Eine Benachrichtigung schreiben',
            FILE_ATTACHMENT: 'Dateianhang',
            COPY_ME: 'Kopie an mich senden',
            SUBJECT: 'Betreff',
            TO: 'An',
            EMAIL_S_SENT: 'E-Mail(s) gesendet',
            SHOW_MORE: 'Mehr anzeigen',
            SHOW_LESS: 'Weniger anzeigen',
            EDIT_RECIPIENTS: 'Empfänger bearbeiten',
            RECIPIENTS: 'Empfänger',
            VIEW_ALL: 'Alle anzeigen',
            WILL_CHASE_AT: 'Wird verfolgt in',
            EXTENSION_DATE: 'Erweiterungsdatum',
            I_AM_THE_APROVER: 'Genehmigender',
            I_AM_APPROVER: 'Ich bin Genehmigender',
            MESSAGE_CREATED: 'Benachrichtigung erstellt',
            ACCEPT: 'Akzeptieren',
            DUE_DATE: 'Fälligkeitsdatum',
            FEET: 'Fuß',
            METRES: 'Meter',
            IMAGE_FILE: 'Bild',
            NOTIFICATION_NOT_FOUND: 'Benachrichtigung nicht gefunden',
            NOTIFICATION_TYPE_NOT_FOUND: 'Benachrichtigungsart nicht gefunden',
            OTHER: 'Sonstiges',
            SKIPPED_NOTIFICATION: 'Übersprungene Benachrichtigung',
            REMINDER_AT: 'Erinnerung in',
            REQUESTED: 'Angefordert',
            INCLUDE_MY_SIGNATURE: 'Meine Unterschrift einschließen',
            LOAD_MORE: 'Mehr laden',
            AT_LEAST_ONE_ITEM_REQUIRED: 'Mindestens ein Element ist erforderlich.',
            NO_MORE_RESULTS: 'Keine Ergebnisse mehr zu laden.',
            MAX_NUMBER_SELECTED: 'Maximale Anzahl von Elementen ausgewählt.',
            EXPIRED: 'Abgelaufen',
            VALID: 'Gültig',
            REVOKE: 'Widerrufen',
            REVOKED: 'Widerrufen',
            INDEFINITE: 'Unbestimmt',
            FOR_REVIEW: 'Zur Überprüfung',
            PAPERWORK: 'Unterlagen',
            CREATED_AT_BETWEEN: 'Erstellt in zwischen',
            DATE_COMPLETE_AT: 'Abgeschlossen in',
            COPIED_TO_CLIPBOARD: 'In die Zwischenablage kopiert',
            BROWSER_UNSUPPORTED: 'Browser nicht unterstützt',
            IMAGES_UPDATED: 'Bilder wurden aktualisiert',
            IMAGE_DELETE: 'Bild wurde gelöscht',
            IMAGE_UPLOAD: 'Bild wurde hochgeladen',
            IMAGE_UPDATE: 'Bild wurde aktualisiert',
            NO_IMAGE_SELECTED: 'Kein Bild ausgewählt',
            SET_MAIN_IMAGE: 'Als Hauptbild festlegen',
            INCORRECT_FILE_TYPE: 'Falscher Dateityp',
            INVALID_PERMISSIONS: 'Berechtigungen ungültig',
            NO_RESOURCE_FOUND: 'Keine Ressource gefunden',
            FILTERED: 'Gefiltert',
            SELECTED: 'Ausgewählt',
            APPROVER_IS_NOT_SET: 'Eine Genehmigung ist erforderlich, aber es wurde kein Genehmigender festgelegt',
            SELECT_AT_LEAST_ONE_SITE: 'Mindestens ein Gebäude ist erforderlich',
            ITEM_FIELD_REQUIRED: 'Elementfeld ist erforderlich',
            SELECTALLSITES: 'Alle Gebäude auswählen',
            TOTALS: 'Gesamtbeträge',
            PLANNED_JOBS: 'Geplante Aufträge',
            REACTIVE_JOBS: 'Reaktive Aufträge',
            REPORT_ON: 'Bericht am',
            TABLE_ACTIONS: 'Tabellenmaßnahmen',
            EXPAND_ALL: 'Alle erweitern',
            COLLAPSE_ALL: 'Alle reduzieren',
            EXPIRED_AT: 'Abgelaufen in',
            REJECTED_AT: 'Abgelehnt in',
            NO_RESULTS: 'Keine Ergebnisse',
            DECLINED_AT: 'Abgelehnt in',
            ACCEPTED_AT: 'Angenommen in',
            FIELD_WITH_MAXIMUM_LENGTH: '{{ fieldName }} maximale Feldlänge beträgt {{ limit }} Zeichen',
            START_DATE: 'Anfangsdatum',
            END_DATE: 'Enddatum',
            UPLOAD_SITE_IMAGE: 'Gebäudebild hochladen',
            ACCESS_DENIED: 'Zugang verweigert',
            CIRCULAR_REFERENCE: 'Zirkelbezug',
            RELATION_LINK_PLANNED_TO_RESTRICTION: 'Geplanter Auftrag kann nicht mit anderen Aufträgen verknüpft werden',
            RELATION_LINK_PLANNED_FROM_QUOTE_RESTRICTION: 'Geplanter Auftrag kann nicht mit dem Angebot verknüpft werden',
            DUPLICATED_RELATION_LINK: 'Auftrags-Link existiert bereits',
            NEW_TAG: ' (Neu)',
            DELETED: 'Gelöscht',
            NOT_FOUND: 'Nicht gefunden',
            EXPIRES_AT: 'Ablaufdatum',
            SERVICETYPE: 'Diensttyp',
            LOCATION_REQUIRED: 'Standort erforderlich',
            PHOTO_IS_REQUIRED: 'Foto ist erforderlich',
            RATIO: 'Verhältnis',
            TRAINING_ACADEMY: 'Schulungsakademie',
            ORDER_MUST_BE_A_NUMBER: 'Bestellung muss eine Zahl sein',
            DUPLICATED_ORDER: 'Doppelte Bestellung',
            INCORRECT_FORMAT: 'Falsches Format',
            NO_ASSOCIATE_TYPE_FOR_SITE: 'Kein solcher Partnertyp für diesen Gebäude',
            MAX_CHARACTER_COUNT: 'Max. 40 Zeichen',
            PIXELS: 'Pixel',
            N_A: 'Nicht verfügbar',
            EITHER: 'Entweder',
            MARKUP: 'Managementgebühr',
            MARKED_UP_VALUE: 'Gesamtkosten',
            SEND_BETWEEN_DATES: 'Gesendet zwischen',
            EFS_VALUE: 'Kosten Unterlieferant',
            EMAIL_DATE: 'Datum',
            EMAIL_SUBJECT: 'Betreff',
            EMAIL_SENDER: 'Sender',
            EMAIL_ATTACHMENT: 'Anhang',
            EMAIL_SENT_START: 'Start gesendet',
            EMAIL_SENT_END: 'Ende gesendet',
            EMAIL_RECIPIENT_NAME: 'Empfängername',
            EMAIL_DATE_TIME: 'Datum und Uhrzeit gesendet',
            REPLY_TO: 'Antwortadresse',
            INVALID_REPLY_TO_ADDRESS: 'Ungültige Antwortadresse',
            BACK_TO_LIST: 'Zurück zur Liste',
            COMMENT_INVALID_LENGTH: 'Der Kommentar darf nicht länger als 255 Zeichen sein',
            MANDATORY: 'Obligatorisch',
            PUBLIC: 'Öffentlich',
            PRIVATE: 'Privat',
            COMMENT: 'Kommentar',
            ATTENDANCE: 'Teilnahme',
            COMPLETION: 'Beendigung',
            JOBS_DUE_BY_DAY: 'Tag(e)',
            JOBS_DUE_BY_WEEK: 'Woche(n)',
            JOBS_DUE_BY_MONTH: 'Monat(e)',
            JOBS_DUE_BY_SELECT: 'Typ auswählen',
            JOBS_DUE_BY_FREQUENCY_SELECT: 'Frequenz auswählen',
            DOWNLOAD_PDF: 'Als PDF herunterladen',
            STAT: 'Statistik-Typ',
            FREQUENCY_YEARLY: '{{ ::interval }} Jährlich',
            FREQUENCY_MONTHLY: '{{ ::interval }} Monatlich',
            FREQUENCY_WEEKLY: '{{ ::interval }} Wöchentlich',
            FREQUENCY_WEEKS: '{{ ::interval }} {{ interval == "1" ? "Week" : "Weeks" }}',
            FREQUENCY_DAYS: '{{ ::interval }} {{ interval == "1" ? "Day" : "Days" }}',
            FREQUENCY_HOURS: '{{ ::interval }} {{ interval == "1" ? "Hour" : "Hours" }}',
            FREQUENCY_MINUTES: '{{ ::interval }} {{ interval == "1" ? "Minute" : "Minutes" }}',
            FREQUENCY_SECONDS: '{{ ::interval }} {{ interval == "1" ? "Second" : "Seconds" }}',
            FORMS: 'Formulare',
            FORM_LIST: 'Formulare',
            FORMS_WITH_COUNT: 'Formulare ({{ ::count || 0 | number:0 }})',
            FORM_REMOVE_COMPONENT: 'Entfernen',
            FORM_COPY_COMPONENT: 'Kopieren',
            FORM_ADD: 'Ein Formular hinzufügen',
            FORM_EDIT: 'Ein Formular bearbeiten',
            FORM_TYPE: 'Typ',
            FORM_VERSION: 'Version',
            FORM_TYPE_JOB: 'Auftragsformular',
            FORM_TYPE_ASSET: 'Asset-Formular',
            FORM_TYPE_TASK: 'Aufgaben-Formular',
            FORM_CREATED: 'Formular erstellt',
            FORM_UPDATED: 'Formular aktualisiert',
            FORM_DELETED: 'Formular gelöscht',
            FORM_ASSOCIATION_CREATED: 'Formular hinzugefügt',
            FORM_ASSOCIATION_DELETED: 'Formular entfernt',
            FORM_INSTANCE_CREATED: 'Formular eingereicht',
            FORM_INSTANCE_UPDATED: 'Formular gespeichert',
            FORM_INSTANCE_DELETED: 'Formular entfernt',
            FORM_STATUS_OPEN: 'Offen',
            FORM_STATUS_IN_PROGRESS: 'In Bearbeitung',
            FORM_STATUS_COMPLETED: 'Abgeschlossen',
            FORM_VERSION_UPDATED: 'Formular aktualisiert',
            FORM_VERSION_NUMBER: 'Version {{ ::version }}',
            FORM_VERSION_CHANGED_SIGNIFICANTLY: 'Dieses Formular hat sich erheblich geändert und beim Speichern kann eine neue Version erstellt werden',
            FORM_VERSION_PUBLISH: 'Formular veröffentlichen',
            FORM_VERSION_STATUS: 'Von Versionsstatus',
            FORM_VERSION_STATUS_ARCHIVED: 'Archiviert',
            FORM_VERSION_STATUS_DRAFT: 'Entwurf',
            FORM_VERSION_STATUS_PUBLISHED: 'Veröffentlicht',
            FORM_SETTINGS: 'Einstellungen',
            FORM_BUILDER: 'Bauarbeiter',
            FORM_RENDER_WEB: 'Webansicht',
            FORM_RENDER_APP: 'App-Ansicht',
            FORM_SETTING_FONT_SIZE: 'Schriftgröße',
            FORM_SETTING_FONT_COLOUR: 'Textfarbe',
            FORM_SETTING_LINE_THICKNESS: 'Linienstärke',
            FORM_SETTING_COLOUR: 'Farbe',
            FORM_SETTING_WIDTH: 'Breite',
            FORM_SETTING_QUESTION_ALIGNMENT: 'Frage Textausrichtung',
            FORM_SETTING_QUESTION_ALIGNMENT_AUTO: 'Automatisch',
            FORM_SETTING_QUESTION_ALIGNMENT_LEFT: 'Links neben der Antwort',
            FORM_SETTING_QUESTION_ALIGNMENT_ABOVE: 'Über der Antwort',
            FORM_SETTING_QUESTION_CHOICE_LABEL_ALIGNMENT: 'Auswahl Label-Ausrichtung',
            FORM_SETTING_QUESTION_CHOICE_LABEL_ALIGNMENT_LEFT: 'Links neben dem Kontrollkästchen',
            FORM_SETTING_QUESTION_CHOICE_LABEL_ALIGNMENT_RIGHT: 'Rechts neben dem Kontrollkästchen',
            FORM_CONTROL_HEADER: 'Kopfzeile',
            FORM_CONTROL_FOOTER: 'Fußzeile',
            FORM_CONTROL_QUESTION: 'Frage',
            FORM_CONTROL_HORIZONTALRULE: 'Teiler',
            FORM_CONTROL_PAGEBREAK: 'Seitenumbruch',
            FORM_CONTROL_TEXT: 'Text',
            FORM_CONTROL_MACRO: 'Makro',
            FORM_CONTROL_UNDO: 'Rückgängig',
            FORM_CONTROL_REDO: 'Wiederherstellen',
            FORM_CONTROL_HEADER_DETAIL: 'Kopfzeilentext',
            FORM_CONTROL_FOOTER_DETAIL: 'Fußzeilentext',
            FORM_CONTROL_QUESTION_DETAIL: 'Frage',
            FORM_CONTROL_MACRO_DETAIL: 'Makro-Element',
            FORM_CONTROL_QUESTION_TYPE_DETAIL: 'Fragen-Typ',
            FORM_CONTROL_QUESTION_TYPE_TEXT: 'Text (Einreihig)',
            FORM_CONTROL_QUESTION_TYPE_TEXTAREA: 'Text (Mehrreihig)',
            FORM_CONTROL_QUESTION_TYPE_CHOICE_SELECT: 'Dropdown-Auswahl',
            FORM_CONTROL_QUESTION_TYPE_CHOICE_RADIO: 'Radio-Auswahl',
            FORM_CONTROL_QUESTION_TYPE_CHOICE_CHECKBOX: 'Auswahl Kontrollkästchen',
            FORM_CONTROL_QUESTION_TYPE_SLIDING_SCALE: 'Staffelung',
            FORM_CONTROL_QUESTION_TYPE_DATEPICKER: 'Datum-Picker',
            FORM_CONTROL_QUESTION_TYPE_FILE: 'Datei-Upload',
            FORM_CONTROL_OPTION_CONDITION: 'Bedingung',
            FORM_CONTROL_OPTION_CONDITION_ELEMENT: 'Frage',
            FORM_CONTROL_OPTION_CONDITION_ELEMENT_SELECT: 'Ein Element auswählen',
            FORM_CONTROL_OPTION_CONDITION_ELEMENT_SELECTING: 'Wahl eines Elements',
            FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE: 'Anpassung',
            FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_DOES_NOT_CONTAIN: 'Beinhaltet nicht',
            FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_CONTAINS: 'Enthält',
            FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_BEGINS: 'Beginnt mit',
            FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_ENDS: 'Endet mit',
            FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_EQUALS: 'Gleich',
            FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_NOT_EQUALS: 'Ist ungleich',
            FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_GREATER_THAN: 'Größer als',
            FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_LESSER_THAN: 'Kleiner als',
            FORM_CONTROL_OPTION_CONDITION_MATCH_TYPE_BETWEEN: 'Zwischen',
            FORM_CONTROL_OPTION_CONDITION_VALUE: 'Wert',
            FORM_CONTROL_OPTION_ALLOW_NA: 'Zulassen nicht zutreffend',
            FORM_CONTROL_OPTION_REQUIRED: 'Erforderlich',
            FORM_CONTROL_OPTION_PLACEHOLDER: 'Platzhaltertext',
            FORM_CONTROL_OPTION_TEXT_ALIGN: 'Textausrichtung',
            FORM_CONTROL_OPTION_ALLOW_TIME: 'Zeit zulassen',
            FORM_CONTROL_OPTION_MIN_DATE_OFFSET: 'Min. Offset-Tage',
            FORM_CONTROL_OPTION_MAX_DATE_OFFSET: 'Max. Offset-Tage',
            FORM_CONTROL_OPTION_UNRESTRICTED: 'Uneingeschränkt',
            FORM_CONTROL_TEXTAREA_ROWS: 'Anzahl der Reihen',
            FORM_CONTROL_CHOICE_OPTIONS: 'Optionen',
            FORM_CONTROL_CHOICE_OPTIONS_TABLE_COLUMNS: 'Spalten',
            FORM_CONTROL_CHOICE_OPTIONS_SORT_ALPHABETICALLY: 'Alphabetisch sortieren',
            FORM_CONTROL_CHOICE_PLEASE_SELECT: 'Bitte wählen Sie eine Option aus',
            FORM_CONTROL_VALIDATION_ALLOW_MULTIPLE: 'Mehrere zulassen',
            FORM_CONTROL_VALIDATION_MIN_LENGTH: 'Mindestlänge',
            FORM_CONTROL_VALIDATION_MIN_LENGTH_ZERO_DETAIL: 'Null entfernt die Anforderung',
            FORM_CONTROL_VALIDATION_MAX_LENGTH: 'Maximale Länge',
            FORM_CONTROL_VALIDATION_MAX_LENGTH_ZERO_DETAIL: 'Null entfernt die Anforderung',
            FORM_CONTROL_VALIDATION_MIN_VALUE: 'Mindestwert',
            FORM_CONTROL_VALIDATION_MAX_VALUE: 'Höchstwert',
            FORM_CONTROL_VALIDATION_INTERVAL: 'Intervall',
            FORM_MACRO_JOB_SUMMARY: 'Auftragszusammenfassung',
            FORM_MACRO_JOB_DESCRIPTION: 'Auftragsbeschreibung',
            FORM_MACRO_JOB_SITE_NAME: 'Gebäude-Name',
            FORM_MACRO_JOB_SERVICE_PROVIDER_NAME: 'Name Dienstleister',
            FORM_MACRO_JOB_OPERATIVE_NAME: 'Name des Arbeiters',
            FORM_MACRO_JOB_ATTENDANCE_DUE_AT: 'Fälligkeitsdatum Teilnahme',
            FORM_MACRO_ASSET_NAME: 'Asset-Name',
            FORM_MACRO_ASSET_REFERENCE: 'Referenz',
            FORM_MACRO_ASSET_MAKE: 'Fabrikat',
            FORM_MACRO_ASSET_MODEL: 'Modell',
            FORM_MACRO_ASSET_BARCODE: 'Barcode',
            FORM_MACRO_ASSET_SERIAL_NUMBER: 'Seriennummer',
            FORM_MACRO_ASSET_LOCATION_TITLE: 'Asset-Standort',
            FORM_MACRO_TASK_NAME: 'Bezeichnung der Aufgabe',
            FORM_MACRO_TASK_LOCATION_TITLE: 'Standort der Aufgabe',
            FORM_MACRO_TASK_ASSET_NAME: 'Asset-Name',
            FORM_MACRO_TASK_ASSET_REFERENCE: 'Asset-Referenz',
            FORM_MACRO_TASK_ASSET_MAKE: 'Asset-Fabrikat',
            FORM_MACRO_TASK_ASSET_MODEL: 'Asset-Modell',
            FORM_MACRO_TASK_ASSET_BARCODE: 'Asset-Barcode',
            FORM_MACRO_TASK_ASSET_SERIAL_NUMBER: 'Asset-Seriennummer',
            FORM_MACRO_TASK_ASSET_LOCATION_TITLE: 'Asset-Standort',
            FORM_MACRO_VALUE_NOT_FOUND: 'Wert nicht gefunden',
            FORM_NO_ANSWER: 'Keine Antwort',
            VIOLATION_FORM_ANSWER_CANNOT_BE_NOT_APPLICABLE: '{{ path }} -- Die Antwort darf nicht als nicht zutreffend gekennzeichnet werden',
            VIOLATION_FORM_ANSWER_REQUIRED: '{{ path }} -- Antwort ist erforderlich',
            VIOLATION_FORM_ANSWER_MIN_LENGTH: '{{ path }} -- Mindestlänge nicht erfüllt',
            VIOLATION_FORM_ANSWER_MAX_LENGTH: '{{ path }} -- Maximale Länge überschritten',
            VIOLATION_FORM_ANSWER_MIN_VALUE: '{{ path }} -- Minimalwert nicht erfüllt',
            VIOLATION_FORM_ANSWER_MAX_VALUE: '{{ path }} -- Höchstwert überschritten',
            VIOLATION_FORM_ANSWER_INTERVAL: '{{ path }} -- Ungültiges Intervall verwendet',
            VIOLATION_FORM_ANSWER_INVALID_CHOICE: '{{ path }} -- Ungültige Wahl verwendet',
            VIOLATION_FORM_ANSWER_MULTIPLE: '{{ path }} -- Erwartete Antwortmöglichkeiten',
            VIOLATION_FORM_ANSWER_MULTIPLE_NOT_ALLOWED: '{{ path }} -- Mehrere Antworten nicht erlaubt',
            VIOLATION_FORM_ANSWER_MIN_DATE: '{{ path }} -- Mindestdatum überschritten',
            VIOLATION_FORM_ANSWER_MAX_DATE: '{{ path }} -- Höchstdatum überschritten',
            VIOLATION_FORM_WRONG_FONT_SIZE: '{{ path }} -- Ungültige Schriftgröße',
            VIOLATION_FORM_WRONG_LINE_THICKNESS: '{{ path }} -- Ungültige Linienstärke',
            UNABLE_TO_DELETE_COMPLETED_FORM: 'Ein ausgefülltes Formular kann nicht entfernt werden',
            INVALID_FORM_VERSION_STATUS: 'Status der ungültigen Formularversion',
            VIOLATION_FORM_CONDITION_UPPER_LIMIT_LESS_THAN_LOWER_LIMIT: 'Die Obergrenze darf nicht kleiner oder gleich der Untergrenze sein',
            FORM_ASSOCIATIONS: 'Formular',
            FORM_ASSOCIATIONS_LIST: 'Formulare',
            FORM_ASSOCIATIONS_ADD: 'Formular hinzufügen',
            FORM_ASSOCIATIONS_CREATED: 'Formular hinzugefügt',
            FORM_ASSOCIATIONS_DELETED: 'Formular entfernt',
            FLOOR: 'Boden',
            FLOORS: 'Böden',
            ADD_NEW_FLOOR: 'Einen neuen Boden hinzufügen',
            EDIT_FLOOR: 'Boden bearbeiten',
            FLOOR_CREATED: 'Boden wurde erfolgreich erstellt',
            FLOOR_UPDATED: 'Boden wurde erfolgreich aktualisiert',
            FLOOR_DELETED: 'Boden gelöscht',
            NO_FLOOR_RECORDS: 'Keine Bodenaufzeichnungen',
            FILE: 'Datei',
            FILES_JOB_FILES: 'Auftragsdateien',
            FILES_SITE_FILES: 'Gebäudedateien',
            FILE_ADD: 'Eine neue Datei hinzufügen',
            FILE_EDIT: 'Datei bearbeiten',
            FILE_LIST: 'Dateien',
            FILE_CREATED: 'Datei erstellt',
            FILE_UPDATED: 'Datei aktualisiert',
            FILE_DELETED: 'Datei gelöscht',
            FILE_TITLE: 'Dateititel:',
            CAPTION: 'Bildunterschrift',
            UPLOAD_AND_EDIT: 'Hochladen und bearbeiten',
            FILE_NAME: 'Dateiname',
            UPLOAD: 'Hochladen',
            DELETE_IMAGE: 'Bild löschen',
            UPLOADED_BY: 'Hochgeladen von',
            UPLOADED_DATE_START: 'Hochgeladenes Startdatum',
            UPLOADED_DATE_END: 'Hochgeladenes Enddatum',
            EXPIRES_DATE_START: 'Ablaufdatum Start',
            EXPIRES_DATE_END: 'Ablaufdatum Ende',
            FILES_NOTIFY_EXPIRY: '{{ ::expiryTime }} Tag(e) vor dem Ablaufdatum benachrichtigen',
            FILES_NOTIFY_EXPIRY_UPDATED: 'Ablauferinnerungen erfolgreich aktualisiert',
            FILES_NOTIFY_EXPIRY_SELECTED: 'Ablauferinnerungen aktualisieren',
            FILES_NOTIFY_EXPIRY_FILTERED: 'Ablauferinnerung an Gefiltert senden',
            FILES_NOTIFY_EXPIRY_TOGGLE_TOOLTIP: 'Ablauferinnerungen festlegen',
            FILES_SELECTED: 'Dateien ausgewählt',
            FILE_DOWNLOAD: 'Download',
            FILE_PROCESSING: 'Dateiverarbeitung',
            INVALID_FILE: 'Ungültige Datei',
            IS_STATUTORY_FILE: 'Gesetzesdatei',
            ISSTATUTORY: 'Gesetzesdatei',
            FILES_REQUIRED: 'Mindestens eine Datei ist erforderlich',
            FILE_REQUIRED: 'Datei ist erforderlich',
            MAXIMUM_SIX_UPLOADS: 'Maximale Anzahl von sechs Uploads',
            MAXIMUM_SIX_UPLOADS_REACHED: 'Maximale Anzahl von sechs Uploads erreicht',
            FILES_10_MAX: 'Maximal 10 Dateien',
            FILES_ADD: 'Dateien hinzufügen',
            INTEGRATION_UPLOAD_STATUS: 'Status hochladen',
            UPLOAD_PENDING: 'Upload ausstehend',
            UPLOADED: 'Hochgeladen',
            UPLOAD_ERROR: 'Upload-Fehler',
            INTEGRATIONS: 'Integrationen',
            ERROR_IMAGE_IS_REQUIRED: 'Bild ist erforderlich',
            FILE_TYPE_IMAGE_REQUIRED: 'Datei ist kein Bild',
            INVALID_UPLOADER: 'Uploader ist ungültig',
            IMAGE: 'Bild',
            FILES_APPLICABLE_TO: 'Anwendbar auf',
            EXTEND_SESSION_HEADER: 'Sitzung erweitern',
            EXTEND_SESSION_BODY: 'Sie werden automatisch ausgeloggt in <strong>{{ ::timeRemaining }}</strong>. Um Ihre Sitzung um 24 Stunden zu erweitern, klicken Sie unten auf \'Erweitern\'.',
            EXTEND_SESSION: 'Erweitern',
            LOGOUT: 'Abmelden',
            EVENT_FEED: 'Audit Trail',
            EVENT: 'Ereignis',
            EVENTS: 'Ereignisse',
            UPDATE_NOTIFY: 'Aktualisieren &amp; Benachrichtigen',
            NOTIFICATIONS_SENT_TO: 'Benachrichtigungen gesendet an',
            NOTIFY: 'Benachrichtigen',
            WRITE_YOUR_UPDATE: 'Ihr Update schreiben',
            ESCALATION: 'Eskalation',
            ESCALATIONS: 'Eskalationen',
            ESCALATION_LIST: 'Eskalationen',
            ESCALATION_ADD: 'Eine neue Eskalation hinzufügen',
            ESCALATION_EDIT: 'Eskalation bearbeiten',
            ESCALATION_CREATED: 'Eskalation erstellt',
            ESCALATION_UPDATED: 'Eskalation aktualisiert',
            ESCALATION_DELETED: 'Eskalation gelöscht',
            ESCALATE: 'Eskalieren',
            ESCALATED: 'An höhere Stelle verwiesen',
            ESCALATED_AT: 'Eskaliert an',
            ESCALATION_DETAILS: 'Eskalation {{ id }} Details',
            ESCALATION_STATUS: 'Status',
            DEESCALATED: 'Aus Eskalation entfernt',
            EXPORT_JOBS: 'Aufträge exportieren',
            EXPORT_QUOTES: 'Angebote exportieren',
            ESCALATION_SUCCESS: 'Eskalation erfolgreich',
            ESCALATE_OPERATION_SUCCESS: 'An höhere Stelle verwiesen',
            ESCALATION_PROGRESS_SUCCESS: 'Eskalation vorangeschritten',
            ESCALATION_POSTPONE_SUCCESS: 'Eskalation aufgeschoben',
            ESCALATION_CANCEL_SUCCESS: 'Eskalation abgebrochen',
            ESCALATION_WORKFLOW_PROGRESS: 'Fortschritt',
            ESCALATION_WORKFLOW_PROGRESS_SUBMIT: 'Fortschritt',
            ESCALATION_WORKFLOW_CANCEL: 'Abbrechen',
            ESCALATION_WORKFLOW_CANCEL_SUBMIT: 'Eskalation abbrechen',
            ESCALATION_WORKFLOW_POSTPONE: 'Aufschieben',
            ESCALATION_WORKFLOW_POSTPONE_SUBMIT: 'Aufschieben',
            NO_ESCALATION_OPERATIVE: 'Arbeiter keine primäre Eskalation',
            ESCALATION_STAGE: 'Eskalationsstufe',
            ESCALATION_STAGES: 'Eskalationsstufen',
            ESCALATION_STAGE_LIST: 'Eskalationsstufen',
            ESCALATION_STAGE_ADD: 'Eine neue Eskalationsstufe hinzufügen',
            ESCALATION_STAGE_EDIT: 'Eskalationsstufe bearbeiten',
            ESCALATION_STAGE_CREATED: 'Eskalationsstufe erstellt',
            ESCALATION_STAGE_UPDATED: 'Eskalationsstufe aktualisiert',
            ESCALATION_STAGE_DELETED: 'Eskalationsstufe gelöscht',
            STAGE: 'Stufe',
            EMAIL_CONTENT: 'E-Mail-Inhalt',
            PRIMARY_CONTACT: 'Primärer Kontakt',
            ASSOCIATE_TYPES: 'Typen zuordnen',
            ASSOCIATE_TYPES_SELECT: 'Partnertypen zuordnen',
            OTHER_CONTACTS: 'Sonstige Kontakte',
            OTHER_CONTACTS_SELECT: 'Sonstige Kontakte auswählen',
            REVIEW_AT: 'Überprüfen um',
            ESCALATION_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat die Eskalation erhoben.',
            ESCALATION_JOB_ADDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat einen Auftrag eskaliert.',
            ESCALATION_JOB_ADDED_SYSTEM_ACTION: 'Das System hat einen Auftrag automatisch eskaliert.',
            ESCALATION_JOB_READDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat einen Auftrag neu eskaliert.',
            ESCALATION_JOB_READDED_SYSTEM_ACTION: 'Das System hat einen Auftrag automatisch neu eskaliert.',
            ESCALATION_JOB_REMOVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat einen Auftrag entfernt.',
            ESCALATION_JOB_REMOVED_SYSTEM_ACTION: 'Das System hat einen Auftrag automatisch entfernt.',
            ESCALATION_QUOTE_ADDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat ein Angebot eskaliert.',
            ESCALATION_QUOTE_ADDED_SYSTEM_ACTION: 'Das System hat ein Angebot automatisch eskaliert.',
            ESCALATION_QUOTE_READDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat ein Angebot neu eskaliert.',
            ESCALATION_QUOTE_READDED_SYSTEM_ACTION: 'Das System hat ein Angebot automatisch neu eskaliert.',
            ESCALATION_QUOTE_REMOVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat ein Angebot entfernt.',
            ESCALATION_QUOTE_REMOVED_SYSTEM_ACTION: 'Das System hat ein Angebot automatisch entfernt.',
            ESCALATION_POSTPONED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat die Eskalation verschoben.',
            ESCALATION_PROGRESSED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat die Eskalation weitergeleitet.',
            ESCALATION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> hat die Eskalation abgebrochen.',
            ESCALATION_MESSAGE_ACTION: '<strong>{{ ::message._links["sender"].title }}</strong> hat ein Update zur Verfügung gestellt.',
            DUTYMANAGEMENT: 'Aufgabenmanagement',
            DUTY_MANAGEMENT: 'Aufgabenmanagement',
            DUTY_MANAGEMENT_SITE_RELATION_REMOVED: 'Gebäude-Beziehung wurde entfernt',
            DUTY_MANAGEMENT_SCHEDULE_REMOVED: 'Zeitplan wurde entfernt',
            DUTY_MANAGEMENT_BACK: 'Zurück',
            DUTY_MANAGEMENT_EDIT_USER: 'Benutzer bearbeiten',
            DUTY_MANAGEMENT_SELECT_USER: 'Benutzer auswählen',
            DUTY_MANAGEMENT_SELECT_SITES: 'Gebäude auswählen',
            DUTY_MANAGEMENT_SELECT_DAYS: 'Tage auswählen',
            DUTY_MANAGEMENT_SELECTED_DAYS: 'Ausgewählte Tage',
            DUTY_MANAGEMENT_SELECTED_SITES: 'Ausgewählte Gebäude',
            DUTY_MANAGEMENT_ADD: 'Zeitplan Aufgabenmanagement erstellen',
            DUTY_MANAGEMENT_ALREADY_IN_USE_SITES: 'Gebäude wird bereits verwendet',
            DOWNLOAD_AS_CSV: 'Als CSV herunterladen',
            DOWNLOAD_AS_JSON: 'Als JSON herunterladen',
            DIFF_LOAD: 'Last/Diff.',
            SOURCE: 'Quelle',
            TRANSLATION: 'Übersetzung',
            DOC: 'Doc',
            DIRECTIVES: 'Richtlinien',
            FILTERS: 'Filter',
            EXAMPLES: 'Beispiele',
            PANELS: 'Abdeckungen',
            PANEL_TITLE: 'Titel',
            TABS: 'Register',
            FILE_UPLOAD: 'Datei-Upload',
            LISTS: 'Listen',
            DATE_FORMAT: 'Datumsformat',
            BUTTONS: 'Schaltflächen',
            OPTIONS: 'Optionen',
            KEY: 'Key',
            MONTH: 'Monat',
            YEAR: 'Jahr',
            DAILY: 'Täglich',
            WEEKLY: 'Wöchentlich',
            MONTHLY: 'Monatlich',
            YEARLY: 'Jährlich',
            FIRST: 'Erstens',
            SECOND: 'Zweitens',
            THIRD: 'Drittens',
            FOURTH: 'Viertens',
            LAST: 'Letzte',
            DAY: 'Tag',
            DATE_STARTEDAT: 'Beginnt',
            DATE_EXPIREDAT: 'Verfällt',
            DATE_EXPIRESAT: 'Verfällt',
            DATE_REVIEWEDAT: 'Überprüfen um',
            DATE_TENDEREDAT: 'Angeboten in',
            DATE_CREATEDAT: 'Erstellt in',
            DATE_UPLOADDATE: 'Hochgeladenes Datum',
            DATE_COMPLETIONAT: 'Beendigung fällig in',
            DATE_LAST_READ: 'Gelesen in',
            DATE_ACTIVEAT: 'Aktiv am',
            DATE_INACTIVEAT: 'Inaktiv am',
            TASK_PLANNER_DAILY: '{{interval}}D',
            TASK_PLANNER_WEEKLY: '{{interval}}W',
            TASK_PLANNER_MONTHLY: '{{interval}}M',
            TASK_PLANNER_ANNUALLY: '{{interval}}Y',
            RELATIVE_STARTDATE: 'Relatives Anfangsdatum',
            MONDAY: 'Montag',
            TUESDAY: 'Dienstag',
            WEDNESDAY: 'Mittwoch',
            THURSDAY: 'Donnerstag',
            FRIDAY: 'Freitag',
            SATURDAY: 'Samstag',
            SUNDAY: 'Sonntag',
            BYDAY_MONDAY: 'Montag',
            BYDAY_TUESDAY: 'Dienstag',
            BYDAY_WEDNESDAY: 'Mittwoch',
            BYDAY_THURSDAY: 'Donnerstag',
            BYDAY_FRIDAY: 'Freitag',
            BYDAY_SATURDAY: 'Samstag',
            BYDAY_SUNDAY: 'Sonntag',
            BYDAY_MONDAY_FIRST: 'Erster Montag im Monat',
            BYDAY_TUESDAY_FIRST: 'Erster Dienstag im Monat',
            BYDAY_WEDNESDAY_FIRST: 'Erster Mittwoch im Monat',
            BYDAY_THURSDAY_FIRST: 'Erster Donnerstag im Monat',
            BYDAY_FRIDAY_FIRST: 'Erster Freitag im Monat',
            BYDAY_SATURDAY_FIRST: 'Erster Samstag im Monat',
            BYDAY_SUNDAY_FIRST: 'Erster Sonntag im Monat',
            BYDAY_MONDAY_LAST: 'Letzter Montag im Monat',
            BYDAY_TUESDAY_LAST: 'Letzter Dienstag im Monat',
            BYDAY_WEDNESDAY_LAST: 'Letzter Mittwoch im Monat',
            BYDAY_THURSDAY_LAST: 'Letzter Donnerstag im Monat',
            BYDAY_FRIDAY_LAST: 'Letzter Freitag im Monat',
            BYDAY_SATURDAY_LAST: 'Letzter Samstag im Monat',
            BYDAY_SUNDAY_LAST: 'Letzter Sonntag im Monat',
            SECONDS: 'Sekunden',
            MINUTES: 'Minuten',
            HOURS: 'Stunden',
            DAYS: 'Tage',
            WEEKS: 'Wochen',
            YEARS: 'Jahre',
            NUMBER_MINUTES: '{{ minutes }} Minute(n)',
            JANUARY: 'Januar',
            FEBRUARY: 'Februar',
            MARCH: 'März',
            APRIL: 'April',
            MAY: 'Mai',
            JUNE: 'Juni',
            JULY: 'Juli',
            AUGUST: 'August',
            SEPTEMBER: 'September',
            OCTOBER: 'Oktober',
            NOVEMBER: 'November',
            DECEMBER: 'Dezember',
            JAN: 'Jan',
            FEB: 'Feb',
            MAR: 'Mär',
            APR: 'Apr',
            JUN: 'Jun',
            JUL: 'Jul',
            AUG: 'Aug',
            SEP: 'Sep',
            OCT: 'Okt',
            NOV: 'Nov',
            DEC: 'Dez',
            CAL_JANUARY: 'JANUAR',
            CAL_FEBRUARY: 'FEBRUAR',
            CAL_MARCH: 'MÄRZ',
            CAL_APRIL: 'APRIL',
            CAL_MAY: 'MAI',
            CAL_JUNE: 'JUNI',
            CAL_JULY: 'JULI',
            CAL_AUGUST: 'AUGUST',
            CAL_SEPTEMBER: 'SEPTEMBER',
            CAL_OCTOBER: 'OKTOBER',
            CAL_NOVEMBER: 'NOVEMBER',
            CAL_DECEMBER: 'DEZEMBER',
            CAL_SUNDAY: 'Sonntag',
            CAL_MONDAY: 'Montag',
            CAL_TUESDAY: 'Dienstag',
            CAL_WEDNESDAY: 'Mittwoch',
            CAL_THURSDAY: 'Donnerstag',
            CAL_FRIDAY: 'Freitag',
            CAL_SATURDAY: 'Samstag',
            CAL_SUNDAY_SHORT: 'So',
            CAL_MONDAY_SHORT: 'Mo',
            CAL_TUESDAY_SHORT: 'Di',
            CAL_WEDNESDAY_SHORT: 'Mi',
            CAL_THURSDAY_SHORT: 'Do',
            CAL_FRIDAY_SHORT: 'Fr',
            CAL_SATURDAY_SHORT: 'Sa',
            EXPIRESAT: 'Läuft ab in',
            DATE_SHORT: 'tt/mm/jjjj',
            MONTH_YEAR: 'mm/jjjj',
            UPDATES_DUE: 'Updates fällig',
            REACTIVE_UPDATES_DUE: 'Reaktive Aufträge',
            PLANNED_UPDATES_DUE: 'Geplante Aufträge',
            QUOTE_UPDATES_DUE: 'Angebote',
            SITES_WITH_PPM_REQUIRING_APPROVAL: 'Gebäude mit PPM erfordert Genehmigung',
            AWAITING_CLIENT_REVIEW: 'Warten auf Kundenüberprüfung',
            P2P_JOBS: 'P2P-Aufträge',
            P2P_AWAITING_VALUE: 'Warten auf Wert',
            P2P_AWAITING_PO_NUMBER: 'Warten auf Auftragsnummer',
            JOB_REQUISITES_REQUIRING_APPROVAL: 'Auftragsanforderungen erfordern Genehmigung',
            JOB_VALUES: 'Auftragswerte',
            JOB_VALUES_PENDING_APPROVAL: 'Warten auf die Genehmigung',
            JOB_VALUE_REQUIRED: 'Wert erforderlich',
            OPEN_ACTIONS_1: 'Maßnahme {{ action_0 }} öffnen',
            OPEN_ACTIONS_2: 'Maßnahmen {{ action_0 }} &amp; {{ action_1 }} öffnen',
            OPEN_ACTIONS_3: 'Maßnahmen {{ action_0 }}, {{ action_1 }} &amp; {{ action_2 }} öffnen',
            OPEN_ACTIONS_4: 'Maßnahmen {{ action_0 }}, {{ action_1 }}, {{ action_2 }} &amp; {{ action_3 }} öffnen',
            OPEN_ACTIONS_5: 'Maßnahmen {{ action_0 }}, {{ action_1 }}, {{ action_2 }}, {{ action_3 }} &amp; {{ action_4 }} öffnen',
            WIDGETS: 'Widgets',
            DASHBOARD_STATISTICS_PERFORMANCE_STATISTICS: 'Leistungsstatistik',
            DASHBOARD_STATISTICS_CONTRACT_TIMELINES: 'Vertragsfristen',
            DASHBOARD_STATISTICS_CONTRACT_RENEWALS: 'Vertragsverlängerungen',
            DASHBOARD_STATISTICS_CONTRACT_APPROVALS: 'Vertragsgenehmigungen',
            DASHBOARD_STATISTICS_OPEN_ACTIONS: 'Maßnahmen öffnen',
            DASHBOARD_STATISTICS_JOB_REQUISITES_REQUIRING_APPROVAL: 'Auftragsanforderungen erfordern Genehmigung',
            DASHBOARD_STATISTICS_OPEN_INTEGRATION_ACTIONS: 'Integrationsmaßnahmen öffnen',
            DASHBOARD_STATISTICS_P_2_P_JOBS: 'P2P-Aufträge',
            DASHBOARD_STATISTICS_SURVEYS: 'Herausragende KPI-Umfragen',
            DASHBOARD_STATISTICS_APPROVALS_REQUIRED: 'Ausstehende Genehmigungen',
            DASHBOARD_STATISTICS_PENDING_ACKNOWLEDGEMENT: 'Bestätigung ausstehend',
            DASHBOARD_STATISTICS_UPDATES_DUE: 'Updates fällig',
            DASHBOARD_STATISTICS_AWAITING_CLIENT_REVIEW: 'Warten auf Kundenüberprüfung',
            DASHBOARD_STATISTICS_PLANNED_OVERDUE_COMPLETION: 'Geplante Aufträge Beendigung überfällig',
            DASHBOARD_STATISTICS_REACTIVE_OVERDUE_COMPLETION: 'Reaktive Aufträge Beendigung überfällig',
            DASHBOARD_STATISTICS_REACTIVE_OVERDUE_ATTENDANCE: 'Reaktive Aufträge Teilnahme überfällig',
            DASHBOARD_STATISTICS_PPM_REQUIRING_APPROVAL: 'Genehmigung erfordernde geplante Aufträge',
            DASHBOARD_STATISTICS_ESCALATIONS: 'Eskalationen',
            DASHBOARD_STATISTICS_JOB_VALUES: 'Auftragswerte',
            CURRENCY_NONE: '',
            CURRENCY_GBP: '£',
            CURRENCY_EUR: '€',
            CORE_CLIENT: 'Kernkunde',
            CORE_CLIENTS: 'Kernkunden',
            CORE_CLIENT_INFORMATION: 'Information Kernkunde',
            ADD_NEW_CORE_CLIENT: 'Einen neuen Kernkunden hinzufügen',
            EDIT_CORE_CLIENT: 'Kernkunde bearbeiten',
            CORE_CLIENT_LIST: 'Kernkunden',
            CORE_CLIENT_ADD: 'Einen neuen Kernkunden hinzufügen',
            CORE_CLIENT_EDIT: 'Kernkunde bearbeiten',
            CORE_CLIENT_CREATED: 'Kernkunde wurde erfolgreich erstellt',
            CORE_CLIENT_UPDATED: 'Kernkunde wurde erfolgreich aktualisiert',
            CORE_CLIENT_DELETED: 'Kernkunde wurde erfolgreich gelöscht',
            CONTRACT: 'Vertrag',
            CONTRACTS: 'Verträge',
            CONTRACTS_LIST: 'Verträge',
            CONTRACT_AVAILABLE: 'Vertrag verfügbar?',
            CONTRACT_BOTH: 'Beide (Elektronisch und Hardcopy)',
            CONTRACT_ELECTRONIC: 'Elektronisch',
            CONTRACT_FORMAT: 'Vertragsformat',
            CONTRACT_HARDCOPY: 'Hardcopy',
            CONTRACT_TYPE: 'Vertragsart',
            NOTICE_PERIOD: 'Kündigungsfrist',
            SCOPE_AVAILABLE: 'Umfang verfügbar?',
            BILLING_FREQUENCY: 'Abrechnungsfrequenz',
            BILLING_PRACTICE: 'Abrechnungspraxis',
            BILLING_PRACTICE_IN_ADVANCE: 'Im Voraus',
            BILLING_PRACTICE_IN_ARREARS: 'In Verzug',
            CONTRACT_CREATED: 'Vertrag erstellt',
            CONTRACT_SUBMITTED_AND_CREATED: 'Vertrag erstellt und zur Genehmigung eingereicht',
            CONTRACT_SUBMITTED_AND_UPDATED: 'Vertrag aktualisiert und zur Genehmigung eingereicht',
            PENDING: 'Ausstehend',
            ACCEPTED: 'Akzeptiert',
            REJECTED: 'Abgelehnt',
            CONTRACT_APPROVAL_REQUESTED: 'Vertragsanforderung',
            CONTRACTS_ADD: 'Vertrag hinzufügen',
            CONTRACT_ADD: 'Vertrag hinzufügen',
            CONTRACT_EDIT: 'Vertrag bearbeiten',
            CONTRACT_RENEW: 'Vertrag verlängern',
            CONTRACT_UPDATED: 'Vertrag aktualisiert',
            CONTRACT_VALUE: 'Wert',
            LAST_TENDERED: 'Zuletzt angeboten',
            REVIEW_REMINDER: 'Erinnerung überprüfen',
            EXPIRY_REMINDER: 'Verfällt Erinnerung',
            CONTRACT_TYPE_CLIENT: 'Kunde',
            CONTRACT_TYPE_CONSULTANT: 'Berater',
            CONTRACT_TYPE_FRAMEWORK: 'Framework',
            CONTRACT_TYPE_PSA: 'PSA',
            CONTRACT_TYPE_SUPPLIER: 'Lieferant',
            CONTRACT_TYPE_OTHER: 'Sonstiges',
            CONTRACT_DETAILS_WITH_ID: 'Vertrag #{{ ::id }} Details',
            CONTRACT_DETAILS: 'Vertragsdetails',
            CONTRACT_STATUS: 'Status',
            CONTRACT_STATUS_DRAFT: 'Entwurf',
            CONTRACT_STATUS_APPROVAL_PENDING: 'Ausstehend zur Genehmigung',
            CONTRACT_STATUS_RENEWAL_APPROVAL_PENDING: 'Verlängerungsgenehmigung ausstehend',
            CONTRACT_STATUS_RENEWAL_APPROVAL_REJECTED: 'Abgelehnte Verlängerung',
            CONTRACT_STATUS_CANCELLED: 'Abgebrochen',
            CONTRACT_STATUS_TERMINATED: 'Beendet',
            CONTRACT_STATUS_ACTIVE: 'Aktiv',
            CONTRACT_STATUS_APPROVAL_REJECTED: 'Abgelehnt',
            CONTRACT_STATUS_DRAFT_IN_RENEWAL: 'Entwurf in Verlängerung',
            CONTRACT_STATUS_EXPIRED: 'Abgelaufen',
            TERMINATE: 'Beenden',
            RENEW: 'Verlängern',
            REGRESS: 'Rücktritt',
            IS_PDF: 'PDF Vertragskopie',
            IS_CONTRACT: 'Ist Vertrag',
            CONTRACT_WORKFLOW_SUBMIT: 'Einreichen',
            CONTRACT_WORKFLOW_SUBMIT_SUBMIT: 'Einreichen',
            CONTRACT_SUBMIT: 'Vertrag einreichen',
            CONTRACT_WORKFLOW_SUBMIT_SUCCESS: 'Vertrag eingereicht',
            CONTRACT_WORKFLOW_CANCEL: 'Abbrechen',
            CONTRACT_WORKFLOW_CANCEL_SUBMIT: 'Abbrechen',
            CONTRACT_CANCEL: 'Vertrag annullieren',
            CONTRACT_WORKFLOW_CANCEL_SUCCESS: 'Vertrag annulliert',
            CONTRACT_WORKFLOW_TERMINATE: 'Beenden',
            CONTRACT_WORKFLOW_TERMINATE_SUBMIT: 'Beenden',
            CONTRACT_TERMINATE: 'Vertrag beendigen',
            CONTRACT_WORKFLOW_TERMINATE_SUCCESS: 'Vertrag beendet',
            CONTRACT_WORKFLOW_APPROVE: 'Genehmigen',
            CONTRACT_WORKFLOW_APPROVE_SUBMIT: 'Genehmigen',
            CONTRACT_APPROVE: 'Vertrag genehmigen',
            CONTRACT_WORKFLOW_APPROVE_SUCCESS: 'Vertrag genehmigt',
            CONTRACT_WORKFLOW_REJECT: 'Ablehnen',
            CONTRACT_WORKFLOW_REJECT_SUBMIT: 'Ablehnen',
            CONTRACT_REJECT: 'Vertrag ablehnen',
            CONTRACT_WORKFLOW_REJECT_SUCCESS: 'Vertrag abgelehnt',
            CONTRACT_WORKFLOW_REGRESS: 'Rücktritt',
            CONTRACT_WORKFLOW_REGRESS_SUBMIT: 'Rücktritt',
            CONTRACT_REGRESS: 'Vom Vertrag zurücktreten',
            CONTRACT_WORKFLOW_REGRESS_SUCCESS: 'Rücktritt abgelehnt',
            CONTRACT_APPROVER_REQUIRED_BUT_NO_APPROVERS: 'Eine Vertragsgenehmigung ist erforderlich, aber dem Gebäude sind derzeit keine Vertragsgenehmigenden zugewiesen',
            TERMINATED_AT: 'Beendet in',
            BEFOREPERIOD: 'Erinnerung (in Tagen)',
            ASSOCIATETYPES: 'Typen Gebäudepartner',
            REVIEW: 'ÜBERPRÜFUNG',
            EXPIRY: 'ABLAUF',
            REVIEW_IN: 'Überprüfen in',
            EXPIRY_IN: 'Verfällt in',
            CONTRACT_SETTINGS_REVIEW_VIEW: 'Überprüfungseinstellungen',
            CONTRACT_SETTINGS_EXPIRY_VIEW: 'Ablaufeinstellungen',
            REVIEW_SETTINGS_TYPE_REVIEW_ADD: 'Neue Überprüfungseinstellung hinzufügen',
            REVIEW_SETTINGS_TYPE_EXPIRY_ADD: 'Neue Ablaufeinstellung hinzufügen',
            REVIEW_SETTINGS_TYPE_REVIEW_EDIT: 'Neue Überprüfungseinstellung bearbeiten',
            REVIEW_SETTINGS_TYPE_EXPIRY_EDIT: 'Ablaufeinstellung bearbeiten',
            REVIEW_SETTING_CREATED: 'Überprüfungseinstellung erstellt',
            EXPIRY_SETTING_CREATED: 'Ablaufeinstellung erstellt',
            REVIEW_SETTING_REMOVED: 'Überprüfungseinstellung entfernt',
            EXPIRY_SETTING_REMOVED: 'Ablaufeinstellung entfernt',
            REVIEW_SETTING_UPDATED: 'Überprüfungseinstellung aktualisiert',
            EXPIRY_SETTING_UPDATED: 'Ablaufeinstellung aktualisiert',
            REVIEW_SETTINGS_REORDERED: 'Überprüfungseinstellungen neu geordnet',
            EXPIRY_SETTINGS_REORDERED: 'Ablaufeinstellungen neu geordnet',
            CONTRACT_CHASE_SETTINGS_TYPE2_ADD: 'Abgelaufener Vertrag Verfolgung hinzufügen',
            CONTRACT_CHASE_SETTINGS_TYPE3_ADD: 'Vertragsgenehmigung Verfolgung hinzufügen',
            CONTRACT_CHASE_SETTINGS_TYPE2_EDIT: 'Abgelaufener Vertrag Verfolgung bearbeiten',
            CONTRACT_CHASE_SETTINGS_TYPE3_EDIT: 'Vertragsgenehmigung Verfolgung bearbeiten',
            CONTRACT_APPROVALS: 'Vertragsgenehmigungen',
            CONTRACT_RENEWALS: 'Vertragsverlängerungen',
            CONTRACT_TIMELINES: 'Vertragsfristen',
            AWAITING_APPROVAL: 'Warten auf die Genehmigung',
            AWAITING_RENEWAL_APPROVAL: 'Warten auf Genehmigung zur Verlängerung',
            RENEWAL_REJECTED: 'Verlängerung abgelehnt',
            APPROVAL_REJECTED: 'Genehmigung abgelehnt',
            APPROACHING_REVIEW: 'Bevorstehende Überprüfung',
            APPROACHING_EXPIRY: 'Bevorstehender Ablauf',
            OVERDUE_APPROVAL: 'Genehmigung überfällig',
            CONTRACT_DOCUMENT: 'Vertragsdokument',
            CONTRACT_DOCUMENT_NAME: 'Name des Vertragsdokuments',
            VERSIONS: 'Versionen',
            COMPARE_VERSIONS_LIST: 'Versionen vergleichen',
            AUDIT_TRAIL: 'Audit Trail',
            VERSION_ID: 'Version ID',
            COMPARE: 'COMPARE',
            CURRENT_CONTRACT_VERSION: 'Aktuelle Vertragsversion',
            CONTRACT_VERSION_WITH_ID: 'Vertragsversion #{{ ::id }}',
            CONTRACT_FILE_VIEW: 'Vertragsdatei',
            CONTRACT_SUPPORT_FILES_VIEW: 'Vertragsunterstützende Dateien',
            CONTRACT_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> hat den Vertrag erstellt',
            CONTRACT_SUBMITTED_ACTION: '<strong>{{ event._links["user"].title }}</strong> hat den Vertrag eingereicht',
            CONTRACT_CANCELLED_ACTION: '<strong>{{ event._links["user"].title }}</strong> hat den Vertrag storniert',
            CONTRACT_TERMINATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> hat den Vertrag beendet',
            CONTRACT_APPROVAL_REJECTED_ACTION: '<strong>{{ event._links["user"].title }}</strong> hat den Vertrag abgelehnt',
            CONTRACT_REGRESSED_ACTION: '<strong>{{ event._links["user"].title }}</strong> ist vom Vertrag zurückgetreten',
            CONTRACT_APPROVAL_ACCEPTED_ACTION: '<strong>{{ event._links["user"].title }}</strong> hat den Vertrag genehmigt',
            CONTRACT_UPDATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> hat den Vertrag aktualisiert',
            CONTRACT_RENEWED_DRAFT_ACTION: '<strong>{{ event._links["user"].title }}</strong> hat den Vertrag verlängert',
            CONTRACT_RENEWED_APPROVED_ACTION: '<strong>{{ event._links["user"].title }}</strong> hat die Vertragsverlängerung genehmigt',
            CONTRACT_RENEWED_REJECTED_ACTION: '<strong>{{ event._links["user"].title }}</strong> hat die Vertragsverlängerung abgelehnt',
            CONTRACT_RENEWED_SUBMITTED_ACTION: '<strong>{{ event._links["user"].title }}</strong> hat die Vertragsverlängerung eingereicht',
            CONTRACT_EXPIRED_SYSTEM_ACTION: 'Das System ließ den Vertrag automatisch ablaufen',
            CONTRACT_OPERATION_APPROVAL_CHASED_SYSTEM_ACTION: 'Genehmigung zur Verfolgung von Benachrichtigung #{{ ::event.chaseSequence + 1 }} wurde gesendet',
            CONTRACT_OPERATION_RENEWAL_APPROVAL_CHASED_SYSTEM_ACTION: 'Verlängerung der Genehmigungsverfolgung von Benachrichtigung #{{ ::event.chaseSequence + 1 }} wurde gesendet',
            CONTRACT_OPERATION_EXPIRE_CHASED_SYSTEM_ACTION: 'Ablauf der Verfolgung von Benachrichtigung #{{ ::event.chaseSequence + 1 }} wurde gesendet',
            CONTRACT_REVIEW_NOTIFICATION_SYSTEM_ACTION: '<strong>{{ event.daysBefore }} Tag</strong> Überprüfungsbenachrichtigung wurde gesendet',
            CONTRACT_EXPIRY_NOTIFICATION_SYSTEM_ACTION: '<strong>{{ event.daysBefore }} Tag</strong> Ablaufbenachrichtigung wurde gesendet',
            CHARGE_TYPE: 'Ladevorgangstyp',
            CHARGE_TYPES: 'Ladevorgangstypen',
            CHARGE_TYPE_INFORMATION: 'Information zum Ladevorgangstyp',
            ADD_NEW_CHARGE_TYPE: 'Einen neuen Ladevorgangstyp hinzufügen',
            EDIT_CHARGE_TYPE: 'Ladevorgangstyp bearbeiten',
            CHARGE_TYPE_LIST: 'Ladevorgangstypen',
            CHARGE_TYPE_ADD: 'Einen neuen Ladevorgangstyp hinzufügen',
            CHARGE_TYPE_EDIT: 'Ladevorgangstyp bearbeiten',
            CHARGE_TYPE_CREATED: 'Ladevorgangstyp wurde erfolgreich erstellt',
            CHARGE_TYPE_UPDATED: 'Ladevorgangstyp wurde erfolgreich aktualisiert',
            CHARGE_TYPE_DELETED: 'Ladevorgangstyp wurde erfolgreich gelöscht',
            CHARGE_TYPE_REQUIRED: 'Ladevorgangstyp ist erforderlich',
            CERTIFICATE_TYPES: 'Zertifikatstypen',
            CERTIFICATE_TYPE: 'Zertifikatstyp',
            CERTIFICATE_TYPE_LIST: 'Zertifikatstypen',
            CERTIFICATE_TYPE_ADD: 'Einen neuen Zertifikatstyp hinzufügen',
            CERTIFICATE_TYPE_EDIT: 'Zertifikatstyp bearbeiten',
            CERTIFICATE_TYPE_CREATED: 'Zertifikatstyp erstellt',
            CERTIFICATE_TYPE_UPDATED: 'Zertifikatstyp aktualisiert',
            CERTIFICATE_TYPE_DELETED: 'Zertifikatstyp gelöscht',
            BULK_ACTIONS: 'Massenmaßnahmen',
            BULK_ACTION_DETAILS_LIST: 'Details zur Massenmaßnahme',
            BULK_ACTION_ERROR_MESSAGE: 'Benachrichtigung',
            BULK_ACTION_FAILED: 'Fehlgeschlagen',
            BULK_ACTION_LOG_ALL: 'Alle',
            BULK_ACTION_LOG_CHANGE: 'Erfolg',
            BULK_ACTION_LOG_ERROR: 'Fehler',
            BULK_ACTION_LOG_NO_CHANGE: 'Keine Änderung',
            BULK_ACTION_LOG_STATUS: 'Status',
            BULK_ACTION_LOG_STATUS_DONE: 'Erledigt',
            BULK_ACTION_LOG_STATUS_PENDING: 'Ausstehend',
            BULK_ACTION_LOG_TYPE: 'Protokolltyp',
            BULK_ACTION_LOGS: 'Protokolle zur Massenmaßnahme',
            BULK_ACTION_MAX_FILTERED_ITEMS_EXCEEDED: 'Massenmaßnahme nicht erstellt - max. gefilterte Aufträge überschritten',
            BULK_ACTION_MAX_ITEMS: 'Massenmaßnahme nicht erstellt - max. ausgewählte Aufträge überschritten',
            BULK_ACTION_MUST_ONLY_BE_FILTERED_OR_SELECTED_JOBS: 'Massenmaßnahme nicht erstellt - Ausgewählte und gefilterte Aufträge können nicht gleichzeitig neu zugewiesen werden',
            BULK_ACTION_NO_JOBS: 'Massenmaßnahme nicht erstellt - Keine Aufträge ausgewählt',
            BULK_ACTION_REASSIGN_USER_REQUIRED: 'Maßnahme Massenneuzuweisung - Benutzer erforderlich',
            BULK_ACTION_REASSIGN_SERVICE_PROVIDER_REQUIRED: 'Massenneuzuweisung - Dienstleister erforderlich',
            BULK_ACTION_NO_JOBS_FOUND_THAT_MATCH_FILTERS: 'Massenmaßnahme nicht erstellt - Keine Aufträge gefunden, die den gefilterten Kriterien entsprechen',
            BULK_ACTION_SUCCESSFUL: 'Erfolgreich',
            BULK_ACTIONS_LIST: 'Liste Massenmaßnahmen',
            BULKACTIONS: 'Massenmaßnahmen',
            CHANGE_OPERATIVE: 'Arbeiter ändern',
            STATUS_PAUSED: 'In Bearbeitung (Pausiert)',
            STATUS_INITIALISING: 'Aufträge initialisieren',
            BULK_ACTION_NOTE_REQUIRED: 'Hinweisfeld ist erforderlich',
            BULK_ACTION_REASSIGN: 'Neu zuweisen',
            BULK_ACTION_CANCEL: 'Abbrechen',
            BULK_ACTION_REASSIGN_OPERATIVES: 'Arbeiter neu zuweisen',
            BULK_ACTION_CANCEL_TASKS: 'Von Aufgaben abbrechen',
            BUILDING: 'Gebäude &amp;',
            BUILDINGS: 'Gebäude',
            ADD_NEW_BUILDING: 'Eine neues Gebäude hinzufügen',
            EDIT_BUILDING: 'Gebäude bearbeiten',
            BUILDING_CREATED: 'Gebäude wurde erfolgreich erstellt',
            BUILDING_UPDATED: 'Gebäude wurde erfolgreich aktualisiert',
            BUILDING_DELETED: 'Gebäude gelöscht',
            NO_BUILDING_RECORDS: 'Keine Gebäudeberichte',
            BUDDY: 'Freund',
            BUDDY_ADD: 'Freund hinzufügen',
            BUDDY_EDIT: 'Freund bearbeiten',
            BUDDY_UPDATED: 'Freund aktualisiert',
            BUDDIES: 'Freunde',
            BUDDIES_NO_RESULTS: 'Keine Freunde',
            BUDDYING_FOR: 'Freunde für',
            BUDDY_SELECT_SITES: 'Ausgewählte Gebäude',
            BUDDY_SITE_RELATION_REMOVED: 'Gebäude entfernt',
            BUDDY_REMOVED: 'Freund entfernt',
            BUDDY_ALREADY_IN_USE_SITES: 'Bereits verwendete Gebäude:',
            BUDDY_CONFLICTS: 'Es gibt Konflikte mit anderen Gebäuden',
            BUDDY_DEACTIVATED: 'Freund deaktiviert',
            MY_BUDDY: 'Mein Freund',
            SCHEDULE: 'Zeitplan',
            MY_BUDDIES: 'Meine Freunde',
            NO_BUDDIES_FOUND: 'Kein Freund festgelegt',
            BUDDY_BETWEEN: 'Freund zwischen',
            ASSIGNED_BY: 'Zugewiesen von',
            BUDDY_ADD_SUCCESS: 'Freund hinzugefügt',
            BUDDY_SITE_ADDED: 'Gebäude hinzugefügt',
            SITES_EMPTY: 'Sites können nicht leer sein',
            BILLING_TYPES: 'Abrechnungsarten',
            BILLING_TYPE: 'Abrechnungsart',
            BILLING_TYPES_LIST: 'Abrechnungsarten',
            BILLING_TYPES_ADD: 'Neue Abrechnungsart hinzufügen',
            BILLING_TYPES_EDIT: 'Abrechnungsart bearbeiten',
            BILLING_TYPE_UPDATED: 'Abrechnungsart aktualisiert',
            BILLING_TYPE_CREATED: 'Abrechnungsart erstellt',
            BILLING_TYPE_DELETED: 'Abrechnungsart gelöscht',
            BILLING_TYPE_INFORMATION: 'Information zur Abrechnungsart',
            BILLING_COST: 'Abrechnungskosten',
            AUDIT: 'Audit',
            AUDITS: 'Audits',
            AUDIT_LIST: 'Audits-Liste',
            TEMPLATE_LIST: 'Liste Audit-Vorlagen',
            TEMPLATE_EDIT: 'Audit-Vorlage bearbeiten',
            SCHEDULE_LIST: 'Liste Audit-Zeitplan',
            AUDIT_ADD: 'Ein neues Audit hinzufügen',
            TEMPLATE_ADD: 'Eine neue Audit-Vorlage hinzufügen',
            SCHEDULE_ADD: 'Einen neuen Audit-Zeitplan hinzufügen',
            QUESTIONS: 'Fragen',
            QUESTION: 'Frage',
            AUDIT_SUB_SECTIONS: 'Unter-Abschnitte',
            AUDIT_QUESTIONS: 'Fragen',
            START_AUDIT: 'Audit starten',
            COMPLETE_AUDIT: 'Audit abschließen',
            EDIT_AUDIT_REFERENCE: 'Referenz bearbeiten',
            AUDIT_CONFIRM_START_INSTRUCTIONS: 'Anfangsdatum bestätigen',
            NOT_ALL_QUESTIONS_ANSWERED_FORCE_COMPLETE: 'Nicht alle Fragen beantwortet, bestätigen Sie, warum Sie das Audit abschließen',
            AUDIT_FORCE_COMPLETE_REASON: 'Audit vollständigen Grund forcieren',
            QUESTIONS_ANSWERED: 'Anzahl von Fragen (beantwortet)',
            NUMBER_OF_QUESTIONS: 'Anzahl von Fragen',
            ANSWERED_QUESTIONS: 'Beantwortete Fragen',
            NUMBER_OF_SECTIONS: 'Anzahl Abschnitte',
            NUMBER_OF_SUB_SECTIONS: 'Anzahl Unter-Abschnitte',
            DEFAULT_BUTTON_OPTIONS: 'Standard-Schaltflächenoptionen',
            NOT_YET_ANSWERED: 'Nicht beantwortet',
            CONFIRM_ANSWER_CHOICE: 'Antwortauswahl bestätigen',
            AUDIT_ANSWER_EXTRA_INFORMATION: 'Optionale Zusatzinformationen',
            AUDIT_ANSWER_OPTIONS_NO_DEFAULT: 'Keine Standardeinstellungen, manuell eingestellt',
            AUDIT_ANSWER_OPTIONS_NO_YES: 'Nein(0%), Ja(100%)',
            AUDIT_ANSWER_OPTIONS_YES_NO: 'Ja(0%), Nein(100%)',
            ANSWER_OPTION_ADDED: 'Antwortoption hinzugefügt',
            ANSWER_OPTION_UPDATED: 'Antwortoption aktualisiert',
            ANSWER_OPTION_VIEW: 'Details Antwortoption',
            ANSWER_OPTION_TITLE_MAX_10_CHARACTERS: 'Der Titel der Schaltfläche darf höchstens 10 Zeichen lang sein.',
            AUDIT_ASSISTED_COMMENTS: 'Unterstützte Kommentare',
            SCORE: 'Ergebnis',
            AUDIT_CREATED: 'Erstellt',
            AUDIT_STARTED: 'In Bearbeitung',
            AUDIT_DRAFT: 'Im Entwurf',
            AUDIT_CANCELLED: 'Abgebrochen',
            AUDIT_COMPLETED: 'Abgeschlossen',
            AUDIT_TEMPLATES: 'Vorlagen',
            AUDIT_TEMPLATE: 'Audit-Vorlage',
            AUDIT_TEMPLATE_ACTIVE: 'Aktive Vorlage',
            AUDIT_TEMPLATE_INACTIVE: 'Inaktive Vorlage',
            AUDIT_SCHEDULE_ACTIVE: 'Aktiver Zeitplan',
            AUDIT_SCHEDULE_CREATED: 'Audit-Zeitplan erstellt',
            AUDIT_SCHEDULE_INACTIVE: 'Inaktiver Zeitplan',
            AUDIT_TEMPLATE_CREATED: 'Audit-Vorlage erstellt',
            AUDIT_TEMPLATE_SAVE: 'Als Vorlage speichern',
            AUDIT_COPY_TEMPLATE: 'Vorlage kopieren',
            AUDIT_TEMPLATE_SUMMARY: 'Zusammenfassung der Vorlage',
            AUDIT_TEMPLATE_STATUS: 'Vorlagenstatus',
            AUDIT_TEMPLATE_VER: 'Vorlagenversion',
            AUDIT_TEMPLATE_CREATE_VERSION: 'Neue Version erstellen',
            AUDIT_TEMPLATE_NEW_VERSION_CREATED: 'Neue Version erstellt',
            AUDIT_TEMPLATE_ACTIVATE_VERSION: 'Version aktivieren',
            AUDIT_TEMPLATE_VERSION_ACTIVATED: 'Aktivierte Version',
            AUDIT_TEMPLATE_DEACTIVATE_VERSION: 'Version deaktivieren',
            AUDIT_TEMPLATE_VERSION_DEACTIVATED: 'Deaktivierte Version',
            AUDIT_ANSWER_OPTIONS: 'Optionen beantworten',
            OPTION_LIST: 'Optionen beantworten',
            OPTION_ADD: 'Neue Antwortoption hinzugefügt',
            AUDIT_SCHEDULES: 'Zeitpläne',
            NO_OPERATIVES_FOUND: 'Keine primären Audit-Mitarbeiter gefunden',
            QUESTION_TYPE: 'Fragen-Typ',
            COMMENT_TYPE: 'Nur Kommentar',
            BUTTONS_TYPE: 'Schaltfläche Optionen',
            OPTIONS_TYPE: 'Drop-down-Optionen',
            ATTACHMENT_REQUIRED: 'Anhang erforderlich',
            WEIGHTING: 'Gewichtung',
            CREATE_AS_TEMPLATE: 'Als Vorlage erstellen',
            CREATE_FROM_TEMPLATE: 'Von Vorlage erstellen',
            INSTRUCTION: 'Anweisung',
            AUDIT_SECTION: 'Abschnitt',
            BLANK_AUDIT_FORM_PDF: 'Leere PDF erzeugen',
            BLANK_AUDIT_FORM_PDF_QUEUED: 'Leere PDF in Warteschlange',
            PDF_REPORT: 'PDF-Bericht erzeugen',
            PDF: 'PDF-Export',
            ACTIVATE_AUDIT: 'Aktivieren',
            CANCEL_AUDIT: 'Abbrechen',
            AUDIT_SECTIONS: 'Abschnitte',
            AUDIT_EDIT: 'Audit bearbeiten',
            SCHEDULE_EDIT: 'Audit-Zeitplan bearbeiten',
            AUDIT_UPDATED: 'Audit aktualisiert',
            AUDIT_DELETED: 'Audit gelöscht',
            AUDIT_CONFIRM_ACTIVATE_INSTRUCTIONS: 'Sind Sie sicher?',
            AUDIT_CONFIRM_CANCEL_INSTRUCTIONS: 'Bitte unterstützenden Text eingeben',
            AUDIT_CANCEL_REASON: 'Grund für die Aufhebung',
            ADD_SECTION: 'Abschnitt hinzufügen',
            EDIT_SECTION: 'Abschnitt bearbeiten',
            SECTION: 'Abschnitt',
            ADD_SUB_SECTION: 'Unterabschnitt hinzufügen',
            ADD_QUESTION: 'Frage hinzufügen',
            SECTIONS: 'Abschnitte',
            AUDIT_SECTION_UPDATED: 'Audit-Abschnitt aktualisiert',
            AUDIT_UPDATED_TO_CANCELLED: 'Audit abgebrochen',
            AUDIT_HAS_NO_ASSIGNED_OPERATIVE: 'Audit hat keinen zugewiesenen Mitarbeiter',
            AUDIT_HAS_NO_ASSIGNED_SERVICE_PROVIDER: 'Audit hat keinen zugewiesenen Dienstleister',
            AUDIT_HAS_NO_QUESTIONS: 'Audit hat keine Fragen',
            AUDIT_SECTION_CREATED: 'Audit-Abschnitt erstellt',
            AUDIT_SECTION_DELETED: 'Audit-Abschnitt gelöscht',
            AUDIT_QUESTION_CREATED: 'Audit-Frage erstellt',
            AUDIT_QUESTION_UPDATED: 'Audit-Frage aktualisiert',
            AUDIT_QUESTION_DELETED: 'Audit-Frage gelöscht',
            AUDIT_UPDATED_TO_ACTIVATED: 'Audit aktiviert',
            ADD_AUDIT_QUESTION: 'Frage hinzufügen',
            EDIT_AUDIT_QUESTION: 'Frage bearbeiten',
            EDIT_AUDIT_TEMPLATE: 'Antwortoption bearbeiten',
            ADD_AUDIT_TEMPLATE: 'Antwortoption hinzufügen',
            AUDIT_QUESTION_COMMENT: 'Kommentar',
            AUDIT_QUESTION_OPTIONS: 'Optionen',
            AUDIT_QUESTION_BUTTONS: 'Schaltflächen',
            ANSWER: 'Antwort',
            ANSWER_OPTIONS: 'Optionen beantworten',
            POINTS_VALUE: 'Punkte',
            AUDIT_SECTION_ORDER_UPDATED: 'Reihenfolge des Audit-Abschnitts aktualisiert',
            AUDIT_QUESTION_ORDER_UPDATED: 'Reihenfolge der Auditfragen aktualisiert',
            AUDIT_IMAGE: 'Audit-Bild',
            CREATE_AS_AUDIT_SCHEDULE: 'Neuen Zeitplan erstellen',
            SCHEDULE_START_AT: 'Zeitplan Start in',
            FORCE_COMPLETE: 'Forcieren vollständig',
            AUDIT_COMPLETE_DATE: 'Audit abgeschlossen in',
            RESET_SECTIONS: 'Bestellung zurücksetzen',
            RESET_QUESTIONS: 'Bestellung zurücksetzen',
            RESET_ANSWER_OPTIONS_ORDER: 'Reihenfolge der Optionen zurücksetzen',
            ANSWER_OPTION_EDIT: 'Antwortoption bearbeiten',
            OUT_OF: '/',
            DATE_STARTED_AT: 'Gestartet bei',
            ANSWER_COMMENT: 'Kommentar',
            REVERT: 'Rückgängig machen',
            QUESTION_ANSWERED: 'Antwort eingereicht',
            QUESTION_REVERTED: 'Antwort rückgängig gemacht',
            ANSWER_OPTION: 'Antwortoption',
            SELECTED_ANSWER: 'Ausgewählte Antwort',
            ANSWER_SCORE: 'Antwortergebnis',
            ANSWERED: 'Beantwortet',
            SHOW_ANSWER: 'Anzeigen',
            HIDE_ANSWER: 'Ausblenden',
            AUDIT_INSTRUCTION: 'Audit-Anweisung',
            AUDIT_UPDATED_TO_COMPLETED: 'Audit abgeschlossen',
            FORCE_COMPLETED: 'Abschlusshinweis',
            ADD_ANSWER_OPTION: 'Antwortoption hinzufügen',
            AUDIT_ANSWER_OPTION_CREATED: 'Antwortoption erstellt',
            ANSWER_OPTION_DELETED: 'Antwortoption gelöscht',
            ANSWER_OTPION_UPDATED: 'Antwortoption aktualisiert',
            AUDIT_ANSWER_OPTIONS_ORDER_UPDATED: 'Reihenfolge der Audit-Antwortoptionen aktualisiert',
            AUDIT_UPDATED_TO_STARTED: 'Audit begonnen',
            AUDIT_AT_LEAST_ONE_SECTION_HAS_NO_QUESTIONS: 'Audit kann nicht aktiviert werden, mindestens ein Abschnitt hat keine Fragen',
            AUDIT_AT_LEAST_ONE_QUESTION_HAS_NO_ANSWER_OPTIONS: 'Mindestens eine Frage hat keine Antwortoptionen',
            NOT_ALL_QUESTIONS_ANSWERED: 'Nicht alle Audit-Fragen beantwortet',
            NO_PERMISSION_TO_FORCE_COMPLETE: 'Keine Berechtigung, ein vollständiges Audit zu erzwingen',
            NO_PERMISSION_TO_EDIT_AUDIT_REFERENCE: 'Keine Berechtigung, die Audit-Referenz zu bearbeiten',
            EDIT_SUB_SECTION: 'Unterabschnitt bearbeiten',
            ANSWER_BELOW_100_REQUIRES_PRIORITY: 'Priorität erforderlich',
            AUDIT_QUESTION_RAISE_ACTION_SELECT_PRIORITY: 'Sie haben eine Antwort von unter 100% gewählt, so dass eine Maßnahme ausgelöst wird, wählen Sie eine Priorität für diese Maßnahme',
            DATE_CANCELLED: 'Datum aufgehoben',
            CANCEL_REASON: 'Grund Stornierung',
            SERVICE_PROVIDER_NO_MATCH: 'Es gibt keine Übereinstimmung zwischen dem Dienstanbieter und dem von Ihnen gewählten Gebäude',
            SITES_REQUIRED: 'Gebäude/Gebäude sind erforderlich',
            CANNOT_CHANGE_FROM_PUBLIC: 'Kein Wechsel von öffentlich zu privat möglich',
            DUPLICATE_ANSWER_OPTION: 'Duplikate sind nicht zulässig',
            AUDITS_DASHBOARD: 'Audits-Dashboard (Bedienfeld)',
            AUDITS_RAISED_IN_LAST_60_DAYS: 'In den letzten 60 Tagen erhobene Audits',
            AUDITS_RECENTLY_COMPLETED: 'Kürzlich abgeschlossene Audits',
            AUDITS_NEXT: 'Nächste anstehende Audits',
            AUDITS_NEXT_SCHEDULED: 'Nächste geplante Audits',
            AUDITS_GENERATED_FOR_DATE: 'Generiert für Datum',
            AUDITS_GENERATED_FOR_DATE_START: 'Generiert für Start-Datum',
            AUDITS_GENERATED_FOR_DATE_END: 'Generiert für End-Datum',
            AUDITS_SCHEDULES: 'Audit-Zeitpläne',
            AUDITS_SCHEDULE: 'Zeitplan',
            FORCE_RAISE: 'Erhebung forcieren',
            FORCE_RAISE_CHOICE: 'Bitte erheben Sie einen Auftrag oder eine Maßnahme',
            REQUIRE_ACTION: 'Maßnahme erforderlich',
            REQUIRE_EITHER: 'Erforderlich entweder',
            RAISE_ACTION: 'Maßnahme erheben',
            ACTION_RAISED: 'Maßnahme erhoben',
            REQUIRE_JOB: 'Auftrag erforderlich',
            ACTION_HAS_QUESTION: 'Für diese Frage gibt es bereits eine Maßnahme',
            NO_PERMISSION_TO_RAISE_JOB: 'Sie haben keine Berechtigung, einen Auftrag zu erteilen, die Frage kann nicht beantwortet werden',
            AUDIT_OPEN_ACTION: 'Audit-Maßnahmen öffnen',
            ANSWER_UPDATED: 'Antwort aktualisiert',
            NEXT_OCCURENCE: 'Nächstes Vorkommen',
            NEXT_OCCURENCE_START: 'Start Nächstes Vorkommen',
            NEXT_OCCURENCE_END: 'Ende Nächstes Vorkommen',
            AUDIT_TEMPLATE_VERSION: 'Version',
            DISABLED_SUMMARY_FIELD: 'Die Zusammenfassung kann nicht bearbeitet werden, da die Vorlage aktiviert ist',
            COPY_FROM_ANSWER_OPTIONS: 'Von Antwortoptionen kopieren',
            SCHEDULE_NAME: 'Name des Zeitplans',
            TEMPLATE_LINK: 'Link zu Vorlage',
            SCHEDULE_INVALID_INVALID_LENGTH: 'Name des Zeitplans ist zu lang. Darf höchstens 100 Zeichen lang sein.',
            ASSET: 'Asset',
            ASSETS: 'Assets',
            ASSET_LIST: 'Assets',
            ASSET_ADD: 'Ein neues Asset hinzufügen',
            ASSET_ENTER: 'Ein Asset eingeben',
            SUB_ASSET_ENTER: 'Ein Sub-Asset eingeben',
            ASSET_EDIT: 'Asset bearbeiten...',
            ASSET_CREATED: 'Asset erstellt',
            ASSET_UPDATED: 'Asset aktualisiert',
            ASSET_DELETED: 'Asset gelöscht',
            ASSET_IMPORTS: 'Asset-Importe',
            MANAGE_ASSETS: 'Assets verwalten',
            SUB_ASSET: 'Sub-Asset',
            SUB_ASSETS: 'Sub-Assets',
            SUB_ASSET_LIST: 'Sub-Assets',
            SUB_ASSET_ADD: 'Ein neues Sub-Asset hinzufügen',
            SUB_ASSET_CREATED: 'Sub-Asset erstellt',
            SUB_ASSET_UPDATED: 'Sub-Asset aktualisiert',
            SUB_ASSET_DELETED: 'Sub-Asset gelöscht',
            SUB_ASSET_EDIT: 'Sub-Asset bearbeitet',
            SUB_ASSETS_WITH_COUNT: 'Sub-Assets ({{ ::count || 0 | number:0 }})',
            BACK_TO_SUB_ASSETS: 'Zurück zu Sub-Assets',
            MAKE: 'Fabrikat',
            MODEL: 'Modell',
            SERIAL: 'Seriell',
            BUSINESS_CONTINUITY_RISK: 'Geschäftskontinuität',
            REPLACEMENT_COST_LABOUR: 'Wiederbeschaffungskosten Arbeit',
            REPLACEMENT_COST_MATERIALS: 'Wiederbeschaffungskosten Material',
            END_OF_LIFE: 'Ende der Lebensdauer',
            CHARGEABLE: 'Aufladbar',
            ASSET_TYPE: 'Asset-Typ',
            NUMBER: 'Nummer',
            IS_STATUTORY: 'Gesetzestyp?',
            ASSET_HISTORICAL_SCORES: 'Historische Ergebnisse',
            ASSET_HISTORICAL_SCORES_LIST: 'Historische Ergebnisse',
            COMPLETED_BY: 'Fertig gestellt durch',
            FREQUENCY_CANT_BE_DAILY: 'Ausgewählte Service-Routine enthält tägliche Frequenz. Bitte ändern oder entfernen',
            ASSET_TYPES: 'Asset-Typen',
            ADD_NEW_ASSET_TYPE: 'Einen neuen Asset-Typ hinzufügen',
            EDIT_ASSET_TYPE: 'Asset-Typ bearbeiten',
            ASSET_TYPE_LIST: 'Asset-Typen',
            ASSET_TYPE_ADD: 'Einen neuen Asset-Typ hinzufügen',
            ASSET_TYPE_EDIT: 'Asset-Typ bearbeiten',
            ASSET_TYPE_CREATED: 'Asset-Typ erstellt',
            ASSET_TYPE_UPDATED: 'Asset-Typ aktualisiert',
            ASSET_TYPE_DELETED: 'Asset-Typ gelöscht',
            ASSET_TYPE_ADDED: 'Asset-Typ hinzugefügt',
            ASSET_SCORE_TYPE: 'Typ Asset-Ergebnis',
            ASSET_SCORE_TYPES: 'Typen Asset-Ergebnis',
            ASSET_SCORE_TYPE_ADD: 'Einen neuen Typ Asset-Ergebnis hinzufügen',
            ASSET_SCORE_TYPE_LIST: 'Typ Asset-Ergebnis',
            ASSET_SCORE_TYPE_VIEW: 'Info',
            ASSET_SCORE_TYPE_EDIT: 'Typ Asset-Ergebnis bearbeiten',
            ASSET_SCORE_TYPE_CREATED: 'Typ Asset-Ergebnis erstellt',
            ASSET_SCORE_TYPE_UPDATED: 'Typ Asset-Ergebnis aktualisiert',
            ASSET_SCORE_TYPE_DELETED: 'Typ Asset-Ergebnis gelöscht',
            ASSET_SCORE_TYPE_ADDED: 'Typ Asset-Ergebnis hinzugefügt',
            RANGE_WEIGHT: 'Bereichsgewicht',
            RANGE_LOWER_BETTER: 'Niedriger ist besser',
            RANGE_HIGHER_BETTER: 'Höher ist besser',
            COLOUR_FIELD_MUST_BE_IN_HEX_FORMAT: 'Farbfeld muss im Hex-Format sein',
            ASSET_SCORE_TYPE_OPTION: 'Optionen Typ Asset-Ergebnis',
            ASSET_SCORE_TYPE_OPTION_LIST: 'Optionen Typ Asset-Ergebnis',
            ASSET_SCORE_TYPE_OPTION_EDIT: 'Optionen Typ Asset-Ergebnis bearbeiten',
            ASSET_SCORE_TYPE_OPTION_ADD: 'Eine neue Option Typ Asset-Ergebnis hinzufügen',
            ASSET_SCORE_TYPE_OPTION_CREATED: 'Option Typ Asset-Ergebnis erstellt',
            ASSET_SCORE_TYPE_OPTION_UPDATED: 'Option Typ Asset-Ergebnis aktualisiert',
            ASSET_SCORE_TYPE_OPTION_DELETED: 'Option Typ Asset-Ergebnis gelöscht',
            ADD_ASSET_SCORES: 'Asset-Ergebnisse hinzufügen',
            UNABLE_TO_DELETE_SCORE_TYPE: 'Es ist nicht möglich, den Ergebnistyp während des Gebrauchs zu löschen',
            UNABLE_TO_EDIT_SCORE_TYPE: 'Es ist nicht möglich, den Ergebnistyp während des Gebrauchs zu bearbeiten',
            UNABLE_TO_DELETE_SCORE_TYPE_OPTION: 'Es ist nicht möglich, die Option Ergebnistyp während des Gebrauchs zu löschen',
            UNABLE_TO_EDIT_SCORE_TYPE_OPTION: 'Es ist nicht möglich, die Option Ergebnistyp während des Gebrauchs zu bearbeiten',
            APPROVER: 'Genehmigender',
            APPROVERS: 'Genehmigende',
            APPROVED_BY: 'Genehmigt von',
            APPROVERS_UPDATED: 'Genehmigende aktualisiert',
            APPROVAL_REQUIRED: 'Genehmigung erforderlich',
            APPROVER_REQUIRED: 'Ein Genehmigender sollte zu dieser Liste hinzugefügt werden, da eine Genehmigung erforderlich ist',
            APPROVER_NOT_ADDED: 'Noch keine Genehmigenden hinzugefügt',
            APPROVER_ADDED_TO_ALL_APPROVER_TYPES: 'Genehmigender zu allen Genehmigendertypen für diesen Gebäude hinzugefügt',
            APPROVER_REMOVED_FROM_ALL_APPROVER_TYPES: 'Genehmigender von allen Genehmigendertypen für diesen Gebäude entfernt',
            USER_ALREADY_APPROVER: 'Benutzer ist bereits als Genehmigender für diesen Typ für diesen Gebäude zugewiesen',
            ADD_REMOVE_USER_ALL_APPROVER_TYPES: 'Benutzer zu allen Genehmigendertypen hinzufügen oder entfernen',
            REACTIVE_JOB_APPROVER: 'Genehmigenden reaktivieren',
            REACTIVE_JOB_APPROVERS: 'Reaktiver Auftrag',
            REACTIVE_JOB_EXTENSIONS_APPROVERS: 'Auftragserweiterungen reaktivieren',
            PLANNED_JOB_APPROVER: 'Genehmigender für Planer',
            PLANNED_JOB_APPROVERS: 'PPM',
            PLANNED_JOB_EXTENSIONS_APPROVERS: 'PPM-Erweiterungen',
            JOB_EXTENSION_APPROVER: 'Genehmigender Auftragserweiterung',
            JOB_EXTENSION_APPROVERS: 'Auftragserweiterung',
            QUOTE_APPROVER: 'Genehmigender Angebot',
            QUOTE_APPROVERS: 'Angebot',
            CONTRACT_APPROVER: 'Genehmigender Vertrag',
            CONTRACT_APPROVERS: 'Vertrag',
            SURVEY_APPROVER: 'Genehmigender Umfrage',
            SURVEY_APPROVERS: 'Umfrage',
            EXTENSION_APPROVER: 'Genehmigender Erweiterung',
            APPROVER_ADDED_BUT_NOT_REQUIRED: 'Es wurde ein Genehmigender hinzugefügt, aber die Option Genehmigung erforderlich wurde nicht angekreuzt.',
            SITE_APPROVER_LIST: 'Genehmiger Gebäude',
            ACTION: 'Maßnahme',
            ACTIONS: 'Maßnahmen',
            ACTION_LIST: 'Maßnahmen',
            ACTION_ADD: 'Eine neue Maßnahme hinzufügen',
            ACTION_EDIT: 'Maßnahme bearbeiten',
            CANNOT_CREATE_ACTION_SITE_ASSOCIATE_NOT_CORRECTLY_DEFINED: 'Maßnahme kann nicht erstellt werden, Gebäudepartner falsch definiert',
            ACTION_CREATED: 'Maßnahme erstellt',
            ACTION_UPDATED: 'Maßnahme aktualisiert',
            ACTION_DELETED: 'Maßnahme gelöscht',
            ACTIONS_OPEN: 'Maßnahmen öffnen',
            ACTION_STATUS_OPEN: 'Offen',
            ACTION_STATUS_COMPLETED: 'Abgeschlossen',
            ACTION_STATUS_CANCELLED: 'Abgebrochen',
            ACTION_STATUS_PAPERWORK_REJECTED: 'Unterlagen abgelehnt',
            ACTION_ADD_SITE: 'Gebäude',
            ACTION_ADD_CONTACT_DETAILS: 'Kontaktdaten',
            ACTION_ADD_DETAILS: 'Maßnahmendetails',
            ACTION_ID: 'Maßnahme {{ id }}',
            ACTION_DETAILS: 'Maßnahmendetails',
            ACTION_STATUS: 'Status',
            ACTION_CHANGE_ASSIGNEE_TO_ME: 'Abtretungsempfänger werden',
            DATE_DUE_START: 'Datum fällig Start',
            DATE_DUE_END: 'Datum fällig Ende',
            PAPERWORK_FOR_CLIENT_REVIEW: 'Unterlagen zur Überprüfung durch den Kunden',
            ESCALATED_TO_CLIENT: 'An den Kunden eskaliert',
            EXTERNAL_SYSTEM: 'Externes System',
            FROM_AUDIT: 'Von Audit',
            FROM_AUDIT_QUESTION: 'Von Audit-Frage',
            ACTION_SUMMARY: 'Maßnahmen-Zusammenfassung',
            JOB_COMPLETION_DATE: 'Auftrag Abschlussdatum',
            ACTION_ASSIGNEE_TYPE: 'Maßnahme Typ Abtretungsempfänger',
            ACTION_WORKFLOW_REJECT_JOB_PAPERWORK: 'Unterlagen ablehnen',
            ACTION_WORKFLOW_REJECT_JOB_PAPERWORK_SUBMIT: 'Unterlagen ablehnen',
            ACTION_WORKFLOW_CANCEL: 'Abbrechen',
            ACTION_WORKFLOW_COMPLETE: 'Abschließen',
            ACTION_WORKFLOW_REOPEN: 'Erneut eröffnen',
            ACTION_WORKFLOW_REASSIGN: 'Neu zuweisen',
            ACTION_WORKFLOW_REJECT_SUBMIT: 'Ablehnen',
            ACTION_WORKFLOW_CANCEL_SUBMIT: 'Einreichen',
            ACTION_WORKFLOW_COMPLETE_SUBMIT: 'Abschließen',
            ACTION_WORKFLOW_REOPEN_SUBMIT: 'Erneut eröffnen',
            ACTION_WORKFLOW_REASSIGN_SUBMIT: 'Neu zuweisen',
            ACTION_WORKFLOW_CANCEL_SUCCESS: 'Maßnahme abgebrochen',
            ACTION_WORKFLOW_COMPLETE_SUCCESS: 'Maßnahme abgeschlossen',
            ACTION_WORKFLOW_REOPEN_SUCCESS: 'Maßnahme erneut geöffnet',
            ACTION_WORKFLOW_REASSIGN_SUCCESS: 'Maßnahme neu zugewiesen',
            ACTION_WORKFLOW_REJECT_JOB_PAPERWORK_SUCCESS: 'Unterlagen abgelehnt',
            ACTION_TYPE: 'Maßnahmentyp',
            ACTION_TYPES: 'Maßnahmentypen',
            ACTION_TYPE_LIST: 'Maßnahmentypen',
            ACTION_TYPE_ADD: 'Einen neuen Maßnahmentyp hinzufügen',
            ACTION_TYPE_EDIT: 'Maßnahmentyp bearbeiten',
            ACTION_TYPE_CREATED: 'Maßnahmentyp erstellt',
            ACTION_TYPE_UPDATED: 'Maßnahmentyp aktualisiert',
            ACTION_TYPE_DELETED: 'Maßnahmentyp gelöscht',
            CANNOT_USE_ACTION_TYPE_RESERVED_NAME: 'Der reservierte Name des Maßnahmentyps kann nicht verwendet werden',
            ACTION_FILE_SENT_ACTION: '<strong>{{ event._links["user"].title }}</strong> hat eine Datei an Riskwise gesendet',
            ACTION_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> hat die Maßnahme erstellt',
            ACTION_COMPLETED_ACTION: '<strong>{{ event._links["user"].title }}</strong> hat die Maßnahme abgeschlossen',
            ACTION_CANCELLED_ACTION: '<strong>{{ event._links["user"].title }}</strong> hat die Maßnahme storniert',
            ACTION_REOPENED_ACTION: '<strong>{{ event._links["user"].title }}</strong> hat die Maßnahme erneut geöffnet',
            ACTION_REASSIGNED_ACTION: '<strong>{{ event._links["user"].title }}</strong> hat die Maßnahme neu zugewiesen an <strong>{{ event._links["assignee"].title }}</strong>',
            ACTION_JOB_PAPERWORK_REJECTED_ACTION: '<strong>{{ event._links["user"].title }}</strong> hat die Auftragsunterlagen abgelehnt',
            ACTION_MESSAGE_ACTION: '<strong>{{ message._links["sender"].title }}</strong> hat ein Update zur Verfügung gestellt',
            ACTION_FILE_ADDED: '<strong>{{ file._links["author"].title }}</strong> hat eine Datei hinzugefügt',
            ACTION_JOB_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> hat einen Auftrag erstellt',
            ACTION_QUOTE_REQUEST_CREATED_ACTION: '<strong>{{ event._links["user"].title }}</strong> hat eine Angebotsanforderung erstellt',
            ACTION_CANCELLED_SYSTEM_ACTION: 'Das System hat die Maßnahme automatisch abgebrochen.',
            LOG_OUT: 'Abmelden',
            YOUR_DASHBOARD: 'Ihr Dashboard (Bedienfeld)',
            LOGGED_IN_AS: 'Angemeldet als <strong>{{::name}}</strong>',
            RETURN_TO_USER: 'Zum Benutzer zurücksenden <strong>{{::name}}</strong>',
            LOGIN: 'Anmelden',
            RESET_PASSWORD: 'Kennwort vergessen?',
            FORGOTTEN_USERNAME: 'Benutzername vergessen?',
            PASSWORD_CHANGED: 'Kennwort geändert',
            RESET_PASSWORD_EXPLANATION: 'Geben Sie unten Ihren Benutzernamen ein und wir senden Ihnen per E-Mail einen Link zum Zurücksetzen Ihres Kennworts.',
            FORGOTTEN_USERNAME_EXPLANATION: 'Geben Sie unten Ihre E-Mail-Adresse ein und wir senden Ihnen Ihren Benutzernamen per E-Mail zu.',
            FORGOTTEN_USERNAME_ERROR: 'Sie haben eine ungültige E-Mail-Adresse eingegeben.',
            SETTINGS: 'Einstellungen',
            SETTINGS_DESCRIPTION: 'Systemeinstellungen zur Anpassung Ihrer Elogs CAFM-Installation.',
    });
    }

})();
