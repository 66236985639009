(function() {
    'use strict';

    angular
        .module('elogbooks.user.tasks')
        .config(taskList)
        .config(taskDetails)
        .config(taskCreateProcess)
        .config(taskGeneratePlanner)
        .config(taskViewPlanner)
        .config(registerRoutesForms);

    function taskList($stateProvider) {
        $stateProvider
            .state('dashboard.user.tasks', {
                url: '/tasks',
                abstract: true,
                template: '<bookerize></bookerize>',
                resolve: {
                    siteCollectionResponse: function(apiClient, user, globalFilterParamsService) {
                        var params = {
                            datagroup: 'search'
                        };

                        params = angular.extend({}, params, globalFilterParamsService.getLocalStorageFilters());

                        return apiClient.get(user.getLink('sites'), params, 'long').then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.user.tasks.manage-tasks', {
                url: '/manage-tasks',
                abstract: true,
                parent: 'dashboard.user.tasks',
                views: parseBook({
                    "elogbooks-center@dashboard.user.tasks": {
                        templateUrl: '/modules/user/tasks/manage-tasks/manage-tasks.html',
                        controller: 'UserManageTasksController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    siteResource: function(selectedSiteResponse) {
                        return selectedSiteResponse;
                    },
                    sectionResource: function(user, apiClient, userManager) {
                        return apiClient.get(user.getLink('operatives'), {permission: 'operative_permission_task_user_imports'}).then(function(response) {
                            var canImport = false;
                            if (response) {
                                canImport = response.count;
                            }
                            return {
                                canImport: canImport || userManager.hasPermission('site_permission_task_user_imports')
                            };
                        });
                    },
                    statutoryTypeCollectionResponse: function (rootResourceResponse) {
                        return rootResourceResponse.getResource('statutorytypes');
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"TASKS_MANAGE_TASKS" | translate }}'
                }
            })
            .state('dashboard.user.tasks.manage-tasks.list', {
                url: '/list?page&limit&taskStatus&taskName&{taskServiceProviderName:any}&order&status&taskMasterTask&siteIds&taskServiceType&taskStatutoryType&taskOperativeName&regionId&taskAssetName&taskAssetId&taskFrequency&taskApprovalStatus',
                parent: 'dashboard.user.tasks.manage-tasks',
                params: {
                    page: '1',
                    limit: '30',
                    order: 'site',
                    taskServiceType: null,
                    taskStatus: 'active',
                    regionId: null,
                    taskOperativeName: null,
                    taskAssetId: null,
                    taskServiceProviderName: null,
                    taskFrequency: null,
                    taskApprovalStatus: null,
                    taskMasterTask: null,
                    siteIds: {array: true},
                    taskStatutoryType: {array: true},
                    items: null,
                    taskAssetName: null,
                    taskName: null,
                    documentTypes: {array: true},
                },
                resolve: {
                    documentTypesCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('documenttypes')) {
                            return apiClient.get(rootResourceResponse.getLink('documenttypes'), null, 'long').then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    serviceProviderCollection: function($stateParams, apiClient, user, selectedSiteResponse, globalFilterParamsService) {
                        var params = {};
                        if (selectedSiteResponse) {
                            params.siteId = selectedSiteResponse.id;
                        }

                        params = angular.extend({}, params, globalFilterParamsService.getLocalStorageFilters());

                        return apiClient.get(user.getLink('serviceproviders'), params, 'long').then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    taskCollectionResource: function($stateParams, selectedSiteResponse, apiClient, user, siteResource, base64, globalFilterParamsService) {
                        var params = $stateParams;

                        if (typeof $stateParams.siteIds === 'undefined') {
                            $stateParams.siteIds = [];
                        }

                        if (selectedSiteResponse) {
                            $stateParams.siteIds = [selectedSiteResponse.id];
                            $stateParams.selectedSiteResource = base64.encode(selectedSiteResponse.getLink('self'));
                        }

                        params = angular.extend({}, params, globalFilterParamsService.getLocalStorageFilters());

                        params = angular.extend({}, params, {
                            'siteIds[]': $stateParams.siteIds,
                            'statutoryType[]': $stateParams.taskStatutoryType,
                            'regionId' : $stateParams.regionId ? JSON.parse($stateParams.regionId).v : null,
                            'assetId' : $stateParams.taskAssetId ? JSON.parse($stateParams.taskAssetId).v : null,
                            'assetName': $stateParams.taskAssetName,
                            'name': $stateParams.taskName,
                            'masterTask': $stateParams.taskMasterTask,
                            'approvalStatus': $stateParams.taskApprovalStatus,
                            'serviceType': $stateParams.taskServiceType,
                            'status': $stateParams.taskStatus,
                            'operativeName': $stateParams.taskOperativeName,
                            'serviceProviderName': $stateParams.taskServiceProviderName,
                            'frequency': $stateParams.taskFrequency,
                            'documentTypes[]': $stateParams.documentTypes,
                        });

                        if (siteResource) {
                            return apiClient.getPage(siteResource.getLink('tasks'), params);
                        }

                        return apiClient.get(user.getLink('tasks'), params);
                    }
                },
                views: parseBook({
                    "elogbooks-right@dashboard.user.tasks.manage-tasks": {
                        templateUrl: '/modules/user/tasks/manage-tasks/list/task-list.html',
                        controller: 'UserTaskListController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"TASKS" | translate }}'
                }
            });
    }

    function taskDetails($stateProvider) {
        $stateProvider
            .state('dashboard.user.tasks.manage-tasks.list.details', {
                url: '/details/{taskResource}',
                parent: 'dashboard.user.tasks.manage-tasks.list',
                abstract: true,
                resolve: {
                    taskResponse: function($stateParams, apiClient, base64) {
                        return apiClient.get(base64.decode($stateParams.taskResource), { datagroup: 'planner' });
                    },
                    eventCollectionResponse: function (apiClient, taskResponse) {
                        return apiClient.get(taskResponse.getLink('audit-trail-events'));
                    }
                },
                views: parseBook({
                    'elogbooks-right@dashboard.user.tasks': {
                        templateUrl: '/modules/user/tasks/details/task-details.html',
                        controller: 'UserTaskDetailsController',
                        controllerAs: 'vm'
                    },
                    'feed@dashboard.user.tasks.manage-tasks.list.details': {
                        templateUrl: '/modules/user/tasks/details/event/feed.html',
                        controller: 'CommonTaskDetailsEventFeedController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().taskResponse.name }}'
                }
            })
            .state('dashboard.user.tasks.manage-tasks.list.details.info', {
                url: '/info',
                parent: 'dashboard.user.tasks.manage-tasks.list.details',
                views: parseBook({
                    'task-details': {
                        templateUrl: '/modules/user/tasks/details/info/task-info.html',
                        controller: 'UserTaskInfoController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.user.tasks.manage-tasks.list.details.planner', {
                url: '/planner?year',
                parent: 'dashboard.user.tasks.manage-tasks.list.details',
                views: parseBook({
                    'task-details': {
                        templateUrl: '/modules/user/tasks/details/planner/task-planner.html',
                        controller: 'UserTaskPlannerController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    year: (new Date()).getFullYear().toString(),
                    datagroup: 'details'
                },
                resolve: {
                    siteResource: function(selectedSiteResponse) {
                        return selectedSiteResponse;
                    },
                    serviceProviderCollection: function($stateParams, apiClient, user, selectedSiteResponse) {
                        var params = {};
                        if (selectedSiteResponse) {
                            params.siteId = selectedSiteResponse.id;
                        }

                        return apiClient.get(user.getLink('serviceproviders'), params);
                    },
                    tasksCollectionResource: function(taskResponse) {
                        return {tasks: [taskResponse]};
                    },
                    taskGenerateRequestsCollectionResource: function($stateParams, apiClient, taskResponse) {
                        return apiClient.get(taskResponse.getLink('generationrequests'), $stateParams);
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.user.tasks.manage-tasks.list.details.files', {
                url: '/files',
                parent: 'dashboard.user.tasks.manage-tasks.list.details',
                views: parseBook({
                    'task-details': {
                        templateUrl: '/modules/common/files/files-list.html',
                        controller: 'CommonFilesListController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    filePage: '1',
                    fileLimit: '30',
                    fileOrder: '-createdAt',
                    fileSelectedSiteResource: null,
                    fileTitle: null,
                    fileUploadedBy: null,
                    fileUploadedDateStart: null,
                    fileUploadedDateEnd: null,
                    fileExpiresDateStart: null,
                    fileExpiresDateEnd: null,
                    fileTags: null,
                    fileIsStatutory: null,
                    documentType: null
                },
                resolve: {
                    fileCollectionResponse: function (apiClient, user, siteResponse, taskResponse, $stateParams) {
                        var params = angular.extend({}, $stateParams,
                            {
                                page: $stateParams.filePage,
                                limit: $stateParams.fileLimit,
                                order: $stateParams.fileOrder,
                                title: $stateParams.fileTitle,
                                uploadedBy: $stateParams.fileUploadedBy,
                                uploadedDateStart: $stateParams.fileUploadedDateStart ? new Date($stateParams.fileUploadedDateStart).toISOString() : null,
                                uploadedDateEnd: $stateParams.fileUploadedDateEnd ? new Date($stateParams.fileUploadedDateEnd).toISOString() : null,
                                expiresDateStart: $stateParams.fileExpiresDateStart ? new Date($stateParams.fileExpiresDateStart).toISOString() : null,
                                expiresDateEnd: $stateParams.fileExpiresDateEnd ? new Date($stateParams.fileExpiresDateEnd).toISOString() : null,
                                tags: $stateParams.fileTags,
                                isStatutory: $stateParams.fileIsStatutory,
                                documentType: $stateParams.documentType ? JSON.parse($stateParams.documentType).v : null
                            }
                        );

                        if (siteResponse) {
                            params.site = siteResponse.id;
                        }

                        return apiClient.get(taskResponse.getLink('files'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    tagCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), { limit: 30 }).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {
                            jobId: true,
                            showStatutoryType: false,
                            hideAddButton: true,
                            overrideRoute: 'dashboard.user.files.list.details'
                        };
                    },
                    integrationDocTypesResponse: function()
                    {
                        return null;
                    },
                    documentTypeCollectionResponse : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', order: 'name'})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    jobResponse: function () {
                        return null;
                    },
                    serviceProviderResponse: function () {
                        return null;
                    },
                    operativeCollectionResponse: function () {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"FILES" | translate }}'
                }
            })
            .state('dashboard.user.tasks.manage-tasks.list.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.user.tasks.manage-tasks.list.details.info',
                resolve: {
                    siteResponse: function(apiClient, taskResponse) {
                        return apiClient.get(taskResponse.getLink('site'));
                    },
                    serviceProviderCollectionResponse: function(apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('partnerships'), { active:1, serviceProvidersStatus: 'active' });
                    },
                    locationCollectionResponse: function(apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('locations'));
                    },
                    statutoryTypeCollectionResponse: function(rootResourceResponse) {
                        return rootResourceResponse.getResource('statutorytypes');
                    },
                    assetsCollectionResponse: function(apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('assets'), {active:1}) || apiClient.noResourceFound();
                    },
                    jobGroupCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('jobgroups'), { limit: 999999 }) || apiClient.noResourceFound();
                    },
                    assetSelected: function () {
                        return null;
                    },
                    versionedServiceRoutinesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('serviceroutines'), { active: 1, isVersioned: 1 });
                    },
                    nonVersionedServiceRoutinesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('serviceroutines'), { active: 1, isVersioned: 0 });
                    },
                    hasEvents: function (taskResponse) {
                        return !!taskResponse.events.length;
                    }
                },
                views: parseBook({
                    'elogbooks-right@dashboard.user.tasks': {
                        templateUrl: '/modules/user/tasks/edit/edit.html',
                        controller: 'UserTaskCreateStep2Controller',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.user.tasks.manage-tasks.list.details.planner.generate', {
                url: '/generate',
                parent: 'dashboard.user.tasks.manage-tasks.list.details.planner',
                resolve: {
                    userResponse: function(user) {
                        return user;
                    },
                    siteResource: function(selectedSiteResponse) {
                        return selectedSiteResponse;
                    },
                    tasksCollectionResource: function() {
                        return null;
                    },
                    taskGenerateRequestResource: function() {
                        return null;
                    }
                },
                views: parseBook({
                    'elogbooks-center@dashboard.user.tasks': {
                        templateUrl: '/modules/user/tasks/details/generate/task-generate.html',
                        controller: 'UserTaskGenerateController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"TASK_GENERATE" | translate }}'
                }
            })
            .state('dashboard.user.tasks.manage-tasks.list.details.planner.generate.edit', {
                url: '/generate/{generateRequestResource}',
                parent: 'dashboard.user.tasks.manage-tasks.list.details.planner',
                resolve: {
                    userResponse: function(user) {
                        return user;
                    },
                    siteResource: function(selectedSiteResponse) {
                        return selectedSiteResponse;
                    },
                    tasksCollectionResource: function() {
                        return null;
                    },
                    taskGenerateRequestResource: function($stateParams, apiClient, base64) {
                        return apiClient.get(base64.decode($stateParams.generateRequestResource));
                    }
                },
                views: parseBook({
                    'elogbooks-center@dashboard.user.tasks': {
                        templateUrl: '/modules/user/tasks/details/generate/task-generate.html',
                        controller: 'UserTaskGenerateController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"TASK_GENERATE" | translate }}'
                }
            })
        ;
    }

    function taskCreateProcess($stateProvider) {
        $stateProvider
            .state('dashboard.user.tasks.manage-tasks.list.add', {
                url: '/add',
                parent: 'dashboard.user.tasks.manage-tasks.list',
                resolve: {
                    siteResponse: function(selectedSiteResponse) {
                        return selectedSiteResponse;
                    },
                    siteCollectionResponse: function(user, apiClient) {
                        return apiClient.get(user.getLink('sites'), {
                            permission: ['site_permission_create_tasks'],
                            datagroup: 'search'
                        }, 'long');
                    }
                },
                views: parseBook({
                    'elogbooks-right@dashboard.user.tasks': {
                        templateUrl: '/modules/user/tasks/create-process/step1/site-service.html',
                        controller: 'UserTaskCreateStep1Controller',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.tasks.manage-tasks.list.add.soft', {
                url: '/soft/{siteResource}',
                parent: 'dashboard.user.tasks.manage-tasks.list.add',
                resolve: {
                    siteResponse: function($stateParams, apiClient, base64) {
                        return apiClient.get(base64.decode($stateParams.siteResource));
                    },
                    taskResponse: function() {
                        return {
                            type: 0
                        };
                    },
                    serviceProviderCollectionResponse: function(apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('partnerships'), { active:1, permission: 'create_tasks', serviceProvidersStatus: 'active' });
                    },
                    locationCollectionResponse: function(apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('locations'));
                    },
                    statutoryTypeCollectionResponse: function(rootResourceResponse) {
                        return rootResourceResponse.getResource('statutorytypes');
                    },
                    assetsCollectionResponse: function() {
                        return [];
                    },
                    jobGroupCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('jobgroups'), { limit: 999999 }) || apiClient.noResourceFound();
                    },
                    assetSelected: function () {
                        return null;
                    },
                    versionedServiceRoutinesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('serviceroutines'), { active: 1, isVersioned: 1 });
                    },
                    nonVersionedServiceRoutinesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('serviceroutines'), { active: 1, isVersioned: 0 });
                    },
                    hasEvents: function () { return false; }
                },
                views: parseBook({
                    'elogbooks-right@dashboard.user.tasks': {
                        templateUrl: '/modules/user/tasks/create-process/step2/task-soft.html',
                        controller: 'UserTaskCreateStep2Controller',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.user.tasks.manage-tasks.list.add.hard', {
                url: '/hard/{siteResource}',
                parent: 'dashboard.user.tasks.manage-tasks.list.add',
                resolve: {
                    siteResponse: function($stateParams, apiClient, base64) {
                        return apiClient.get(base64.decode($stateParams.siteResource));
                    },
                    taskResponse: function() {
                        return {
                            type: 1
                        };
                    },
                    serviceProviderCollectionResponse: function(apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('partnerships'), { active:1, permission: 'create_tasks', serviceProvidersStatus: 'active' });
                    },
                    locationCollectionResponse: function(apiClient, siteResponse) {
                        return false;
                    },
                    statutoryTypeCollectionResponse: function(rootResourceResponse) {
                        return rootResourceResponse.getResource('statutorytypes');
                    },
                    assetsCollectionResponse: function(apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('assets'), {active:1}) || apiClient.noResourceFound();
                    },
                    jobGroupCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('jobgroups'), { limit: 999999 }) || apiClient.noResourceFound();
                    },
                    assetSelected: function () {
                        return null;
                    },
                    versionedServiceRoutinesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('serviceroutines'), { active: 1, isVersioned: 1 });
                    },
                    nonVersionedServiceRoutinesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('serviceroutines'), { active: 1, isVersioned: 0 });
                    },
                    hasEvents: function () { return false; }
                },
                views: parseBook({
                    'elogbooks-right@dashboard.user.tasks': {
                        templateUrl: '/modules/user/tasks/create-process/step2/task-hard.html',
                        controller: 'UserTaskCreateStep2Controller',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    skip: true
                }
            })
        ;
    }

    function taskGeneratePlanner($stateProvider) {
        $stateProvider
            .state('dashboard.user.tasks.generate-planner', {
                url: '/generate-planner',
                parent: 'dashboard.user.tasks',
                abstract: true,
                views: parseBook({
                    'elogbooks-center@dashboard.user.tasks': {
                        templateUrl: '/modules/user/tasks/generate-planner/generate-planner.html',
                        controller: 'UserTaskGeneratePlannerController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"TASK_GENERATE_PLANNER" | translate }}'
                },
                resolve: {
                    sectionResource: function(user, apiClient, userManager) {
                        return apiClient.get(user.getLink('operatives'), {permission: 'operative_permission_asset_task_user_imports'}).then(function(response) {
                            var canImport = false;
                            if (response) {
                                canImport = response.count;
                            }
                            return {
                                canImport: canImport || userManager.hasPermission('site_permission_asset_task_user_imports')
                            };
                        });
                    }
                }
            })
            .state('dashboard.user.tasks.generate-planner.awaiting-approval', {
                url: '/awaiting-approval?taskPage&taskLimit&taskYear&taskName&{taskServiceProviderName:any}&taskStatus&taskRequestType&taskMasterTask&siteIds&taskServiceType&taskStatutoryType&taskOperativeName&regionId&taskAssetId&taskFrequency&taskApprovalStatus',
                parent: 'dashboard.user.tasks.generate-planner',
                params: {
                    taskYear: (new Date()).getFullYear().toString(),
                    ppmPlanner: true,
                    taskServiceType: null,
                    taskStatus: 'active',
                    taskRequestType: 'all',
                    regionId: null,
                    taskOperativeName: null,
                    taskAssetId: null,
                    taskServiceProviderName: null,
                    taskFrequency: null,
                    taskApprovalStatus: null,
                    taskMasterTask: null,
                    siteIds: {array: true},
                    taskStatutoryType: {array: true},
                    fromDashboard: null,
                    taskPage: '1',
                    taskLimit: '30',
                    taskOrder: 'site',
                    taskName: null,
                    documentTypes: {array: true},
                },
                resolve: {
                    siteResource: function(selectedSiteResponse, stonlyService) {
                        stonlyService.checkStonly();
                        return selectedSiteResponse;
                    },
                    documentTypesCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('documenttypes')) {
                            return apiClient.get(rootResourceResponse.getLink('documenttypes'), null, 'long').then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    selectedSiteFilterResponse: function($stateParams, rootResourceResponse, apiClient) {
                        if ($stateParams.fromDashboard && $stateParams.siteIds && $stateParams.siteIds.length > 0) {
                            return apiClient.get(rootResourceResponse.getLink('sites') + '/' + $stateParams.siteIds[0]).then(function (response) {
                                return response;
                            });
                        } else {
                            return null;
                        }
                    },
                    serviceProviderCollection: function($stateParams, apiClient, user, selectedSiteResponse) {
                        var params = {};
                        if (selectedSiteResponse) {
                            params.siteId = selectedSiteResponse.id;
                        }

                        return apiClient.get(user.getLink('serviceproviders'), params);
                    },
                    statutoryTypeCollectionResponse: function(rootResourceResponse) {
                        return rootResourceResponse.getResource('statutorytypes');
                    },
                    tasksCollectionResource: function($stateParams, apiClient, user, selectedSiteResponse, base64, globalFilterParamsService) {
                        var params = {};
                        params.datagroup = 'planner';

                        if (typeof $stateParams.siteIds === 'undefined') {
                            $stateParams.siteIds = [];
                        }

                        if (selectedSiteResponse) {
                            $stateParams.siteIds = [selectedSiteResponse.id];
                            $stateParams.selectedSiteResource = base64.encode(selectedSiteResponse.getLink('self'));
                        }

                        if ($stateParams.userId) {
                            params.userId = $stateParams.userId;
                        }

                        if (typeof $stateParams.taskStatutoryType === 'undefined') {
                            $stateParams.taskStatutoryType = [];
                        }
                        params = angular.extend({}, params, {
                            'siteIds[]': $stateParams.siteIds,
                            'statutoryType[]': $stateParams.taskStatutoryType,
                            'regionId' : $stateParams.regionId ? JSON.parse($stateParams.regionId).v : null,
                            'assetId': $stateParams.taskAssetId ? JSON.parse($stateParams.taskAssetId).v : null,
                            'year': $stateParams.taskYear,
                            'ppmPlanner': $stateParams.ppmPlanner,
                            'serviceType': $stateParams.taskServiceType,
                            'status': $stateParams.taskStatus,
                            'taskRequestType': $stateParams.taskRequestType,
                            'operativeName': $stateParams.taskOperativeName,
                            'serviceProviderName': $stateParams.taskServiceProviderName,
                            'frequency': $stateParams.taskFrequency,
                            'approvalStatus': $stateParams.taskApprovalStatus,
                            'fromDashboard': $stateParams.fromDashboard,
                            'page': $stateParams.taskPage,
                            'limit': $stateParams.taskLimit,
                            'order': $stateParams.taskOrder,
                            'name': $stateParams.taskName,
                            'documentTypes[]': $stateParams.documentTypes
                        });

                        if (selectedSiteResponse) {
                            params.siteId = selectedSiteResponse.id;
                        }

                        params = angular.extend({}, params, globalFilterParamsService.getLocalStorageFilters());

                        return apiClient.get(user.getLink('tasks'), params);
                    }
                },
                views: parseBook({
                    'task-generate-planner': {
                        templateUrl: '/modules/user/tasks/generate-planner/awaiting-approval-planner.html',
                        controller: 'UserTaskApprovePlannerController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"TASKS_AWAITING_APPROVAL" | translate }}'
                }
            })
            .state('dashboard.user.tasks.generate-planner.my-planner', {
                url: '/my-planner?taskPage&taskLimit&taskYear&taskName&{taskServiceProviderName:any}&taskStatus&taskMasterTask&siteIds&taskServiceType&taskStatutoryType&taskOperativeName&regionId&taskAssetId&taskFrequency&taskApprovalStatus&taskOrder&draftMode&taskId',
                parent: 'dashboard.user.tasks.generate-planner',
                params: {
                    draftMode: null,
                    taskYear: (new Date()).getFullYear().toString(),
                    taskServiceType: null,
                    taskStatus: 'active',
                    regionId: null,
                    taskOperativeName: null,
                    taskAssetId: null,
                    taskServiceProviderName: null,
                    taskFrequency: null,
                    taskApprovalStatus: null,
                    taskMasterTask: null,
                    siteIds: {array: true},
                    taskStatutoryType: {array: true},
                    taskOrder: 'site',
                    taskName: null,
                    documentTypes: {array: true},
                    fromDashboard: null,
                    taskId: null
                },
                resolve: {
                    siteResource: function(selectedSiteResponse) {
                        return selectedSiteResponse;
                    },
                    documentTypesCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('documenttypes')) {
                            return apiClient.get(rootResourceResponse.getLink('documenttypes'), null, 'long').then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    serviceProviderCollection: function($stateParams, apiClient, user, selectedSiteResponse, globalFilterParamsService) {
                        var params = {};
                        if (selectedSiteResponse) {
                            params.siteId = selectedSiteResponse.id;
                        }

                        if (selectedSiteResponse) {
                            params.siteId = selectedSiteResponse.id;
                        }

                        params = angular.extend({}, params, globalFilterParamsService.getLocalStorageFilters());

                        return apiClient.get(user.getLink('serviceproviders'), params);
                    },
                    tasksCollectionResource: function($stateParams, apiClient, user, selectedSiteResponse, base64, globalFilterParamsService) {
                        var params = {};
                        params['statutoryType[]'] = $stateParams.taskStatutoryType;
                        params.datagroup = 'planner';

                        if (typeof $stateParams.siteIds === 'undefined') {
                            $stateParams.siteIds = [];
                        }

                        if (selectedSiteResponse) {
                            $stateParams.siteIds = [selectedSiteResponse.id];
                            $stateParams.selectedSiteResource = base64.encode(selectedSiteResponse.getLink('self'));
                        }

                        if ($stateParams.userId) {
                            params.userId = $stateParams.userId;
                        }

                        if (typeof $stateParams.taskStatutoryType === 'undefined') {
                            $stateParams.taskStatutoryType = [];
                        }

                        params = angular.extend({}, params, {
                            'siteIds[]': $stateParams.siteIds,
                            'statutoryType[]': $stateParams.taskStatutoryType,
                            'regionId': $stateParams.regionId ? JSON.parse($stateParams.regionId).v : null,
                            'assetId': $stateParams.taskAssetId ? JSON.parse($stateParams.taskAssetId).v : null,
                            'year': $stateParams.taskYear,
                            'draftMode': $stateParams.draftMode,
                            'serviceType': $stateParams.taskServiceType,
                            'status': $stateParams.taskStatus,
                            'operativeName': $stateParams.taskOperativeName,
                            'serviceProviderName': $stateParams.taskServiceProviderName,
                            'frequency': $stateParams.taskFrequency,
                            'approvalStatus': $stateParams.taskApprovalStatus,
                            'masterTask': $stateParams.taskMasterTask,
                            'order': $stateParams.taskOrder,
                            'name': $stateParams.taskName,
                            'page': $stateParams.taskPage,
                            'limit': $stateParams.taskLimit,
                            'documentTypes[]': $stateParams.documentTypes,
                            'id': $stateParams.taskId
                        });

                        if (selectedSiteResponse) {
                            params.siteId = selectedSiteResponse.id;
                        }

                        params = angular.extend({}, params, globalFilterParamsService.getLocalStorageFilters());

                        return apiClient.get(user.getLink('tasks'), params);
                    },
                    taskGenerateRequestsCollectionResource: function($stateParams, apiClient, user) {
                        return [];
                    },
                    statutoryTypeCollectionResource: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('statutorytypes')) {
                            return apiClient.get(rootResourceResponse.getLink('statutorytypes')).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    selectedSiteFilterResponse: function($stateParams, siteCollectionResponse) {
                        return siteCollectionResponse.sites.find(({id}) => id === Number($stateParams.siteIds[0])) || null
                    },
                    config: function (stonlyService) {
                        stonlyService.checkStonly();
                        return {
                            siteLevel: false
                        };
                    }
                },
                views: parseBook({
                    'task-generate-planner': {
                        templateUrl: '/modules/user/tasks/generate-planner/my-planner.html',
                        controller: 'UserTasksPlannerController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"TASKS_MY_PLANNER" | translate }}'
                }
            })
        ;
    }

    function taskViewPlanner($stateProvider) {
        $stateProvider
            .state('dashboard.user.tasks.planner-view', {
                url: '/planner-view?taskPage&taskLimit&taskYear&taskName&{taskServiceProviderName:any}&taskStatus&taskMasterTask&siteIds&taskServiceType&taskStatutoryType&taskOperativeName&regionId&taskAssetId&taskFrequency&taskApprovalStatus&taskOrder&taskApproved',
                parent: 'dashboard.user.tasks',
                params: {
                    taskYear: (new Date()).getFullYear().toString(),
                    taskServiceType: null,
                    taskStatus: 'active',
                    regionId: null,
                    taskOperativeName: null,
                    taskAssetId: null,
                    taskServiceProviderName: null,
                    taskFrequency: null,
                    taskApprovalStatus: null,
                    taskMasterTask: null,
                    siteIds: {array: true},
                    taskStatutoryType: {array: true},
                    taskOrder: 'site',
                    taskApproved: 'true',
                    taskName: null,
                    taskPage: '1',
                    taskLimit: '30',
                    documentTypes: {array: true}
                },
                resolve: {
                    siteResource: function(selectedSiteResponse) {
                        return selectedSiteResponse;
                    },
                    documentTypesCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('documenttypes')) {
                            return apiClient.get(rootResourceResponse.getLink('documenttypes'), null, 'long').then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    serviceProviderCollection: function($stateParams, apiClient, user, selectedSiteResponse) {
                        var params = {};
                        if (selectedSiteResponse) {
                            params.siteId = selectedSiteResponse.id;
                        }

                        return apiClient.get(user.getLink('serviceproviders'), params);
                    },
                    tasksCollectionResource: function($stateParams, apiClient, user, selectedSiteResponse, base64, globalFilterParamsService) {
                        var params = {};
                        params['statutoryType[]'] = $stateParams.taskStatutoryType;
                        params.datagroup = 'planner';

                        if (typeof $stateParams.siteIds === 'undefined') {
                            $stateParams.siteIds = [];
                        }

                        if (selectedSiteResponse) {
                            $stateParams.siteIds = [selectedSiteResponse.id];
                            $stateParams.selectedSiteResource = base64.encode(selectedSiteResponse.getLink('self'));
                        }

                        if ($stateParams.userId) {
                            params.userId = $stateParams.userId;
                        }

                        if (typeof $stateParams.taskStatutoryType === 'undefined') {
                            $stateParams.taskStatutoryType = [];
                        }

                        params = angular.extend({}, params, {
                            'siteIds[]': $stateParams.siteIds,
                            'statutoryType[]': $stateParams.taskStatutoryType,
                            'regionId': $stateParams.regionId ? JSON.parse($stateParams.regionId).v : null,
                            'assetId': $stateParams.taskAssetId ? JSON.parse($stateParams.taskAssetId).v : null,
                            'year': $stateParams.taskYear,
                            'serviceType': $stateParams.taskServiceType,
                            'status': $stateParams.taskStatus,
                            'operativeName': $stateParams.taskOperativeName,
                            'serviceProviderName': $stateParams.taskServiceProviderName,
                            'frequency': $stateParams.taskFrequency,
                            'approvalStatus': $stateParams.taskApprovalStatus,
                            'masterTask': $stateParams.taskMasterTask,
                            'order': $stateParams.taskOrder,
                            'approved': $stateParams.taskApproved,
                            'name': $stateParams.taskName,
                            'page': $stateParams.taskPage,
                            'limit': $stateParams.taskLimit,
                            'documentTypes[]': $stateParams.documentTypes
                        });

                        if (selectedSiteResponse) {
                            params.siteId = selectedSiteResponse.id;
                        }

                        params = angular.extend({}, params, globalFilterParamsService.getLocalStorageFilters());

                        return apiClient.get(user.getLink('tasks'), params);
                    },
                    taskGenerateRequestsCollectionResource: function($stateParams, apiClient, user) {
                        return [];
                    },
                    statutoryTypeCollectionResource: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('statutorytypes')) {
                            return apiClient.get(rootResourceResponse.getLink('statutorytypes')).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    selectedSiteFilterResponse: function($stateParams, siteCollectionResponse) {
                        return siteCollectionResponse.sites.find(({id}) => id === Number($stateParams.siteIds[0])) || null
                    },
                    config: function (stonlyService) {
                        stonlyService.checkStonly();
                        return {
                            siteLevel: false
                        };
                    }
                },
                views: parseBook({
                    "elogbooks-center@dashboard.user.tasks": {
                        templateUrl: '/modules/common/tasks/view-planner/task-planner.html',
                        controller: 'UserTasksPlannerController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"TASK_PLANNER" | translate }}'
                }
            })
        ;
    }


    function registerRoutesForms($stateProvider) {
        $stateProvider
            .state('dashboard.user.tasks.manage-tasks.list.details.forms', {
                url: '/forms',
                parent: 'dashboard.user.tasks.manage-tasks.list.details',
                views: parseBook({
                    'task-details': {
                        templateUrl: '/modules/common/form-associations/list/form-associations.html',
                        controller: 'CommonFormAssociationsController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    formAssociationsPage: '1',
                    formAssociationsLimit: '30',
                },
                resolve: {
                    formAssociationsCollectionResponse: function ($stateParams, apiClient, taskResponse) {
                        var params = {
                            page: $stateParams.formAssociationsPage,
                            limit: $stateParams.formAssociationsLimit,
                        };

                        return apiClient.get(taskResponse.getLink('formassociations'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {
                            type: 'task',
                            noBorder: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"FORMS" | translate }}'
                }
            })
            .state('dashboard.user.tasks.manage-tasks.list.details.forms.add', {
                url: '/add',
                parent: 'dashboard.user.tasks.manage-tasks.list.details.forms',
                views: parseBook({
                    'elogbooks-right@dashboard.user.tasks': {
                        templateUrl: '/modules/common/form-associations/add/form-association.add.html',
                        controller: 'FormAssociationAddController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    formCollectionResponse: function (apiClient, rootResourceResponse, taskResponse) {
                        var params = {
                            type: 'task',
                            faType: 'task',
                            faEntityId: taskResponse.id,
                            faExclude: true,
                            active: true,
                            fvStatus: 1
                        };

                        return apiClient.get(rootResourceResponse.getLink('forms'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function(config, taskResponse) {
                        return angular.extend({}, config, {
                            entityId: taskResponse.id
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}',
                }
            })
            .state('dashboard.user.tasks.manage-tasks.list.details.forms.details', {
                url: '/{formAssociationsResource}',
                parent: 'dashboard.user.tasks.manage-tasks.list.details.forms',
                views: parseBook({
                    'elogbooks-right@dashboard.user.tasks': {
                        templateUrl: '/modules/common/form-associations/details/details.html',
                        controller: 'CommonFormAssociationsDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    formAssociationsResponse: function($stateParams, apiClient) {
                        return apiClient
                            .get($stateParams.formAssociationsResource.decode(), {})
                            .then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    formVersionResponse: function($stateParams, apiClient, formAssociationsResponse){
                        if (formAssociationsResponse.getLink('published-version')) {
                            return apiClient.get(formAssociationsResponse.getLink('published-version'), {}).then(function (response) {
                                return response;
                            });
                        }
                        return {};
                    },
                    assetResponse: function (apiClient, taskResponse) {
                        if (taskResponse.getLink('asset')) {
                            return apiClient.get(taskResponse.getLink('asset'), {active:1}).then(function (response) {
                                return response;
                            });
                        }
                        return {};
                    },
                    associatedEntities: function(taskResponse, assetResponse) {
                        return {
                            task: taskResponse,
                            asset: assetResponse,
                        };
                    },
                    config: function() {
                        return {
                            mode: 'render-preview'
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().formAssociationsResponse.summary }}'
                }
            });
    }
})();
