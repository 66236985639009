angular
    .module('elogbooksDirectives')
    .directive('recurringInterval', [function () {
    return {
        restrict: 'AE',
        scope: {
            model: '=ngModel',
            minified: '@',
            fields: '=?',
        },
        
        templateUrl: '/modules/directives/recurring-interval/recurring-interval.html',
        controller: function ($scope, lodash) {
            if (typeof $scope.model === 'undefined') {
                clearAction();
            }

            // I don't think this is right
            $scope.$parent.criterion.onValueClear = function() {
                clearAction();
            };
       
            function clearAction() {
                // Initialize Model
                lodash.forEach($scope.fields, function(field) {
                    $scope.model[field.modelKey] = null;
                });
                $scope.model.selected = null;
            }
        }
    };
}]);
