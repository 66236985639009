(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbNoResultsFound', ElbNoResultsFound);

    ElbNoResultsFound.$inject = [];

    function ElbNoResultsFound() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                customText: '@'
            },
            templateUrl: '/modules/directives/elb-no-results-found/elb-no-results-found.html',
            link: function (scope, element, attrs, ctrl, transclude) {
                transclude(function (clone) {
                    scope.hasTranscludedContent = clone.length > 0;
                });
            }
        };
    }
})();
