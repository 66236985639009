(function () {
    'use strict';

    angular
        .module('elogbooks.user.manage-operatives')
        .controller('UserManageOperativeLabourController', UserManageOperativeLabourController);

    UserManageOperativeLabourController.$inject = [
        '$stateParams',
        '$state',
        'jobLabourLinesCollectionResponse',
        'operativeCollectionResponse',
        'serviceProviderCollectionResponse',
        'siteCollectionResponse',
        'selectedSiteResponse',
        'jobTypeService'
    ]
        ;

    function UserManageOperativeLabourController (
        $stateParams,
        $state,
        jobLabourLinesCollectionResponse,
        operativeCollectionResponse,
        serviceProviderCollectionResponse,
        siteCollectionResponse,
        selectedSiteResponse,
        jobTypeService
    ) {
        var vm = this;
        vm.jobLabourLinesCollectionResponse = jobLabourLinesCollectionResponse;

        vm.saveParams = saveParams;
        vm.search = search;
        vm.order = orderAction;

        var params = angular.copy($stateParams);

        vm.serviceProviderSelect = {
            response : serviceProviderCollectionResponse,
            link : serviceProviderCollectionResponse ? serviceProviderCollectionResponse.getLink('self') : null,
            itemValuePath: 'id',
            itemTitlePath: 'name',
            responseKeyPath: 'serviceproviders'
        };

        var typeOptions = jobTypeService.getFilterOptions();

        vm.operativeSelect = {
            response : operativeCollectionResponse,
            link : operativeCollectionResponse ? operativeCollectionResponse.getLink('self') : null,
            itemValuePath: '_links.user.id',
            itemTitlePath: '_links.user.name',
            responseKeyPath: 'operatives'
        };

        vm.siteSelect = {
            response : siteCollectionResponse,
            link : siteCollectionResponse.getLink('self'),
            responseKeyPath: 'sites',
            itemValuePath: 'name',
            itemHrefPath: 'id',
            disabled: selectedSiteResponse ? true : false
        };

        if (selectedSiteResponse) {
            vm.siteSelect.singleResult = true;
            vm.siteSelect.selectedItems = [{
                value: selectedSiteResponse.name,
                href: selectedSiteResponse.id,
                object: selectedSiteResponse
            }];
        }

        vm.criteria = {
            dateRange: {
                type: 'date-range',
                title: 'BETWEEN_DATES',
                value: {
                    startDate: ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string') ? moment(new Date($stateParams.dateRange.split(',')[0])) : null,
                    endDate: ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string') ? moment(new Date($stateParams.dateRange.split(',')[1])) : null,
                    endMaxDate: moment()
                },
                clearValue: {
                    startDate: null,
                    endDate: null
                }
            },
            siteIds:{
                    type: 'autocompletemulti',
                    multiple: true,
                    title: 'SITE',
                    value: selectedSiteResponse ? vm.siteSelect.selectedItems?.map((site) => site.object.id) : $stateParams.siteIds,
                    options: vm.siteSelect ? vm.siteSelect : null,
                    param: 'siteIds',
                    max: 50,
                    isClear: $stateParams.siteIds.length !== 0 ? false : true
            },
            operative: { type: 'jsonselectwidget', title: 'OPERATIVE', value: JSON.parse($stateParams.operative), options: vm.operativeSelect },
            serviceProvider: { type: 'jsonselectwidget', title: 'SERVICE_PROVIDER', value: JSON.parse($stateParams.serviceProvider), options: vm.serviceProviderSelect },
            jobsType: { type: 'options', title: 'JOB_TYPE', value: $stateParams.jobsType, options: typeOptions }
        };

        function saveParams (selectModel) {
            if (selectModel.mapValue) {
                if (selectModel.selected === undefined) {
                    params[selectModel.mapValue] = null;
                } else {
                    $stateParams[selectModel.mapValue] = selectModel.selected.object.id;
                }
            }

            vm.search();
        }

        function search(params) {
            var override = {
                labourLinePage: '1'
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function orderAction(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }
    }
})();
