(function () {
    'use strict';

    angular
        .module('elogbooks.admin.surveys')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesLines)
        .config(registerRoutesEdit)
        .config(registerRoutesSites)
        .config(registerRoutesOther);

    function registerRoutes($stateProvider, $urlRouterProvider) {
        $stateProvider
            .state('dashboard.admin.surveys', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_view_surveys', 'user_permission_client_admin_manage_surveys']
                }
            });
    }

    function registerRoutesList($stateProvider) {
        $stateProvider
            .state('dashboard.admin.surveys.list', {
                url: '/surveys?page&limit&order&name&id&status',
                parent: 'dashboard.admin.surveys',
                data: {
                    permissions: ['user_permission_view_surveys', 'user_permission_client_admin_manage_surveys']
                },
                views: {
                    '@dashboard.admin.surveys': {
                        templateUrl: '/modules/admin/surveys/surveys-list.html',
                        controller: 'SurveysController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    surveyCollectionResponse: function (apiClient, $stateParams, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('surveys'), $stateParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',
                    name: null,
                    status: null,
                    id: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SURVEYS" | translate }}'
                }
            });
    }

    function registerRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.admin.surveys.list.add', {
                url: '/add',
                parent: 'dashboard.admin.surveys.list',
                data: {
                    permissions: ['user_permission_manage_surveys', 'user_permission_client_admin_manage_surveys']
                },
                views: {
                    '@dashboard.admin.surveys': {
                        templateUrl: '/modules/admin/surveys/add-edit/survey-form.html',
                        controller: 'SurveyAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    surveyResponse: function () {
                        return {};
                    },
                    surveyStatCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('surveystats')) {
                            return apiClient.get(rootResourceResponse.getLink('surveystats')).then(function (response) {
                                return response || null;
                            });
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetails ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.surveys.list.details', {
                url: '/details/{resource}',
                parent: 'dashboard.admin.surveys.list',
                data: {
                    permissions: ['user_permission_view_surveys', 'user_permission_client_admin_manage_surveys']
                },
                abstract: true,
                views: {
                    '@dashboard.admin.surveys': {
                        templateUrl: '/modules/admin/surveys/details/survey-details.html',
                        controller: 'SurveyDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    surveyResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    sitesResponse: function (apiClient, rootResourceResponse, surveyResponse) {
                        return apiClient.get(surveyResponse.getLink('sites'), { order: ['name'] }).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    surveyLinesResponse: function (apiClient, surveyResponse) {
                        return apiClient.get(surveyResponse.getLink('lines')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    surveyVersionsResponse: function (apiClient, surveyResponse)  {
                        if (typeof surveyResponse._links.versions !== 'undefined') {
                            return apiClient.get(surveyResponse.getLink('versions')).then(function (response) {
                                return response;
                            });
                        } else {
                            return {};
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().surveyResponse.name }}'
                }
            })
            .state('dashboard.admin.surveys.list.details.info', {
                url: '/info',
                parent: 'dashboard.admin.surveys.list.details',
                data: {
                    permissions: ['user_permission_view_surveys', 'user_permission_client_admin_manage_surveys']
                },
                views: {
                    '@dashboard.admin.surveys.list.details': {
                        templateUrl: '/modules/admin/surveys/details/info/survey-info.html',
                        controller: 'SurveyInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            });
    }

    function registerRoutesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.admin.surveys.list.details.info.edit', {
                url: '/edit/{resource}',
                parent: 'dashboard.admin.surveys.list.details.info',
                data: {
                    permissions: ['user_permission_manage_surveys', 'user_permission_client_admin_manage_surveys']
                },
                views: {
                    '@dashboard.admin.surveys': {
                        templateUrl: '/modules/admin/surveys/add-edit/survey-form.html',
                        controller: 'SurveyAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    surveyStatCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('surveystats')) {
                            return apiClient.get(rootResourceResponse.getLink('surveystats')).then(function(response) {
                                return response || null;
                            });
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesLines ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.surveys.list.details.survey-lines', {
                url: '/survey-lines?surveyLinesPage&surveyLinesLimit',
                parent: 'dashboard.admin.surveys.list.details',
                data: {
                    permissions: ['user_permission_view_surveys', 'user_permission_client_admin_manage_surveys']
                },
                views: {
                    '@dashboard.admin.surveys.list.details': {
                        templateUrl: '/modules/admin/surveys/details/lines/survey-lines-list.html',
                        controller: 'SurveyLinesController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    surveyLinesPage: '1',
                    surveyLinesLimit: '30'
                },
                resolve: {
                    surveyLineCollectionResponse: function (apiClient, $stateParams, surveyResponse) {
                        var params = {
                            page: $stateParams.surveyLinesPage,
                            limit: $stateParams.surveyLinesLimit
                        };

                        return apiClient.get(surveyResponse.getLink('lines'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SURVEY_LINES" | translate }}'
                }
            })
            .state('dashboard.admin.surveys.list.details.survey-lines.add', {
                url: '/add',
                parent: 'dashboard.admin.surveys.list.details.survey-lines',
                data: {
                    permissions: ['user_permission_manage_surveys', 'user_permission_client_admin_manage_surveys']
                },
                views: {
                    '@dashboard.admin.surveys': {
                        templateUrl: '/modules/admin/surveys/details/lines/add-edit/survey-line-form.html',
                        controller: 'SurveyLineAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    surveyLineResponse: function () {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.admin.surveys.list.details.survey-lines.edit', {
                url: '/edit/{surveyLineResource}',
                parent: 'dashboard.admin.surveys.list.details.survey-lines',
                data: {
                    permissions: ['user_permission_manage_surveys', 'user_permission_client_admin_manage_surveys']
                },
                views: {
                    '@dashboard.admin.surveys.list.details': {
                        templateUrl: '/modules/admin/surveys/details/lines/add-edit/survey-line-form.html',
                        controller: 'SurveyLineAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    surveyLineResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.surveyLineResource.decode()).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesSites ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.surveys.list.details.survey-sites', {
                url: '/survey-sites',
                parent: 'dashboard.admin.surveys.list.details',
                data: {
                    permissions: ['user_permission_view_surveys', 'user_permission_client_admin_manage_surveys']
                },
                views: {
                    '@dashboard.admin.surveys.list.details': {
                        templateUrl: '/modules/admin/surveys/details/sites/survey-sites-list.html',
                        controller: 'SurveySitesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    SurveySitesCollectionResponse: function (apiClient, $stateParams, surveyResponse) {
                        return apiClient.get(surveyResponse.getLink('sites'), angular.extend({}, $stateParams, {
                            page: $stateParams.sitePage,
                            limit: $stateParams.siteLimit,
                            order: $stateParams.siteOrder
                        })).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                params: {
                    sitePage: '1',
                    siteLimit: '30',
                    siteOrder: 'id',
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SURVEY_SITES" | translate }}'
                }
            })
            .state('dashboard.admin.surveys.list.details.survey-sites.add', {
                url: '/add',
                parent: 'dashboard.admin.surveys.list.details.survey-sites',
                data: {
                    permissions: ['user_permission_manage_surveys', 'user_permission_client_admin_manage_surveys']
                },
                views: {
                    '@dashboard.admin.surveys.list.details': {
                        templateUrl: '/modules/admin/surveys/details/sites/add-edit/survey-site-add-edit.html',
                        controller: 'SurveySiteAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    SurveySitesCollectionResponse: function (apiClient, $stateParams, surveyResponse) {
                        return apiClient.get(surveyResponse.getLink('sites'), $stateParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesOther ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.surveys.list.details.survey-sites.form', {
                url: '/form',
                parent: 'dashboard.admin.surveys.list.details.survey-sites',
                data: {
                    permissions: ['user_permission_manage_surveys', 'user_permission_client_admin_manage_surveys']
                },
                views: {
                    '@dashboard.admin.surveys.list.details': {
                        templateUrl: '/modules/admin/surveys/details/sites/add-edit/survey-site-access-form.html',
                        controller: 'SurveySiteAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    sitesResponse: function ($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('sites'), { order: ['name'] }).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    SurveySitesCollectionResponse: function (apiClient, $stateParams, surveyResponse) {
                        return apiClient.get(surveyResponse.getLink('sites'), $stateParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }
})();
