/**
 * Panel for entities
 *   data-add-hide : With no data - hide the add button, otherwise evaluates the expression passed
 */
angular.module('elogbooksDirectives').directive('panelEntity', ['$http', 'apiClient', '$state', '$filter', 'lodash', '$q', 'confirmationModal', function($http, apiClient, $state, $filter, lodash, $q, confirmationModal) {
    return {
        restrict: 'AE',
        replace: true,
        transclude: true,
        scope: true,
        templateUrl: '/modules/directives/layout/panel-entity/panel-entity.html',
        link: function($scope, $element, $attrs, $ctrl) {
            $scope.panel = {
                type: $attrs.type || 'addedit',
                title: $attrs.title || null,
                icon: $attrs.icon,
                noBorder: lodash.get($attrs, 'noBorder') ? $scope.$eval($attrs.noBorder) : lodash.has($attrs, 'noBorder'),
                titleNoBorder: ((lodash.has($attrs, 'titleNoBorder') && lodash.get($attrs, 'titleNoBorder', 'true') !== 'false') ? 'hide' : false),
                entity: $scope.$eval($attrs.entity) || $scope.$parent.vm.data,
                responseEntity: $attrs.responseEntity ? $scope.$eval($attrs.responseEntity) : $scope.$eval($attrs.entity),
                resourceType: $attrs.resourceType || 'resource',
                cancel: $attrs.cancel || '^',
                edit: $attrs.edit || '.edit',
                entityName: $attrs.entityName || null,
                additionalButtons: $scope.$eval($attrs.additionalButtons),
                disableButton: $scope.$eval($attrs.disableButton),
                disableTooltip: $scope.$eval($attrs.disableTooltip),
                tooltipMessage: $attrs.tooltipMessage,
                additionalBottomButtons: $scope.$eval($attrs.additionalBottomButtons),
                fields: [],
                addHide: (lodash.get($attrs, 'addHide') ? $scope.$eval($attrs.addHide) : $attrs.hasOwnProperty('addHide')),
                onChange: onChange,
                getPriorities: getPriorities,
                editHide: (lodash.get($attrs, 'editHide') ? $scope.$eval($attrs.editHide) : $attrs.hasOwnProperty('editHide')),
                tabs: $attrs.tabs || [],
                autoGenerated: false, // The view was generated from data (no transclusion)
                raiseEvents: lodash.has($attrs, 'raiseEvents'),
                addLink: $attrs.addLink || '.add',
                editLink: $attrs.editLink || '.edit',
                hideButtons: (lodash.get($attrs, 'hideButtons') ? $scope.$eval($attrs.hideButtons) : $attrs.hasOwnProperty('hideButtons')),
                submitButtonText: lodash.get($attrs, 'submitButtonText'),
                titleTemplate: $attrs.titleTemplate || null,
                editWithoutResource: lodash.has($attrs, 'editWithoutResource'),
                siteResponse: $scope.$eval(lodash.get($attrs, 'siteResponse')),
                deleteHide: lodash.get($attrs, 'deleteHide') ? $scope.$eval($attrs.deleteHide) : false,
                submitHide: lodash.get($attrs, 'submitHide') ? $scope.$eval($attrs.submitHide) : false,
                deleteDisabled: lodash.get($attrs, 'deleteDisabled') ? $scope.$eval($attrs.deleteDisabled) : false,
                submitDisabled: lodash.get($attrs, 'submitDisabled') ? $scope.$eval($attrs.submitDisabled) : false,
                ignoreCount: $scope.$eval($attrs.ignoreCount) || false,
                hideTitle: $scope.$eval($attrs.hideTitle) || false,
                plainPanel: $scope.$eval($attrs.plainPanel) || false,
                highlightedSubtitle: $attrs.highlightedSubtitle || null
            };

            $attrs.$observe('responseEntity', function(value){
                $scope.panel.responseEntity = $scope.$eval(value);
            });

            $attrs.$observe('submitDisabled', function(value){
                $scope.panel.submitDisabled = $scope.$eval(value);
            });

            // Did the user override cancel state string with a function in their controller?
            var cancelFunction = lodash.get($scope.$parent, 'vm.cancel');
            if (cancelFunction && typeof cancelFunction === 'function') {
                $scope.panel.cancel = cancelFunction;
            }

            $scope.panel.action = $scope.panel.type;
            if (lodash.get($scope.panel.responseEntity, 'id')) {
                $scope.panel.action = $scope.panel.editLink;
            } else {
                $scope.panel.action = $scope.panel.addLink;
            }

            let noTitle = lodash.has($attrs, 'noTitle');

            if (lodash.has($attrs, 'noTitle') && $attrs.noTitle.length) {
                noTitle = $scope.$eval($attrs.noTitle);
            }

            //Determine Panel Title
            if (!$scope.panel.title && $scope.panel.entityName && !noTitle) {
                $scope.panel.title = $scope.panel.entityName.toUpperCase() + '_';

                if ($scope.panel.type !== 'addedit') {
                    $scope.panel.title += $scope.panel.type.toUpperCase();
                } else if (lodash.get($scope.panel.responseEntity, 'id') || lodash.get($scope.panel.entity, 'id')) {
                    $scope.panel.title += 'EDIT';
                    $scope.panel.action = $scope.panel.editLink;
                } else {
                    $scope.panel.title += 'ADD';
                    $scope.panel.action = $scope.panel.addLink;
                }

                $scope.panel.title = $filter('translate')($scope.panel.title);
            }

            function getPriorities(priority, panel) {
                var partnership = lodash.get($scope, 'panel.entity._links.partnership.href');
                var sp = lodash.get($scope, 'panel.entity._links.serviceprovider.href');
                var site = lodash.get($scope, 'panel.entity._links.site.href', lodash.get($scope, 'panel.siteResponse._links.self.href'));

                var getAPIPriorities = function(link) {
                    var deferred = $q.defer();
                    if (typeof link === 'undefined') {
                        deferred.reject();
                    } else {
                        apiClient.get(link + '/priorities', {status: 'active'}).then(
                            function(response) {
                                if (lodash.has(response, 'priorities') && response.priorities.length > 0) {
                                    deferred.resolve(response);
                                } else {
                                    deferred.reject();
                                }
                            }
                        );
                    }

                    return deferred.promise;
                };

                var setPriorities = function(response) {
                    var priorityList = [];
                    lodash.forEach(response.priorities,
                        function(response) {
                            priorityList.push({
                                title: response.name,
                                value: response.getLink('self'),
                                alertMessageEnabled: response.alertMessageEnabled,
                                alertMessage: response.alertMessage
                            });
                        }
                    );
                    // Add BY_PLANNED_DATE
                    priorityList.push(
                        {
                            title: $filter('translate')('BY_PLANNED_DATE'),
                            value: 'BY_PLANNED_DATE'
                        }
                    );
                    priority.scope.priorities = priorityList;
                };

                getAPIPriorities(partnership).then(
                    function(response) {
                        setPriorities(response);
                    },
                    function() {
                        getAPIPriorities(sp).then(
                            function(response) {
                                setPriorities(response);
                            },
                            function() {
                                getAPIPriorities(site).then(
                                    function(response) {
                                        setPriorities(response);
                                    },
                                    function() {
                                        getAPIPriorities('').then(
                                            function(response) {
                                                setPriorities(response);
                                            }
                                        );
                                    }
                                );
                            }
                        );
                    }
                );
            }

        },
        controller: ['$scope', function($scope) {

            this.getEntity = function() {
                return $scope.panel.entity;
            };
            $scope.invalidForm = function(entityForm) {
                $scope.$emit('panelEntityInvalid', entityForm);
            }
        }]
    };

    /**
     * A panel entity form row has changed
     */
    function onChange(element) {
        console.log('onChange for ' + element.type);
        switch (element.type) {
            case 'site':
                // Sites refresh locations, partnerships and site priority dropdowns in the form
                if (element.site) {
                    element.entity._links.site = {href: element.site.getLink('self')} || null;
                } else {
                    element.entity._links.site = null;
                }
                // Is there a location or site-priority in the entity panel?
                var siteLocation = lodash.find(element.panel.fields, function(o) {
                        return o.type === 'location';
                    }),
                    sitePriority = lodash.find(element.panel.fields, function(o) {
                        return o.type === 'site-priority';
                    }),
                    priority = lodash.find(element.panel.fields, function(o) {
                        return o.type === 'priority';
                    }),
                    prioritySelect = lodash.find(element.panel.fields, function(o) {
                        return o.type === 'priority-select';
                    }),
                    sitePartnership = lodash.find(element.panel.fields, function(o) {
                        return o.type === 'site-partnership';
                    });
                if (siteLocation) {
                    // Reset the sites locations
                    siteLocation.scope.$$childHead.$$childHead.locations = null;
                    if (element.site) {
                        apiClient.get(element.site.getLink('locations')).then(
                            function(response) {

                                console.log('onChange: received ' + response.locations.length + ' locations.');
                                siteLocation.scope.$$childHead.$$childHead.locations = response.locations;
                                siteLocation.scope.$$childHead.$$childHead.location = null;
                                console.log('onChange: locations updated.');
                            },
                            function() {
                                console.error('onChange: failed retrieving locations');
                            }
                        );
                    }
                }

                if (sitePriority) {
                    // Clear down the sites priorities (this dropdown only ever contains site priorities)
                    getSitePriorities(sitePriority, element.site);
                }

                if (sitePartnership) {
                    // Clear down the sites partnerships
                    getSitePartnerships(sitePartnership, element.site);
                }

                if (priority) {
                    // Clear down the priorities
                    this.getPriorities(priority);
                }

                break;
            case 'site-priority':
                // If the selection was 'none' remove the link
                if (element.entity._links[element.property].href == null) {
                    delete element.entity._links[element.property];
                }
                break;
            case 'priority':
                var priorities = element.$parent.priorities;
                var priority = lodash.get(element.entity, '_links.priority.href');
                // A priority was selected so remove the manual dates
                if (priority !== 'BY_PLANNED_DATE') {
                    if (element.entity.completionDueAt) {
                        element.entity.completionDueAt = null;
                    }
                    if (element.entity.attendanceDueAt) {
                        element.entity.attendanceDueAt = null;
                    }
                }

                var priorityObj = {};
                angular.forEach(priorities, function(p) {
                    if (p.value == priority && p.alertMessageEnabled) {
                        priorityObj = p;
                    }
                })

                if (priorityObj.alertMessageEnabled) {
                    confirmationModal.open({
                        titleMessage: 'PRIORITY_MESSAGE',
                        bodyMessage: priorityObj.alertMessage,
                        primaryText: 'OK',
                        primaryClass: 'btn-primary',
                        secondaryText: ''
                    });
                }
                break;
            case 'location':
                if (element.site) {
                    element.entity._links.location = {href: element.location.getLink('self')} || null;
                } else {
                    element.entity._links.location = null;
                }
                break;
        }
    }

    /**
     * Get site priorities
     */
    function getSitePriorities(sitePriority, site) {
        // Rest the sites priorities
        sitePriority.scope.sitePriorities = null;

        if (site && site.getLink('priorities')) {
            apiClient.get(site.getLink('priorities')).then(
                function(response) {
                    var sitePriorities = [];
                    lodash.forEach(response.priorities,
                        function(response) {
                            sitePriorities.push({
                                title: response.name,
                                value: response.getLink('self')
                            });
                        }
                    );
                    sitePriority.scope.sitePriorities = sitePriorities;
                }
            );
        }
    }

    /**
     * Get site partnerships
     */
    function getSitePartnerships(sitePartnership, site) {
        // Rest the sites priorities
        sitePartnership.scope.sitePartnerships = null;
        // support multiple
        sitePartnership.scope.sitePartnershipsSelected = [];

        if (site && site.getLink('partnerships')) {
            apiClient.get(site.getLink('partnerships')).then(
                function(response) {
                    var sitePartnerships = [];
                    lodash.forEach(response.partnerships,
                        function(response) {
                            //multiple checkbox requires label for href
                            sitePartnerships.push({
                                name: response._links.serviceprovider.title,
                                label: response._links.serviceprovider.href,
                                value: response._links.serviceprovider.href
                            });
                        });
                    sitePartnership.scope.sitePartnerships = sitePartnerships;
                }
            );
        }
    }
}]);
