angular.module('elogbooksServices').service('siteAssociatesService', function (apiClient, $q, userManager) {
    var self = this;
    self.getSiteAssociates = getSiteAssociates;
    self.buildSiteAssociateObject = buildSiteAssociateObject;

    function getSiteAssociates(siteResponse, rootResourceResponse, params = null) {
        if ((userManager.hasPermission('site_permission_view_associates') ||
            userManager.hasPermission('user_permission_manage_sites')) &&
            siteResponse.getLink('associates')) {
            let associatesResponse = apiClient.get(siteResponse.getLink('associates')).then(function(response) {
                return response || apiClient.noResourceFound();
            });

            let associateTypesResponse = apiClient.get(rootResourceResponse.getLink('siteassociatetypes'), params).then(function(response) {
                return response || apiClient.noResourceFound();
            });

            let associatesCollection = [];

            $q.all([associatesResponse, associateTypesResponse]).then(function(response) {
                let associates = response[0].associates;
                let associateTypes = response[1].siteAssociateTypes;
                let siteassociatetype = null;
                let siteMembership = null;
                angular.forEach(associateTypes, function(associateType, key) {
                    siteassociatetype = {'title' : associateType.name, 'href' : associateType._links.self.href, 'members' : []};

                    angular.forEach(associates, function(associate, key) {

                        if(associate._links.type.href === associateType._links.self.href) {
                            siteMembership = {
                                'title' : associate._links.sitemembership.title,
                                'href' : associate._links.sitemembership.href,
                                'userHref' : associate._links.user.href,
                                'email' : associate._links.user.email,
                                'phone' : associate._links.user.phone
                            };
                            siteassociatetype.members.push(siteMembership);
                        }
                    });
                    associatesCollection.push(siteassociatetype);
                });
            });

            return associatesCollection;
        }

        return [];
    }

    function buildSiteAssociateObject(associatesResponse, associateTypesResponse) {
        let siteassociatetype = {};
        let siteMembership = {};
        let associatesArray = [];
        angular.forEach(associateTypesResponse.siteAssociateTypes, function(associateType, key) {
            siteassociatetype = {title: associateType.name, href: associateType._links.self.href, members: []};

            angular.forEach(associatesResponse.associates, function(associate, key) {
                if(associate._links.type.href === associateType._links.self.href) {
                    siteMembership = {
                        title: associate._links.sitemembership.title,
                        href: associate._links.sitemembership.href,
                        userHref: associate._links.user.href
                    };
                    siteassociatetype.members.push(siteMembership);
                }
            });
            associatesArray.push(siteassociatetype);
        });

        associateTypesResponse.siteAssociateTypes = associatesArray;

        return associateTypesResponse;
    }
});
